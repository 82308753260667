/* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 30/12/2021
* Author : Nisha Kataria
*/

import React from 'react';
import { DropDownView, DropDownTitle, DropDownButton, ArrowDown, SelectedInput, TitleContainer, SelectedText, DropDownList, ItemText } from './Filter.styled';
import i_down_large from "../../images/icons/i-down-large.png";
import {  useTranslation } from 'react-i18next';
function renderChildren(mapList, listOfObjects, selectionMethod, title, selectedValue, t) {
    return mapList.map(function (key, index) {
        let selectedItem = false
        let textValue = ''
        selectedItem = selectedValue === key
        if(key === 'ALL' || key === t('ALL')){
            textValue = t('ALL')
        }else if (title === t('Regional Manager:')) {
            textValue = listOfObjects[key].name
        } else if(title === t('Channel of Trade:')) {
            textValue = key
        } else{
            textValue = listOfObjects[key].siteName
        }
        return (<ItemText selectedItem={selectedItem} className='hoverItem' onClick={() => {
            const value = { [key]: listOfObjects[key] };
            selectionMethod(key, value)
        }}>{textValue}</ItemText>
        )
    })
}

export const DropDown = React.forwardRef(
    ({ title, openDropDownMethod, dropDownKey, placeholderText, listOfObjects,arrayList, selectedValue, selectionMethod, handleChange }, ref) => {
        const { t  } = useTranslation();
        return (
            <DropDownView>
                <TitleContainer>
                    <DropDownTitle>{title}</DropDownTitle>
                    <DropDownButton onClick={() => {
                        openDropDownMethod(title)
                    }}>
                        {title === t('Site Name:') || title === t('Regional Manager:') ?
                            <SelectedInput type="text"
                                placeholder={title.substring(0, title.length - 1)}
                                value={placeholderText}
                                onChange={(e) => { handleChange(e) }}
                            />
                            :
                            <SelectedText>{placeholderText}</SelectedText>}
                        {title !== t('Country :') && <ArrowDown src={i_down_large} />}
                    </DropDownButton>
                    {title !== t('Country :') && title === dropDownKey && <DropDownList>
                        {
                            renderChildren(arrayList, listOfObjects, selectionMethod, title, selectedValue, t)
                        }
                    </DropDownList>}
                </TitleContainer>
            </DropDownView>
        )
    }
);

DropDown.defaultProps = {

};


DropDown.propTypes = {

};

export default DropDown;
