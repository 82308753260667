
import styled from "styled-components";

export const ProgressContainer = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
  margin: ${(props) => (props.isCoManager ? "0px 12px" : "0px 23px")};
  flex-direction: column;
`;

export const TotalOpenTicket = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${(props) => (props.isCoManager ? "3px" : "5px")};
`;

export const TicketText = styled.span`
  font-family: ${(props) => (props.boldText ? "Roboto-bold" : "Roboto-light")};
  font-size: ${(props) => (props.isCoManager ? "18px" : "14px")};
  font-weight: 300;
  color: #000;
  text-align: center;
  font-weight: ${(props) => (props.boldText ? "800" : "400")};
  margin-right: ${(props) => (props.boldText ? "5px" : "0px")};
`;

export const Container = styled.div`
  border-top: ${(props) => (props.isCoManager ? "1px solid #ebebeb" : "1px solid #ccc")};
  border-bottom: ${(props) => (props.isCoManager ? "none" : "1px solid #ccc")};
  object-fit: cover;
  @media only screen and (max-width: 1150px) {
    overflow-x: hidden;
    overflow-y: hidden;
  } ;
  height: ${(props) => (!props.isCoManager ? "250px" : "")};
`;

export const TicketContainer = styled.div`
  display: flex;
  margin: ${(props) => (props.isCoManager ? "0 8px" : "10px 23px 0px 23px")};
  justify-content: space-between;
`;

 export const ContainerType = styled.div`
  flex: 0.49;
`;
export const TicketView = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  font-size: 0px;
  margin-bottom: ${(props) => (props.isCoManager ? "6.5px" : "5px")};
  margin-top: ${(props) => (props.isCoManager ? "6px" : "0")};
`;

export const TicketSubTitle = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 2px;
  padding-bottom: 1px;
  overflow: hidden;
  align-items: start;
`;
export const IconView = styled.div`
  width: 21px;
  height: 23px;
  margin-top: 2px;
`;

export const CircleView = styled.div`
  width: ${(props) => (props.type === "subText" ? "16px" : "18px")};
  height: ${(props) => (props.type === "subText" ? "16px" : "18px")};
  border-radius: ${(props) => (props.type === "subText" ? "8px" : "9px")};
  text-align: center;
  margin-top: ${(props) => (props.type === "subText" ? "4px" : "0px")};
  background-color: ${(props) =>
    props.taskType === "open" ? props.isCoManager ? "#007833" : "#090" : "#edac1a"};
  font-family: Roboto-Regular;
  font-size: ${(props) => (props.isCoManager ? "12px" : "10px")};
  color: #fff;
`;

export const TicketSubText = styled.div`
  display: -webkit-box;
  // -webkit-line-clamp: ${(props) => (props.fulltext ? "" : "1")};
  // width: ${(props) => (props.type === "subText" ? "105px" : "85px")};
  width: auto;
  font-family: ${(props) =>
    props.type === "subText" ? "Roboto-Regular" : "Roboto-Light"};
  font-size: ${(props) => (props.type === "subText" ? "12px" : "14px")};
  padding-left: ${(props) => (props.type === "subText" ? "10px" : "8px")};
  padding-top: ${(props) => (props.type === "subText" ? "4px" : "0px")};
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  color: ${(props) => (props.type === "subText" ? "#666666" : "#000")};
  margin-top: -2px;

  ${(props) =>
    props.type === "subText" &&
    `
  &:hover {
    background-color: ${
      props.taskType === "open"
        ? "rgba(0, 153, 1, 0.17)"
        : "rgba(245, 206, 51, 0.17)"
    };
  }
  `}
`;

export const TicketScrollView = styled.div`
  height: ${(props) => (props.isCoManager ? "230px" : "148px")};
  overflow-y: scroll;
  padding-left: 5px;
  -webkit-scrollbar {
    display: thin;
  }
  box-shadow: inset -0.5px 0 0 0 rgba(0, 0, 0, 0.07),
    inset -1px 0 0 0 rgba(0, 0, 0, 0.02);
  background-color: rgba(0, 0, 0, 0.02);
`;
export const ArrowIcon = styled.img``;

export const DescriptionView = styled.div`
  font-size: 0;
  margin: 5px 0px 5px 0px;
`;
export const DescriptionText1 = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #000;
  padding: 2px 0px 2px 0px;
  margin-top: 4px;
`;

export const DescriptionText = styled.div`
  font-family: Roboto-Regular;
  position: relative;
  display: flex;
  font-size: 11px;
  color: #666666;
  padding: 2px 0px 2px 12px;
  margin-left: 6px;
  margin-top: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
  width:70%;
  align-items: center;

  &:hover {
    background-color: ${(props) =>
      props.taskType === "open"
        ? "rgba(0, 153, 1, 0.17)"
        : "rgba(245, 206, 51, 0.17)"};
  }
`;

export const PopupContainer = styled.div`
  visibility: ${(props) => (props.showPopup ? "visible" : "hidden")};
  width: 115px;
  background-color: #353535;
  text-align: center;
  border-radius: 9px;
  padding: 10px;
  position: fixed;
  z-index: 1;
  top: ${(props) => `${props.top +30}px`};
  
  ${(props) =>
    props.taskType === "open"
      ? `left: ${props.left - 5}px;`
      : `left: ${props.left + 10}px;`}
  ${(props) =>
    props.taskType === "open"
      ? `
    &::after {
      content: "";
      position: absolute;
      top: -17%;
      left: 45%;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #353535 transparent ;
    }
    `
      : `
    &::after {
      content: "";
      position: absolute;
      top: -17%;
      right: 40%;
      border-width: 10px;
      border-style: solid;
      border-color: transparent  transparent #353535 transparent;
    }
    `};
`;
export const PopupContainerMobile = styled.div`
  visibility: ${(props) => (props.showPopup ? "visible" : "hidden")};
  width:150px;
  background-color: #353535;
  text-align: center;
  border-radius: 9px;
  padding: 10px;
  position: fixed;
  z-index: 1;
  top: ${(props) => `${props.top + 36}px`};
  left:${(props) => `${props.left - 6}px`};
};
`;

export const DisFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: ${(props) => (props.fulltext ? "auto" : "100%")};
  margin-left: ${(props) => (props.id === "top-indicator" ? "5px" : "")};
  padding-left: 12px;
  padding-top: ${(props) => (props.isCoManager ? "5px" : "9px")};
  padding-bottom: ${(props) => (props.isCoManager ? "7px" : "0")};
  width:100%
`;

export const ArrowContainer = styled.div`
  display: inline-block;
  text-align: center;
  flex-grow: 1;
  margin-right:0.5em;
  margin-left:0.5em;
`;

export const SpincontainingMessage = styled.div`
  display: flex;
  width: 100%;
  height: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MessageText = styled.span`
  font-family: Roboto-light;
  font-size: 13px;
`;

export const MessageText2 = styled.span`
  font-family: Roboto-light;
  font-size: 12px;
`;

export const Clockwisebox = styled.div`
  height: 20px;
  width: 20px;
  margin-top: 1.5em;
`;
export const GreenColorText = styled.span`
  color: #009901;
  font-weight: bold;
  cursor: pointer;
`;

export const BlackLine = styled.div`
  width: 5px;
  height: 100%;
  border-right: 2px solid black;
  padding-left: ${(props) => props.Sidepercent}%;
  position: relative;
`;
export const SideLine = styled.span`
  display: flex;
  align-self: flex-end;
  height: 15%;
  border-right: 1px solid grey;
`;

export const BlackAverageLine = styled.div`
  width: 104%;
  border-bottom: 2px solid black;
  height: ${(props) => (props.id === "green" ? `${100 - props.margin}%` : "")};
`;

export const Targetpoint = styled.div`
 height: "100%",
 width: 2px;
 border-left: 2px solid black;
 border-right: 2px solid black;
 background-color: black;
`;


