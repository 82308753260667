export const SORT = {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC',
};

export const SELECTION = {
    ALL: 'ALL',
    SOME: 'SOME',
    NONE: 'NONE',
};
