import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import TradingUiComponent from "../../../../scorecardNewDesign/TradingProfitably/Screens/TradingUiComponent";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const WBCSales = ({ wbcSales, getWBCSalesData, layout }) => {
    useEffect(() => {
        getWBCSalesData(KPINames.WBC_SALES);
    }, []);

    if (wbcSales.isLoading || layout?.data === null) {
        return <ScorecardPlaceholder />;
    }

    if (wbcSales.data) {
        return (
            <TradingUiComponent
                dataFor={"Month"}
                data={wbcSales.data}
                index={3}
                weekNumber={layout?.data?.weekNumber}
                expectedRefreshTimestamp={layout?.data?.schedules?.shopSalesData}
            />
        );
    }

    return null;
};

function mapStateToProps(state) {
    const {
        scorecardImprovedInfo,
        authInfo: {
            userData: { name },
        },
    } = state;
    const wbcSales = scorecardImprovedInfo[KPINames.WBC_SALES];
    const layout = scorecardImprovedInfo[KPINames.LAYOUT];
    return { name, wbcSales, layout };
}

const mapDispatchToProps = {
    getWBCSalesData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(WBCSales);
