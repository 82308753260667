import { LANGUAGE_URLS } from '../constants/environmentConstants';

export const siteServices = {
    getSiteData
}

function getSiteData(id, data, languageCode) {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization':data
        } 
    }
    const url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    return fetch(`${url}/site-ranking/${id}?start-date=2020-01-01&end-date=2021-11-26`, requestOptions)
    .then(handleResponse)
    .then(data => {
        if(data.statusCode === 400){
            return Promise.reject(data);
        }
        else{
            return data;
        }
    })
    .catch(error => {
        return Promise.reject(error);
    });
}

function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        let error = (data && data.message) || response.statusText;
        if (response.status === 400) {
          error = response.status;
        }
        throw new Error(error);
      }
      return data;
    });
  }