import { constants } from '../constants/reducerConstants';
import { LOGIN_URLS, LOGOUT_URLS } from '../constants/environmentConstants';

export const isEmptyObj = (value) => {
  return (
    value && Object.keys(value).length === 0 && value.constructor === Object
  );
};

export const startLoader = () => {
  window.scrollTo(0, 0);
  return { type: constants.SHOW_LOADER };
};
export const stopLoader = () => {
  return { type: constants.HIDE_LOADER };
};

export const defaultLanguage = 'PL';

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const errorMessage = (data && data.message) || response.statusText;
      const errorCode = response.status;
      const error = {
        errorMessage,
        errorCode
      }
      throw (error);
    }
    return data;
  });
}

export function showErrorPage(error){
    const message = error.message
    const errorCode = error.errorCode || 500;
    const isShow = true;
    return {type: constants.SHOW_ERROR_PAGE ,errorCode, isShow, message}
}

export function hideErrorPage(){
  const errorCode = null
    const isShow = false
  return {type: constants.SHOW_ERROR_PAGE ,errorCode, isShow}
}

export function getRedirectUrl(langCode) {
  let redirectUrl = '';
  if (window.location.hostname.includes('localhost')) {
    redirectUrl = process.env.REACT_APP_REDIRECTURL;
  } else if (!window.location.hostname.includes('localhost') && process.env.REACT_APP_ENVIRONMENT !== 'prod') {
    redirectUrl = `https://${langCode}.${process.env.REACT_APP_ENVIRONMENT}-eos.bpglobal.com`;
  } else {
    redirectUrl = `https://${langCode}.eos.bpglobal.com`;
  }
  return redirectUrl;
}

export function getLanguageCode() {
  let languageCode = '';
  if (window.location.hostname.includes('localhost')) {
    languageCode = process.env.REACT_APP_COUNTRYCODE.toLowerCase();
  } else {
    languageCode = window.location.hostname.substring(0, 2).toLowerCase();
  }
  return languageCode;
}

export function getLoginUrl(langCode) {
  const redirectUrl = getRedirectUrl(langCode);
  return `${LOGIN_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT).url}${redirectUrl}`;
}

export function getLogoutUrl(langCode) {
  const redirectUrl = getRedirectUrl(langCode);
  return `${LOGOUT_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT).url}${redirectUrl}`;
}