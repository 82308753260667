/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 06/12/2021
* Author : Sunil Vora
*/
import { LANGUAGE_URLS } from '../constants/environmentConstants';

export const tableServices = {
    getAverageRatings
}

function getAverageRatings(id, data, filter, languageCode) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': data
        }
    }
    const url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    return fetch(`${url}/google-site-ratings${filter}`, requestOptions)
        .then(handleResponse)
        .then(data1 => {

            // const sites = data.body.map(site => site.siteName);
            // const headings = data.body[0].ratings.map(header => header.columnHeader);
            if (data1.statusCode === 400) {
                return Promise.reject(data);
            }
            else {
                let data = data1.body.listOfSites
                if (data.length > 0) {
                    let monthHeader = []
                    let weekHeader = []
                    let dayHeader = []
                    let sites = []
                    sites = data.map(site => site.siteName);
                    //  data.map((item, index) => {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].monthRatings && data[i].monthRatings.length > 0) {
                            monthHeader = data[i].monthRatings.map(header => header.columnHeader);
                        }
                        if (data[i].weekRatings && data[i].weekRatings.length > 0) {
                            weekHeader = data[i].weekRatings.map(header => header.columnHeader)
                        }
                        if (data[i].dayRatings && data[i].dayRatings.length > 0) {
                            dayHeader = data[i].dayRatings.map(header => header.columnHeader)
                        }
                        if (monthHeader.length > 0 && dayHeader.length > 0 && weekHeader.length > 0) {
                            break;
                        }
                    }
                    return {
                        sites,
                        monthHeader,
                        dayHeader,
                        weekHeader,
                        rating: data
                    }
                }
            }

        })
        .catch(error => {
            return Promise.reject(error);
        });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            let error = (data && data.message) || response.statusText;
            if (response.status === 400) {
                error = response.status;
            }
            throw new Error(error);
        }
        return data;
    });
}