

export function selectedFilter(filterKey, detailsList, filterList, country) {
   let fetchArray
   if((filterKey === "ALL Roles") && filterList.includes("ALL Views") && country === "ALL Countries"){
     return detailsList
   }
   else if((filterKey !== "ALL Roles") && !filterList.includes("ALL Views") && country !== "ALL Countries"){
     fetchArray = detailsList.filter((response) => response.role === filterKey && response.countryCode === country && filterList.includes(response.view))
     return fetchArray
   }
   else if((filterKey === "ALL Roles") && !filterList.includes("ALL Views") && country === "ALL Countries"){
    fetchArray = detailsList.filter((response) => filterList.includes(response.view))
    return fetchArray
  }
  else if((filterKey === "ALL Roles") && filterList.includes("ALL Views") && country !== "ALL Countries"){
    fetchArray = detailsList.filter((response) => response.countryCode===country)
    return fetchArray
  }
  else if((filterKey === "ALL Roles") && !filterList.includes("ALL Views") && country !== "ALL Countries"){
    fetchArray = detailsList.filter((response) => response.countryCode===country && filterList.includes(response.view))
    return fetchArray
  }
  else if((filterKey !== "ALL Roles") && filterList.includes("ALL Views") && country === "ALL Countries"){
    fetchArray = detailsList.filter((response) => response.role === filterKey)
    return fetchArray
  }
  else if((filterKey !== "ALL Roles")  && country !== "ALL Countries" && filterList.includes("ALL Views")){
    fetchArray = detailsList.filter((response) => response.role === filterKey && response.countryCode===country)
    return fetchArray
  }
  
  else if((filterKey !== "ALL Roles") && !filterList.includes("ALL Views") && country === "ALL Countries"){
    fetchArray = detailsList.filter((response) => response.role === filterKey && filterList.includes(response.view))
    return fetchArray
  }
  else if((country !== "ALL Countries") && !filterList.includes("ALL Views") && filterKey === "ALL Roles"){
    fetchArray = detailsList.filter((response) => response.countryCode === country && filterList.includes(response.view))
    return fetchArray
  }
  }

export function searchListFetch(detailsList, searchText) {    
    const fetchArray = detailsList.filter(arrayValue => arrayValue.name.toLowerCase().search(searchText.toLowerCase()) !== -1||arrayValue.id?.toLowerCase().search(searchText.toLowerCase()) !== -1)
    return fetchArray;
  }

export function checkAllStatus(logdata){
  const fetchArray = logdata.filter((item) => item.status === "COMPLETED" || item.status === "FAILED")
  return fetchArray
}

export function removeCategory(data, list){
  const fetchArray = list.filter((item) => item.category !== data)
  return fetchArray
}

  export function searchLinkFetch(detailsList, searchText) {    
    const fetchArray = detailsList.filter(arrayValue => arrayValue.name.toLowerCase().search(searchText.toLowerCase()) !== -1)
    return fetchArray;
  }

  export function findEditList(detailsList) {
    const filteredValue = detailsList.find((response) => response.editKey)
    return filteredValue;
  }



  export function checkCategory(detail, detailsList) {
    const filteredValue = detailsList.find((response) => response.category === detail)
    return filteredValue;
  }

  export function subCategoryFinder(item,data){
    const fetchArray = data.filter(arrayValue => arrayValue["category"] === item)
    return fetchArray;
  }

  export function itemFinder(item, data){
    const fetchArray = data.subCategories.filter(arrayValue => arrayValue["subCategory"] === item)
    return fetchArray;
  }