/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/14/2022
 * Author : Aditya Padmanabhan
 */



import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { eMaintenanceActions } from '../../actions/eMaintenanceAction';
import { scorecardActions } from '../../actions/scorecardActions';
import { EVENT_REGIONAL_MANAGER_SELECTION, EVENT_SITE_FILTER_SELECTION, KEY_ARRAY_OF_SAP_ID, KEY_ARRAY_OF_SITES, KEY_REGIONAL_MANAGER_ID, KEY_REGIONAL_MANAGER_NAME, KEY_USER_ID, KEY_USER_TITLE, UNKNOWN_TITLE } from '../../constants/FireBaseEvents';
import isMobile from '../../helpers/isMobile';
import ChecklistSelection from '../button/checklistSelection';
import RMSelectionList from '../button/rmSelectionList';
import SelectionButton from '../button/selectionButton';
import { FilterContainer, FilterView, FilterWidget } from '../filter/Filter.styled';
import MobileScorecardFilter from './MobileScorecardFilter';
import { ApplyText, StoreFilter } from './ScoreCard.styled';
import { capitalize, eventLogging, siteCollector } from './utils';
function ScorecardFilter(props) {
  const { t } = useTranslation();
  const [dropDownKey, setDropDownKey] = useState(false);
  const [dropDownKeyRm, setDropDownKeyRm] = useState(false);
  let [sites, setAllSites] = useState([]);
  let [selectedSites, setSelectedSites] = useState([]);
  let [tempNumberofsites, setTempNumSelectedSites] = useState([]);
  let [rmData, setRmData] = useState([])
  let [filterButton, setFilterButton] = useState(true)
  const [idOfManager, setIdOfManager] = useState(props.selectedManagerId)
  const [nameOfManager, setnameOfManager] = useState(props.selectedManagerName)
  
  let refRmDrop = useRef(null);// take ref for regional manager dropdown
  let refCheck = useRef(null) // take ref for sites dropdown
  let refApplyFilter = useRef(null)
 
  useEffect(() => {
    //condition for initial filter rendering
    if (props.languageCode === 'uk' && (isEmpty(props.listOfSites))) {
      props.getScorecardSiteData().then((data) => {
        let details = data.body.sites[data.body.user.regionalManagerId].map((item) => {
          return item.siteName;
        });
        props.numberOfSites(details.length)
        details.unshift("ALL");
        setRmData(data.body.regionalManagers)
        // updating managername and managerid in state
      props.toggleManagerName({id:data.body.user.regionalManagerId, name:data.body.user.regionalManagerName})
        setAllSites(details);
        setSelectedSites(details);
        if (isMobile()) {
          setSelectedSites([details[0]]);
          props.numberOfSites(0);
        }
      });
    }
    // condition to get back  scorecard filter states when user refresh the page
    else if (isEmpty(sites) && !isEmpty(props.listOfSites) && isEmpty(props.siteNames)) { 
      let details = props.listOfSites[props.selectedManagerId].map((item) => {
        return item.siteName;
      });
      props.numberOfSites(details.length);
      details.unshift("ALL");
      setRmData(props.regionalManagers)
       // updating managername and managerid in state
      props.toggleManagerName({id:props.selectedManagerId, name:props.selectedManagerName})
      setAllSites(details);
      setSelectedSites(details);
     // props.getScorecardData();
      props.sitesName([]);
      if (isMobile()) {
        setSelectedSites([details[0]]);
        props.numberOfSites(0);
      }
    }
    // condition to get back scorecard filterstates when user applies mobile filter 
    else {
      let details = props.listOfSites[props.selectedManagerId].filter((item) => props.siteNames.includes(item.siteName));
      details = details.map((item) =>{
        return item.siteName
      })
      let details1 = props.listOfSites[props.selectedManagerId].map((item) => {
        return item.siteName;
      });
      setRmData(props.regionalManagers)
      setSelectedSites(details);
      details1.unshift("ALL");
      setAllSites(details1);
      
    }
   }, [])

  // useEffect for close dropdown outside for Sites and useEffect for Apply filter button
  useEffect(() => {
    const closedropdown = (event)=>{
    if ((refCheck?.current && !refCheck?.current.contains(event?.target)) && (refApplyFilter?.current && !refApplyFilter?.current.contains(event?.target)) ) {
      setDropDownKey(false)
      }
    }
    document.addEventListener('click',closedropdown, true)
    return () => {
      document.removeEventListener('click', closedropdown, true);
    };
  }, [refCheck]);

  // useEffect for close dropdown outside for Regional Manager
  useEffect(() => {
    const closedropdown = (event)=>{
    if ((refRmDrop?.current && !refRmDrop?.current.contains(event?.target))) {
      setDropDownKeyRm(false)
      }
    }
    document.addEventListener('click',closedropdown, true)
    return () => {
      document.removeEventListener('click', closedropdown, true);
    };
  }, [refRmDrop]);



  function selectionMethod(key, value) {
    let selectedItem = [...selectedSites];
    let searchStar = selectedItem.indexOf(key);
    if (selectedItem.length === props.listOfSites[props.selectedManagerId].length - 1 && !selectedItem.includes("ALL") && searchStar === -1) {
      selectedItem = [...sites];
    }
    else if (value === 0 && selectedItem.includes("ALL")) {
      selectedItem = [];
    }
    else if (value === 0) {
      selectedItem = [...sites];
    } else {
      if (searchStar === -1) {
        selectedItem.push(key);
      }

      else {
        selectedItem.splice(searchStar, 1);
        if (selectedItem[0] === 'ALL') {
          selectedItem.splice(0, 1);
        }
      }
    }
    setSelectedSites(selectedItem);
  }

 

  //mobile selection function
  function mobileSelection(key, value) {
    let selectedItem = [...selectedSites];
    let searchStar = selectedItem.indexOf(key)
    if (selectedItem.length === sites.length - 2 && !selectedItem.includes('ALL')) {
      selectedItem = [...sites]
      setTempNumSelectedSites(0)
        props.numberOfSites(0);
    }
    else if (searchStar === -1 && value === 0) {
      selectedItem = [sites[0]];
      setTempNumSelectedSites(0)
        props.numberOfSites(0);
      
    }
    else if (selectedItem[0] === 'ALL' && value !== 0) {
      selectedItem = [sites[value]];
        props.numberOfSites(selectedItem.length);
      setTempNumSelectedSites(selectedItem.length)
    }
    else {
      if (searchStar === -1) {
        selectedItem.push(key);
          props.numberOfSites(selectedItem.length);
       setTempNumSelectedSites(selectedItem.length)
      }
      else if (selectedItem.length > 1) {
        selectedItem.splice(searchStar, 1);
         props.numberOfSites(selectedItem.length);
        setTempNumSelectedSites(selectedItem.length)
      }
    }
    setSelectedSites(selectedItem);
   }

  //send function for desktop
  function sendFunction() {
    if (dropDownKey) {
      let siteSelector = [...selectedSites];
      if (siteSelector.indexOf("ALL") !== -1) {
        siteSelector.splice(siteSelector.indexOf("ALL"), 1);
        let site = siteCollector(siteSelector, props.listOfSites[props.selectedManagerId]);
        props.emptyScoreCard();
        let sapIdArr = [];
        let sapNameArr = [];
        let request = {
          sapIds: sapIdArr,
          siteNames: sapNameArr,
          regionalManagerId: props.selectedManagerId,
         regionalManagerName: props.selectedManagerName,
        };
        props.getScorecardData(undefined, undefined, undefined, request).then(() => {
          delete request.siteNames;
          props.getEmaintananceData(request);
        });
        props.numberOfSites(site.length);
        let emptyres = []
        props.sitesName(emptyres);
        // log event for apply filter button
        let managerSelectionPayload = {
          [KEY_USER_ID]: props?.id,
          [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
          [KEY_REGIONAL_MANAGER_NAME]: props.selectedManagerName,
         [KEY_REGIONAL_MANAGER_ID]: props?.selectedManagerId,
         [KEY_ARRAY_OF_SAP_ID]:sapIdArr,
         [KEY_ARRAY_OF_SITES]:sapNameArr
         } 
         eventLogging(EVENT_SITE_FILTER_SELECTION, managerSelectionPayload )

      } else {
        let site = siteCollector(siteSelector, props.listOfSites[props.selectedManagerId]);
        let sapIdArr = [];
        let sapNameArr = [];
        site.forEach((item) => {
          sapIdArr.push(item.sapId);
          sapNameArr.push(item.siteName);
        });
        let request = {
          sapIds: sapIdArr,
          siteNames: sapNameArr,
          regionalManagerId: props.selectedManagerId,
          regionalManagerName: props.selectedManagerName,
        };
        props.emptyScoreCard();
        let flag = sapNameArr.filter((Item) => !props.siteNames.includes(Item));
        if (!isEmpty(flag)) {
          props.sitesName(sapNameArr);
          props
            .getScorecardData(undefined, undefined, undefined, request)
            .then(() => {
              delete request.siteNames;
              props.getEmaintananceData(request);
            });
          props.numberOfSites(site.length);
        }

        // log event for apply filter button
        let managerSelectionPayload = {
          [KEY_USER_ID]: props?.id,
          [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
          [KEY_REGIONAL_MANAGER_NAME]: props.selectedManagerName,
          [KEY_REGIONAL_MANAGER_ID]: props?.selectedManagerId,
          [KEY_ARRAY_OF_SAP_ID]:sapIdArr,
          [KEY_ARRAY_OF_SITES]:sapNameArr
         } 
         eventLogging(EVENT_SITE_FILTER_SELECTION, managerSelectionPayload )

      }
      if (dropDownKey) {
        setDropDownKey(!dropDownKey)
      }
    }
  }

  //mobile scorecard filter apply function
 async function sendMobile() {
   // updating regional managername and managerid in state
  await props.toggleManagerName({id:idOfManager, name:nameOfManager})
   props.numberOfSites(tempNumberofsites)
   if (props.noSelected === -1) {
       let sapIdArr = [];
        let sapNameArr = [];
        let request = {
          sapIds: sapIdArr,
          siteNames: sapNameArr,
          regionalManagerId: idOfManager,
         regionalManagerName: nameOfManager,
        };
        props.emptyScoreCard();
        props.sitesName(sapNameArr);
        props.getScorecardData(undefined, undefined, undefined, request).then(() => {
          delete request.siteNames;
          props.getEmaintananceData(request);
        });
        
        // log event for apply filter button
        let managerSelectionPayload = {
          [KEY_USER_ID]: props?.id,
          [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
          [KEY_REGIONAL_MANAGER_NAME]: props.selectedManagerId,
          [KEY_REGIONAL_MANAGER_ID]: props.selectedManagerName,
          [KEY_ARRAY_OF_SAP_ID]:sapIdArr,
          [KEY_ARRAY_OF_SITES]:sapNameArr
         } 
         eventLogging(EVENT_SITE_FILTER_SELECTION, managerSelectionPayload )
    }
    else {
       let siteSelector = [...selectedSites];
      //let siteSelector = [...tempSelectedSites];
      if (siteSelector[0] === "ALL") {
        siteSelector.splice(siteSelector.indexOf("ALL"), 1)
      }
      let site = siteCollector(siteSelector, props.listOfSites[idOfManager]);
      let sapIdArr = [];
      let sapNameArr = [];
      site.forEach((item) => {
        sapIdArr.push(item.sapId);
        sapNameArr.push(item.siteName);
      });
      let request = {
        sapIds: sapIdArr,
        siteNames: sapNameArr,
        regionalManagerId:  idOfManager,
        regionalManagerName: nameOfManager,
      };
      props.sitesName(sapNameArr);
      props.emptyScoreCard();
      props.getScorecardData(undefined, undefined, undefined, request).then(() => {
        delete request.siteNames;
        props.getEmaintananceData(request);
      });
      let managerSelectionPayload = {
        [KEY_USER_ID]: props?.id,
        [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
        [KEY_REGIONAL_MANAGER_NAME]: nameOfManager,
        [KEY_REGIONAL_MANAGER_ID]: idOfManager,
        [KEY_ARRAY_OF_SAP_ID]:sapIdArr,
        [KEY_ARRAY_OF_SITES]:sapNameArr
       } 
       eventLogging(EVENT_SITE_FILTER_SELECTION, managerSelectionPayload )
    }
  }

  function conditionChecker() {
    if (selectedSites.length !== 0 && dropDownKey) {
      sendFunction();
    }
  
  }

  function dropdownCloser() {
    setDropDownKeyRm(false)
    if (selectedSites.length !== 0) {
     // setDropDownKey(!dropDownKey);
      if (props.siteNames.length === 0) {
        let resArr = props.listOfSites[props.selectedManagerId].map((item) => {
          return item.siteName;
        });
        resArr.unshift("ALL");
        setSelectedSites(resArr);
      }
      else {
        let resArr = [...props.siteNames];
        setSelectedSites(resArr);
      }
    }
    setDropDownKey(!dropDownKey);
  }
  const dropdownCloserRmSelection = () => {
    setDropDownKeyRm(!dropDownKeyRm)
    setDropDownKey(false);
  }

  const selectionMethodRm = (item, index)=>{
    // updating regional managername and managerid in state
    props.toggleManagerName({id:item.regionalManagerId, name:item.regionalManagerName})
    // end
      if (!isEmpty(props.listOfSites)) {
        let details = props.listOfSites[item.regionalManagerId].map((item) => {
          return item.siteName;
        });
        props.numberOfSites(details.length);
        details.unshift("ALL");
        setAllSites(details);
        setSelectedSites(details);
        let request = {
          sapIds: [],
          siteNames: [],
          regionalManagerId: item.regionalManagerId,
          regionalManagerName: item.regionalManagerName,
        };
        props.emptyScoreCard();
        props.getScorecardData(undefined, undefined, undefined, request).then(() => {
          
          delete request.siteNames;
          props.getEmaintananceData(request);
          setFilterButton(false)
          
        });
        props.sitesName([]);
        //event logging on firebase for regional manager change
        let managerSelectionPayload = {
          [KEY_USER_ID]: props?.id,
          [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
         [KEY_REGIONAL_MANAGER_NAME]: item?.regionalManagerName,
         [KEY_REGIONAL_MANAGER_ID]: item?.regionalManagerId,
         } 
         eventLogging(EVENT_REGIONAL_MANAGER_SELECTION, managerSelectionPayload )
         //end

        // if (isMobile()) {
        //   setSelectedSites([details[0]]);
        //   props.numberOfSites(0);
        // }
      }
      setDropDownKeyRm(!dropDownKeyRm) 
  }

  const selectionMethodRmMobileVersion = (item, index) => {
    if (!isEmpty(props.listOfSites)) {
      setIdOfManager(item.regionalManagerId);
      setnameOfManager(item.regionalManagerName);
      let details = props.listOfSites[item.regionalManagerId].map((item) => {
        return item.siteName;
      });
      details.unshift("ALL");
      setAllSites(details);
      setSelectedSites(details);
      setSelectedSites([details[0]]);
      props.numberOfSites(0);
    }
  };

  const revertFilterselection =  () => {
    if(isEmpty(props.siteNames)){
      let details = props.listOfSites[props.selectedManagerId].map((item) => {
        return item.siteName;
      });
      details.unshift("ALL");
      setIdOfManager(props.selectedManagerId);
      setnameOfManager(props.selectedManagerName);
      setAllSites(details);
      setSelectedSites([details[0]]);
      props.numberOfSites(0);
    }
    else{
      let details = props.listOfSites[props.selectedManagerId].filter((item) => props.siteNames.includes(item.siteName));
      details = details.map((item) =>{
        return item.siteName
      })
      let allSiteSelection = props.listOfSites[props.selectedManagerId].map((item) => {
        return item.siteName;
      });
      setRmData(props.regionalManagers)
      setIdOfManager(props.selectedManagerId);
      setnameOfManager(props.selectedManagerName);
      setSelectedSites(details);
      allSiteSelection.unshift("ALL");
      setAllSites(allSiteSelection);
      props.numberOfSites(props.siteNames.length);
      }
  }

  if (!isMobile()) {
    return (
      <FilterView filterFor="score" data-test="filter-header" id="filterView">
        <FilterContainer data-test="filter-block" id="filterContainer">
          <FilterWidget usedFor="scoreCard" id="filterWidget">Filter Scorecard:</FilterWidget>
           <StoreFilter ref={el => {  refRmDrop.current = el; }} id="storeFilter">

            <SelectionButton
              onClick={() => dropdownCloserRmSelection()}
              buttonText={props.selectedManagerName=== "" ?"Please select item":capitalize(props.selectedManagerName)}
              width={300}
              openDropDown={dropDownKeyRm}
              id="selectionButton"
            />

            {dropDownKeyRm && (
              <RMSelectionList
                arrayList={rmData}
                width={300}
                selectedMethod={(item, index) => selectionMethodRm(item, index)}
                selectedValue={props.selectedManagerName}
                id="selectionList"
              />
            )}

          </StoreFilter>
          <div style={{ marginLeft: 20 }} />
          <StoreFilter  ref={el => {  refCheck.current = el; }} id="storeFilter">

            <SelectionButton
              onClick={() => dropdownCloser()}
              buttonText={selectedSites.length === 1 ? selectedSites[0] : selectedSites[0] === "ALL" ?
                t("All") : selectedSites.length === 0 ? t('Please select a site') : t("Multiple Selection")}
              width={300}
              openDropDown={dropDownKey}
            />

            {dropDownKey && (
              <ChecklistSelection
                arrayList={sites}
                width={300}
                selectedMethod={(item, index) => selectionMethod(item, index)}
                selectedValue={selectedSites}
                noStarProp={true}
                id = "checkList"
              />
            )}

          </StoreFilter>
          {dropDownKey && <ApplyText ref={el => {  refApplyFilter.current = el; }} onClick={() => {
            conditionChecker()
          }} color={selectedSites.length} applyColor={dropDownKey} id="applyText">Apply Filter</ApplyText>}
        </FilterContainer>
      </FilterView>
    );
  }
  else {
    return (
      !isEmpty(sites) ? <> <MobileScorecardFilter
        arrayList={sites}
        mangName = {nameOfManager}
        regionalManagerList = {rmData}
        mobileSelection={(item, index) => mobileSelection(item, index)}
        regionalManagerSelection = {(item,index)=> selectionMethodRmMobileVersion(item,index)}
        siteList={selectedSites}
        sendMobile={() => sendMobile()}
        revertFilterselection = {() => revertFilterselection()}
        filterButtonValue = {filterButton}
        id="mobileScoreCardFilter"
      /></> : <></>
    )
  }
}

function mapStateToProps(state) {
  const { scorecardInfo, authInfo } = state;
  const { scorecardData, error, listOfSites, noSelected, siteNames,regionalManagers,selectedManagerId,selectedManagerName } = scorecardInfo;
  const { userData, languageCode } = authInfo;
  const { name, id } = userData;
  return { scorecardData, error, name, languageCode, listOfSites, noSelected, siteNames,regionalManagers,selectedManagerId,selectedManagerName,id };
}

const mapDispatchToProps = {
  getScorecardSiteData: scorecardActions.getScorecardSiteData,
  getScorecardData: scorecardActions.getScorecardData,
  numberOfSites: (data) => scorecardActions.numberOfSites(data),
  getEmaintananceData: eMaintenanceActions.getEmaintananceData,
  sitesName: (data) => scorecardActions.sitesName(data),
  toggleManagerName: (data)=> scorecardActions.toggleManagerName(data),
  emptyScoreCard: scorecardActions.emptyScoreCard,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ScorecardFilter
);
