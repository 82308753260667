
/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 25/11/2021
* Author : Sunil Vora
*/

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { withTranslation } from 'react-i18next';
import { uniqBy, isEmpty } from 'lodash';
import PageHeader from '../page/PageHeader';
import PageFooter from '../page/PageFooter';
import SiteRankingTable from '../Datatable/Datatable';
import SiteRankingRow from '../SiteRankingRow/SiteRankingRow';
import LineGraph from '../chart/LineGraph';
import { fetchArrayfromLine } from '../filter/utils';
import { tableActions } from '../../actions/tableActions';
import { graphActions } from '../../actions/graphActions';
import { averageScoreActions } from "../../actions/GoogleAverageScoreAction";
import { SORT } from '../Table/Table.constants';
import { TableContainer, SiteListingContainer, FilterButton, FilterText, LineGraphContainer, ReviewView, ViewContainer, HeadingContainer, TitleContainer, TimeContainer, ListingHeaderKey, ListingHeaderValue, FilterByTime, MessagePopup, ExportContainer, ExcelLabel, DownloadIcon, IconImage, BannerText } from './ReviewsDashboard.styled';
import { ExcelSpin } from "../commonStyles/common.styled";
import { REVIEWS_HEADINGS } from '../../constants/googlereviewsdata';
import ScoreComponent from '../scoreComponent/ScoreComponent';
import { TIME_FILTER, COLOR_CODES } from './ReviewsDashboard.constants';
import Filter from '../filter/Filter';
import isMobile from "../../helpers/isMobile";
import TableReview from '../tableReview/TableReview';
import i_download from "../../images/icons/i_download.svg";
import i_download_disabled from "../../images/icons/i_download_disabled.svg";
import { TitleHeader } from "./../page/Page.styled";
const keyBoardValue = window.innerHeight
export class ReviewsDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.counter = 0;
        this.interVal = '';
        this.state = {
            tableFilter: 'YEAR',
            lineGraphFilter: 'YEAR',
            tableReviewFilter: 'YEAR',
            filterQuery: '',
            filterValue: '',
            ratingTable: [],
            ratingStructure: {},
            lineGraphArray: [],
            lineGraphObject: {},
            filterObject: {},
            showColorGraph: [],
            regionalManager: 'ALL',
            siteName: 'ALL',
            showRatingTable: false,
            starRating: false,
            showPopup: false,
            timeFilter: '',
            closeFilter: true,
            previousFilter: false,
            colour: 0,
            filterView: false
        };
    }

    getAverageRatingsByTime(e) {
        const getTimeFilter = TIME_FILTER[e.target.firstChild.textContent];
        const { ratingStructure } = this.state
        if (getTimeFilter !== undefined) {
            this.setState({
                tableFilter: getTimeFilter
            }, () => { this.filterRating(ratingStructure) })
        }
    }

    getlineGraphByTime(e) {
        const getTimeFilter = TIME_FILTER[e.target.firstChild.textContent];
        const { lineGraphObject } = this.state
        if (getTimeFilter !== undefined) {
            this.setState({
                lineGraphFilter: getTimeFilter,
                lineGraphArray: [],
            }, () => { this.lineGraphMethod(lineGraphObject) });
        }
    }

    componentDidMount() {
       
        // document.addEventListener('click', this.handleClickOutside, true);
    }

    componentDidUpdate(prevProps) {
        const { sortState, excelLoader } = this.props;
        const { ratingTable } = this.state;
        if (sortState.order !== prevProps.sortState.order) {
            let result = ratingTable.ratings.reverse();
            this.setState({
                ratingTable: { ...ratingTable, ratings: []}
            },() => this.setState({
                ratingTable: { ...ratingTable, ratings: result}
            }));
        }
        if (excelLoader !== prevProps.excelLoader && excelLoader) {
            this.interVal = setInterval(this.colorChanger, 100);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interVal);
    }

    colorChanger = () => {
        if (this.counter > 2) {
            this.counter = 0;
            this.setState({ colour: COLOR_CODES[this.counter] });
            this.counter += 1;
        }
        else {
            this.setState({ colour: COLOR_CODES[this.counter] });
            this.counter += 1;
        }
    }

    handleClickOutside = (event) => {
        this.setState({ starRating: true })
    }

    handleFilterHover(type, value) {
        this.setState({ showPopup: type === "enter", timeFilter: value });
    }

    renderPopup(message) {
        const { showPopup } = this.state;
        if (showPopup) {
            return (
                <MessagePopup showPopup={showPopup}>
                    <span>{message}</span>
                </MessagePopup>
            );
        }
        return null;
    }

    renderFilters(stateFilter, type) {
        const { t } = this.props;
        const { timeFilter } = this.state;
        return (
            <>
                <FilterByTime onMouseEnter={() => this.handleFilterHover('enter', `${type}-week`)} onMouseLeave={() => this.handleFilterHover('leave', '')} isCurrentFilter={stateFilter === 'WEEK'}>{`1${t("W")}`}
                    {((timeFilter === 'table-week' && type === 'table') || (timeFilter === 'graph-week' && type === 'graph')) && this.renderPopup(t('week_message'))}
                </FilterByTime>
                <FilterByTime onMouseEnter={() => this.handleFilterHover('enter', `${type}-month`)} onMouseLeave={() => this.handleFilterHover('leave', '')} isCurrentFilter={stateFilter === 'MONTH'}>{`1${t("M")}`}
                    {((timeFilter === 'table-month' && type === 'table') || (timeFilter === 'graph-month' && type === 'graph')) && this.renderPopup(t('month_message'))}
                </FilterByTime>
                <FilterByTime onMouseEnter={() => this.handleFilterHover('enter', `${type}-year`)} onMouseLeave={() => this.handleFilterHover('leave', '')} isCurrentFilter={stateFilter === 'YEAR'}>{`1${t("Y")}`}
                    {((timeFilter === 'table-year' && type === 'table') || (timeFilter === 'graph-year' && type === 'graph')) && this.renderPopup(t('year_message'))}
                </FilterByTime>
            </>
        )
    }

    renderRow(row, headings) {
        const id = row.siteName;

        return (
            <SiteRankingRow
                tableId="scheduleDetails"
                key={id}
                id={id}
                filterSelection={this.state.tableFilter}
                siteDetail={row}
                headings={headings}
            />
        );
    }


    setSortState(key, sortState) {
        const order = sortState.order === SORT.DESCENDING ? SORT.ASCENDING : SORT.DESCENDING;
        const updatedSortState = { ...sortState, order };
        this.props.updatedSortState(updatedSortState);
    }

    filterMethod(filterQuery, filterObject) {
        this.setState({ filterQuery: filterQuery, filterObject: filterObject, lineGraphArray: [], filterView: false }, () => {
            Promise.all([this.props.getAverageRatings(filterQuery), this.props.getLineGraphData(filterQuery)]).then(values => {
                const { tableData } = this.props;
                if (tableData.sites) {
                    this.filterRating(tableData);
                }
                this.lineGraphMethod(fetchArrayfromLine(this.props.sitesData, this.state.filterQuery, tableData, this.state.filterObject));
            })
        });
    }

    lineGraphMethod(lineGraph) {
        const uniqueCategories = uniqBy(lineGraph.month.map(item => ({ name: item.name, type: item.type })), 'type');
        const colorGraph = uniqueCategories.map(item => {
            if (item.type === 'Regional Manager') {
                return { name: item.name, className: 'dotRegionalManager' }
            } else if (item.type === 'DODO CS') {
                return { name: item.name, className: 'dotDODOCS' }
            }
            return { name: item.name, className: `dot${item.type}` }
        })
        let selectedLineGraphArray = [];
        if (this.state.lineGraphFilter === 'WEEK') {
            selectedLineGraphArray = lineGraph.day;
        } else if (this.state.lineGraphFilter === 'YEAR') {
            selectedLineGraphArray = lineGraph.month;
        } else {
            selectedLineGraphArray = lineGraph.week;
        }
        this.setState({
            lineGraphArray: selectedLineGraphArray,
            lineGraphObject: lineGraph,
            showColorGraph: colorGraph
        })
    }


    filterRating(ratingTable) {
        let sites = []
        let headings = []
        let ratings = []
        this.setState({
            ratingStructure: ratingTable,
            showRatingTable: true,
            ratingTable: { sites, headings, ratings }
        },() => {
        if (this.state.tableFilter === 'WEEK') {
            let days = ratingTable.rating
            .sort((a, b) => {
                return (b.dayAverage === null ? 0 : Number(b.dayAverage)) - (a.dayAverage === null ? 0 : Number(a.dayAverage));
            })
            headings = ratingTable.dayHeader
            ratings = days
        } else if (this.state.tableFilter === 'YEAR') {
            let months = ratingTable.rating
            .sort((a, b) => {
                return (b.monthAverage === null ? 0 : Number(b.monthAverage)) - (a.monthAverage === null ? 0 : Number(a.monthAverage));
            })
            headings = ratingTable.monthHeader;
            ratings = months
        } else {
            let weeks = ratingTable.rating
            .sort((a, b) => {
                return (b.weekAverage === null ? 0 : Number(b.weekAverage)) - (a.weekAverage === null ? 0 : Number(a.weekAverage));
            })
            headings = ratingTable.weekHeader
            ratings = weeks
        }
        sites = ratingTable.sites;
        this.setState({
            ratingStructure: ratingTable,
            showRatingTable: true,
            ratingTable: {sites, headings, ratings}
        })
    })
    }

    filterButton() {
        return (
            <FilterButton style={{ top: keyBoardValue - 100 }} onClick={() => this.setState({ filterView: true })}>
                <FilterText>Filter</FilterText>
            </FilterButton>
        )
    }

    render() {
        const { t, ratingTableLoader, scoreLoader, graphLoader, googleReviewLoader, excelLoader } = this.props;
        const { ratingTable } = this.state;
        const { headings = [], ratings = [] } = ratingTable;
        const getHeadings = [...REVIEWS_HEADINGS, ...headings];
        const isRegionalManager = !isEmpty(this.state.filterObject.regionalManager);
        const allHeadings = getHeadings.map((key, index) => {
            if (key === 'Site name') {
                return {
                    key,
                    title: t(key),
                    isFontLight: true,
                    index: 0
                }
            } else if (key === 'Avg star rating') {
                return {
                    key,
                    title: t(key),
                    sortable: true,
                    index: 1
                }
            }
            return {
                key,
                title: key,
                highLightColor: index === 2 ? true : false,
                index: index
            }
        });
        return (
            <div>
                {isMobile() ? <TitleHeader id="mobile">
                    <BannerText >{`${t(
                        "google_review_page_text"
                    )} - ${this.props.scoreData?.country?.headerText || ""}`}</BannerText>
                </TitleHeader> : null}
                <ReviewView filterView={this.state.filterView}>
                    {/* <PageHeader isGoogleReviewPage={true} hideView={true} onClick={() => {
                        this.setState({ closeFilter: false })
                    }} /> */}
                    <div className="container-body" >
                        <div className='container'>
                            <Filter filterView={this.state.filterView}
                                keyBoardValue={keyBoardValue}
                                closeFilterMethodMob={() => this.setState({ filterView: false })}
                                closeFilter={this.state.closeFilter} closeFilterMethod={(value) => this.setState({ closeFilter: value })} filterChange={(filterQuery, filterObject) => this.filterMethod(filterQuery, filterObject)} />
                        </div>
                        {isRegionalManager ? (
                            <ExportContainer isRegionalManager={isRegionalManager} className='container' onClick={() => this.props.exportToExcel(this.state.filterQuery, true)} mobile={isMobile()}>
                                <ExcelLabel isRegionalManager={isRegionalManager}>Export</ExcelLabel>
                                <DownloadIcon>{excelLoader ? <ExcelSpin color={this.state.colour} /> : <IconImage src={i_download} alt='download_excel' />}
                                </DownloadIcon>
                            </ExportContainer>
                        ) : (
                            <ExportContainer isRegionalManager={isRegionalManager} className='container'>
                                <ExcelLabel isRegionalManager={isRegionalManager}>Export</ExcelLabel>
                                <DownloadIcon>{excelLoader ? <ExcelSpin color={this.state.colour} /> : <IconImage src={i_download_disabled} alt='download_excel_disabled' />}
                                </DownloadIcon>
                            </ExportContainer>
                        )}
                        <ViewContainer onClick={() => {
                            this.setState({ closeFilter: false })
                        }}>
                            <div className='container'>
                                <ScoreComponent t={t} filterQuery={this.state.filterQuery} />
                                {!isMobile() && this.state.showRatingTable && <SiteListingContainer>
                                    <HeadingContainer>
                                        <TitleContainer>
                                            <ListingHeaderKey>{t("Google reviews")} </ListingHeaderKey>
                                            {ratings.length === 0 && <ListingHeaderValue>{t('please select a regional manager or site to see the ratings')}</ListingHeaderValue>}
                                            {ratings.length > 0 && <ListingHeaderValue>{t(this.state.tableFilter)}</ListingHeaderValue>}
                                        </TitleContainer>
                                        <TimeContainer onClick={e => this.getAverageRatingsByTime(e)}>
                                            {this.renderFilters(this.state.tableFilter, 'table')}
                                        </TimeContainer>
                                    </HeadingContainer>
                                    {ratings.length > 0 && <TableContainer>
                                        <SiteRankingTable
                                            tableId="scheduleDetails"
                                            noResultsEl={t('No data')}
                                            isLayoutFixed
                                            headings={allHeadings}
                                            sortState={this.props.sortState}
                                            getData={() => console.log('data')}
                                            onSort={(key, sortState) => this.setSortState(key, sortState)}
                                        >
                                            {ratings.map(row => this.renderRow(row, allHeadings))}
                                        </SiteRankingTable>
                                    </TableContainer>}
                                </SiteListingContainer>}
                                {!isMobile() && this.state.lineGraphArray.length > 0 &&
                                    <SiteListingContainer>
                                        <HeadingContainer>
                                            <TitleContainer>
                                                <ListingHeaderKey>{t("Google reviews")} </ListingHeaderKey>
                                                <ListingHeaderValue>{t(this.state.lineGraphFilter)}</ListingHeaderValue>
                                            </TitleContainer>
                                            <TimeContainer onClick={e => this.getlineGraphByTime(e)}>
                                                {this.renderFilters(this.state.lineGraphFilter, 'graph')}
                                            </TimeContainer>
                                        </HeadingContainer>
                                        <LineGraphContainer>
                                            <LineGraph lineGraphArray={this.state.lineGraphArray} />
                                        </LineGraphContainer>
                                        <div className='right-align'>
                                            <ul class="list-group  list-group-horizontal-md right-align">
                                                {this.state.showColorGraph.map((item, index) => {
                                                    return (<li class="list-group-item border-0"><span class={item.className}></span>&nbsp;{item.name}</li>)
                                                })}
                                            </ul>
                                        </div>
                                    </SiteListingContainer>}
                                <SiteListingContainer isMobile={isMobile()}>
                                    {!isMobile() &&
                                        <HeadingContainer>
                                            <TitleContainer>
                                                <ListingHeaderKey>{t("Table reviews")}</ListingHeaderKey>
                                                <ListingHeaderValue> {t("review filter value")}</ListingHeaderValue>
                                            </TitleContainer>
                                        </HeadingContainer>}
                                    <TableReview filterObject={this.state.filterObject} starRatingMethod={() => this.setState({ starRating: false })} starRating={this.state.starRating} filterQuery={this.state.filterQuery} tableReviewFilter={this.state.tableReviewFilter} />
                                </SiteListingContainer>
                                <LoadingOverlay active={ratingTableLoader || scoreLoader || graphLoader || googleReviewLoader} className="loaderSpinner" spinner
                                    styles={{
                                        wrapper: {
                                            backgroundColor: 'red',
                                            width: '100%',
                                            height: '90%'
                                        }
                                    }}>
                                </LoadingOverlay>
                            </div>
                        </ViewContainer>
                    </div>
                </ReviewView>
                {isMobile() && !this.state.filterView && this.filterButton()}
                {!this.state.filterView && <PageFooter />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { tableInfo, graphInfo, googleAverageScoreInfo } = state;
    const { scoreLoader, googleReviewLoader, excelLoader, scoreData } = googleAverageScoreInfo;
    const { sitesData, graphLoader } = graphInfo;
    const { tableData, ratingTableLoader, sortState } = tableInfo;
    return { tableData, sitesData, ratingTableLoader, scoreLoader, scoreData, graphLoader, googleReviewLoader, excelLoader, sortState };
}

const mapDispatchToProps = {
    getAverageRatings: (filter, sortState) => tableActions.getAverageRatingsData(filter, sortState),
    updatedSortState: (sortState) => tableActions.getAverageSortResponse(sortState),
    getLineGraphData: (filterQuery) => graphActions.getSiteData(filterQuery),
    exportToExcel: (filterQuery, isExport) => averageScoreActions.getTableReview(filterQuery, isExport)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(ReviewsDashboard));
