import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { TileTop, KpiName, Exportandispace, Exportcontainer, ExportIcon, Infoicon,RightCardView } from '../../style/Customer.styled'
import i_download from "../../../../../images/icons/i_download_white.svg";
import info_gray from '../../Assets/info_gray.svg';
import info_scorecard from '../../../TradingProfitably/Assets/info_scorecard.svg';
import { ExcelSpin } from "../../../../commonStyles/common.styled";
import i_download_white from "../../../../../images/icons/i_download_white.svg";



export const TileHeader = (props) => {
    const {showPopup,handleHover} = props
    const { t } = useTranslation();
    useEffect(() => {

    }, [])

    const exportExcel = () => {
       props.exportToExcel();
      };
  
    const {state,title} = props;
    return (
        <TileTop state = {props.state}>
            <KpiName state = {state}>{title}</KpiName>
           <Exportandispace  width = {state == undefined||props.eMaintenance?"20px":"150px"}>
               {props.eMaintenance ? null: state && 
               <Exportcontainer backgroundcolor={state} onClick={() => exportExcel()}>
               Excel
                 {props.excelLoader &&
                   props.kpiName === title.split(" ")[0] ? (
                     <ExcelSpin
                       color={state === "NEUTRAL" ? "#007833" : "white"}
                     />
                   ) : (
                     <ExportIcon
               id="IconImageExcel"
               src={state === "NEUTRAL" ? i_download : i_download_white}
               alt="scorecard_excel"
                />
                   )}
             </Exportcontainer>
              }
                <RightCardView
                    id="right-card-view-div"
                    onMouseEnter={(e) => handleHover(e, "enter")}
                    onMouseLeave={(e) => handleHover(e, "leave")}
                    onClick={(e) => {
                        showPopup
                            ? handleHover(e, "leave")
                            : handleHover(e, "enter");
                    }}
                >
                   {state === 'NEUTRAL' || state === undefined? <Infoicon src={info_gray} alt='info_icon'/>:<Infoicon usedFor='customer' src = {info_scorecard} alt='info_icon'/>}
                </RightCardView>
            </Exportandispace>
        </TileTop>
    )
}
