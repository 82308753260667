import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { scorecardActions } from '../../../../../actions/scorecardActions';
import DarkSites from '../../../../scorecardNewDesign/SafetyOpertaion/screens/DarkSites';
import { KPINames } from '../../../ScorecardImprovedConstants';
import ScorecardPlaceholder from '../../../ScorecardPlaceholder';



export const DarkSitesComponent = ({ dark_sites, getDarkSitesData , layout}) => {

  useEffect(() => {
    getDarkSitesData(KPINames.DARK_SITES);
  }, []);


  if (dark_sites.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (dark_sites.data) {
    return (
      (<DarkSites data={dark_sites.data} dataFor={"Month"} weekNumber={layout?.data?.weekNumber} />)
    );
  }
  return null;

}

function mapStateToProps(state) {
  const { scorecardImprovedInfo } = state;
  const dark_sites = scorecardImprovedInfo[KPINames.DARK_SITES];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return { layout, dark_sites };
}

const mapDispatchToProps = {
  getDarkSitesData: (kpiUrl) =>
    scorecardActions.getScoreCardContent(kpiUrl),
};

export default connect(mapStateToProps, mapDispatchToProps)(DarkSitesComponent)