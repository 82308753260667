/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/1/2023
 * Author : Aditya Padmanabhan
 */

import React, { useState } from "react";

import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../actions/scorecardActions";
import isMobile from "../../../../helpers/isMobile";
import {
  defaultSortOrder,
  renderSortIcons,
  sortData
} from "../../../Scorecard/utils";
import { KPINames } from "../../../ScorecardImproved/ScorecardImprovedConstants";
import { Olddatatradingapprovee } from "../../TradingProfitably/Styles/TradingUi.styled";
import useWindowDimensions, { responsiveGraphWidth } from "../../TradingProfitably/utils/WindowDimensions";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import ScoreLineGraph from "../../components/ScorecardLineGraph";
import { titleGenerator } from "../../scorecardConstants";
import { returnRequiredRegionalHeader } from "../Utils/customerUtils";
import {
  Actual,
  ActualText,
  Actualpbar,
  DataContainer,
  Exclaimation,
  ExclaimationText,
  Headertradingtable,
  LoyaltyFor,
  MainContainer,
  OldDataBlur,
  OlddataRLcontent,
  Oldtext,
  Oldviewcustomersatisfaction,
  RegionalBox,
  RegionalLoyaltyTable,
  Regionalheadercell,
  ScoreGraphContainer,
  ScoreLegendContainer,
  TooltipValue,
  Whitepbar
} from "../style/Customer.styled";
import { TileHeader } from "./Components/TileHeader";

function RegionalLoyalty(props) {
  const { renderItems, dataFor } = props;
  const { data, graph, state, title, toolTipText, lastWeekUpdated, expectedRefreshTimestamp, headers } = renderItems;
  const headerItems = [...headers, "Loyalty Range", ""];
  const [graphData, setGraphData] = useState([]);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  let headerNames = data?.siteDataComparison?.data?.length > 0 ? defaultSortOrder(data.siteDataComparison.data) : [];
  const [sortedData, setSortedData] = useState(data?.siteDataComparison?.data);
  const [sortState, setSortState] = useState(headerNames);
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [decPos, setDecPos] = useState(1);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
  const [blur, setBlur] = useState(props.weekNumber !== lastWeekUpdated);

  const getGraphData = (scoreSales) => {
    let sales = [];
    scoreSales?.forEach((sale) => {
      if (props.siteNames.length === 0 && !isMobile()) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else if (isMobile() && props.noSelected === 0) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
        ];
      }
    });
    return sales;
  };

  useEffect(() => {
    setGraphData([]);
    async function correctGraph() {
      let graphData = await getGraphData(data.lineGraph)
      setGraphData(graphData);
    }
    correctGraph();
  }, [props, width, blur])


  const giveProgress = (data) => {
    let result = (data.issuance / data.targetIssuance) * 100;
    let color;
    if (result < 100) {
      color = "#e64949";
      return { color, result };
    } else {
      color = "#288f33";
      return { color, result };
    }
  };

  const getHeaderState = () => {
    if (data.averageComparison.data.average.value >= data.averageComparison.data.target.value) {
      return "OK";
    }
    return "DANGER"
  }

  const renderRegionalData = (headerKey, itemData, headerIndex) => {
    let key = returnRequiredRegionalHeader(headerKey);
    if (headerIndex === 0) {
      return (
        <>
          <Regionalheadercell renderedIn="table" index={headerIndex}>
            {itemData[key]}
          </Regionalheadercell>
        </>
      );
    } else if (headerIndex === 4) {
      let calculateValues = giveProgress(itemData);
      return (
        <>
          <Regionalheadercell renderedIn="table" index={headerIndex}>
            <Whitepbar>
              <Actualpbar
                widthPercent={calculateValues.result}
                color={calculateValues.color}
              />
            </Whitepbar>
          </Regionalheadercell>
        </>
      );
    } else if (headerIndex === 5) {
      return (
        <>
          <Regionalheadercell renderedIn="table" index={headerIndex}>

            {itemData["issuance"] && itemData["targetIssuance"] !== 'NA' ? `${itemData["issuance"]}/${itemData["targetIssuance"]}%` : "NA"}
          </Regionalheadercell>
        </>
      );
    }
    return (
      <>
        <Regionalheadercell renderedIn="table" index={headerIndex}>
          {itemData[key] !== "NA" ? `${itemData[key]}%` : "NA"}
        </Regionalheadercell>
      </>
    );
  };

  const sortItem = (keyName, sortState, sortedData) => {
    let result;
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    const rowsWithNA = data.siteDataComparison.data.filter((rows) => rows[keyName] === "NA");
    if (isAnyColumnSorted === "NONE") {
      const initialSortState = Object.keys(data.siteDataComparison.data[0]).map((item) => ({
        name: item,
        sortState: "NONE",
      }));
      result = sortData(
        keyName,
        initialSortState,
        data.siteDataComparison.data.filter((rows) => rows[keyName] !== "NA")
      );
    } else {
      result = sortData(
        keyName,
        sortState,
        sortedData.filter((rows) => rows[keyName] !== "NA")
      );
    }
    const currentState = result.sortedState.find((val) => val.name === keyName);
    setSortState(result.sortedState);
    setSortedData(
      currentState.sortState === "ASC"
        ? [...result.sortingData, ...rowsWithNA]
        : [...rowsWithNA, ...result.sortingData]
    );
  };

  const handleHover = (event, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  function loadExcel() {
    props.exportToExcel(titleGenerator[title], headers, sortedData);
  }

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
      //props.changeScrollEvent();
      //props.changeEvent();
    } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };

  const renderSortIcon = (colName) => {
    return true;
  };

  return (
    <RegionalBox>
      <OldDataBlur boolTrue={blur} id="OldDataBlur">
        {title === 'Waste' || title === 'Till Losses' ? <>
          {
            props.weekNumber - 1 === 0 ?
              <>
                {!blur && 52 !== lastWeekUpdated && (
                  <Exclaimation
                    onMouseEnter={(e) => blurHover(e, "enter")}
                    onMouseLeave={(e) => blurHover(e, "leave")}
                    id="exclamationContainer"
                  >
                    <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                  </Exclaimation>
                )}
              </>
              :
              <>
                {!blur && props.weekNumber - 1 !== lastWeekUpdated && (
                  <Exclaimation
                    onMouseEnter={(e) => blurHover(e, "enter")}
                    onMouseLeave={(e) => blurHover(e, "leave")}
                    id="exclamationContainer"
                  >
                    <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                  </Exclaimation>
                )}
              </>
          }
        </> : <>
          {!blur && props.weekNumber !== lastWeekUpdated && (
            <Exclaimation
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
              id="exclamationContainer"
            >
              <ExclaimationText id="ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
        </>}
        {oldPopUpVisible && (
          <TooltipValue
            id="tooltipvalue"
            top={blurRect.top + 70}
            left={blurRect.left + 15}
          >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
        )}
        <TileHeader
          showPopup={showPopup}
          exportToExcel={() => loadExcel()}
          excelLoader={props.excelLoader}
          kpiName={props.kpiName}
          handleHover={(e, value) => handleHover(e, value)}
          title={title}
          state={getHeaderState()}
        />
        {showPopup && (
          <ScorePopupContainerWeb
            top={top}
            left={left - 20}
            width={18}
            id='popUpDesktop'
            textDescription={toolTipText}
          />
        )}
        <ScoreGraphContainer id="CS">
          <ScoreLegendContainer id="ScoreLegendContainer" usedIn='Regional'>
            <LoyaltyFor>Loyalty score:</LoyaltyFor>
            {!isMobile() && props.siteNames.length === 0 && (
              <>

                <Actual
                  id="actual-text"
                  isDanger={getHeaderState()}
                  isActualMe={true}
                ></Actual>
                <ActualText id="actual-text">
                  Actual UK
                </ActualText>{" "}
              </>
            )}

            {!isMobile() && (
              <>
                <Actual state={state}></Actual>
                <ActualText
                  id="actual-text"
                  isTill
                  Losses={title === "Till Losses"}
                >
                  {props.siteNames.length === 0
                    ? props.selectedManagerName
                    : props.siteNames.length === 1
                      ? props.siteNames[0]
                      : t("Selected Sites")}
                </ActualText>
              </>
            )}

            {isMobile() && props.siteNames.length === 0 && (
              <>
                <Actual state={state}></Actual>
                <ActualText id="actual-text" isLosses={title === "Till Losses"}>
                  Actual UK
                </ActualText>
              </>
            )}

            {isMobile() && (
              <>
                <Actual
                  id="actual-text"
                  isDanger={state === "DANGER"}
                  isActualMe={true}
                ></Actual>
                <ActualText
                  id="actual-text"
                  isTill
                  Losses={title === "Till Losses"}
                >
                  {props.noSelected === 0
                    ? props.selectedManagerName
                    : props.siteNames.length === 1
                      ? props.siteNames[0]
                      : t("Selected Sites")}
                </ActualText>
              </>
            )}
          </ScoreLegendContainer>
          {!isEmpty(graphData) ? (
            <ScoreLineGraph
              regionalManager={props.selectedManagerName}
              isDanger={getHeaderState() === "DANGER"}
              scoreGraph={true}
              xScale={responsiveGraphWidth(width, "regional")}
              graphId={`scoreCard-${title.replace(/[^a-zA-Z]/g, "")}`}
              graphClass={`scoreGraph-${title.replace(/[^a-zA-Z]/g, "")}`}
              sales={graphData}
              title={title}
              state={getHeaderState()}
              data={dataFor}
              selection={props.noSelected}
              siteLength={props.siteNames.length}
              device={isMobile()}
              id="Financial_graph"
              scroll={props.event}
              decPos={decPos}
              boolValue={blur}
            />
          ) : null}
        </ScoreGraphContainer>
        <RegionalLoyaltyTable>
          <Headertradingtable>
            {headerItems.map((item, index) => {
              return (
                <>
                  <Regionalheadercell
                    onClick={() =>
                      sortItem(
                        returnRequiredRegionalHeader(item),
                        sortState,
                        sortedData
                      )
                    }
                    index={index}>{item}
                    {renderSortIcons(
                      returnRequiredRegionalHeader(item),
                      sortState,
                      true,
                      "NEUTRAL"
                    )}
                  </Regionalheadercell>
                </>
              );
            })}
          </Headertradingtable>
          <MainContainer height={200}>
            {!isEmpty(sortedData)
              ? sortedData.map((itemData, index) => {
                return (
                  <DataContainer
                    key={index}
                    alignIndex={index}
                    id="dataContainer"
                  >
                    {headerItems.map((headerKey, headerIndex) =>
                      renderRegionalData(headerKey, itemData, headerIndex)
                    )}
                  </DataContainer>
                );
              })
              : null}
          </MainContainer>
        </RegionalLoyaltyTable>
      </OldDataBlur>
      {blur && (
        <Oldviewcustomersatisfaction id="OldDataFullview">
          <OlddataRLcontent id="olddatacontent">
            <Oldtext id="old">You're viewing old data</Oldtext>
            <Oldtext id="data_not_updated_text">This data has not been updated yet.</Oldtext>
            <Oldtext id="oldtext">
              Scheduled updates occurs on&nbsp;{props.loyaltyIssuanceData}
            </Oldtext>
            <Olddatatradingapprovee id="olddataapprove" onClick={() => setBlur(!blur)}>
              continue with old data
            </Olddatatradingapprovee>
          </OlddataRLcontent>
        </Oldviewcustomersatisfaction>
      )}
    </RegionalBox>
  );
}

const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
    scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

const mapStateToProps = (state) => {
  const { scorecardInfo, loader, authInfo, scorecardImprovedInfo } = state;
  const { userData } = authInfo;
  const { showLoader } = loader;
  const { excelLoader, kpiName, siteNames, noSelected, selectedManagerName } =
    scorecardInfo;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  const loyaltyIssuanceData = layout?.data?.schedules?.loyaltyIssuanceData;
  return {
    excelLoader,
    kpiName,
    showLoader,
    siteNames,
    noSelected,
    selectedManagerName,
    userData,
    loyaltyIssuanceData
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(RegionalLoyalty)
);

