/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 06/01/2023
 * Author : Aditya Padmanabhan
 */

import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { adminActions } from "../../actions/adminActions";
import EmaintenaceLoading from "../E-Maintenance/EmaintenaceLoading";
import { Fileheader } from "./AdminConstants";
import {
  FileTable,
  FileTablecontent,
  FileTableHeader,
  HighlightLogText,
  LogMesaageBox,
  Logstable,
  Perrowupload,
  StatusOfUpload,
  SubmitFilebutton,
  TableAlignment,
} from "./AdminDashboard.styled";

function SiteDataUpload(props) {
  let headerLogs = ["TIMESTAMP", "DESCRIPTION", "STATUS"];
  const data = props.data;
  let [rowHightlight, setRowHighlight] = useState(0);
  let [loadLog, setLoadLog] = useState(false);
  let [logs, setLogs] = useState([]);
  // const messagesEndRef = useRef(null);
  let [uploadFlag, setUploadFlag] = useState(true);
  const [intervalCheck, setIntervalCheck] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    setLogs(props.siteLogs);
    if (props.siteLogs.length > 0) {
      setLoadLog(true);
      let res = props.siteLogs.filter(
        (item) => item.status === "FAILED" || item.status === "COMPLETED"
      );
      if (res.length > 0) {
        setUploadFlag(true);
        document.getElementById("submitButton").disabled = false;
        let newSetData = [];
        props.siteUpdateExe(newSetData);
      } else {
        setUploadFlag(false);
        document.getElementById("submitButton").disabled = true;
      }
    }
  }, [props.siteLogs]);

  useEffect(() => {
    if (!isEmpty(props.siteExeId)) {
      clearInterval(intervalCheck);
      let id = setInterval(() => {
        setIntervalCheck(id);
        props.siteDatalogFetch(props.siteExeId);
      }, 40000);
    } else {
      clearInterval(intervalCheck);
    }
    return () => {
      clearInterval(intervalCheck);
    };
  }, [props.siteExeId]);

  function changeHigLight(key) {
    setRowHighlight(key);
  }

  const gotoDashboard = (url) => {
    if (url === "/") {
      return;
    } else {
      window.open(url);
    }
  };

  function upload() {
    let response = {
      fileName: data[rowHightlight].fileName,
    };
    setLoadLog(true);
    document.getElementById("submitButton").disabled = true;
    props.uploadData(response, true).then(
      (data) => {
        let allExecutionID = [data.execution_id];
        props.siteUpdateExe(allExecutionID);
        setUploadFlag(false);
      },
      () => {
        document.getElementById("submitButton").disabled = false;
      }
    );
  }

  return (
    <>
      {data.length > 0 && (
        <FileTable>
          <TableAlignment>
            {Fileheader.map((item) => {
              return <FileTableHeader>{item}</FileTableHeader>;
            })}
          </TableAlignment>
          <TableAlignment id="values">
            {data.map((item, Index) => {
              return (
                <Perrowupload
                  key={Index}
                  toBehighlighted={Index === rowHightlight ? true : false}
                  onClick={() => changeHigLight(Index)}
                >
                  <FileTablecontent
                    onClick={() => gotoDashboard(item?.linkingUrl)}
                  >
                    {item.fileName}
                  </FileTablecontent>
                  <FileTablecontent>{item.size}</FileTablecontent>
                  <FileTablecontent>{item.lastModifiedTime}</FileTablecontent>
                </Perrowupload>
              );
            })}
          </TableAlignment>
          <SubmitFilebutton
            id="submitButton"
            color={uploadFlag}
            onClick={() => uploadFlag && upload()}
          >
            Submit
          </SubmitFilebutton>
          {loadLog && (
            <StatusOfUpload>
              <TableAlignment>
                {headerLogs.map((item) => {
                  return <FileTableHeader>{item}</FileTableHeader>;
                })}
              </TableAlignment>
              <Logstable>
                {logs.length > 0 ? (
                  logs.map((item) => {
                    return (
                      <TableAlignment id="values">
                        <Perrowupload>
                          <FileTablecontent>
                            {item.message.includes("is successful") ||
                            item.message.includes("failed") ||
                            item.message.includes("successfully") ? (
                              <HighlightLogText>{`${item.timestamp}`}</HighlightLogText>
                            ) : (
                              ` ${item.timestamp}`
                            )}
                          </FileTablecontent>
                          <FileTablecontent>
                            {item.message.includes("is successful") ||
                            item.message.includes("failed") ||
                            item.message.includes("successfully") ? (
                              <HighlightLogText>{`${item.message}`}</HighlightLogText>
                            ) : (
                              ` ${item.message}`
                            )}
                          </FileTablecontent>
                          <FileTablecontent>{item.status}</FileTablecontent>
                        </Perrowupload>
                      </TableAlignment>
                    );
                  })
                ) : (
                  <EmaintenaceLoading />
                )}
              </Logstable>
            </StatusOfUpload>
          )}
        </FileTable>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { adminInfo } = state;
  const { siteLogs, siteExeId } = adminInfo;
  return { siteLogs, siteExeId };
}

const mapDispatchToProps = {
  uploadData: (data, check) => adminActions.uploadData(data, check),
  siteDatalogFetch: (data) => adminActions.siteDatalogFetch(data),
  siteUpdateExe: (data) => adminActions.siteUpdateExe(data),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(SiteDataUpload)
);
