/* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 25/11/2021
* Author : Sunil Vora
*/

import styled from 'styled-components';

export const DataTableStyled = styled.div``;

export const EmptyTableStyled = styled.div`
  
   display:flex;
   height: 17em;
   align-items:center;
   justify-content:center;
`;
export const EmptyText = styled.span`
font-family: Roboto-Regular;
  font-size: 22px;`
