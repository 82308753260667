/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SelectionText, DropDownContainerRm,SelectionContainerRm } from './button.Styled'
import tick_box from "../../images/icons/tick-box.png";
import {capitalize } from '../Scorecard/utils';

export function RMSelectionList(props) {
    const { t } = useTranslation();
    const { arrayList, selectedValue, selectedMethod } = props
    useEffect(() => {

    }, []);
    return (
        <DropDownContainerRm data-test="drop-down" style={{ width: props.width, }}>
            {arrayList.map((item, index) => {
                return (<SelectionContainerRm key={index} data-test="selection-cell"
                  onClick={()=>selectedMethod(item, index)}>
                    <SelectionText data-test="selection-text">{capitalize(item.regionalManagerName)}</SelectionText>
                    </SelectionContainerRm>)

            }
            )}
        </DropDownContainerRm>
    )
}

export default RMSelectionList
