import { scorecardServices } from "../services/scorecardServices";
import { constants } from "../constants/reducerConstants";
import { getLogoutUrl, startLoader, stopLoader } from '../helpers/commons';
import { dispatch } from "d3";
import { scorecardReducerConstants } from "../constants/reducerConstants";
import { KPIUrls } from "../components/ScorecardImproved/ScorecardImprovedConstants";
import { isEmpty } from "lodash";
import { changeEmainData, eMaintenanceActions, getEmaintananceData } from "./eMaintenanceAction";

export const scorecardActions = {
  getScorecardData,
  getScorecardSiteData,
  numberOfSites,
  sitesName,
  toggleManagerName,
  emptyScoreCard,
  getScoreCardContent,
  siteNameSet,
  getScorecardDataExport
};

function getScorecardData(kpiName, headers, sortedData, sites) {
  return (dispatch, getState) => {
    const { authInfo, scorecardInfo } = getState();
    const { selectedManagerName } = scorecardInfo
    if (kpiName) {
      dispatch({ type: constants.SCORECARD_DOWNLOAD_LOADER_START, data: kpiName });
    } else {
      // dispatch(startLoader());
    }
    return scorecardServices
      .getscorecardData(authInfo.sessionData.id_token, authInfo.languageCode, kpiName, headers, sortedData, sites, selectedManagerName)
      .then(
        (data) => {
          if (!data.body.preSignedUrl) {
            dispatch({ type: constants.SCORECARD_SUCCESS, data });
          } else {
            dispatch({ type: constants.SCORECARD_DOWNLOAD_LOADER_DONE });
          }
          return data;  
        },
        (error) => {
          dispatch({ type: constants.SCORECARD_FAILURE, error });
          dispatch({ type: constants.SCORECARD_DOWNLOAD_LOADER_DONE });
          if (error && error.message === 'Failed to fetch') {
            dispatch({ type: constants.CLEAR_AUTH_STORE });
            window.location.href = getLogoutUrl(authInfo.languageCode);
          }
        }
      );
  };
}

function getScorecardDataExport(kpiName, headers, sortedData) {
  return (dispatch, getState) => {
    const { authInfo, scorecardInfo } = getState();
    const { selectedManagerName } = scorecardInfo
    if (kpiName) {
      dispatch({ type: constants.SCORECARD_DOWNLOAD_LOADER_START, data: kpiName });
    } 
    return scorecardServices
      .getscorecardExport(authInfo.sessionData.id_token, authInfo.languageCode, kpiName, headers, sortedData, selectedManagerName)
      .then(
        (data) => {
          if (data.body.preSignedUrl) {
            dispatch({ type: constants.SCORECARD_DOWNLOAD_LOADER_DONE });
          } 
         
        },
        (error) => {
          dispatch({ type: constants.SCORECARD_DOWNLOAD_LOADER_DONE });
        }
      );
  };
}

function emptyScoreCard() {
  return (dispatch) => {
    dispatch({ type: constants.EMPTY_SCORECARD })
  }
}

function getScorecardSiteData() {
  return (dispatch, getState) => {
    const { authInfo, scorecardInfo } = getState();
    const { selectedManagerName } = scorecardInfo
    dispatch(startLoader())
    return scorecardServices
      .getscorecardSiteData(authInfo.sessionData.id_token, authInfo.languageCode, selectedManagerName)
      .then(
        (data) => {
          dispatch({ type: constants.FETCH_LIST_OF_SITE, data });
          dispatch(stopLoader());
          return data;
        },
        (error) => {
          dispatch(stopLoader());
          dispatch({ type: constants.SCORECARD_FAILURE, error });
          dispatch({ type: constants.SCORECARD_DOWNLOAD_LOADER_DONE });
          if (error && error.message === 'Failed to fetch') {
            dispatch({ type: constants.CLEAR_AUTH_STORE });
            window.location.href = getLogoutUrl(authInfo.languageCode);
          }
        }
      );
  };
}

function numberOfSites(data) {
  return (dispatch) => {
    dispatch({ type: constants.CHANGE_NUMBER_OF_SITES, data })
  }
}

function sitesName(data) {
  return (dispatch) => {
    dispatch({ type: constants.SITE_NAMES, data })
  }
}

function siteNameSet(data){
  return (dispatch) => {
    dispatch ({type: constants.SET_SITES, data})
  }
}

function toggleManagerName(data) {
  return (dispatch) => {
    dispatch({ type: constants.SELECTED_MANAGER_NAME, data })
  }
}


function getScoreCardContent(kpiName) {
  let request
  const reducerConst = scorecardReducerConstants[kpiName];
  return (dispatch, getState) => {
    const { authInfo, scorecardInfo } = getState();
    const {selectedManagerName, selectedManagerId, siteNames, sites} = scorecardInfo;
    if(isEmpty(siteNames)){
        request = {
          sapIds: [],
          siteNames: [],
          regionalManagerId: selectedManagerId,
          regionalManagerName: selectedManagerName,
        };
    }
    else{
      let sapIdArr = [];
      let sapNameArr = [];
        sites?.forEach((item) => {
          sapIdArr.push(item.sapId);
          sapNameArr.push(item.siteName);
        });
         request = {
          sapIds: sapIdArr,
          siteNames: sapNameArr,
          regionalManagerId: selectedManagerId,
          regionalManagerName: selectedManagerName,
        };
    }
    dispatch({ type: reducerConst.LOADER_START, data: {}, kpi: kpiName });
    return scorecardServices
      .getScorecardContentData(authInfo.sessionData.id_token, authInfo.languageCode, KPIUrls[kpiName], request)
      .then(
        (data) => {
          dispatch({ type: reducerConst.SUCCESS, data, kpi: kpiName });
          dispatch({ type: reducerConst.LOADER_DONE, kpi: kpiName });
        },
        (error) => {
          dispatch({ type: reducerConst.FAILURE, error, kpi: kpiName });
          dispatch({ type: reducerConst.LOADER_DONE, kpi: kpiName });
          if (error && ((error.errorMessage === 'The incoming token has expired') || (error.errorMessage === 'Failed to fetch'))) {
            dispatch({ type: constants.CLEAR_AUTH_STORE });
            window.location.href = getLogoutUrl(authInfo.languageCode);
          }
        }
      );
  };

}