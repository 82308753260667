/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { adminActions } from "../../actions/adminActions";
import green_tick from "../../images/icons/green_tick.svg";
import icon_cross from "../../images/icons/icon_cross.svg";
import pencil_green from "../../images/icons/pencil_green.svg";
import pencil_grey from "../../images/icons/pencil_grey.svg";
import tick_box from "../../images/icons/tick-box.png";
import trash_red_icon from "../../images/icons/trash_red_icon.svg";
import { Linksectionlinks, Linksectionoptions } from "../AdminDashboard/AdminConstants";
import { DropDown } from '../dropDown/dropdown';
import { BodyContainer, Checkbox, Container, EditIcons, ErrorMessage, HorizontalList, Iconsoptions, InputContainer, InputView, LeftAlign, ListName, ListURL, ListView, Listcategory, Listrole, Listtile, NoSearchresult, Selectionicons, SitesContainer } from './sites.styled';

export function SitesComponent(props) {
  const { t } = useTranslation();
  const [header, setHeader] = useState(props.siteheader);
  const [listValues, setListValues] = useState(props.listDetails);
  const [edit, setEdit] = useState([]);
  const [tileSection, setTileSection] = useState('');
  let [url, setUrl] = useState('')
  let [category, setCategory] = useState('')
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [id, setId] = useState("")
  const [errorName, setErrorName] = useState(false)
  const [errorId, setErrorId] = useState(false)
  const userData = ['Link', 'Tool']
  const [tileDropDown, setTileDropDown] = useState([])
  const [openDropdown, setOpenDropdown] = useState(false)
  const [openCountryDropDown, setOpenCountryDropDown] = useState(false)
  const [openDropdowntile, setOpenDropdownTile] = useState(false)
  let [mouseCategory, setMouseCategory]= useState("");
  let [mouseTile, setMouseTile] = useState("")
  let refDropDown = useRef(null)
  

  useEffect(() => {
   setListValues(props.listDetails);
   if(!isEmpty(props.allViews)){
      let views = [...props.allViews]
      let presentView = props.presentView
      let completeData= {...props.viewMangerData}
      if(views.indexOf(presentView)!== -1){
        let dropDownValues = completeData[presentView].tileNames
        dropDownValues = [...dropDownValues, Linksectionlinks[0]]
        setTileDropDown(dropDownValues)
      }
      else{
        let dropDownValues = Linksectionoptions
        setTileDropDown(dropDownValues)
      }
  }
  }, [props.listDetails, props.presentView]);

   useEffect(()=>{
    if(openDropdown){
        window.addEventListener("click", (e)=>{
          setMouseCategory(e.pageY + 5);
      });
      
  }
   return ()=>{
    window.removeEventListener("click",()=>{
    })
   }
  },[openDropdown])

   // useEffect for close dropdown outside for allRoles
   useEffect(() => {
    const closedropdown = (event) => {
      if ((refDropDown?.current && !refDropDown?.current.contains(event?.target))) {
        props.onClickDropDownOutside();
      }
    }
    document.addEventListener('click', closedropdown, true)
    return () => {
        document.removeEventListener('click', closedropdown, true);
    };
}, [refDropDown]);

   useEffect(() => {
     if (openDropdowntile) {
       window.addEventListener("click", (e) => {
         setMouseTile(e.pageY + 5);
      });
     }
     return () => {
       window.removeEventListener("click", () => {
      });
     };
   }, [openDropdowntile]);

  function headerMethod() {
    return (
      <HorizontalList>
        {Object.values(header).map((itemKey, index) => (
          <ListView index={index}>
            <ListName>{itemKey}</ListName>
          </ListView>
        ))}
      </HorizontalList>
    )
  }
  function changeEditable(index) {
    if(!props.editLinkCheck){
      if (index.editKey) {
        index.editKey = false;
      }
      else {
        index.editKey = true
      }
      props.changeStatus(index)
    }
  }

  function onViewClick(index) {
    setOpenDropdown(openDropdown === index ? false : index)
  }

  function onViewClickTile(index) {
    setOpenDropdownTile(openDropdowntile === index ? false : index)
  }

  function toAllowEdit(index){
    if(props.editLinkCheck){
      if (index.editKey) {
        index.editKey = false;
      }
      else {
        index.editKey = true
      }
      props.changeStatus(index)
    }
  }

  function onCloseExecution(item, name, index, s, tile, url) {
    toAllowEdit(item);
      let dd = {}
      dd.name = name;
      dd.category = s;
      dd.tile = tile;
      dd.url = url;
      setName('');
      setCategory('');
      setTileSection('');
      setUrl('');
      props.changeRowLink(item, dd);
    
  }

  function onDiscard(item){
    toAllowEdit(item)
    if(item.name === "Please enter link name"){
      props.removeLink(item)
    }
  }

  function itemUiMethod(item, itemKey, index) {
    switch (itemKey) {
      case 'name':
        return (!item.editKey ? <ListName>{item[itemKey]}</ListName> : <InputView>
          {errorName && name.length === 0 && <ErrorMessage>* Please add any name</ErrorMessage>}
          <InputContainer type='text' errorUI={errorName && name.length === 0} placeholder={item[itemKey]} value={name} onChange={(e) => setName(e.target.value.toUpperCase())} />
        </InputView>);
      case 'id':
        return (!item.editKey ? <Listtile>{item[itemKey]}</Listtile> : <InputView>
          {errorId && id.length === 0 && <ErrorMessage>* Email ID required</ErrorMessage>}
          <InputContainer type='text' errorUI={errorId && id.length === 0} placeholder={'Enter Email Id'} value={id} onChange={(e) => setId(e.target.value)} />
        </InputView>);
      case 'url':
        return (!item.editKey ? <ListURL>{item[itemKey]}</ListURL> : <><input type='text' placeholder={item[itemKey]} onChange={(e) => setUrl(e.target.value)} /></>);
      case 'role':
        return <Listrole>{item[itemKey] === 'regional_manager' ? 'RM (' + item['channelOfTrade'] + ')' : <LeftAlign id ={item[itemKey]}>{item[itemKey]}</LeftAlign>}</Listrole>;
      case 'category':
        return !item.editKey ? (
          <Listcategory>{item[itemKey]}</Listcategory>
        ) : (
          <div>
           <DropDown
              styleValues={"viewFilterButtonStyle filterWidth"}
              filterWidth={116}
              radioButton={true}
              dropDownKey={openDropdown === index ? true : false}
              arrayList={userData}
              selectedItem={category.length===0?item[itemKey]:category}
              onClick={() => onViewClick(index)}
              itemSelection={(item) => setCategory(item)}
              alignHeight = {mouseCategory}
              selectedViewText={category.length===0?item[itemKey]:category}
              scrollView= {true}
             />
          </div>
        );
      case 'tile':
        return (!item.editKey ? <Listtile id='tile'>{item[itemKey]}</Listtile> : <div>
          <DropDown
              styleValues={"viewFilterButtonStyle viewFilterWidth"}
              filterWidth={145}
              selectedItem={tileSection.length===0?item[itemKey]:tileSection}
              arrayList={tileDropDown}
              dropDownKey={openDropdowntile === index ? true : false}
              selectedViewText={tileSection.length===0?item[itemKey]:tileSection}
              radioButton={true}
              onClick={() => onViewClickTile(index)}
              itemSelection={(item) => setTileSection(item)}
              alignHeight = {mouseTile}
              scrollView= {true}
            />
        </div>);
      case 'icon':
        return (!item.editKey ?
          <Selectionicons>{props.editLinkCheck?<> <Iconsoptions src={pencil_grey} alt='tick'/> <Iconsoptions src={trash_red_icon} alt='tick'  onClick={() => props.deletelink(item, index)} /></>:<><Iconsoptions src={pencil_green} alt='tick'  onClick={() => changeEditable(item)} /><Iconsoptions src={trash_red_icon} alt='tick'  onClick={() => props.deletelink(item, index)} /></>}</Selectionicons> : <Selectionicons><Iconsoptions src={green_tick} alt='tick' 
          onClick={() => onCloseExecution(item, name, index, category, tileSection, url)} /><Iconsoptions src={icon_cross} alt='tick'  onClick={() => onDiscard(item)} /></Selectionicons>)
      case 'view':
        return (
          <div style={{paddingLeft:"0.4em"}}>
            <DropDown
              styleValues={"viewFilterButtonStyle viewFilterWidth"}
              filterWidth={145}
              selectedItem={item[itemKey]}
              arrayList={props.viewList}
              regionalManager={item['role']}
              dropDownKey={props.viewSiteDropDown === index ? true : false}
              noStarProp={true}
              selectedViewText={item[itemKey]}
              radioButton={true}
              onClick={() => props.onViewClick(index)}
              scrollView= {true}
              itemSelection={(itemView, indexView) => props.userFilterSelection(itemView, item, indexView, index, "assignedView")}
              alignHeight = {props.mousePoint}
            />
          </div>
        )
      case 'countryCode':
        return (
            <div style={{paddingLeft:"0.4em"}}>
              <DropDown
                styleValues={"viewFilterButtonStyle viewFilterWidth"}
                filterWidth={145}
                selectedItem={item[itemKey]}
                arrayList={props.countryList}
                regionalManager={item['countryCode']}
                dropDownKey={props.viewCountryDropDown === index ? true : false}
                noStarProp={true}
                selectedViewText={item[itemKey]}
                radioButton={true}
                onClick={() => props.onCountryClickTable(index)}
                scrollView= {true}
                itemSelection={(itemView, indexView) => props.userFilterSelection(itemView, item, indexView, index,"countryCode")}
                alignHeight = {props.mousePoint}
              />
            </div>
          )
      case 'admin':
        return (
          <div onClick={() => props.loginUser !== item['id'] && props.adminMethod(item, index, itemKey)}>
            {item[itemKey] === 'true' ? <img src={tick_box} alt='tick' height={20} width={20} /> : <Checkbox />}
          </div>);
      
      case 'delete':
        return (
          <Container>
            {item.editKey ? <EditIcons>
              <div style={{ paddingTop: 10 }} onClick={() => userSubmitButton()}>
                <img src={green_tick} height={20} width={20} />
              </div>
              <div style={{ paddingTop: 10 }} onClick={() => discardNewUser()}>
                <img src={icon_cross} alt='tick' height={20} width={20} />
              </div>
            </EditIcons> :
              <div onClick={() => props.deleteSite(item, index)}>
                {item["role"] === 'RM (CO)' && <img width={14} height={15} src={trash_red_icon} alt="trash_red_icon" />}
              </div>}
          </Container>);
      case 'title':
        return <ListName>{item[itemKey]}</ListName> 

      default:
        return "default"
    }
  }

  function discardNewUser() {
    setErrorName(false)
    setErrorId(false)
    setName('')
    setId('')
    props.discardNewUser()
  }

  function userSubmitButton() {
    if (name.length === 0) {
      setErrorName(true)
    }
    if (id.length === 0) {
      setErrorId(true)
    }
    if (name.length > 0 && id.length > 0) {
      setErrorName(false)
      setErrorId(false)
      props.submitNewUser(name, id)
      setName('')
      setId('')
    }
  }

  function handleScroll(value) {
    if(value){
      onViewClick(false);
      onViewClickTile(false);
    }
    else{
      props.onViewClick(false);
    }

  }

  return (
    <SitesContainer>
      {headerMethod()}
      <BodyContainer onScroll={() => handleScroll(props.viewComponent)}>
        {!props.searchResult? listValues.map((item, index) => {
          return (
            <HorizontalList index={index} ref={el => { refDropDown.current = el; }} >
              {Object.keys(header).map((itemKey, indexKey) => (
                <ListView index={indexKey}>
                  {itemUiMethod(item, itemKey, index)}
                </ListView>)
              )}
            </HorizontalList>
          )
        }):<NoSearchresult>{props.no_data_result}</NoSearchresult>}
      </BodyContainer>
    </SitesContainer>
  )
}

function mapStateToProps(state) {
  const { adminInfo} = state;
  const {editLinkCheck, allViews,presentView,viewMangerData} = adminInfo;
   return { editLinkCheck,allViews,presentView,viewMangerData};
}

const mapDispatchToProps = {
 changeEdit: ()=>adminActions.changeEdit()
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SitesComponent);
