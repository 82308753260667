/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/5/2023
 */

import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../actions/scorecardActions";
import { KPINames } from "../../../ScorecardImproved/ScorecardImprovedConstants";
import { TileHeader } from "../../Customer/Screens/Components/TileHeader";
import { returnRequiredRegionalHeader } from "../../Customer/Utils/customerUtils";
import {
  Actualpbar,
  Colorgradient,
  Exclaimation, ExclaimationText,
  Loyaltyheader,
  Loyaltyperc,
  Loyaltyscore,
  OldDataBlur,
  Oldtext, Oldviewcustomersatisfaction,
  Ravg,
  Scoreboxtitle,
  Targetscore,
  TooltipValue,
  Whitepbar
} from "../../Customer/style/Customer.styled";
import { Olddatatradingapprovee } from "../../TradingProfitably/Styles/TradingUi.styled";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import { titleGenerator } from "../../scorecardConstants";
import { defaultSortOrder, getFieldFromKey, renderSortIcons, sortData } from "../../utils";
import {
  ClockingOnTimeTable,
  ClockingOnTimetableData,
  ClockingTimeContainer,
  Clockingheadercell,
  Clockingspace,
  OldClockingcontent
} from "../Style/PeopleUi.styled";

function ClockingTime(props) {
  const { data, weekNumber, complianceData } = props;
  let renderScoreData = data.data["rmTarget"];
  let rmData = data.data;
  const { expectedRefreshTimestamp, lastWeekUpdated, title, toolTipText, state, headers } = data;
  let headerValue = [...headers, "graph"]
  let headerNames = rmData?.length > 0 ? defaultSortOrder(rmData) : [];
  const [sortedData, setSortedData] = useState(rmData);
  const [sortState, setSortState] = useState(headerNames);
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [site, setSite] = useState("");
  const [rect, setRect] = useState(0);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
  const [blur, setBlur] = useState(weekNumber !== lastWeekUpdated);


  const sortItem = (keyName, sortState, sortedData) => {
    let result;
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    const rowsWithNA = rmData.filter((rows) => rows[keyName] === "NA");
    if (isAnyColumnSorted === "NONE") {
      const initialSortState = Object.keys(rmData[0]).map((item) => ({
        name: item,
        sortState: "NONE",
      }));
      result = sortData(
        keyName,
        initialSortState,
        rmData.filter((rows) => rows[keyName] !== "NA")
      );
    } else {
      result = sortData(
        keyName,
        sortState,
        sortedData.filter((rows) => rows[keyName] !== "NA")
      );
    }
    const currentState = result.sortedState.find((val) => val.name === keyName);
    setSortState(result.sortedState);
    setSortedData(
      currentState.sortState === "ASC"
        ? [...result.sortingData, ...rowsWithNA]
        : [...rowsWithNA, ...result.sortingData]
    );
  };

  function loadExcel() {
    props.exportToExcel(titleGenerator[title], headers, rmData);
  }

  const handleHover = (event, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  const giveProgress = (data) => {
    let result = data;
    let color;
    if (result < 50) {
      color = "#e64949";
      return { color, result };
    } else if (result > 50 && result < 70) {
      color = "#ffc000";
      return { color, result };
    } else {
      color = "#288f33";
      return { color, result };
    }
  };

  const handleSiteHover = (event, value, type) => {
    if (type === "leave") {
      setSite(value);
    }
    if (event.target.scrollHeight >= event.target.clientHeight) {
      setSite(value);
      setRect(event.target.getBoundingClientRect());
    }
  };

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
    } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };

  const colorDecider = (keyName, sortState) => {
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    if (isAnyColumnSorted === 'NONE') {
      return ""
    }
    else if (isAnyColumnSorted === 'ASC') {
      return "#007833"
    }
    else {
      return "#8B0000"
    }
  }

  return (
    <ClockingTimeContainer>
      <OldDataBlur boolTrue={blur} id="OldDataBlur">
        {title === 'Waste' || title === 'Till Losses' ? <>
          {
            props.weekNumber - 1 === 0 ?
              <>
                {!blur && 52 !== lastWeekUpdated && (
                  <Exclaimation
                    onMouseEnter={(e) => blurHover(e, "enter")}
                    onMouseLeave={(e) => blurHover(e, "leave")}
                    id="exclamationContainer"
                  >
                    <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                  </Exclaimation>
                )}
              </>
              :
              <>
                {!blur && props.weekNumber - 1 !== lastWeekUpdated && (
                  <Exclaimation
                    onMouseEnter={(e) => blurHover(e, "enter")}
                    onMouseLeave={(e) => blurHover(e, "leave")}
                    id="exclamationContainer"
                  >
                    <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                  </Exclaimation>
                )}
              </>
          }
        </> : <>
          {!blur && props.weekNumber !== lastWeekUpdated && (
            <Exclaimation
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
              id="exclamationContainer"
            >
              <ExclaimationText id="ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
        </>}
        {oldPopUpVisible && (
          <TooltipValue
            id="tooltipvalue"
            top={blurRect.top + 70}
            left={blurRect.left + 15}
          >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
        )}
        <TileHeader
          showPopup={showPopup}
          exportToExcel={() => loadExcel()}
          excelLoader={props.excelLoader}
          kpiName={props.kpiName}
          handleHover={(e, value) => handleHover(e, value)}
          title={title}
          state={data.state}
        />
        {showPopup && (
          <ScorePopupContainerWeb
            top={top}
            left={left - 20}
            width={18}
            id='popUpDesktop'
            textDescription={toolTipText}
          />
        )}
        <Scoreboxtitle></Scoreboxtitle>
        <Loyaltyscore component="people">
          <Loyaltyperc>
            {`${sortedData.filter(key => key.sites === "Total")[0]['compliance']}%`}
          </Loyaltyperc>
          <Ravg>Regional average</Ravg>
          <Targetscore>
          </Targetscore>
        </Loyaltyscore>
        <ClockingOnTimeTable>

        
        <Loyaltyheader usedIn="header">
          {data.headers.map((item, index) => {
            return (
              <Clockingheadercell usedIn="header" index={index}
                onClick={() =>
                  sortItem(
                    getFieldFromKey(item),
                    sortState,
                    sortedData
                  )
                }
              >
                {<Colorgradient index={index}>{item}</Colorgradient>}
                {renderSortIcons(
                  getFieldFromKey(item),
                  sortState,
                  true,
                  state
                )}
              </Clockingheadercell>
            );
          })}
        </Loyaltyheader>
        <ClockingOnTimetableData height={170} usedin="clocking">
          {sortedData.filter(key => key.sites !== "Total").map((itemData, dataindex) => {
            let calculateValues = giveProgress(itemData["compliance"]);
            const { left, top } = rect;
            return (
              <>
                <Loyaltyheader key = {dataindex}>
                  {headerValue.map((headerItem, headerIndex) => {
                    let key = returnRequiredRegionalHeader(headerItem);
                    return headerIndex === 0 ? (
                      <Clockingheadercell usedIn="table" index={headerIndex} onMouseOver={(e) =>
                        handleSiteHover(e, itemData[key], "enter")
                      }
                        onMouseOut={(e) => handleSiteHover(e, "", "leave")}>
                        <Clockingspace>
                          {`${itemData[key]}`}
                          {site === itemData[key] && (
                            <TooltipValue id="tooltipvalue" top={top} left={left}>
                              {itemData[key]}
                            </TooltipValue>
                          )}
                        </Clockingspace>
                      </Clockingheadercell>
                    ) : headerIndex === 1 ? (
                      <Clockingheadercell usedIn="table" index={headerIndex}>
                        {itemData[key]}%
                      </Clockingheadercell>
                    ) : (
                      <>
                        <Clockingheadercell index={headerIndex}>
                          <Whitepbar>
                            <Actualpbar
                              widthPercent={calculateValues.result}
                              color={calculateValues.color}
                            />
                          </Whitepbar>
                        </Clockingheadercell>
                      </>
                    );
                  })}
                </Loyaltyheader>
              </>
            );

          })}
        </ClockingOnTimetableData>
        </ClockingOnTimeTable>
       
      </OldDataBlur>
      {blur && (
        <Oldviewcustomersatisfaction id="OldDataFullview">
          <OldClockingcontent id="olddatacontent">
            <Oldtext id="old">You're viewing old data</Oldtext>
            <Oldtext id="data_not_updated_text">This data has not been updated yet.</Oldtext>
            <Oldtext id="oldtext">
              Scheduled updates occurs on&nbsp;{complianceData}
            </Oldtext>
            <Olddatatradingapprovee id="olddataapprove" onClick={() => setBlur(!blur)}>
              continue with old data
            </Olddatatradingapprovee>
          </OldClockingcontent>
        </Oldviewcustomersatisfaction>
      )}
    </ClockingTimeContainer>
  );
}



const mapStateToProps = (state) => {
  const { scorecardInfo, loader, authInfo, scorecardImprovedInfo } = state;
  const { userData } = authInfo;
  const { showLoader } = loader;
  const { excelLoader, kpiName, siteNames, noSelected, selectedManagerName } =
    scorecardInfo;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  const complianceData = layout?.data?.schedules?.complianceData;
  return {
    excelLoader,
    kpiName,
    showLoader,
    siteNames,
    noSelected,
    selectedManagerName,
    userData,
    complianceData
  };
};

const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
    scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(ClockingTime)
);

