import { constants } from "../constants/reducerConstants";

const initialState = {
  scoreCardCompleteData : {},
  scorecardData: [],
  error: "",
  isScorecardLoading: true,
  excelLoader: false,
  kpiName: '',
  listOfSites: [],
  noSelected: 0,
  siteNames: [],
  regionalManagers: [],
  selectedManagerId: "",
  selectedManagerName: "",
  sites:[]
};

export function scorecardInfo(state = initialState, action) {
  switch (action.type) {
    case constants.SCORECARD_SUCCESS:
      return {
        ...state,
        scorecardData: action.data.body,
        error: ""
      };
    case constants.SCORECARD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case constants.EMPTY_SCORECARD:
      return {
        ...state,
        scorecardData: []
      }
    case constants.SET_SITES:
      return{
        ...state,
        sites: action.data
      }
    case constants.SCORECARD_DOWNLOAD_LOADER_START:
      return {
        ...state,
        excelLoader: true,
        kpiName: action.data
      }
      case constants.SAVE_COMPLETE_SCORECARD_DATA:
      return {
        ...state,
        scoreCardCompleteData: action.data
      }
    case constants.SCORECARD_DOWNLOAD_LOADER_DONE:
      return {
        ...state,
        excelLoader: false
      }
    case constants.FETCH_LIST_OF_SITE:
      return {
        ...state,
        listOfSites: action.data.body.sites,
        regionalManagers: action.data.body.regionalManagers,
        selectedManagerId: action.data.body.user.regionalManagerId,
        selectedManagerName: action.data.body.user.regionalManagerName,
      }
    case constants.CHANGE_NUMBER_OF_SITES:
      return {
        ...state,
        noSelected: action.data
      }
    case constants.SITE_NAMES:
      return {
        ...state,
        siteNames: action.data
      }
    case constants.SELECTED_MANAGER_NAME:
      return {
        ...state,
        selectedManagerId: action.data.id,
        selectedManagerName:action.data.name
      }
    default:
      return state;
  }
}
