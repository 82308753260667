import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import Unpublished from "../../../../scorecardNewDesign/People/Screens/Unpublished";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const UnpublishedScheduleComponent = ({ unpublished_schedule, getUnpublishedScheduleData , layout}) => {
  useEffect(() => {
    getUnpublishedScheduleData(KPINames.UNPUBLISHED_SCHEDULE);
  }, []);

  if (unpublished_schedule.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (unpublished_schedule.data) {
    return (
        <Unpublished weekNumber = {layout.data?.weekNumber} data = {unpublished_schedule.data} dataFor = {"Month"}/>
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
  } = state;
 const unpublished_schedule = scorecardImprovedInfo[KPINames.UNPUBLISHED_SCHEDULE];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return {  layout, unpublished_schedule };
}

const mapDispatchToProps = {
  getUnpublishedScheduleData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UnpublishedScheduleComponent);
