import React, { Component } from 'react';
import { ItemView, Text1, View, PercentStyle, ComponentTitle,Mainview, YearView,YearGuide, ScoreContainer, PercentValue, ArrowIcon, CurrentYear, ItemAlign, Mobileview, MobileTitle, Mobileyearguide } from './ScoreComponent.styled';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { faCaretUp, faCaretDown, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { averageScoreActions } from "../../actions/GoogleAverageScoreAction";
import { compose } from 'redux';
import { withRouter } from "react-router-dom";
import { HeadingContainer,  TitleContainer } from '../GoogleReviewDashboard/ReviewsDashboard.styled';
import isMobile from '../../helpers/isMobile';


export class ScoreComponent extends Component {
    constructor() {
        super();
        this.state = {
            averageScoreList: {},
            filterQuery: '',
            kpiWidth:0
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filterQuery !== this.props.filterQuery) {
            this.setState({ filterQuery: this.props.filterQuery })
            this.props.getFilterData(this.props.filterQuery).then(() => {
                let tilesLength = 0;
                Object.keys(this.props.scoreData).map((row) =>{
                    if((row === 'channelOfTrade' || row ==='channelOfTradeUnderRegionalManagers') && this.props.scoreData[row].month===undefined){
                       tilesLength=tilesLength + Object.keys(this.props.scoreData[row]).length;
                    }else{
                        tilesLength =tilesLength+1
                    }
                })
                this.setState({ averageScoreList: this.props.scoreData, kpiWidth:tilesLength })
            })
        }
    }

    scoreComponentUI(item, index) {
        const { t } = this.props;
        if(!isMobile()){
        return (
            
            <Mainview key = {index} width = {this.state.kpiWidth} data-test="score-kpi" mobile = {isMobile()}>
                <ComponentTitle isCountryScore={true} width = {this.state.kpiWidth} mobile = {isMobile()}>
                    {item.headerText}
                </ComponentTitle>
                <ItemView mobile = {isMobile()}>
                    <YearGuide width = {this.state.kpiWidth} data-test="year-kpi" mobile={isMobile()}>
                        <View>{t("Year Comparison")}</View>
                        <YearView>
                        {item.year.map((row, i) => {
                            return (
                                <CurrentYear key = {i}>
                                    <Text1 id="value">{row.value || '-'}</Text1>
                                    <Text1 id="reviews">{`${t("Reviews")}: `}{row.reviews || '-'}</Text1>
                                    <Text1 id="Year">{(i === 1?"YTD ":'')+row.period}</Text1>
                                </CurrentYear>)
                        })}
                        <PercentStyle>
                            <PercentValue width = {this.state.kpiWidth} avarageScore={item.differenceInPercentForYear}>{item.differenceInPercentForYear ? Math.abs(item.differenceInPercentForYear):'-'}%</PercentValue>
                            <ArrowIcon avarageScore={item.differenceInPercentForYear} isCountryScore={true}>
                                {
                                    item.differenceInPercentForYear === '0.0' || item.differenceInPercentForYear === undefined ? <FontAwesomeIcon icon={faCaretLeft} /> : item.differenceInPercentForYear > 0 ?
                                        <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />
                                }
                            </ArrowIcon>
                        </PercentStyle>
                        </YearView>
                        </YearGuide> 
                        <YearGuide id ="month" width = {this.state.kpiWidth} data-test="month-kpi" mobile={isMobile()}>
                            <View>{t("Month Comparison")}</View>
                            <YearView id ="month">
                            {item.month.map((row, i) => {
                            return (
                                <CurrentYear key = {i}>
                                    <Text1 id="value">{row.value || '-'}</Text1>
                                    <Text1 id="reviews">{`${t("Reviews")}: `}{row.reviews || '-'}</Text1>
                                    <Text1 id="Year">{row.columnHeader}</Text1>
                                </CurrentYear>
                            )
                        })}
                        <PercentStyle>
                            <PercentValue width = {this.state.kpiWidth} avarageScore={item.differenceInPercentForMonth}>{item.differenceInPercentForMonth ? Math.abs(item.differenceInPercentForMonth) : '-'}%</PercentValue>
                            <ArrowIcon avarageScore={item.differenceInPercentForMonth} isCountryScore={true}>
                                {
                                    item.differenceInPercentForMonth === '0.0' || item.differenceInPercentForMonth === undefined ? <FontAwesomeIcon icon={faCaretLeft} /> : item.differenceInPercentForMonth > 0 ?
                                        <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />
                                }
                            </ArrowIcon>
                            </PercentStyle>
                            </YearView> 
                        </YearGuide>               
                    </ItemView>
                   </Mainview>
        )}
        else{
            return(
                <Mobileview key = {index}  data-test="score-kpi" >
                <MobileTitle isCountryScore={true}>
                    {item.headerText}
                </MobileTitle>
                <ItemView mobile = {isMobile()}>
                    <Mobileyearguide data-test="year-kpi">
                        <View>{t("Year Comparison")}</View>
                        <YearView>
                        {item.year.map((row, i) => {
                            return (
                                <CurrentYear key = {i}>
                                    <Text1 id="value">{row.value || '-'}</Text1>
                                    <Text1 id="reviews">{`${t("Reviews")}: `}{row.reviews || '-'}</Text1>
                                    <Text1 id="Year">{(i === 1?"YTD ":'')+row.period}</Text1>
                                </CurrentYear>)
                        })}
                        <PercentStyle>
                            <PercentValue width = {this.state.kpiWidth} avarageScore={item.differenceInPercentForYear}>{item.differenceInPercentForYear ? Math.abs(item.differenceInPercentForYear):'-'}%</PercentValue>
                            <ArrowIcon avarageScore={item.differenceInPercentForYear} isCountryScore={true}>
                                {
                                    item.differenceInPercentForYear === '0.0' || item.differenceInPercentForYear === undefined ? <FontAwesomeIcon icon={faCaretLeft} /> : item.differenceInPercentForYear > 0 ?
                                        <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />
                                }
                            </ArrowIcon>
                        </PercentStyle>
                        </YearView>
                        </Mobileyearguide> 
                        <Mobileyearguide id ="month"  data-test="month-kpi">
                            <View>{t("Month Comparison")}</View>
                            <YearView id ="month">
                            {item.month.map((row, i) => {
                            return (
                                <CurrentYear key = {i}>
                                    <Text1 id="value">{row.value || '-'}</Text1>
                                    <Text1 id="reviews">{`${t("Reviews")}: `}{row.reviews || '-'}</Text1>
                                    <Text1 id="Year">{row.columnHeader}</Text1>
                                </CurrentYear>
                            )
                        })}
                        <PercentStyle>
                            <PercentValue width = {this.state.kpiWidth} avarageScore={item.differenceInPercentForMonth}>{item.differenceInPercentForMonth ? Math.abs(item.differenceInPercentForMonth) : '-'}%</PercentValue>
                            <ArrowIcon avarageScore={item.differenceInPercentForMonth} isCountryScore={true}>
                                {
                                    item.differenceInPercentForMonth === '0.0' || item.differenceInPercentForMonth === undefined ? <FontAwesomeIcon icon={faCaretLeft} /> : item.differenceInPercentForMonth > 0 ?
                                        <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />
                                }
                            </ArrowIcon>
                            </PercentStyle>
                            </YearView> 
                        </Mobileyearguide>               
                    </ItemView>
                   </Mobileview>
            )
        }
    }

    render() {
        const { averageScoreList } = this.state;
        const { t } = this.props;
        if (Object.keys(averageScoreList).length > 0) {
            return (
                <ScoreContainer data-test="score-container">
                   <HeadingContainer>
                       <TitleContainer mobile = {isMobile()} className='titleAdjustments'>
                          {t("Average Google Score")}
                       </TitleContainer>
                   </HeadingContainer>
                    <ItemAlign width = {this.state.kpiWidth} mobile={isMobile()}>
                            {Object.keys(averageScoreList).map((row, i) => {
                            if (row === 'channelOfTrade' || (row === 'channelOfTradeUnderRegionalManagers')) {
                                if (Object.keys(averageScoreList[row])[0] !== 'month') {
                                    return Object.keys(averageScoreList[row]).map((row1, i) => {
                                        return this.scoreComponentUI(averageScoreList[row][row1], '$(row)+$(row1)')
                                    })
                                } else {
                                    return this.scoreComponentUI(averageScoreList[row], row)
                                }
                            } else if (row !== 'channelOfTradeUnderRegionalManagers') {
                                return this.scoreComponentUI(averageScoreList[row], row)
                            }
                        })}
                    </ItemAlign> 
              </ScoreContainer>
            )
        } else {
            return null
        }
    }

}
function mapStateToProps(state) {
    const { googleAverageScoreInfo } = state;
    const { scoreData } = googleAverageScoreInfo;
    return { scoreData };
}

const mapDispatchToProps = {
    getFilterData: (filter) => averageScoreActions.getAverageScoreData(filter)
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ScoreComponent);
