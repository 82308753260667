import { LANGUAGE_URLS } from '../constants/environmentConstants';

export const googleAverageScoreServices = {
    getScoreData,
    getFilterData,
    getTableReview
}

function getScoreData(id, data, filter, languageCode) {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': data
        }
    }
    const url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    let apiLink = `${url}/google-average-score`+filter
    return fetch(apiLink, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.statusCode === 400) {
                return Promise.reject(data);
            }
            else {
                return data;
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

function getFilterData(id, data, languageCode, isMobile) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': data
        }
    }
    const url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    let request = ''
    if (!isMobile) {
        request = `${url}/filter-details`
    } else {
        request = `${url}/filter-details${id}`
    }
    return fetch(request, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.statusCode === 400) {
                return Promise.reject(data);
            }
            else {
                return data;
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
}


function getTableReview(id, data, key, isExport, languageCode) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': data
        }
    }
    const api_url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    const url = isExport ? `${api_url}/google-reviews${key}&export=true` : `${api_url}/google-reviews${key}`;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.statusCode === 400) {
                return Promise.reject(data);
            }
            else if (data.body.preSignedUrl) {
                const link = document.createElement('a');
                link.href = data.body.preSignedUrl;
                link.setAttribute('download', data.body.key);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return data;
            }
            else {
                return data;
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
}



function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            let error = (data && data.message) || response.statusText;
            if (response.status === 400) {
                error = response.status;
            }
            throw new Error(error);
        }
        return data;
    });
}