import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import EmaintenanceWrapper from "../../../scorecardNewDesign/Emaintaince/screens/EmaintenanceWrapper";
import DarkSitesComponent from './KPI/DarkSites';
import MandatoryTaskComponent from './KPI/MandatoryTasks';
import SiteSafetyOverviewComponent from './KPI/SiteSafetyOverview';

function SafetyFirstSection() {
    return (
        <>
            <Row>
                <Col xs={12} lg={8}>
                    <SiteSafetyOverviewComponent />
                </Col>
                <Col >
                    <DarkSitesComponent />
                    <MandatoryTaskComponent />
                </Col>
            </Row>
            <Row>
                <Col>
                    <EmaintenanceWrapper id="eMainWrapper" />
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SafetyFirstSection)