// eslint-disable-next-line import/no-anonymous-default-export
export const bpPolandSites = [
    {
            "lat": 52.223323541362056, 
            "lng": 21.027459995232565,
            "location": "BPSite@Warsaw",
            "rank": 1
        },
    {
            "lat": 52.3577208985059, 
            "lng":16.896600620232565,
            "location": "BPSite@Poznan",
            "rank": 2
        },
    {
            "lat": 51.19542107192283,
            "lng": 22.565545932732565,
            "location": "BPSite@Lublin",
            "rank": 3
        },
        {
            "lat": 52.226140, 
            "lng": 18.243140,
            "location": "BPSite@Konin",
            "rank": 4
        },
        {
            "lat": 52.546345, 
            "lng": 19.706535,
            "location": "BPSite@Plock",
            "rank": 5
        },
    {
            "lat": 53.121132,
            "lng": 17.992970,
            "location": "BPSite@Bydgoszcz",
            "rank": 6
        },    
    ]

// eslint-disable-next-line import/no-anonymous-default-export
export const bpUkSites = [
    {
            "lat": 51.447338, 
            "lng": -0.409050,
            "location": "BPSite@Feltham",
            "rank": 1
        },
    {
            "lat": 51.454266, 
            "lng": -0.978130,
            "location": "BPSite@Reading",
            "rank": 2
        },
        {
            "lat": 53.480759, 
            "lng": -2.242631,
            "location": "BPSite@Manchester",
            "rank": 3
        },
        {
            "lat": 55.953251, 
            "lng": -3.188267,
            "location": "BPSite@Reading",
            "rank": 4
        },           
]

export const countryCoordinates = {
    "PL": {
        "lat": 51.919437,
        "lng": 19.145136
    },
    "EN": {
        "lat": 55.378052,
        "lng": -3.435973
    }
}

export const MAPS_KEY = "AIzaSyBhF4A1EeR9Z0igb0Kb0HHPr3lmKIz9vP0";
