/* Copyright (C) BP -
 * All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 7/6/2022
 * Author : Aditya Padmanabhan
 */

import { constants } from "../constants/reducerConstants";

const initialState = {
  seen: [],
  unseen: [],
  showNotification: false,
  SeenData: [],
  UnSeenData: [],
  connectionId: "",
};

export function NotificationInfo(state = initialState, action) {
  switch (action.type) {
    case constants.SAVE_NOTIFICATION_DATA:
      return {
        ...state,
        seen: action.data.viewed,
        unseen: action.data.new,
      };
    case constants.SAVE_INITIAL_TIMESTAMP:
      return {
        ...state,
        initialTimeStamp: action.data.timestamp,
      };
    case constants.SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
      };
    case constants.HIDE_NOTIFICATION:
      return {
        ...state,
        showNotification: false,
      };
    case constants.SET_SEEN_DATA:
      return {
        ...state,
        SeenData: action.payload,
      };
    case constants.SET_UNSEEN_DATA:
      return {
        ...state,
        UnSeenData: action.payload,
      };
    case constants.SET_SEEN_EMPTY:
      return {
        ...state,
        SeenData: [],
      };

    case constants.SET_UNSEEN_EMPTY:
      return {
        ...state,
        UnSeenData: [],
      };

    case constants.SAVE_CONNECTION_ID:
      return {
        ...state,
        connectionId: action.payload,
      };

    default:
      return state;
  }
}
