/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 05/09/2022
 * Author : Aditya Padmanabhan
 */




import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from 'redux';
import { scorecardActions } from "../../actions/scorecardActions";
import { DOWNLOAD_SECTION_TILE_NAME, EVENT_DOWNLOAD_EXCEL, KEY_USER_ID, KEY_USER_TITLE, UNKNOWN_TITLE } from '../../constants/FireBaseEvents';
import isMobile from "../../helpers/isMobile";
import cross_icon from "../../images/icons/cross_icon.svg";
import i_download from "../../images/icons/i_download.svg";
import i_download_white from "../../images/icons/i_download_white.svg";
import i_icon from "../../images/icons/i_icon.svg";
import { IconImage } from "../GoogleReviewDashboard/ReviewsDashboard.styled";
import { ExcelSpin } from "../commonStyles/common.styled";
import Personalranking from './PersonalRanking';
import Rangeloyalty from './RangeLoyalty';
import {
  CustomerContainer, CustomerMobile, Exclaimation, ExclaimationText, Loyaltycomponents,
  MobilePopup,
  OldDataApprove, OldDataBlur,
  OldDataContent,
  OldDataFullview,
  Oldtext,
  RankingContainer,
  ScoreDownloadIcon,
  ScoreExcelLabel,
  ScoreExportContainer,
  ScoreKpiName,
  ScorePopupContainer,
  ScoreTitle,
  TableHeader, TooltipValue
} from './ScoreCard.styled';
import { createMarkup, eventLogging } from './utils';

function LoyaltyIssuance(props) {
    const {title, toolTipText, data, rank, lastWeekUpdated, expectedRefreshTimestamp, state} = props.data;
    const {maxIssuance, siteDataComparison, averageComparison, topThree, personalPlacement} = data;
    const [ blur, setBlur] = useState(props.weekNumber!==lastWeekUpdated)
    const [blurRect, setBlurRect] = useState(0);
    const [showPopup, setShowPopup] = useState('');
    const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
    const siteHeader = [`${siteDataComparison.header}`];
    const rankingTitle = 'Loyalty Issuance Ranking';

    useEffect(() => {
        if (props.event) {
            setBlurRect(0);
            props.changeEvent();
          }
        window.addEventListener('scroll', controlDirection);
        return () => {
            window.removeEventListener('scroll', controlDirection);
        };
      }, []);
      const controlDirection = () => {
        setOldPopUpVisible(false)
        setShowPopup('')
    }

    const handleHover = (name, type) => {
        if (type === 'leave') {
            setShowPopup('');
        } else {
            setShowPopup(name);
        }
    }

    const blurHover = async(event, type) => {
        if(type==='enter'){
            setBlurRect(event.target.getBoundingClientRect());
        }
        else{
            setBlurRect(0)
        }
    }
  
    const blurHoverMobile = async(event, type) => {
        if(type==='enter'){
            await setOldPopUpVisible(true)
            setBlurRect(event.target.getBoundingClientRect());
        }
        else{
           await setOldPopUpVisible(false)
            setBlurRect(0)
        }
    }

    const exportToExcel = () => {
        const kpiName = title.split(' ')[0];
        const headers = ['Sites','Issuance %','Target Issuance %','Previous Week Issuance %'];
        props.exportToExcel(kpiName, headers, siteDataComparison?.data);
        // log download event
     let downloadEventData = {
      [KEY_USER_ID]: props?.userData?.id,
      [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
      [DOWNLOAD_SECTION_TILE_NAME]:title
      }
      eventLogging(EVENT_DOWNLOAD_EXCEL, downloadEventData)
    }

   
  if (isMobile()) {
    return (
        <CustomerMobile id='loyalty'>
            <Card className='data-card' id="data-card">
            <OldDataBlur boolTrue={blur} id="OldDataBlur">
                {(!blur && props.weekNumber!==lastWeekUpdated) && <Exclaimation id="exclamationContainer" onMouseEnter={(e)=>blurHoverMobile(e,'enter')} onMouseLeave={(e)=>blurHoverMobile(e,'leave')}><ExclaimationText id = "ExclaimationText">!</ExclaimationText></Exclaimation>}
                    {oldPopUpVisible && <TooltipValue id = "tooltipvalue" top={blurRect.top+70} left={blurRect.left+15}>{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>}
                <TableHeader id={"header"+title}>
                    <ScoreTitle id="loyaltyTitle">
                    <ScoreKpiName id="scoreKpiName">{title}</ScoreKpiName>
                    <div className="loyalty-card-view" onClick ={(e)=>{showPopup === title ? handleHover('', 'leave') : handleHover(title, 'enter')}}>
                        <img className="cross-class" alt="tile_info" src={showPopup === title ?  cross_icon : i_icon} />
                    </div>
                    </ScoreTitle>
                    <Loyaltycomponents id="loyaltyComponents">
                        <Rangeloyalty id="rangeLoyalty" header={siteHeader} data={siteDataComparison.data} max_percentage={maxIssuance.data}/>
                    </Loyaltycomponents>
                </TableHeader>
                {showPopup === title &&
                <MobilePopup top={63} left={230} width={20} arrowTop={0} arrowLeft={-100} id='popUpMobile'>
                    <span className="What-you-can-expect">
                        <span key={toolTipText} dangerouslySetInnerHTML={createMarkup(toolTipText)} />
                    </span>
                </MobilePopup>}                     
            </OldDataBlur>
           {blur && (
            <OldDataFullview id = "OldDataFullview">
            <OldDataContent id = "olddatacontent">
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </OldDataFullview>
          )}
            </Card>
            <RankingContainer>
            <Card className='data-card' id="data-card">
            <OldDataBlur boolTrue={blur} id="OldDataBlur">
                {(!blur && props.weekNumber!==lastWeekUpdated) && <Exclaimation id="exclamationContainer" onMouseEnter={(e)=>blurHoverMobile(e,'enter')} onMouseLeave={(e)=>blurHoverMobile(e,'leave')}><ExclaimationText id = "ExclaimationText">!</ExclaimationText></Exclaimation>}
                    {oldPopUpVisible && <TooltipValue id = "tooltipvalue" top={blurRect.top+70} left={blurRect.left+15}>{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>}
                <TableHeader id={"header"+title}>
                    <ScoreTitle id="loyaltyTitle">
                    <ScoreKpiName id="scoreKpiName">{rankingTitle}</ScoreKpiName>
                    <div className="loyalty-card-view" onClick ={(e)=>{showPopup === rankingTitle ? handleHover('', 'leave'): handleHover(rankingTitle, 'enter')}}>
                        <img className="cross-class" alt="tile_info" src={showPopup === rankingTitle ?  cross_icon : i_icon} />
                    </div>
                    </ScoreTitle>
                    <Loyaltycomponents id="loyaltyComponents">
                        <Personalranking id="personalRanking" averageComparison ={averageComparison} compare={personalPlacement} topThree={topThree} rank = {rank}/>
                    </Loyaltycomponents>
                </TableHeader>
                {showPopup === rankingTitle &&
                <MobilePopup top={63} left={230} width={20} arrowTop={0} arrowLeft={-100} id='popUpMobile'>
                    <span className="What-you-can-expect-mobile">
                        <span key={toolTipText} dangerouslySetInnerHTML={createMarkup(toolTipText)} />
                    </span>
                </MobilePopup>}                        
            </OldDataBlur>
            {blur && (
            <OldDataFullview id = "OldDataFullview">
            <OldDataContent id = "olddatacontent">
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </OldDataFullview>
          )}
            </Card>
            </RankingContainer>
        </CustomerMobile>
    );
  }
  return (
    <CustomerContainer id="loyalty">
      <Card className="data-card" id="data-card">
        <OldDataBlur boolTrue={blur} id="OldDataBlur">
          {!blur && props.weekNumber !== lastWeekUpdated && (
            <Exclaimation
            id="exclamationContainer"
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
            >
              <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
          {blurRect !== 0 && (
            <TooltipValue
            id = "tooltipvalue"
              top={blurRect.top + 70}
              left={blurRect.left + 15}
            >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
          )}
          <TableHeader id={"header"+title}>
            <ScoreTitle id="loyaltyTitle">
              <ScoreKpiName id="scoreKpiName">{title}</ScoreKpiName>
              {siteDataComparison?.data?.length > 0 && (
                <ScoreExportContainer
                  isRegionalManager={true}
                  onClick={() => exportToExcel()}
                  mobile={isMobile()}
                  id = "ScoreExportContainer"
                >
                  <ScoreExcelLabel isNeutral={state === "NEUTRAL"} id = "ScoreExcelLabel">
                    Excel
                  </ScoreExcelLabel>
                  <ScoreDownloadIcon marginRight={15} id = "ScoreDownloadIcon">
                    {props.excelLoader &&
                    props.kpiName === title.split(" ")[0] ? (
                      <ExcelSpin
                        color={state === "NEUTRAL" ? "#007833" : "white"}
                      />
                    ) : (
                      <IconImage
                        src={
                          state === "NEUTRAL" ? i_download : i_download_white
                        }
                        alt="scorecard_excel"
                      />
                    )}
                  </ScoreDownloadIcon>
                </ScoreExportContainer>
              )}
              <div
                className="loyalty-card-view"
                id="loyalty-card-view"
                onMouseOver={(e) => handleHover(title, "enter")}
                onMouseOut={(e) => handleHover("", "leave")}
                >
                <img className="cross-class" alt="tile_info" src={i_icon} />
              </div>
            </ScoreTitle>
          </TableHeader>
          <Loyaltycomponents id="loyaltyComponents">
            <Rangeloyalty
              header={siteHeader}
              data={siteDataComparison.data}
              max_percentage={maxIssuance.data}
              id="rangeLoyalty"
            />
            <Personalranking
              averageComparison={averageComparison}
              compare={personalPlacement}
              topThree={topThree}
              rank={rank}
              id="personalRanking"
            />
          </Loyaltycomponents>
          {showPopup === title && (
            <ScorePopupContainer
              top={45}
              left={0}
              width={20}
             // arrowTop={-8}
              arrowLeft={89}
              id='popUpDesktop'
            >
              <span className="What-you-can-expect">
                <span
                  key={toolTipText}
                  dangerouslySetInnerHTML={createMarkup(toolTipText)}
                />
              </span>
            </ScorePopupContainer>
          )}
        </OldDataBlur>
       {blur && (
            <OldDataFullview id = "OldDataFullview">
            <OldDataContent id = "olddatacontent">
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </OldDataFullview>
          )}
      </Card>
    </CustomerContainer>
  );
}

const mapStateToProps = (state) => {
    const { scorecardInfo, authInfo } = state;
    const { excelLoader, kpiName } = scorecardInfo;
    const { userData } = authInfo;
    return { excelLoader, kpiName, userData};
}

const mapDispatchToProps = {
    exportToExcel: (kpiName, headers, sortedData) => scorecardActions.getScorecardData(kpiName, headers, sortedData)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(LoyaltyIssuance));