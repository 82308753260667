import { googleAverageScoreServices } from "../services/googleScoreService";
import { constants } from "../constants/reducerConstants";
import { startLoader, stopLoader, getLogoutUrl } from '../helpers/commons';
import isMobile from "../helpers/isMobile";

export const averageScoreActions = {
  getAverageScoreData,
  getFilterData,
  getTableReview,
  getFilterDataMobile
};

function getAverageScoreData(filter) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    dispatch({ type: constants.SCORE_START_LOADER,});
    return googleAverageScoreServices
      .getScoreData(authInfo.userData.id, authInfo.sessionData.id_token, filter, authInfo.languageCode)
      .then(
        (data) => {
          dispatch({ type: constants.SCORE_FETCH_SUCCESS, data });
        },
        (error) => {
          dispatch({ type: constants.SCORE_FETCH_FAILURE, error });
          if (error && error.message === 'Failed to fetch') {
            dispatch({type: constants.CLEAR_AUTH_STORE});
            window.location.href = getLogoutUrl(authInfo.languageCode);
           }
        }
      );
  };
}

function getFilterData() {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    dispatch(startLoader());
    return googleAverageScoreServices
      .getFilterData(authInfo.userData.id, authInfo.sessionData.id_token, authInfo.languageCode, isMobile())
      .then(
        (data) => {
          dispatch(stopLoader());
          dispatch({ type: constants.FILTER_FETCH_SUCCESS, data });
        },
        (error) => {
          dispatch(stopLoader());
          dispatch({ type: constants.FILTER_FETCH_FAILURE, error });
        }
      );
  };
}

function getFilterDataMobile(request) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    dispatch(startLoader());
    return googleAverageScoreServices
      .getFilterData(request,authInfo.sessionData.id_token, authInfo.languageCode, isMobile())
      .then(
        (data) => {
          dispatch(stopLoader());
          dispatch({ type: constants.FILTER_FETCH_SUCCESS, data });
        },
        (error) => {
          dispatch(stopLoader());
          dispatch({ type: constants.FILTER_FETCH_FAILURE, error });
        }
      );
  };
}

function getTableReview(key, isExport) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    if (!isExport) {
      dispatch({ type: constants.GOOGLE_REVIEW_START_LOADER });
    } else {
      dispatch({ type: constants.EXCEL_DOWNLOAD_LOADER_START });
    }
    return googleAverageScoreServices
      .getTableReview(authInfo.userData.id, authInfo.sessionData.id_token, key, isExport, authInfo.languageCode)
      .then(
        (data) => {
          if (!data.body.preSignedUrl) {
            dispatch(stopLoader());
            dispatch({ type: constants.TABLE_REVIEW_SUCCESS, data });
          } else {
            dispatch({ type: constants.EXCEL_DOWNLOAD_LOADER_DONE });
          }
        },
        (error) => {
          dispatch({ type: constants.EXCEL_DOWNLOAD_LOADER_DONE });
          dispatch(stopLoader());
          dispatch({ type: constants.TABLE_REVIEW_FAILURE, error });
        }
      );
  };
}
