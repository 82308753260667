/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/1/2022
 * Author : Aditya Padmanabhan
 */

import styled from "styled-components";

export const Notificationcontainer = styled.div`
  position: absolute;
  width: 480px;
  height: 32em;
  margin-top: 3em;
  left: 62%;
  overflow-y: scroll;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #fbfbfb;
  z-index: 1;
`;

export const Imagebox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.15;
  height: inherit;
  margin-bottom: 5px;
`;

export const Eyebox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.15;
  height: inherit;
  margin-bottom: 5px;
`;
export const Image = styled.img`
  width: 36px;
  height: 36px;
`;

export const Textarea = styled.div`
  display: flex;
  flex: 0.7;
`;

export const MainTextView = styled.div`
  display: flex;
  flex: 1;
  width: inherit;
  height: inherit;
  flex-direction: column;
  cursor: pointer;
`;

export const NotificationText = styled.div`
  display: flex;
  flex: 0.7;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: roboto-light;
  padding-top: 7px;
`;

export const NotificationSubText = styled.div`
  display: flex;
  flex: 0.4;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
  font-family: roboto-light;
`;

export const NotificationBox = styled.div`
  display: flex;
  flex: 0.1;
  flex-direction: column;
  background-color: violet;
`;

export const Heading = styled.div`
  display: flex;
  flex: 0.4;
  justify-content: flex-start;
  align-items: center;
  font-size: 17px;
  margin-left: 1em;
  background-color: #fbfbfb;
`;

export const Tickarea = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.8em;
  cursor: pointer;
  background-color: #fbfbfb;
`;

export const Greentext = styled.div`
  font-size: 17px;
  color: #007833;
  font-family: ${(props) =>
    props.id === "save" ? "Roboto-bold" : "Roboto-regular"};
  margin-left: ${(props) => (props.id === "save" ? "1.3em" : "")};
  cursor: pointer;
`;

export const GreenText = styled.text`
  color: #007833;
  font-size: 14px;
  font-family: ${(props) => (props.id ? "Roboto-bold" : "Roboto-regular")};
`;

export const BlackText = styled.text`
  color: black;
  font-size: 14px;
`;

export const Rowarea = styled.div`
  display: flex;
  flex-direction: row;
  height: 75px;
  width: 100%;
  margin-top: 0px;
  border: solid 1px #e3e3e3;
  background-color: var(--white);
  border-left: ${(props) => (props.id ? "4px solid #007833 " : "")};
`;

export const Header = styled.div`
  height: 54px;
  display: flex;
  flex: 1;
  flex-direction: row
  background-color: #fbfbfb;
`;

export const NotificationCloseContainer = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 0;
  right: 0px;
  left: 0px;
  top: 0px;
`;

export const Nonotification = styled.span`
  margin-left: 1.5em;
`;