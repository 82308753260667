/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/5/2023
 */

import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../actions/scorecardActions";
import { defaultSortOrder, renderSortIcons, sortData } from "../../../Scorecard/utils";
import { KPINames } from "../../../ScorecardImproved/ScorecardImprovedConstants";
import { TileHeader } from "../../Customer/Screens/Components/TileHeader";
import { Exclaimation, OldDataBlur } from "../../Customer/style/Customer.styled";
import { ExclaimationText, Oldtext, TooltipValue } from "../../ScoreCard.styled";
import { Olddatatradingapprovee } from "../../TradingProfitably/Styles/TradingUi.styled";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import { titleGenerator } from "../../scorecardConstants";
import { Clockingheadercell, Clockingspace, DarkSitesContainer, Loyaltyheader, MainContainer, Noresult, Olddatadarkcontent, Olddatadarkoverlay } from "../styles/SafetyUi.styled";
import { returnRequiredRegionalHeader } from "../utils/SafetyUtils";

function DarkSites(props) {
  const { data, darkSite } = props;
  const { toolTipText, title,state, headers, lastWeekUpdated, expectedRefreshTimestamp } = data;
  let headerNames = data?.data?.length > 0 ? defaultSortOrder(data.data) : [];
  const [blur, setBlur] = useState(props.weekNumber !== lastWeekUpdated);
  const [sortedData, setSortedData] = useState(data?.data);
  const [showPopup, setShowPopup] = useState(false);
  const [sortState, setSortState] = useState(headerNames);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);

  const handleHover = (event, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  function loadExcel() {
    props.exportToExcel(titleGenerator[title], headers, sortedData);
  }


  const sortItem = (keyName, sortState, sortedData) => {
    let result;
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    const rowsWithNA = data?.data.filter((rows) => rows[keyName] === "NA");
    if (isAnyColumnSorted === "NONE") {
      const initialSortState = Object.keys(data?.data[0]).map((item) => ({
        name: item,
        sortState: "NONE",
      }));
      result = sortData(
        keyName,
        initialSortState,
        data?.data.filter((rows) => rows[keyName] !== "NA")
      );
    } else {
      result = sortData(
        keyName,
        sortState,
        sortedData.filter((rows) => rows[keyName] !== "NA")
      );
    }
    const currentState = result.sortedState.find((val) => val.name === keyName);
    setSortState(result.sortedState);
    setSortedData(
      currentState.sortState === "ASC"
        ? [...result.sortingData, ...rowsWithNA]
        : [...rowsWithNA, ...result.sortingData]
    );
  };

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
     } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };

  return (
    <DarkSitesContainer>
      <OldDataBlur boolTrue={blur} id="OldDataBlur">
      {!blur && props.weekNumber !== lastWeekUpdated && (
              <Exclaimation
                onMouseEnter={(e) => blurHover(e, "enter")}
                onMouseLeave={(e) => blurHover(e, "leave")}
                id="exclamationContainer"
              >
                <ExclaimationText id="ExclaimationText">!</ExclaimationText>
              </Exclaimation>
            )}
            {oldPopUpVisible && (
            <TooltipValue
              id="tooltipvalue"
              top={blurRect.top + 70}
              left={blurRect.left + 15}
            >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
          )}
          <TileHeader
       exportToExcel = {() => loadExcel()}
       excelLoader={props.excelLoader}
       kpiName = {props.kpiName}
        showPopup={showPopup}
        handleHover={(e, value) => handleHover(e, value)}
        title={title}
        state={state}
      />
      {showPopup && (
         <ScorePopupContainerWeb
         top={top}
         left={left-20}
         width = {18}
          id='popUpDesktop'
          textDescription = {toolTipText}
       />
      )}
     
       {data?.data.length>0 ? <Loyaltyheader usedIn="header">
        {headers.map((item, index) => {
          return (
            <Clockingheadercell 
            onClick={() =>
              sortItem(
                returnRequiredRegionalHeader(item),
                sortState,
                sortedData
              )
            }
            usedIn="header" 
            index={index}
            key = {index}>
              {item}
              {renderSortIcons(
                  returnRequiredRegionalHeader(item),
                  sortState,
                  true,
                  data.state
                )}
            </Clockingheadercell>
          );
        })}
      </Loyaltyheader>: <Loyaltyheader>
        </Loyaltyheader>}
      <MainContainer height={115} usedin="clocking">
      { data?.data.length>0?
      sortedData.map((itemData, dataindex) => {
          return (
            <>
              <Loyaltyheader key = {dataindex}>
                 { data.headers.map((headerItem, headerIndex) => {
                  let key = returnRequiredRegionalHeader(headerItem);
                  return headerIndex === 0 ? (
                    <Clockingheadercell usedIn="table" index={headerIndex}>
                      <Clockingspace>{itemData[key]}</Clockingspace>
                    </Clockingheadercell>
                  ) : headerIndex === 1 ? (
                    <Clockingheadercell usedIn="table" index={headerIndex}>
                      {itemData[key]}%
                    </Clockingheadercell>
                  ) : (
                    <>
                      <Clockingheadercell index={headerIndex}>
                      {itemData[key]}
                      </Clockingheadercell>
                    </>
                  );
                })}
              </Loyaltyheader>
           
            </>
          );
        })
      : <Noresult>No dark sites within your sites</Noresult>}
        </MainContainer>
        </OldDataBlur>
        {blur && (
          <>
          <Olddatadarkoverlay>
            <Olddatadarkcontent>
            <Oldtext id="old">You're viewing old data</Oldtext>
              <Oldtext id="data_not_updated_text">This data has not been updated yet.</Oldtext>
               <Oldtext id="oldtext">
                 Scheduled updates occurs on&nbsp;{props.layout?.data?.schedules?.darkSite}
              </Oldtext>
               <Olddatatradingapprovee id="olddataapprove" onClick={() => setBlur(!blur)}>
                 continue with old data
               </Olddatatradingapprovee>
            </Olddatadarkcontent>
          </Olddatadarkoverlay>
          </>
        )}
    </DarkSitesContainer>
  );
}

const mapStateToProps = (state) => {
  const { scorecardInfo, loader, authInfo, scorecardImprovedInfo } = state;
  const { userData } = authInfo;
  const { showLoader } = loader;
  const { excelLoader, kpiName, siteNames, noSelected, selectedManagerName } =
    scorecardInfo;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return {
    excelLoader,
    kpiName,
    showLoader,
    siteNames,
    noSelected,
    selectedManagerName,
    userData,
    layout
    
  };
};

const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(DarkSites)
);

