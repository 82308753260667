/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 16/08/2022
 * Author : Sunil Vora
 */

import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import * as d3 from "d3";
import { axisLeft, axisBottom } from 'd3';
import { TableHeader, ScoreTitle, ScoreKpiName, BarContainer, LastWeekDetails,
LastWeekData, PointsSection, PointName, PointValue, LegendContainer,
Legend, LegendColor, LegendName, ScorePopupContainer, OldDataBlur, OldDataContent, Oldtext, OldDataApprove, Exclaimation, TooltipValue, ExclaimationText, OldDataFullview, MobilePopup } from "./ScoreCard.styled";
import { getFieldFromKey, createMarkup } from "./utils";
import i_icon from "../../images/icons/i_icon.svg";
import { SAFETY_FIRST_LEGENDS, SUBGROUPS } from "../../constants/scorecard";
import "./BarComponent.css";
import { isTablet } from "../../helpers/isTablet";
import cross_icon from "../../images/icons/cross_icon.svg";
import isMobile from "../../helpers/isMobile";

function BarComponent(props) {
    const { state, title, infoSection, data, toolTipText ,lastWeekUpdated,expectedRefreshTimestamp } = props.data;
    const lastWeekData = data[data.length - 1];
    const dataPoints = [...infoSection.dataPoints].reverse();
    const [showPopup, setShowPopup] = useState('');
    const [ blur, setBlur] = useState(false)
    const [blurRect, setBlurRect] = useState(0);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [oldPopUpVisible, setOldPopUpVisible] = useState(false);

    useEffect(() => {
        if(props.weekNumber!==lastWeekUpdated){
            setBlur(true)
        }    
        render(); 
        window.addEventListener('scroll', controlDirection);
    return () => {
        window.removeEventListener('scroll', controlDirection);
    };   
    }, [props]);

    const controlDirection = () => {
        setOldPopUpVisible(false)
        setShowPopup('')
    }

    const blurHover = (event, type) => {
        if(type==='enter'){
            setBlurRect(event.target.getBoundingClientRect());
            setOldPopUpVisible(true)
        }
        else{
            setBlurRect(0)
            setOldPopUpVisible(false)
        }
    }
  
    const render = () => {
        if (Array.isArray(data) && data.length > 0) {
            const groups = d3.map(data, function(d){return(`W${(d.weekNumber>52)?(d.weekNumber%52):d.weekNumber}`)});
            const svg = d3.select(`svg[class=${props.graphClass}]`);
            const graphContainer = d3.select(`#${props.graphId}`);
            const height = +svg.attr('height');
            const margin = { top: 10, right: 50, bottom: 40, left:isMobile()?33: 80 };
            const innerHeight = height - margin.top - margin.bottom - 12;

            const maxValue = data.reduce(function(prev, curr) {
                const prevValue = parseInt(prev.security) + parseInt(prev.nearMiss) + parseInt(prev.injuries) + parseInt(prev.g);
                const currValue = parseInt(curr.security) + parseInt(curr.nearMiss) + parseInt(curr.injuries) + parseInt(curr.g);
                return prevValue > currValue ? prevValue : currValue;
            });

            const xScale = d3.scaleBand()
                .range([0, isMobile() ? 320 : 400])
                .domain(groups)
                .padding([isMobile() ? 0.6 : 0.4]);

            const yScale = d3.scaleLinear()
                .domain([0 , maxValue])
                .range([120, 0]);
            
            const graphPosition = svg.append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);
            
            const xAxis = axisBottom(xScale)
                // .tickSizeOuter(0);
            
            const yAxis = axisLeft(yScale)
                .tickValues([0 , (0 + maxValue) / 2 , maxValue])
                .tickPadding(2)
                .tickSize(isMobile() ? -285 : -400);
            ;
            
            graphPosition.append('g').call(yAxis);
                
            graphPosition.append('g').call(xAxis)
                .attr('transform', `translate(0,${innerHeight})`);
            
            const color = d3.scaleOrdinal()
            .domain(SUBGROUPS)
            .range(['#000099', '#999999', '#99cc00', '#007f00']);        

            const stackedData = d3.stack()
                .keys(SUBGROUPS)
                (data);

            const barTooltip = graphContainer
                .append("div")
                .style("display", "none")
                .attr("class", isMobile()? "barTooltipMobile": "barTooltip");

            const mousemove = function(event) {
                const data = event.currentTarget.__data__.data;
                const rect = event.currentTarget.getBoundingClientRect();
                barTooltip
                    .html(`
                        <div>${data.security} Security</div>
                        <div>${data.g} G+</div>
                        <div>${data.injuries} Injury</div>
                        <div>${data.nearMiss} Near Miss</div>
                    `)
                    .style("display", "block");
                barTooltip
                    .style("left", isMobile()?`${rect.left - 75}px` : `${rect.left - 125}px`)
                    .style("top", `${rect.bottom-60}px`);
                }
            const mouseleave = function(event) {
                barTooltip
                    .style("display", "none");
                }

            svg.append("g")
            .selectAll("g")
            // Enter in the stack data = loop key per key = group per group
            .data(stackedData)
            .enter().append("g")
                .attr('transform', `translate(${isMobile()?margin.left:(margin.left+7)},${margin.top})`)
                .attr("fill", function(d) { return color(d.key); })
                .selectAll("rect")
                // enter a second time = loop subgroup per subgroup to add all rectangles
                .data(function(d) { return d; })
                .enter().append("rect")
                .attr("x", function(d) { return xScale( `W${(d.data.weekNumber>52)?(d.data.weekNumber%52):d.data.weekNumber}`); })
                .attr("y", function(d) { return yScale(d[1]); })
                .attr("height", function(d) { return yScale(d[0]) - yScale(d[1]); })
                .attr("width", isMobile()?25: 45)
                .on("mousemove", mousemove)
                .on("touchstart", mousemove)  // for mobile touch
                .on("mouseleave", mouseleave);

                window.addEventListener("mousewheel", mouseleave);
                window.addEventListener("touchmove", mouseleave);// it's for mobile version
                return () => {
                    window.removeEventListener('mousewheel', mouseleave);
                    window.removeEventListener("touchmove", mouseleave); // it's for mobile version
                };
        }
    };

    const handleHover = (event, name, type) => {
        const currRect = event.target.getBoundingClientRect();
        setTop(currRect.top);
        setLeft(currRect.left);
        if (type === 'leave') {
            setShowPopup('');
        } else {
            setShowPopup(name);
        }
    }

    return (
      <BarContainer width={props.mobileDevice} id="barContainer">
        <Card className="data-card" style={{ height: "375px" }} >
          <OldDataBlur boolTrue={blur} id= "OldDataBlur">
            {!blur && props.weekNumber !== lastWeekUpdated && (
              <Exclaimation
                onMouseEnter={(e) => blurHover(e, "enter")}
                onMouseLeave={(e) => blurHover(e, "leave")}
                id="exclamationContainer"
              >
                <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
              </Exclaimation>
            )}
            {oldPopUpVisible && (
              <TooltipValue
              id = "tooltipvalue"
                top={blurRect.top + 70}
                left={blurRect.left + 15}
              >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
            )}
            <TableHeader id={"header"+title}>
              <ScoreTitle value="barTitle" id="barTitle">
                <ScoreKpiName
                  id="scoreKpiName"
                  textColor={state !== "DANGER" ? "black" : "white"}
                >
                  {title}
                </ScoreKpiName>
                <div
                  className="right-card-chart"
                  onMouseOver={(e) => handleHover(e, title, "enter")}
                  onMouseLeave={(e) => handleHover(e, "", "leave")}
                  onClick={(e) => {
                    isTablet() && showPopup === title
                      ? handleHover(e, "", "leave")
                      : handleHover(e, title, "enter");
                  }}
                >
                  {isTablet() ? (
                    <img
                      className="cross-class"
                      alt="tile_info"
                      src={showPopup === title ? cross_icon : i_icon}
                    />
                  ) : (
                    <img className="cross-class" alt="tile_info" src={i_icon} />
                  )}
                </div>
              </ScoreTitle>
            </TableHeader>
            <LastWeekDetails id="lastWeekDetails">
              <LastWeekData id="lastWeekData">
                {dataPoints.map((point, index) => {
                  const fieldName = getFieldFromKey(point);
                  return (
                    <PointsSection key={index} id="pointSection">
                      <PointName id="pointName">{point}</PointName>
                      <PointValue id="pointValue">{lastWeekData[fieldName]}</PointValue>
                    </PointsSection>
                  );
                })}
              </LastWeekData>
            </LastWeekDetails>
            <div id={props.graphId}>
              <svg
                className={props.graphClass}
                width="100%"
                height="180"
                style={{ marginTop: "10px" }}
              />
            </div>
            <LegendContainer id="legendContainer">
              {SAFETY_FIRST_LEGENDS.map((legend, idxLegend) => {
                return (
                  <Legend key={idxLegend} id="legend">
                    <LegendColor color={legend.color}  id="legendColor"/>
                    <LegendName id="legendName">{legend.name}</LegendName>
                  </Legend>
                );
              })}
            </LegendContainer>

            {isTablet()
              ? showPopup === title && (
                  <MobilePopup
                    top={60}
                    left={315}
                    width={15}
                    arrowLeft={-100}
                    arrowTop={0}
                    device="mobile"
                    id='popUpMobile'
                  >
                    <span className="What-you-can-expect">
                      <span
                        key={toolTipText}
                        dangerouslySetInnerHTML={createMarkup(toolTipText)}
                      />
                    </span>
                  </MobilePopup>
                )
              : showPopup === title && (
                  <ScorePopupContainer
                    top={40}
                    left={-33}
                    width={15}
                   // arrowTop={-7}
                    arrowLeft={73}
                    id='popUpDesktop'
                  >
                    <span className="What-you-can-expect">
                      <span
                        key={toolTipText}
                        dangerouslySetInnerHTML={createMarkup(toolTipText)}
                      />
                    </span>
                  </ScorePopupContainer>
                )}
          </OldDataBlur>
          {blur && (
            <OldDataFullview id = "OldDataFullview">
            <OldDataContent id = "olddatacontent">
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </OldDataFullview>
          )}
        </Card>
      </BarContainer>
    );
}

export default BarComponent;
