import React from "react";
import { IconView, DescriptionText1, DisFlex } from "./EMaintenance.styled";
import { useTranslation } from "react-i18next";
import i_collab from "../../images/icons/i_collab.svg";
export default function OnTime() {
  const { t } = useTranslation();
  return (
    <DisFlex>
      <IconView>
        <img src={i_collab} alt="collab" />
      </IconView>
      &nbsp;
      <DescriptionText1 task="open">
      {<b>{t("WOW")}</b>}, {t("your area has no issues")}
      </DescriptionText1>
    </DisFlex>
  );
}
