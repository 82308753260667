

import styled from "styled-components";

export const SitesContainer = styled.div`
  diplay: flex;
  padding: 0px 15px 15px 15px;
  flex-direction: column;
`;

export const ListView = styled.div`
  display: flex;
  flex: ${(props) =>
    props.index === 0 ? 0.25 : props.index === 5 ? 0.1 : 0.2};
  justify-content: ${(props) =>
   (props.index === 3 || props.index === 5)  ? "center" : "left"};
  overflow: hidden;
  align-items: flex-end;
`;

export const ListName = styled.div`
  font-family: Roboto-Light;
  font-size: 14px;
  font-weight: 300;
  color: #666;
`;
export const Listrole = styled.div`
  font-family: Roboto-Light;
  font-size: 14px;
  font-weight: 300;
  color: #666;
  padding-left: 2.5em;
`;

export const ListURL = styled.div`
  font-family: Roboto-Light;
  font-size: 14px;
  font-weight: 300;
  color: #666;
  padding-left: 0.2em;
`;

export const Listcategory = styled.div`
  font-family: Roboto-Light;
  font-size: 14px;
  font-weight: 300;
  color: #666;
  padding-right: 1em;
`;

export const Listtile = styled.div`
  font-family: Roboto-Light;
  font-size: 14px;
  font-weight: 300;
  color: #666;
  padding-left: ${(props) => (props.id === "tile" ? "0.5em" : "0.20em")};
`;
export const HorizontalList = styled.div`
  display: flex;
  flex: 1;
  padding: 8px 0px 8px 0px;
  overflow: hidden;

  background-color: ${(props) =>
    props?.index && (2 + props.index) % 2 === 1 ? "#ededed" : "white"};
`;
export const Image = styled.img`
  width: 18px;
  height: 17px;
`;

export const Checkbox = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  margin-right: 4px;
  border: solid 1px #009900;
`;

export const Icons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;
export const BodyContainer = styled.div`
  overflow-y: scroll;
  height: 270px;
`;

export const Selectionicons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-left: 5px;
`;

export const Iconsoptions = styled.img`
  height:20px;
  width: 20px;
  margin-right: 0.5em;
  margin-left: "0.5em"
`;

export const EditIcons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100px;
`;

export const Container = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.input`
  border-radius: 2px;
  border: ${(props) =>props.errorUI ? "solid 1px #bb453a" : "solid 1px #ededed"};
  height: 23px;
  width: 130px;
  font-family: Roboto-Light;
  font-size: 12px;
  font-weight: 300;
`;
export const ErrorMessage = styled.div`
  font-family: Roboto-Bold;
  font-size: 11px;
  font-weight: bold;
  color: #bb453a;
  margin-bottom: 5px;
`;

export const InputView = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const NoSearchresult = styled.div`
 display:flex;
 align-items:center;
 justify-content:center;
 font-family:Roboto-regular;
 font-size: 16px;
 width:inherit;
 height:inherit;
`;
 
 export const LeftAlign = styled.span`
  padding-right: ${(props) =>props.id ==="other" ? "1.5em" : ""};
`;
