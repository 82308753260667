/* Copyright (C) BP -
 * All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 7/6/2022
 * Author : Aditya Padmanabhan
 */

import { constants } from "../constants/reducerConstants";

function savenotificationInfo(data) {
  return (dispatch) => {
    dispatch({ type: constants.SAVE_NOTIFICATION_DATA, data: data });
  };
}

function setSeenData(data) {
  return (dispatch) => {
    dispatch({ type: constants.SET_SEEN_DATA, payload: data });
  };
}

function setSeenEmpty() {
  return (dispatch) => {
    dispatch({ type: constants.SET_SEEN_EMPTY });
  };
}

function setUnSeen(data) {
  return (dispatch) => {
    dispatch({ type: constants.SET_UNSEEN_DATA, payload: data });
  };
}

function setUnSeenEmpty() {
  return (dispatch) => {
    dispatch({ type: constants.SET_UNSEEN_EMPTY });
  };
}

function changeShowNotification() {
  return (dispatch, getState) => {
    const { NotificationInfo } = getState();
    if (NotificationInfo.showNotification) {
      dispatch({ type: constants.HIDE_NOTIFICATION });
    } else {
      dispatch({ type: constants.SHOW_NOTIFICATION });
    }
  };
}

function saveConnectionId(data) {
  return (dispatch) => {
    dispatch({ type: constants.SAVE_CONNECTION_ID, payload: data });
  };
}

export const NotificationActions = {
  savenotificationInfo,
  changeShowNotification,
  setSeenData,
  setSeenEmpty,
  setUnSeen,
  setUnSeenEmpty,
  saveConnectionId,
};
