import styled from "styled-components";
import { SiteListingContainer } from "../GoogleReviewDashboard/ReviewsDashboard.styled";

export const LinkItem = styled.div`
  width: 100%;
  padding: 2px;
  text-align: left;
  margin-left: ${(props) => props.isMobile ? '21px' : '76px'};
  &:hover{
    #color {
      color: #007f00;
    }
    #next {
      color: #383736;
      right: 75px;
      transition: 0.2s ease-in;
    }
    #next span {
      display: inline-block;
      transition: 0.2s ease-in;
      color: #007f00;
    }
    #next:hover span {
      transform: translateX(50%);
    }
  }
`;

export const LinkHeading = styled.div`
  display: flex;
  flex-direction: row;
`;
export const LinkTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  width: 100%;
  font-family: Roboto-Regular;
`;

export const LinkHeadingText = styled.div`
  font-family: Roboto-Light;
  font-size: 14px;
  color: #000;
  padding:  0px 2px 0px;
  margin-top:2px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex: wrap;
  height:520px;
  @media only screen and (max-width: 1200px) {
    overflow: scroll;
  };
`;

export const LinkFlex = styled.div`
  display: flex;
  height: 3rem;
  width: 20rem;
  @media only screen and (max-width: 1150px) {
    width: 15rem;
  };
`;

export const FlexText = styled.div`
  display: flex;
  flex: 1;
  font-family: Roboto-Light;
  font-size: 16px;
  align-self: flex-start;
  padding-right: 1px;
  padding-left: 1px;
  height: 100%;
  padding-left: ${(props) => (props.id !== "color" ? "10px" : "0px")};
`;

export const FlexArrow = styled(FlexText)`
  flex: 0.3;
`;

export const LinkPart = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  flex: ${(props) => (props.id === "links" ? "0.65" : "0.35")};
  border-left: ${(props) => (props.id === "tools" ? "1px solid grey" : "")};
  @media only screen and (max-width: 1200px) {
      flex: ${(props) => (props.id === "links" ? "0.50" : "0.50")};
  }
`;

export const Noresult = styled.div`
  display: flex;
  margin-top: 3em;
  margin-left: ${(props) => (props.id === "tools" ? "5.55em" : "5.8em")};
  justify-content: center;
`;

export const NoResultMobile = styled(Noresult)`
  margin-left: 21px;
  justify-content: left;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LinksTitle = styled.div`
  flex: 0.5;
  text-align: center;
  border-bottom: ${(props) => (props.isCurrentTab ? '3px solid #a1a1a1' : '1px solid #cccccc')};
  padding: 16px 0;
  font-family: Roboto-Regular;
  font-size: 14px;
  color:  ${(props) => (props.isCurrentTab ? '#000000' : '#7f7f7f')};
`;

export const ToolsTitle = styled.div`
  flex: 0.5;
  text-align: center;
  border-bottom: ${(props) => (props.isCurrentTab ? '3px solid #a1a1a1' : '1px solid #cccccc')};
  padding: 16px 0;
  font-family: Roboto-Regular;
  font-size: 14px;
  color:  ${(props) => (props.isCurrentTab ? '#000000' : '#7f7f7f')};
`;

export const LinksContainer = styled.div`
  height: 900px;
  margin-top:1.5rem;
  overflow-y: auto;
`;


export const Linkbox = styled.div`
  width: inherit;
  height: 50px;
`

export const LinkSectionContainer = styled(SiteListingContainer)`
  padding: 14px 0px 19px 0px;
  overflow: hidden;
`;
