/* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 25/11/2021
* Author : Sunil Vora
*/

import React from 'react';
import PropTypes from 'prop-types';
import { TableStyled, TableBody } from './Table.styled';
import TableHeader from './TableHeader';
import { SORT, SELECTION } from './Table.constants';

function renderChildren(children, data, onRenderChildren) {
    if (onRenderChildren) {
        return onRenderChildren();
    }

    return children;
}

export const Table = React.forwardRef(
    ({ children, data, onRenderChildren, caption, rowSpacing, isLayoutFixed, isStuck, customStyle, ...rest }, ref) => (
        <TableStyled rowSpacing={rowSpacing} className='is-fixed' >
            {caption && <caption className="u-srt">{caption}</caption>}
            <TableHeader ref={ref} {...rest} disableTabIndex={isStuck} customStyle={customStyle} />
            <TableBody>{renderChildren(children, data, onRenderChildren)}</TableBody>
        </TableStyled>
    )
);

Table.defaultProps = {
    customStyle: false,
    isStuck: false,
    selectionOptions: [],
    selectionState: SELECTION.NONE,
    sortState: {
        key: null,
        order: 'DESC'
    }
};

const headingObjectShape = PropTypes.shape({
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    sortable: PropTypes.bool.isRequired,
    width: PropTypes.string,
    textAlign:PropTypes.string,
    component: PropTypes.element
});

Table.propTypes = {
    caption: PropTypes.string,
    children: PropTypes.node,
    customStyle: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    fetching: PropTypes.bool,
    headings: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([headingObjectShape, PropTypes.element])),
        PropTypes.node
    ]),
    isLayoutFixed: PropTypes.bool,
    isStuck: PropTypes.bool,
    onRenderChildren: PropTypes.func,
    onSort: PropTypes.func,
    rowSpacing: PropTypes.string,
    selectionOptions: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            text: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
            action: PropTypes.func.isRequired
        })
    ),
    selectionState: PropTypes.oneOf([SELECTION.ALL, SELECTION.SOME, SELECTION.NONE]),
    sortState: PropTypes.shape({
        key: PropTypes.string,
        order: PropTypes.oneOf([SORT.ASCENDING, SORT.DESCENDING])
    })
};

export default Table;
