/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 8/3/2022
 * Author : Aditya Padmanabhan
 */

import React, { useEffect, useState } from "react";
import { HeaderView,  EmptyText } from "../tableReview/TableReview.styled";
import { useTranslation } from "react-i18next";
import search from "../../images/icons/search.png";
import i_linkandtool from "../../images/icons/i_linkandtool.svg";
import {
  LinkHeading, LinkHeadingText, LinkContainer, LinkTitle, LinkPart, Noresult,
  NoResultMobile, ImageContainer, TabContainer, LinksTitle, ToolsTitle, LinksContainer,
  LinkSectionContainer,Linkbox
} from "./LinkSection.Styled";
import { Col, Row } from "react-bootstrap";
import { SiteListingContainer } from '../GoogleReviewDashboard/ReviewsDashboard.styled';
import LinkCode from "./LinkCode";
import Search from '../search/search.js'
import { IconView } from "../E-Maintenance/EMaintenance.styled";
import isMobile from "../../helpers/isMobile";

function LinkSection(props) {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [nextdashboardUrl, setNextDashboardUrl] = useState([]);
  const [noData, setNoData] = useState(false);
  const [nextToolLink, setNextToolLink] = useState([]);
  const [searchShow, setSearchShow] = useState(true);
  const [currentTab, setCurrentTab] = useState('Links');
  useEffect(() => {
    changeValue(query);
  }, [query]);

  function changeValue(query) {
    if (query !== "") {
      const Linkresult = props.additionalLink.filter((link) =>
        link.name.toLowerCase().includes(query.toLowerCase())
      );

      const toolResult = props.additionalToolLinks.filter((link) =>
        link.name.toLowerCase().includes(query.toLowerCase())
      );
      if (Linkresult.length === 0 && toolResult.length === 0) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setNextDashboardUrl(Linkresult);
      setNextToolLink(toolResult);
    } else {
      const result = props.additionalLink;
      setNextDashboardUrl(result);
      const tr = props.additionalToolLinks;
      setNextToolLink(tr);
      setNoData(false);
    }
  }

  if (isMobile()) {
    const currentUrls = query.length === 0 ? currentTab === 'Links' ? nextdashboardUrl : nextToolLink : nextdashboardUrl.concat(nextToolLink);
    const noResultMobile =[];
    return (
      <LinkSectionContainer id='link'>
        <HeaderView isMobile={true}>
          {searchShow && <LinkHeading>
            <IconView>
              <img src={i_linkandtool} alt="collab" />
            </IconView>
            &nbsp;
            <LinkHeadingText>{t("LINKS AND TOOLS")}</LinkHeadingText>
          </LinkHeading>}
          {searchShow && <ImageContainer onClick={() => setSearchShow(false)}><img src={search} alt='search' /></ImageContainer>}
          {!searchShow && <Search isMobile={true} value={query} placeholder={t("Search a Link or Tool")}
            onChange={(e) => setQuery(e.target.value)}
            onClick={() => { setQuery(""); setSearchShow(true); }}
          />}
        </HeaderView>
        {searchShow && <TabContainer>
          <LinksTitle isCurrentTab={currentTab === 'Links'} onClick={() => setCurrentTab('Links')}>{t("Links")}</LinksTitle>
          <ToolsTitle isCurrentTab={currentTab === 'Tools'} onClick={() => setCurrentTab('Tools')}>{t("Tools")}</ToolsTitle>
        </TabContainer>}
        <LinksContainer>
          {noData && (
            <NoResultMobile>
              <EmptyText id='link'>{t("No results")}</EmptyText>
            </NoResultMobile>
          )}
           <Linkbox>
            {!searchShow && query.length === 0
              ? noResultMobile.map(() => {
                  return null;
                })
            :currentUrls.map((link) => {
              if (link.url !== '/') {
                return (
                <LinkCode isMobile={true} link={link} />
                 );
              }
              return null
            })}
          </Linkbox>
        </LinksContainer>
      </LinkSectionContainer>
    );
  }

  return (
    <SiteListingContainer id='link'>
      <HeaderView id='link'>
        <LinkHeading>
          <IconView>
            <img src={i_linkandtool} alt="collab" />
          </IconView>
          &nbsp;
          <LinkHeadingText>{t("LINKS AND TOOLS")}</LinkHeadingText>
        </LinkHeading>
        <Search value={query} placeholder={t("Search a Link or Tool")}
          onChange={(e) => setQuery(e.target.value)}
          onClick={() => { setQuery("") }}
        />
      </HeaderView>
      <LinkTitle>
        <div className="titleLink">
          <Row xs={1}>
            <Col>{t("LINKS")}</Col>
          </Row>
        </div>
        <div className="tilteTool">
          <Row xs={1}>
            <Col>{t("TOOLS")}</Col>
          </Row>
        </div>
      </LinkTitle>

      <LinkContainer className='top-adjusted-linkscontainer'>
        <LinkPart id="links">
          {noData && (
            <Noresult>
              <EmptyText id='link'>{t("No results")}</EmptyText>
            </Noresult>
          )}
          <div className="body-data mx-3">
            <Row xs={1} xl={2}>
              {nextdashboardUrl.map((link) => {
                if (link.url !== '/') {
                  return (
                    <Col>
                      <LinkCode link={link} />
                    </Col>
                  );
                }
                return null
              })}
            </Row>
          </div>
        </LinkPart>

        <LinkPart id="tools">
          {noData && (
            <Noresult id='tools'>
              <EmptyText id='link'>{t("No results")}</EmptyText>
            </Noresult>
          )}
          <div className="body-data mx-3">
            <Row xs={1} className='top-adjusted'>
              {nextToolLink.map((link) => {
                if (link.url !== '/') {
                  return (
                    <Col>
                      <LinkCode link={link} />
                    </Col>
                  );
                }
                return null
              })}
            </Row>
          </div>
        </LinkPart>
      </LinkContainer>
    </SiteListingContainer>
  );
}

export default LinkSection;
