import styled from "styled-components";

export const ScoreHeader = styled.div`
  ${(props) =>
    !props.isCoManager &&
    `
        flex: 1 1 auto;
    `}
  padding: ${(props) =>
    props.title === "Unpublished schedules" && props.isMobile
      ? "0 1px 0 6px"
      : "0 10px 0 6px"};
  background-color: ${(props) => props.color};
`;

export const ScoreTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 0 !important;
  height: ${(props) => (props.value === "card" ? "35px" : "34px")};
  flex: 1;
`;

export const ScoreKpiName = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  font-family: Roboto-Light;
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: normal;
  padding-top: 4px;
  color: ${(props) => props.textColor};
  @media only screen and (min-width: 300px) and (max-width: 500px) {
    font-size: 13px;
  }
`;

export const MobilePopup = styled.div`
  position: absolute;
  background-color: #353535;
  border-radius: 9px;
  top: ${(props) => `${props.top - 32}px`};
  left: ${(props) => `${props.left - 218}px`};
  width: ${(props) => `${props.width}em`};
  align-items: center;
  padding: 8px;
  justify-content: center;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    top: ${(props) => `${props.arrowTop}%`};
    left: ${(props) => `${props.arrowLeft}%`};
    border-width: 13px;
    border-style: solid;
    border-color: transparent transparent #353535 transparent;
  }
`;

export const ContainerEmaintaince = styled.div`
  padding-right: 0;
  margin-left: 0;
  margin-top:1em;
  border-radius: 10px;
  border: solid 0.5px #ccc;
  box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.06);
  background-color: #fff;
  padding-bottom: 5px;
`;

