import React, { useEffect } from 'react'
import { useState } from 'react'
import { Card } from 'react-bootstrap';
import isMobile from '../../helpers/isMobile';
import { Exclaimation, ExclaimationText, MobilePopup, Mobilesitedetails, 
Mobilesiteheading, Mobiletableheader, OldDataApprove, OldDataBlur,
OldDataContent, OldDataFullview, Oldtext, Persitedetails, Persitefield,
ScoreTitle, Sitetext, TableContainer, Tablemobilecontainer,TablemobilecontainerNew,
TooltipValue, ScoreKpiName , AlertIcon } from './ScoreCard.styled';
import { createMarkup, defaultSortOrder, getFieldFromKey, renderAlert } from './utils';
import i_icon from '../../images/icons/i_icon.svg';
import i_icon_info_bold_white from "../../images/icons/i_icon_info_bold_white.svg";
import i_icon_alert from '../../images/icons/i_icon_alert.svg';
import { isTablet } from '../../helpers/isTablet';
import cross_icon from '../../images/icons/cross_icon.svg';

function MobileTable(props) {
    const { state, title,  headers, data, lastWeekUpdated,toolTipText,expectedRefreshTimestamp } = props.data;
    
    const [sortedData, setSortedData] = useState(data);
    const [sortState, setSortState] = useState(data?.length > 0 ? defaultSortOrder(data) : []);
    const [site, setSite] = useState('');
    const [rect, setRect] = useState(0);
    const [showPopup, setShowPopup] = useState('');
    const [ blur, setBlur] = useState(props.weekNumber!==lastWeekUpdated)
    const [blurRect, setBlurRect] = useState(0);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
    useEffect(()=>{
      setSortedData(data);
      window.addEventListener('scroll', controlDirection);
        return () => {
            window.removeEventListener('scroll', controlDirection);
        };
   },[data]);
   const controlDirection = () => {
    setOldPopUpVisible(false)
    setShowPopup("")
}

   const handleHover = (event, name, type) => {
        const currRect = event.target.getBoundingClientRect();
        setTop(currRect.top);
        setLeft(currRect.left);
        if (type === 'leave') {
            setShowPopup('');
        } else {
            setShowPopup(name);
        }
    }

    const blurHover = (event, type) => {
        if(type==='enter'){
          setOldPopUpVisible(true)
            setBlurRect(event.target.getBoundingClientRect());
        }
        else{
            setBlurRect(0)
            setOldPopUpVisible(false)
        }
    }

    const renderValue = (headeritem, item, covertedHeader, headerIndex) => {
      if (headeritem === 'Labour vs demand' && renderAlert(item[covertedHeader])) {
        return (
          <Sitetext value={true} id = {"siteText"+headerIndex}>
            <AlertIcon src={i_icon_alert} alt="alert" />
            {Math.sign(parseInt(item[covertedHeader])) === 1 ? `+${item[covertedHeader]}%` : item[covertedHeader] !== 'NA' ? `${item[covertedHeader]}%`: item[covertedHeader]}
          </Sitetext>
        )
      } else if (headeritem === 'Labour vs demand' && !renderAlert(item[covertedHeader])) {
        return (
          <Sitetext value={true} id = {"siteText"+headerIndex}>
            {Math.sign(parseInt(item[covertedHeader])) === 1 ? `+${item[covertedHeader]}%` : item[covertedHeader] !== 'NA' ? `${item[covertedHeader]}%`: item[covertedHeader]}
          </Sitetext>
        )
      } else if (title === 'People Overview') {
        return (
          <Sitetext value={true} id = {"siteText"+headerIndex}>{item[covertedHeader] === 'NA'  ? `${item[covertedHeader]}`: `${item[covertedHeader]}%`}</Sitetext>
        )
      }
      return (
        <Sitetext value={true} id = {"siteText"+headerIndex}>{item[covertedHeader]}</Sitetext>
      )
    }

  return (
    <>
      <TableContainer width={100} displaydevice={isMobile()} id="tableContainer">
        <Card className="data-card" style={{ height: "279px" }}>
        <OldDataBlur boolTrue={blur} id= "OldDataBlur">
            {(!blur && props.weekNumber!==lastWeekUpdated) && <Exclaimation  id="exclamationContainer" onMouseEnter={(e)=>blurHover(e,'enter')} onMouseLeave={(e)=>blurHover(e,'leave')}><ExclaimationText id = "ExclaimationText">!</ExclaimationText></Exclaimation>}
                {oldPopUpVisible && <TooltipValue id = "tooltipvalue" top={blurRect.top+70} left={blurRect.left+15}>{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>}
          <Mobiletableheader id="mobileTableHeader">
            <ScoreTitle id="scoreTitle">
              <ScoreKpiName textColor={state === "NEUTRAL" ? "black" : "white"} id="scoreKpiName">
                {title.includes("Safety") ? "Safety Overview () = last week count" : title}
              </ScoreKpiName>
              {
                <div
                  className="safety-card-view"
                  onMouseEnter={(e) => handleHover(e, title, "enter")}
                  onMouseLeave={(e) => handleHover(e, "", "leave")}
                  onClick ={(e)=>{isMobile()&& showPopup === title ? handleHover(e, '', 'leave'):handleHover(e, title, 'enter')}}
                >
                {isTablet()?<img className="cross-class" alt="tile_info" src={ showPopup === title ?  cross_icon: i_icon} /> 
                          :<img className="cross-class" alt="tile_info" src={state === 'NEUTRAL' ? i_icon : i_icon_info_bold_white} />  }        
                </div>
              }
            </ScoreTitle>
          </Mobiletableheader>
          <TablemobilecontainerNew id="tableMobileContainerNew">
            {sortedData.map((item, idx) => {
                return <Persitedetails id="perSiteDetails">
                <Mobilesiteheading id="mobileSiteHeading">{item.sites}</Mobilesiteheading>
                <Mobilesitedetails id="mobileSiteHeading">
                    {headers.map((headeritem,key)=>{
                      const covertedHeader = getFieldFromKey(headeritem);
                        return(<>
                         {key!==0?<>
                          <Persitefield id="perSiteField">
                            <Sitetext id = "siteText">{headeritem}</Sitetext>
                            {renderValue(headeritem, item, covertedHeader,key)}
                          </Persitefield>
                         </>:null}
                        </>)
                    })}
                </Mobilesitedetails>
              </Persitedetails>;
            })}
            {(showPopup === title && title !== "People Overview") &&
                    <MobilePopup top={65} left={230} width={20} arrowLeft={-100} device='mobile' arrowTop={0} id='popUpMobile'>
                        <span className="What-you-can-expect-mobile">
                        <span key={toolTipText} dangerouslySetInnerHTML={createMarkup(toolTipText)}/>
                        </span>
                    </MobilePopup> 
            }    
            {(showPopup === title && title === "People Overview") &&
                    <MobilePopup top={65} left={230} width={20} arrowLeft={-100} device='mobile' arrowTop={0} id='popUpMobile'>
                        <span className="What-you-can-expect-mobile">
                        <span key={toolTipText} dangerouslySetInnerHTML={createMarkup(toolTipText)}/>
                        </span>
                    </MobilePopup> 
            }  
          </TablemobilecontainerNew>
          </OldDataBlur> 
           {blur && (
            <OldDataFullview id = "OldDataFullview">
            <OldDataContent id = "olddatacontent">
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </OldDataFullview>
          )}
        </Card>
      </TableContainer>
    </>
  );
}

export default MobileTable