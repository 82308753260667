/* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 25/11/2021
* Author : Sunil Vora
*/

import styled from 'styled-components';

export const BlankButton = styled.button`
    vertical-align: middle;
    padding: 0;
    border: 0;
    background-color: transparent;
    font: inherit;
    color: inherit;
    text-align: inherit;
`;

export const TableStyled = styled.table`
    width: 100%;
    display: flex;
    flex-flow: column;
    height: 100%;
    font-size: 0.75rem;
    border-spacing: 0 ${(props) => props.rowSpacing || 0};
    border-collapse: separate;

    &.is-fixed {
        table-layout: fixed;
    }

    @media (max-width: 992px) {
        display: block;
        
        .container & {
            width: auto;
            margin: 0 21px;
        }
    }
`;

export const TableHead = styled.thead`
    background-color: white;
    flex: 1;
    width: calc(100% - 0.9em);
    display: table;
    table-layout: fixed;
    @media (max-width: 992px) {
        display: none;
    }
`;

export const TableBody = styled.tbody`
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
    @media (max-width: 992px) {
        display: block;

        tr:not([class]),
        td:not([class]) {
            display: block;
        }

        td {
            max-width: none;
        }
    }
`;


export const HeadingCell = styled.th`
    font-family: 'Roboto-Regular';
    padding: ${(props) => {
        if (props.style && props.style.width === '0px') {
            return '0';
        }
        if (props.customStyle || !(props.style && props.style.width === '0px')) {
            return '1em 0.1em';
        }
        return '1em 0.25rem';
    }
    };
    text-align: ${(key) => key.index === 0?'left':'center'};
    max-width: none;
    color: #666666;
    font-weight:${(key) => key.children === 'Site name'?'normal':key.highLightColor?'Bold':'normal'};
    font-size: 12px;
    position: sticky;
    padding-right:4px;
    top: 0;
   
   
`;

export const MultiSelectCell = styled(HeadingCell)`
    width: 3rem;
    text-align: center;
    padding-left: 0.75em;
`;

export const HeadingButton = styled(BlankButton)`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content:center;
`;

export const SortIcon = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 0.3rem;

    > i {
        margin: -0.35em 0;
        font-size: 1rem;
    }
`;
