import styled from 'styled-components';

export const ButtonContainer = styled.div`
 border-radius: 5px;
 border: solid 1px #29a21f;
 padding:5px 8px 5px 8px;
 z-index:0;
 width:120px;
 display: inline-block;
 overflow: hidden;
 white-space: nowrap;
 display:flex;
 height: 35px;
align-items: center;
padding-left:15px;
&:hover {
  background-color: #e0e0e0; /* Change to the desired hover background color */
  cursor: pointer;
}
`

export const ButtonText = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.1px;
  color: #111;
 font-family: Roboto-Light;
 text-align:left;
 flex:1;
 display: inline-block;
 overflow: hidden;
 white-space: nowrap;
`
export const TriangleDesignDown = styled.div`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #999;
  font-size: 0;
  line-height: 0;
  float: left;
  margin:6px 3px 5px 3px;
`

export const TriangleDesignUp = styled.div`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  font-size: 0;
  line-height: 0;
  float: left;
  margin:6px 3px 5px 3px;
`
export const DropDownContainer = styled.div`
 padding: 4px 5px 9px 5px;
 border-radius: 2px;
 z-index:2;
 position:absolute;
 border: solid 1px #ebebeb;
 background-color: #fff;
 display:flex;
 flex-direction:column;
 height:105px;
 overflow-y:scroll;
 box-shadow: 0 1px 3px -2px;
`
export const DropDownContainerRm = styled.div`
 padding: 4px 5px 9px 5px;
 border-radius: 2px;
 z-index:2;
 position:absolute;
 border: solid 1px #ebebeb;
 background-color: #fff;
 display:flex;
 flex-direction:column;
 height:105px;
 overflow-y:scroll;
 box-shadow: 0 1px 3px -2px;
`
export const SelectionContainer = styled.div`
 display:flex;
 flex-direction:row;
 align-items:center;
 justify-content:space-between;
 padding-right:6px;
`
export const SelectionContainerRm = styled.div`
 display:flex;
 flex-direction:row;
 align-items:center;
 justify-content:space-between;
 padding-right:0px;
 cursor: pointer;
`
export const SelectionText = styled.span`
 margin: 4px 0px 4px 5px;
 font-family: Roboto-Regular;
 font-size: 12px;
 font-weight: 300;
 color: #000;
`
export const SelectionTextSubCat = styled.span`
 margin: 4px 0px 4px 5px;
 font-family: Roboto-Regular;
 font-size: 12px;
 font-weight: 300;
 color: #000;
 flex:8;
`
export const Checkbox = styled.div`
 height:16px;
 width:16px;
 border-radius: 2px;
 margin-right:4px;
 border: solid 1px #009900;
`
export const CircleRadioButton = styled.div`
 width: 14px;
 height: 14px;
 border-radius :7px;
 margin: 10px 1px 10px 2px;
 border: solid 1px #b6b6b6;
`
export const SelectedRadioButton = styled.div`
 width: 10px;
 height: 10px;
 border-radius :5px;
 margin:1px;
 background-color:#009901;
 border: solid 1px #b6b6b6;
`