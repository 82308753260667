import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import RegionalLoyalty from "../../../../scorecardNewDesign/Customer/Screens/RegionalLoyalty";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";


export const RegionalLoyaltyComponent = ({ regional_loyalty, getRegionalLoyaltyData , layout}) => {
  useEffect(() => {
    getRegionalLoyaltyData(KPINames.REGIONAL_LOYALTY);
  }, []);

  if (regional_loyalty.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (regional_loyalty.data) {
    return (
      (<RegionalLoyalty renderItems = {regional_loyalty.data} dataFor = {"Month"} weekNumber = {layout?.data?.weekNumber}/>)
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
  } = state;
  const regional_loyalty = scorecardImprovedInfo[KPINames.REGIONAL_LOYALTY];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return {  regional_loyalty, layout };
}

const mapDispatchToProps = {
  getRegionalLoyaltyData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RegionalLoyaltyComponent);
