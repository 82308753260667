import { constants } from "../constants/reducerConstants";

const initialState = {
  maintenanceData: {},
  error: "",
  isEmaintenanceLoading: true
};

export function eMaintenanceInfo(state = initialState, action) {
  switch (action.type) {
    case constants.EMAINTENANCE_SUCCESS:
      return {
        ...state,
        maintenanceData: action.data.body,
      };
    case constants.EMAINTENANCE_FAILURE:
      return {
        ...state,
        error: action.error,
        
      };
    case constants.EMAINTENANCE_LOADING_FAILED:
      return{
        ...state,
        isEmaintenanceLoading: false
      }
    case constants.EMAINTENANCE_LOADING_SUCCESS:
      return {
        ...state,
        isEmaintenanceLoading:true
      }
    default:
      return state;
  }
}
