import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { GraphLegend,LegendText } from "../../style/Customer.styled";
import { bpThemeGreenColor, CIRCULAR_RED,LEGEND_GRAY } from "../../../../../constants/colors";

export const Legends = (props)=>{
    const {color,value} = props.item
    return(
        <GraphLegend color = {color}>
             {value}
        </GraphLegend>

    )

}