/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/1/2022
 * Author : Aditya Padmanabhan
 */

import React, { useEffect } from "react";
import {Notificationcontainer,Heading,Header,Tickarea,Greentext,  NotificationCloseContainer, Nonotification} from "./Notification.styled";
import i_customer_insights from "../../images/icons/i_customer.svg";
import i_financials from "../../images/icons/i_financials.svg";
import i_siteranking from "../../images/icons/i_siteranking.svg";

import i_hsse from "../../images/icons/i_hsse.svg";
import i_operations from "../../images/icons/i_operations.svg";
import i_salesforce from "../../images/icons/i_salesforce.svg";
import { compose } from "redux";

import { connect } from "react-redux";
import {  isEmpty } from "lodash";
import Rowdetails from "./Rowdetails";
import i_linkandtool from "../../images/icons/i_linkandtool.svg";

import { NotificationActions } from "../../actions/NotificationAction";
import { useTranslation } from "react-i18next";

 export function Notification(props) {
  const { t } = useTranslation();
  
  useEffect(()=>{
    if(!props.showNotification){
      seenList();
      props.sendData();
    }
  })
  
   function changeStatus(key) {
     // to select elements in new section
     let unseen = [...props.unseen];
     let seen = [...props.seen];
     let json = {};
     if (props.unseen[key].seen) {
       unseen[key].seen = false;
     } else {
       unseen[key].seen = true;
     }
     json = {
       new: unseen,
       viewed: seen,
     };
     props.savenotificationInfo(json);
   }

  function changeSeenStatus(key) {
    // to select elements in viewed section
    let unseen = [...props.unseen];
    let seen = [...props.seen];
    let json = {};
    if (props.seen[key].seen) {
      seen[key].seen = false;
    } else {
      seen[key].seen = true;
    }
    json = {
      new: unseen,
      viewed: seen,
    };
    props.savenotificationInfo(json);
  }

   function MarkAllSeen() {                  // to make all new items as marked
    if (!isEmpty(props.unseen)) {
      let unseen =[...props.unseen]
      let seen = [...props.seen]
      let json ={}
      unseen.forEach((item)=>{
         item.seen=false;
      })
      json={
        new: unseen,
        viewed:seen
      }
      props.savenotificationInfo(json);
     }
  }

  

 function seenList() {
   // to move selected items from new to viewed and vice versa on closing notification
   let updatedSeenList = [];
   let updatedUnSeenList = [];
   if (!isEmpty(props.unseen)) {
     props.unseen.forEach((item) => {
       if (item.seen === false) {
         updatedSeenList.push(item);
       }
     });
     if (!isEmpty(updatedSeenList)) {
       if (isEmpty(props.SeenData)) {
         props.setSeenData(updatedSeenList);
       } else {
         let data = [];
         data = [...props.SeenData];
         data = [...data, ...updatedSeenList];
         props.setSeenData(data);
       }
       let changeSeenValues = [];
       let changeUnseenValues = [];
       let json = {};
       changeUnseenValues = [...props.unseen];
       changeSeenValues = [...props.seen];
       if (!isEmpty(updatedSeenList)) {
         updatedSeenList.forEach((item) => {
           changeSeenValues.push(item);
         });
         updatedSeenList.forEach((item) => {
           changeUnseenValues.splice(changeUnseenValues.indexOf(item), 1);
         });
       }
       json = {
         new: changeUnseenValues,
         viewed: changeSeenValues,
       };
       updatedSeenList = [];
       props.savenotificationInfo(json);
     }
   }
   if (!isEmpty(props.seen)) {
     props.seen.forEach((item) => {
       if (item.seen === true) {
         updatedUnSeenList.push(item);
       }
     });
     if (!isEmpty(updatedUnSeenList)) {
       if (isEmpty(props.UnSeenData)) {
         props.setUnSeen(updatedUnSeenList);
       } else {
         let data = [];
         data = [...props.UnSeenData];
         data = [...data, ...updatedUnSeenList];
         props.setUnSeen(data);
       }
       let changeSeenValues = [];
       let changeUnseenValues = [];
       let json = {};
       changeUnseenValues = [...props.seen];
       changeSeenValues = [...props.unseen];

       if (!isEmpty(updatedUnSeenList)) {
         updatedUnSeenList.forEach((item) => {
           changeSeenValues.push(item);
         });
         updatedUnSeenList.forEach((item) => {
           changeUnseenValues.splice(changeUnseenValues.indexOf(item), 1);
         });
       }
       json = {
         new: changeSeenValues,
         viewed: changeUnseenValues,
       };
       updatedUnSeenList = [];
       props.savenotificationInfo(json);
     }
   }
 }
   
  function stringAndIconSetter(data) {
    let text = "";
    let icon;
    let firstWord = data.folderName.split("/");
    if (firstWord === "Financial%20Performance") {
      firstWord = firstWord.replace("%20", " ");
    }

    switch (data.action) {
      case "MODIFY":
        text = t("got updated");
        break;
      case "ADD":
        text = t("got added");
        break;
      case "DELETE":
        text = t("got deleted");
        break;
      default:
        break;
    }
    switch (firstWord[0]) {
      case "Financial Performance":
        icon = i_financials;
        break;
      case "Customer Insights":
        icon = i_customer_insights;
        break;
      case "Site Ranking":
        icon = i_siteranking;
        break;
      case "HSSE":
        icon = i_hsse;
        break;
      case "Operations":
        icon = i_operations;
        break;
      case "WYNIKI FINANSOWE":
        icon = i_financials;
        break;
      case "KLIENT":
        icon = i_customer_insights;
        break;
      case "OPERACYJNE":
        icon = i_operations;
        break;
      case "SALESFORCE":
        icon = i_salesforce;
        break;
      case "RANKINGI STACYJNE":
        icon = i_siteranking;
        break;
      default:
        icon = i_linkandtool;
        break;
    }
    return { text, icon };
  }
 
  if (props.showNotification) {
    return (
      <div style={{height:'100%', overflow: 'hidden',  
      backgroundColor:'transparent'}} data-test="notification-container">
      <NotificationCloseContainer onClick={()=> props.closeNotification()}/>
     <Notificationcontainer >
        <Header>
          <Heading>{t("New")}</Heading>
          <Tickarea>
            <Greentext onClick={() => MarkAllSeen()}>
              {t("Mark all as seen")}
            </Greentext>
          </Tickarea>
        </Header>
        {props.unseen.length > 0 ?
          props.unseen.map((data, key) => {
           let result   = stringAndIconSetter(data)
            return (
              <Rowdetails
                key = {key}
                data-test="unseen-container"
                data={data}
                icon={result.icon}
                id = 'new'
                String={result.text}
                index={key}
                status={data.seen}
                changeStatus={(value) => changeStatus(value)}
              />
            );
          }):<Nonotification>{t("No new notifications")}</Nonotification>}
        <Header>
          <Heading>{t("Viewed")}</Heading>
        </Header>
        {props.seen.length > 0 &&
          props.seen.map((data, key) => {
            let result   = stringAndIconSetter(data)
            if (data !== undefined) {
              return (
                <Rowdetails
                key = {key}
                data-test="seen-container"
                data={data}
                icon={result.icon}
                String={result.text}
                index={key}
                id='viewed'
                status={data.seen}
                changeStatus={(value) => changeSeenStatus(value)}
              />
              );
            } else {
              return null;
            }
          })}
      </Notificationcontainer></div>
    );
  } else {
    return null;
  } 
}
const actionCreators = {
  savenotificationInfo: NotificationActions.savenotificationInfo,
  setSeenData: NotificationActions.setSeenData,
  setUnSeen: NotificationActions.setUnSeen,
 
};

function mapStateToProps(state) {
  const { NotificationInfo } = state;
  const {seen,unseen,initialTimeStamp,SeenData,UnSeenData,showNotification,ws,} = NotificationInfo;
  return {seen,unseen,initialTimeStamp,SeenData,showNotification,ws,UnSeenData};
}

export default compose(connect(mapStateToProps, actionCreators))(Notification);
