/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/10/2022
 */

import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from 'redux';
import { isTablet } from "../../../../helpers/isTablet";
import Emaintanence from "../../../E-MaintenanceNew/Emaintanence";
import { TileHeader } from "../../Customer/Screens/Components/TileHeader";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import { ContainerEmaintaince, MobilePopup } from "../styles/emaintaince.styled";
import { createMarkup } from "../utils/Utilities";

function EmaintenanceWrapper(props) {
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState(false);
    const [top, setTop] = useState(0);
    const [left, setLeft] =useState(0);

    const handleHover = (event, name, type) => {
        const currRect = event.target.getBoundingClientRect();
        setTop(currRect.top);
        setLeft(currRect.left);
        if (type === 'leave') {
            setShowPopup(false);
        } else {
            setShowPopup(true);
        }
    }

    return (
      <ContainerEmaintaince  id="col">
       <TileHeader
        showPopup={showPopup}
        handleHover={(e, value) => handleHover(e,"", value)}
        title=  {t("Emaintenance_header")}
        state={'GREY'}
        eMaintenance = {true}
      />
        {isTablet()
            ? showPopup === "eMaintenance" && (
                <MobilePopup
                  top={70}
                  left={left - 20}
                  width={15}
                  arrowTop={0}
                  arrowLeft={-100}
                  device="mobile"
                  id="scoreKpiName"
                >
                  <span className="What-you-can-expect">
                    <span
                      key={t("Emaintenance_Popup_Text")}
                      dangerouslySetInnerHTML={createMarkup(
                        t("Emaintenance_Popup_Text")
                      )}
                    />
                  </span>
                </MobilePopup>
              )
            : 
              showPopup && (
                <ScorePopupContainerWeb
                top={(top)}
                left={left-20}
                width = {18}
                 id='popUpDesktop'
                 textDescription = {t("Emaintenance_Popup_Text")}
              />
             )
             
             }
          <Emaintanence isCoManager={true} class="image-cover" id="eMaintenance"/>
      </ContainerEmaintaince>
    )
}

const mapStateToProps = (state) => {
    const { scorecardInfo } = state;
    const { kpiName } = scorecardInfo;
    return { kpiName };
}

export default compose(
    connect(mapStateToProps, null)
)(withTranslation()(EmaintenanceWrapper));