/* Copyright (C) BP -
 * All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 07/12/2021
 * Author : Arun Rapolu
 */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import bpImage from "../../images/hazard-icon.svg";
import { hideErrorPage } from "../../helpers/commons";
import { ErrorPageContainer, ErrorPageHeading, ErrorPageMessage, ErrorPageHomeButton } from './Error.styled';
import { authActions } from "../../actions/authActions";

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

static getDerivedStateFromError(error) {
    return { hasError: true };
}

 componentDidUpdate(prevProps) {
    if(this.props.location !== prevProps.location){
      this.props.clearErrorPage();
      this.setState({hasError: false});
    }
  }

  gotoHome(){
    this.props.clearErrorPage();
    this.props.history.push("/");
  }

  render() {
    const { t, errorCode } = this.props;
    let errorMessage = null;
    switch(errorCode){
      case 400:
      case 500:
        errorMessage= t("something_went_wrong");
        break;
      case 401:
      case 404:
        errorMessage = t("unauthorize_message");
        break;
      default:
        errorMessage = t("something_went_wrong");
    }
    if(this.state.hasError || this.props.showErrorPage) {
      return (
        <div className="container-body">
          <div className="container">
            <ErrorPageContainer>
              <div className="row">
                <div className="col-md-6">
                    {errorCode!== 401 ?<ErrorPageHeading>Oops</ErrorPageHeading> : <ErrorPageHeading>Session has expired</ErrorPageHeading> }
                    {errorCode!== 401 ?<ErrorPageMessage className="mt-4">{errorMessage}</ErrorPageMessage>:<ErrorPageMessage>Please login again</ErrorPageMessage>}
                    <div className="mt-4 pb-4">
                      {errorCode!== 401 ?<ErrorPageHomeButton onClick={() => this.gotoHome()}>Home</ErrorPageHomeButton>:<ErrorPageHomeButton onClick={() => this.props.userLogout()}>Please login again</ErrorPageHomeButton>}
                    </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <img className="float-right align-bottom w-100" alt="Internal Server Error" src={bpImage} />
                  </div>
                </div>
              </div>
            </ErrorPageContainer>
          </div>
        </div>
      )
    }
    else
    return this.props.children;
  }
}

function mapState(state) {
  const { errorInfo } = state;
  const { showErrorPage, errorCode } = errorInfo;
  return { showErrorPage, errorCode };
}

const actionCreators = {
  clearErrorPage: hideErrorPage,
  userLogout: authActions.userLogout,
}

export default compose(
  withRouter,
  connect(mapState, actionCreators),
  withTranslation("translations")
)(Error);
