export const scoreCardData = {
  Month: [
    // {
    //   sectionHeader: "Trading Profitably",
    //   weekNumber: 13,
    //   startDate: "27 Mar",
    //   endDate: "02 Apr",
    //   components: [
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: "4",
    //       total: "11",
    //       title: "Shop Sales vs Plan",
    //       state: "OK",
    //       toolTipText:
    //         "This widget displays a trend graph showing (%) to plan for shop (currency) within selected region & the UK as a whole. The header will display as red, if the last closed week performance is behind plan, and similarly displayed green if above plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance Vs Plan as well as Vs LFL (Like for Like - sales vs same week last year)",
    //       displayGraph: true,
    //       graph: [
    //         {
    //           weekNumber: 9,
    //           avgCountry: -1.86,
    //           avgRegionalManager: -5.2,
    //           totalRegionalManager: 1523479,
    //         },
    //         {
    //           weekNumber: 10,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //         },
    //         {
    //           weekNumber: 11,
    //           avgCountry: -1.94,
    //           avgRegionalManager: -0.2,
    //           totalRegionalManager: 1778646,
    //         },
    //         {
    //           weekNumber: 12,
    //           avgCountry: 0.49,
    //           avgRegionalManager: 1.5,
    //           totalRegionalManager: 1611745,
    //         },
    //         {
    //           weekNumber: 13,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //           varLFL: 12.4,
    //         },
    //       ],
    //       headers: ["Sites", "Wk Sales", "Wk Var to Plan", "Wk Var LFL", "MTD Sales", "MTD Var vs Plan", "MTD Var LFL"],
    //       data: [
    //         {
    //           sites: "Holborough",
    //           wk_Sales: 3130,
    //           wk_Var_to_plan: -11.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 11503,
    //           MTD_vs_Plan: -11.9,
    //           MTD_Var_LFL: -8.1
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -12.3,
    //           wk_varLFL: -7.1,
    //           MTD_Sales: 12503,
    //           MTD_vs_Plan: -17.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Hove",
    //           wk_Sales: 3135,
    //           wk_Var_to_plan: -5.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 11503,
    //           MTD_vs_Plan: -10.9,
    //           MTD_Var_LFL: -11.1
    //         },
    //         {
    //           sites: "Total",
    //           wk_Sales: 3135,
    //           wk_Var_to_plan: -5.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 11503,
    //           MTD_vs_Plan: -10.9,
    //           MTD_Var_LFL: -11.1
    //         },
    //         {
    //           sites: "Wisely North",
    //           wk_Sales: 3138,
    //           wk_Var_to_plan: -20.3,
    //           wk_varLFL: -14.1,
    //           MTD_Sales: 9503,
    //           MTD_vs_Plan: -4.9,
    //           MTD_Var_LFL: -5.1
    //         },
    //         {
    //           sites: "Malling",
    //           wk_Sales: 3148,
    //           wk_Var_to_plan: -43,
    //           wk_varLFL: -12.1,
    //           MTD_Sales: 8503,
    //           MTD_vs_Plan: -1.9,
    //           MTD_Var_LFL: -10.1
    //         },
    //         {
    //           sites: "Chatham",
    //           wk_Sales: 3101,
    //           wk_Var_to_plan: -22.3,
    //           wk_varLFL: -3.1,
    //           MTD_Sales: 7503,
    //           MTD_vs_Plan: -12.9,
    //           MTD_Var_LFL: -4.1
    //         },
    //         {
    //           sites: "Alfod",
    //           wk_Sales: 3032,
    //           wk_Var_to_plan: -16.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 5503,
    //           MTD_vs_Plan: -11.9,
    //           MTD_Var_LFL: -14.1
    //         },
    //         {
    //           sites: "Blueboys",
    //           wk_Sales: 3232,
    //           wk_Var_to_plan: -30.3,
    //           wk_varLFL: -12.1,
    //           MTD_Sales: 12503,
    //           MTD_vs_Plan: -19.9,
    //           MTD_Var_LFL: -19.1
    //         },
    //         {
    //           sites: "Hilden",
    //           wk_Sales: 3182,
    //           wk_Var_to_plan: -15.3,
    //           wk_varLFL: -10.1,
    //           MTD_Sales: 10303,
    //           MTD_vs_Plan: -10.9,
    //           MTD_Var_LFL: -19.1
    //         },
    //         {
    //           sites: "Wisely South",
    //           wk_Sales: 3332,
    //           wk_Var_to_plan: -33.3,
    //           wk_varLFL: -13.1,
    //           MTD_Sales: 10003,
    //           MTD_vs_Plan: -21.9,
    //           MTD_Var_LFL: -19.1
    //         },
    //         {
    //           sites: "Cheriton",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "LeatherHead",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Croft Road",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Lenham",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Country Oak Connect",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         // {
    //         //   sites: "total",
    //         //   wk_Sales: 3132,
    //         //   wk_Var_to_plan: -13.3,
    //         //   wk_varLFL: -9.1,
    //         //   MTD_Sales: 10503,
    //         //   MTD_vs_Plan: -14.9,
    //         //   MTD_Var_LFL: -9.1
    //         // },

    //       ],
    //       lastWeekUpdated: 12,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: "6",
    //       total: "11",
    //       title: "Fuel Volume vs Plan",
    //       state: "OK",
    //       toolTipText:
    //         "This widget displays a trend graph showing (%) to plan for fuel (litres)  within selected region & the UK as a whole. The header will display as red, if the last closed week performance is behind plan, and similarly displayed green if above plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance Vs Plan as well as Vs LFL (Like for Like - sales vs same week last year)",
    //       displayGraph: true,
    //       graph: [
    //         {
    //           weekNumber: 9,
    //           avgCountry: 6.72,
    //           avgRegionalManager: 5.3,
    //           totalRegionalManager: 2393863,
    //         },
    //         {
    //           weekNumber: 10,
    //           avgCountry: 5.48,
    //           avgRegionalManager: 9.7,
    //           totalRegionalManager: 2461134,
    //         },
    //         {
    //           weekNumber: 11,
    //           avgCountry: 13.97,
    //           avgRegionalManager: 20.1,
    //           totalRegionalManager: 2598342,
    //         },
    //         {
    //           weekNumber: 12,
    //           avgCountry: 10.15,
    //           avgRegionalManager: 17.8,
    //           totalRegionalManager: 2565496,
    //         },
    //         {
    //           weekNumber: 13,
    //           avgCountry: 5.48,
    //           avgRegionalManager: 9.7,
    //           totalRegionalManager: 2461134,
    //           varLFL: -4.8,
    //         },
    //       ],
    //       headers: ["Sites", "Wk Vol", "Wk Var to Plan", "Wk Var LFL", "MTD Vol", "MTD Var vs Plan", "MTD Var LFL"],
    //       data: [
    //         {
    //           sites: "Holborough",
    //           wk_Vol: 3345,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Vol: 3367,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Hove",
    //           wk_Vol: 3276,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Wisely North",
    //           wk_Vol: 3167,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Malling",
    //           wk_Vol: 3890,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Chatham",
    //           wk_Vol: 3567,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Alfod",
    //           wk_Vol: 3130,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Blueboys",
    //           wk_Vol: 3167,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Hilden",
    //           wk_Vol: 3137,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Wisely South",
    //           wk_Vol: 3135,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Cheriton",
    //           wk_Vol: 3133,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "LeatherHead",
    //           wk_Vol: 3130,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Croft Road",
    //           wk_Vol: 3129,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Lenham",
    //           wk_Vol: 3128,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Country Oak Connect",
    //           wk_Vol: 3127,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "total",
    //           wk_Vol: 3126,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Vol: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },

    //       ],
    //       lastWeekUpdated: 13,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: "8",
    //       total: "11",
    //       title: "Services vs Plan",
    //       state: "OK",
    //       toolTipText:
    //         "This widget displays a trend graph showing (%) to plan for services (currency) within selected region & the UK as a whole. The header will display as red, if the last closed week performance is behind plan, and similarly displayed green if above plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance Vs Plan as well as Vs LFL (Like for Like - sales vs same week last year)",
    //       displayGraph: true,
    //       graph: [
    //         {
    //           weekNumber: 9,
    //           avgCountry: -1.86,
    //           avgRegionalManager: -5.2,
    //           totalRegionalManager: 1523479,
    //         },
    //         {
    //           weekNumber: 10,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //         },
    //         {
    //           weekNumber: 11,
    //           avgCountry: -1.94,
    //           avgRegionalManager: -0.2,
    //           totalRegionalManager: 1778646,
    //         },
    //         {
    //           weekNumber: 12,
    //           avgCountry: 0.49,
    //           avgRegionalManager: 1.5,
    //           totalRegionalManager: 1611745,
    //         },
    //         {
    //           weekNumber: 13,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //           varLFL: 12.4,
    //         },
    //       ],
    //       headers: ["Sites", "Wk Sales", "Wk Var to Plan", "Wk Var LFL", "MTD Sales", "MTD Var vs Plan", "MTD Var LFL"],
    //       data: [
    //         {
    //           sites: "Holborough",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Hove",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Wisely North",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Malling",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Chatham",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Alfod",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Blueboys",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Hilden",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Wisely South",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Cheriton",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "LeatherHead",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Croft Road",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Lenham",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Country Oak Connect",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },

    //       ],
    //       lastWeekUpdated: 13,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: "8",
    //       total: "11",
    //       title: "Wild Bean Cafe vs Plan",
    //       state: "OK",
    //       toolTipText:
    //         "This widget displays a trend graph showing (%) to plan for services (currency) within selected region & the UK as a whole. The header will display as red, if the last closed week performance is behind plan, and similarly displayed green if above plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance Vs Plan as well as Vs LFL (Like for Like - sales vs same week last year)",
    //       displayGraph: true,
    //       graph: [
    //         {
    //           weekNumber: 9,
    //           avgCountry: -1.86,
    //           avgRegionalManager: -5.2,
    //           totalRegionalManager: 1523479,
    //         },
    //         {
    //           weekNumber: 10,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //         },
    //         {
    //           weekNumber: 11,
    //           avgCountry: -1.94,
    //           avgRegionalManager: -0.2,
    //           totalRegionalManager: 1778646,
    //         },
    //         {
    //           weekNumber: 12,
    //           avgCountry: 0.49,
    //           avgRegionalManager: 1.5,
    //           totalRegionalManager: 1611745,
    //         },
    //         {
    //           weekNumber: 13,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //           varLFL: 12.4,
    //         },
    //       ],
    //       headers: ["Sites", "Wk Sales", "Wk Var to Plan", "Wk Var LFL", "MTD Sales", "MTD Var vs Plan", "MTD Var LFL"],
    //       data: [
    //         {
    //           sites: "Holborough",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Hove",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Wisely North",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Malling",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Chatham",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Alfod",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Blueboys",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Hilden",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Wisely South",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Cheriton",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "LeatherHead",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Croft Road",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Lenham",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "Country Oak Connect",
    //           wk_Sales: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_varLFL: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },

    //       ],
    //       lastWeekUpdated: 13,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: "10",
    //       total: "11",
    //       title: "Waste",
    //       state: "OK",
    //       toolTipText:
    //         "This widget displays a trend graph showing (%) to plan for waste, including waste for Branded, M&S & Wild Bean Cafe. The header will display as red, if the last closed week performance is above plan, and similarly displayed green if below plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance in terms of actual waste in currency, waste % to planned waste and waste % of shop sales excluding services",
    //       displayGraph: true,
    //       graph: [
    //         {
    //           weekNumber: 9,
    //           avgCountry: -1.86,
    //           avgRegionalManager: -5.2,
    //           totalRegionalManager: 1523479,
    //         },
    //         {
    //           weekNumber: 10,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //         },
    //         {
    //           weekNumber: 11,
    //           avgCountry: -1.94,
    //           avgRegionalManager: -0.2,
    //           totalRegionalManager: 1778646,
    //         },
    //         {
    //           weekNumber: 12,
    //           avgCountry: 0.49,
    //           avgRegionalManager: 1.5,
    //           totalRegionalManager: 1611745,
    //         },
    //         {
    //           weekNumber: 13,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //           varLFL: 12.4,
    //         },
    //       ],
    //       headers: ["Sites", "Wk Loss", "Wk Loss % to Sales", "MTD Sales", "MTD Loss % to Sales"],
    //       data: [
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Loss: 3132,
    //           wk_Loss_Perc_to_Sales: -13.3,
    //           MTD_Sales: -9.1,
    //           MTD_Loss_perc_to_Sales: -14.9,
    //         },

    //       ],
    //       lastWeekUpdated: 12,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: "11",
    //       total: "11",
    //       title: "Till Losses",
    //       state: "DANGER",
    //       toolTipText:
    //         'This widget displays a trend of cash losses based on shop only for the region as well as the UK. Till losses are made up of "Till Shorts" and "EFT" shorts as seen in All Sites Report. The header will be red if the till loss absolute value is greater than zero (0&ltLoss), and green if the loss value is equal to zero (Loss=0). The site table shows individual cash loss as well as the % which the loss makes up of total sales',
    //       displayGraph: true,
    //       graph: [
    //         {
    //           weekNumber: 9,
    //           avgCountry: -1.86,
    //           avgRegionalManager: -5.2,
    //           totalRegionalManager: 1523479,
    //         },
    //         {
    //           weekNumber: 10,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //         },
    //         {
    //           weekNumber: 11,
    //           avgCountry: -1.94,
    //           avgRegionalManager: -0.2,
    //           totalRegionalManager: 1778646,
    //         },
    //         {
    //           weekNumber: 12,
    //           avgCountry: 0.49,
    //           avgRegionalManager: 1.5,
    //           totalRegionalManager: 1611745,
    //         },
    //         {
    //           weekNumber: 13,
    //           avgCountry: -0.82,
    //           avgRegionalManager: 1.1,
    //           totalRegionalManager: 1593861,
    //           varLFL: 12.4,
    //         },
    //       ],
    //       headers: ["Sites", "Wk Waste", "Wk Var to Plan", "Wk % to Sales", "MTD Waste", "MTD Var vs Plan", "MTD % to Sales"],
    //       data: [
    //         {
    //           sites: "Holborough",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Holborough",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Hove",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Wisely North",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Malling",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Chatham",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Alfod",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Blueboys",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Hilden",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Wisely South",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Cheriton",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Sales: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Var_LFL: -9.1
    //         },
    //         {
    //           sites: "LeatherHead",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Croft Road",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Lenham",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //         {
    //           sites: "Country Oak Connect",
    //           wk_Waste: 3132,
    //           wk_Var_to_plan: -13.3,
    //           wk_Waste_Perc_to_Sales: -9.1,
    //           MTD_Waste: 10503,
    //           MTD_vs_Plan: -14.9,
    //           MTD_Perc_To_Sales: -9.1
    //         },
    //       ],
    //       lastWeekUpdated: 12,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //   ],
    // },
    {
      sectionHeader: "Customer",
      weekNumber: 13,
      startDate: "27 Mar",
      endDate: "02 Apr",
      components: [
        {
          type: "tile2",
          arrow: null,
          rank: "11",
          total: "11",
          title: "Customer Satisfaction",
          state: "NEUTRAL",
          toolTipText:
            "This widget contains data relevant to the voice of the customer. Data is available at a weekly level, showing the overall CSAT score for your region as well as the CSAT score for the country as a whole, with number of surveys completed shown within the CSAT barometers. Within the site table you will see individual CSAT scores per site, Wow and Rescue moments, as well as Wow and Rescue moments as a % of survey responses recorded",
          displayGraph: true,
          graph: [
            {
              graphText: { heading: "CSAT Score", subHeading: "Sat & V.Sat" },
              data: [
                { description: "ADAM WHITEHOUSE", percent: 89, count: 136 },
                { description: "United Kingdom", percent: 24, count: 1574 },
              ],
              legend: [
                { value: "0% - 24%", color: "#e64949", description: "Bad" },
                { value: "25% - 74%", color: "#999999", description: "Neutral" },
                { value: "75% - 100%", color: "#007833", description: "Great" },
              ],

            },
            {
              barGraphData: [
                { weekNumber: 9, avgCountry: 25, avgRegionalManager: 15 },
                { weekNumber: 10, avgCountry: 35, avgRegionalManager: 30 },
                { weekNumber: 11, avgCountry: 40, avgRegionalManager: 50 },
                { weekNumber: 12, avgCountry:65, avgRegionalManager: 70 },
                { weekNumber: 13, avgCountry: 70, avgRegionalManager: 90 },
              ]
            }
          ],
          headers: ["Sites", "CSAT Score", "Wow Moments", "Wow to total", "Rescue to total"],
          data: [
            {
              sites: "The Buck",
              csat_score: 28,
              wow_moments: 1,
              wow_total: 9.1,
              rescue_total: 87,
            },
            {
              sites: "UNIVERSITY WAY SF CONNECT",
              csat_score: 65,
              wow_moments: 10,
              wow_total: 15,
              rescue_total: 55,
            },
            {
              sites: "Great Torrington",
              csat_score: 61,
              wow_moments: 8,
              wow_total: 9,
              rescue_total: 66,
            },
            {
              sites: "Blackbird Hill",
              csat_score: 60,
              wow_moments: 7,
              wow_total: 31,
              rescue_total: 81,
            },
            {
              sites: "Blue Boys",
              csat_score: 58,
              wow_moments: 6,
              wow_total: 230,
              rescue_total: 99,
            },
            {
              sites: "Blackbird Hill",
              csat_score: 57,
              wow_moments: 5,
              wow_total: 29,
              rescue_total: 97,
            },
            {
              sites: "Blackbird",
              csat_score: 56,
              wow_moments: 4,
              wow_total: 28,
              rescue_total: 64,
            }
          ],
          lastWeekUpdated: 12,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
        {
          type: "comparator2",
          title: "Loyalty Issuance () = last week count",
          state: "NEUTRAL",
          rank: 1,
          toolTipText:
            "This widget will provide data around loyalty issuance. The left hand bar chart shows target issuance and actual issuance per site (ranked highest to lowest by deficit). The percentages in brackets on the right of these bars represents the previous weeks issuance per site. The middle bar below regional manager name displays the overall regional loyalty issuance achieved. On the right you will see how you have performed in relation to your colleagues !",
          data: [
            {
              sites: "Malling",
              loyaltyScore: 5,
              targetScore: 15,
              lastWeek: 13,
            },
            {
              sites: "Gatwick South",
              loyaltyScore: 15,
              targetScore: 16,
              lastWeek: 16,
            },
            {
              sites: "Blue Boys",
              loyaltyScore: 10,
              targetScore: 15,
              lastWeek: 13,
            },
            {
              sites: "Hove",
              loyaltyScore: 16,
              targetScore: 16,
              lastWeek: 14,
            },
            {
              sites: "Hillside",
              loyaltyScore: 16,
              targetScore: 16,
              lastWeek: 15,
            },
            {
              sites: "Wisley South",
              loyaltyScore: 15,
              targetScore: 15,
              lastWeek: 16,
            },
            {
              sites: "Cheriton",
              loyaltyScore: 17,
              targetScore: 17,
              lastWeek: 16,
            },
            {
              sites: "Leatherhead",
              loyaltyScore: 15,
              targetScore: 17,
              lastWeek: 19,
            },
            {
              sites: "University Way",
              loyaltyScore: 12,
              targetScore: 15,
              lastWeek: 14,
            },
            {
              sites: "Alfold",
              loyaltyScore: 11,
              targetScore: 17,
              lastWeek: 19,
            },
            {
              sites: "Lenham",
              loyaltyScore: 13,
              targetScore: 17,
              lastWeek: 20,
            },
            {
              sites: "Tudor",
              loyaltyScore: 15,
              targetScore: 16,
              lastWeek: 18,
            },
            {
              sites: "Willingdon",
              loyaltyScore: 18,
              targetScore: 20,
              lastWeek: 20,
            },
            {
              sites: "County Oak Connect",
              loyaltyScore: 17,
              targetScore: 19,
              lastWeek: 18,
            },
            {
              sites: "Hilden",
              loyaltyScore: 18,
              targetScore: 21,
              lastWeek: 20,
            },
            {
              sites: "Pevensey",
              loyaltyScore: 12,
              targetScore: 17,
              lastWeek: 22,
            },
            {
              sites: "Wisley North",
              loyaltyScore: 12,
              targetScore: 15,
              lastWeek: 11,
            },
          ],
          headers: ["Sites", "Loyalty Score", "Target Score", "Last Week", "Loyalty Range", ""],
          graph: [
            {
              weekNumber: 9,
              avgCountry: -1.86,
              avgRegionalManager: -5.2,
              totalRegionalManager: 1523479,
            },
            {
              weekNumber: 10,
              avgCountry: -0.82,
              avgRegionalManager: 1.1,
              totalRegionalManager: 1593861,
            },
            {
              weekNumber: 11,
              avgCountry: -1.94,
              avgRegionalManager: -0.2,
              totalRegionalManager: 1778646,
            },
            {
              weekNumber: 12,
              avgCountry: 0.49,
              avgRegionalManager: 1.5,
              totalRegionalManager: 1611745,
            },
            {
              weekNumber: 13,
              avgCountry: -0.82,
              avgRegionalManager: 1.1,
              totalRegionalManager: 1593861,

            },
          ],
          lastWeekUpdated: 10,
          expectedRefreshTimestamp: "Tuesday 3:00PM",
        },
        {
          type: "ratings",
          toolTipText: "Some text here",
          header: ["Loyalty Ranking", "Score", "Target"],
          state: "DANGER",
          data: {
            rmTarget: [{
              ActualTarget: 14.2,
              desiredTarget: 15.3
            }],
            allRmData: [
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Adam Whitehouse", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Clive Davies", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
            ]

          },
          lastWeekUpdated: 0,
          expectedRefreshTimestamp: "Monday 1:00AM",
        },
      ],
    },
    {
      sectionHeader: "People",
      weekNumber: 13,
      startDate: "27 Mar",
      endDate: "02 Apr",
      components: [
        {
          type: "table",
          title: "People Overview",
          state: "NEUTRAL",
          toolTipText:
            "This widget provides an overview of people focused data. The Labour vs Demand spend KPI is calculated from demand hours and actual hours worked Column (I & J ) within the weekly labour report. A positive value means excess labour spend and a negative value means insufficient labour to meet demand. Above 10%  and below -10% value with this KPI will flag as red within the widget. The sick absence; absence and holiday absence % KPIs are calculated as is normal from the weekly labour report",
          headers: ["Sites",  "Labour vs demand", "", "Sick", "Absence", "Holiday"],
          maxLabVsDem:19,
          data: [
            {
              sites: "Church View",
              labourVsDemand: 19,
              sick: 4,
              absence: 4,
              holiday: 5,
            },
            {
              sites: "North End",
              labourVsDemand: 18,
              sick: 0,
              absence: 0,
              holiday: 0,
            },
            {
              sites: "Hove",
              labourVsDemand: 18,
              sick: 3,
              absence: 0,
              holiday: 8,
            },
            {
              sites: "Hillside",
              labourVsDemand: 15,
              sick: 0,
              absence: 0,
              holiday: 12,
            },
            {
              sites: "Alfold",
              labourVsDemand: 15,
              sick: 7,
              absence: 0,
              holiday: 5,
            },
            {
              sites: "Merrow",
              labourVsDemand: 13,
              sick: 4,
              absence: 7,
              holiday: 20,
            },
            {
              sites: "Tudor",
              labourVsDemand: 9,
              sick: 3,
              absence: 0,
              holiday: 6,
            },
            {
              sites: "Croft Road",
              labourVsDemand: 6,
              sick: 2,
              absence: 1,
              holiday: 11,
            },
            {
              sites: "Blue Boys",
              labourVsDemand: 6,
              sick: 1,
              absence: 6,
              holiday: 11,
            },
            {
              sites: "Hilden",
              labourVsDemand: 6,
              sick: 3,
              absence: 0,
              holiday: 0,
            },
            {
              sites: "Holborough",
              labourVsDemand: 5,
              sick: 5,
              absence: 1,
              holiday: 8,
            },
            {
              sites: "Wisley North",
              labourVsDemand: 5,
              sick: 0,
              absence: 0,
              holiday: 9,
            },
            {
              sites: "Pyecombe",
              labourVsDemand: 5,
              sick: 2,
              absence: 0,
              holiday: 13,
            },
            {
              sites: "Chatham",
              labourVsDemand: 4,
              sick: 0,
              absence: 0,
              holiday: 0,
            },
            {
              sites: "Gatwick South",
              labourVsDemand: 4,
              sick: 23,
              absence: 0,
              holiday: 22,
            },
            {
              sites: "University Way",
              labourVsDemand: 2,
              sick: 1,
              absence: 2,
              holiday: 13,
            },
            {
              sites: "Wisley South",
              labourVsDemand: 0,
              sick: 3,
              absence: 2,
              holiday: 15,
            },
            {
              sites: "Leatherhead",
              labourVsDemand: -1,
              sick: 4,
              absence: 0,
              holiday: 9,
            },
            {
              sites: "Cheriton",
              labourVsDemand: -3,
              sick: 0,
              absence: 4,
              holiday: 13,
            },
            {
              sites: "Garlinge",
              labourVsDemand: -4,
              sick: 3,
              absence: 11,
              holiday: 15,
            },
            {
              sites: "Malling",
              labourVsDemand: -8,
              sick: 8,
              absence: 0,
              holiday: 6,
            },
            {
              sites: "Willingdon",
              labourVsDemand: -8,
              sick: 2,
              absence: 0,
              holiday: 16,
            },
            {
              sites: "Lenham",
              labourVsDemand: -8,
              sick: 2,
              absence: 0,
              holiday: 11,
            },
            {
              sites: "Pevensey",
              labourVsDemand: -16,
              sick: 7,
              absence: 0,
              holiday: 21,
            },
            {
              sites: "County Oak Connect",
              labourVsDemand: "NA",
              sick: 0,
              absence: 0,
              holiday: 0,
            },
            {
              sites: "Faygate",
              labourVsDemand: "NA",
              sick: 0,
              absence: 0,
              holiday: 0,
            },
            // {
            //   sites: "Total",
            //   labourVsDemand: 4,
            //   sick: 3,
            //   absence: 2,
            //   holiday: 10,
            // },
          ],
          graph: [
            {
              weekNumber: 9,
              avgCountry: -1.86,
              avgRegionalManager: -5.2,
              totalRegionalManager: 1523479,
            },
            {
              weekNumber: 10,
              avgCountry: -0.82,
              avgRegionalManager: 1.1,
              totalRegionalManager: 1593861,
            },
            {
              weekNumber: 11,
              avgCountry: -1.94,
              avgRegionalManager: -0.2,
              totalRegionalManager: 1778646,
            },
            {
              weekNumber: 12,
              avgCountry: 0.49,
              avgRegionalManager: 1.5,
              totalRegionalManager: 1611745,
            },
            {
              weekNumber: 13,
              avgCountry: -0.82,
              avgRegionalManager: 1.1,
              totalRegionalManager: 1593861,

            },
          ],
          lastWeekUpdated: 9,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
        {
          type: "tile",
          arrow: null,
          rank: 6,
          total: 11,
          title: "Clocking on time",
          state: "DANGER",
          toolTipText:
            "Showing all sites in order of compliance for clocking in on time. Red header? That means the average for the region is below 80%. If compliance is 80% or greater, the header will be green. Where employees worked an unscheduled shift their compliance might exceed 100%. If the scheduled shift has not been worked due to illness or any other absence it would still be taken into consideration",
          displayGraph: false,
          graph: null,
          headers: ["Sites", "Compliance", ""],
          data: {
            rmTarget: [{
              ActualTarget: 14.2,
              desiredTarget: 15.3
            }],
            allRmData: [
              { sites: "Alfold", compliance: 24 },
              { sites: "Blue Boys", compliance: 75 },
              { sites: "Hilden", compliance: 83 },
              { sites: "Malling", compliance: 87 },
              { sites: "Merrow", compliance: 89 },
              { sites: "Garlinge", compliance: 89 },
              { sites: "Chatham", compliance: 93 },
              { sites: "Pyecombe", compliance: 93 },
              { sites: "Pevensey", compliance: 94 },
              { sites: "Hove", compliance: 99 },
              { sites: "University Way", compliance: 99 },
              { sites: "Croft Road", compliance: 100 },
              { sites: "Holborough", compliance: 100 },
              { sites: "Wisley North", compliance: 100 },
              { sites: "Leatherhead", compliance: 69 },
              { sites: "Gatwick South", compliance: 97 },
              { sites: "Willingdon", compliance: 79 },
              { sites: "Church View", compliance: 69 },
              { sites: "Tudor", compliance: 40 },
              { sites: "Lenham", compliance: 55 },
              { sites: "Cheriton", compliance: 58 },
              { sites: "Wisley South", compliance: 95 },
              { sites: "Hillside", compliance: 90 },
              { sites: "North End", compliance: 80 },
              { sites: "Total", compliance: 97 },
            ]

          },
          lastWeekUpdated: 40,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
        {     
          type: "tile",
          arrow: null,
          rank: "",
          total: "",
          title: "Unpublished schedules",
          state: "OK",
          toolTipText:
            "This widget will display sites within the region which have not published work schedules for either W+1 , W+2 or both W+1 and W+2. The warning icons are an indicator of exactly which schedule is missing",
          displayGraph: false,
          graph: null,
          headers: ["Sites", "W + 1", "W + 2"],
          data: [],
          lastWeekUpdated: 9,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
      ],
    },
    {
      sectionHeader: "Safety",
      weekNumber: 13,
      startDate: "27 Mar",
      endDate: "02 Apr",
      components: [
        {
          type: "table",
          title: "Safety Operations Overview",
          state: "OK",
          toolTipText:
            'This widget provides a summary of safety operations data for the last closed week. Click on column headers to sort data hierarchically.  The figures that are found within brackets ( ) represent the figures for the same data-point last week. The column marked "Safety Ind." represents the difference between safety conversations had and the sum of Near Miss, Inj. Count & G+ Incidents combined. (ie: a negative "Safety Ind" score would suggest more safety conversations are required to drive a positive safety culture)',
          headers: [
            "Sites",
            "Safety Ind.",
            "Safety Conv.",
            "Proactive Conv.",
            "Near Miss",
            "Inj. Count",
            "G+",
            "Security",
          ],
          data: [
            {
              sites: "Faygate",
              safetyInd: 0,
              safetyConv: "0 (0)",
              proActiveConv: "0 (0)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "0 (0)",
            },
            {
              sites: "Hilden",
              safetyInd: 2,
              safetyConv: "2 (2)",
              proActiveConv: "2 (2)",
              nearMiss: "0 (2)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "1 (0)",
            },
            {
              sites: "Malling",
              safetyInd: 3,
              safetyConv: "3 (3)",
              proActiveConv: "3 (3)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "3 (6)",
            },
            {
              sites: "County Oak Connect",
              safetyInd: 3,
              safetyConv: "3 (3)",
              proActiveConv: "4 (4)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "0 (2)",
            },
            {
              sites: "Hove",
              safetyInd: 3,
              safetyConv: "3 (6)",
              proActiveConv: "2 (4)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "4 (8)",
            },
            {
              sites: "Willingdon",
              safetyInd: 4,
              safetyConv: "5 (10)",
              proActiveConv: "3 (6)",
              nearMiss: "1 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "0 (1)",
            },
            {
              sites: "Tudor",
              safetyInd: 4,
              safetyConv: "4 (7)",
              proActiveConv: "6 (7)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "2 (3)",
            },
            {
              sites: "Gatwick South",
              safetyInd: 5,
              safetyConv: "5 (4)",
              proActiveConv: "7 (5)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "1 (6)",
            },
            {
              sites: "Cheriton",
              safetyInd: 5,
              safetyConv: "5 (3)",
              proActiveConv: "1 (4)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "0 (2)",
            },
            {
              sites: "Wisley North",
              safetyInd: 6,
              safetyConv: "7 (8)",
              proActiveConv: "4 (2)",
              nearMiss: "0 (0)",
              injCount: "1 (0)",
              g: "0 (0)",
              security: "0 (0)",
            },
            {
              sites: "Pevensey",
              safetyInd: 6,
              safetyConv: "6 (16)",
              proActiveConv: "6 (5)",
              nearMiss: "0 (3)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "4 (1)",
            },
          ],
          graph:[{
            "weekNumber": 7,
            "g": "2",
            "injuries": "3",
            "nearMiss": "5",
            "security": "2",
            'safetyConv':'10'
          },
          {
            "weekNumber": 8,
            "g": "1",
            "injuries": "4",
            "nearMiss": "5",
            "security": "3",
            'safetyConv':'80'
          },
          {
            "weekNumber": 9,
            "g": "2",
            "injuries": "2",
            "nearMiss": "3",
            "security": "3",
            'safetyConv':'3'
          },
          {
            "weekNumber": 10,
            "g": "2",
            "injuries": "3",
            "nearMiss": "2",
            "security": "5",
            'safetyConv':'55'
          },
          {
            "weekNumber": 11,
            "g": "3",
            "injuries": "2",
            "nearMiss": "1",
            "security": "3",
            'safetyConv':'7'
          }
        
        ],

          lastWeekUpdated: 12,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
        {
          type: "tile",
          arrow: null,
          rank: 6,
          total: 11,
          title: "Mandatory Task",
          state: "OK",
          toolTipText:
            "Showing all sites in order of compliance for clocking in on time. Red header? That means the average for the region is below 80%. If compliance is 80% or greater, the header will be green. Where employees worked an unscheduled shift their compliance might exceed 100%. If the scheduled shift has not been worked due to illness or any other absence it would still be taken into consideration",
          displayGraph: false,
          graph: null,
          headers: ["Mandatory Tasks", "Completed", ""],
          data: {
            rmTarget: [{
              ActualTarget: 14.2,
              desiredTarget: 15.3
            }],
            allRmData: [
              { sites: "Alfold", completed: 24 },
              { sites: "Blue Boys", completed: 75 },
              { sites: "Hilden", completed: 83 },
              { sites: "Malling", completed: 87 },
              { sites: "Merrow", completed: 89 },
              { sites: "Garlinge", completed: 89 },
              { sites: "Chatham", completed: 93 },
              { sites: "Pyecombe", completed: 93 },
              { sites: "Pevensey", completed: 94 },
              { sites: "Hove", completed: 99 },
            ]

          },
          lastWeekUpdated: 40,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
        {     
          type: "tile",
          arrow: null,
          rank: "",
          total: "",
          title: "Dark Sites",
          state: "DANGER",
          toolTipText:
            "This widget will display sites within the region which have not published work schedules for either W+1 , W+2 or both W+1 and W+2. The warning icons are an indicator of exactly which schedule is missing",
          displayGraph: false,
          graph: null,
          headers: ["Sites", "W + 1", "W + 2"],
          data: [
              { sites: "Alf", w_one: 24, w_two:33 },
              { sites: "Blue", w_one: 23, w_two:34 },
              { sites: "Hilden", w_one: 22, w_two:36 },
              { sites: "Malling", w_one: 27, w_two:38 },
              { sites: "Merrow", w_one: 2, w_two:40},
              { sites: "Garlinge", w_one: 8, w_two:45 },
              { sites: "Chatham", w_one: 10, w_two:46},
              { sites: "Pyecombe", w_one: 13, w_two:16 },
              { sites: "Pevensey",w_one: 15, w_two:17 },
              { sites: "Hove", w_one: 17, w_two:20 },
           
          ],
          lastWeekUpdated: 9,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
       
      ],
    },
    ],
  Year: [
    {
      sectionHeader: "Trading Profitably",
      weekNumber: 13,
      startDate: "02 Apr",
      endDate: "09 Apr",
      components: [
        {
          type: "tile",
          arrow: null,
          rank: "6",
          total: "11",
          title: "Shop Sales vs Plan",
          state: "OK",
          toolTipText:
            "This widget displays a trend graph showing (%) to plan for shop (currency) within selected region & the UK as a whole. The header will display as red, if the last closed week performance is behind plan, and similarly displayed green if above plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance Vs Plan as well as Vs LFL (Like for Like - sales vs same week last year)",
          displayGraph: true,
          graph: [
            {
              weekNumber: "Mar",
              avgCountry: -3.84,
              avgRegionalManager: -8.6,
              totalRegionalManager: 1523479,
              monthNumber:1
            },
            {
              weekNumber: "April",
              avgCountry: -1,
              avgRegionalManager: 3,
              totalRegionalManager: 1593861,
              monthNumber:2
            },
            {
              
              weekNumber: "May",
              avgCountry: -2.90,
              avgRegionalManager: -0.75,
              totalRegionalManager: 1778646,
              monthNumber:3
            },
            {
              weekNumber: "Jun",
              avgCountry: 0.55,
              avgRegionalManager: 2.56,
              totalRegionalManager: 1611745,
              monthNumber:4
            },
            {
              
              weekNumber: "Jul",
              avgCountry: -1.90,
              avgRegionalManager: -0.55,
              totalRegionalManager: 1678646,
              monthNumber:5
            },
            {
              weekNumber: "Aug",
              avgCountry: -1,
              avgRegionalManager: 3.1,
              totalRegionalManager: 1593861,
              monthNumber:6,
              // varLFL: 12.4,
            },
            {
              weekNumber: "Sept",
              avgCountry: -1,
              avgRegionalManager: 3.1,
              totalRegionalManager: 1593861,
              monthNumber:7,
              // varLFL: 12.4,
            },
            {
              weekNumber: "Oct",
              avgCountry: -1,
              avgRegionalManager: 3.1,
              totalRegionalManager: 1593861,
              monthNumber: 8,
              // varLFL: 12.4,
            },
            {
              weekNumber: "Nov",
              avgCountry: -1,
              avgRegionalManager: 3.1,
              totalRegionalManager: 1593861,
              monthNumber: 9,
              // varLFL: 12.4,
            },
            {
              weekNumber: "Dec",
              avgCountry: -1,
              avgRegionalManager: 3.1,
              totalRegionalManager: 1593861,
              monthNumber: 10,
              // varLFL: 12.4,
            },
            {
              weekNumber: "Jan",
              avgCountry: -1,
              avgRegionalManager: 3.1,
              totalRegionalManager: 1593861,
              monthNumber: 11,
              // varLFL: 12.4,
            },
            {
              weekNumber: "Feb",
              avgCountry: -1,
              avgRegionalManager: 3.1,
              totalRegionalManager: 1593861,
              monthNumber:12,
              varLFL: 12.4,
            },
          ],
          headers: ["Sites", "QTD Sales", "QTD Var to Plan", "QTD Var LFL", "YTD Sales", "YTD vs Plan", "YTD Var LFL"],
          data: [
            {
              sites: "Holborough",
              qtd_sales: 3133,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3134,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3136,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3137,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3032,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3332,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3632,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3932,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3190,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3150,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3160,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3177,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3165,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3112,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3231,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },

          ],
          lastWeekUpdated: 13,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
        {
          type: "tile",
          arrow: null,
          rank: "4",
          total: "11",
          title: "Fuel Volume vs Plan",
          state: "OK",
          toolTipText:
            "This widget displays a trend graph showing (%) to plan for fuel (litres)  within selected region & the UK as a whole. The header will display as red, if the last closed week performance is behind plan, and similarly displayed green if above plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance Vs Plan as well as Vs LFL (Like for Like - sales vs same week last year)",
          displayGraph: true,
          graph: [
            {
              weekNumber: "May",
              avgCountry: 6.36,
              avgRegionalManager: 7.3,
              totalRegionalManager: 2393863,
              monthNumber:1
            },
            {
              weekNumber: "June",
              avgCountry: 5.40,
              avgRegionalManager: 9.5,
              totalRegionalManager: 2461134,
              monthNumber:2
            },
            {
              weekNumber: "july",
              avgCountry: 13.97,
              avgRegionalManager: 20.1,
              totalRegionalManager: 2598342,
              monthNumber:3
            },
            {
              weekNumber: "Aug",
              avgCountry: 10.15,
              avgRegionalManager: 17.8,
              totalRegionalManager: 2565496,
              monthNumber:4
            },
            {
              weekNumber: "Sept",
              avgCountry: 5.48,
              avgRegionalManager: 9.7,
              totalRegionalManager: 2461134,
              monthNumber:5
             
            },
            {
              weekNumber: "Oct",
              avgCountry: 10.15,
              avgRegionalManager: 17.8,
              totalRegionalManager: 2565496,
              monthNumber: 6
            },
            {
              weekNumber: "Nov",
              avgCountry: 10.15,
              avgRegionalManager: 17.8,
              totalRegionalManager: 2565496,
              monthNumber:7
            },
            {
              weekNumber: "Dec",
              avgCountry: 10.15,
              avgRegionalManager: 17.8,
              totalRegionalManager: 2565496,
              monthNumber: 8
            },
            {
              weekNumber: "Jan",
              avgCountry: 10.15,
              avgRegionalManager: 17.8,
              totalRegionalManager: 2565496,
              monthNumber: 9
            },
            {
              weekNumber: "Feb",
              avgCountry: 10.15,
              avgRegionalManager: 17.8,
              totalRegionalManager: 2565496,
              monthNumber: 10
            },
            {
              weekNumber: "Mar",
              avgCountry: 10.15,
              avgRegionalManager: 17.8,
              totalRegionalManager: 2565496,
              monthNumber:11
            },
            {
              weekNumber: "April",
              avgCountry: 10.15,
              avgRegionalManager: 17.8,
              totalRegionalManager: 2565496,
              monthNumber: 12,
                varLFL: -4.8,
            },
          ],
          headers: ["Sites", "QTD Vol", "QTD Var to Plan", "QTD Var LFL", "YTD Vol", "YTD vs Plan", "YTD Var LFL"],
          data: [
            {
              sites: "Holborough",
              qtd_Vol: 3135,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_Vol: 3135,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Hove",
              qtd_Vol: 3000,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Wisely North",
              qtd_Vol: 3100,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Malling",
              qtd_Vol: 3432,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Chatham",
              qtd_Vol: 3512,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Alfod",
              qtd_Vol: 3912,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Blueboys",
              qtd_Vol: 4012,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Hilden",
              qtd_Vol: 1112,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Wisely South",
              qtd_Vol: 5123,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Cheriton",
              qtd_Vol: 7899,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "LeatherHead",
              qtd_Vol: 3987,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Croft Road",
              qtd_Vol: 1345,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Lenham",
              qtd_Vol: 3234,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Country Oak Connect",
              qtd_Vol: 4356,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "total",
              qtd_Vol: 5678,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              YTD_Vol: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },

          ],
          lastWeekUpdated: 13,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
        {
          type: "tile",
          arrow: null,
          rank: "8",
          total: "11",
          title: "Services vs Plan",
          state: "OK",
          toolTipText:
            "This widget displays a trend graph showing (%) to plan for services (currency) within selected region & the UK as a whole. The header will display as red, if the last closed week performance is behind plan, and similarly displayed green if above plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance Vs Plan as well as Vs LFL (Like for Like - sales vs same week last year)",
          displayGraph: true,
          graph: [
            {
              weekNumber: "sept",
              avgCountry: 2.41,
              avgRegionalManager: -3.0,
              totalRegionalManager: 38982,
              monthNumber:1
            },
            {
              weekNumber: "Oct",
              avgCountry: 10.36,
              avgRegionalManager: 13.6,
              totalRegionalManager: 39633,
              monthNumber:2
            },
            {
              weekNumber: "Nov",
              avgCountry: 30.36,
              avgRegionalManager: 15.2,
              totalRegionalManager: 40328,
              monthNumber:3
            },
            {
              weekNumber: "Dec",
              avgCountry: 1.23,
              avgRegionalManager: 1.9,
              totalRegionalManager: 37711,
              monthNumber:4
            },
            {
              weekNumber: "Jan",
              avgCountry: 11.36,
              avgRegionalManager: 11.6,
              totalRegionalManager: 39633,
              // varLFL: 10.6,
              monthNumber:5
            },
            {
              weekNumber: "Feb",
              avgCountry: 30.36,
              avgRegionalManager: 15.2,
              totalRegionalManager: 40328,
              monthNumber: 6
            },
            {
              weekNumber: "Mar",
              avgCountry: 30.36,
              avgRegionalManager: 15.2,
              totalRegionalManager: 40328,
              monthNumber: 7
            },
            {
              weekNumber: "Apr",
              avgCountry: 30.36,
              avgRegionalManager: 15.2,
              totalRegionalManager: 40328,
              monthNumber: 8
            },
            {
              weekNumber: "May",
              avgCountry: 30.36,
              avgRegionalManager: 15.2,
              totalRegionalManager: 40328,
              monthNumber: 9
            },
            {
              weekNumber: "June",
              avgCountry: 30.36,
              avgRegionalManager: 15.2,
              totalRegionalManager: 40328,
              monthNumber: 10
            },
            {
              weekNumber: "Jul",
              avgCountry: 30.36,
              avgRegionalManager: 15.2,
              totalRegionalManager: 40328,
              monthNumber: 11
            },
            {
              weekNumber: "Aug",
              avgCountry: 30.36,
              avgRegionalManager: 15.2,
              totalRegionalManager: 40328,
              monthNumber: 12,
              varLFL: 10.6,
            },
          ],
          headers: ["Sites", "QTD Sales", "QTD Var to Plan", "QTD Var LFL", "YTD Sales", "YTD vs Plan", "YTD Var LFL"],
          data: [
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },

          ],
          lastWeekUpdated: 13,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
        {
          type: "tile",
          arrow: null,
          rank: "8",
          total: "11",
          title: "Wild Bean Cafe vs Plan",
          state: "OK",
          toolTipText:
            "This widget displays a trend graph showing (%) to plan for services (currency) within selected region & the UK as a whole. The header will display as red, if the last closed week performance is behind plan, and similarly displayed green if above plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance Vs Plan as well as Vs LFL (Like for Like - sales vs same week last year)",
          displayGraph: true,
          graph: [
            {
              weekNumber: "Aug",
              avgCountry: 2.51,
              avgRegionalManager: -4.0,
              totalRegionalManager: 38982,
              monthNumber:1
            },
            {
              weekNumber: "Sept",
              avgCountry: 11.36,
              avgRegionalManager: 11.6,
              totalRegionalManager: 39633,
              monthNumber:2
            },
            {
              weekNumber: "Oct",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 3
            },
            {
              weekNumber: "Nov",
              avgCountry: 1.23,
              avgRegionalManager: 1.9,
              totalRegionalManager: 37711,
              monthNumber: 4
            },
            {
              weekNumber: "Dec",
              avgCountry: 11.36,
              avgRegionalManager: 11.6,
              totalRegionalManager: 39633,
              // varLFL: 10.6,
              monthNumber:5
            },
            {
              weekNumber: "Jan",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 6
            },
            {
              weekNumber: "Feb",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 7
            },
            {
              weekNumber: "Mar",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 8
            },
            {
              weekNumber: "April",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 9
            },
            {
              weekNumber: "May",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 10
            },
            {
              weekNumber: "Jun",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 11
            },
            {
              weekNumber: "July",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 12
            },
          ],
          headers: ["Sites", "QTD Sales", "QTD Var to Plan", "QTD Var LFL", "YTD Sales", "YTD vs Plan", "YTD Var LFL"],
          data: [
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },
            {
              sites: "Holborough",
              qtd_sales: 3132,
              qtd_var_to_plan: -13.3,
              qtd_varlfl: -9.1,
              ytd_sales: 10503,
              ytd_vs_plan: -14.9,
              ytd_varlfl: -9.1
            },

          ],
          lastWeekUpdated: 13,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
        {
          type: "tile",
          arrow: null,
          rank: "10",
          total: "11",
          title: "Waste",
          state: "OK",
          toolTipText:
            "This widget displays a trend graph showing (%) to plan for waste, including waste for Branded, M&S & Wild Bean Cafe. The header will display as red, if the last closed week performance is above plan, and similarly displayed green if below plan. In the header there is also an overall ranking showing where the region performed in relation to others within the UK. The site table shows site performance in terms of actual waste in currency, waste % to planned waste and waste % of shop sales excluding services",
          displayGraph: true,
          graph: [
            // {
            //   weekNumber: 1,
            //   avgCountry: -0.51,
            //   avgRegionalManager: -1.41,
            //   totalRegionalManager: 49101,
            // },
            // {
            //   weekNumber: 2,
            //   avgCountry: -0.51,
            //   avgRegionalManager: -1.41,
            //   totalRegionalManager: 49101,
            // },
            // {
            //   weekNumber: 3,
            //   avgCountry: 0.01,
            //   avgRegionalManager: -1.14,
            //   totalRegionalManager: 59051,
            // },
            // {
            //   weekNumber: 4,
            //   avgCountry: 0.33,
            //   avgRegionalManager: -0.71,
            //   totalRegionalManager: 63332,
            // },
            // {
            //   weekNumber: 5,
            //   avgCountry: -0.51,
            //   avgRegionalManager: -1.41,
            //   totalRegionalManager: 49101,
            //   percSales: 4.1,
            // },
           
              {
                weekNumber: "Aug",
                avgCountry: 2.51,
                avgRegionalManager: -4.0,
                totalRegionalManager: 38982,
                monthNumber:1
              },
              {
                weekNumber: "Sept",
                avgCountry: 11.36,
                avgRegionalManager: 11.6,
                totalRegionalManager: 39633,
                monthNumber:2
              },
              {
                weekNumber: "Oct",
                avgCountry: 32.36,
                avgRegionalManager: 14.2,
                totalRegionalManager: 40328,
                monthNumber: 3
              },
              {
                weekNumber: "Nov",
                avgCountry: 1.23,
                avgRegionalManager: 1.9,
                totalRegionalManager: 37711,
                monthNumber: 4
              },
              {
                weekNumber: "Dec",
                avgCountry: 11.36,
                avgRegionalManager: 11.6,
                totalRegionalManager: 39633,
                // varLFL: 10.6,
                monthNumber:5
              },
              {
                weekNumber: "Jan",
                avgCountry: 32.36,
                avgRegionalManager: 14.2,
                totalRegionalManager: 40328,
                monthNumber: 6
              },
              {
                weekNumber: "Feb",
                avgCountry: 32.36,
                avgRegionalManager: 14.2,
                totalRegionalManager: 40328,
                monthNumber: 7
              },
              {
                weekNumber: "Mar",
                avgCountry: 32.36,
                avgRegionalManager: 14.2,
                totalRegionalManager: 40328,
                monthNumber: 8
              },
              {
                weekNumber: "April",
                avgCountry: 32.36,
                avgRegionalManager: 14.2,
                totalRegionalManager: 40328,
                monthNumber: 9
              },
              {
                weekNumber: "May",
                avgCountry: 32.36,
                avgRegionalManager: 14.2,
                totalRegionalManager: 40328,
                monthNumber: 10
              },
              {
                weekNumber: "Jun",
                avgCountry: 32.36,
                avgRegionalManager: 14.2,
                totalRegionalManager: 40328,
                monthNumber: 11
              },
              {
                weekNumber: "July",
                avgCountry: 32.36,
                avgRegionalManager: 14.2,
                totalRegionalManager: 40328,
                monthNumber: 12,
                percSales: 4.1,
              },
            ],
          
          headers: ["Sites", "QTD Loss", "QTD Loss % to Sales", "YTD Sales", "YTD Loss % to Sales"],
          data: [
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },
            {
              sites: "Holborough",
              qtd_Loss: 3132,
              qtd_Loss_Perc_to_Sales: -13.3,
              ytd_sales: -9.1,
              YTD_Loss_perc_to_Sales: -14.9,
            },

          ],
          lastWeekUpdated: 12,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
        {
          type: "tile",
          arrow: null,
          rank: "11",
          total: "11",
          title: "Till Losses",
          state: "DANGER",
          toolTipText:
            'This widget displays a trend of cash losses based on shop only for the region as well as the UK. Till losses are made up of "Till Shorts" and "EFT" shorts as seen in All Sites Report. The header will be red if the till loss absolute value is greater than zero (0&ltLoss), and green if the loss value is equal to zero (Loss=0). The site table shows individual cash loss as well as the % which the loss makes up of total sales',
          displayGraph: true,
          graph: [
          
            // {
            //   weekNumber: 2,
            //   avgCountry: 0.0,
            //   avgRegionalManager: 0.0,
            //   totalRegionalManager: 33,
            // },
            // {
            //   sites: "Blue Boys",
            //   loyaltyScore: 9,
            //   targetScore: 15,
            //   lastWeek: 13,
            // },
            // {
            //   weekNumber: 4,
            //   avgCountry: 0.01,
            //   avgRegionalManager: 0.0,
            //   totalRegionalManager: 12,
            // },
            // {
            //   weekNumber: 5,
            //   avgCountry: 0.01,
            //   avgRegionalManager: 0.01,
            //   totalRegionalManager: -210,
            // },
            
            {
              weekNumber: "Aug",
              avgCountry: 2.51,
              avgRegionalManager: -4.0,
              totalRegionalManager: 38982,
              monthNumber:1
            },
            {
              weekNumber: "Sept",
              avgCountry: 11.36,
              avgRegionalManager: 11.6,
              totalRegionalManager: 39633,
              monthNumber:2
            },
            {
              weekNumber: "Oct",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 3
            },
            {
              weekNumber: "Nov",
              avgCountry: 1.23,
              avgRegionalManager: 1.9,
              totalRegionalManager: 37711,
              monthNumber: 4
            },
            {
              weekNumber: "Dec",
              avgCountry: 11.36,
              avgRegionalManager: 11.6,
              totalRegionalManager: 39633,
              // varLFL: 10.6,
              monthNumber:5
            },
            {
              weekNumber: "Jan",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 6
            },
            {
              weekNumber: "Feb",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 7
            },
            {
              weekNumber: "Mar",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 8
            },
            {
              weekNumber: "April",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 9
            },
            {
              weekNumber: "May",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 10
            },
            {
              weekNumber: "Jun",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 11
            },
            {
              weekNumber: "July",
              avgCountry: 32.36,
              avgRegionalManager: 14.2,
              totalRegionalManager: 40328,
              monthNumber: 12,
              percSales: 4.1,
            },
           
          ],
          headers: ["Sites", "QTD Waste", "QTD Var to plan", "QTD % to Sales", "YTD Waste", "YTD vs Plan", "YTD % to Sales"],
          data: [
            {
              sites: "Holborough",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Holborough",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Holborough",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Wisely North",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Malling",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Chatham",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Alfod",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Blueboys",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Hilden",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Wisely South",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Cheriton",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "LeatherHead",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Croft Road",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Lenham",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
            {
              sites: "Country Oak Connect",
              qtd_waste: 3132,
              qtd_Var_to_plan: -13.3,
              qtd_Perc_to_Sales: -9.1,
              YTD_Waste: 10503,
              ytd_vs_plan: -14.9,
              YTD_Perc_To_Sales: -9.1
            },
          ],
          lastWeekUpdated: 12,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
      ],
    },
    {
      sectionHeader: "Customer",
      weekNumber: 13,
      startDate: "27 Mar",
      endDate: "02 Apr",
      components: [
        {
          type: "tile2",
          arrow: null,
          rank: "11",
          total: "11",
          title: "Customer Satisfaction",
          state: "NEUTRAL",
          toolTipText:
            "This widget contains data relevant to the voice of the customer. Data is available at a weekly level, showing the overall CSAT score for your region as well as the CSAT score for the country as a whole, with number of surveys completed shown within the CSAT barometers. Within the site table you will see individual CSAT scores per site, Wow and Rescue moments, as well as Wow and Rescue moments as a % of survey responses recorded",
          displayGraph: true,
          graph: [
            {
              graphText: { heading: "CSAT Score", subHeading: "Sat & V.Sat" },
              data: [
                { description: "ADAM WHITEHOUSE", percent: 89, count: 136 },
                { description: "United Kingdom", percent: 24, count: 1574 },
              ],
              legend: [
                { value: "0% - 24%", color: "#e64949", description: "Bad" },
                { value: "25% - 74%", color: "#999999", description: "Neutral" },
                { value: "75% - 100%", color: "#007833", description: "Great" },
              ],

            },
            {
              barGraphData: [
                { weekNumber: "Jun", avgCountry: 25, avgRegionalManager: 15, monthNumber:1  },
                { weekNumber: "July", avgCountry: 35, avgRegionalManager: 30 ,monthNumber:2},
                { weekNumber: "Aug", avgCountry: 40, avgRegionalManager: 50 ,monthNumber:3},
                { weekNumber: "Sep", avgCountry:65, avgRegionalManager: 70, monthNumber:4 },
                { weekNumber: "Oct", avgCountry: 70, avgRegionalManager: 90 ,monthNumber:5},
                { weekNumber: "Nov", avgCountry: 73, avgRegionalManager: 85 ,monthNumber:6},
                { weekNumber: "Dec", avgCountry: 75, avgRegionalManager: 65 ,monthNumber:7},
                { weekNumber: "Jan", avgCountry: 77, avgRegionalManager: 30 ,monthNumber:8},
                { weekNumber: "Feb", avgCountry: 79, avgRegionalManager: 40 ,monthNumber:9},
                { weekNumber: "Mar", avgCountry: 81, avgRegionalManager: 45 ,monthNumber:10},
                { weekNumber: "Apr", avgCountry: 85, avgRegionalManager: 22 ,monthNumber:11},
                { weekNumber: "May", avgCountry: 90, avgRegionalManager: 25 ,monthNumber:12},
              ]
            }
          ],
          headers: ["Sites", "CSAT Score", "Wow Moments", "Wow to total", "Rescue to total"],
          data: [
            {
              sites: "The Buck",
              csat_score: 28,
              wow_moments: 1,
              wow_total: 9.1,
              rescue_total: 87,
            },
            {
              sites: "UNIVERSITY WAY SF CONNECT",
              csat_score: 65,
              wow_moments: 10,
              wow_total: 15,
              rescue_total: 55,
            },
            {
              sites: "Great Torrington",
              csat_score: 61,
              wow_moments: 8,
              wow_total: 9,
              rescue_total: 66,
            },
            {
              sites: "Blackbird Hill",
              csat_score: 60,
              wow_moments: 7,
              wow_total: 31,
              rescue_total: 81,
            },
            {
              sites: "Blue Boys",
              csat_score: 58,
              wow_moments: 6,
              wow_total: 230,
              rescue_total: 99,
            },
            {
              sites: "Blackbird Hill",
              csat_score: 57,
              wow_moments: 5,
              wow_total: 29,
              rescue_total: 97,
            },
            {
              sites: "Blackbird",
              csat_score: 56,
              wow_moments: 4,
              wow_total: 28,
              rescue_total: 64,
            }
          ],
          lastWeekUpdated: 13,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
        {
          type: "comparator2",
          title: "Loyalty Issuance () = last week count",
          state: "NEUTRAL",
          rank: 1,
          toolTipText:
            "This widget will provide data around loyalty issuance. The left hand bar chart shows target issuance and actual issuance per site (ranked highest to lowest by deficit). The percentages in brackets on the right of these bars represents the previous weeks issuance per site. The middle bar below regional manager name displays the overall regional loyalty issuance achieved. On the right you will see how you have performed in relation to your colleagues !",
          data: [
            {
              sites: "Malling",
              loyaltyScore: 5,
              targetScore: 15,
              lastMonth: 13,
            },
            {
              sites: "Gatwick South",
              loyaltyScore: 15,
              targetScore: 16,
              lastMonth: 16,
            },
            {
              sites: "Blue Boys",
              loyaltyScore: 10,
              targetScore: 15,
              lastMonth: 13,
            },
            {
              sites: "Hove",
              loyaltyScore: 16,
              targetScore: 16,
              lastMonth: 14,
            },
            {
              sites: "Hillside",
              loyaltyScore: 16,
              targetScore: 16,
              lastMonth: 15,
            },
            {
              sites: "Wisley South",
              loyaltyScore: 15,
              targetScore: 15,
              lastMonth: 16,
            },
            {
              sites: "Cheriton",
              loyaltyScore: 17,
              targetScore: 17,
              lastMonth: 16,
            },
            {
              sites: "Leatherhead",
              loyaltyScore: 18,
              targetScore: 17,
              lastMonth: 19,
            },
            {
              sites: "University Way",
              loyaltyScore: 16,
              targetScore: 15,
              lastMonth: 14,
            },
            {
              sites: "Alfold",
              loyaltyScore: 19,
              targetScore: 17,
              lastMonth: 19,
            },
            {
              sites: "Lenham",
              loyaltyScore: 19,
              targetScore: 17,
              lastMonth: 20,
            },
            {
              sites: "Tudor",
              loyaltyScore: 18,
              targetScore: 16,
              lastMonth: 18,
            },
            {
              sites: "Willingdon",
              loyaltyScore: 18,
              targetScore: 20,
              lastMonth: 20,
            },
            {
              sites: "County Oak Connect",
              loyaltyScore: 17,
              targetScore: 19,
              lastMonth: 18,
            },
            {
              sites: "Hilden",
              loyaltyScore: 18,
              targetScore: 21,
              lastMonth: 20,
            },
            {
              sites: "Pevensey",
              loyaltyScore: 12,
              targetScore: 17,
              lastMonth: 22,
            },
            {
              sites: "Wisley North",
              loyaltyScore: 12,
              targetScore: 15,
              lastMonth: 11,
            },
          ],
          headers: ["Sites", "Loyalty Score", "Target Score", "Last Month", "Loyalty Range", ""],
          graph: [
            { weekNumber: "Jun", avgCountry: 25, avgRegionalManager: 15, monthNumber:1  },
                { weekNumber: "July", avgCountry: 35, avgRegionalManager: 30 ,monthNumber:2},
                { weekNumber: "Aug", avgCountry: 40, avgRegionalManager: 50 ,monthNumber:3},
                { weekNumber: "Sep", avgCountry:65, avgRegionalManager: 70, monthNumber:4 },
                { weekNumber: "Oct", avgCountry: 70, avgRegionalManager: 90 ,monthNumber:5},
                { weekNumber: "Nov", avgCountry: 73, avgRegionalManager: 85 ,monthNumber:6},
                { weekNumber: "Dec", avgCountry: 75, avgRegionalManager: 65 ,monthNumber:7},
                { weekNumber: "Jan", avgCountry: 77, avgRegionalManager: 30 ,monthNumber:8},
                { weekNumber: "Feb", avgCountry: 79, avgRegionalManager: 40 ,monthNumber:9},
                { weekNumber: "Mar", avgCountry: 81, avgRegionalManager: 45 ,monthNumber:10},
                { weekNumber: "Apr", avgCountry: 85, avgRegionalManager: 22 ,monthNumber:11},
                { weekNumber: "May", avgCountry: 90, avgRegionalManager: 25 ,monthNumber:12},
          ],
          lastWeekUpdated: 10,
          expectedRefreshTimestamp: "Tuesday 3:00PM",
        },
        {
          type: "ratings",
          toolTipText: "Some text here",
          header: ["Loyalty Ranking", "Score", "Target"],
          state: "OK",
          data: {
            rmTarget: [{
              ActualTarget: 14.2,
              desiredTarget: 15.3
            }],
            allRmData: [
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Adam whitehouse", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Clive Davies", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
              { rmName: "Alex", score: 12, target: 13 },
            ]

          },
          lastWeekUpdated: 0,
          expectedRefreshTimestamp: "Monday 1:00AM",
        },
      ],
    },
    {
      sectionHeader: "People",
      weekNumber: 13,
      startDate: "27 Mar",
      endDate: "02 Apr",
      components: [
        {
          type: "table",
          title: "People Overview",
          state: "NEUTRAL",
          toolTipText:
            "This widget provides an overview of people focused data. The Labour vs Demand spend KPI is calculated from demand hours and actual hours worked Column (I & J ) within the weekly labour report. A positive value means excess labour spend and a negative value means insufficient labour to meet demand. Above 10%  and below -10% value with this KPI will flag as red within the widget. The sick absence; absence and holiday absence % KPIs are calculated as is normal from the weekly labour report",
          headers: ["Sites", "", "Labour vs demand", "Sick", "Absence", "Holiday"],
          maxLabVsDem:19,
          data: [
            {
              sites: "Church View",
              labourVsDemand: 19,
              sick: 4,
              absence: 4,
              holiday: 5,
            },
            {
              sites: "North End",
              labourVsDemand: 18,
              sick: 0,
              absence: 0,
              holiday: 0,
            },
            {
              sites: "Hove",
              labourVsDemand: 18,
              sick: 3,
              absence: 0,
              holiday: 8,
            },
            {
              sites: "Hillside",
              labourVsDemand: 15,
              sick: 0,
              absence: 0,
              holiday: 12,
            },
            {
              sites: "Alfold",
              labourVsDemand: 15,
              sick: 7,
              absence: 0,
              holiday: 5,
            },
            {
              sites: "Merrow",
              labourVsDemand: 13,
              sick: 4,
              absence: 7,
              holiday: 20,
            },
            {
              sites: "Tudor",
              labourVsDemand: 9,
              sick: 3,
              absence: 0,
              holiday: 6,
            },
            {
              sites: "Croft Road",
              labourVsDemand: 6,
              sick: 2,
              absence: 1,
              holiday: 11,
            },
            {
              sites: "Blue Boys",
              labourVsDemand: 6,
              sick: 1,
              absence: 6,
              holiday: 11,
            },
            {
              sites: "Hilden",
              labourVsDemand: 6,
              sick: 3,
              absence: 0,
              holiday: 0,
            },
            {
              sites: "Holborough",
              labourVsDemand: 5,
              sick: 5,
              absence: 1,
              holiday: 8,
            },
            {
              sites: "Wisley North",
              labourVsDemand: 5,
              sick: 0,
              absence: 0,
              holiday: 9,
            },
            {
              sites: "Pyecombe",
              labourVsDemand: 5,
              sick: 2,
              absence: 0,
              holiday: 13,
            },
            {
              sites: "Chatham",
              labourVsDemand: 4,
              sick: 0,
              absence: 0,
              holiday: 0,
            },
            {
              sites: "Gatwick South",
              labourVsDemand: 4,
              sick: 23,
              absence: 0,
              holiday: 22,
            },
            {
              sites: "University Way",
              labourVsDemand: 2,
              sick: 1,
              absence: 2,
              holiday: 13,
            },
            {
              sites: "Wisley South",
              labourVsDemand: 0,
              sick: 3,
              absence: 2,
              holiday: 15,
            },
            {
              sites: "Leatherhead",
              labourVsDemand: -1,
              sick: 4,
              absence: 0,
              holiday: 9,
            },
            {
              sites: "Cheriton",
              labourVsDemand: -3,
              sick: 0,
              absence: 4,
              holiday: 13,
            },
            {
              sites: "Garlinge",
              labourVsDemand: -4,
              sick: 3,
              absence: 11,
              holiday: 15,
            },
            {
              sites: "Malling",
              labourVsDemand: -8,
              sick: 8,
              absence: 0,
              holiday: 6,
            },
            {
              sites: "Willingdon",
              labourVsDemand: -8,
              sick: 2,
              absence: 0,
              holiday: 16,
            },
            {
              sites: "Lenham",
              labourVsDemand: -8,
              sick: 2,
              absence: 0,
              holiday: 11,
            },
            {
              sites: "Pevensey",
              labourVsDemand: -16,
              sick: 7,
              absence: 0,
              holiday: 21,
            },
            {
              sites: "County Oak Connect",
              labourVsDemand: "NA",
              sick: 0,
              absence: 0,
              holiday: 0,
            },
            {
              sites: "Faygate",
              labourVsDemand: "NA",
              sick: 0,
              absence: 0,
              holiday: 0,
            },
            // {
            //   sites: "Total",
            //   labourVsDemand: 4,
            //   sick: 3,
            //   absence: 2,
            //   holiday: 10,
            // },
          ],
          graph: [
            // {
            //   weekNumber: 9,
            //   avgCountry: -1.86,
            //   avgRegionalManager: -5.2,
            //   totalRegionalManager: 1523479,
            // },
            // {
            //   weekNumber: 10,
            //   avgCountry: -0.82,
            //   avgRegionalManager: 1.1,
            //   totalRegionalManager: 1593861,
            // },
            // {
            //   weekNumber: 11,
            //   avgCountry: -1.94,
            //   avgRegionalManager: -0.2,
            //   totalRegionalManager: 1778646,
            // },
            // {
            //   weekNumber: 12,
            //   avgCountry: 0.49,
            //   avgRegionalManager: 1.5,
            //   totalRegionalManager: 1611745,
            // },
            // {
            //   weekNumber: 13,
            //   avgCountry: -0.82,
            //   avgRegionalManager: 1.1,
            //   totalRegionalManager: 1593861,

            // },
            { weekNumber: "Jun", avgCountry: 25, avgRegionalManager: 15, monthNumber:1  },
            { weekNumber: "July", avgCountry: 35, avgRegionalManager: 30 ,monthNumber:2},
            { weekNumber: "Aug", avgCountry: 40, avgRegionalManager: 50 ,monthNumber:3},
            { weekNumber: "Sep", avgCountry:65, avgRegionalManager: 70, monthNumber:4 },
            { weekNumber: "Oct", avgCountry: 70, avgRegionalManager: 90 ,monthNumber:5},
            { weekNumber: "Nov", avgCountry: 73, avgRegionalManager: 85 ,monthNumber:6},
            { weekNumber: "Dec", avgCountry: 75, avgRegionalManager: 65 ,monthNumber:7},
            { weekNumber: "Jan", avgCountry: 77, avgRegionalManager: 30 ,monthNumber:8},
            { weekNumber: "Feb", avgCountry: 79, avgRegionalManager: 40 ,monthNumber:9},
            { weekNumber: "Mar", avgCountry: 81, avgRegionalManager: 45 ,monthNumber:10},
            { weekNumber: "Apr", avgCountry: 85, avgRegionalManager: 22 ,monthNumber:11},
            { weekNumber: "May", avgCountry: 90, avgRegionalManager: 25 ,monthNumber:12},
          ],
          lastWeekUpdated: 9,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
        {
          type: "tile",
          arrow: null,
          rank: 6,
          total: 11,
          title: "Clocking on time",
          state: "OK",
          toolTipText:
            "Showing all sites in order of compliance for clocking in on time. Red header? That means the average for the region is below 80%. If compliance is 80% or greater, the header will be green. Where employees worked an unscheduled shift their compliance might exceed 100%. If the scheduled shift has not been worked due to illness or any other absence it would still be taken into consideration",
          displayGraph: false,
          graph: null,
          headers: ["Sites", "Compliance", ""],
          data: {
            rmTarget: [{
              ActualTarget: 14.2,
              desiredTarget: 15.3
            }],
            allRmData: [
              { sites: "Alfold", compliance: 24 },
              { sites: "Blue Boys", compliance: 75 },
              { sites: "Hilden", compliance: 83 },
              { sites: "Malling", compliance: 87 },
              { sites: "Merrow", compliance: 89 },
              { sites: "Garlinge", compliance: 89 },
              { sites: "Chatham", compliance: 93 },
              { sites: "Pyecombe", compliance: 93 },
              { sites: "Pevensey", compliance: 94 },
              { sites: "Hove", compliance: 99 },
              { sites: "University Way", compliance: 99 },
              { sites: "Croft Road", compliance: 100 },
              { sites: "Holborough", compliance: 100 },
              { sites: "Wisley North", compliance: 100 },
              { sites: "Leatherhead", compliance: 69 },
              { sites: "Gatwick South", compliance: 97 },
              { sites: "Willingdon", compliance: 79 },
              { sites: "Church View", compliance: 69 },
              { sites: "Tudor", compliance: 40 },
              { sites: "Lenham", compliance: 55 },
              { sites: "Cheriton", compliance: 58 },
              { sites: "Wisley South", compliance: 95 },
              { sites: "Hillside", compliance: 90 },
              { sites: "North End", compliance: 80 },
              { sites: "Total", compliance: 97 },
            ]

          },
          lastWeekUpdated: 40,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
        {     
          type: "tile",
          arrow: null,
          rank: "",
          total: "",
          title: "Unpublished schedules",
          state: "OK",
          toolTipText:
            "This widget will display sites within the region which have not published work schedules for either W+1 , W+2 or both W+1 and W+2. The warning icons are an indicator of exactly which schedule is missing",
          displayGraph: false,
          graph: null,
          headers: ["Sites", "W + 1", "W + 2"],
          data: [],
          lastWeekUpdated: 9,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
      ],
    },
     {
      sectionHeader: "Safety",
      weekNumber: 13,
      startDate: "27 Mar",
      endDate: "02 Apr",
      components: [
        {
          type: "table",
          title: "Safety Operations Overview",
          state: "OK",
          toolTipText:
            'This widget provides a summary of safety operations data for the last closed week. Click on column headers to sort data hierarchically.  The figures that are found within brackets ( ) represent the figures for the same data-point last week. The column marked "Safety Ind." represents the difference between safety conversations had and the sum of Near Miss, Inj. Count & G+ Incidents combined. (ie: a negative "Safety Ind" score would suggest more safety conversations are required to drive a positive safety culture)',
          headers: [
            "Sites",
            "Safety Ind.",
            "Safety Conv.",
            "Proactive Conv.",
            "Near Miss",
            "Inj. Count",
            "G+",
            "Security",
          ],
          data: [
            {
              sites: "Faygate",
              safetyInd: 0,
              safetyConv: "0 (0)",
              proActiveConv: "0 (0)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "0 (0)",
            },
            {
              sites: "Hilden",
              safetyInd: 2,
              safetyConv: "2 (2)",
              proActiveConv: "2 (2)",
              nearMiss: "0 (2)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "1 (0)",
            },
            {
              sites: "Malling",
              safetyInd: 3,
              safetyConv: "3 (3)",
              proActiveConv: "3 (3)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "3 (6)",
            },
            {
              sites: "County Oak Connect",
              safetyInd: 3,
              safetyConv: "3 (3)",
              proActiveConv: "4 (4)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "0 (2)",
            },
            {
              sites: "Hove",
              safetyInd: 3,
              safetyConv: "3 (6)",
              proActiveConv: "2 (4)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "4 (8)",
            },
            {
              sites: "Willingdon",
              safetyInd: 4,
              safetyConv: "5 (10)",
              proActiveConv: "3 (6)",
              nearMiss: "1 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "0 (1)",
            },
            {
              sites: "Tudor",
              safetyInd: 4,
              safetyConv: "4 (7)",
              proActiveConv: "6 (7)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "2 (3)",
            },
            {
              sites: "Gatwick South",
              safetyInd: 5,
              safetyConv: "5 (4)",
              proActiveConv: "7 (5)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "1 (6)",
            },
            {
              sites: "Cheriton",
              safetyInd: 5,
              safetyConv: "5 (3)",
              proActiveConv: "1 (4)",
              nearMiss: "0 (0)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "0 (2)",
            },
            {
              sites: "Wisley North",
              safetyInd: 6,
              safetyConv: "7 (8)",
              proActiveConv: "4 (2)",
              nearMiss: "0 (0)",
              injCount: "1 (0)",
              g: "0 (0)",
              security: "0 (0)",
            },
            {
              sites: "Pevensey",
              safetyInd: 6,
              safetyConv: "6 (16)",
              proActiveConv: "6 (5)",
              nearMiss: "0 (3)",
              injCount: "0 (0)",
              g: "0 (0)",
              security: "4 (1)",
            },
          ],
          graph:[{
            "weekNumber": "Jan",
            "g": "2",
            "injuries": "3",
            "nearMiss": "5",
            "security": "2",
            'safetyConv':'97'
          },
          {
            "weekNumber": "Feb",
            "g": "1",
            "injuries": "4",
            "nearMiss": "5",
            "security": "3",
            'safetyConv':'80'
          },
          {
            "weekNumber": "Mar",
            "g": "2",
            "injuries": "2",
            "nearMiss": "3",
            "security": "3",
            'safetyConv':'68'
          },
          {
            "weekNumber": "Apr",
            "g": "2",
            "injuries": "3",
            "nearMiss": "2",
            "security": "5",
            'safetyConv':'55'
          },
          {
            "weekNumber": "May",
            "g": "3",
            "injuries": "2",
            "nearMiss": "1",
            "security": "3",
            'safetyConv':'62'
          }
        
        ],

          lastWeekUpdated: 10,
          expectedRefreshTimestamp: "Monday 1:00PM",
        },
        {
          type: "tile",
          arrow: null,
          rank: 6,
          total: 11,
          title: "Mandatory Task",
          state: "OK",
          toolTipText:
            "Showing all sites in order of compliance for clocking in on time. Red header? That means the average for the region is below 80%. If compliance is 80% or greater, the header will be green. Where employees worked an unscheduled shift their compliance might exceed 100%. If the scheduled shift has not been worked due to illness or any other absence it would still be taken into consideration",
          displayGraph: false,
          graph: null,
          headers: ["Mandatory Tasks", "Completed", ""],
          data: {
            rmTarget: [{
              ActualTarget: 14.2,
              desiredTarget: 15.3
            }],
            allRmData: [
              { sites: "Alfold", completed: 24 },
              { sites: "Blue Boys", completed: 75 },
              { sites: "Hilden", completed: 83 },
              { sites: "Malling", completed: 87 },
              { sites: "Merrow", completed: 89 },
              { sites: "Garlinge", completed: 89 },
              { sites: "Chatham", completed: 93 },
              { sites: "Pyecombe", completed: 93 },
              { sites: "Pevensey", completed: 94 },
              { sites: "Hove", completed: 99 },
            ]

          },
          lastWeekUpdated: 40,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
        {     
          type: "tile",
          arrow: null,
          rank: "",
          total: "",
          title: "Dark Sites",
          state: "DANGER",
          toolTipText:
            "This widget will display sites within the region which have not published work schedules for either W+1 , W+2 or both W+1 and W+2. The warning icons are an indicator of exactly which schedule is missing",
          displayGraph: false,
          graph: null,
          headers: [],
          data: [],
          lastWeekUpdated: 9,
          expectedRefreshTimestamp: "Monday 3:00PM",
        },
       
      ],
    },
    // {
    //   sectionHeader: "People",
    //   weekNumber: 13,
    //   startDate: "27 Mar",
    //   endDate: "02 Apr",
    //   components: [
    //     {
    //       type: "table",
    //       title: "People Overview",
    //       state: "NEUTRAL",
    //       toolTipText:
    //         "This widget provides an overview of people focused data. The Labour vs Demand spend KPI is calculated from demand hours and actual hours worked Column (I & J ) within the weekly labour report. A positive value means excess labour spend and a negative value means insufficient labour to meet demand. Above 10%  and below -10% value with this KPI will flag as red within the widget. The sick absence; absence and holiday absence % KPIs are calculated as is normal from the weekly labour report",
    //       headers: ["Sites", "Labour vs demand", "Sick", "Absence", "Holiday"],
    //       data: [
    //         {
    //           sites: "Church View",
    //           labourVsDemand: 19,
    //           sick: 4,
    //           absence: 4,
    //           holiday: 5,
    //         },
    //         {
    //           sites: "North End",
    //           labourVsDemand: 18,
    //           sick: 0,
    //           absence: 0,
    //           holiday: 0,
    //         },
    //         {
    //           sites: "Hove",
    //           labourVsDemand: 18,
    //           sick: 3,
    //           absence: 0,
    //           holiday: 8,
    //         },
    //         {
    //           sites: "Hillside",
    //           labourVsDemand: 15,
    //           sick: 0,
    //           absence: 0,
    //           holiday: 12,
    //         },
    //         {
    //           sites: "Alfold",
    //           labourVsDemand: 15,
    //           sick: 7,
    //           absence: 0,
    //           holiday: 5,
    //         },
    //         {
    //           sites: "Merrow",
    //           labourVsDemand: 13,
    //           sick: 4,
    //           absence: 7,
    //           holiday: 20,
    //         },
    //         {
    //           sites: "Tudor",
    //           labourVsDemand: 9,
    //           sick: 3,
    //           absence: 0,
    //           holiday: 6,
    //         },
    //         {
    //           sites: "Croft Road",
    //           labourVsDemand: 6,
    //           sick: 2,
    //           absence: 1,
    //           holiday: 11,
    //         },
    //         {
    //           sites: "Blue Boys",
    //           labourVsDemand: 6,
    //           sick: 1,
    //           absence: 6,
    //           holiday: 11,
    //         },
    //         {
    //           sites: "Hilden",
    //           labourVsDemand: 6,
    //           sick: 3,
    //           absence: 0,
    //           holiday: 0,
    //         },
    //         {
    //           sites: "Holborough",
    //           labourVsDemand: 5,
    //           sick: 5,
    //           absence: 1,
    //           holiday: 8,
    //         },
    //         {
    //           sites: "Wisley North",
    //           labourVsDemand: 5,
    //           sick: 0,
    //           absence: 0,
    //           holiday: 9,
    //         },
    //         {
    //           sites: "Pyecombe",
    //           labourVsDemand: 5,
    //           sick: 2,
    //           absence: 0,
    //           holiday: 13,
    //         },
    //         {
    //           sites: "Chatham",
    //           labourVsDemand: 4,
    //           sick: 0,
    //           absence: 0,
    //           holiday: 0,
    //         },
    //         {
    //           sites: "Gatwick South",
    //           labourVsDemand: 4,
    //           sick: 23,
    //           absence: 0,
    //           holiday: 22,
    //         },
    //         {
    //           sites: "University Way",
    //           labourVsDemand: 2,
    //           sick: 1,
    //           absence: 2,
    //           holiday: 13,
    //         },
    //         {
    //           sites: "Wisley South",
    //           labourVsDemand: 0,
    //           sick: 3,
    //           absence: 2,
    //           holiday: 15,
    //         },
    //         {
    //           sites: "Leatherhead",
    //           labourVsDemand: -1,
    //           sick: 4,
    //           absence: 0,
    //           holiday: 9,
    //         },
    //         {
    //           sites: "Cheriton",
    //           labourVsDemand: -3,
    //           sick: 0,
    //           absence: 4,
    //           holiday: 13,
    //         },
    //         {
    //           sites: "Garlinge",
    //           labourVsDemand: -4,
    //           sick: 3,
    //           absence: 11,
    //           holiday: 15,
    //         },
    //         {
    //           sites: "Malling",
    //           labourVsDemand: -8,
    //           sick: 8,
    //           absence: 0,
    //           holiday: 6,
    //         },
    //         {
    //           sites: "Willingdon",
    //           labourVsDemand: -8,
    //           sick: 2,
    //           absence: 0,
    //           holiday: 16,
    //         },
    //         {
    //           sites: "Lenham",
    //           labourVsDemand: -8,
    //           sick: 2,
    //           absence: 0,
    //           holiday: 11,
    //         },
    //         {
    //           sites: "Pevensey",
    //           labourVsDemand: -16,
    //           sick: 7,
    //           absence: 0,
    //           holiday: 21,
    //         },
    //         {
    //           sites: "County Oak Connect",
    //           labourVsDemand: "NA",
    //           sick: 0,
    //           absence: 0,
    //           holiday: 0,
    //         },
    //         {
    //           sites: "Faygate",
    //           labourVsDemand: "NA",
    //           sick: 0,
    //           absence: 0,
    //           holiday: 0,
    //         },
    //         {
    //           sites: "Total",
    //           labourVsDemand: 4,
    //           sick: 3,
    //           absence: 2,
    //           holiday: 10,
    //         },
    //       ],
    //       lastWeekUpdated: 9,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: 6,
    //       total: 11,
    //       title: "Clocking on time",
    //       state: "OK",
    //       toolTipText:
    //         "Showing all sites in order of compliance for clocking in on time. Red header? That means the average for the region is below 80%. If compliance is 80% or greater, the header will be green. Where employees worked an unscheduled shift their compliance might exceed 100%. If the scheduled shift has not been worked due to illness or any other absence it would still be taken into consideration",
    //       displayGraph: false,
    //       graph: null,
    //       headers: ["Sites", "Compliance"],
    //       data: [
    //         { sites: "Alfold", compliance: 24 },
    //         { sites: "Blue Boys", compliance: 75 },
    //         { sites: "Hilden", compliance: 83 },
    //         { sites: "Malling", compliance: 87 },
    //         { sites: "Merrow", compliance: 89 },
    //         { sites: "Garlinge", compliance: 89 },
    //         { sites: "Chatham", compliance: 93 },
    //         { sites: "Pyecombe", compliance: 93 },
    //         { sites: "Pevensey", compliance: 94 },
    //         { sites: "Hove", compliance: 99 },
    //         { sites: "University Way", compliance: 99 },
    //         { sites: "Croft Road", compliance: 100 },
    //         { sites: "Holborough", compliance: 100 },
    //         { sites: "Wisley North", compliance: 100 },
    //         { sites: "Leatherhead", compliance: 101 },
    //         { sites: "Gatwick South", compliance: 102 },
    //         { sites: "Willingdon", compliance: 105 },
    //         { sites: "Church View", compliance: 109 },
    //         { sites: "Tudor", compliance: 110 },
    //         { sites: "Lenham", compliance: 113 },
    //         { sites: "Cheriton", compliance: 114 },
    //         { sites: "Wisley South", compliance: 115 },
    //         { sites: "Hillside", compliance: 115 },
    //         { sites: "North End", compliance: 125 },
    //         { sites: "Total", compliance: 97 },
    //       ],
    //       lastWeekUpdated: 40,
    //       expectedRefreshTimestamp: "Monday 3:00PM",
    //     },
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: "",
    //       total: "",
    //       title: "Unpublished schedules",
    //       state: "OK",
    //       toolTipText:
    //         "This widget will display sites within the region which have not published work schedules for either W+1 , W+2 or both W+1 and W+2. The warning icons are an indicator of exactly which schedule is missing",
    //       displayGraph: false,
    //       graph: null,
    //       headers: ["Sites", "W + 1", "W + 2"],
    //       data: [],
    //       lastWeekUpdated: 9,
    //       expectedRefreshTimestamp: "Monday 3:00PM",
    //     },
    //   ],
    // },
    // {
    //   sectionHeader: "Safety First Operations",
    //   weekNumber: 13,
    //   startDate: "27 Mar",
    //   endDate: "02 Apr",
    //   components: [
    //     {
    //       type: "table",
    //       title: "Safety Operations Overview () = last week count",
    //       state: "NEUTRAL",
    //       toolTipText:
    //         'This widget provides a summary of safety operations data for the last closed week. Click on column headers to sort data hierarchically.  The figures that are found within brackets ( ) represent the figures for the same data-point last week. The column marked "Safety Ind." represents the difference between safety conversations had and the sum of Near Miss, Inj. Count & G+ Incidents combined. (ie: a negative "Safety Ind" score would suggest more safety conversations are required to drive a positive safety culture)',
    //       headers: [
    //         "Sites",
    //         "Safety Ind.",
    //         "Safety Conv.",
    //         "Near Miss",
    //         "Inj. Count",
    //         "G+",
    //         "Security",
    //       ],
    //       data: [
    //         {
    //           sites: "Faygate",
    //           safetyInd: 0,
    //           safetyConv: "0 (0)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "0 (0)",
    //         },
    //         {
    //           sites: "Hilden",
    //           safetyInd: 2,
    //           safetyConv: "2 (2)",
    //           nearMiss: "0 (2)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "1 (0)",
    //         },
    //         {
    //           sites: "Malling",
    //           safetyInd: 3,
    //           safetyConv: "3 (3)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "3 (6)",
    //         },
    //         {
    //           sites: "County Oak Connect",
    //           safetyInd: 3,
    //           safetyConv: "3 (3)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "0 (2)",
    //         },
    //         {
    //           sites: "Hove",
    //           safetyInd: 3,
    //           safetyConv: "3 (6)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "4 (8)",
    //         },
    //         {
    //           sites: "Willingdon",
    //           safetyInd: 4,
    //           safetyConv: "5 (10)",
    //           nearMiss: "1 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "0 (1)",
    //         },
    //         {
    //           sites: "Tudor",
    //           safetyInd: 4,
    //           safetyConv: "4 (7)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "2 (3)",
    //         },
    //         {
    //           sites: "Gatwick South",
    //           safetyInd: 5,
    //           safetyConv: "5 (4)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "1 (6)",
    //         },
    //         {
    //           sites: "Cheriton",
    //           safetyInd: 5,
    //           safetyConv: "5 (3)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "0 (2)",
    //         },
    //         {
    //           sites: "Wisley North",
    //           safetyInd: 6,
    //           safetyConv: "7 (8)",
    //           nearMiss: "0 (0)",
    //           injCount: "1 (0)",
    //           g: "0 (0)",
    //           security: "0 (0)",
    //         },
    //         {
    //           sites: "Pevensey",
    //           safetyInd: 6,
    //           safetyConv: "6 (16)",
    //           nearMiss: "0 (3)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "4 (1)",
    //         },
    //         {
    //           sites: "Blue Boys",
    //           safetyInd: 6,
    //           safetyConv: "6 (5)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "3 (5)",
    //         },
    //         {
    //           sites: "University Way",
    //           safetyInd: 6,
    //           safetyConv: "6 (1)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "4 (0)",
    //         },
    //         {
    //           sites: "Hillside",
    //           safetyInd: 8,
    //           safetyConv: "8 (3)",
    //           nearMiss: "0 (1)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "0 (4)",
    //         },
    //         {
    //           sites: "Leatherhead",
    //           safetyInd: 8,
    //           safetyConv: "8 (8)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "0 (0)",
    //         },
    //         {
    //           sites: "Lenham",
    //           safetyInd: 10,
    //           safetyConv: "10 (5)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "0 (0)",
    //         },
    //         {
    //           sites: "Wisley South",
    //           safetyInd: 13,
    //           safetyConv: "13 (9)",
    //           nearMiss: "0 (0)",
    //           injCount: "0 (0)",
    //           g: "0 (0)",
    //           security: "2 (0)",
    //         },
    //         {
    //           sites: "Alfold",
    //           safetyInd: 16,
    //           safetyConv: "16 (11)",
    //           nearMiss: "0 (1)",
    //           injCount: "0 (0)",
    //           g: "0 (1)",
    //           security: "2 (4)",
    //         },
    //         {
    //           sites: "Total",
    //           safetyInd: 108,
    //           safetyConv: "110 (104)",
    //           nearMiss: "1 (7)",
    //           injCount: "1 (0)",
    //           g: "0 (1)",
    //           security: "26 (42)",
    //         },
    //       ],
    //       lastWeekUpdated: 10,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //     {
    //       type: "chart",
    //       title: "Regional Safety Scores",
    //       state: "NEUTRAL",
    //       toolTipText:
    //         'This widget provides a summary of safety operations data for the last closed week. Click on column headers to sort data hierarchically.  The figures that are found within brackets ( ) represent the figures for the same data-point last week. The column marked "Safety Ind." represents the difference of safety conversations had and the number of Near Miss, Inj. Count & G+ Incidents',
    //       infoSection: {
    //         header: "Last Week",
    //         dataPoints: ["Near Miss", "Injuries", "G+", "Security"],
    //       },
    //       data: [
    //         {
    //           weekNumber: 7,
    //           g: "0",
    //           injuries: "5",
    //           nearMiss: "8",
    //           security: "27",
    //         },
    //         {
    //           weekNumber: 8,
    //           g: "2",
    //           injuries: "2",
    //           nearMiss: "8",
    //           security: "30",
    //         },
    //         {
    //           weekNumber: 9,
    //           g: "1",
    //           injuries: "0",
    //           nearMiss: "7",
    //           security: "42",
    //         },
    //         {
    //           weekNumber: 10,
    //           g: "0",
    //           injuries: "1",
    //           nearMiss: "1",
    //           security: "26",
    //         },
    //       ],
    //       lastWeekUpdated: 10,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: "",
    //       total: "",
    //       title: "Dark Sites",
    //       state: "DANGER",
    //       toolTipText:
    //         "This widget shows sites that have no data entries for the following: Safety Conversations ; Near Misses ; Injury Count ; G+ Incidents & Security Incidents. The header will display as red if any dark sites are listed, and green if no dark sites are listed",
    //       displayGraph: false,
    //       graph: null,
    //       headers: ["Sites"],
    //       data: [{ sites: "Faygate" }],
    //       lastWeekUpdated: 10,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //     {
    //       type: "tile",
    //       arrow: null,
    //       rank: 11,
    //       total: 11,
    //       title: "Mand. Tasks",
    //       state: "DANGER",
    //       toolTipText:
    //         "This widget shows the % of priority 1,2 & 3 tasks completed within the last closed week, from the site with the highest mandatory task completion to the lowest. The colour of the header will be displayed as red if the region is ranked within the bottom 3 regions, green if within the top 3 regions and white for regions that fall within the middle of the range",
    //       displayGraph: false,
    //       graph: null,
    //       headers: ["Sites", "Completed"],
    //       data: [
    //         { sites: "Faygate", completed: 0 },
    //         { sites: "County Oak Connect", completed: 64 },
    //         { sites: "University Way", completed: 81 },
    //         { sites: "Malling", completed: 86 },
    //         { sites: "North End", completed: 89 },
    //         { sites: "Wisley North", completed: 89 },
    //         { sites: "Gatwick South", completed: 91 },
    //         { sites: "Hillside", completed: 91 },
    //         { sites: "Hove", completed: 93 },
    //         { sites: "Alfold", completed: 95 },
    //         { sites: "Blue Boys", completed: 95 },
    //         { sites: "Willingdon", completed: 95 },
    //         { sites: "Leatherhead", completed: 96 },
    //         { sites: "Cheriton", completed: 98 },
    //         { sites: "Church View", completed: 98 },
    //         { sites: "Pevensey", completed: 100 },
    //         { sites: "Tudor", completed: 100 },
    //         { sites: "Wisley South", completed: 100 },
    //         { sites: "Total", completed: 91 },
    //       ],
    //       lastWeekUpdated: 11,
    //       expectedRefreshTimestamp: "Monday 1:00PM",
    //     },
    //   ],
    // },
  ]
};


export const titleGenerator = {
  "Shop Sales vs Plan": "Shop",
  "Fuel Volume vs Plan":"Fuel",
  "Services vs Plan":"Services",
  "WBC Sales vs Plan":"WBC",
  "Waste": "Waste",
  "Till Losses": "Till",
  "Customer Satisfaction": "Customer",
  "Regional Loyalty": "Regional",
  "Loyalty Ranking": "Loyalty",
  "People Overview": "People",
  "Safety Operations Overview": "Safety",
  "Mand. Tasks": "Mand.",
  "Clocking on time": "Clocking",
  "Dark Sites": "Dark",
  "Unpublished schedules": "Unpublished"
}