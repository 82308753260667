/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 03/06/2022
* Author : Sunil Vora
*/

import { handleResponse } from '../helpers/commons';
import { LANGUAGE_URLS } from '../constants/environmentConstants';

export const financialServices = {
    getFinancialData
}

function getFinancialData(id, data, languageCode) {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization':data
        } 
    }
    const url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    return fetch(`${url}/financials`, requestOptions)
    .then(handleResponse)
    .then(data => {
        return data;
    })
    .catch(error => {
        return Promise.reject(error);
    });
}