/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonContainer, ButtonText, ArrowUp, ArrowDown, DropDownStyle } from './dropdown.styled'
import { RadioButton } from '../button/radioButton'
import { ChecklistSelection } from '../button/checklistSelection'
export function DropDown(props) {
  const { t } = useTranslation();
  const [searchvalue, setSearchValue]= useState('')

 const  changeHandler =(e)=>{
    let value = e.target.value;
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setSearchValue(value)
      props.searchFilter(value)
    }
   
}
 const {type,dropDownKey} = props
  return (
    <div style={{ zIndex: 0, position: "static" }} >
      <ButtonContainer className={props.styleValues} onClick={() => props.onClick()}>
        <ButtonText>{props.selectedViewText}</ButtonText>
        {props.dropDownKey ? <ArrowUp /> :
          <ArrowDown />}
      </ButtonContainer>
      {type === 'week' && dropDownKey && <input id = "searchValue" type='text' minLength={2} maxLength={4} name="week" value = {searchvalue} onChange={changeHandler}  placeholder="Write here..."  className={'dropdownsearchAdmin'}/>}
      {props.dropDownKey && props.arrayList.length>0 && <DropDownStyle style={props.scrollView ? { top: props.alignHeight + 7 } : {}} className={props.filterDropDownStyle} >
        {props.radioButton && <RadioButton
          arrayList={props.arrayList}
          regionalManager={props.regionalManager || false}
          width={props.filterWidth}
          selectedValue={props.selectedItem}
          onClick={(item, index) => {
            setSearchValue("")
            props.itemSelection(item, index)}
          }
          usedFor={props.usedFor}
        />}
        {props.checklistSelection && props.arrayList.length>0 && <ChecklistSelection
          arrayList={props.arrayList}
          width={props.filterWidth}
          selectedViewText={props.selectedViewText}
          regionalManager={props.regionalManager || false}
          noStarProp={props.noStarProp}
          selectedMethod={(item, index) => {
            setSearchValue("")
            props.itemSelection(item, index)}
          }
          selectedValue={props.selectedItem}
        />}
      </DropDownStyle>}
    </div>
  )
}

export default DropDown
