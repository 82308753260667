/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/5/2023
*/

import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import isMobile from "../../../../helpers/isMobile";
import {
  LoyaltyFor
} from "../../Customer/style/Customer.styled";
import useWindowDimensions, {
  responsiveGraphWidth,
} from "../../TradingProfitably/utils/WindowDimensions";

import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../actions/scorecardActions";
import i_icon_alert from "../../../../images/icons/i_icon_alert.svg";
import { TooltipValue } from "../../../Scorecard/ScoreCard.styled";
import {
  defaultSortOrder,
  renderSortIcons,
  sortData
} from "../../../Scorecard/utils";
import { KPINames } from "../../../ScorecardImproved/ScorecardImprovedConstants";
import { TileHeader } from "../../Customer/Screens/Components/TileHeader";
import { returnRequiredPeopleOverviewHeader, returnRequiredRegionalHeader } from "../../Customer/Utils/customerUtils";
import {
  Actual,
  ActualText,
  AlertIcon,
  DataContainer,
  Exclaimation,
  ExclaimationText,
  MainContainer,
  OldDataBlur,
  OlddataRLcontent,
  Oldtext,
  Oldviewcustomersatisfaction,
  ScoreGraphContainer,
  ScoreLegendContainer
} from "../../Customer/style/Customer.styled";
import { Olddatatradingapprovee } from "../../TradingProfitably/Styles/TradingUi.styled";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import ScoreLineGraph from "../../components/ScorecardLineGraph";
import { titleGenerator } from "../../scorecardConstants";
import {
  Greenbar,
  Headertradingtable,
  PeopleOverviewBox,
  PeopleOverviewTable,
  Peopleheadingcell,
  Peopleicon,
  Progress,
  Redbar,
  TotalclockingContainerRow,
  Whitepeoplebar,
} from "../Style/PeopleUi.styled";
import { renderAlert } from "../Utils/Utils";

function PeopleOverView(props) {
  const { renderItems, peopleData } = props;
  const { headers, data, title, state, graph, toolTipText,  lastWeekUpdated,expectedRefreshTimestamp } = renderItems;
  const [graphData, setGraphData] = useState([]);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  let headerNames = data?.length > 0 ? defaultSortOrder(data) : [];
  const [sortedData, setSortedData] = useState(data);
  const [sortState, setSortState] = useState(headerNames);
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [decPos, setDecPos] = useState(1);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
  const [blur, setBlur] = useState(props.weekNumber !== lastWeekUpdated);
  let maxLabVsDem = 0;

  data.forEach((item) => {
    if (item.labourVsDemand != 'NA') {
       maxLabVsDem =  Math.max(maxLabVsDem, Math.abs(item.labourVsDemand))
    }
  })
  
  const getGraphData = (scoreSales) => {
    let sales = [];
    scoreSales?.forEach((sale) => {
      if (props.siteNames.length === 0 && !isMobile()) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else if (isMobile() && props.noSelected === 0) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
        ];
      }
    });
    return sales;
  };

  function loadExcel() {
    props.exportToExcel(titleGenerator[title], headers, sortedData);
  }

  useEffect(() => {
    setGraphData([]);
    async function correctGraph() {
      let graphData = await getGraphData(graph)
      setGraphData(graphData);
    }
    correctGraph();
  }, [props, width, blur])



  function checkWeigtage(value) {
    if (value < 0) {
      return false;
    } else {
      return true;
    }
  }

  const renderPeopleData = (headerKey, itemData, headerIndex, usedFor) => {
    let key = returnRequiredRegionalHeader(headerKey);
    let booleanProgress = checkWeigtage(itemData["labourVsDemand"]);

    if (headerIndex === 2 && renderAlert(itemData["labourVsDemand"])) {
      return (
        <Peopleheadingcell
          index={headerIndex}
          colorRed={renderAlert(itemData["labourVsDemand"])}
        >
         <Peopleicon> <AlertIcon src={i_icon_alert} alt="alert" />
          {itemData["labourVsDemand"] === "NA" ? itemData["labourVsDemand"] : itemData["labourVsDemand"] >= 0 ?  `+${itemData["labourVsDemand"]}%`:`${itemData["labourVsDemand"]}%`}</Peopleicon>
        </Peopleheadingcell>
      );
    }
    if(headerIndex === 2 && !renderAlert(itemData["labourVsDemand"])){
      return (
        <>
         <Peopleheadingcell renderedIn="table" index={headerIndex}>
        {itemData["labourVsDemand"] === "NA" ? itemData["labourVsDemand"] : `${itemData["labourVsDemand"]}%`}
      </Peopleheadingcell>
        </>
      )
    }

    return headerIndex === 1 ? 
    (
      <Peopleheadingcell index={headerIndex}>
      { usedFor !== 'totalRow' ? <Whitepeoplebar>
          {booleanProgress ? (
            <>
              <Redbar />
              <Greenbar>
                <Progress
                  width={(itemData["labourVsDemand"] / maxLabVsDem) * 100}
                  booleanProgress={renderAlert(itemData["labourVsDemand"])}
                />
              </Greenbar>
            </>
          ) : (
            <>
              <Redbar>
                <Progress
                  width={
                    (Math.abs(itemData["labourVsDemand"]) / maxLabVsDem) * 100
                  }
                  booleanProgress={renderAlert(itemData["labourVsDemand"])}
                />
              </Redbar>
              <Greenbar />
            </>
          )}
        </Whitepeoplebar>: null}
      </Peopleheadingcell>
    ) : headerIndex === 0 ? (
      <Peopleheadingcell renderedIn="table" index={headerIndex}>
        {itemData[key]}
      </Peopleheadingcell>
    ) : (
      <Peopleheadingcell renderedIn="table" index={headerIndex}>
        {itemData[key] === "NA" ? itemData[key] : `${itemData[key]}%`}
      </Peopleheadingcell>
    );
  };

  const renderSortIcon = (colName) => {
    return true;
  };

const getHeaderState = () => {
  const avgLabourVsDemand = sortedData.filter(key => key.sites == 'Total')[0]?.labourVsDemand;
  if (-10< avgLabourVsDemand && avgLabourVsDemand < 10)
  {
    return "OK";
  }
  return "DANGER"
  
  
}
  const sortItem = (keyName, sortState, sortedData) => {
    let result;
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    const rowsWithNA = data.filter((rows) => rows[keyName] === "NA");
    if (isAnyColumnSorted === "NONE") {
      const initialSortState = Object.keys(data[0]).map((item) => ({
        name: item,
        sortState: "NONE",
      }));
      result = sortData(
        keyName,
        initialSortState,
        data.filter((rows) => rows[keyName] !== "NA")
      );
    } else {
      result = sortData(
        keyName,
        sortState,
        sortedData.filter((rows) => rows[keyName] !== "NA")
      );
    }
    const currentState = result.sortedState.find((val) => val.name === keyName);
    setSortState(result.sortedState);
    setSortedData(
      currentState.sortState === "ASC"
        ? [...result.sortingData, ...rowsWithNA]
        : [...rowsWithNA, ...result.sortingData]
    );
  };

  const handleHover = (event, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
     
    } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };

  return (
    <PeopleOverviewBox>
        <OldDataBlur boolTrue={blur} id= "OldDataBlur">
      {title === 'Waste' || title === 'Till Losses' ? <>
    {
     props.weekNumber - 1 === 0 ?
     <>
     {!blur && 52 !== lastWeekUpdated && (
       <Exclaimation
         onMouseEnter={(e) => blurHover(e, "enter")}
         onMouseLeave={(e) => blurHover(e, "leave")}
         id="exclamationContainer"
       >
         <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
       </Exclaimation>
     )}
     </>
     :
     <>
     {!blur && props.weekNumber-1 !== lastWeekUpdated && (
       <Exclaimation
         onMouseEnter={(e) => blurHover(e, "enter")}
         onMouseLeave={(e) => blurHover(e, "leave")}
         id="exclamationContainer"
       >
         <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
       </Exclaimation>
     )}
     </>
    }
    </>:<>
    {!blur && props.weekNumber !== lastWeekUpdated && (
       <Exclaimation
         onMouseEnter={(e) => blurHover(e, "enter")}
         onMouseLeave={(e) => blurHover(e, "leave")}
         id="exclamationContainer"
       >
         <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
       </Exclaimation>
     )}
     </>}
     {oldPopUpVisible && (
       <TooltipValue
       id = "tooltipvalue"
         top = {blurRect.top + 70}
         left = {blurRect.left + 15}
       >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
     )}
      <TileHeader
        exportToExcel = {() => loadExcel()}
        excelLoader={props.excelLoader}
        kpiName = {props.kpiName}
        showPopup={showPopup}
        handleHover={(e, value) => handleHover(e, value)}
        title="People Overview"
        state={getHeaderState()}
      />
      {showPopup && (
           <ScorePopupContainerWeb
           top={top}
           left={left-20}
           width = {18}
            id='popUpDesktop'
            textDescription = {toolTipText}
         />
      )}
      <ScoreGraphContainer id="CS">
        <ScoreLegendContainer id="ScoreLegendContainer" usedIn = 'people'>
          <LoyaltyFor>Labour vs Demand:</LoyaltyFor>
          {!isMobile() && props.siteNames.length === 0 && (
              <>

                <Actual
                  id="actual-text"
                  isDanger={getHeaderState()}
                  isActualMe={true}
                ></Actual>
                <ActualText id="actual-text">
                  Actual UK
                </ActualText>{" "}
              </>
            )}

            {!isMobile() && (
              <>
                <Actual state={state}></Actual>
                <ActualText
                  id="actual-text"
                  isTill
                  Losses={title === "Till Losses"}
                >
                  {props.siteNames.length === 0
                    ? props.selectedManagerName
                    : props.siteNames.length === 1
                      ? props.siteNames[0]
                      : t("Selected Sites")}
                </ActualText>
              </>
            )}

            {isMobile() && props.siteNames.length === 0 && (
              <>
                <Actual state={state}></Actual>
                <ActualText id="actual-text" isLosses={title === "Till Losses"}>
                  Actual UK
                </ActualText>
              </>
            )}

            {isMobile() && (
              <>
                <Actual
                  id="actual-text"
                  isDanger={state === "DANGER"}
                  isActualMe={true}
                ></Actual>
                <ActualText
                  id="actual-text"
                  isTill
                  Losses={title === "Till Losses"}
                >
                  {props.noSelected === 0
                    ? props.selectedManagerName
                    : props.siteNames.length === 1
                      ? props.siteNames[0]
                      : t("Selected Sites")}
                </ActualText>
              </>
            )}
       </ScoreLegendContainer>
        {!isEmpty(graphData) ? (
          <ScoreLineGraph
            regionalManager={props.selectedManagerName}
            isDanger={state === "DANGER"}
            scoreGraph={true}
            xScale={responsiveGraphWidth(width, "People")}
            graphId={`scoreCard-${title.replace(/[^a-zA-Z]/g, "")}`}
            graphClass={`scoreGraph-${title.replace(/[^a-zA-Z]/g, "")}`}
            sales={graphData}
            title={title}
            state={state}
            data={props.dataFor}
            selection={props.noSelected}
            siteLength={props.siteNames.length}
            device={isMobile()}
            id="Financial_graph"
            scroll={props.event}
            decPos={decPos}
            boolValue = {blur}
          />
        ) : null}
      </ScoreGraphContainer>
      <PeopleOverviewTable>
      <Headertradingtable>
        {headers.map((item, index) => {
          return index === 1 ? (
            <Peopleheadingcell index={index}></Peopleheadingcell>
          ) : (
            <Peopleheadingcell
              onClick={() =>
                sortItem(
                  returnRequiredPeopleOverviewHeader(item),
                  sortState,
                  sortedData
                )
              }
              index={index}
            >
              {item}{" "}
              {renderSortIcon(item) &&
                renderSortIcons(
                  returnRequiredPeopleOverviewHeader(item),
                  sortState,
                  true,
                  data.state
                )}
            </Peopleheadingcell>
          );
        })}
      </Headertradingtable>
      <MainContainer height={170}>
        {!isEmpty(sortedData)
          ? sortedData.filter(key => key.sites !== 'Total').map((itemData, index) => {
              return (
                <DataContainer
                  key={index}
                  alignIndex={index}
                  id="dataContainer"
                >
                  {headers.map((headerKey, headerIndex) =>
                    renderPeopleData(headerKey, itemData, headerIndex, "dataRows")
                  )}
                </DataContainer>
              );
            })
          : null}
      </MainContainer>

      <TotalclockingContainerRow>
      {!isEmpty(sortedData)
          ? sortedData.filter(key => key.sites === 'Total').map((itemData, index) => {
              return (
               <> {headers.map((headerKey, headerIndex) =>
                renderPeopleData(headerKey, itemData, headerIndex, "totalRow")
              )}
               </>
            );
            })
          : null}
      </TotalclockingContainerRow>
      </PeopleOverviewTable>
      </OldDataBlur>
      {blur && (
          <Oldviewcustomersatisfaction id = "OldDataFullview">
             <OlddataRLcontent id = "olddatacontent">
              <Oldtext id="old">You're viewing old data</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates occurs on&nbsp;{props.peopleData}
              </Oldtext>
              <Olddatatradingapprovee id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </Olddatatradingapprovee>
            </OlddataRLcontent> 
           </Oldviewcustomersatisfaction>
        )}
    </PeopleOverviewBox>
  );
}

const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

const mapStateToProps = (state) => {
  const { scorecardInfo, loader, authInfo,scorecardImprovedInfo } = state;
  const { userData } = authInfo;
  const { showLoader } = loader;
  const { excelLoader, kpiName, siteNames, noSelected, selectedManagerName } =
    scorecardInfo;
    const layout = scorecardImprovedInfo[KPINames.LAYOUT];
    const peopleData = layout?.data?.schedules?.peopleData;
  return {
    excelLoader,
    kpiName,
    showLoader,
    siteNames,
    noSelected,
    selectedManagerName,
    userData,
    peopleData
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(PeopleOverView)
);

