/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 02/11/2022
* Author : Sunil Vora
*/
import { constants } from '../constants/reducerConstants';

const initialState = {
    currentMenuItem: '',
    menuData: {}
}

export function menuInfo(state = initialState, action) {
    switch (action.type) {
        case constants.SAVE_CURRENT_MENUITEM:
            return {
                ...state,
                currentMenuItem: action.menuItem
            };
        case constants.SAVE_MENU_DATA:
            return {
                ...state,
                menuData: action.data.body
            };
        default:
            return state
    }
}
