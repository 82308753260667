import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import CustomerSection from "./sections/Customer/CustomerSection";
import PeopleSection from "./sections/People/PeopleSection";
import SafetyFirstSection from "./sections/SafetyFirstOperations/SafetyFirstOperations";
import TradingProfitably from "./sections/TradingProfitably/TradingProfitably";

function ScorecardContent() {
  return (
    <Container fluid>
      <TradingProfitably />
      <CustomerSection />
      <PeopleSection />
      <SafetyFirstSection />
    </Container>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ScorecardContent
);
