/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/30/2022
 * Author : Aditya Padmanabhan
 */



import React, { useEffect, useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { scorecardActions } from '../../actions/scorecardActions'
import { DOWNLOAD_SECTION_TILE_NAME, EVENT_DOWNLOAD_EXCEL, KEY_USER_ID, KEY_USER_TITLE, UNKNOWN_TITLE } from '../../constants/FireBaseEvents'
import isMobile from '../../helpers/isMobile'
import { isTablet } from '../../helpers/isTablet'
import cross_icon from "../../images/icons/cross_icon.svg"
import i_cross_white from "../../images/icons/i_cross_white.svg"
import i_download from "../../images/icons/i_download.svg"
import i_download_white from "../../images/icons/i_download_white.svg"
import i_icon from "../../images/icons/i_icon.svg"
import i_icon_info_bold_white from "../../images/icons/i_icon_info_bold_white.svg"
import { IconImage } from '../GoogleReviewDashboard/ReviewsDashboard.styled'
import { ExcelSpin } from '../commonStyles/common.styled'
import { C1, CircleandDesc, Circlesubtext, CircularArea, Circularprogress, Coloredcircle, CsatScore, CustomerSatisfactionHeading, CustomerSatisfactionMobile, CustomerSatisfactionTable, DataContainer, DescriptionProgress, Detailtext, Exclaimation, ExclaimationText, HeaderContainer, MainContainer, MobileCircleContainer, MobileLegendexplaination, MobilePopup, Mobilesitedetails, Mobilesiteheading, Name, NocircularData, OldDataApprove, OldDataBlur, OldDataContent, OldDataFullview, Oldtext, Percentage, Perlinedetails, Persitedetails, Persitefield, ProgressVoc, ScoreDownloadIcon, ScoreExcelLabel, ScoreExportContainer, ScoreGraphContainer, ScoreHeader, ScoreKpiName, ScorePopupContainer, ScoreTitle, Sitetext, SomeValue, TextAreaCSAT, TooltipValue, ValueContainer, VoCDataFullview, VoCProgress, VoCValue, VocTableHeader, VoiceDataBlur } from './ScoreCard.styled'
import { capitalize, createMarkup, defaultSortOrder, eventLogging, getFieldFromKey, renderSortIcons, sortData } from './utils'

function VoiceOfCustomer(props) {
    const { state, title, toolTipText,  headers, data, graph, rank, total,lastWeekUpdated, expectedRefreshTimestamp, arrow
    } =  props.data;
    const headerNames = data?.length > 0 ? defaultSortOrder(data) : [];
    const [sortedData, setSortedData] = useState(data);
    const [sortState, setSortState] = useState(headerNames);
    const [site, setSite] = useState('');
    const [rect, setRect] = useState(0);
    const [ blur, setBlur] = useState(props.weekNumber !== lastWeekUpdated);
    const [blurRect, setBlurRect] = useState(0);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [showPopup, setShowPopup] = useState('');
    const [oldPopUpVisible, setOldPopUpVisible] = useState(false);

    useEffect(()=>{
        setSortedData(data);
        window.addEventListener('scroll', controlDirection);
        return () => {
            window.removeEventListener('scroll', controlDirection);
        };
     },[data]);
     const controlDirection = () => {
        setOldPopUpVisible(false)
        setShowPopup("")
    }
   const getBackgroundColor = (status) => {
        switch (status) {
            case 'NEUTRAL':
                return '#ffffff';
            case 'DANGER':
                return '#bb453a';
            case 'OK':
                return '#007833';
            default:
                break;
        }
    }

    const getMarginIcon = (title) => {
        switch(title) {
            case 'Mand. Tasks':
                return 5;
            case 'Dark Sites':
                return 5;
            case 'Clocking on time':
                return 5;
            case 'Unpublished schedules':
                return 2;
            default:
                return 15;
        }
    }

    const paddingRight = (headerKey) => {
        if (headerKey === 'Var to plan' && title === 'Waste') {
            return 4;
        }
        switch(headerKey) {
            case 'Var LFL':
                return 5;
            case 'Vs sales':
                return 33;
            case '% to total':
                return 22;
            case 'Waste':
                return 12;
            default:
                return 0;
        }
    }

    const exportToExcel = () => {
        const kpiName = title.split(' ')[0];
        props.exportToExcel(kpiName, headers, sortedData);

        let downloadEventData = {
          [KEY_USER_ID]: props?.userData?.id,
          [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
          [DOWNLOAD_SECTION_TILE_NAME]:title
          }
          eventLogging(EVENT_DOWNLOAD_EXCEL, downloadEventData)
    }

    const handleHover = (event, name, type) => {
        const currRect = event.target.getBoundingClientRect();
        setTop(currRect.top);
        setLeft(currRect.left);
        if (type === 'leave') {
            setShowPopup('');
        } else {
            setShowPopup(name);
        }
    }

    const handleSiteHover = (event, value, type) => {
        if (type === 'leave') {
            setSite(value);
        }
        if (event.target.scrollHeight > event.target.clientHeight) {
            setSite(value);
            setRect(event.target.getBoundingClientRect());
        }
    }

    const renderData = (headerKey, itemData, headerIndex, title) => {
        let fieldName = getFieldFromKey(headerKey);
        const { left, top } = rect;
        if(fieldName === "wowPercent" || fieldName === "csatScore"|| fieldName === "rescuePercent"){
            return( <VoCValue  id={itemData[fieldName]+headerIndex+itemData} paddingRight={paddingRight(headerKey)} align='end' alignment={headerIndex} isTileWider={false} headerKey={headerKey} widthKey={title}>{itemData[fieldName] === 'NA' || itemData[fieldName] === 0 ? itemData[fieldName] : `${itemData[fieldName]}%`}
           </VoCValue>)  
          }
         return (
          <VoCValue id={itemData[fieldName]+headerIndex+itemData} onMouseOver={(e) => handleSiteHover(e, itemData[fieldName], 'enter')} onMouseOut={(e) => handleSiteHover(e, '', 'leave')} flex={0.5} paddingRight={paddingRight(headerKey)} align='end' alignment={headerIndex} isTileWider={false} headerKey={headerKey} widthKey={title}>{itemData[fieldName] === 'NA' ? itemData[fieldName] : headerKey === 'Sites'?`${capitalize(itemData[fieldName])}`:`${itemData[fieldName]}`}
          {site === itemData[fieldName] && <TooltipValue top={top} left={left}>{capitalize(itemData[fieldName])}</TooltipValue>}</VoCValue>
        );
        }

    const sortItem = (keyName, sortState, sortedData) => {
        let result;
        const isAnyColumnSorted = sortState?.find(val => val.name === keyName)?.sortState;
        const rowsWithNA = data.filter(rows => rows[keyName] === 'NA');
        if (isAnyColumnSorted === 'NONE') {
            const initialSortState = Object.keys(data[0]).map(item => ({name: item, sortState: 'NONE'}));            
            result = sortData(keyName, initialSortState, data.filter(rows => rows[keyName] !== 'NA'));
        } else {
            result = sortData(keyName, sortState, sortedData.filter(rows => rows[keyName] !== 'NA'));
        }
        const currentState = result.sortedState.find(val => val.name === keyName);
        setSortState(result.sortedState);
        setSortedData(currentState.sortState === 'ASC' ? [...result.sortingData, ...rowsWithNA] : [...rowsWithNA, ...result.sortingData]);     
    }

    const renderSortIcon = (colName) => {
        if (colName === 'W + 1' || colName === 'W + 2') {
            return false;
        }
        return true;
    }

    const colorDetermine = (value) =>{
         if(value >= 75) {
            return "#007833"
         }
         else if(value >= 25){
            return "#999999"
         }
         else{
            return "#bb453a"
         }
    }

    const getDotColor = (value) =>{
        if(value === "green") {
           return "#007833"
        }
        else if(value === "grey"){
           return "#999999"
        }
        else{
           return "#bb453a"
        }
   }

  const blurHover = (event, type) => {
    if(type==='enter'){
        setBlurRect(event.target.getBoundingClientRect());
        setOldPopUpVisible(true)
        props.changeScrollEvent();
        props.changeEvent();
    }
    else{
        setOldPopUpVisible(false)
        setBlurRect(0)
    }
}

const renderValue = (headeritem, item, covertedHeader) => {
   if(covertedHeader === "wowPercent" || covertedHeader === "csatScore"|| covertedHeader === "rescuePercent"){
      return(<Sitetext id={covertedHeader+item} value={true}>{ item[covertedHeader] === 'NA'  ? `${item[covertedHeader]}`: `${item[covertedHeader]}%`}</Sitetext>)  
    }
    return (
      <Sitetext id={covertedHeader+item}  value={true}>{item[covertedHeader]}</Sitetext>
    )
  
  }


if(isMobile()){
    return (
       <CustomerSatisfactionMobile id="customerSatisfactionMobile">
       <Card className='data-card' id="vocCard">
        <VoiceDataBlur boolTrue = {blur} id="voiceDataBlur">
       
          {oldPopUpVisible && (
            <TooltipValue
              top={blurRect.top + 70}
              left={blurRect.left + 15}
              id = "tooltipvalue"
            >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
          )}
        <div className='data-card' style={{height:"255px"}} id="data-card-voc">
        
        <ScoreHeader id= "scoreHeader" isCoManager={true} title={title} isMobile={isMobile()} color={getBackgroundColor(state)}>
        {!blur && props.weekNumber !== lastWeekUpdated && (
            <Exclaimation
             id="exclamationContainer"
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
            >
              <ExclaimationText id="ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
                    <ScoreTitle value="card" id="card">
                    <ScoreKpiName id="scoreKpiName" textColor={state === 'NEUTRAL' ? 'black' : 'white'}>{title}</ScoreKpiName>
                    {
                        <div className="safety-card-view" id="safety-card-view" onMouseOver={(e) => handleHover(e, title, 'enter')} onMouseLeave={(e) => handleHover(e, '', 'leave')} onClick ={(e)=>{isTablet()&& showPopup===title ? handleHover(e, '', 'leave'):handleHover(e, title, 'enter')}}>
                           {isTablet()?<img className="cross-class" alt="tile_info" src={ showPopup === title ?  cross_icon: i_icon} /> 
                            :<img className="cross-class" alt="tile_info" src={state === 'NEUTRAL' ? i_icon : i_icon_info_bold_white} />  }                        
                        </div>
                        }
                </ScoreTitle>
                </ScoreHeader>
                {showPopup === title &&
                <MobilePopup id='popUpMobile' top={70} left={230} width={20} arrowTop={0} arrowLeft={-100}>
                    <span className="What-you-can-expect">
                        <span key={toolTipText} dangerouslySetInnerHTML={createMarkup(toolTipText)} />
                    </span>
                </MobilePopup>}   
                <MobileCircleContainer id="mobileCircleContainer">
                    <CircularArea marginTop = {0} id="circularArea">
                        <TextAreaCSAT id="textAreaCSAT">
                    <CustomerSatisfactionHeading value = 'heading' id="customerSatisfactionHeading">{graph.graphText.heading}</CustomerSatisfactionHeading>
                <CustomerSatisfactionHeading id="customerSatisfactionHeading">{graph.graphText.subHeading}</CustomerSatisfactionHeading></TextAreaCSAT>
                   <TextAreaCSAT id="textAreaCSAT">
                        {graph.legend.map((item, index)=>{
                                    return(<MobileLegendexplaination id={index+"mobileLegendExplain"}>
                                         <Coloredcircle id={index+"coloredCircle"} color = {getDotColor(item.color)}/>
                                         <Detailtext id={index+"detailText"}>{item.value}</Detailtext>
                                        <Detailtext id={index+"detailText"}>{item.description}</Detailtext>
                                    </MobileLegendexplaination>
                                    )
                                 })}
                        </TextAreaCSAT>
                    </CircularArea>
                    <CircularArea marginTop = {10} id="circularArea">
                    {graph.data.map((item,index)=>{
                                    return(<>
                                    <TextAreaCSAT id={index+"textAreaCSAT"}>
                                   <ProgressVoc id = {index+"progressVoc"} percentage ={item.percent} color = {colorDetermine(item.percent)}>
                                        <C1 id="c1">
                                         <ValueContainer id={index+"valueContainer"}>{item.percent}%</ValueContainer>
                                         <SomeValue id={index+"someValue"}>{item.count}</SomeValue>
                                        </C1>
                                    </ProgressVoc>
                                    <Circlesubtext id={index+"circleSubText"}>{item.description}</Circlesubtext>
                                 </TextAreaCSAT>
                                    </>)
                                })}
                    </CircularArea>
                </MobileCircleContainer>
            </div>
        <div className='data-card' style ={{height: "250px", overflowX: "hidden", overflowY:"hidden"}}>
        <ScoreHeader id= {"header"+title} title={title} isMobile={isMobile()} color={getBackgroundColor(state)}>
                    <ScoreTitle value="card" id="card">
                    <ScoreKpiName id="scoreKpiName" textColor={state === 'NEUTRAL' ? 'black' : 'white'}>{'CAST per site'}</ScoreKpiName>
                </ScoreTitle>
                </ScoreHeader>
                <CustomerSatisfactionTable id="customerSatisfactionTable">
             {sortedData.map((item, idx) => {
                return <Persitedetails id="perSiteDetails">
                <Mobilesiteheading id="mobileSiteHeading">{item.sites}</Mobilesiteheading>
                <Mobilesitedetails id="mobileSiteDetails">
                    {headers.map((headeritem,key)=>{
                      const covertedHeader = getFieldFromKey(headeritem);
                      return(<>
                         {key!==0?<>
                          <Persitefield id="perSiteField">
                            <Sitetext id="siteText" >{headeritem}</Sitetext>
                            {renderValue(headeritem, item, covertedHeader)}
                          </Persitefield>
                         </>:null}
                        </>)
                    })}
                </Mobilesitedetails>
              </Persitedetails>;
              })}
          </CustomerSatisfactionTable>
         </div>
        </VoiceDataBlur>
        {blur && (
          <VoCDataFullview id="vocDataFullView">
            {/* <OldDataContent>
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext>This data has not been updated yet.</Oldtext>
              <Oldtext>
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent> */}
            <OldDataContent id = "olddatacontent">
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </VoCDataFullview>)}
          </Card>
       </CustomerSatisfactionMobile>
    )
  }

  return (
    <Col className="VoC" id="voc">
      <Card className="data-card" id="data-card-voc">
        <OldDataBlur boolTrue={blur}>
          {!blur && props.weekNumber !== lastWeekUpdated && (
            <Exclaimation
            id="exclamationContainer"
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
            >
              <ExclaimationText id="ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
          {blurRect !== 0 && (
            <TooltipValue
              top={blurRect.top + 70}
              left={blurRect.left + 15}
              id = "tooltipvalue"
            >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
          )}
          <ScoreHeader
            title={title}
            id= {"header"+title}
            isMobile={isMobile()}
            color={getBackgroundColor(state)}
          >
            <ScoreTitle value="card" id="card">
              <ScoreKpiName id="scoreKpiName" textColor={state === "NEUTRAL" ? "black" : "white"}>
                {title}
              </ScoreKpiName>
              {sortedData?.length > 0 && !isMobile() && !isTablet() && (
                <ScoreExportContainer
                  isRegionalManager={true}
                  onClick={() => exportToExcel()}
                  mobile={isMobile()}
                >
                  <ScoreExcelLabel isNeutral={state === "NEUTRAL"}>
                    Excel
                  </ScoreExcelLabel>
                  <ScoreDownloadIcon id = "ScoreDownloadIcon" marginRight={getMarginIcon(title)}>
                    {props.excelLoader &&
                    props.kpiName === title.split(" ")[0] ? (
                      <ExcelSpin
                        color={state === "NEUTRAL" ? "#007833" : "white"}
                      />
                    ) : (
                      <IconImage
                        src={
                          state === "NEUTRAL" ? i_download : i_download_white
                        }
                        alt="scorecard_excel"
                      />
                    )}
                  </ScoreDownloadIcon>
                </ScoreExportContainer>
              )}
              {rank && total && (
                <Percentage id="percentage" textColor={state === "NEUTRAL" ? "black" : "white"}>
                  {rank}/{total}
                </Percentage>
              )}
              <div
                className="right-card-view"
                id="right-card-view"
                onMouseOver={(e) => handleHover(e, title, "enter")}
                onMouseOut={(e) => handleHover(e, "", "leave")}
                onClick={(e) => {
                  isTablet() && showPopup === title
                    ? handleHover(e, "", "leave")
                    : handleHover(e, title, "enter");
                }}
              >
                {isTablet() ? (
                  <img
                    className="cross-class"
                    alt="tile_info"
                    src={
                      showPopup === title
                        ? i_cross_white
                        : i_icon_info_bold_white
                    }
                  />
                ) : (
                  <img
                    className="cross-class"
                    alt="tile_info"
                    src={state === "NEUTRAL" ? i_icon : i_icon_info_bold_white}
                  />
                )}
              </div>
            </ScoreTitle>
          </ScoreHeader>
          <ScoreGraphContainer id="CS">
            <VoCProgress id="vocProgress">
              <CsatScore id="csatScore">
                <CustomerSatisfactionHeading value="heading" id="customerSatisfactionHeading">
                  {graph.graphText.heading}
                </CustomerSatisfactionHeading>
                <CustomerSatisfactionHeading id="customerSatisfactionHeading">
                  {graph.graphText.subHeading}
                </CustomerSatisfactionHeading>
              </CsatScore>
              <Circularprogress id="circularProgress">
                {graph.data.length > 0 ? graph.data.map((item,index) => {
                  return (
                    <>
                      <CircleandDesc id={index+"circleandDesc"}>
                        <ProgressVoc
                          id = {index+"progressVoc"}
                          percentage={item.percent}
                          color={colorDetermine(item.percent)}
                        >
                          <C1 id = {index+"c1"}>
                            <ValueContainer id = {index+"valueContainer"}>{item.percent}%</ValueContainer>
                            <SomeValue id = {index+"someValue"}>{item.count}</SomeValue>
                          </C1>
                        </ProgressVoc>
                        <Circlesubtext id = {index+"circleSubText"}>{item.description}</Circlesubtext>
                      </CircleandDesc>
                    </>
                  );
                }): <NocircularData>no data available</NocircularData> }
              </Circularprogress>

              <DescriptionProgress id="descriptionProgress">
                {graph.legend.map((item, index) => {
                  return (
                    <Perlinedetails id={index+"perlineDetails"}>
                      <Coloredcircle id={index+"coloredCircle"} color={getDotColor(item.color)} />
                      <Detailtext id={index+"detailText1"} usedFor="value">{item.value}</Detailtext>
                      <Detailtext id={index+"detailText2"}>{item.description}</Detailtext>
                    </Perlinedetails>
                  );
                })}
              </DescriptionProgress>
            </VoCProgress>
          </ScoreGraphContainer>
            <HeaderContainer isTileWider={props.isTileWider} id="headerBox">
                {headers.map((colName, colIdx) => {
                  return (
                    <VocTableHeader 
                      id= "header_voc"
                      align="end"
                      alignment={colIdx}
                      isTileWider={false}
                      headerKey={colName}
                      widthKey={title}
                      onClick={() =>
                        sortItem(
                          getFieldFromKey(colName),
                          sortState,
                          sortedData
                        )
                      }
                    >
                      <Name>
                        {colName}{" "}
                        {renderSortIcon(colName) &&
                          renderSortIcons(
                            getFieldFromKey(colName),
                            sortState,
                            true
                          )}
                      </Name>
                    </VocTableHeader>
                  );
                })}
              </HeaderContainer>
              <MainContainer height={107} id="mainContainer">
                {sortedData.length>0? sortedData.map((itemData, index) => {
                  if (itemData.sites !== "Total") {
                    return (
                      <DataContainer key={index} alignIndex={index} id="dataContainer">
                        {headers.map((headerKey, headerIndex) =>
                          renderData(
                            headerKey,
                            itemData,
                            headerIndex,
                            title,
                            props.isTileWider
                          )
                        )}
                      </DataContainer>
                    );
                  }
                }): <NocircularData>no data available</NocircularData>}
              </MainContainer>
            
          
          
          {showPopup === title && (
            <ScorePopupContainer
              top={40}
              left={5}
              isTileWider={true}
              width={17}
             // arrowTop={-8}
              arrowLeft={88}
              id='popUpDesktop'
            >
              <span className="What-you-can-expect">
                <span
                  key={toolTipText}
                  dangerouslySetInnerHTML={createMarkup(toolTipText)}
                />
              </span>
            </ScorePopupContainer>
          )}
        </OldDataBlur>
        {/* {blur && (
          <OldDataFullview>
            <OldDataContent>
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext>This data has not been updated yet.</Oldtext>
              <Oldtext>
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </OldDataFullview>
        )} */}
         {blur && (
            <OldDataFullview id = "OldDataFullview">
            <OldDataContent id = "olddatacontent">
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </OldDataFullview>
          )}
      </Card>
    </Col>
  );
}

const mapStateToProps = (state) => {
    const { scorecardInfo, authInfo } = state;
    const { userData } = authInfo;
    const { excelLoader, kpiName } = scorecardInfo;
    return { excelLoader, kpiName, userData };
}

const mapDispatchToProps = {
    exportToExcel: (kpiName, headers, sortedData) => scorecardActions.getScorecardData(kpiName, headers, sortedData)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(VoiceOfCustomer));