
export function selectedobject(detailsList, filterData) {
  const filteredValue = Object.fromEntries(
    Object.entries(detailsList).filter(([key, value]) => key.search(filterData) === -1 ? false : true))
  return filteredValue
}

export function fetchListArray(detailsList, filterList) {
  const filteredValue = Object.fromEntries(
    Object.entries(detailsList).filter(([key, value]) => filterList.includes(key)))
  return filteredValue
}

export function fetchKeyFromObject(detailsList) {
  let months = []
  let days = []
  let weeks = []
  Object.keys(detailsList).forEach(function (key, index) {
    let monthObject = detailsList[key].ratingForPeriod.month || []
    let weekObject = detailsList[key].ratingForPeriod.week || []
    let dayObject = detailsList[key].ratingForPeriod.day || []
    if (monthObject.ratings) {
      let monthValue = fetchObject(key, detailsList[key].name, monthObject.ratings, monthObject.averageRating)
      months.push(monthValue)
    }
    if (weekObject.ratings) {
      let weekValue = fetchObject(key, detailsList[key].name, weekObject.ratings, weekObject.averageRating)
      weeks.push(weekValue)
    }
    if (dayObject.ratings) {
      let dayValue = fetchObject(key, detailsList[key].name, dayObject.ratings, dayObject.averageRating)
      days.push(dayValue)
    }
  })
  return {
    months: months,
    weeks: weeks,
    days: days
  }
}

function fetchObject(key, name, rating, averageRating) {
  return {
    sapId: key,
    siteName: name,
    ratings: rating,
    averageRating: averageRating
  }
}

function addType(value, type, name) {
  return {
    type: type,
    name: name,
    ...value
  }
}

function addTypes(lineArray, type, addMonth, addWeek, addDay, name, isSite) {
  const month = !isSite ? lineArray.month : lineArray[0].monthRatings;
  const week = !isSite ? lineArray.week : lineArray[0].weekRatings;
  const day = !isSite ? lineArray.day : lineArray[0].dayRatings;
  Object.keys(month || []).forEach(function (key, index) {
    addMonth.push(addType(month[key], type, name))
  })
  Object.keys(week || []).forEach(function (key, index) {
    addWeek.push(addType(week[key], type, name))
  })
  Object.keys(day || []).forEach(function (key, index) {
    addDay.push(addType(day[key], type, name))
  })
}

export function fetchArrayfromLine(lineData, filterQuery, tableData, filterObject = {}) {
  let addMonth = [];
  let addWeek = [];
  let addDay = [];
  const { ratings } = lineData;
  const { rating } = tableData;
  const { regionalManager, siteName, country } = filterObject;
  Object.keys(ratings).forEach((item, i) => {
    if (item === 'country') {
      addTypes(ratings[item], 'Country', addMonth, addWeek, addDay, country);
    } else if (item === 'regionalManagers') {
      addTypes(ratings[item], 'Regional Manager', addMonth, addWeek, addDay, regionalManager);
    } else if (item === 'channelOfTradeUnderRegionalManagers' || item === 'channelOfTrade') {
      if (Object.keys(ratings[item])[0] !== 'month') {
        Object.keys(ratings[item]).forEach(row =>
          addTypes(ratings[item][row], row, addMonth, addWeek, addDay, row)
        )
      } else {
        const channelOfTrade = filterQuery.split('&').find(item => item.includes('channel-of-trade')).split('=')[1];
        addTypes(ratings[item], channelOfTrade, addMonth, addWeek, addDay, channelOfTrade);
      }
    }
  })

  if (filterQuery.split('&').find(item => item.includes('site-id'))) {
    addTypes(rating, 'Site', addMonth, addWeek, addDay, siteName, true);
  }
  return {
    month: addMonth,
    week: addWeek,
    day: addDay
  }
}

function fetchSearchObject(key, filterList, tableReviewFilter) {
  let data = key[tableReviewFilter].filter(arrayValue => filterList.includes(parseInt(arrayValue.rating)))
  if (data.length > 0) {
    return data
  }
}

export function fetchListObjectMatch(detailsList, filterList, tableReviewFilter) {
  let filterObject = {}
  Object.keys(detailsList).forEach(function (key, index) {
    let filterKey = fetchSearchObject(detailsList, filterList, key)
    filterKey = { [key]: filterKey !== undefined ? filterKey : [] }
    filterObject = { ...filterObject, ...filterKey }
  })
  return filterObject
}

function searchMethod(key, searchText, filterText) {
  let review = key[filterText].filter(arrayValue => arrayValue.review.toLowerCase().search(searchText.toLowerCase()) !== -1);
  let response = key[filterText].filter(arrayValue => arrayValue?.comments?.length > 0 && arrayValue?.comments[0]?.data.toLowerCase().search(searchText.toLowerCase()) !== -1);
  if (review.length > 0 || response.length > 0) {
    if (review.length > 0 && response.length === 0) {
      return review
    } else {
      return response
    }
  }
}

export function searchMethodFromKey(detailsList, searchText, filterText) {
  let filterObject = {}
  let filterKey = {}
  if (!detailsList.siteName) {
    Object.keys(detailsList).forEach(function (key, index) {
      filterKey = searchMethod(detailsList, searchText, key)
      filterKey = { [key]: filterKey !== undefined ? filterKey : [] }
      filterObject = { ...filterObject, ...filterKey }
    })
  }
  return filterObject
}

export function searchTextFilter(searchText, searchList) {
  const filteredValue = Object.fromEntries(
    Object.entries(searchList).filter(([key, value]) => (key !== 'ALL' &&
      (value.name ?
        value.name.toLowerCase().includes(searchText.toLowerCase())
        :
        value.siteName.toLowerCase().includes(searchText.toLowerCase()))
    )))
  return filteredValue

}