/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 12/08/2022
 * Author : Sunil Vora
 */
import React, { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../actions/scorecardActions";
import { DOWNLOAD_SECTION_TILE_NAME, EVENT_DOWNLOAD_EXCEL, KEY_USER_ID, KEY_USER_TITLE, UNKNOWN_TITLE } from '../../constants/FireBaseEvents';
import { FIELD_WITH_PERCENT } from "../../constants/scorecard";
import isMobile from "../../helpers/isMobile";
import { isTablet } from "../../helpers/isTablet";
import i_collab from "../../images/icons/i_collab.svg";
import i_cross_white from "../../images/icons/i_cross_white.svg";
import i_download from "../../images/icons/i_download.svg";
import i_download_white from "../../images/icons/i_download_white.svg";
import i_icon from "../../images/icons/i_icon.svg";
import i_icon_alert from "../../images/icons/i_icon_alert.svg";
import i_icon_arrow_down_black from "../../images/icons/i_icon_arrow_down_black.svg";
import i_icon_arrow_down_white from "../../images/icons/i_icon_arrow_down_white.svg";
import i_icon_arrow_right_black from "../../images/icons/i_icon_arrow_right_black.svg";
import i_icon_arrow_up_black from "../../images/icons/i_icon_arrow_up_black.svg";
import i_icon_arrow_up_white from "../../images/icons/i_icon_arrow_up_white.svg";
import i_icon_dash from "../../images/icons/i_icon_dash.svg";
import i_icon_info_bold_white from "../../images/icons/i_icon_info_bold_white.svg";
import {
  DescriptionText1,
  DisFlex,
  IconView,
} from "../E-Maintenance/EMaintenance.styled";
import FinancialLineGraph from "../FinancialWidget/FinancialLineGraph";
import { IconImage } from "../GoogleReviewDashboard/ReviewsDashboard.styled";
import { ExcelSpin } from "../commonStyles/common.styled";
import {
  Actual,
  ActualText,
  AlertIcon,
  ColName,
  DataContainer,
  DataContainerMandTask,
  DataContainerUnPub,
  DataValue,
  DataValueNew,
  DataValueTotalShopSales,
  DataValueUnPub,
  Exclaimation,
  ExclaimationText,
  FilterBlur,
  HeaderContainerMandTask,
  HeaderContainerNew,
  MainContainer,
  MobilePopup,
  Name,
  OldDataApprove,
  OldDataBlur,
  OldDataContent,
  OldDataFullview,
  Oldtext,
  Percentage,
  ScoreDownloadIcon,
  ScoreExcelLabel,
  ScoreExportContainer,
  ScoreGraphContainer,
  ScoreHeader,
  ScoreKpiName,
  ScoreKpiNameNew,
  ScoreLegendContainer,
  ScorePopupContainer,
  ScoreTitle,
  TooltipValue,
  TotalContainer,
  TotalContainerWbc,
  WbcValue
} from "./ScoreCard.styled";
import {
  createMarkup,
  defaultSortOrder,
  eventLogging,
  getFieldFromKey,
  renderSortIcons,
  sortData,
} from "./utils";

function TileComponent(props) {
  const { t } = useTranslation();
  const {
    state,
    title,
    toolTipText,
    displayGraph,
    headers,
    data,
    graph,
    rank,
    total,
    lastWeekUpdated,
    expectedRefreshTimestamp,
  } = props.data;
  const headerNames = data?.length > 0 ? defaultSortOrder(data) : [];
  const [showPopup, setShowPopup] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortState, setSortState] = useState(headerNames);
  const [site, setSite] = useState("");
  const [rect, setRect] = useState(0);
  const [blur, setBlur] = useState(title === 'Waste' || title === 'Till Losses' ? props.weekNumber === 1 ? 52 !== lastWeekUpdated : props.weekNumber-1 !== lastWeekUpdated : props.weekNumber !== lastWeekUpdated);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  
  
  useEffect(() => {
    if (props.event) {
      setBlurRect(0);
      props.changeEvent();
      setShowPopup("");
    }
    setSortedData(data);
    window.addEventListener('scroll', controlDirection);
    return () => {
        window.removeEventListener('scroll', controlDirection);
    };
  }, [props.event, data,  props]);

  const controlDirection = (e) => {
    setOldPopUpVisible(false)
    setShowPopup("");
}
  const getBackgroundColor = (status) => {
    switch (status) {
      case "NEUTRAL":
        return "#ffffff";
      case "DANGER":
        return "#bb453a";
      case "OK":
        return "#007833";
      default:
        break;
    }
  };
  
  const handleHover = (event, name, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup("");
    } else {
      setShowPopup(name);
    }
  };
  
  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
      //props.changeScrollEvent();
      //props.changeEvent();
    } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };
  
  const handleSiteHover = (event, value, type) => {
    if (type === "leave") {
      setSite(value);
    }
    if (event.target.scrollHeight > event.target.clientHeight) {
      setSite(value);
      setRect(event.target.getBoundingClientRect());
    }
  };
  
  const getStatusIcon = (status) => {
    switch (status) {
      case "UP":
        return i_icon_arrow_up_white;
      case "DOWN":
        return i_icon_arrow_down_white;
      default:
        return null;
    }
  };
  
  const getSiteStatus = (sales) => {
    switch (sales) {
      case "UP":
        return i_icon_arrow_up_black;
      case "DOWN":
        return i_icon_arrow_down_black;
      case "LEVEL":
        return i_icon_dash;
      case "RIGHT":
        return i_icon_arrow_right_black;
      default:
        return null;
    }
  };
  
  const getGraphData = (scoreSales) => {
    let sales = [];
    scoreSales?.forEach((sale) => {
      if (sale?.plannedSales) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "PlannedSales", value: sale.plannedSales, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else if(props.siteNames.length === 0 &&  !isMobile()) {
        sales =  [...sales, { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
        { type: "ActualSalesUK", value: sale.avgCountry, ...sale }];
    } else if(isMobile() && props.siteNames.length === 0) {
      sales =  [...sales, { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
      { type: "ActualSalesUK", value: sale.avgCountry, ...sale }];
    }else{
        sales =  [...sales, { type: "ActualSales", value: sale.avgRegionalManager, ...sale }];
    }
    });
    return sales;
  };
  
  const renderPercent = (fieldName) => {
    return FIELD_WITH_PERCENT.find((val) => val === fieldName);
  };
  
  const paddingRight = (headerKey) => {
    if (title === "Waste") {
      return 4;
    }
    switch (headerKey) {
      case "Var LFL":
        return 10;
      case "Vs sales":
        return 33;
      case "% to total":
        return 15;
      case "Waste":
        return 16;
        case "Total Vol":
          return 10;
        case "Total Sales":
          return 30;
          case "Var to plan":
          return 12;
         
      default:
        return 0;
    }
  };
  
  const paddingLeft = (headerKey) => {
    if (headerKey === "Var to plan" && title === "Services vs Plan") {
      return 15;
    } else if (headerKey === "Var LFL" && title === "Services vs Plan") {
      return 0;
    }
    switch (headerKey) {
      case "Var LFL":
        return 12;
      case "Vs sales":
        return 33;
      case "% to total":
        return 44;
      case "Waste":
        return 12;
      case "Var to plan":
        return 26;
        case "Value loss":
        return 20;
        
      default:
        return 0;
    }
  };
  
  const align = (headerKey) => {
    switch (headerKey) {
      case "Sites":
        return "left";
        case "Var to plan":
        return "end";
          case "% to sales":
            return "center";
      default:
        return "end";
    }
  };
  
  const renderValueLoss = (dataValue) => {
    if(dataValue !== 'NA'){
      const isNegative = Math.sign(dataValue);
      if (isNegative === -1) {
        return `-£${dataValue.toString().split("-")[1]}`;
      }
      return `£${dataValue.toString()}`;
    }
    else{
      return `${dataValue.toString()}`
    }
    }
  
  
  
  const renderData = (headerKey, itemData, headerIndex, title) => {
    let fieldName = getFieldFromKey(headerKey);
    const { left, top } = rect;
    if (headerKey === "W + 1" || headerKey === "W + 2"|| headerKey === "Sites"  && (title === "Unpublished schedules" )) {
      return (
        <DataValueUnPub 
          alignment={headerIndex} widthKey={title}
          id ={title+headerIndex+itemData[fieldName]}
          >
          {headerKey === "Sites"? (`${itemData[fieldName]?.toLocaleString("en-GB")}`): itemData[fieldName] &&  <div style={{marginRight: headerIndex === 1?30:0}}><AlertIcon src={i_icon_alert} alt="alert" /></div>}
        </DataValueUnPub>
      );
    } else if (headerKey === "Total Vol" && title === "Fuel Volume vs Plan") {
        if(itemData[fieldName]==='NA'){
          return (
            <DataValue
              id ={title+headerIndex+itemData[fieldName]}
              isTileWider={props.isTileWider}
              flex={0.5}
              align="end"
              alignment={headerIndex}
              headerKey={headerKey}
              widthKey={title}
            >{`${itemData[fieldName]?.toLocaleString("en-GB")}`}</DataValue>
          );
        }
        else{
          return (
            <DataValue
            id ={title+headerIndex+itemData[fieldName]}
              isTileWider={props.isTileWider}
              flex={0.5}
              align="end"
              alignment={headerIndex}
              headerKey={headerKey}
              widthKey={title}
            >{`${itemData[fieldName]?.toLocaleString("en-GB")}L`}</DataValue>
          );
        }
      
    } 
    else if (headerKey === "Waste") {
      if(itemData[fieldName] === 'NA'){
        return (
          <DataValue
            id ={title+headerIndex+itemData[fieldName]}
            paddingRight={paddingRight(headerKey)}
            isTileWider={props.isTileWider}
            flex={0.5}
            align="center"
            alignment={headerIndex}
            headerKey={headerKey}
            widthKey={title}
          >{`${itemData[fieldName]?.toLocaleString("en-GB")}`}</DataValue>
        );
      }
      else{
      return (
        <DataValue
          id ={title+headerIndex+itemData[fieldName]}
          paddingRight={paddingRight(headerKey)}
          isTileWider={props.isTileWider}
          flex={0.5}
          align="center"
          alignment={headerIndex}
          headerKey={headerKey}
          widthKey={title}
        >{`£${itemData[fieldName]?.toLocaleString("en-GB")}`}</DataValue>
      );
      }
    } else if (headerKey === "Total Sales") {
      if(itemData[fieldName] === 'NA'){
        return (
          <DataValue
            id ={title+headerIndex+itemData[fieldName]}
            isTileWider={props.isTileWider}
            flex={0.5}
            align="center"
            alignment={headerIndex}
            headerKey={headerKey}
            widthKey={title}
          >{`${itemData[fieldName]?.toLocaleString("en-GB")}`}</DataValue>
        );
      }
      else{
      return (
        <DataValue
          id ={title+headerIndex+itemData[fieldName]}
          isTileWider={props.isTileWider}
          flex={0.5}
          align="center"
          alignment={headerIndex}
          headerKey={headerKey}
          widthKey={title}
        >{`£${itemData[fieldName]?.toLocaleString("en-GB")}`}</DataValue>
      );
      }
    }

    else if (headerKey === "Value loss") {
      return (
        <DataValue
          paddingLeft={paddingLeft(headerKey)}
          isTileWider={props.isTileWider}
          flex={0.5}
          align="center"
          alignment={headerIndex}
          headerKey={headerKey}
          widthKey={title}
          id ={title+headerIndex+itemData[fieldName]}
        >
          {renderValueLoss(itemData[fieldName])}
        </DataValue>
      );
    }
    return !renderPercent(fieldName) ? (
      <DataValue
        onMouseOver={(e) => handleSiteHover(e, itemData[fieldName], "enter")}
        onMouseOut={(e) => handleSiteHover(e, "", "leave")}
        align={align(headerKey)}
        paddingRight={paddingRight(headerKey)}
        flex={0.5}
        headerKey={headerKey}
        isTileWider={props.isTileWider}
        alignment={headerIndex}
        widthKey={title}
        id ={title+headerIndex+itemData[fieldName]}
      >
        {itemData[fieldName]}
        {site === itemData[fieldName] && (
          <TooltipValue  id = "tooltipvalue" top={top} left={left}>
            {itemData[fieldName]}
          </TooltipValue>
        )}
      </DataValue>
    ) : (
      <DataValue
        flex={0.5}
        paddingRight={(headerKey === "Var to plan" && title === "Waste")?0:paddingRight(headerKey)}
        align={(headerKey === "Var to plan" && title === "Waste")?"center": align(headerKey)}
        alignment={headerIndex}
        isTileWider={props.isTileWider}
        headerKey={headerKey}
        widthKey={title}
        id ={title+headerIndex+itemData[fieldName]}
      >
        {itemData[fieldName] === "NA"
          ? itemData[fieldName]
          : `${itemData[fieldName]}%`}
      </DataValue>
    );
  };


  const paddingRightTotal = (headerKey) => {
    if(headerKey === "Var to plan" && (title === "Fuel Volume vs Plan" || title === "Services vs Plan") ){
      return 5
    }else if(headerKey === "% to sales"){
      return 20
    }else if(headerKey === "% to total"){
      return 0
    }else if(headerKey === "Var LFL" && title === "Shop Sales vs Plan"){
      return 0
    }
    else if(headerKey === "Var LFL" && title === "Fuel Volume vs Plan"){
      return 6
    }
    else if(headerKey === "Var LFL" && title === "Services vs Plan"){
      return 3
    }
    else if(headerKey === "Var to plan" && (title === "Shop Sales vs Plan")){
        return (isMobile() || isTablet())?0:3
    }
    
    else if(headerKey === "Var to plan" && (title === "Waste")){
      return 3
    }
    else {
      return 15
    }
  };


  const paddingLeftTotal = (headerKey) => {
    if(headerKey === "% to total"){
     return (isMobile() || isTablet())?40:40
    }else if(headerKey === "Var to plan" && title === "Services vs Plan"){
    return (isMobile() || isTablet())?10:0
    }
    else if(headerKey === "Total Sales" && title === "Services vs Plan"){
      return (isMobile() || isTablet())?15:0
      }
    else if(headerKey === "Var to plan" && (title === "Shop Sales vs Plan")){
        return (isMobile() || isTablet())?20:3
    }
    else {
      return 0
    }
  };

  const renderTotal = (headerKey) => {
    let fieldName;
    const lastWeekData = graph?.length && graph[graph?.length - 1];
    switch (headerKey) {
      case "Total Sales":
        fieldName = "totalRegionalManager";
        break;
      case "Var to plan":
        fieldName = "avgRegionalManager";
        break;
      case "Var LFL":
        fieldName = "varLFL";
        break;
      case "Total Vol":
        fieldName = "totalRegionalManager";
        break;
      case "Waste":
        fieldName = "totalRegionalManager";
        break;
      case "% to sales":
        fieldName = "percSales";
        break;
      case "Value loss":
        fieldName = "totalRegionalManager";
        break;
      case "% to total":
        fieldName = "avgRegionalManager";
        break;
      default:
        break;
    }


     if(title === "WBC Sales vs Plan"){
        if (headerKey === "Sites") {
          return (
            <WbcValue
              id ={fieldName+"_"+headerKey}
              itemAlign= {'left'}
              flex={0.18}
              paddingLeft= {'10px'}
            >
              {"Total"}
            </WbcValue>
          );
          
        }else if(headerKey === 'Total Sales'){
          return (
            <WbcValue
              id ={fieldName+"_"+headerKey}
              itemAlign= {'center'}
              flex={0.25}
            >
              {`£${lastWeekData[fieldName]?.toLocaleString("en-GB")}`}
            </WbcValue>
          );
        }else {
          return (
            <WbcValue
              id ={fieldName+"_"+headerKey}
              itemAlign= {'center'}
              flex={0.25}
            >
              {`${lastWeekData[fieldName]}%`}
            </WbcValue>
          );
        }
          
       
     }

    if (headerKey === "Sites") {
      return (
        <DataValue
          id ={fieldName+"_"+headerKey}
          paddingLeft={title === "Till Losses" ?(isMobile() || isTablet())?20: 10 : 10}
          flex={0.6}
          // align={(title === "Till Losses" || title ===  "Services vs Plan" || title == "Shop Sales vs Plan") ? "left" : "center"}
          align = {"left"}
          isTileWider={props.isTileWider}
          headerKey={headerKey}
        >
          {"Total"}
        </DataValue>
      );
    } 
    else if (headerKey === "Total Vol") {
      return (
        <DataValue
          id ={fieldName+"_"+headerKey}
          paddingLeft={0}
          paddingRight={paddingRight(headerKey)}
          flex={0.6}
          align="center"
          isTileWider={props.isTileWider}
          headerKey={headerKey}
        >{`${lastWeekData[fieldName]?.toLocaleString("en-GB")}L`}</DataValue>
      );
    } else if (
      (headerKey === "Total Sales" && title !== "Fuel Volume vs Plan")
    ) {
      return (
        <DataValue
          id ={fieldName+"_"+headerKey}
          paddingRight={0}
          paddingLeft = {paddingLeftTotal(headerKey)}
          flex={0.5}
          align="left"
          isTileWider={props.isTileWider}
          headerKey={headerKey}
        >{`£${lastWeekData[fieldName]?.toLocaleString("en-GB")}`}</DataValue>
      );
      
    } 
    else if (headerKey === "Value loss") {
      return (
        <DataValue
          id ={fieldName+"_"+headerKey}
          paddingLeft={0}
          paddingRight={(isMobile() || isTablet())?10:10}
          flex={0.6}
          align="center"
          isTileWider={props.isTileWider}
          headerKey={headerKey}
        >
          {renderValueLoss(lastWeekData[fieldName])}
        </DataValue>
      );
    } 
    else if (!isMobile() && headerKey === "Var to plan" && title === "Waste" && props.siteNames.length !== 0 )  {
      return (
        <FilterBlur>
        <DataValue
          id ={fieldName+"_"+headerKey}
          paddingLeft={0}
          flex={0.6}
          paddingRight={paddingRight(headerKey)}
          align="center"
          isTileWider={props.isTileWider}
          headerKey={headerKey}
        >{`£${lastWeekData[fieldName]?.toLocaleString("en-GB")}`}</DataValue></FilterBlur>
      );
    } 
    else if (isMobile() && headerKey === "Var to plan" && title === "Waste" && props.siteNames.length !== 0 )  {
      return (
        <FilterBlur>
        <DataValue
          id ={fieldName+"_"+headerKey}
          paddingLeft={0}
          flex={0.6}
          paddingRight={paddingRight(headerKey)}
          align="center"
          isTileWider={props.isTileWider}
          headerKey={headerKey}
        >{`£${lastWeekData[fieldName]?.toLocaleString("en-GB")}`}</DataValue></FilterBlur>
      );
    } 
    
    else if (headerKey === "Waste") {
      return (
        <DataValue
           id ={fieldName+"_"+headerKey}
          paddingLeft={0}
          flex={0.6}
          paddingRight={paddingRight(headerKey)}
          align="center"
          isTileWider={props.isTileWider}
          headerKey={headerKey}
        >{`£${lastWeekData[fieldName]?.toLocaleString("en-GB")}`}</DataValue>
      );
    }
    else {
      return (
        ((headerKey === "Var LFL" || headerKey === "Var to plan") && title === "Shop Sales vs Plan" && isMobile())?
        <DataValueTotalShopSales
        flex={headerKey === "Var to plan"?0.6:0.4}
        paddingLeft = {headerKey === "Var to plan"?0:18}
        paddingRight = {headerKey === "Var LFL"?10:0}
        align={"center"}
        isTileWider={props.isTileWider}
        headerKey={headerKey}
        id ={fieldName+"_"+headerKey}
      >{(lastWeekData[fieldName] !=="NA" && lastWeekData[fieldName] !== 0 && lastWeekData[fieldName] !== null)? `${lastWeekData[fieldName]}%`: lastWeekData[fieldName] ===0? lastWeekData[fieldName]:"NA"}</DataValueTotalShopSales>
        :<DataValue
          id ={fieldName+"_"+headerKey}
          paddingRight={paddingRightTotal(headerKey)}
          paddingLeft = {paddingLeftTotal(headerKey)}
          flex={0.6}
          align={"center"}
          isTileWider={props.isTileWider}
          headerKey={headerKey}
        >{ (lastWeekData[fieldName] !=="NA" && lastWeekData[fieldName] !== 0 && lastWeekData[fieldName] !== null) ?`${lastWeekData[fieldName]}%`: lastWeekData[fieldName] === 0? lastWeekData[fieldName]:"NA"}</DataValue>
      );
    }
  };
  
  const renderTotalSmall = (headerKey) => {
    const itemd1 = data.filter((itemData) => itemData.sites === "Total");
    if (title === "Mand. Tasks" && itemd1.length > 0) {
      if (headerKey === "Sites") {
        return (
          <DataValueNew
          id ={"_"+headerKey}
            //paddingLeft={10}
            flex={0.5}
            align={"center"}
            isTileWider={true}
            headerKey={headerKey}
          >
            {itemd1[0]["sites"]}
          </DataValueNew>
        );
      } else if (headerKey === "Completed") {
        return (
          <DataValue
            paddingLeft={(isMobile() || isTablet())?0:0}
            paddingRight={(isMobile() || isTablet())?5:0}
            flex={0.5}
            align={"center"}
            isTileWider={true}
            headerKey={headerKey}
          >
            {`${itemd1[0]["completed"]}%`}
          </DataValue>
        );
      }
    }
    else if(title === 'Clocking on time'){
      if (headerKey === "Sites") {
        return (
          <DataValueNew
            //paddingLeft={10}
            id ={title+"_"+headerKey}
            flex={0.5}
            align={"center"}
            isTileWider={true}
            headerKey={headerKey}
          >
            {itemd1[0]["sites"]}
          </DataValueNew>
        );
      } else if (headerKey === "Compliance") {
        return (
          <DataValue
            id ={title+"_"+headerKey}
            paddingLeft={(isMobile() || isTablet())?0:15}
            paddingRight={(isMobile() || isTablet())?5:0}
            flex={0.5}
            align={"center"}
            isTileWider={true}
            headerKey={headerKey}
          >
            {`${itemd1[0]["compliance"]}%`}
          </DataValue>
        );
      }
    }
  };
  
  const renderClassName = (isTileWider) => {
    if (isTileWider && title !== "Till Losses" && !isTablet()) {
      return "scorecard-column-wide";
    } else if ((isMobile() || isTablet()) && isTileWider) {
      return "scorecard-column-wide-mobile";
    } else if (isMobile() && isTileWider === undefined) {
      if (title === "Dark Sites") {
        return "dark-site-mobile";
      } else if (title === "Clocking on time") {
        return "clocking-on-time-mobile";
      } else if (title === "Unpublished schedules") {
        return "unpublished-schedules-mobile";
      } else {
        return "Mand-task-mobile";
      }
    } else if (isTablet() && isTileWider === undefined) {
      if (title === "Unpublished schedules") {
        return "unpublished-schedules";
      }
      return "dark-sites-tablet";
    } else {
      switch (title) {
        case "Clocking on time":
          return "clocking-on-time";
        case "Unpublished schedules":
          return "unpublished-schedules";
        case "Mand. Tasks":
          return "Mand-task-desktop";
        case "Till Losses":
          return "losses";
        case "Dark Sites":
          return "dark-sites";
        default:
          return "scorecard-column";
      }
    }
  };
  
  const renderSortIcon = (colName) => {
    if (colName === "W + 1" || colName === "W + 2") {
      return false;
    }
    return true;
  };
  
  const getMarginLeft = (colName) => {
    let marginLeft = 0;
    if (!isTablet() && title === "Till Losses" && colName === "Value loss") {
      marginLeft = 2;
    } else if (
      !isTablet() &&
      title === "Till Losses" &&
      colName === "% to total"
    ) {
      marginLeft = 2;
    } else if (isTablet() && title === "Waste" && colName === "Waste") {
      marginLeft = 3;
    } else if (
      isTablet() &&
      title === "Till Losses" &&
      colName === "Value loss"
    ) {
      marginLeft = 3;
    } else if (
      isTablet() &&
      title === "Till Losses" &&
      colName === "% to total"
    ) {
      marginLeft = 3;
    } else if (
      isTablet() &&
      title === "Fuel Volume vs Plan" &&
      colName === "Total Vol"
    ) {
      marginLeft = 3;
    } else if (
      isTablet() &&
      title === "Services vs Plan" &&
      colName === "Total Sales"
    ) {
      marginLeft = 2;
    } else if (!isTablet() && title === "Waste" && colName === "Waste") {
      marginLeft = 3;
    } else if (
      !isTablet() &&
      title === "Fuel Volume vs Plan" &&
      colName === "Total Vol"
    ) {
      marginLeft = 3;
    } else if (
      !isTablet() &&
      title === "Shop Sales vs Plan" &&
      colName === "Total Sales"
    ) {
      marginLeft = 1.5;
    } else if (
      isMobile() &&
      title === "Shop Sales vs Plan" &&
      colName === "Total Sales"
    ) {
      marginLeft = 1.8;
    } else if (
      !isTablet() &&
      title === "Services vs Plan" &&
      colName === "Total Sales"
    ) {
      marginLeft = 2;
    }
    else if (
      !isTablet() &&
      title === "Mand. Tasks" &&
      colName === "Completed"
    ) {
      marginLeft = 1.5;
    }
    else if (
      !isTablet() &&
      title === "Unpublished schedules" &&
      (colName === "W + 1" || colName === "W + 2")
    ) {
      marginLeft = 1.5;
    }
    return marginLeft;
  };
  
  const getMargin = (colName) => {
    const windowWidth = window.innerWidth;
    let marginRight = 40;
    if (title === "Mand. Tasks" && colName === "Sites" && windowWidth >= 1400) {
      marginRight = 91;
    } else if (title === "Mand. Tasks" && colName === "Sites" && isTablet()) {
      marginRight = 51;
    } else if (
      title === "Clocking on time" &&
      colName === "Sites" &&
      isMobile()
    ) {
      marginRight = 43;
    } else if (
      title === "Clocking on time" &&
      colName === "Sites" &&
      windowWidth >= 1400
    ) {
      marginRight = 138;
    } else if (
      title === "Unpublished schedules" &&
      colName === "Sites" &&
      windowWidth >= 1400
    ) {
      marginRight = 100;
    } else if (title === "Clocking on time" && colName === "Sites") {
      marginRight = 115;
    } else if (
      title === "Unpublished schedules" &&
      colName === "Sites" &&
      isMobile()
    ) {
      marginRight = 28;
    } else if (
      title === "Unpublished schedules" &&
      colName === "W + 1" &&
      isMobile()
    ) {
      marginRight = 15;
    } else if (
      title === "Unpublished schedules" &&
      colName === "W + 2" &&
      isMobile()
    ) {
      marginRight = 0;
    } else if (title === "Unpublished schedules" && colName === "Sites") {
      marginRight = 75;
    } else if (title === "Unpublished schedules" && colName === "W + 2") {
      marginRight = 11;
    } else if (title === "Unpublished schedules" && colName === "W + 1") {
      marginRight = 45;
    } else if (title === "Mand. Tasks" && colName === "Sites") {
      marginRight = 66;
    } else if (
      title === "Mand. Tasks" &&
      colName === "Completed" &&
      windowWidth <= 400
    ) {
      marginRight = 95;
    }
    return marginRight;
  };
  
  const width = (title) => {
    if (isTablet()) {
      switch (title) {
        case "Mand. Tasks":
          return 12;
        case "Dark Sites":
          return 10;
        case "Clocking on time":
            return 10;
            case "Unpublished schedules":
            return 10;
        // case "Waste":
        //       return 17;
        default:
          return 18;
      }
    } else {
      switch (title) {
        case "Mand. Tasks":
          return 20;
        case "Dark Sites":
          return 12;
        case "Safety Conv.":
          return 12;
        default:
          return 18;
      }
    }
  };
 
  const getMarginIcon = (title) => {
    switch (title) {
      case "Mand. Tasks":
        return 5;
      case "Dark Sites":
        return 5;
      case "Clocking on time":
        return 5;
      case "Unpublished schedules":
        return 2;
      default:
        return 15;
    }
  };
  
  const sortItem = (keyName, sortState, sortedData) => {
    let result;
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    const rowsWithNA = data.filter((rows) => rows[keyName] === "NA");
    if (isAnyColumnSorted === "NONE") {
      const initialSortState = Object.keys(data[0]).map((item) => ({
        name: item,
        sortState: "NONE",
      }));
      result = sortData(
        keyName,
        initialSortState,
        data.filter((rows) => rows[keyName] !== "NA")
      );
    } else {
      result = sortData(
        keyName,
        sortState,
        sortedData.filter((rows) => rows[keyName] !== "NA")
      );
    }
    const currentState = result.sortedState.find((val) => val.name === keyName);
    setSortState(result.sortedState);
    setSortedData(
      currentState.sortState === "ASC"
        ? [...result.sortingData, ...rowsWithNA]
        : [...rowsWithNA, ...result.sortingData]
    );
  };
  
  const exportToExcel = () => {
    const kpiName = title.split(" ")[0];
    props.exportToExcel(kpiName, headers, sortedData);
    // log download event
    let downloadEventData = {
    [KEY_USER_ID]: props?.userData?.id,
    [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
    [DOWNLOAD_SECTION_TILE_NAME]:title
    }
    eventLogging(EVENT_DOWNLOAD_EXCEL, downloadEventData)
  };
  
  const blackArrow = (title) => {
    if (isMobile()) {
      switch (title) {
        case "Mand. Tasks":
          return 83;
        case "Dark Sites":
          return -75;
        case "Services vs Plan":
          return 85;
       
        case "Clocking on time":
          return 80;
        case "Unpublished schedules":
          return 85;
       
        default:
          return 85;
      }
    } else {
      switch (title) {
        case "Till Losses":
          return 85;
        case "Mand. Tasks":
          return 88;
        case "Dark Sites":
          return 73;
        default:
          return 80;
      }
    }
  };
  
  const getLeft = (title) => {
    if (isTablet()) {
      switch (title) {
        case "Dark Sites":
          return 230;
        // case "Till Losses":
        //   return 296;
        case "Mand. Tasks":
          return 200;
        case "Clocking on time":
          return 220;
        case "Unpublished schedules":
          return 220;
        
        default:
          return 260;
      }
    } else {
      switch (title) {
        case "Mand. Tasks":
          return -5;
        case "Dark Sites":
          return -20;
        case "Till Losses":
          return -8;
          case "Services vs Plan":
            return -5;
            case "Unpublished schedules":
              return 5;
        case 'WBC Sales vs Plan':
           return -5
        default:
          return -20;
      }
    }
  };
  
  const getTop = (title) => {
    if (isTablet()) {
      switch (title) {
       
        case "Clocking on time":
          return 60;
        case "Unpublished schedules":
          return 60;
        default:
          return 70;
      }
    } else {
      switch (title) {
        case "Dark Sites":
          return 45;
        case "Mand. Tasks":
          return 45;
        default:
          return 40;
      }
    }
  };
  
  const arrowTopSelector = (title) => {
    if (isTablet()) {
      switch (title) {
        case "Till Losses":
          return -10;
        case "Dark Sites":
          return -19;
        case "Clocking on time":
          return -10;
        case "Unpublished schedules":
          return -12;
        case "Waste":
          return -7;
         default:
          return 0;
      }
    } else {
      switch (title) {
        case "Dark Sites":
          return -8;
        case "Unpublished schedules":
          return -12;
        case "Mand. Tasks":
          return -10;
        case "Till Losses":
          return -13;
        case "Clocking on time":
          return -13;
        case "Waste":
          return -7;
        default:
          return -8;
      }
    }
  };
  
  const getHeight = (title) => {
    if (props.isTileWider) {
      return 100;
    } else if (title === "Mand. Tasks") {
      return 280;
    } else if (title === "Clocking on time") {
      return 280;
    }
    else {
      return 305;
    }
  };
  
  return (
    <Col id = "colMain" className={renderClassName(props.isTileWider)}>
      <Card
         id = {props.compId+"_"+props.selectedManagerName+"_"+title}
        className="data-card"
        style={
          props.isTileWider
            ? { height: "375px",// boxShadow: "none", 
               marginTop: "0.7em" }
            : { height: "375px", //boxShadow: "none" 
              }
        }
      >
        <OldDataBlur boolTrue={blur} id= "OldDataBlur">
         {title === 'Waste' || title === 'Till Losses' ? <>
         {
          props.weekNumber - 1 === 0 ?
          <>
          {!blur && 52 !== lastWeekUpdated && (
            <Exclaimation
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
              id="exclamationContainer"
            >
              <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
          </>
          :
          <>
          {!blur && props.weekNumber-1 !== lastWeekUpdated && (
            <Exclaimation
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
              id="exclamationContainer"
            >
              <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
          </>
         }
         </>:<>
         {!blur && props.weekNumber !== lastWeekUpdated && (
            <Exclaimation
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
              id="exclamationContainer"
            >
              <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
          </>}
          {oldPopUpVisible && (
            <TooltipValue
            id = "tooltipvalue"
              top = {blurRect.top + 70}
              left = {blurRect.left + 15}
            >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
          )}
          <ScoreHeader
             id= {"header"+title}
            title={title}
            isMobile={isMobile()}
            color={getBackgroundColor(state)}
          >
            <ScoreTitle value="card" id="card">
              {/* {props.isTileWider && 
                            <ScoreStatusContainer>
                                <ScoreStatus src={getStatusIcon(arrow)} />
                            </ScoreStatusContainer>
                        } */}
             { (title === "Unpublished schedules" || title === "Clocking on time")? 
             <ScoreKpiNameNew id = "ScoreKpiName" textColor={state === "NEUTRAL" ? "black" : "white"}>
             {title}
           </ScoreKpiNameNew>
             :<ScoreKpiName id = "ScoreKpiName" textColor={state === "NEUTRAL" ? "black" : "white"}>
                {title}
              </ScoreKpiName>}
              {sortedData?.length > 0 && !isMobile() && !isTablet() && (
                <ScoreExportContainer
                  id = "ScoreExportContainer"
                  isRegionalManager={true}
                  onClick={() => exportToExcel(title)}
                  mobile={isMobile()}
                >
                  <ScoreExcelLabel id = "ScoreExcelLabel" isNeutral={state === "NEUTRAL"}>
                    Excel
                  </ScoreExcelLabel>
                  <ScoreDownloadIcon id = "ScoreDownloadIcon" marginRight={getMarginIcon(title)}>
                    {props.excelLoader &&
                    props.kpiName === title.split(" ")[0] ? (
                      <ExcelSpin
                        color={state === "NEUTRAL" ? "#007833" : "white"}
                      />
                    ) : (
                      <IconImage
                       id = "IconImageExcel"
                        src={
                          state === "NEUTRAL" ? i_download : i_download_white
                        }
                        alt="scorecard_excel"
                      />
                    )}
                  </ScoreDownloadIcon>
                </ScoreExportContainer>
              )}
              {rank && total && (
                <Percentage  id = "percentage" textColor={state === "NEUTRAL" ? "black" : "white"}>
                  {rank}/{total}
                </Percentage>
              )}
              {!isTablet() ? (
                <div
                  id = "right-card-view-div"
                  className="right-card-view"
                  onMouseOver={(e) => handleHover(e, title, "enter")}
                  onMouseOut={(e) => handleHover(e, "", "leave")}
                >
                  {
                    <img
                    id = "imgCross"
                      className="cross-class"
                      alt="tile_info"
                      src={
                        state === "NEUTRAL" ? i_icon : i_icon_info_bold_white
                      }
                    />
                  }
                </div>
              ) : (
                <div
                id = "right-card-view"
                  className="right-card-view-div"
                  onMouseOut={(e) => handleHover(e, "", "leave")}
                  onClick={(e) => {
                    showPopup === title
                      ? handleHover(e, "", "leave")
                      : handleHover(e, title, "enter");
                  }}
                >
                  {
                    <img
                      id = "img-card-view"
                      className="cross-class"
                      alt="tile_info"
                      src={
                        showPopup === title
                          ? i_cross_white
                          : i_icon_info_bold_white
                      }
                    />
                  }
                </div>
              )}
            </ScoreTitle>
          </ScoreHeader>
          {displayGraph && !props.showLoader && (
            <>
              <ScoreGraphContainer id = "ScoreGraphContainer">
                <ScoreLegendContainer id = "ScoreLegendContainer">
                 {!isMobile() && props.siteNames.length === 0 &&  <> <Actual></Actual>
                 <ActualText id= "actual-text" isLosses={title === "Till Losses"}>
                    actual UK
                  </ActualText> </> }
                  {isMobile() && props.siteNames.length=== 0 &&  <> <Actual></Actual>
                 <ActualText id= "actual-text" isLosses={title === "Till Losses"}>
                    actual UK
                  </ActualText> </> }
                  <Actual
                    id= "actual-text"
                    isDanger={state === "DANGER"}
                    isActualMe={true}
                  ></Actual>
                 {!isMobile() && <ActualText id= "actual-text" isTill Losses={title === "Till Losses"}>
                    {props.siteNames.length === 0 ? props.selectedManagerName : props.siteNames.length === 1? props.siteNames[0] :t("Selected Sites")}
                  </ActualText>}
                  {isMobile() && <ActualText id= "actual-text" isTill Losses={title === "Till Losses"}>
                    {props.siteNames.length === 0 ? props.selectedManagerName : props.siteNames.length === 1? props.siteNames[0] :t("Selected Sites")}
                  </ActualText>}
                </ScoreLegendContainer>
                <FinancialLineGraph
                  regionalManager={props.selectedManagerName}
                  isDanger={state === "DANGER"}
                  scoreGraph={true}
                  xScale={235}
                  graphId={`scoreCard-${title.replace(/[^a-zA-Z]/g, "")}`}
                  graphClass={`scoreGraph-${title.replace(/[^a-zA-Z]/g, "")}`}
                  sales={getGraphData(graph)}
                  title={title}
                  selection = {props.noSelected}
                  siteLength= {props.siteNames.length}
                  device ={isMobile()}
                  id = "Financial_graph"
                  scroll = {props.event}
                />
              </ScoreGraphContainer>
            </>
          )}
          {data?.length > 0 ? (
            <>
              { (title !== "Mand. Tasks" && title !== "Clocking on time" && title !== "Unpublished schedules")
                ?<HeaderContainerNew id = "headerContainer" isTileWider={props.isTileWider}>
                {headers.map((colName, colIdx) => {
                  return (
                    <ColName
                    id = "colName"
                     marginRight={getMargin(colName)}
                     marginLeft={getMarginLeft(colName)}
                      colName={colName}
                      fontSize = {(colName == "W + 1" || colName == "W + 2")? "12px":"12px"}
                      flex = {colName == "Sites"?0.6:0.5}
                      isTileWider={props.isTileWider}
                      onClick={() =>
                        sortItem(
                          getFieldFromKey(colName),
                          sortState,
                          sortedData
                        )
                      }
                      alignIndex={colIdx}
                    >
                      <Name id="name">{colName}</Name>
                      {renderSortIcon(colName) &&
                        renderSortIcons(
                          getFieldFromKey(colName),
                          sortState,
                          true
                        )}
                    </ColName>
                  );
                })}
              </HeaderContainerNew>:
              <HeaderContainerMandTask id = "headerContainer" paddingRight = {title !== "Clocking on time"?35:30} isTileWider={props.isTileWider}>
              {headers.map((colName, colIdx) => {
                return (
                  <ColName
                  // marginRight={getMargin(colName)}
                  //  marginLeft={getMarginLeft(colName)}
                    id = "colName"
                    colName={colName}
                    flex={0.5}
                    align="center"
                    isTileWider={props.isTileWider}
                    onClick={() =>
                      sortItem(
                        getFieldFromKey(colName),
                        sortState,
                        sortedData
                      )
                    }
                    alignIndex={colIdx}
                  >
                    <Name id="name">{colName}</Name>
                    {renderSortIcon(colName) &&
                      renderSortIcons(
                        getFieldFromKey(colName),
                        sortState,
                        true
                      )}
                  </ColName>
                );
              })}
            </HeaderContainerMandTask>
            }
              <MainContainer id = "mainContainer" height={getHeight(title)}>
                {sortedData.map((itemData, index) => {
                  if (itemData.sites !== "Total") {
                    return (
                      (title !== "Mand. Tasks" && title !== "Clocking on time" && title !== "Unpublished schedules" ) ?
                      <DataContainer key={index} alignIndex={index} id="dataContainer">
                        {/* {props.isTileWider && <SiteStatus src={getSiteStatus(itemData['arrow'])} />} */}
                        {headers.map((headerKey, headerIndex) =>
                          renderData(
                            headerKey,
                            itemData,
                            headerIndex,
                            title,
                            props.isTileWider
                          )
                        )}
                      </DataContainer>:
                      ( title === "Unpublished schedules"? 
                      <DataContainerUnPub key={index} alignIndex={index}>
                       {/* {props.isTileWider && <SiteStatus src={getSiteStatus(itemData['arrow'])} />} */}
                       {headers.map((headerKey, headerIndex) =>
                         renderData(
                           headerKey,
                           itemData,
                           headerIndex,
                           title,
                           props.isTileWider
                         )
                       )}
                     </DataContainerUnPub>
                      :<DataContainerMandTask key={index} alignIndex={index}>
                       {/* {props.isTileWider && <SiteStatus src={getSiteStatus(itemData['arrow'])} />} */}
                       {headers.map((headerKey, headerIndex) =>
                         renderData(
                           headerKey,
                           itemData,
                           headerIndex,
                           title,
                           props.isTileWider
                         )
                       )}
                     </DataContainerMandTask>)
                          
                    );
                  }
                })}
              </MainContainer>
              {props.isTileWider ? (
                title == "WBC Sales vs Plan"? 
                (<TotalContainerWbc id="totalContainer">
                    {headers.map((headerKey) => renderTotal(headerKey))}
                </TotalContainerWbc>)
                :(<TotalContainer id="totalContainer">
                  {headers.map((headerKey) => renderTotal(headerKey))}
                </TotalContainer>)
              ) : (
                (title !== "Dark Sites"  && title !== "Unpublished schedules" ) && (
                  <TotalContainer id="totalContainer">
                    {headers.map((headerKey) => renderTotalSmall(headerKey))}
                  </TotalContainer>
                )
              )}
            </>
          ) : (
            <>
              <DisFlex>
                <IconView id="iconView">
                  <img src={i_collab} alt="collab" />
                </IconView>
                &nbsp;
                <DescriptionText1 task="open" id="descriptionText1">
                  {<b>{t("WOW")}</b>}, {`no ${title} within your sites`}
                </DescriptionText1>
              </DisFlex>
            </>
          )}
          {isTablet()
            ? showPopup === title && (
                <MobilePopup
                  top={getTop(title)}
                  left={getLeft(title)}
                  width={width(title)}
                  arrowLeft={blackArrow(title)}
                 // arrowTop={arrowTopSelector(title)}
                 id='popUpMobile'
                >
                  <span className="What-you-can-expect">
                    <span
                      key={toolTipText}
                      dangerouslySetInnerHTML={createMarkup(toolTipText)}
                    />
                  </span>
                </MobilePopup>
              )
            : showPopup === title && (
                <ScorePopupContainer
                  top={getTop(title)}
                  left={getLeft(title)}
                  isTileWider={props.isTileWider}
                  width={width(title)}
                 // arrowTop={arrowTopSelector(title)}
                  arrowLeft={blackArrow(title)}
                  id='popUpDesktop'
                >
                  <span className="What-you-can-expect">
                    <span
                      key={toolTipText}
                      dangerouslySetInnerHTML={createMarkup(toolTipText)}
                    />
                  </span>
                </ScorePopupContainer>
              )}
        </OldDataBlur >
        {blur && (
          <OldDataFullview id = "OldDataFullview">
            <OldDataContent id = "olddatacontent">
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </OldDataFullview>
        )}
      </Card>
    </Col>
  );
}
const mapStateToProps = (state) => {
  const { scorecardInfo, loader, authInfo } = state;
  const { userData } = authInfo;
  const { showLoader } = loader;
  const { excelLoader, kpiName, siteNames, noSelected, selectedManagerName } = scorecardInfo;
  return { excelLoader, kpiName, showLoader, siteNames, noSelected,selectedManagerName, userData  };
};
const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
    scorecardActions.getScorecardData(kpiName, headers, sortedData),
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(TileComponent)
);
