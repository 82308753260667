/* eslint-disable no-loop-func */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { countryCoordinates, MAPS_KEY } from './siteInformation';

import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript
} from "@react-google-maps/api";

function Map(props) {

  const [libraries] = React.useState(['places']);


  const mapContainerStyle = {
    width: "100%",
    height: "209px",
  };

  const center = countryCoordinates[props.userData.countryCode];
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: MAPS_KEY,
    libraries,
  });

  const [selected, setSelected] = React.useState(null);

  const options = {
    //  styles: mapStyles,
    disableDefaultUI: true
  };

  if (loadError) return "Error loading Google Maps";
  if (!isLoaded) return "Loading Google Maps";

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={5}
      center={center}
      options={options}
      // onClick={onMapClick}
      onLoad={onMapLoad}
    >
      {props.sites.map((marker) => (
        <Marker
          key={marker.siteId}
          position={{ lat: marker.location.lat, lng: marker.location.lng }}
          icon={{
            path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillColor: marker.fuelSalesRank < 5 ? "#00FF00" : "#FF0",
            fillOpacity: .6,
            strokeWeight: 1,
            scale: .5,
            text: "57"
          }}
          label={{
            text: marker.fuelSalesRank.toString(),
          }}
          onClick={() => {
            setSelected(marker);
          }}
        />
      ))}

      {selected ? (
        <InfoWindow
          position={{ lat: selected.location.lat, lng: selected.location.lng }}
          onCloseClick={() => {
            setSelected(null);
          }}
        >
          <div>
            <h4>{selected.siteName}</h4>
            <p>Rank: {selected.fuelSalesRank}</p>
            <a href="https://www.bp.com">View Dashboard</a>
          </div>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  );
}

function mapStateToProps(state) {
  const { authInfo, sitesInfo } = state;
  const { userData } = authInfo;
  const { sites } = sitesInfo;
  return { userData, sites };
}

export default compose(
  connect(mapStateToProps, null)
)(Map);
