import { constants } from '../constants/reducerConstants';

const initialState = {
    filterDetails: {},
    error: '',
    tableReviewDetails: {},
    scoreLoader: false,
    googleReviewLoader: false,
    excelLoader: false

}

export function googleAverageScoreInfo(state = initialState, action) {
    switch (action.type) {
        case constants.SCORE_START_LOADER:
            return {
                ...state,
                scoreLoader: true
            }
        case constants.SCORE_FETCH_SUCCESS:
            return {
                ...state,
                scoreLoader: false,
                scoreData: action.data.body,
            };
        case constants.SCORE_FETCH_FAILURE:
            return {
                ...state,
                scoreLoader: false,
                error: action.error
            };
        case constants.FILTER_FETCH_SUCCESS:
            return {
                ...state,
                filterDetails: action.data?.body
            };
        case constants.FILTER_FETCH_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case constants.TABLE_REVIEW_SUCCESS:
            return {
                ...state,
                tableReviewDetails: action.data?.body,
                googleReviewLoader: false
            }
        case constants.TABLE_REVIEW_FAILURE:
            return {
                ...state,
                error: action.error,
                googleReviewLoader: false
            }
        case constants.GOOGLE_REVIEW_START_LOADER:
            return {
                ...state,
                googleReviewLoader: true
            }
        case constants.EXCEL_DOWNLOAD_LOADER_START:
            return {
                ...state,
                excelLoader: true
            }
        case constants.EXCEL_DOWNLOAD_LOADER_DONE:
            return {
                ...state,
                excelLoader: false
            }
        default:
            return state
    }
}
