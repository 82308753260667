import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { mapSiteRankingHeaderCellToValue, mapSiteReviewHeaderCellToValue } from './utils';
import { Cell, DetailsRowStyled } from './SiteRankingRow.styled';

class SiteRankingRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalStatus: false
        };
    }

    getCells = memoize((headings, siteRanking, filterSelection, reviewkey, languageKey,tableId) => {
        if (!headings) return null; 
        return headings.map((heading) => (
            {
                key: heading.key,
                value: tableId !== "ReviewTable"?
                mapSiteRankingHeaderCellToValue(siteRanking, heading.key, filterSelection, reviewkey, languageKey)
                :
                mapSiteReviewHeaderCellToValue(siteRanking, heading.key, filterSelection, reviewkey, languageKey)
            }));
    });

    renderCell({ key, value }, index) {
        const {tableId } = this.props
        const id = this.props.siteDetail.siteId;
        let style
        if (key === 'Comments' && tableId === "ReviewTable") {
            style = { width: '120px' }
        } else if(tableId === "ReviewTable") {
            style = { width: '20px' }
        }
        return (
            <Cell value={key} style={style} fontLight={key === 'Site'} key={`${id}-${key}`} index={index}>
                {value || (tableId !== "ReviewTable" && '-')}
            </Cell>
        );
    }

    renderRow() {
        const { headings, siteDetail, filterSelection, review, languageKey, tableId } = this.props
        const cells = this.getCells(headings, siteDetail, filterSelection, review, languageKey, tableId);
        if (!cells || !cells.length) return null;
        return (
            <DetailsRowStyled>
                {cells.map(this.renderCell, this)}
            </DetailsRowStyled>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderRow()}
            </React.Fragment>
        );
    }
}


SiteRankingRow.propTypes = {
    headings: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number,
    scheduleDetail: PropTypes.object,
    updateScheduleStatus: PropTypes.func
};

export default SiteRankingRow;
