/* Copyright (C) BP -
 * All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 17/07/2022
 * Author : Aditya Padmanabhan
 */
import React from "react";
import PageCardsDisplay from "./../../components/page/PageCardsDisplay";
import { compose } from "redux";
import { connect } from "react-redux";
import { Pagecontent } from "../page/Page.styled";
import LinkSection from "../LinkSection/LinkSection";
 let kpiList = [];
 let additionalLinks = [];
 let additionalToolsLinks =[];
function Previewchanges(props) {
   if(props.allViews.indexOf(props.presentView) !== -1){
    kpiList = props.viewMangerData[props.presentView]?.tileContents?.kpiList;
    additionalLinks = props.viewMangerData[props.presentView]?.tileContents?.additionalLinks;
      additionalToolsLinks = props.viewMangerData[props.presentView]?.tileContents?.additionalToolsLinks;
   }
  return (
    <>
      <div className="container-body">
        <div className="container">
          <div className="mx-3">
            <Pagecontent id="desktop">
              <PageCardsDisplay data={kpiList} />
            </Pagecontent>
          </div>
          {(additionalLinks?.length !== 0  || additionalToolsLinks?.length !== 0) && (
            <LinkSection
              additionalLink={additionalLinks}
              additionalToolLinks={additionalToolsLinks}
            />
          )}
        </div>
      </div>
    </>
  );
}
function mapStateToProps(state) {
  const { adminInfo } = state;
  const { viewMangerData, presentView, allViews } = adminInfo;
  return {viewMangerData, presentView, allViews};
}
export default compose(connect(mapStateToProps, null))(Previewchanges);
