export function returnRequiredHeader(value){
    switch(value){
        case 'Loyalty Ranking':
            return 'rm_name'
        case 'Score':
            return 'issuance'
        case 'Target':
            return 'target_issuance'
        default:
    }
}

export function returnRequiredRegionalHeader(value){
    switch(value){
        case 'Sites':
            return 'sites'
        case 'Loyalty Score':
            return 'issuance'
        case 'Target Score':
            return 'targetIssuance'
        case 'Last Month':
            return 'lastMonth'
        case 'Previous Week':
            return 'previousIssuance'
        case 'Compliance':
            return 'compliance'
        case 'Labour vs demand':
            return 'labourVsDemand'
        case 'Sick':
            return 'sick'
        case 'Absence':
            return 'absence'
        case 'Holiday':
            return 'holiday'
        default:
    }
}

export function returnRequiredPeopleOverviewHeader(value){
    switch(value){
        case 'Sites':
            return 'sites'
        case 'Labour vs demand':
            return 'labourVsDemand'
        case 'Sick':
            return 'sick'
        case 'Absence':
            return 'absence'
        case 'Holiday':
            return 'holiday'
        default:
    }
}

