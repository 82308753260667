/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 16/02/2021
* Author : Arun Rapolu
*/

import { KPINames } from "../components/ScorecardImproved/ScorecardImprovedConstants"

export const constants = {
    SAVE_AUTH_INFO: 'SAVE_AUTH_INFO',
    CLEAR_AUTH_INFO: 'CLEAR_AUTH_INFO',
    SAVE_REDIRECT_PATH: 'SAVE_REDIRECT_PATH',
    CLEAR_REDIRECT_PATH: 'CLEAR_REDIRECT_PATH',
    SAVE_USER_DATA: 'SAVE_USER_DATA',
    CLEAR_USER_DATA: 'CLEAR_USER_DATA',
    CLEAR_AUTH_STORE: 'CLEAR_AUTH_STORE',
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',
    SAVE_KPI_INFO: 'SAVE_KPI_INFO',
    CLEAR_KPI_INFO: 'CLEAR_KPI_INFO',
    FETCH_SITE_RANKING_SUCCESS: 'FETCH_SITE_RANKING_SUCCESS',
    FETCH_SITE_RANKING_FAILURE: 'FETCH_SITE_RANKING_FAILURE',
    SAVE_AVERAGE_RATINGS_DATA: 'SAVE_AVERAGE_RATINGS_DATA',
    CLEAR_AVERAGE_RATINGS_DATA: 'CLEAR_AVERAGE_RATINGS_DATA',
    SCORE_FETCH_SUCCESS: 'SCORE_FETCH_SUCCESS',
    SCORE_FETCH_FAILURE: 'SCORE_FETCH_FAILURE',
    SAVE_SITE_LINE_DATA: 'SAVE_SITE_LINE_DATA',
    CLEAR_SITE_LINE_DATA: 'CLEAR_SITE_LINE_DATA',
    SHOW_ERROR_PAGE: 'SHOW_ERROR_PAGE',
    SET_SORT_STATE: 'SET_SORT_STATE',
    EMAINTENANCE_SUCCESS: 'EMAINTENANCE_SUCCESS',
    EMAINTENANCE_FAILURE: 'EMAINTENANCE_FAILURE',
    FILTER_FETCH_SUCCESS: 'FILTER_FETCH_SUCCESS',
    FILTER_FETCH_FAILURE: 'FILTER_FETCH_FAILURE',
    TABLE_REVIEW_SUCCESS: 'TABLE_REVIEW_SUCCESS',
    TABLE_REVIEW_FAILURE: 'TABLE_REVIEW_FAILURE',
    SCORE_START_LOADER: 'SCORE_START_LOADER',
    GOOGLE_RATING_START_LOADER: 'GOOGLE_RATING_START_LOADER',
    GOOGLE_REVIEW_START_LOADER: 'GOOGLE_REVIEW_START_LOADER',
    GOOGLE_LINE_GRAPH_LOADER: 'GOOGLE_LINE_GRAPH_LOADER',
    EMAINTENANCE_LOADING_SUCCESS: 'EMAINTENANCE_LOADING_SUCCESS',
    EMAINTENANCE_LOADING_FAILED: 'EMAINTENANCE_LOADING_FAILED',
    EXCEL_DOWNLOAD_LOADER_START: 'EXCEL_DOWNLOAD_LOADER_START',
    EXCEL_DOWNLOAD_LOADER_DONE: 'EXCEL_DOWNLOAD_LOADER_DONE',
    SAVE_LANGUAGE_CODE: 'SAVE_LANGUAGE_CODE',
    SAVE_NOTIFICATION_DATA: 'SAVE_NOTIFICATION_DATA',
    SAVE_INITIAL_TIMESTAMP: "SAVE_INITIAL_TIMESTAMP",
    SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
    HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
    SET_SEEN_DATA: "SET_SEEN_DATA",
    SET_SEEN_EMPTY: "SET_SEEN_EMPTY",
    SET_UNSEEN_DATA: "SET_UNSEEN_DATA",
    SET_UNSEEN_EMPTY: "SET_UNSEEN_EMPTY",
    SAVE_CONNECTION_ID: "SAVE_CONNECTION_ID",
    FINANCIAL_DATA_SUCCESS: 'FINANCIAL_DATA_SUCCESS',
    FINANCIAL_DATA_FAILURE: 'FINANCIAL_DATA_FAILURE',
    USER_ADMIN_SUCCESS: 'USER_ADMIN_SUCCESS',
    USER_ADMIN_FAILURE: 'USER_ADMIN_FAILURE',
    VIEW_MANAGER_SUCCESS: "VIEW_MANAGER_SUCCESS",
    VIEW_MANAGER_FAILURE: "VIEW_MANAGER_FAILURE",
    SAVE_UPDATED_TABLE: "SAVE_UPDATED_TABLE",
    SAVE_UPDATED_LINK: "SAVE_UPDATED_LINK",
    SET_SEND_DATA: "SET_SEND_DATA",
    SET_VIEW_SEND_DATA: "SET_VIEW_SEND_DATA",
    CLEAR_SEND_DATA: "CLEAR_SEND_DATA",
    SET_PREVIEW_TRUE: "SET_PREVIEW_TRUE",
    SET_PREVIEW_FALSE: "SET_PREVIEW_FALSE",
    UPDATE_VIEW: "UPDATE_VIEW",
    CREATE_USER_MANAGER_SUCCESS: "CREATE_USER_MANAGER_SUCCESS",
    CREATE_USER_MANAGER_FAILURE: "CREATE_USER_MANAGER_FAILURE",
    UPDATE_USER_MANAGER_SUCCESS: "UPDATE_USER_MANAGER_SUCCESS",
    UPDATE_USER_MANAGER_FAILURE: "UPDATE_USER_MANAGER_FAILURE",
    DELETE_USER_MANAGER_SUCCESS: "DELETE_USER_MANAGER_SUCCESS",
    DELETE_USER_MANAGER_FAILURE: "DELETE_USER_MANAGER_FAILURE",
    EDIT_LINK_FALSE: "EDIT_LINK_FALSE",
    EDIT_LINK_TRUE: "EDIT_LINK_TRUE",
    SET_ALL_VIEWS: "SET_ALL_VIEWS",
    NEW_ADDED_VIEW: "NEW_ADDED_VIEW",
    SCORECARD_SUCCESS: "SCORECARD_SUCCESS",
    SCORECARD_FAILURE: "SCORECARD_FAILURE",
    SCORECARD_DOWNLOAD_LOADER_START: 'SCORECARD_DOWNLOAD_LOADER_START',
    SCORECARD_DOWNLOAD_LOADER_DONE: 'SCORECARD_DOWNLOAD_LOADER_DONE',
    SAVE_CURRENT_MENUITEM: 'SAVE_CURRENT_MENUITEM',
    SAVE_MENU_DATA: 'SAVE_MENU_DATA',
    FETCH_LIST_OF_SITE: 'FETCH_LIST_OF_SITE',
    CHANGE_NUMBER_OF_SITES: "CHANGE_NUMBER_OF_SITES",
    SITE_NAMES: "SITE_NAMES",
    CATEGORY_FETCH_SUCCESS: "CATEGORY_FETCH_SUCCESS",
    CATEGORY_FETCH_FAILURE: "CATEGORY_FETCH_FAILURE",
    FILE_TABLE_UPDATE: "FILE_TABLE_UPDATE",
    FILE_TABLE_CLEAR: "FILE_TABLE_CLEAR",
    DISABLE_SUBMIT: "DISABLE_SUBMIT",
    ENABLE_SUBMIT: "ENABLE_SUBMIT",
    LOG_FETCH_SUCCESS: "LOG_FETCH_SUCCESS",
    SELECTED_MANAGER_NAME: "SELECTED_MANAGER_NAME",
    SITE_MASTER_LIST_FETCH_SUCCESS: "SITE_MASTER_LIST_FETCH_SUCCESS",
    SITE_LOG_FETCH_SUCCESS: "SITE_LOG_FETCH_SUCCESS",
    SET_CATEGORY: "SET_CATEGORY",
    EMPTY_SCORECARD: "EMPTY_SCORECARD",
    UPDATE_EXECUTION_ID: "UPDATE_EXECUTION_ID",
    SITE_UPDATE_EXECUTION_ID: "SITE_UPDATE_EXECUTION_ID",
    SAVE_COMPLETE_SCORECARD_DATA: "SAVE_COMPLETE_SCORECARD_DATA",
    SET_SITES:"SET_SITES"
}

export const scorecardReducerConstants = {
    [KPINames.LAYOUT]: {
        SUCCESS: 'SCORECARD_LAYOUT_SUCCESS',
        FAILURE: 'SCORECARD_LAYOUT_FAILURE',
        LOADER_START: 'SCORECARD_LAYOUT_LOADER_START',
        LOADER_DONE: 'SCORECARD_LAYOUT_LOADER_DONE',
    },
    [KPINames.SHOP_SALES]: {
        SUCCESS: 'TRADING_PROFITABLY_SHOP_SALES_SUCCESS',
        FAILURE: 'TRADING_PROFITABLY_SHOP_SALES_FAILURE',
        LOADER_START: 'TRADING_PROFITABLY_SHOP_SALES_LOADER_START',
        LOADER_DONE: 'TRADING_PROFITABLY_SHOP_SALES_LOADER_DONE',
    },
    [KPINames.FUEL_SALES]: {
        SUCCESS: 'TRADING_PROFITABLY_FUEL_SALES_SUCCESS',
        FAILURE: 'TRADING_PROFITABLY_FUEL_SALES_FAILURE',
        LOADER_START: 'TRADING_PROFITABLY_FUEL_SALES_LOADER_START',
        LOADER_DONE: 'TRADING_PROFITABLY_FUEL_SALES_LOADER_DONE',
    },
    [KPINames.SERVICES]: {
        SUCCESS: 'TRADING_PROFITABLY_SERVICES_SUCCESS',
        FAILURE: 'TRADING_PROFITABLY_SERVICES_FAILURE',
        LOADER_START: 'TRADING_PROFITABLY_SERVICES_LOADER_START',
        LOADER_DONE: 'TRADING_PROFITABLY_SERVICES_LOADER_DONE',
    },
    [KPINames.WBC_SALES]: {
        SUCCESS: 'TRADING_PROFITABLY_WBC_SALES_SUCCESS',
        FAILURE: 'TRADING_PROFITABLY_WBC_SALES_FAILURE',
        LOADER_START: 'TRADING_PROFITABLY_WBC_SALES_LOADER_START',
        LOADER_DONE: 'TRADING_PROFITABLY_WBC_SALES_LOADER_DONE',
    },
    [KPINames.WASTE]: {
        SUCCESS: 'TRADING_PROFITABLY_WASTE_SUCCESS',
        FAILURE: 'TRADING_PROFITABLY_WASTE_FAILURE',
        LOADER_START: 'TRADING_PROFITABLY_WASTE_LOADER_START',
        LOADER_DONE: 'TRADING_PROFITABLY_WASTE_LOADER_DONE',
    },
    [KPINames.LOSS]: {
        SUCCESS: 'TRADING_PROFITABLY_LOSS_SUCCESS',
        FAILURE: 'TRADING_PROFITABLY_LOSS_FAILURE',
        LOADER_START: 'TRADING_PROFITABLY_LOSS_LOADER_START',
        LOADER_DONE: 'TRADING_PROFITABLY_LOSS_LOADER_DONE',
    },
    [KPINames.CUSTOMER_SATISFACTION]: {
        SUCCESS: 'CUSTOMER_CUSTOMER_SATISFACTION_SUCCESS',
        FAILURE: 'CUSTOMER_CUSTOMER_SATISFACTION_FAILURE',
        LOADER_START: 'CUSTOMER_CUSTOMER_SATISFACTION_LOADER_START',
        LOADER_DONE: 'CUSTOMER_CUSTOMER_SATISFACTION_LOADER_DONE',
    },
    [KPINames.REGIONAL_LOYALTY]: {
        SUCCESS: 'CUSTOMER_REGIONAL_LOYALTY_SUCCESS',
        FAILURE: 'CUSTOMER_REGIONAL_LOYALTY_FAILURE',
        LOADER_START: 'CUSTOMER_REGIONAL_LOYALTY_LOADER_START',
        LOADER_DONE: 'CUSTOMER_REGIONAL_LOYALTY_LOADER_DONE',
    },
    [KPINames.LOYALTY_RANKING]: {
        SUCCESS: 'CUSTOMER_LOYALTY_RANKING_SUCCESS',
        FAILURE: 'CUSTOMER_LOYALTY_RANKING_FAILURE',
        LOADER_START: 'CUSTOMER_LOYALTY_RANKING_LOADER_START',
        LOADER_DONE: 'CUSTOMER_LOYALTY_RANKING_LOADER_DONE',
    },
    [KPINames.PEOPLE_OVERVIEW]: {
        SUCCESS: 'CUSTOMER_PEOPLE_OVERVIEW_SUCCESS',
        FAILURE: 'CUSTOMER_PEOPLE_OVERVIEW_FAILURE',
        LOADER_START: 'CUSTOMER_PEOPLE_OVERVIEW_LOADER_START',
        LOADER_DONE: 'CUSTOMER_PEOPLE_OVERVIEW_LOADER_DONE',
    },
    [KPINames.CLOCKING_ON_TIME]: {
        SUCCESS: 'CUSTOMER_CLOCKING_ON_TIME_SUCCESS',
        FAILURE: 'CUSTOMER_CLOCKING_ON_TIME_FAILURE',
        LOADER_START: 'CUSTOMER_CLOCKING_ON_TIME_LOADER_START',
        LOADER_DONE: 'CUSTOMER_CLOCKING_ON_TIME_LOADER_DONE',
    },
    [KPINames.UNPUBLISHED_SCHEDULE]: {
        SUCCESS: 'CUSTOMER_UNPUBLISHED_SCHEDULE_SUCCESS',
        FAILURE: 'CUSTOMER_UNPUBLISHED_SCHEDULE_FAILURE',
        LOADER_START: 'CUSTOMER_UNPUBLISHED_SCHEDULE_LOADER_START',
        LOADER_DONE: 'CUSTOMER_UNPUBLISHED_SCHEDULE_LOADER_DONE',
    },
    [KPINames.SITE_SAFETY_OVERVIEW]: {
        SUCCESS: 'CUSTOMER_SITE_SAFETY_OVERVIEW_SUCCESS',
        FAILURE: 'CUSTOMER_SITE_SAFETY_OVERVIEW_FAILURE',
        LOADER_START: 'CUSTOMER_SITE_SAFETY_OVERVIEW_LOADER_START',
        LOADER_DONE: 'CUSTOMER_SITE_SAFETY_OVERVIEW_LOADER_DONE',
    },
    [KPINames.DARK_SITES]: {
        SUCCESS: 'SAFETY_FIRST_DARK_SITES_SUCCESS',
        FAILURE: 'SAFETY_FIRST_DARK_SITES_FAILURE',
        LOADER_START: 'SAFETY_FIRST_DARK_SITES_LOADER_START',
        LOADER_DONE: 'SAFETY_FIRST_DARK_SITES_LOADER_DONE',
    },
    [KPINames.MANDATORY_TASKS]: {
        SUCCESS: 'SAFETY_FIRST_MANDATORY_TASKS_SUCCESS',
        FAILURE: 'SAFETY_FIRST_MANDATORY_TASKS_FAILURE',
        LOADER_START: 'SAFETY_FIRST_MANDATORY_TASKS_LOADER_START',
        LOADER_DONE: 'SAFETY_FIRST_MANDATORY_TASKS_LOADER_DONE',
    },
}