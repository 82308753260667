import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import CustomerSatisfaction from "../../../../scorecardNewDesign//Customer/Screens/CustomerSatisfaction";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const CustomerSatisfactionComponent = ({ customer_satisfaction, getCustomerSatisfactionData , layout}) => {
  useEffect(() => {
    getCustomerSatisfactionData(KPINames.CUSTOMER_SATISFACTION);
  }, []);

  if (customer_satisfaction.isLoading || layout?.data === null ) {
    return <ScorecardPlaceholder />;
  }

  if (customer_satisfaction.data) {
    return (
        <CustomerSatisfaction weekNumber = {layout?.data?.weekNumber} data = {customer_satisfaction.data} dataFor = {"Month"}/>
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
  } = state;
  const customer_satisfaction = scorecardImprovedInfo[KPINames.CUSTOMER_SATISFACTION];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return {  customer_satisfaction, layout };
}

const mapDispatchToProps = {
  getCustomerSatisfactionData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CustomerSatisfactionComponent);
