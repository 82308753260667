import { isEmpty } from "lodash";
import { LANGUAGE_URLS } from "../constants/environmentConstants";

export const adminServices = {
  getAdminData,
  getViewData,
  sendToServer,
  createAdminUser,
  updateAdminUser,
  deleteAdminUser,
  sendToAddView,
  deleteView,
  categoryScoreCardfetch,
  fetchTable,
  uploadToS3,
  scoreCardLogfetch,
  siteMasterFetch,
  siteDataLogfetch
};

function getAdminData(data, languageCode) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: data,
    },
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/users`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function categoryScoreCardfetch(data, languageCode) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: data,
    },
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/scorecard/categories`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function siteMasterFetch(data, languageCode) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: data,
    },
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/site-master/files`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function scoreCardLogfetch(data, languageCode, value) {
  let executionList = {
    "executionIds": value
  };
  let attachments = JSON.stringify(executionList)
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: data,
      "Content-Type": "application/json",
    },
    body: attachments
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/scorecard/logs`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        // if(data.body.logs !== undefined){
        return data.body;
        // }
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function siteDataLogfetch(data, languageCode, input) {
  let executionList = {
    "executionIds": input
  };
  let attachments = JSON.stringify(executionList)
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: data,
        "Content-Type": "application/json",

      },
      body:attachments
    };
    const url = LANGUAGE_URLS.find(
      (value) =>
        value.env === process.env.REACT_APP_ENVIRONMENT &&
        value.code.toLowerCase() === languageCode
    ).api_url;
    let apiLink = `${url}/admin/site-master/logs`;
    return fetch(apiLink, requestOptions)
      .then(handleResponse)
      .then((data) => {
        if (data.statusCode === 400) {
          return Promise.reject(data);
        } else {
          // if(data.body.logs !== undefined){
          return data.body;
          // }
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }



function fetchTable(data, languageCode, id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: data,
    },
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/scorecard/files/${id}`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getViewData(data, languageCode) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: data,
    },
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/views`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      let error = (data && data.message) || response.statusText;
      if (response.status === 400) {
        error = response.status;
      }
      throw new Error(error);
    }
    return data;
  });
}

function sendToAddView(data, toAddView, languageCode) {
  let toAddViewString = JSON.stringify(toAddView);
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: data,
      "Content-Type": "application/json",
    },
    body: toAddViewString,
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/views/add`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function uploadToS3(data, languageCode, toAddView, scoreCardUpload) {
    let toAddViewString = JSON.stringify(toAddView);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: data,
        "Content-Type": "application/json",
      },
      body: toAddViewString,
    };
    const url = LANGUAGE_URLS.find(
      (value) =>
        value.env === process.env.REACT_APP_ENVIRONMENT &&
        value.code.toLowerCase() === languageCode
    ).api_url;
    let apiLink = scoreCardUpload ? `${url}/admin/scorecard/upload` : `${url}/admin/site-master/upload`;
    return fetch(apiLink, requestOptions)
      .then(handleResponse)
      .then((data) => {
        if (data.statusCode === 400) {
          return Promise.reject(data);
        } else {
          return data.body;
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });

}

function sendToServer(data, toSendUpdate, view, languageCode) {
  let toSendString = JSON.stringify(toSendUpdate);
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: data,
      "Content-Type": "application/json",
    },
    body: toSendString,
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/views/update/${view}`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function deleteView(data, view, languageCode) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: data,
    },
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/views/delete/${view}`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function createAdminUser(token, request, languageCode) {
  delete request.editKey;
  let toSendString = JSON.stringify(request);
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: toSendString,
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/users/add`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
function updateAdminUser(token, request, languageCode, id) {
  let toSendString = JSON.stringify(request);
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: toSendString,
  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/users/update/${id}`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function deleteAdminUser(data, id, languageCode, value) {
  let toSendString = JSON.stringify(value);
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: data,
      "Content-Type": "application/json",
    },
    body: toSendString,

  };
  const url = LANGUAGE_URLS.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  ).api_url;
  let apiLink = `${url}/admin/users/delete/${id}`;
  return fetch(apiLink, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data.body;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
