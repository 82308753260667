import styled from "styled-components";

export const UserSites = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(53, 53, 53, 0.06);
  border: solid 0.5px #ccc;
  margin: 50px 25px 25px 25px;
`;

export const Changes = styled.div`
  margin-left: 1.5em;
  margin-right: 1.5em;
  background-color: white;
  display: flex;
  justify-content: space-between;
  height: 5em;
  align-items: center;
`;

export const Preview = styled.text`
  color: #007833;
  text-decoration: underline;
  font-size: 14px;
  font-family: roboto-regular;
`;

export const Editview = styled.div`
 display: flex;
 justify:content: space-between;
 margin-right: 1em;
 margin-left: 1em;
`;

export const Newlinkadd = styled.div`
  width: inherit;
  display: flex;
  height: 1em;
  justify-content: flex-end;
  align-items: center;
  font-family: roboto-regular;
  margin-right: 2em;
  padding-top: 2em;
  cursor: pointer;
  color: #666;
  -webkit-tap-highlight-color: transparent;
`;

export const Backgroundblur = styled.div`
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
  overflow: hidden;
  z-index: 100;
  display: flex;
  background-color: #00000050;
  justify-content: center;
  align-items: center;
`;

export const Mainbox = styled.div`
  width: 28rem;
  height: 18rem;
  background-color: white;
  display: flex;
`;
export const ImageBox = styled.div`
  display: flex;
  flex: 0.4;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Textbox = styled.div`
  display: flex;
  flex: 0.6;
  flex-direction: column;
`;

export const Deleteuser = styled.text`
  margin-top: 5em;
  font -size: 16px;
  font-family: roboto-light;
  

`;
export const Noreverting = styled.text`
  position:relative;
  margin-top: 1.5em;
  font -size: 14px;
  font-family: roboto-light;
  


`;

export const Buttons = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Deletebutton = styled.div`
  font-size: 14px;
  border-radius: 27px;
  border: solid 1px #dedede;
  background-color: white;
  padding: 9px 25px 10px 25px;
  cursor: pointer;
`;

export const Declinebutton = styled.div`
  font-size: 14px;
  margin-top: 0.7em;
  margin-right: 2em;
  cursor: pointer;
`;

export const Sadicon = styled.img`
  width: 8em;
  height: 8em;
`;

export const Excelbox = styled.div`
 margin-top: 0.5em;
`;


export const SCdataUploadValues = styled.div`
 display: flex;
 flex: 1;
 width: inherit;
 height: ${(props) => props.usedFor === 'siteDataUpload' ? "auto" : "10em"};
 align-items:center;
 justify-content: ${(props) => props.usedFor === 'siteDataUpload' ? "center" : "space-between"};
 margin-left: 3em;
 margin-right: 3em;

`;

export const FileTable = styled.div`
 width: inherit;
 margin-left: 3em;
 margin-right: 3em;
 margin-top: 1em;
 height: auto;
 display: flex;
 flex-direction: column;
`;

export const TableAlignment = styled.div`
 display: flex;
 width:  100%;
 border-bottom: ${(props) => props.id === 'values' ? "1px solid black" : ""};
 border-top: 1px solid black;
 border-left: 1px solid black;
 border-right: 1px solid black;
 flex-direction: ${(props) => props.id === 'values' ? "column" : ""};

`;

export const Logstable = styled.div`
 width: 100%;
 height: 90%;
 overflow-y: scroll;
`;


export const SelectionOption = styled.div`
 display: flex:
 flex-direction: column;
 height: 2em;
 width:10em;
 `;

export const ButtonText = styled.div`
 margin-left: 1.3em;
 `

export const SubmitFilebutton = styled.button`
 align-self: flex-end;
 margin-top: 1em;
 border-radius: 27px;
 width: 114px;
 height: 32px;
 border-color: ${(props) => props.color ? "#404040" : "#ededed"};
 color: ${(props) => props.color ? "#000000" : "#666666"};
 cursor: ${(props) => props.color ? "pointer" : "not-allowed"};
`;

export const StatusOfUpload = styled.div`
 width: 100%;
 height: 15em;
 margin-top: 1em;
 border: 1px solid;
`;

export const LogMesaageBox = styled.div`
 margin-left: 1.5em;
 font-family: roboto-light;
 font-size: 14px;
`
export const HighlightLogText = styled.text`
 color: black;
 font-family: roboto-bold;
`

export const InfoOfUpload = styled.div`
 width: 100%;
 height : 10em;
 margin-top: 1em;
 display: flex;
 font-family: roboto-light;
 font-size: 14px;
 margin-left: 5em;
 margin-right: 5em;
 
`

export const FileTableHeader = styled.div`
width: inherit;
height:1.5em;
display: flex;
justify-content:center;
//flex: 0.34;
flex:${(props) => props.flex}
`;



export const Perrowupload = styled.div`
 display: flex;
 width: inherit;
//  height: 4em;
padding-top: 7px;
padding-bottom: 7px;
 align-items: center;
 background-color: ${(props) => props.toBehighlighted ? "lightgreen" : ""}
`
export const FileTablecontent = styled.div`
 display: flex;
//  flex:0.34;
flex:${(props) => props.flex || 1};
 font-family: roboto-light;
 font-size: 14px;
 justify-content: center;
 text-overflow: ellipsis;
 text-align: center;
    //white-space: nowrap;
    overflow: hidden;
   // width:100px; /* some width */
`;




export const Perrowfield = styled.div`
  display: flex;
  justify-content: center;

`;


