import React from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { LinkFlex, LinkItem, FlexText } from "./LinkSection.Styled";
import { Container } from "../card/Card.styled";

function LinkCode(props) {
 
let gotoDashboard = (url) => {
    if (url === "/") {
      return;
    } else if (url === "/reviews/dashboard") {
      props.history.push(url);
    } else {
      window.open(url);
    }
  };
  return (
    <LinkFlex>
      <LinkItem isMobile={props.isMobile}
        onClick={() => gotoDashboard(props.link.url)}
        className={props.link.url === "/" ? "pointer-not-allow" : "pointer-hand"}
      >
        <Container>
          <FlexText id="color">
            {props.link.name}
          </FlexText>
          </Container>
      </LinkItem>
    </LinkFlex>
  );
}

export default compose(withRouter)(LinkCode);
