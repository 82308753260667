/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 17/07/2022
* Author : Aditya Padmanabhan
*/

import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { adminActions } from '../../actions/adminActions';
import { useHistory } from "react-router-dom";
import { AddNewText, AddNewView, FilterText, HeaderContainer, HorizontalList, PopupContainer } from '../card/Card.styled';
import HeaderCard from '../card/HeaderCard';
import SitesComponent from '../userSites/sites';
import { Newlinkadd, UserSites } from './AdminDashboard.styled';
import { findEditList, searchLinkFetch } from './AdminUtils'
import DropDown from '../dropDown/dropdown';
import { kpiData,  Linksectionlinks, Linksectiontools, New_link_add, No_link_result, View_place_holder, View_title_text } from './AdminConstants';
import { Selectionicons } from '../userSites/sites.styled';
import green_tick from "../../images/icons/green_tick.svg";

import trash_red_icon from "../../images/icons/trash_red_icon.svg";
import pencil_green from "../../images/icons/pencil_green.svg";
import icon_cross from "../../images/icons/icon_cross.svg";
import Delete from './Delete';

let history
function ViewManger(props) {
    history = useHistory();
    let [viewTableDetails, setViewTableDetails] = useState([]);
    let [selectedText, setSelectedText] = useState([]);
    let [selectedValue, setSelectedValue] = useState("");
    let [deleteKey, setDeleteKey]=useState(false);
    let [openDropdown, setOpenDropdown]= useState(false);
    const [popup, setPopup] = useState(false);
    let [viewEdit, setViewEdit]=useState(false);
    let [newViewName, setNewViewName]= useState("");
    let [searchData, setSearchData]= useState(false);
   
    let viewheader= {
        name: 'Linkname', url: 'URL', category : 'Link or Tool', tile: 'Tile',  icon: ''
    }
    
    useEffect(()=>{                                               // initial starting of view tab
      let data = Object.keys(props.viewMangerData);
      setSelectedText(data);
      props.setAllViews(data);
      data.push("+ Add new view");
      setSelectedValue(data[0]);
      props.updateView(data[0])
      changeData(data[0]);
    },[])

    useEffect(()=>{                                            // whenever a view a added or deleted 
      let data = Object.keys(props.viewMangerData);
      props.setAllViews(data);
    },[props.viewMangerData])


    function filterSelection(item, index, text){               // for top view selection
      setSelectedValue(item);
      props.updateView(item)
      changeData(item);
    }

    function changeIndex(item) {                               // to make editing of link possible
        let data =[...viewTableDetails] 
        let i = data.indexOf(item)
        data[i] = item;
       setViewTableDetails(data);
       props.changeEdit()
    }

    function addNewRow(){                                     // adding new link
        let key = [...viewTableDetails];
        let data = {name:"Please enter link name",url:"https://",category:"Link",tile:"LINKS & TOOLS", editKey:true } 
        key.unshift(data);
        setViewTableDetails(key);
        let isNewUserCreated = findEditList(key)
        if(isNewUserCreated!==undefined){
          props.changeEdit()
        }
      }

    function changeData(name){                            // helps in loading links related to corresponding view
       if(props.editLinkCheck){
          props.changeEdit()
        }
        let completeData = { ...props.viewMangerData };
        let selectedManager = completeData[name];
        if (selectedManager === undefined && name !== "+ Add new view") {
          setViewTableDetails([]);
        } 
        else if (name === "+ Add new view") {
          setViewEdit(!viewEdit);
          setViewTableDetails([]);
        } 
        else {
          selectedManager = selectedManager["links"].map((item) => {
            return {
              ...item,
              editKey: false,
            };
          });
          setViewTableDetails(selectedManager);
          props.updateTable(selectedManager);
        }
      
      }

     
      
      function changeRowLink(item,data){                         // for updating, deleting or adding a link 
        if(props.allViews.indexOf(props.presentView)!== -1){
            let key = [...viewTableDetails];
            let i = key.indexOf(item);
            let updatedData = {};
            if (props.updatedTable.indexOf(item) === -1) {
                updatedData["ADD"] = [];
                let updateItem = {
                  assetType: "LINK",
                  linkParams: {},
                };
                Object.keys(data).forEach(value =>{
                  if(data[value] !== "" && data[value]!==undefined){
                   key[i][value] = data[value]
                  }
                })
                updateItem.linkParams = { ...key[i] };
                delete updateItem.linkParams.editKey;
                updatedData.ADD.push(updateItem);
                props.sendDataToServer(updatedData, props.presentView).then(()=>{
                  props.updateTable(key)
                });
                setViewTableDetails(key);
            } 
            else {
                updatedData["UPDATE"] = [];
                let updateItem = {
                  assetType: "LINK",
                  linkParamsOld: {},
                  linkParamsNew: {},
                };
                updateItem.linkParamsOld = { ...item };
                delete updateItem.linkParamsOld.editKey;
                Object.keys(data).forEach(value =>{
                  if(data[value] !== "" && data[value]!==undefined){
                   key[i][value] = data[value]
                  }
                })
                updateItem.linkParamsNew = { ...key[i] };
                delete updateItem.linkParamsNew.editKey;
                updatedData.UPDATE.push(updateItem);
                props.sendDataToServer(updatedData, props.presentView).then(()=>{
                  props.updateTable(key);
                });
                setViewTableDetails(key);
              }
     }
     else{
          if(props.presentView!=="+ Add new view"){
           sendNewView(props.presentView,data,item)
          }
     }
   }
   
 
   function sendNewView(view,data, prevResponse){        // creating a new view
       let newViewData = {};
       let key = [...viewTableDetails];
       let i = key.indexOf(prevResponse);
       Object.keys(data).forEach(value =>{
         if(data[value] !== "" && data[value]!==undefined){
          key[i][value] = data[value]
         }
       })
       setViewTableDetails(key);
       newViewData.viewName = view;
       newViewData.kpiList = kpiData;
       newViewData.additionalLinks = [];
       newViewData.additionalToolsLinks = [];
      if (key[i].tile === Linksectionlinks[0] && key[i].category === Linksectionlinks[1] ) {
         data.translationKey = "";
         delete data.editKey;
         newViewData.additionalLinks.push(key[i]);
       }
      if (key[i].tile === Linksectiontools[0] && key[i].category === Linksectiontools[1]) {
         delete data.editKey;
         data.translationKey = "";
         newViewData.additionalToolsLinks.push(key[i]);
       }
        props.addNewViewServer(newViewData).then(() => {
        props.updateTable(key);
       });
       
   }
    
   function checkNewLinkSelection() {            
      if (!props.editLinkCheck) {
        addNewRow();
      }
    }

    function  deleteLink(item,index){               // for deleting a link
        let key = [...viewTableDetails]
        let updatedData ={};
        let view = props.presentView;
        updatedData["DELETE"]=[];
        let updateItem={
          assetType: "LINK",
          linkParams:{},
        }
        updateItem.linkParams ={...item};
        delete updateItem.linkParams.editKey;
        updatedData.DELETE.push(updateItem)
        key.splice(index,1)
         props.sendDataToServer(updatedData,view).then(()=>{
          props.updateTable(key)
         })
        setViewTableDetails(key)
        
    }


    function userSearchMethod(e) {        // search of link or tool
      let searchUserDetails = props.updatedTable;
      if (e.length > 0) {
        searchUserDetails = searchLinkFetch(props.updatedTable, e);
      }
      if(searchUserDetails.length===0){
        setSearchData(true)
    }
    else{
        setSearchData(false)
        setViewTableDetails(searchUserDetails)
    }

  }

  function deleteViewItem(){           // deleting a view
      let views = [...selectedText];
      setDeleteKey(!deleteKey);
      if (props.presentView !== "+ Add new view") {
        let i = views.indexOf(props.presentView);
        views.splice(i, 1);
        setSelectedText(views);
        setSelectedValue(views[i - 1]);
        filterSelection(views[i - 1], "", "");
        if (props.allViews.indexOf(props.presentView) !== -1) {
          props.deleteView(props.presentView) 
        }
      }
  }

  function addView(){                            // adding a new view
    let views = [...selectedText];
    let i = views.indexOf("+ Add new view");
    views.splice(i, 0, newViewName);
    setSelectedText(views);
    setSelectedValue(newViewName);
    props.updateView(newViewName);
    setViewEdit(!viewEdit);
    setViewTableDetails([]);
    setNewViewName("");
  }

  function removeItem(item){
    let key = [...viewTableDetails]
    let index = key.indexOf(item);
    key.splice(index,1)
    setViewTableDetails(key);
  }

  return (
    <>
      <HeaderContainer>
        <HorizontalList>
          <FilterText>View to edit:</FilterText>
          {!isEmpty(props.viewMangerData) ? (
            !viewEdit ? (
              <><DropDown
                  styleValues={"filterButtonStyle viewFilterWidth"}
                  filterWidth={145}
                  radioButton={true}
                  dropDownKey={openDropdown}
                  arrayList={selectedText}
                  selectedItem={selectedValue}
                  selectedViewText={selectedValue}
                  onClick={() => setOpenDropdown(!openDropdown)}
                  itemSelection={(item, index) =>
                    filterSelection(item, index, "roles")
                  }
                  filterDropDownStyle={"filterDropDownStyle filterWidth"}
                />
                <Selectionicons>
                  <img
                    src={pencil_green}
                    alt="tick"
                    height={20}
                    width={20}
                    style={{ marginRight: "0.5em", marginLeft: "0.5em" }}
                  />
                  <img
                    src={trash_red_icon}
                    alt="tick"
                    height={20}
                    width={20}
                    style={{ marginRight: "0.5em", marginLeft: "0.5em" }}
                    onClick={() => setDeleteKey(!deleteKey)}
                  />
                </Selectionicons>
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder={"add view name"}
                  onChange={(e) => setNewViewName(e.target.value)}
                  style={{marginLeft:"5px"}}
                />
                <Selectionicons>
                  <img
                    src={green_tick}
                    alt="tick"
                    height={20}
                    width={20}
                    style={{ marginRight: "0.5em", marginLeft: "0.5em" }}
                    onClick={() => addView()}
                  />
                  <img
                    src={icon_cross}
                    alt="tick"
                    height={20}
                    width={20}
                    style={{ marginRight: "0.5em", marginLeft: "0.5em" }}
                    onClick={() => setViewEdit(!viewEdit)}
                  />
                </Selectionicons>
              </>
            )
          ) : null}
        </HorizontalList>
        <AddNewView>
          <AddNewText
            onClick={() => {
              history.push("/admin/previewchanges");
            }}
          >
            Preview Changes
          </AddNewText>
        </AddNewView>
      </HeaderContainer>
      <Newlinkadd>
        <AddNewText disableEditView={props.editLinkCheck} onMouseEnter={() => props.editLinkCheck && setPopup(true)}
        onMouseLeave={() => setPopup(false)}
        onClick={() => checkNewLinkSelection()}>
          {New_link_add}
        </AddNewText>
        {popup && props.editLinkCheck && (
          <PopupContainer>
            <span className="add_user_popup">
              Please finish editing the current Link, before adding a new one.
            </span>
          </PopupContainer>
        )}
      </Newlinkadd>
      <UserSites>
        <HeaderCard
          titleText={View_title_text}
          placeholderText={View_place_holder}
          searchMethod={(e) => userSearchMethod(e)}
        />
        <SitesComponent
          siteheader={viewheader}
          listDetails={viewTableDetails}
          deletelink={(item, index) => deleteLink(item, index)}
          changeRowLink={(item, data) => changeRowLink(item, data)}
          changeStatus={(item) => changeIndex(item)}
          removeLink={(item) => removeItem(item)}
          searchResult = {searchData}
          viewComponent = {true}
          no_data_result = {No_link_result}
        />
      </UserSites>
      {deleteKey && (
        <Delete
          userManager={false}
          view={props.presentView}
          deleteMethod={() => deleteViewItem()}
          doNotDelete={() => setDeleteKey(!deleteKey)}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
    const { adminInfo} = state;
    const {viewMangerData,sendData, updatedTable,presentView,editLinkCheck, allViews} = adminInfo;
     return { sendData,viewMangerData, updatedTable,presentView,editLinkCheck,allViews};
  }

  const mapDispatchToProps = {
    updateTable: (data)=>adminActions.updateTable(data),
    updateView: (data)=>adminActions.updateView(data),
    sendDataToServer: (data,view) => adminActions.sendDataToServer(data,view),
    changeEdit: ()=>adminActions.changeEdit(),
    setAllViews: (data)=> adminActions.setAllViews(data),
    addNewViewServer:(data)=>adminActions.addNewViewServer(data),
    deleteView:(data)=> adminActions.deleteView(data)
  };

  export default compose(
    withRouter,
   connect(mapStateToProps, mapDispatchToProps)
  )(ViewManger);
  