/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/1/2023
 */

import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../actions/scorecardActions";
import { capitalize } from "../../../Scorecard/utils";
import { KPINames } from "../../../ScorecardImproved/ScorecardImprovedConstants";
import { LoyaltyRankingName } from "../../People/Style/PeopleUi.styled";
import { Olddatatradingapprovee } from "../../TradingProfitably/Styles/TradingUi.styled";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import { titleGenerator } from "../../scorecardConstants";
import { returnRequiredHeader } from "../Utils/customerUtils";
import {
  Exclaimation, ExclaimationText,
  LoyaltyBox,
  LoyaltyMainContainer,
  Loyaltyheader,
  Loyaltyheaderdata,
  Loyaltyperc,
  Loyaltyscore,
  OldDataBlur,
  OldLoyaltyBox,
  Oldtext,
  Oldviewcustomersatisfaction,
  Ravg,
  Scoreboxtitle,
  Targetscore,
  TooltipValue
} from "../style/Customer.styled";
import { TileHeader } from "./Components/TileHeader";

function Loyalty(props) {
  const { data } = props;
  const {expectedRefreshTimestamp, lastWeekUpdated, title, state, toolTipText, headers} =data;
  let renderScoreData = data?.data?.averageComparison?.data;
  let rmData = data?.data?.rmRanking;
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
  const [blur, setBlur] = useState(props.weekNumber !== lastWeekUpdated);
  const headerItem = ["Loyalty Ranking", "Score", "Target"];

 const handleHover = (event, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
     
    } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };

  const getHeaderState = () => {
    if (data.data.averageComparison.data.average.value >= data.data.averageComparison.data.target.value)
    {
      return "OK";
    }
    return "DANGER"
  }

  function loadExcel() {
    props.exportToExcel(titleGenerator[title], headers, rmData);
  }

 return (
    <LoyaltyBox>
       <OldDataBlur boolTrue={blur} id= "OldDataBlur">
      {title === 'Waste' || title === 'Till Losses' ? <>
    {
     props.weekNumber - 1 === 0 ?
     <>
     {!blur && 52 !== lastWeekUpdated && (
       <Exclaimation
         onMouseEnter={(e) => blurHover(e, "enter")}
         onMouseLeave={(e) => blurHover(e, "leave")}
         id="exclamationContainer"
       >
         <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
       </Exclaimation>
     )}
     </>
     :
     <>
     {!blur && props.weekNumber-1 !== lastWeekUpdated && (
       <Exclaimation
         onMouseEnter={(e) => blurHover(e, "enter")}
         onMouseLeave={(e) => blurHover(e, "leave")}
         id="exclamationContainer"
       >
         <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
       </Exclaimation>
     )}
     </>
    }
    </>:<>
    {!blur && props.weekNumber !== lastWeekUpdated && (
       <Exclaimation
         onMouseEnter={(e) => blurHover(e, "enter")}
         onMouseLeave={(e) => blurHover(e, "leave")}
         id="exclamationContainer"
       >
         <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
       </Exclaimation>
     )}
     </>}
     {oldPopUpVisible && (
       <TooltipValue
       id = "tooltipvalue"
         top = {blurRect.top + 70}
         left = {blurRect.left + 15}
       >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
     )}
      <TileHeader
        showPopup={showPopup}
        exportToExcel = {() => loadExcel()}
        excelLoader={props.excelLoader}
        kpiName = {props.kpiName}
        handleHover={(e, value) => handleHover(e, value)}
        title="Loyalty Ranking"
        state={getHeaderState()}
      />
      {showPopup && (
        <ScorePopupContainerWeb
        top={top}
        left={left-20}
        width = {18}
         id='popUpDesktop'
         textDescription = {toolTipText}
      />
      )}
      <Scoreboxtitle></Scoreboxtitle>
      <Loyaltyscore>
        <Loyaltyperc color={renderScoreData?.average?.value >= renderScoreData?.target?.value ? "Fine":"DANGER"}>{renderScoreData?.average?.value}%</Loyaltyperc>
        <Ravg>Regional average</Ravg>
        <Targetscore>Target: {renderScoreData?.target?.value}%</Targetscore>
      </Loyaltyscore>
      <Loyaltyheader usedIn="header">
        {headerItem.map((item, index) => {
          return <Loyaltyheaderdata index={index}>{item}</Loyaltyheaderdata>;
        })}
      </Loyaltyheader>
      <LoyaltyMainContainer height={320}>
        {rmData?.map((itemData, dataindex) => {
          return (
            <>
              <Loyaltyheader
                key = {dataindex}
                bgColor={
                  props.selectedManagerName.toLowerCase() ===
                  itemData["rm_name"].toLowerCase()
                }
              >
                {headerItem.map((headerItem, headerIndex) => {
                  let key = returnRequiredHeader(headerItem);
                  return headerIndex === 0 ? (
                    <Loyaltyheaderdata usedFor="table" index={headerIndex}>
                      {dataindex + 1}{" "}
                      <LoyaltyRankingName>{capitalize(itemData[key])}
                      </LoyaltyRankingName>
                    </Loyaltyheaderdata>
                  ) : (
                    <Loyaltyheaderdata usedFor="table" index={headerIndex}>
                      {itemData[key]}%
                    </Loyaltyheaderdata>
                  );
                })}
              </Loyaltyheader>
            </>
          );
        })}
      </LoyaltyMainContainer>
      </OldDataBlur>
       {blur && (
           <Oldviewcustomersatisfaction id = "OldDataFullview">
           <OldLoyaltyBox id = "olddatacontent">
            <Oldtext id="old">You're viewing old data</Oldtext>
            <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
            <Oldtext id = "oldtext">
              Scheduled updates occurs on&nbsp;{props.loyaltyIssuanceData}
            </Oldtext>
            <Olddatatradingapprovee id = "olddataapprove" onClick={() => setBlur(!blur)}>
              continue with old data
            </Olddatatradingapprovee>
          </OldLoyaltyBox> 
         </Oldviewcustomersatisfaction>
          // <></>
         )}
    </LoyaltyBox>
  );
}

const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
    scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

const mapStateToProps = (state) => {
  const { scorecardInfo , scorecardImprovedInfo} = state;

  const { selectedManagerName ,kpiName, excelLoader} = scorecardInfo;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  const loyaltyIssuanceData = layout?.data?.schedules?.loyaltyIssuanceData;
  return {
    selectedManagerName, kpiName, excelLoader, loyaltyIssuanceData
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(Loyalty)
);


