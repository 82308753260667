/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/5/2023
*/

import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../actions/scorecardActions";
import { KPINames } from "../../../ScorecardImproved/ScorecardImprovedConstants";
import { TileHeader } from "../../Customer/Screens/Components/TileHeader";
import {
  Actualpbar,
  Exclaimation,
  ExclaimationText,
  Loyaltyheader,
  Loyaltyperc,
  Loyaltyscore,
  OldDataBlur,
  Oldtext,
  Oldviewcustomersatisfaction,
  Ravg,
  Scoreboxtitle,
  Targetscore,
  TooltipValue,
  Whitepbar
} from "../../Customer/style/Customer.styled";
import { Olddatatradingapprovee } from "../../TradingProfitably/Styles/TradingUi.styled";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import { titleGenerator } from "../../scorecardConstants";
import { defaultSortOrder, renderSortIcons, sortData } from "../../utils";
import {
  Clockingheadercell,
  Clockingspace,
  MandatoryTaskContainer,
  MandatoryTaskData,
  MandatoryTaskTable,
  Oldmandcontent
} from "../styles/SafetyUi.styled";
import { returnRequiredRegionalHeader } from "../utils/SafetyUtils";
function MandatoryTask(props) {
  const { data,  mandatoryTasksData } = props;
  const { toolTipText, title,state , expectedRefreshTimestamp, lastWeekUpdated, headers} = data;
  let rmData = data.data;
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
  const [blur, setBlur] = useState(props.weekNumber !== lastWeekUpdated);
  let headerValue = [...headers,"graph"]
  let headerNames = rmData?.length > 0 ? defaultSortOrder(rmData) : [];
  const [sortedData, setSortedData] = useState(rmData);
  const [sortState, setSortState] = useState(headerNames);
  const handleHover = (event, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  const giveProgress = (data) => {
    let result = data;
    let color;

    if (result < 50) {
      color = "#e64949";
    } else if (result > 50 && result < 70) {
      color = "#ffc000";
    } else {
      color = "#288f33";
    }
    return { color, result };
  };

  const sortItem = (keyName, sortState, sortedData) => {
    let result;
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    const rowsWithNA = rmData.filter((rows) => rows[keyName] === "NA");
    if (isAnyColumnSorted === "NONE") {
      const initialSortState = Object.keys(rmData[0]).map((item) => ({
        name: item,
        sortState: "NONE",
      }));
      result = sortData(
        keyName,
        initialSortState,
        rmData.filter((rows) => rows[keyName] !== "NA")
      );
    } else {
      result = sortData(
        keyName,
        sortState,
        sortedData.filter((rows) => rows[keyName] !== "NA")
      );
    }
    const currentState = result.sortedState.find((val) => val.name === keyName);
    setSortState(result.sortedState);
    setSortedData(
      currentState.sortState === "ASC"
        ? [...result.sortingData, ...rowsWithNA]
        : [...rowsWithNA, ...result.sortingData]
    );
  };

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
    } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };

  function loadExcel() {
    props.exportToExcel(titleGenerator[title], headers, rmData);
}

  return (
    <MandatoryTaskContainer>
       <OldDataBlur boolTrue={blur} id= "OldDataBlur">
      {title === 'Waste' || title === 'Till Losses' ? <>
    {
     props.weekNumber - 1 === 0 ?
     <>
     {!blur && 52 !== lastWeekUpdated && (
       <Exclaimation
         onMouseEnter={(e) => blurHover(e, "enter")}
         onMouseLeave={(e) => blurHover(e, "leave")}
         id="exclamationContainer"
       >
         <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
       </Exclaimation>
     )}
     </>
     :
     <>
     {!blur && props.weekNumber-1 !== lastWeekUpdated && (
       <Exclaimation
         onMouseEnter={(e) => blurHover(e, "enter")}
         onMouseLeave={(e) => blurHover(e, "leave")}
         id="exclamationContainer"
       >
         <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
       </Exclaimation>
     )}
     </>
    }
    </>:<>
    {!blur && props.weekNumber !== lastWeekUpdated && (
       <Exclaimation
         onMouseEnter={(e) => blurHover(e, "enter")}
         onMouseLeave={(e) => blurHover(e, "leave")}
         id="exclamationContainer"
       >
         <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
       </Exclaimation>
     )}
     </>}
     {oldPopUpVisible && (
       <TooltipValue
       id = "tooltipvalue"
         top = {blurRect.top + 70}
         left = {blurRect.left + 15}
       >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
     )}
      <TileHeader
        showPopup={showPopup}
        exportToExcel = {() => loadExcel()}
        excelLoader={props.excelLoader}
        kpiName = {props.kpiName}
        handleHover={(e, value) => handleHover(e, value)}
        title={title}
        state={state}
      />
      {showPopup && (
          <ScorePopupContainerWeb
          top={top}
          left={left-20}
          width = {18}
           id='popUpDesktop'
           textDescription = {toolTipText}
        />
      )}
      <Scoreboxtitle></Scoreboxtitle>
      <Loyaltyscore component="people">
        <Loyaltyperc>
          {`${rmData.filter(key => key.sites === "Total")[0]['completed']}%`}
         </Loyaltyperc>
        <Ravg>Regional average</Ravg>
        <Targetscore>
        </Targetscore>
      </Loyaltyscore>
      <MandatoryTaskTable>
      <Loyaltyheader usedIn="header">
        {data.headers.map((item, index) => {
          return (
            <Clockingheadercell 
            onClick={() =>
              sortItem(
                returnRequiredRegionalHeader(item),
                sortState,
                sortedData
              )
            }
             usedIn="header" index={index}>
              {item} 
              {
              renderSortIcons(
                returnRequiredRegionalHeader(item),
                sortState,
                true,
                state
              )}
            </Clockingheadercell>
          );
        })}
      </Loyaltyheader>
      <MandatoryTaskData height={192} usedin="mandatory">
        {sortedData.filter(key => key.sites !== "Total").map((itemData, dataindex) => {
          let calculateValues = giveProgress(itemData["completed"]);
          return (
            <>
              <Loyaltyheader key = {dataindex}>
                {headerValue.map((headerItem, headerIndex) => {
                  let key = returnRequiredRegionalHeader(headerItem);
                  return headerIndex === 0 ? (
                    <Clockingheadercell usedIn="table" index={headerIndex}>
                      <Clockingspace>{itemData[key]}</Clockingspace>
                    </Clockingheadercell>
                  ) : headerIndex === 1 ? (
                    <Clockingheadercell usedIn="table" index={headerIndex}>
                      {itemData[key]}%
                    </Clockingheadercell>
                  ) : (
                    <>
                      <Clockingheadercell index={headerIndex}>
                        <Whitepbar>
                          <Actualpbar
                            widthPercent={calculateValues.result}
                            color={calculateValues.color}
                          />
                        </Whitepbar>
                      </Clockingheadercell>
                    </>
                  );
                })}
              </Loyaltyheader>
            </>
          );
        })}
      </MandatoryTaskData>
      </MandatoryTaskTable>
     
      </OldDataBlur>
       {blur && (
           <Oldviewcustomersatisfaction id = "OldDataFullview">
               <Oldmandcontent id = "olddatacontent">
               <Oldtext id="old">You're viewing old data</Oldtext>
               <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates occurs on&nbsp;{props.layout?.data?.schedules?.mandatoryTasksData}
               </Oldtext>
              <Olddatatradingapprovee id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
               </Olddatatradingapprovee>
             </Oldmandcontent>  
           </Oldviewcustomersatisfaction>
         )}
    </MandatoryTaskContainer>
  );
}

const mapStateToProps = (state) => {
  const { scorecardInfo, loader, authInfo, scorecardImprovedInfo } = state;
  const { userData } = authInfo;
  const { showLoader } = loader;
  const { excelLoader, kpiName, siteNames, noSelected, selectedManagerName } =
    scorecardInfo;
    const layout = scorecardImprovedInfo[KPINames.LAYOUT];
   return {
    excelLoader,
    kpiName,
    showLoader,
    siteNames,
    noSelected,
    selectedManagerName,
    userData,
    layout
  };
};

const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(MandatoryTask)
);


