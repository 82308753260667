


import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { NotificationActions } from "../../actions/NotificationAction";
import { authActions } from "../../actions/authActions";
import { menuActions } from "../../actions/menuActions";
import { EVENT_LINK_VISIT, KEY_LINK_HEADER, KEY_LINK_NAME, KEY_LINK_URL, KEY_USER_ID, KEY_USER_TITLE, UNKNOWN_TITLE } from '../../constants/FireBaseEvents';
import { WEBSOCKET_URLS } from '../../constants/environmentConstants';
import isMobile from "../../helpers/isMobile";
import { store } from "../../helpers/store";
import bp_brand_logo from "../../images/bp_brand_logo.svg";
import i_icon_bp_menu from "../../images/icons/i_icon_bp_menu.svg";
import i_icon_hamburger from "../../images/icons/i_icon_hamburger.svg";
import i_icon_logout from "../../images/icons/i_icon_logout.svg";
import i_icon_logout_green from "../../images/icons/i_icon_logout_green.svg";
import i_icon_remove from "../../images/icons/i_icon_remove.svg";
import i_icon_right from "../../images/icons/i_icon_right.svg";
import i_icon_search_green from "../../images/icons/i_icon_search_green.svg";
import i_icon_support from "../../images/icons/i_icon_support.svg";
import i_icon_tail from "../../images/icons/i_icon_tail.svg";
import i_notification_green from "../../images/icons/i_notification_green.svg";
import i_notification_grey from "../../images/icons/i_notification_grey.svg";
import search from "../../images/icons/search.png";
import silver_cross from "../../images/icons/silver_cross.svg";
import Notification from "../Notification/Notification";
import { eventLogging, getRequiredInput } from "../Scorecard/utils";
import {
  AdditionalImages,
  AdditionalLinks,
  AdminBox,
  Applychange,
  BannerAdjustmenst,
  Blurbox,
  Circletext,
  Circleview,
  CrossMenu,
  DesktopsearchNo,
  DropdownBox,
  HeaderText,
  Iconbox,
  ImageLogo,
  ImageLogoMobile,
  ImageMenu,
  InputMenu,
  Label,
  LinkMobileName,
  LinkMobileWrapper,
  LinkWrapper,
  Linknamesearch,
  LinksList,
  LogoutBox,
  Logoutbox,
  MenuContainer,
  MenuImageContainer,
  Menubox,
  Menuboxtext,
  Menudesktop,
  Menuoptions,
  Perrowsearchresult,
  Resultfield,
  SearchBox,
  SearchBoxd, SearchContent,
  SearchFeild,
  SearchMenuContainer,
  Section,
  SupportBox
} from "./Page.styled";
let history 

export function PageHeader(props) {
  const [changeColor, setColorChange] = useState(false);
  const [colorSupport, setColorSupport] = useState(false);
  const [notification, setNotification] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [menuShow, setMenuShow] = useState(true);
  const [subMenu, showSubMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchViewAnimatedClass, setSearchViewClass] = useState("seachBoxAnimation");
  // const [desktopSearch, setDesktopSearch] = useState([]);
  const { t } = useTranslation();
  const { scoreData, isGoogleReviewPage } = props;
  let [ws, setWs] = useState({});
  const userLanguage = store.getState().authInfo?.userData?.countryCode;
  const languageCode = store.getState().authInfo?.languageCode;
  history = useHistory()
  const url = WEBSOCKET_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT).api_url;
  const selectedMenuSection = props?.menuData?.menu?.find(section => section.header === props.currentMenuItem)?.links;
  let allLinks = [];
  props?.menuData?.menu?.forEach(menuSection => {
    allLinks = [...allLinks, ...menuSection.links];
  });
  
  useEffect(() => {
    if (isEmpty(props.unseen) && isEmpty(props.seen)) {
     establish();
    } else if (performance.navigation.type === 1) {
     reEstablish();
    }
    else if(window.location.pathname==='/reviews/dashboard'){
      reEstablish();
    }
    return () => {
      try{
        ws.close();
      }catch(e){
        
      }
    };
  }, []);

  

  async function establish() {
    if (userLanguage === "PL") {
      let wss = {};
      wss = await new WebSocket(`${url}?auth=${props.id_token}`);
      setWs(wss);
    }
  }

  async function reEstablish() {
    let wss = {};
    wss = await new WebSocket(`${url}?auth=${props.id_token}&terminate-connection=${props.connectionId}`);
    setWs(wss);
  }
  
  ws.onmessage = function (e) {
    //to get data from server
    const json = JSON.parse(e.data);
    if (json.new !== undefined && (!isEmpty(json.new) || !isEmpty(json.viewed))) {
      let object = {};
      let unseen = [];
      let seen = [];
      unseen = json.new.map((item) => {
        return {
          ...item,
          seen: true,
        };
      });
      seen = json.viewed.map((item) => {
        return {
          ...item,
          seen: false,
        };
      });
      object = {
        new: unseen,
        viewed: seen,
      };
      props.savenotificationInfo(object);
      props.saveConnectionId(json.connectionId);
    } else if (json.type === "UPDATE") {
      let object = {};
      let unseen = [];
      let seen = [];
      unseen = json.notification.new.map((item) => {
        return {
          ...item,
          seen: true,
        };
      });
      seen = json.notification.viewed.map((item) => {
        return {
          ...item,
          seen: false,
        };
      });
      object = {
        new: unseen,
        viewed: seen,
      };
      props.savenotificationInfo(object);
    }
  };

  ws.onopen = () => {
    // for sending all updates done by user to server
    if ((!isEmpty(props.SeenData) || !isEmpty(props.UnSeenData)) && !props.showNotification) {
      let seen = [];
      let unseen = [];
      if (props.SeenData.length > 0 && props.SeenData !== undefined) {
        props.SeenData.forEach((item) => {
          seen.push(item.id);
        });
      }
     if (props.UnSeenData.length > 0 && props.UnSeenData !== undefined) {
        props.UnSeenData.forEach((item) => {
          unseen.push(item.id);
        });
      }
      const apiCall = {
        action: "changeStatus",
        unseen: unseen,
        seen: seen,
      };
      ws.send(JSON.stringify(apiCall));
      props.setSeenEmpty();
      props.setUnSeenEmpty();
    }
  };

  ws.onclose = () =>{
    establish();
  }

  const logout = () => {
    props.userLogout();
  };
  
  let notify = t("Notifications");
  
  

  function closeNotification(){
    // if(props.showNotification){
      props.changeShowNotification();
    // }
  }

  function handleMenuClick(header) {
    props.setActiveMenu(header);
  }

  function handleMobileMenu(header) {
    props.setActiveMenu(header);
    showSubMenu(true);
  }

  const gotoDashboard = (url) => {
    if (url === "/") {
      return;
    } else {
      window.open(url);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setSearchResults(allLinks?.filter(link => link?.name?.toLowerCase().includes(value.toLowerCase())));
  };

  const handleSeachClose = () => {
      setShowSearch(false);
      setSearchValue('');
      setSearchResults([]);
  }

  const checkSearchResults = (e) => {
    let searchData = [];
    setSearchValue(e.target.value);
    searchData = getRequiredInput(e.target.value, props.menuData?.menu);
     setSearchResults(searchData);
  }

  const logEventForLinkVisit = (linkcatured) => {
    // logged event
    eventLogging(EVENT_LINK_VISIT, linkcatured)
  }

  const eventObject = (data)=>{
    let linkcatured = {
      [KEY_USER_ID]:props?.id,
      [KEY_LINK_NAME]:data?.name,
      [KEY_LINK_URL]: data?.url || data?.link,
      [KEY_LINK_HEADER]: props?.currentMenuItem,
      [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase()
    }
    logEventForLinkVisit(linkcatured)
  }

  if (!isMobile()) {
    if(!props.userAdmin){
    return (
      <>
        <MenuContainer>
          <ImageMenu
                src={i_icon_bp_menu}
                className="d-inline-block align-top mr-2"
                alt="BP logo"
                loading="lazy"
                data-test="BPlogo"
            />
          <LinksList>
          {props.menuData?.menu?.map(section => {
            return (
              <LinkWrapper onClick={() => handleMenuClick(section?.header)}>
                <Section isSelected={props.currentMenuItem === section?.header}>{section?.header}</Section>
              </LinkWrapper>
            );
          })}
          </LinksList>
          <AdditionalLinks>
             {showSearch ? <SearchBoxd className={searchViewAnimatedClass}>
            <SearchContent search ={showSearch}>
                <Iconbox src={i_icon_search_green}/>
                <SearchFeild type={"text"} placeholder={"Search"} onChange ={(e) => checkSearchResults(e)}></SearchFeild>
                <Resultfield visiblity = {searchValue}>
                  {searchResults.length !== 0 ? <>
                     {searchResults.map((item) => {
                      return <Perrowsearchresult onClick={() => {
                        gotoDashboard(item?.link)
                        eventObject(item)
                      }
                      }>
                        <Linknamesearch id = "text">
                             {item.name}
                           </Linknamesearch >
                           <Linknamesearch >
                             {item.linkname}
                           </Linknamesearch >
                      </Perrowsearchresult>
                      })
                     }
                  </>:<>
                  <DesktopsearchNo>Sorry, we couldn't find the results -_-</DesktopsearchNo>
                  </>}
                </Resultfield> 
                <Iconbox id ='cross' src ={silver_cross} onClick ={() => {
                   setSearchViewClass("seachBoxAnimationRollIn")
                   setTimeout(()=>{
                    setSearchValue('');
                    setShowSearch(false)
                    setSearchViewClass("seachBoxAnimation")
                   },200)
                  }}/>
              </SearchContent>
           </SearchBoxd>:<SearchBox >
             {(languageCode  === 'uk' && props.channelOfTrade === "CO") ?<><AdditionalImages src={search} onClick={() => setShowSearch(true)}/>
              <Label onClick={() => setShowSearch(true)}>{t("Search")}</Label></> :<></>}
            </SearchBox>}
           {props.admin === "true" ? <><AdminBox onClick={() => history.push("/admin/dashboard")}>Admin</AdminBox></> :null }
            <SupportBox onClick={() =>(window.location ="mailto:GEoSSupport@bp.com?subject=Scorecard Performance Issue")}>
              <AdditionalImages src={i_icon_support} />
              <Label>{t("Support")}</Label>
            </SupportBox>

            {userLanguage === "PL" && (
                <Nav.Link>
                 <SupportBox>
                    <span onClick={() =>  closeNotification()}>
                      <Logoutbox>
                        {notification ? (
                          <AdditionalImages
                            src={i_notification_green}
                            alt="hovered_logout"
                          />
                        ) : (
                          <AdditionalImages src={i_notification_grey} alt="logout" />
                        )}
                        {props.unseen.length !== 0 &&
                        !props.showNotification ? (
                          <Circleview>
                            <Circletext>{props.unseen.length}</Circletext>
                          </Circleview>
                        ) : null}
                      </Logoutbox>
                    </span>
                  </SupportBox>
                </Nav.Link>
              )}
              {userLanguage === "PL" && (
                <Notification
                  closeNotification = {() => closeNotification()}
                  sendData={() => {
                    ws.onopen();
                  }}
                />
              )}
            <LogoutBox onClick={() => logout()}>
              <AdditionalImages src={i_icon_logout} />
              <Label>{t("Logout")}</Label>
            </LogoutBox>
          </AdditionalLinks>
        </MenuContainer>
      </>
    )}
    else{
      return (<Nav
        className="navbar sticky-top navbar-light bg-light shadow-sm justify-content-between px-0"
        id="navbar-primary"
        data-test="navHeader"
        style={{ zIndex: "500" }}
        onClick={() => props.hideView && props.onClick()}
      >
        <div className="adminHeader">
        <a className="navbar-brand d-flex align-items-center" style={{ width: "60%", marginLeft: "1em" }}>
            <ImageLogo
              isGoogleReviewPage={isGoogleReviewPage}
              src={bp_brand_logo}
              className="d-inline-block align-top mr-2  h2"
              alt="BP logo"
              loading="lazy"
              data-test="BPlogo"
            />
            <HeaderText admin={true} isGoogleReviewPage={false}>Landing Page - Admin</HeaderText>
            </a>
            <Nav className="header-navigations" style={{ width: "40%" }}>
              <Menudesktop id='admin'>
                <Applychange onClick={()=> history.push('/')}>Apply all changes</Applychange>
              </Menudesktop>
              </Nav>  
        </div>
        </Nav>);
    }
    ;
  } else {
    return (
      <Nav
        className="navbar sticky-top navbar-light bg-light shadow-sm justify-content-between px-0"
        id="navbar-primary"
        data-test="navHeader"
        style={{ zIndex: "2000" }}
        onClick={() => props.hideView && props.onClick()}
      >
        <BannerAdjustmenst>
          {menuShow && !showSearch && (
            <Menubox
              src={i_icon_hamburger}
              alt="menu"
              onClick={() => setMenuShow(!menuShow)}
            />
          )}
          {!menuShow && !showSearch && (
            <Menubox
              src={i_icon_remove}
              alt="menu"
              onClick={() => setMenuShow(!menuShow)}
            ></Menubox>
          )}
          {!menuShow && (
            <Blurbox>
              <Menuoptions>
                <DropdownBox>
                {searchValue?.length > 0 && 
                  searchResults?.map(link => {
                    return (
                      <LinkMobileWrapper onClick={() => {
                        
                        gotoDashboard(link?.url)
                        eventObject(link)

                      }
                      
                      }>
                        <LinkMobileName>{link?.name}</LinkMobileName>
                      </LinkMobileWrapper>
                    )
                  })
                }
                {subMenu && searchValue?.length === 0 && 
                <>
                  <LinkMobileWrapper isBack={true} onClick={() => showSubMenu(false)}>
                    <AdditionalImages src={i_icon_tail} usedFor="backArrow"/>
                    <Menuboxtext>{t("Back")}</Menuboxtext>
                  </LinkMobileWrapper>
                  <LinkMobileWrapper isHeading={true}>
                    <LinkMobileName>{props?.currentMenuItem}</LinkMobileName>
                  </LinkMobileWrapper>
                  {selectedMenuSection?.map(link => {
                    return (
                      <LinkMobileWrapper onClick={() => {
                        gotoDashboard(link?.url)
                        eventObject(link)
                      }
                      }>
                        <LinkMobileName>{link?.name}</LinkMobileName>
                      </LinkMobileWrapper>
                    )
                  })}
                </>}
                {!subMenu && searchValue?.length === 0 &&
                <>
                {props.menuData?.menu?.map(section => {
                  return (
                    <LinkMobileWrapper onClick={() => handleMobileMenu(section?.header)}>
                      <LinkMobileName>{section?.header}</LinkMobileName>
                      <MenuImageContainer>
                        <AdditionalImages src={i_icon_right} />
                      </MenuImageContainer>
                    </LinkMobileWrapper>
                  );
                })}
                  <LinkMobileWrapper onClick={() => (window.location = "mailto:GEoSSupport@bp.com?subject=Landing page issue")}>
                    <AdditionalImages src={i_icon_support} />
                    <Menuboxtext>{t("Support")}</Menuboxtext>
                  </LinkMobileWrapper>
                  <LinkMobileWrapper onClick={() => logout()}>
                    <AdditionalImages src={i_icon_logout} />
                    <Menuboxtext>{t("Logout")}</Menuboxtext>
                  </LinkMobileWrapper>
                </>}                
                </DropdownBox>
              </Menuoptions>
            </Blurbox>
          )}
          {!showSearch && 
            <ImageLogoMobile
              src={i_icon_bp_menu}
              className="d-inline-block align-top mr-2"
              alt="BP logo"
              loading="lazy"
              data-test="BPlogo"
            />
          }          
          {menuShow && !showSearch && (
            <Menubox
              src={i_icon_logout_green}
              alt="BP logo"
              loading="lazy"
              data-test="BPlogo"
              onClick={() => logout()}
          />)}
          {!menuShow && !showSearch && (
            <Menubox
              src={i_icon_search_green}
              alt="menu"
              onClick={() => setShowSearch(true)}
          />)}
          {showSearch && 
            <SearchMenuContainer>
              <Menubox
                id="menuBox"
                src={i_icon_search_green}
                alt="menu"
              />
              <InputMenu
                type="text"
                value={searchValue}
                placeholder='Search'
                onChange={(e) => handleSearch(e.target.value)}
              />
              <CrossMenu>
                <Menubox
                  src={i_icon_remove}
                  alt="menu"
                  onClick={() => handleSeachClose()}
                ></Menubox>
              </CrossMenu>
            </SearchMenuContainer>
          }
        </BannerAdjustmenst>
      </Nav>
    );
  }
}
function mapState(state) {
  const { authInfo, loader, googleAverageScoreInfo, NotificationInfo, menuInfo } = state;
  const { showLoader } = loader;
  const {showNotification,initialTimeStamp,ws,unseen,SeenData,UnSeenData,connectionId} = NotificationInfo;
  const { scoreData } = googleAverageScoreInfo;
  const { sessionData, userData } = authInfo;
  const { id_token } = sessionData;
  const { menuData, currentMenuItem } = menuInfo;
  const { channelOfTrade,admin, id} = userData ;
  return { authInfo, showLoader, scoreData, channelOfTrade,admin, showNotification, unseen, initialTimeStamp, id_token, ws, connectionId, SeenData, UnSeenData, menuData, currentMenuItem, id };
}
const actionCreators = {
  userLogout: authActions.userLogout,
  savenotificationInfo: NotificationActions.savenotificationInfo,
  savePulse: NotificationActions.savePulse,
  changeShowNotification: NotificationActions.changeShowNotification,
  setSeenEmpty: NotificationActions.setSeenEmpty,
  setUnSeenEmpty: NotificationActions.setUnSeenEmpty,
  clearInitialTimestamp: NotificationActions.clearInitialTimestamp,
  saveConnectionId: NotificationActions.saveConnectionId,
  setActiveMenu: menuActions.saveCurrentMenu
};

export default compose(connect(mapState, actionCreators))(PageHeader);
