/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 17/07/2022
* Author : Aditya Padmanabhan
*/



import React, { useState } from "react";
import thinking from './../../images/icons/thinking.svg'
import danger from './../../images/icons/danger.svg'
import { Mainbox, Textbox, ImageBox, Deleteuser, Noreverting, Buttons, Deletebutton, Declinebutton, Backgroundblur, Sadicon, Excelbox } from './AdminDashboard.styled'
import { ExcelSpin } from "../commonStyles/common.styled";

function Delete(props) {
  let [spin, setSpin] = useState(false);

  function deleteUser(){
    props.deleteMethod();
    setSpin(true)
  }
  return (
    <Backgroundblur>
      <Mainbox>
        <ImageBox>
         {props.userManager? <Sadicon src={thinking} alt='thinking' />:<Sadicon src={danger} alt='danger' />}
        </ImageBox>
        <Textbox>
         {props.userManager?<> <Deleteuser>Delete the user &nbsp;<b> {props.deleteItem[0].name}</b></Deleteuser></>:<Deleteuser>Delete the view&nbsp;<b>{props.view}</b></Deleteuser>}
          <Noreverting>This action cannot be undone.</Noreverting>
          <Buttons>
           {props.userManager?
           <><Deletebutton onClick={() => {
              deleteUser()
            }}><>yes, delete </></Deletebutton> {spin && <Excelbox><ExcelSpin usedFor='delete' color ={'green'}/></Excelbox> }
            <Declinebutton onClick={() => props.doNotDelete()}>No don't delete</Declinebutton></>
            :
            <>
            <Deletebutton onClick={() => {
              props.doNotDelete()
            }}>No don't delete</Deletebutton>
            <Declinebutton onClick={() =>  deleteUser()}>
            yes, delete 
              </Declinebutton> 
          </>}
          </Buttons>
        </Textbox>
      </Mainbox></Backgroundblur>
  )
}

 export default Delete

