/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 22/4/2022
 * Author : Aditya Padmanabhan
 */


import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { Container,SpincontainingMessage,MessageText,MessageText2 } from "./EMaintenance.styled";
import { Spin } from "../commonStyles/common.styled";
import { COLOR_CODES } from "../GoogleReviewDashboard/ReviewsDashboard.constants";
import isMobile from '../../helpers/isMobile';

function EmaintenaceLoading(props) {
  const { t } = useTranslation();
  let counter = 0;
  const [color, setColor] = useState("");
  useEffect(() => {
    const loader = setInterval(colorChanger, 100);
    return () =>{
      clearInterval(loader)
    }
  }, []);
  const colorChanger = () => {
      if (counter > 2) {
        counter = 0;
        setColor(COLOR_CODES[counter])
        counter += 1;
      }
      else {
        setColor(COLOR_CODES[counter])
        counter += 1;
      }
  }
  return (
      <Container>
        <SpincontainingMessage>
           { props.id==='e-maintenance'? <> <MessageText>{t("Please wait...Your eMaintenance data is loading")}</MessageText>
            <MessageText2>{t("This may take up to 30 seconds")}</MessageText2></>: null}
            <Spin  device= {isMobile()&&props.id!=='e-maintenance'} col={color}/>
        </SpincontainingMessage>
      </Container>
    
  )
}

export default EmaintenaceLoading