import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import ClockingOnTimeComponent from "./KPI/ClockingOnTime";
import PeopleOverviewComponent from "./KPI/PeopleOverview";
import UnpublishedScheduleComponent from "./KPI/UnpublishedSchedule";

function PeopleSection() {
    return (
        <>
            <Row>
                <Col xs={12} lg={8}>
                    <PeopleOverviewComponent />
                </Col>
                <Col>
                    <ClockingOnTimeComponent />
                    <UnpublishedScheduleComponent />
                </Col>
            </Row>

        </>
    );
}

function mapStateToProps(state) {
}

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    PeopleSection
);
