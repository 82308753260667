import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    ReviewContainer, HeaderView, EmptyText, ReviewFilter,
    FilterButton, EmptyList, TableBodyContainer,HeaderViewMobile,
    Container, HeadingContainer, HeadingKey, HeadingValue,
    ReviewsContainer, Reviews, SiteName, RatingsContainer,
    Rating, Date, CustomerReview, ResponseContainer, ResponseHeading,
    ResponseDate, Response, ReviewsHeading, ImageContainer, Image,
    FilterHeading, FilterContainer, SelectionContainer, ResponseHead
} from './TableReview.styled';
import { averageScoreActions } from "../../actions/GoogleAverageScoreAction";
import { compose } from 'redux';
import search from "../../images/icons/search.png";
import { SORT } from '../Table/Table.constants';
import Search from '../search/search.js'
import { fetchListObjectMatch, searchMethodFromKey } from '../filter/utils';
import SiteRankingTable from '../Datatable/Datatable';
import { withTranslation } from 'react-i18next';
import SiteRankingRow from '../SiteRankingRow/SiteRankingRow';
import SelectionButton from '../button/selectionButton';
import { ChecklistSelection } from '../button/checklistSelection';
import RadioButton from '../button/radioButton';
import isMobile from '../../helpers/isMobile';
import { ratingFields } from '../../constants/googlereviewsdata';
import i_star_black from '../../images/icons/i_star_black.svg';
import i_star_filled from '../../images/icons/i_star_filled.svg';

let tableDetails = {}
export class TableReview extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            filterQuery: '',
            tableDetails: {},
            prevTableResponse: {},
            searchText: '',
            regionalManager: 'ALL',
            siteName: 'ALL',
            loader: true,
            tableReviewFilter: 'YEAR',
            sortState: {
                key: null,
                order: 'DESC'
            },
            selectedStar: [0, 1, 2, 3, 4, 5],
            reviewArray: [{
                key: 'All reviews',
                title: t('All reviews'),
            }, {
                key: 'Reviews without responses',
                title: t('Reviews without responses'),
            }, {
                key: 'Only reviews with responses',
                title: t('Only reviews with responses'),
            },
            ],
            selectedReview: {
                key: 'All reviews',
                title: t('All reviews'),
            },
            openStarSelection: false,
            openReviewSelection: false,
            starArray: ['All', 1, 2, 3, 4, 5],
            headerList: [
                {
                    index: 0,
                    width: '20px',
                    textAlign: 'left',
                    key: 'Site name',
                    title: t('Site name'),
                },
                {
                    index: 1,
                    width: '20px',
                    sortable: true,
                    textAlign: 'center',
                    key: "Date",
                    title: t("Date"),
                },
                {
                    index: 2,
                    width: '20px',
                    textAlign: 'center',
                    key: "Rating",
                    title: t("Rating"),
                },
                {
                    index: 3,
                    width: '120px',
                    textAlign: 'left',
                    key: "Comments",
                    title: t("Comments"),
                }
            ],
            showSearch: true
        }
    }


    tableApiCall(filterQuery) {
        this.props.getTableReviewData(filterQuery).then(() => {
            tableDetails = this.props.tableReviewDetails
            this.setState({
                tableDetails: this.props.tableReviewDetails,
                prevTableResponse: this.props.tableReviewDetails, loader: false
            })
        })
    }

    componentDidMount() {
        this.setState({ filterQuery: this.props.filterQuery, loader: true, searchText: '' })
        if (this.props.filterQuery.length > 0 && this.props.filterObject.regionalManager) {
            this.tableApiCall(this.props.filterQuery)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filterQuery !== this.props.filterQuery) {
            const { sortState } = this.state
            const order = SORT.DESCENDING;
            this.setState({ filterQuery: this.props.filterQuery, sortState: { ...sortState, order }, selectedStar: [0, 1, 2, 3, 4, 5], searchText: '' })
            if (this.props.filterQuery.length > 0 && this.props.filterObject.regionalManager) {
                this.setState({ loader: true })
                this.tableApiCall(this.props.filterQuery)
            }
        } else if (prevProps.tableReviewFilter !== this.props.tableReviewFilter) {
            this.setState({
                openStarSelection: false,
                searchText: '',
                loader: true,
                selectedStar: [0, 1, 2, 3, 4, 5],
                tableDetails: tableDetails || {},
                prevTableResponse: tableDetails || {},
            }, () => this.setState({ loader: false }))
        }
    }

    async starFilter(starResponse) {
        const { tableReviewFilter } = this.props;
        if (starResponse[0] === 0) {
            this.setState({ tableDetails: this.state.prevTableResponse, searchText: '' })
        } else {
            var value = await fetchListObjectMatch(this.state.prevTableResponse, starResponse, tableReviewFilter.toLowerCase())
            this.setState({ tableDetails: value, searchText: '' })
        }
    }

    async handleChange(e) {
        const { tableReviewFilter } = this.props;
        this.setState({ searchText: e, selectedStar: [0, 1, 2, 3, 4, 5], openStarSelection: false, })
        if (e.length > 3) {
            var value = await searchMethodFromKey(this.state.prevTableResponse, e, tableReviewFilter.toLowerCase())
            this.setState({ tableDetails: value, })
        } else {
            this.setState({ tableDetails: this.state.prevTableResponse })
        }
    }

    selectedStarMethod(item, index) {
        let selectedItem = this.state.selectedStar
        let searchStar = this.state.selectedStar.indexOf(index)
        if (this.state.selectedStar.length === 6 && index === 0) {
            selectedItem = []
        } else if (this.state.selectedStar.length === 5 && !this.state.selectedStar.includes(0)) {
            selectedItem = [0, 1, 2, 3, 4, 5]
        }
        else if (index === 0) {
            selectedItem = [0, 1, 2, 3, 4, 5]
        } else {
            if (searchStar === -1) {
                selectedItem.push(index)
            } else {
                selectedItem.splice(searchStar, 1)
            }
            let allSelected = this.state.selectedStar.indexOf(0)
            if (allSelected !== -1) {
                selectedItem.splice(allSelected, 1)
            } else if (selectedItem.length === 5) {
                selectedItem = [0, 1, 2, 3, 4, 5]
            }
        }
        !this.state.loader && this.setState({
            selectedStar: selectedItem
        }, () => {
            this.starFilter(this.state.selectedStar)
        })
    }

    tableDesign(key, index, showResponse) {
        const { t } = this.props;
        return (<>
            {this.cellMethod(key.siteName, index, key, t("Customer Review:"))}
            {showResponse && key?.comments.map((value, indexComment) => {
                return this.cellMethod(key.siteName, indexComment, value, t("Response"))
            })}
        </>
        )
    }

    renderReviews(key, index, showResponse) {
        const { t } = this.props;
        return (<>
            <Reviews key={index}>
                <SiteName>{key.siteName}</SiteName>
                <RatingsContainer>
                    <Rating>
                        {ratingFields.map((value, i) =>
                            <Image key={i} src={value <= key.rating ? i_star_filled : i_star_black} alt='star' />
                        )}
                    </Rating>
                    <Date>{key.dateCreated}</Date>
                </RatingsContainer>
                <CustomerReview>{key.review}</CustomerReview>
                {showResponse && key?.comments.map(value =>
                    <ResponseContainer>
                        <ResponseHead>
                            <ResponseHeading>{t('Table Response')}</ResponseHeading>
                            <ResponseDate>{value.dateCreated}</ResponseDate>
                        </ResponseHead>
                        <Response>{value.data}</Response>
                    </ResponseContainer>
                )}
            </Reviews>
        </>
        )
    }

    tableReviewUI(key, index) {
        switch (this.state.selectedReview.key) {
            case 'All reviews':
                return this.tableDesign(key, index, true)
            case 'Reviews without responses':
                return key.comments.length === 0 &&
                    this.tableDesign(key, index, false)
            case 'Only reviews with responses':
                return key.comments.length > 0 &&
                    this.tableDesign(key, index, true)
            default:
        }
    }

    tableReviewMobile(key, index) {
        switch (this.state.selectedReview.key) {
            case 'All reviews':
                return this.renderReviews(key, index, true)
            case 'Reviews without responses':
                return key.comments.length === 0 &&
                    this.renderReviews(key, index, false)
            case 'Only reviews with responses':
                return key.comments.length > 0 &&
                    this.renderReviews(key, index, true)
            default:
        }
    }

    cellMethod(siteName, index, key, review) {
        const { t, tableReviewFilter } = this.props;
        return (<SiteRankingRow
            tableId="ReviewTable"
            key={siteName}
            id={index}
            filterSelection={tableReviewFilter.toLowerCase()}
            siteDetail={key}
            languageKey={t}
            review={review}
            headings={this.state.headerList}
        />)
    }


    sortingMethod() {
        // const { tableReviewFilter } = this.props
        this.setState({ loader: true })
        // let tableReviewFiltr = tableReviewFilter.toLowerCase()
        const { sortState } = this.state
        const order = sortState.order === SORT.DESCENDING ? SORT.ASCENDING : SORT.DESCENDING;
        this.setState({
            sortState: { ...sortState, order }, tableDetails: {
                'month': this.state.tableDetails["month"].reverse(),
                'week': this.state.tableDetails["week"].reverse(),
                'year': this.state.tableDetails["year"].reverse()
            }, loader: false
        })
    }

    reviewSelection(item, index) {
        this.setState({ selectedReview: item })
    }

    emptyListMethod(text, id) {
        return (
            <EmptyList >
                <EmptyText id={id}>{text}</EmptyText>
            </EmptyList>
        )
    }

    render() {
        const { t, tableReviewFilter } = this.props;
        const { showSearch } = this.state;
        if (isMobile()) {
            return (
                <Container>
                    <HeadingContainer>
                        {showSearch &&
                            <ReviewsHeading>
                                <HeaderViewMobile>
                                <HeadingKey>{t("Table reviews")}</HeadingKey>
                                <HeadingValue>{t("review filter value")}</HeadingValue>
                                </HeaderViewMobile>
                                <>
                                <ImageContainer onClick={() => this.setState({ showSearch: false })}><img src={search} alt='search' /></ImageContainer>
                                </>
                            </ReviewsHeading>
                        }
                        {!showSearch && <Search isReviewsSection={true} isMobile={true} data-test="search-container" value={this.state.searchText} placeholder={t("Search Comments")}
                            onChange={(e) => { !this.state.loader ? this.handleChange(e.target.value) : console.log('') }}
                            onClick={() => {
                                this.setState({ searchText: '', showSearch: true })
                                this.handleChange('')
                            }}
                        />
                        }
                    </HeadingContainer>
                    <FilterContainer isMobile={true}>
                        <FilterHeading>{t("Filter By")}</FilterHeading>
                        <SelectionContainer>
                            <div>
                                <SelectionButton
                                    onClick={() => {
                                        this.setState({
                                            openStarSelection: !this.state.openStarSelection,
                                            openReviewSelection: false
                                        })
                                    }}
                                    buttonText={t("Star rating")}
                                    width={107}
                                    openDropDown={this.state.openStarSelection}
                                />
                                {this.state.openStarSelection && <ChecklistSelection
                                    arrayList={this.state.starArray}
                                    width={107}
                                    selectedMethod={(item, index) => this.selectedStarMethod(item, index)}
                                    selectedValue={this.state.selectedStar}
                                />}
                            </div>
                            <ReviewFilter>
                                <SelectionButton
                                    onClick={() => {
                                        this.setState({
                                            openReviewSelection: !this.state.openReviewSelection,
                                            openStarSelection: false
                                        })
                                    }}
                                    width={178}
                                    buttonText={this.state.selectedReview.title}
                                    openDropDown={this.state.openReviewSelection}
                                />
                                {this.state.openReviewSelection && <RadioButton
                                    arrayList={this.state.reviewArray}
                                    width={178}
                                    selectedValue={this.state.selectedReview}
                                    onClick={(item, index) => this.reviewSelection(item, index)}
                                />}
                            </ReviewFilter>
                        </SelectionContainer>
                    </FilterContainer>
                    <ReviewsContainer id="review-scroll">
                        {
                            this.props.filterObject.regionalManager && this.props.filterQuery.length !== 0 ?
                                this.state.tableDetails[tableReviewFilter.toLowerCase()]?.length > 0 ?
                                    this.state.tableDetails[tableReviewFilter.toLowerCase()].map((key, index) => {
                                        return this.tableReviewMobile(key, index)
                                    })
                                    :
                                    this.emptyListMethod(t('No data'), false) :
                                this.emptyListMethod(t('To show the Google reviews component'), 'link')
                        }
                    </ReviewsContainer>
                </Container>
            );
        }
        return (
            <ReviewContainer data-test="review-table-container">
                <HeaderView data-test="review-star-container">
                    <FilterButton>
                        <div>
                            <SelectionButton
                                onClick={() => {
                                    this.setState({
                                        openStarSelection: !this.state.openStarSelection,
                                        openReviewSelection: false
                                    })
                                }}
                                buttonText={t("Star rating")}
                                width={120}
                                openDropDown={this.state.openStarSelection}
                            />
                            {this.state.openStarSelection && <ChecklistSelection
                                arrayList={this.state.starArray}
                                width={120}
                                selectedMethod={(item, index) => this.selectedStarMethod(item, index)}
                                selectedValue={this.state.selectedStar}
                            />}
                        </div>
                        <ReviewFilter>
                            <SelectionButton
                                onClick={() => {
                                    this.setState({
                                        openReviewSelection: !this.state.openReviewSelection,
                                        openStarSelection: false
                                    })
                                }}
                                width={220}
                                buttonText={this.state.selectedReview.title}
                                openDropDown={this.state.openReviewSelection}
                            />
                            {this.state.openReviewSelection && <RadioButton
                                arrayList={this.state.reviewArray}
                                width={220}
                                selectedValue={this.state.selectedReview}
                                onClick={(item, index) => this.reviewSelection(item, index)}
                            />}
                        </ReviewFilter>
                    </FilterButton>
                    <Search data-test="search-container" value={this.state.searchText} placeholder={t("Search Comments")}
                        onChange={(e) => { !this.state.loader ? this.handleChange(e.target.value) : console.log('') }}
                        onClick={() => {
                            this.setState({ searchText: '' })
                            this.handleChange('')
                        }}
                    />
                </HeaderView>
                <TableBodyContainer data-test="title-container">
                    {this.props.filterObject.regionalManager ? !this.state.loader && <SiteRankingTable
                        tableId="ReviewTable"
                        noResultsEl='No Reviews Available'
                        isLayoutFixed
                        headings={this.state.headerList}
                        sortState={this.state.sortState}
                        getData={() => console.log('data')}
                        onSort={(key, sortState) => this.sortingMethod()}
                    >
                        {
                            this.state.tableDetails[tableReviewFilter.toLowerCase()]?.length > 0 ?
                                this.state.tableDetails[tableReviewFilter.toLowerCase()].map((key, index) => {
                                    return this.tableReviewUI(key, index)
                                })
                                :
                                this.emptyListMethod(t('No data'), false)
                        }
                    </SiteRankingTable> :
                        !this.state.loader && this.emptyListMethod(t('To show the Google reviews component'), 'link')
                    }
                </TableBodyContainer>
            </ReviewContainer>
        )
    }

}
function mapStateToProps(state) {
    const { googleAverageScoreInfo } = state;
    const { tableReviewDetails } = googleAverageScoreInfo;
    return { tableReviewDetails };
}

const mapDispatchToProps = {
    getTableReviewData: (key) => averageScoreActions.getTableReview(key)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(TableReview));
