/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 31/12/2021
 * Author : Aditya Padmanabhan
 */

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { eMaintenanceActions } from "../../actions/eMaintenanceAction";
import {
  Container,
  TotalOpenTicket,
  ProgressContainer,
  TicketSubTitle,
  CircleView,
  TicketSubText,
  TicketScrollView,
  TicketContainer,
  TicketText,
  TicketView,
  ContainerType,
  DisFlex
} from "./EMaintenance.styled";
import ProgressBar from "./../chartnew/Bars";
import SubTaskView from "./SubTaskView";
import { compose } from "redux";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Overdue from "./Overdue";
import OnTime from "./OnTime";
import EmaintenanceError from "./EmaintenanceError";
import EmaintenaceLoading from "./EmaintenaceLoading";
const ACTIVE = 'active'
const CLOSED = 'Overdue'
function Emaintanence(props) {
  const { t } = useTranslation();
  useEffect(() => {
    if (isEmpty(props.maintenanceData) && props.isEmaintenanceLoading) {
      props.getEmaintananceData();
    }
  }, [props, props.maintenanceData]);


  
  function ticketTextView(taskType) {
    const { overdueTasks, onTimeTasks, totalCount } = props.maintenanceData;
   return (
      <>
        <TicketView isCoManager={props.isCoManager}>
         <TicketSubTitle>
            <DisFlex id='top-indicator' isCoManager={props.isCoManager}>
            <CircleView isCoManager={props.isCoManager} taskType={taskType} type="titleText">
            { taskType === 'open' ? totalCount.onTime : totalCount.overdue }
            </CircleView>
            <TicketSubText taskType={taskType} type="titleText">
            { taskType === 'open' ? t('On time') : t('Overdue') }
            </TicketSubText></DisFlex>
          </TicketSubTitle>
        </TicketView>
        <TicketScrollView isCoManager={props.isCoManager} id="scrollbar-thin">
          <TicketSubTitle className={taskType}>
          {taskType === 'open' && totalCount.onTime === 0 && <OnTime/>}
          {taskType === 'overdue' && totalCount.overdue === 0 && <Overdue/>}
          {taskType === "open" ? 
             onTimeTasks.map((site) => (
                <>
                  <SubTaskView
                    site={site}
                    task="open"
                    isCoManager={props.isCoManager}
                  />
                </>
              )) : overdueTasks.map((site) => (
                <>
                  <SubTaskView
                    site={site}
                    task="overdue"
                    isCoManager={props.isCoManager}
                  />
                </>
              ))
            }
          </TicketSubTitle>
        </TicketScrollView>
      </>
    );
  }
  if (!isEmpty(props.maintenanceData)) {
    const { totalCount } = props.maintenanceData;
    return (
      <Container isCoManager={props.isCoManager}>
        <ProgressContainer isCoManager={props.isCoManager}>
          <TotalOpenTicket isCoManager={props.isCoManager}>
            {/* <TicketText boldText={true}>{totalCount.open} </TicketText>
            <TicketText>{props.isCoManager ? t("Open_Tickets_CO") : t("Open Tickets")}</TicketText> */}
          </TotalOpenTicket>
          <ProgressBar isCoManager={props.isCoManager} completed={totalCount.onTimePercent} />
        </ProgressContainer>
        <TicketContainer isCoManager={props.isCoManager}>
          <ContainerType>
            {ticketTextView("open")}
          </ContainerType>
          <ContainerType>
            {ticketTextView("overdue")}
          </ContainerType>
        </TicketContainer>
      </Container>
    );
  } else {
    return(props.isEmaintenanceLoading ? <EmaintenaceLoading id='e-maintenance'/>  : <EmaintenanceError loadData = {() => props.getEmaintananceData()}/>);
  }
}

function mapStateToProps(state) {
  const { eMaintenanceInfo } = state;
  const { maintenanceData, isEmaintenanceLoading } = eMaintenanceInfo;
  return { maintenanceData, isEmaintenanceLoading};
}

const mapDispatchToProps = {
  getEmaintananceData: eMaintenanceActions.getEmaintananceData,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Emaintanence
);
