import React, { useState } from "react";
import { Card, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { getAnalytics, logEvent } from "firebase/analytics";
import financials from "../../images/financials.jpg";
import client from "../../images/client.jpg";
import site_ranking from "../../images/site_ranking.jpg";
import salesforce from "../../images/salesforce.jpg";
import hsse from "../../images/hsse.jpg";
import operations from "../../images/operations.jpg";
import i_financials from "../../images/icons/i_financials.svg";
import i_customer_insights from "../../images/icons/i_customer.svg";
import i_siteranking from "../../images/icons/i_siteranking.svg";
import i_globalrecognition from "../../images/icons/i_globalrecognition.svg";
import i_hsse from "../../images/icons/i_hsse.svg";
import { linkName } from "../../constants/googlereviewsdata"
import i_operations from "../../images/icons/i_operations.svg";
import i_salesforce from "../../images/icons/i_salesforce.svg";
import Map from "../map/Map";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import cross_icon from "../../images/icons/cross_icon.svg";
import { LinkText, LessLinksGrid, MoreLinksGrid, GridItem, Container, Maintext, AdditionalText, CardPopup } from "./Card.styled";
import { store } from '../../helpers/store';
import Emaintanence from "../E-Maintenance/Emaintanence";
import FinancialWidget from "../FinancialWidget/FinancialWidget";
import i_icon from "../../images/icons/i_icon.svg";
import firebaseConfig from '../../constants/firebaseConfig';
import isMobile from "../../helpers/isMobile";
import people from "../../images/people.jpg";




export function CardComponent({ ...props }) {
  const max = store.getState().kpiInfo?.max;
  const [showPopup, setShowPopup] = useState(false);
  const [showFinancePopup, setShowFinancePopup] = useState(false);
  const { t } = useTranslation();
  const languageCode = store.getState().authInfo?.languageCode;
  
  const analytics = !isEmpty(firebaseConfig(languageCode)) ? getAnalytics() : '';
  
  const gotoDashboard = (url, name) => {
    if (url === "/") {
      return;
    } else {
      window.open(url);
    }
    if (url !== '/' && !isEmpty(firebaseConfig(languageCode))) {
      logEvent(analytics, `${name} link visited`);
    }
  };
  const userLanguage = store.getState().authInfo?.userData?.countryCode;

  const popupView = () => {
    return (
      <div className="popup-view">
        <div className="arrow-up"></div>
        <div className="popup-body">
          <span className="What-you-can-expect">
            {props.data?.tooltipText?.title}
          </span>
          <br />
          <span className="Lorem-ipsum-dolor-si">
            {props.data?.tooltipText?.body}
          </span>
        </div>
      </div>
    );
  };

  const renderKpiComponents = (graphName, kpiImage) => {
    if ((graphName === 'operations') && (props.channelOfTrade !== 'CO' || languageCode !== 'uk')) {
      return (<Emaintanence className="image-cover" isCoManager= {false}/>);
    } else if (graphName === 'financials' && props?.financialData?.isCoco && store.getState().authInfo?.languageCode === 'uk' && process.env.REACT_APP_ENVIRONMENT !== 'uat' && process.env.REACT_APP_ENVIRONMENT !== 'prod') {
      return (<FinancialWidget className="image-cover" />);
    }
    return (<Card.Img variant="top" src={kpiImage} class="image-cover" />);
  };

  let kpiImage;
  let kpiIcon;
  let kpiName = props.data.kpiName;
  let graphName = props.data.graphName;
 
  switch (graphName.toLowerCase().replace("_", " ")) {
    case "operations":
      kpiImage = operations;
      kpiIcon = i_operations;
      break;
    case "hsse":
      kpiImage = hsse;
      kpiIcon = i_hsse;
      break;
    case "people":
      kpiImage = people;
      kpiIcon = i_globalrecognition;
      break;
    case "financials":
      kpiImage = financials;
      kpiIcon = i_financials;
      break;
    case "client":
      kpiImage = client;
      kpiIcon = i_customer_insights;
      break;
    case "site ranking":
      kpiIcon = i_siteranking;
      kpiImage = site_ranking;
      return (
        <Col>
          <Card className="data-card" data-test="kpi-card">
            <Card.Body>
              <Card.Title className="card-top-title">
                <div className="left-card-view">
                  <img src={kpiIcon} alt="sdfas" className="icon-class" />
                  <span className="card-title">{kpiName}</span>
                </div>

                <div
                  className="right-card-view"
                  onClick={() => {
                    isMobile() && showPopup === props.id ? setShowPopup(false) : setShowPopup(props.id)
                  }}
                  onMouseOver={(e) => !isMobile() && props.id !== showPopup ? setShowPopup(props.id) : setShowPopup(false)}
                  onMouseOut={(e) => setShowPopup(false)}
                >
                 <img className="cross-class" src={isMobile() && showPopup === props.id ? cross_icon : i_icon} alt='cross' />
                </div>
              </Card.Title>
            </Card.Body>
            {userLanguage === 'PL' ?
              <Card.Img variant="top" src={kpiImage} class="image-cover" /> : <Map className="image-cover" />}
            <Card.Body>
              {max < 3 ? <><LessLinksGrid linkNumber={props.data.dashboardUrl.length}>

                {props.data.dashboardUrl.map((link) => {
                  const { url, name } = link;
                  return (
                    <GridItem 
                      className={
                        url === "/" ? "pointer-not-allow" : "pointer-hand"
                      }>
                      <Container id="next" mobile={isMobile()} onClick={() => gotoDashboard(url, name)}>
                        <LinkText id="color">{url === "/" ? t("Coming Soon") : name}</LinkText>
                      </Container>
                    </GridItem>
                  );
                })}
              </LessLinksGrid></> : <><MoreLinksGrid linkNumber={props.data.dashboardUrl.length}>

                {props.data.dashboardUrl.map((link) => {
                  const { url, name } = link;
                  return (
                    <GridItem 
                      className={
                        url === "/" ? "pointer-not-allow" : "pointer-hand"
                      }>
                      <Container id="next" mobile={isMobile()} onClick={() => gotoDashboard(url, name)}>
                        <LinkText id="color">{url === "/" ? t("Coming Soon") : name}</LinkText>
                      </Container>
                    </GridItem>
                  );
                })}
              </MoreLinksGrid></>}
            </Card.Body>
            {showPopup === props.id && popupView()}
          </Card>
        </Col>
      );
    case "salesforce":
      kpiImage = salesforce;
      kpiIcon = i_salesforce;
      break;
    default:
      kpiImage = null;
      kpiIcon = null;
      kpiName = null;
  }
  return (
    <Col>
      <Card className="data-card" data-test="kpi-card" >
        <Card.Body>
          <Card.Title className="card-top-title">
            <div className="left-card-view">
              <img src={kpiIcon} alt="sdfas" className="icon-class" />
              <span className="card-title">{kpiName}</span>
            </div>
            <div
              className="right-card-view"
              onClick={() => {
                isMobile() && (showPopup === props.id ? setShowPopup(false) : setShowPopup(props.id))
              }}
              onMouseOver={(e) => !isMobile() && props.id !== showPopup ? setShowPopup(props.id) : setShowPopup(false)}
              onMouseOut={(e) => setShowPopup(false)}
            >
              <img className="cross-class" src={isMobile() && showPopup === props.id ? cross_icon : i_icon} alt='cross' />
            </div>
          </Card.Title>
        </Card.Body>
        {renderKpiComponents(graphName, kpiImage)}
        <Card.Body >
          {max < 3 ? <><LessLinksGrid linkNumber={props.data.dashboardUrl.length}>
            {props.data.dashboardUrl.map((link, index) => {
              const { url, name } = link;
              if (index > 4) {
                return (
                  <GridItem key={index} 
                    className={
                      url === "/" ? "pointer-not-allow" : "pointer-hand"
                    }>
                    <Container id="next" mobile={isMobile()} onClick={() => gotoDashboard(url, name)}>
                      <LinkText id="color">{url === "/" ? t("Coming Soon") : t("more")}</LinkText>
                    </Container>
                  </GridItem>
                );
              } else {
                return (
                  <GridItem key={index} 
                    className={
                      url === "/" ? "pointer-not-allow" : "pointer-hand"
                    }>
                    <Container id="next" mobile={isMobile()} onClick={() => gotoDashboard(url, name)}>
                      <LinkText id="color">{url === "/" ? t("Coming Soon") : name}</LinkText>
                    </Container>
                  </GridItem>
                );
              }
            })}
          </LessLinksGrid></> : <><MoreLinksGrid linkNumber={props.data.dashboardUrl.length}>
            {props.data.dashboardUrl.map((link, index) => {
              const { url, name } = link;
                if (index > 4) {
                  return (
                    <GridItem 
                      className={
                        url === "/" ? "pointer-not-allow" : "pointer-hand"
                      }>
                      <Container id="next" mobile={isMobile()} onClick={() => gotoDashboard(url, name)}>
                        <LinkText id="color">{url === "/" ? t("Coming Soon") : t("more")}</LinkText>
                      </Container>
                    </GridItem>
                  );
                } else {
                  return (
                    <GridItem 
                      className={
                        url === "/" ? "pointer-not-allow" : "pointer-hand"
                      }>
                      <Container id="next" mobile={isMobile()} onClick={() => gotoDashboard(url, name)}>
                        {name === 'Wyniki finansowe' ?
                          <>
                            <LinkText onMouseEnter={() => setShowFinancePopup(true)} onMouseLeave={() => setShowFinancePopup(false)} onClick={() => gotoDashboard(url, name)}>
                              <Maintext>Już wkrótce</Maintext>
                              <AdditionalText>{`(${name})`}</AdditionalText>
                              <CardPopup showPopup={showFinancePopup}>
                                <span>{t("finance_popup_text")}</span>
                              </CardPopup>
                            </LinkText>
                          </> : (name === linkName && userLanguage === "es") ? <>
                           <LinkText>
                           <Maintext>Muy pronto</Maintext>
                           <AdditionalText>{`(${name})`}</AdditionalText>
                           </LinkText>
                          
                          </>:
                          <>
                            <LinkText id="color">{name}</LinkText>
                          </>
                        }
                      </Container>
                    </GridItem>
                  );
                }
            })}
          </MoreLinksGrid></>}

        </Card.Body>
        {showPopup === props.id && popupView()}
      </Card>
    </Col>
  );
}

export default compose(withRouter)(CardComponent);
