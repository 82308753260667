import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { PopupContainer,TitleContainer,Infoicon,TitleName, TitleDescription } from "./style/Common.styled";
import info_scorecard from '../TradingProfitably/Assets/info_scorecard.svg'

export const ScorePopupContainerWeb = (props)=>{
    const { t } = useTranslation();
    const {textDescription, top, left, width} = props

    return(
        <PopupContainer left = {left} width = {width} top = {top}>
            <TitleContainer>
            <Infoicon src={info_scorecard} alt='info_icon'/>
            <TitleName>
                {t('Information')}
            </TitleName>

            </TitleContainer>
            <TitleDescription>
            {textDescription}
            </TitleDescription>
        </PopupContainer>

    )
}