/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 25/11/2021
* Author : Sunil Vora
*/

import { handleResponse } from '../helpers/commons';
import { LANGUAGE_URLS } from '../constants/environmentConstants';

export const kpiServices = {
    getKpiData
}

function getKpiData(id, data, languageCode) {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization':data
        } 
    }
    const url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    return fetch(`${url}/kpi-endpoints`, requestOptions)
    .then(handleResponse)
    .then(data => {
        return data;
    })
    .catch(error => {
        return Promise.reject(error);
    });
}