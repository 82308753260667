export const TIME_FILTER = {
    '1Y': 'YEAR',
    '1M': 'MONTH',
    '1W': 'WEEK',
    '1rok': 'YEAR',
    '1mies': 'MONTH',
    '1tydz': 'WEEK',
    '1año':'YEAR',
    '1mes':'MONTH',
    '1semana':'WEEK'
};

export const COLOR_CODES = ['#009901','#99cc00','#ffe600'];

