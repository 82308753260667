export const kpiData = [
    {
        kpiName: "FINANCIALS",
        graphName: "financials",
        dashboardUrl: [],
        tooltipText: {
          title: "What can you expect here?",
          body: "Explore the links in this section for fast access to top reports and KPIs on Financial Performance.View the widget to see Bottom 5 and Top 5 sites for shop sales, ranked by vs. Plan figures (nos. on left indicate site ranking for CO). Click on a site name to see the trend. To see further detail, click the RM Cockpit link.",
        },
      },
      {
        kpiName: "CUSTOMER INSIGHTS",
        graphName: "client",
        dashboardUrl: [],
        tooltipText: {
          title: "What can you expect here?",
          body: "Explore the links in this section for fast access to top reports and KPIs on Financial Performance.View the widget to see Bottom 5 and Top 5 sites for shop sales, ranked by vs. Plan figures (nos. on left indicate site ranking for CO). Click on a site name to see the trend. To see further detail, click the RM Cockpit link.",
        },
      },
      {
        kpiName: "OPERATIONS",
        graphName: "operations",
        dashboardUrl: [],
        tooltipText: {
          title: "What can you expect here?",
          body: "Explore this section for fast access to Operations reports and KPIs. Check out the eMaintenance section for key support ticket info.",
        },
      },
      {
        kpiName: "HSSE",
        graphName: "hsse",
        dashboardUrl: [],
        tooltipText: {
          title: "What can you expect here?",
          body: "Explore this section for fast access to key HSSE reports and KPIs.",
        },
      },
      {
        kpiName: "SALESFORCE",
        graphName: "salesforce",
        dashboardUrl: [],
        tooltipText: {
          title: "What can you expect here?",
          body: "Explore the links in this section for fast access to top reports and KPIs on Financial Performance.View the widget to see Bottom 5 and Top 5 sites for shop sales, ranked by vs. Plan figures (nos. on left indicate site ranking for CO). Click on a site name to see the trend. To see further detail, click the RM Cockpit link.",
        },
      },
]

export const Linksectionlinks = ["LINKS & TOOLS","Link"]

export const Linksectionoptions = ["LINKS & TOOLS",]

export const Linksectiontools = ["LINKS & TOOLS","Tool"]

export const No_user_result = "This name or email doesn't exist"

export const No_link_result = "This Linkname doesn't exist"

export const New_user_add = "+ Add new user"

export const New_link_add = "+ Add New Link or Tool"

export const View_title_text = "All Links and tools on site"

export const View_place_holder = "Search Linkname or URL"

export const ScorecardDataUpdate= [
  {
    "category": "Trading Profitably",
    "id": "trading_profitably"
  },
  {
    "category": "Customer",
    "subCategories": [
      {
        "subCategory": "Voice of Customer",
        "id": "voice_of_customer"
      },
      {
        "subCategory": "Loyalty Issuance",
        "id": "loyalty_issuance"
      }
    ]
  },
  {
    "category": "People",
    "subCategories": [
      {
        "subCategory": "People Overview",
        "id": "people_overview"
      },
      {
        "subCategory": "Clocking on time",
        "id": "clocking_on_time"
      },
      {
        "subCategory": "Unpublished schedules",
        "id": "unpublished_schedules"
      }
    ]
  },
  {
    "category": "Safety First Operations",
    "subCategories": [
      {
        "subCategory": "Safety Operations Overview",
        "id": "safety_data"
      },
      {
        "subCategory": "Mandatory Tasks Completion",
        "id": "mandatory_tasks"
      }
    ]
  }
]


export const Allcategories = ["Trading Profitably", "Customer", "Safety First Operations", "People"]

export const siteDataUpload = [
  {
  requiredFields:[
    "SAP ID",
    "RM ID"
  ]
},
{
  CategoryFiles:[
    {
    "fileName": "ABC.xlsb",
    "size": "2.1 MB",
    "lastModified": "2022-01-01 12:23:12"
  },
  {
    "fileName": "ABC.xlsb",
    "size": "2.1 MB",
    "lastModified": "2022-01-01 12:23:12"
  },
  {
    "fileName": "ABC.xlsb",
    "size": "2.1 MB",
    "lastModified": "2022-01-01 12:23:12"
  },
  ]
}

]

export const CategoryFiles = [
  {
    "fileName": "ABC.xlsb",
    "fileSize": "2.1 MB",
    "lastModified": "2022-01-01 12:23:12"
  },
  {
    "fileName": "ABC.xlsb",
    "fileSize": "2.1 MB",
    "lastModified": "2022-01-01 12:23:12"
  },
  {
    "fileName": "ABC.xlsb",
    "fileSize": "2.1 MB",
    "lastModified": "2022-01-01 12:23:12"
  }
]


export const Fileheader = ["File Name", "File Size", "Last Modified"]

export const years = function(startYear){
  // startYear = (typeof(startYear) == 'undefined') ? 2000 : startYear
  var currentYear = new Date().getFullYear();
  var years = []
  for(var i=currentYear;i>=currentYear-1;i--){
      years.push(i);
  } 
  return years;
}

export const weeks = function(){
     
 var weeks = [];
 for(var i=53;i>=1;i--){
  weeks.push(i);
} 
return weeks;
}