/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { HeaderContainer, HorizontalList, PopupContainer, FilterText, AddNewView, AddNewText } from './Card.styled'
import { DropDown } from '../dropDown/dropdown'

export function FilterCard(props) {
    const { t } = useTranslation();
    const [popup, setPopup] = useState(false);
    let refAllRoles = useRef(null);
    useEffect(() => {

    }, []);

    // useEffect for close dropdown outside for allRoles
    useEffect(() => {
        const closedropdown = (event) => {
            if ((refAllRoles?.current && !refAllRoles?.current.contains(event?.target))) {
                clickOutside()
            }
        }
        document.addEventListener('click', closedropdown, true)
        return () => {
            document.removeEventListener('click', closedropdown, true);
        };
    }, [refAllRoles]);
    //end
    const { rolesList, countryList, selectedRolesList, selectedCountryList, disableEditView, selectedViewText, viewList, viewDropDown, selectedViewList, onRolesClick, onViewClick, rightViewText, rolesDropDown, countryDropDown, onCountryClick, clickOutside } = props

    return (
        <HeaderContainer removeBorder={true}>
            <HorizontalList  ref={el => { refAllRoles.current = el; }}>
                <FilterText>Filter by:</FilterText>
                <div>
                    <DropDown
                        styleValues={"filterButtonStyle filterWidthRoles"}
                        filterWidth={126}
                        radioButton={true}
                        dropDownKey={rolesDropDown}
                        arrayList={rolesList}
                        selectedItem={selectedRolesList}
                        selectedViewText={selectedRolesList}
                        onClick={() => onRolesClick()}
                        itemSelection={(item, index) => props.filterSelection(item, index, "roles")}
                        filterDropDownStyle={"filterDropDownStyle filterWidth"} />
                </div>
                <div >
                    <DropDown styleValues={"filterButtonStyle filterWidth"}
                        filterWidth={116}
                        arrayList={viewList}
                        dropDownKey={viewDropDown}
                        selectedItem={selectedViewList}
                        selectedViewText={selectedViewText.length > 0 ? selectedViewText : 'Select View'}
                        noStarProp={true}
                        checklistSelection={true}
                        selectedMethod={() => onViewClick()}
                        onClick={() => onViewClick()}
                        itemSelection={(item, index) => props.filterSelection(item, index, "views")}
                        filterDropDownStyle={"filterDropDownStyle filterWidth"} />
                </div>
                <div>
                    <DropDown styleValues={"filterButtonStyle filterWidthCountryAdmin"}
                        filterWidth={136}
                        radioButton={true}
                        dropDownKey={countryDropDown}
                        arrayList={countryList}
                        selectedItem={selectedCountryList}
                        selectedViewText={selectedCountryList}
                        onClick={() => onCountryClick()}
                        itemSelection={(item, index) => props.filterSelection(item, index, "Country")}
                        filterDropDownStyle={"filterDropDownStyle filterWidth"} />
                </div>
            </HorizontalList>
            <AddNewView onMouseEnter={() => disableEditView && setPopup(true)}
                onMouseLeave={() => setPopup(false)}
            onClick={() => { props.rightViewMethod() }}
            >
                <AddNewText disableEditView={disableEditView}>{rightViewText}</AddNewText>
                {popup && disableEditView && <PopupContainer   >
                    <span className="add_user_popup">Please finish editing the current user, before adding a new one.
                    </span>
                </PopupContainer>}
            </AddNewView>
        </HeaderContainer>
    )
}

export default FilterCard
