import { eMaintenanceServices  } from "../services/eMaintenanceService";
import { constants } from "../constants/reducerConstants";
import { getLogoutUrl } from '../helpers/commons';
import { isEmpty } from "lodash";

export const eMaintenanceActions = {
    getEmaintananceData,
};

function getEmaintananceData() {
  let request
  return (dispatch, getState) => {
    const { authInfo, eMaintenanceInfo, scorecardInfo } = getState();
    const {selectedManagerName, selectedManagerId, siteNames, sites} = scorecardInfo;
    if(isEmpty(siteNames)){
      request = {
        sapIds: [],
        siteNames: [],
        regionalManagerId: selectedManagerId,
        regionalManagerName: selectedManagerName,
      };
  }
  else{
    let sapIdArr = [];
    let sapNameArr = [];
      sites?.forEach((item) => {
        sapIdArr.push(item.sapId);
        sapNameArr.push(item.siteName);
      });
       request = {
        sapIds: sapIdArr,
        siteNames: sapNameArr,
        regionalManagerId: selectedManagerId,
        regionalManagerName: selectedManagerName,
      };
  }
    if(!eMaintenanceInfo.isEmaintenanceLoading){
      dispatch({type:constants.EMAINTENANCE_LOADING_SUCCESS})
    }
    return eMaintenanceServices
      .geteMaintenanceData(authInfo.userData.id, authInfo.sessionData.id_token, authInfo.languageCode, request)
      .then(
        (data) => {
         dispatch({ type: constants.EMAINTENANCE_SUCCESS, data });
        },
        (error) => {
          dispatch({type:constants.EMAINTENANCE_LOADING_FAILED})
          dispatch({ type: constants.EMAINTENANCE_FAILURE, error });
          
          if (error && error.message === 'Failed to fetch') {
            dispatch({type: constants.CLEAR_AUTH_STORE});
            window.location.href = getLogoutUrl(authInfo.languageCode);
          }
        }
      );
  };
}