/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 06/12/2021
* Author : Arun Rapolu
*/
import { constants } from '../constants/reducerConstants';

const initialState = {
    showErrorPage: false,
    errorCode: null,
    errorMessage: ""
}

export function errorInfo(state = initialState, action) {
    switch (action.type) {
        case constants.SHOW_ERROR_PAGE:
            return {
                ...state,
                showErrorPage: action.isShow,
                errorCode: action.errorCode,
                errorMessage: action.message
            };
        default:
            return state
    }
}