/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect } from "react";
import { ButtonContainer, ButtonText, TriangleDesignDown, TriangleDesignUp } from './button.Styled'
import dropdown_below from "../../images/icons/dropdown_below.png"
import dropdown_up from "../../images/icons/dropdown_up.png";

export function SelectionButton(props) {
    useEffect(() => {

    }, []);
    return (
        <>
            <ButtonContainer data-test="button-container" style = {{width:props.width}} onClick={() => props.onClick()}>
                <ButtonText data-test="button-text">{props.buttonText}</ButtonText>
                {!props.openDropDown ? <img src={dropdown_below} style={{width:24, height:24}}/> 
                : <img src={dropdown_up} style={{width:24,height:24}}/>}
            </ButtonContainer>
        </>
    )
}

export default SelectionButton
