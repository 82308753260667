
/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 30/06/2022
* Author : Nisha Kataria
*/

import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { adminActions } from "../../actions/adminActions";
import { HeaderCard } from '../card/HeaderCard';
import { FilterCard } from '../card/filterCard';
import { SitesComponent } from '../userSites/sites';
import { New_user_add, No_user_result } from './AdminConstants';
import { UserSites } from './AdminDashboard.styled';
import { findEditList, searchListFetch, selectedFilter } from './AdminUtils';
import Delete from './Delete';

class UserManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rolesList: this.props.rolesList,
      selectedRolesList: "ALL Roles",
      selectedCountryList: "ALL Countries",
      viewList: this.props.viewList,
      selectedViewText: "ALL Views",
      selectedViewList: this.props.selectedViewList,
      userViewList: this.props.userViewList,
      rolesDropDown: false,
      viewDropDown: false,
      countryDropDown: false,
      countryDropDownTable: false,
      selectedItem: "ALL Roles",
      selectedView: "+ New Role",
      siteheader: { name: "Displayed Name", id: "Email ID", countryCode: "Country code", role: "Role", view: "Assigned View", admin: "Admin", title: "Title", delete: "",},
      viewSiteDropDown: false,
      userDetails: this.props.userDetails,
      viewDetails: {},
      viewTableDetails: [],
      deleteKey: false,
      disableEditView: false,
      searchResult: false,
      MousePoint: "",
      MousePoint1:"",
      countryListTable: [],
      viewCountryDropDown: false
      
    };
    this.mousePointSet = this.mousePointSet.bind(this);
    }

    componentDidMount(){
      let data = [...this.props.countryList]
      let index = data.indexOf("ALL Countries");
      data.splice(index,1);
      this.setState({
        countryListTable:data
      })
    }

  componentDidUpdate(prevProps, prevState) {
    window.addEventListener("click", (e) => {
      if (prevState.viewSiteDropDown !== this.state.viewSiteDropDown) {
        prevState.viewSiteDropDown = this.state.viewSiteDropDown;
        this.mousePointSet(e.pageY);
      }
     });
    window.removeEventListener("click",this.mousePointSet)
   }
 
 mousePointSet(value) {
    this.setState({ MousePoint: value + 5 });
  }

  //filter for country list
  countryFilterMethod(item, index){
    let selectedRolesUserState;
    selectedRolesUserState = selectedFilter(this.state.selectedRolesList, this.props.userDetails, this.state.selectedViewList, item)
    this.setState({
      userDetails: selectedRolesUserState,
      selectedCountryList: item
    })
  }

  // Filter for Roles List
  rolesFilterMethod(item, index) {
    let selectedRolesUserState;
   selectedRolesUserState = selectedFilter(item, this.props.userDetails, this.state.selectedViewList, this.state.selectedCountryList);
    this.setState({
      userDetails: selectedRolesUserState,
      selectedRolesList: item,
    });
  }

  // Filter for View Check List
 viewFilterMethod(item, index) {
    let  selectedViewList, roleselected;
    let selectedItem = this.state.selectedViewList.indexOf(item);
    selectedViewList = this.state.selectedViewList.slice();
    if (selectedItem !== -1) {
      if (index === 0) {
        selectedViewList = [];
      } else {
        selectedViewList.splice(selectedItem, 1);
        if (selectedViewList.length === this.state.viewList.length - 1) {
          selectedViewList.splice(0, 1);
        }
      }
    } else {
      if (index === 0) {
        selectedViewList = this.state.viewList;
      } else {
        selectedViewList.push(item);
        if (selectedViewList.length === this.state.viewList.length - 1) {
          selectedViewList = ["ALL Views"].concat(selectedViewList);
        }
      }
    }
    let selectedViewText;
    if (selectedViewList.length === this.state.viewList.length) {
      selectedViewText = selectedViewList[0];
    } else {
      selectedViewText = selectedViewList.toString();
    }
    roleselected = selectedFilter(this.state.selectedRolesList, this.props.userDetails, selectedViewList, this.state.selectedCountryList);
    this.setState({
      userDetails: roleselected,
      selectedViewList: selectedViewList,
      selectedViewText: selectedViewText,
    });
  }

  // Selected Filter Method
  filterSelection(item, index, type) {
    switch (type) {
      case "roles":
        return this.rolesFilterMethod(item, index);
      case "views":
        return this.viewFilterMethod(item, index);
      case "Country":
        return this.countryFilterMethod(item, index)
      default:
        return "default";
    }
  }

  // Admin Selection Method
  userAdminMethod(item, index, itemKey) {
    let userDetails = this.state.userDetails;
    let keyChange = userDetails[index][itemKey] === "false" ? "true" : "false";
    userDetails[index][itemKey] = keyChange;
    this.setState({ userDetails: userDetails }, () => {
      this.props.updateAdminUser(userDetails[index], "adminCheck");
    });
  }

  // Delete User Details
  deleteSiteItem() {
    let userDetails = this.state.userDetails;
    userDetails.splice(this.state.deleteKey[1], 1);
     this.setState({ userDetails: userDetails }, () => {
      let deleteVlaue = this.state.deleteKey[0].id + "," + this.state.deleteKey[0].countryCode
      this.props.deleteAdminUser(deleteVlaue).then(() => {
        userDetails.splice(this.state.deleteKey[1], 1);
        this.setState({ deleteKey: false });
      });
    });
  }

  // Search Method for users
  userSearchMethod(e) {
    let searchUserDetails = this.props.userDetails;
    if (e.length > 0) {
      searchUserDetails = searchListFetch(this.props.userDetails, e);
    }
    if (searchUserDetails.length === 0) {
      this.setState({ searchResult: true });
    } else {
      this.setState({ userDetails: searchUserDetails, searchResult: false });
    }
  }

  // view filter selection from row of user details list
  userFilterSelection(itemView, itemUser, indexUser,value) {
    let changeViewItem = this.state.userDetails;
    if(value === 'countryCode'){
      itemUser.countryCode = itemView;
    }else{
      itemUser.view = itemView;
    }
    changeViewItem.splice(indexUser, 1, itemUser);
    this.setState({ userDetails: changeViewItem }, () => {
      // if (!itemUser.editKey) {
        if(value === 'countryCode'){
          this.props.updateAdminUser(itemUser, "countryCode");
        }
        else{
          this.props.updateAdminUser(itemUser, "viewFilter");
        }
      // }
    });
  }

  addNewUser() {
    let addNewUser = this.state.userDetails;
    let newUser = { id: "", name: "", role: "RM (CO)",countryCode: this.props.languageCode.toUpperCase(), channelOfTrade: "CO", admin: "false", view: "CO", editKey: true };
    addNewUser.splice(0, 0, newUser);
    this.setState({ userDetails: addNewUser });
  }

  submitNewUser(name, id) {
    let newUser = this.state.userDetails;
    newUser[0].id = id;
    newUser[0].name = name;
    this.setState({ disableEditView: false });
    this.props.createAdminUser(newUser[0]).then((response) => {
      newUser.splice(0, 1, response);
      this.setState({ userDetails: newUser });
    });
  }

  checkNewUserSelection() {
    let isNewUserCreated = findEditList(this.state.userDetails);
    if (isNewUserCreated === undefined) {
      this.addNewUser();
      this.setState({ disableEditView: true });
    }
  }

  discardNewUser() {
    let discardNewUser = this.state.userDetails;
    discardNewUser.splice(0, 1);
    this.setState({ userDetails: discardNewUser, disableEditView: false });
  }

  render() {
    const { countryDropDown, selectedRolesList, selectedViewList, countryListTable, viewSiteDropDown, viewDropDown, countryDropDownTable, rolesList,viewList, rolesDropDown, siteheader, userDetails, selectedCountryList,viewTableDetails, viewheader, } = this.state;
    return (
      <div style={{ zIndex: 0 }}>
        <UserSites>
          <HeaderCard
            titleText={"Users on Site"}
            placeholderText={"Search User"}
            searchMethod={(e) => this.userSearchMethod(e)}
          />
          {rolesList.length > 1 && (
            <FilterCard
              rolesList={rolesList}
              selectedRolesList={selectedRolesList}
              countryList = {this.props.countryList}
              filterSelection={(item, index, type) =>
                this.filterSelection(item, index, type)
              }
              viewList={viewList}
              viewDropDown={viewDropDown}
              selectedCountryList = {selectedCountryList}
              countryDropDown = {countryDropDown}
              selectedViewText={this.state.selectedViewText}
              selectedViewList={selectedViewList}
              clickOutside = {()=>{
                this.setState({
                  rolesDropDown: false,
                  viewDropDown: false,
                  countryDropDown: false
                })
              }}
              onRolesClick={() =>{
                this.setState({
                  rolesDropDown: !this.state.rolesDropDown,
                  viewDropDown: false,
                  countryDropDown: false
                })
              }
              }
              onViewClick={() => {
                this.setState({
                  viewDropDown: !this.state.viewDropDown,
                  rolesDropDown: false,
                  countryDropDown: false
                });
              }}
              onCountryClick = {() => {
                this.setState({
                  viewDropDown: false,
                  viewSiteDropDown: false,
                  countryDropDown: !this.state.countryDropDown
                })
              }}
              rolesDropDown={rolesDropDown}
              onClick={(item) => this.setState({ selectedRolesList: item })}
              onClickCountry={(item) => this.setState({selectedCountryList: item})}
              rightViewText={New_user_add}
              disableEditView={this.state.disableEditView}
              rightViewMethod={() => this.checkNewUserSelection()}
            />
          )}
          {this.state.userDetails.length > 0 && (
            <SitesComponent
              siteheader={siteheader}
              listDetails={userDetails}
              loginUser={this.props.userData.id}
              viewList={this.state.userViewList}
              countryList = {countryListTable}
              viewSiteDropDown={viewSiteDropDown}
              viewCountryDropDown = {countryDropDownTable}
              userFilterSelection={(itemView, item, indexView, indexUser,value) =>
                this.userFilterSelection(itemView, item, indexUser, value)
              }
              deleteSite={(item, index) =>
                this.setState({ deleteKey: [item, index] })
              }
              adminMethod={(item, index, itemKey) =>
                this.userAdminMethod(item, index, itemKey)
              }
              onViewClick={(index) =>
                this.setState({
                  viewSiteDropDown:
                    this.state.viewSiteDropDown === index ? false : index,
                })
              }
              onCountryClickTable ={(index) =>
                this.setState({
                  countryDropDownTable:this.state.countryDropDownTable === index ? false : index,
                })
              }
              onClickDropDownOutside = {()=>{
                this.setState({
                  viewSiteDropDown: false})
              }}
              discardNewUser={() => this.discardNewUser()}
              submitNewUser={(name, id) => this.submitNewUser(name, id)}
              searchResult={this.state.searchResult}
              mousePoint={this.state.MousePoint}
              // mousePointC={this.state.MousePoint1}
              no_data_result = {No_user_result}
            />
          )}
        </UserSites>
        {this.state.deleteKey && (
          <Delete
            deleteItem={this.state.deleteKey}
            userManager={true}
            deleteMethod={() => this.deleteSiteItem()}
            doNotDelete={() => this.setState({ deleteKey: false })}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
    const { authInfo } = state;
    const { userData,languageCode } = authInfo;
    return { userData,languageCode };
}

const mapDispatchToProps = {
    createAdminUser: (request) => adminActions.createAdminUser(request),
    updateAdminUser: (request, type) => adminActions.updateAdminUser(request, type),
    deleteAdminUser: (id) => adminActions.deleteAdminUser(id)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(UserManager));
