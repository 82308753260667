export const  bpThemeGreenColor = "#007833";
export const  graphLabelGrayColor = "#666666";
export const  graphDashedLineGreenColor = "#007f00";
export const titleColor = "#575757"
export const EXCEL_DOWNLOAD_BACKGROUND = '#060'
export const  CIRCULAR_RED = "#e64949";
export const  LEGEND_GRAY = "#999999"
export const  EXCEL_RED_DARK = "#9e3232"



