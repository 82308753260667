import React from "react";
import { CardComponent } from "../card/CardComponent";
import { Row } from "react-bootstrap";

function PageCardsDisplay(props) {
  return (
    <Row xs={1} md={2} xl={3} className="g-4 page-tiles-centered">
      {Array.isArray(props.data) &&
        props.data.map((info, idx) => (
          info.dashboardUrl?.length > 0 ?
          <CardComponent
            id={idx}
            data={info}
            financialData={props.financialData}
            key={idx}
            popup={props.popup}
            channelOfTrade={props.channelOfTrade}
            navigationMethod={(url) => {
              props.history.push(url);
            }}
            setPopup={(value) => props.setPopup(value)}
          />:null
        ))}
    </Row>
  );
}

export default PageCardsDisplay;
