import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import ClockingTime from "../../../../scorecardNewDesign/People/Screens/ClockingTime";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const ClockingOnTimeComponent = ({ clocking_on_time, getClockingOnTimeData , layout}) => {
  useEffect(() => {
    getClockingOnTimeData(KPINames.CLOCKING_ON_TIME);
  }, []);

  if (clocking_on_time.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (clocking_on_time.data) {
    return (
        <ClockingTime weekNumber = {layout.data?.weekNumber} data = {clocking_on_time.data} dataFor = {"Month"}/>
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
  } = state;
  const clocking_on_time = scorecardImprovedInfo[KPINames.CLOCKING_ON_TIME];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return {  clocking_on_time, layout };
}

const mapDispatchToProps = {
  getClockingOnTimeData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ClockingOnTimeComponent);
