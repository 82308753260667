/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 05/09/2022
 * Author : Aditya Padmanabhan
 */




import { isEmpty } from 'lodash'
import React from 'react'
import {   AverageBarGreen, AverageBarYellow } from '../chart/Bars'
import { CurrentYear, PositionDisplay,  Topthreerow, Valuetexting } from '../scoreComponent/ScoreComponent.styled'
import { Averageontainer, AverageValue,  Heading, Innerheading,  LoyaltyContainer, NocircularData, Rowdisplay,  Sectioncontainer, Sectiondivider } from './ScoreCard.styled'
import isMobile from '../../helpers/isMobile';

function Personalranking(props) {
    const {averageComparison, compare, topThree,rank} = props;
     const {data, header} = averageComparison;
     let post = "";
     let personalPlacement = [...compare.data];
    let array = [];
    function positionsuffix(number){
      let position="";
      switch(number){
        case 1:
          position ="1st";
          break;
        case 2:
            position ="2nd";
            break;
        case 3:
            position ="3rd";
            break;
         default:
            position = `${number}th`;
            break;
      }
      return position;
     }
      
     personalPlacement = personalPlacement.map((item, index) => {
         if (index === 0) {
            post = positionsuffix(rank - 1);
           return {
             ...item,
             position: post,
           };
         }
         if (index === 1) {
            post = positionsuffix(rank + 1);
           return {
             ...item,
             position: post,
           };
         }
       });

       let me_position = positionsuffix(rank);
       let me = {
         name: "me",
         value: data.average.value,
         position: me_position,
       };
       personalPlacement.splice(1, 0, me);
    
       if(!isEmpty(topThree.data)){
        array = topThree.data?.map((item,index)=>{
         let post = positionsuffix(index+1);
          return{
           ...item,
           position: post
         }
       })
     }
     
     if(!isEmpty(array)){
     let temp = array[1];
     array[1] = array[0];
     array[0] = temp;
   }

      

    function renderrowdata(data, category, indexValue) {
      
      function nameSetter(str){
        let [first, ...second] = str.split(" ")
        if(!isEmpty(second)){
          first = first.concat(" "+second[0][0]);
          return first
        }
        else{
          return str
        }
      }
      if (category !== "top_three") {
        if(data.name!==null){
          let name = nameSetter(data.name);
          return (
            <CurrentYear id={"currentYear"+indexValue}>
              <Valuetexting id = {"value"+indexValue}>{`${data.value}%`}</Valuetexting>
              <Valuetexting id={"name"+indexValue}>{name}</Valuetexting>
              <Valuetexting id = {"position"+indexValue}>{data.position}</Valuetexting>
            </CurrentYear>
          );
        }
      } else {
        let name = nameSetter(data.name);
        return (
          <>
            <Topthreerow index={indexValue} id={"topThreeRow"+indexValue}>
              <Valuetexting id = {"value"+indexValue}>{`${data.value}%`}</Valuetexting>
              <Valuetexting id={"name"+indexValue}>{name}</Valuetexting>
              <PositionDisplay index={indexValue} id = {"position"+indexValue}>{data.position}</PositionDisplay>
            </Topthreerow>
          </>
        );
      }
    }
  return (
       <LoyaltyContainer isMobile={isMobile()} id="loyaltyContainer">
          <Sectioncontainer id="sectionContainer">
            <Sectiondivider id='average'>
               <Heading id="heading">{averageComparison?.header}</Heading>
               <Averageontainer>
              { data.average.value >= data.target.value? <><AverageBarGreen Target={(data.target.value/data.average.value)*100}  average ={data.average.value} /> <AverageValue id='loyalty' sidepercent={100-((data.target.value/data.average.value)*100)}>{`Target:${data.target.value}%`}</AverageValue></> :
               <><AverageBarYellow  Target={100-((data.average.value/data.target.value)*100)}  average ={data.average.value}/>
               <AverageValue  sidepercent={data.target.value}>{`Target:${data.target.value}%`}</AverageValue></>
               }
            </Averageontainer>
          </Sectiondivider>
            <Sectiondivider id='personal'>
                <Heading id="heading">Personal Placement</Heading>
                <Rowdisplay>
                 {personalPlacement.map((item)=>{
                    return renderrowdata(item);
                 })}</Rowdisplay>
                    <Heading id='top_three'>
                    <Innerheading id="innerHeading">{topThree.header}</Innerheading>
                    </Heading>
                <Rowdisplay id='top_three'>
                {array.length> 0? array.map((item,index)=>{
                   return renderrowdata(item,"top_three",index);
                 }): <NocircularData>no data available</NocircularData>}
                 </Rowdisplay>
            </Sectiondivider>
            </Sectioncontainer>
      </LoyaltyContainer>
   
  )
}

export default Personalranking