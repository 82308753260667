
import { constants } from "../constants/reducerConstants";
import { siteServices } from "../services/siteServices";

export const siteActions = {
    getSiteData
}
function getSiteData() {
    return (dispatch, getState)  => {
        const { authInfo } = getState();
        return siteServices.getSiteData(authInfo.userData.id, authInfo.sessionData.id_token, authInfo.languageCode).then( data => {
           dispatch({type: constants.FETCH_SITE_RANKING_SUCCESS, data});
           
       },
       error => {
           dispatch({type: constants.FETCH_SITE_RANKING_FAILURE, error});
       })   
   }
}
