import styled from 'styled-components';

export const Spin = styled.div`
    margin-top:${(props)=>props.device?'7em':'0.5em'};
    border: 8px solid #f3f3f3;
    border-top: ${(props) =>  props.col} 8px solid;
    border-radius: 50%;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    width: 90px;
    height:90px;
    margin-left: 10px;
    margin-right: 10px;
    align-items:center;
    animation: spin 0.5s linear infinite;
`;

export const ExcelSpin = styled.div`
    border: 2px solid #f3f3f3;
    border-top: ${(props) =>  props.color} 2px solid;
    border-radius: 50%;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    width: ${(props) => props.usedFor === 'delete' ? '15px' : '20px'};
    height: ${(props) => props.usedFor === 'delete' ? '15px' : '20px'};
    align-items:center;
    animation: spin 0.5s linear infinite;
`;