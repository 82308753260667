import { Row } from "react-bootstrap";
import styled from "styled-components";

export const FilterView = styled.div`
margin-top: 30px;
padding-bottom: 0px;
background: white;
margin-right: 15px;

`;

export const MobileWeekRow = styled(Row)`
    margin-top: 10px;
    background-color: white;
    border-radius: 7px;
    border: 1px solid #ebebeb;
    box-shadow: 0 5px 15px -2px rgba(46,74,79,0.12);
`;

export const FilterCustomRow = styled(Row)`
    margin-top: ${(props) => props.marginTop};
    margin-left: calc(.5 * 1.5rem);
    margin-right: 15px;
    padding-left: 15px;
    padding-right: calc(.5 * 1.5rem);
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: white;
    border-radius: 7px;
    border: 1px solid #ebebeb;
    box-shadow: 0 5px 15px -2px rgba(46,74,79,0.12);
  
  }
`;

export const FilterName = styled.h6`
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  
  
  color: #575757;

`;

export const CrossContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const FilterObject = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
    padding-left: 15px;
    padding-right: calc(.5 * 1.5rem);
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: white;
    margin-left: calc(.5 * 1.5rem);
    border-radius: 7px;
    border: 1px solid #ebebeb;
    box-shadow: 0 5px 15px -2px rgba(46,74,79,0.12);
  @media only screen and (max-width: 1000px) {
     {
      overflow-x: hidden;
    }
  }
`;

export const FilterWidget = styled.h6`
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-right: 20px;
  padding-top: ${(props) => props.usedFor === 'scoreCard' ? "15px" : '32px'};
  color: #575757;
  height: 40px;
  align-items: ${(props) => props.usedFor === 'scoreCard' ? " " : 'flex-end'};
`;

export const DropDownView = styled.div`
  margin-left: 20;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 15px;
`;

export const DropDownTitle = styled.span`
  font-family: Roboto-Light;
  font-size: 12px;
  font-weight: 300;
`;

export const DropDownButton = styled.button`
  height: 23px;
  margin: 4px 0 0;
  padding: 0px 9px 0px 8px;
  border-radius: 2px;
  border: solid 1px #ebebeb;
  width: 80%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
`;

export const ArrowDown = styled.img`
  height: 15;
  width: 15;
`;

export const SelectedText = styled.span`
  padding-vertical: 1.2em;
  font-family: Roboto-Light;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
`;

export const SelectedInput = styled.input`
  padding-vertical: 1.2em;
  font-family: Roboto-Light;
  background-color: transparent;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  border: 0px;
`;

export const DropDownList = styled.div`
  background-color: white;
  margin: 4px 0 0;
  padding: 5px 9px 3px 8px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 91%;
  height: 450%;
  width: 80%;
  left: 0;
  right: 0;
  z-index: 5;
  overflow: scroll;
  font-family: Roboto-Light;
`;

export const ItemText = styled.span`
  color:${(props) => (props.selectedItem ? "#007f00" : "black")}};
  font-family: Roboto-Regular;
  font-size: 12px;
  font-weight: 300;
  padding:3px;
  padding-top:4px;
  cursor: pointer;
`;

export const IndexView = styled.div`
  z-index: 5;
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
`;

export const MobileFilterContainer = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 1;
  right: 0px;
  left: 0px;
  top: 0px;
  backdrop-filter: blur(5px);
`;

export const FilterViewMobile = styled.div`
  background-color:white;
  bottom:0px;
  z-index:3;
  position:fixed;
  overflow: 'hidden',
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  padding-bottom:15px;
  right:0px;
  left:0px;
`;

export const ApplyButton = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;

export const ApplyText = styled.div`
  padding: 10px;
  color: #007833;
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: normal;
`;

export const FilterTitle = styled.span`
  font-family: Roboto-Light;
  font-size: 14px;
  font-weight: 300px;
  color: #666;
`;

export const FilterHeader = styled.div`
  padding-left: 20px;
`;

export const FilterListHeader = styled.span`
  font-family: Roboto-Regular;
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
`;

export const FilterList = styled.div`
  overflow-y: scroll;
  white-space: nowrap;
  margin-top: 6px;
  margin-bottom: 7px;
`;

export const FilterListItem = styled.div`
  display: inline-block;
  margin-right:20px;
  border-radius: 27px;
  border: solid 1px ;
  border-color: : ${(props) => (props.selectedItem ? "#666666" : "#aaaaaa")}};
  background-color: ${(props) => (props.selectedItem ? "#666666" : "#ffffff")}};
`;

export const FilterListText = styled.span`
  font-family: Roboto-Regular;
  font-size: 14px;
  color: ${(props) => (props.selectedItem ? "#ffffff" : "#aaaaaa")}};
  margin: 6px 19px 7px;
  align-item: center;
  display: flex;
`;

export const SiteMobileCircle = styled.img`
 width: 20px;
 height: 20px;
 border-radius: 50%;
 background-color: ${(props) => props.selectedItem ? "#666666" : "#ffffff"};;
 align-item: center;
 border: ${(props) => props.selectedItem ? "solid 1px white" : "solid 1px black"};
 padding-bottom: 3px;
 padding-top: 3px;
`;

export const SearchView = styled.div`
  height: 40px;
  margin-left: -20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  background-color: white;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const SelectedInputMobile = styled.input`
  font-family: Roboto-Light;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  overflow: hidden;
  border: 0px;
  padding: 0px 20px 0px 20px;
`;

export const WeakContainer = styled.div`
  padding:10px;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 10px;
  
`;
export const KpiWeak = styled.h6`
font-family: Roboto;
font-size: 16px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: 1.75;
letter-spacing: 0.15px;
color: #575757;
padding-top: 2px;
display: flex;
justify-content: ${(props) => props.usedIn === 'mobile' ? 'center' : ""}
`;
export const ButtonBox = styled.div`
  height: 40px;
  width:62px;
  background-color: ${(props) => !props.isWhite ? "#dadada" : "#006600"};
  display: flex;
  border-radius: 4px;
  align-items: center;
  margin-left:15px;
  cursor: pointer;
`;

export const MobweakYearLabel = styled.h3`
width: inherit;
font-family: Roboto-Light;
font-size: 14px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
margin-top: 0.25em;
color: ${(props) => !props.isWhite ? "#666666" : "#fff"};

`

export const WeakYearLabel = styled.h3`
font-family: Roboto;
font-size: 14px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
width: 62px;
height: 10px;
color: ${(props) => !props.isWhite ? "#666666" : "#fff"};
`;

