/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 26/08/2021
* Author : Arun Rapolu
*/
import React, { Component } from "react";
import { I18nextProvider } from 'react-i18next'; 
import { connect } from "react-redux";
import {isEmptyObj} from "../helpers/commons";


class I18Component extends Component {
  constructor(props){
    super(props);
    this.state = {
      i18nObj: null
    }
  }

  componentDidMount(){
    if(!isEmptyObj(this.props.userData)){
      import('./i18n').then((i18n) => {
        let a = i18n.default
        this.setState({i18nObj:a})
      });
    }
    else{
      import('./i18n').then((i18n) => {
        let a = i18n.default
        this.setState({i18nObj:a})
      });
  }
}
    render(){
      if(this.state.i18nObj){
        return(
          <I18nextProvider i18n={this.state.i18nObj}>
            {this.props.children}
          </I18nextProvider>
        )
      }
      else{
        return(
          <></>
        )
      }
    }
}

function mapState(state) {
  const { authInfo } = state;
  const { userData } = authInfo;
  return { userData };
}

export default connect(mapState, null)(I18Component);