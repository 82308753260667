import styled from "styled-components";

export const FilterView = styled.div`
  --top-spacing: 82px;
  margin-top: ${(props)=>props.filterFor==='score'?"var(--top-spacing)":""};;
  padding-bottom: 20;
  top: 0px;
  position: absolute;
  z-index: 1;
  width: ${(props)=>props.filterFor==='score'?"100%":""};
`;

export const CrossContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const FilterObject = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  margin-left: -12px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  box-shadow: 0 5px 15px -2px rgba(46, 74, 79, 0.12);
  @media only screen and (max-width: 1000px) {
     {
      overflow-x: hidden;
    }
  }
`;

export const FilterWidget = styled.h6`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  padding-right: 80px;
  padding-top: ${(props)=>props.usedFor === 'scoreCard'? "15px":'32px'};
  color: #666;
  height: 40px;
  align-items: ${(props)=>props.usedFor === 'scoreCard'? " ":'flex-end'};
`;

export const DropDownView = styled.div`
  margin-left: 20;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 15px;
`;

export const DropDownTitle = styled.span`
  font-family: Roboto-Light;
  font-size: 12px;
  font-weight: 300;
`;

export const DropDownButton = styled.button`
  height: 23px;
  margin: 4px 0 0;
  padding: 0px 9px 0px 8px;
  border-radius: 2px;
  border: solid 1px #ebebeb;
  width: 80%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
`;

export const ArrowDown = styled.img`
  height: 15;
  width: 15;
`;

export const SelectedText = styled.span`
  padding-vertical: 1.2em;
  font-family: Roboto-Light;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
`;

export const SelectedInput = styled.input`
  padding-vertical: 1.2em;
  font-family: Roboto-Light;
  background-color: transparent;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  border: 0px;
`;

export const DropDownList = styled.div`
  background-color: white;
  margin: 4px 0 0;
  padding: 5px 9px 3px 8px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 91%;
  height: 450%;
  width: 80%;
  left: 0;
  right: 0;
  z-index: 5;
  overflow: scroll;
  font-family: Roboto-Light;
`;

export const ItemText = styled.span`
  color:${(props) => (props.selectedItem ? "#007f00" : "black")}};
  font-family: Roboto-Regular;
  font-size: 12px;
  font-weight: 300;
  padding:3px;
  padding-top:4px;
  cursor: pointer;
`;

export const IndexView = styled.div`
  z-index: 5;
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
`;

export const MobileFilterContainer = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 1;
  right: 0px;
  left: 0px;
  top: 0px;
  backdrop-filter: blur(5px);
`;

export const FilterViewMobile = styled.div`
  background-color:white;
  bottom:0px;
  z-index:3;
  position:fixed;
  overflow: 'hidden',
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  padding-bottom:15px;
  right:0px;
  left:0px;
`;

export const ApplyButton = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;

export const ApplyText = styled.div`
  padding: 10px;
  color: #007833;
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: normal;
`;

export const FilterTitle = styled.span`
  font-family: Roboto-Light;
  font-size: 14px;
  font-weight: 300px;
  color: #666;
`;

export const FilterHeader = styled.div`
  padding-left: 20px;
`;

export const FilterListHeader = styled.span`
  font-family: Roboto-Regular;
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
`;

export const FilterList = styled.div`
  overflow-y: scroll;
  white-space: nowrap;
  margin-top: 6px;
  margin-bottom: 7px;
`;

export const FilterListItem = styled.div`
  display: inline-block;
  margin-right:20px;
  border-radius: 27px;
  border: solid 1px ;
  border-color: : ${(props) => (props.selectedItem ? "#666666" : "#aaaaaa")}};
  background-color: ${(props) => (props.selectedItem ? "#666666" : "#ffffff")}};
`;

export const FilterListText = styled.span`
  font-family: Roboto-Regular;
  font-size: 14px;
  color: ${(props) => (props.selectedItem ? "#ffffff" : "#aaaaaa")}};
  margin: 6px 19px 7px;
  align-item: center;
  display: flex;
`;

export const SiteMobileCircle = styled.img`
 width: 20px;
 height: 20px;
 border-radius: 50%;
 background-color: ${(props)=> props.selectedItem?"#666666":"#ffffff"};;
 align-item: center;
 border: ${(props)=> props.selectedItem?"solid 1px white":"solid 1px black"};
 padding-bottom: 3px;
 padding-top: 3px;
`;

export const SearchView = styled.div`
  height: 40px;
  margin-left: -20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  background-color: white;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const SelectedInputMobile = styled.input`
  font-family: Roboto-Light;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  overflow: hidden;
  border: 0px;
  padding: 0px 20px 0px 20px;
`;
