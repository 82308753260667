/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 23/08/2022
* Author : Sunil Vora
*/

import { isEmpty } from 'lodash';
import { columnHeaderLosses, columnHeaderWaste, columnHeadersFuel, columnHeadersServices, downloadExcel } from '../components/Scorecard/utils';
import { LANGUAGE_URLS } from '../constants/environmentConstants';
import { handleResponse } from '../helpers/commons';

export const scorecardServices = {
    getscorecardData,
    getscorecardSiteData,
    getScorecardContentData,
    getscorecardExport
}

function getscorecardData(data, languageCode, kpiName, headers, sortedData, sites, selectedManagerName) {
     let excelBody;
     let headerSetting = headerSelection(kpiName);

     function headerSelection(name) {
       switch (name) {
         case "Services":
           return columnHeadersServices;
        case "Shop":
           return columnHeadersServices;
        case "Fuel":
           return columnHeadersFuel;
        case "Waste":
           return columnHeaderWaste;
        case "Losses":
           return columnHeaderLosses;
        default:
           return headers;
       }
     }
     excelBody = {
       columnNames: headers,
       columnHeaders: headerSetting,
       kpiName,
       kpiData: sortedData,
       regionalManagerName: selectedManagerName
     };

     function responseBodyCreator(){
        if(kpiName){
            return {
                method: 'POST',
                headers: {
                    'Authorization': data,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(excelBody)
            }
        }
        else if(sites){
          return  {
                method: 'POST',
                headers: {
                    'Authorization': data,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sites)
            }
        }
        else{
            return {
                method: 'POST',
                headers: {
                    'Authorization': data
                },
            }
        }
     }
    
    const requestOptions = responseBodyCreator();
    const apiUrl = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    const updatedUrl = kpiName ? `${apiUrl}/scorecard/export` : `${apiUrl}/scorecard/`;
    return fetch(updatedUrl, requestOptions)
    .then(handleResponse)
    .then(data => {
        if (data.body.preSignedUrl) {
            return downloadExcel(data);
        }
        else {
            return data;
        }
    })
    .catch(error => {
        return Promise.reject(error);
    });
}

function getscorecardExport(data, languageCode, kpiName, headers, sortedData, selectedManagerName) {
    let excelBody;
     excelBody = {
      columnNames: headers,
      columnHeaders: headers,
      config_type:"MTD",
      kpiName,
      kpiData: sortedData,
      regionalManagerName: selectedManagerName
    };

    function responseBodyCreator(){
          return {
               method: 'POST',
               headers: {
                   'Authorization': data,
                   'Content-Type': 'application/json'
               },
               body: JSON.stringify(excelBody)
           }
   }
   
   const requestOptions = responseBodyCreator();
   const apiUrl = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
   const updatedUrl =  `${apiUrl}/scorecard/export`;
   return fetch(updatedUrl, requestOptions)
   .then(handleResponse)
   .then(data => {
       if (data.body.preSignedUrl) {
           return downloadExcel(data);
       }
       else {
           return data;
       }
   })
   .catch(error => {
       return Promise.reject(error);
   });
}

function getScorecardContentData(data, languageCode, kpiUrl, request)
{
    
    function responseBodyCreator(){
         return {
                method: 'POST',
                headers: {
                    'Authorization': data,
                    'Content-Type': 'application/json'

                },
                body : JSON.stringify(request)
            }
    }
    const apiUrl = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    const updatedUrl = `${apiUrl}/scorecard/${kpiUrl}`;
    const requestOptions = responseBodyCreator();
    return fetch(updatedUrl,requestOptions)
    .then(handleResponse)
    .then(data => {
            return data;
    })
    .catch(error => {
        return Promise.reject(error);
    });
}

function getscorecardSiteData(data, languageCode, selectedManagerName) {
    const requestOptions =  {
     method: 'GET',
       headers: {
           'Authorization': data,
       } ,
   }
   const apiUrl = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
   const updatedUrl =  `${apiUrl}/scorecard/sites/`;
   return fetch(updatedUrl, requestOptions)
   .then(handleResponse)
   .then(data => {
       if (data.body.preSignedUrl) {
           return downloadExcel(data);
       }
       else {
           return data;
       }
   })
   .catch(error => {
       return Promise.reject(error);
   });
}
