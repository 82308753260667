
/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 30/06/2022
* Author : Nisha Kataria
*/

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TabView } from '../tabView/tabView'
import { adminActions } from "../../actions/adminActions"
import ViewManger from './ViewManger';
import UserManager from './UserManager';
import { isEmpty } from 'lodash';
import ScoreCardDataUpload from './ScoreCardDataUpload';
import { ScorecardDataUpdate, siteDataUpload } from './AdminConstants';
import SiteDataUpload from './SiteDataUpload';
import { store } from '../../helpers/store';


class AdminDashboard extends React.Component {
        
    constructor(props) {
        super(props);
        this.state = {
            tabViewKeys: [
                { key: 'View Manager', value: 'View Manager' },
                { key: 'User Manager', value: 'User Manager' }
            ],
            tabDataUpload:[
                { key: 'Site data', value: 'Site data' },
                { key: 'Store performance data', value: 'Store performance data' },
                {key: 'User Manager', value: 'User Manager'}
            ],
            selectedTab: 'User Manager',
            selectDataTab: 'Site data',
            rolesList: ['ALL Roles'],
            viewList: ['ALL Views'],
            selectedViewText: 'ALL Views',
            selectedViewList: ['ALL Views'],
            userViewList: [],
            selectedItem: 'ALL Roles',
            selectedView: '+ New Role',
            userDetails: [],
            viewDetails: {},
            countryList: ["ALL Countries","UK","PL"]
        };
    }
    
    componentDidMount() {
        let  userLanguage = store.getState().authInfo?.userData?.countryCode 
        if(isEmpty(this.props.scoreCardCategories)||isEmpty(this.props.userDetails)){
          if(userLanguage === "UK"){
            this.props.getAdminUserDetails().then(() => {
                let viewsEdit = ["ALL Views", ...this.props.views];
                let value = viewsEdit.indexOf("DO")
                viewsEdit.splice(value, 1)
                let removeAllView = [...viewsEdit];
                value = removeAllView.indexOf("ALL Views")
                removeAllView.splice(value,1)
                let roleEdit = this.state.rolesList.concat(this.props.channelOfTrades)
                let valueRole = roleEdit.indexOf("RM (DO)")
                if(valueRole !== -1){
                    roleEdit.splice(valueRole, 1)
                }
                let selectViewEdit = this.state.selectedViewList.concat(this.props.views)
                value = selectViewEdit.indexOf("DO")
                selectViewEdit.splice(value,1)
                let allUserList = this.props.userDetails.map((item) => {
                    return{
                        ...item,
                        editKey:false
                    }
                })
                this.setState({
                    userDetails: allUserList.slice(),
                    rolesList: roleEdit,
                    viewList: viewsEdit,
                    userViewList: removeAllView,
                    selectedViewList: selectViewEdit
                })
                this.props.scoreCardCategoryFetch().then(() => {
                    if(isEmpty(this.props.siteMasterFileList)){
                        this.props.siteMasterListFetch();
                    }
                });
           })
          }else{
            if(isEmpty(this.props.siteMasterFileList)){
                this.props.siteMasterListFetch();
            }
          }
        }else{
             if(userLanguage === "UK"){
                this.props.getAdminUserDetails().then(() => {
                    let viewsEdit = ["ALL Views", ...this.props.views];
                    let value = viewsEdit.indexOf("DO")
                    viewsEdit.splice(value, 1)
                    let removeAllView = [...viewsEdit];
                    value = removeAllView.indexOf("ALL Views")
                    removeAllView.splice(value,1)
                    let roleEdit = this.state.rolesList.concat(this.props.channelOfTrades)
                    let valueRole = roleEdit.indexOf("RM (DO)")
                    if(valueRole !== -1){
                        roleEdit.splice(valueRole, 1)
                    }
                    let selectViewEdit = this.state.selectedViewList.concat(this.props.views)
                    value = selectViewEdit.indexOf("DO")
                    selectViewEdit.splice(value,1)
                    let allUserList = this.props.userDetails.map((item) => {
                        return{
                            ...item,
                            editKey:false
                        }
                    })
                    this.setState({
                        userDetails: allUserList.slice(),
                        rolesList: roleEdit,
                        viewList: viewsEdit,
                        userViewList: removeAllView,
                        selectedViewList: selectViewEdit
                    })
                })
              }
        }
    }

    render() {
        const { tabViewKeys, userViewList, selectedViewList, countryList, tabDataUpload, selectDataTab, rolesList, viewList, userDetails, viewDetails, } = this.state
        let userLanguage = store.getState().authInfo?.userData?.countryCode;
        return (
             <div className="container-body" >
                <div className='container' >
                    <div style={{ backgroundColor: 'white', paddingBottom: 15 }}>
                        <TabView
                            selectedTab={selectDataTab}
                            tabItems={userLanguage === "UK" ? tabDataUpload : [{key: 'Site data', value: 'Site data' }]} 
                            onClick={(item) => userLanguage === "UK" ? this.setState({ selectDataTab: item.key }) : item.key !== 'Site data' ? null : this.setState({ selectDataTab: item.key })} />
                           {this.state.selectDataTab === 'Site data'?
                           this.props.siteMasterFileList.length > 0 && 
                           <SiteDataUpload data = {this.props.siteMasterFileList} />
                            : this.state.selectDataTab === 'User Manager' ?
                             userDetails.length > 0 && 
                             <UserManager
                                userDetails={userDetails}
                                rolesList={rolesList}
                                viewList={viewList}
                                countryList = {countryList}
                                userViewList={userViewList}
                                selectedViewList={selectedViewList} />: 
                                 this.props.scoreCardCategories.length > 0 && 
                                <ScoreCardDataUpload data={this.props.scoreCardCategories}/>}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { adminInfo } = state;
    const { channelOfTrades, userDetails, views, viewMangerData, updatedLinksOfViews,scoreCardCategories, siteMasterFileList } = adminInfo;
    return { channelOfTrades, userDetails, views, viewMangerData, updatedLinksOfViews,scoreCardCategories, siteMasterFileList };
}

const mapDispatchToProps = {
    getAdminUserDetails: () => adminActions.getAdminUserDetails(),
    getAdminViewDetails: () => adminActions.getAdminViewDetails(),
    updateLink: (data) => adminActions.updateLink(data),
    scoreCardCategoryFetch:() => adminActions.scoreCardCategoryFetch(),
    siteMasterListFetch: () => adminActions.siteMasterListFetch()
    
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(AdminDashboard));
