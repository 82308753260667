import i_small_sort_highest_to_lowest from "../../images/icons/i_small_sort_highest_to_lowest.svg";
import i_small_sort_lowest_to_highest from "../../images/icons/i_small_sort_lowest_to_highest.svg";
import i_sort_neutral from "../../images/icons/i_sort_neutral.svg";
import { ArrowIcon } from "./ScoreCard.styled";
// import i_sort_green_up from "../../components/Scorecard/TradingProfitably/Assets/icon_sort_green_up.svg";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { isEmpty } from "lodash";
const HEADER_KEY_MAP = {
    "Wk Sales": "wk_Sales",
    "QTD Sales": "qtd_sales",
    "QTD Vol": "qtd_Vol",
    "Wk Var to Plan": "wk_Var_to_plan",
    "QTD Var to Plan": "qtd_var_to_plan",
    'Wk Vol': 'wk_Vol',
    '5 Wk Vol': 'MTD_Vol',
    'Wk Var LFL': 'wk_varLFL',
    'QTD Var LFL': 'qtd_varlfl',
    'YTD Vol': 'YTD_Vol',
    '5 Wk Sales': 'MTD_Sales',
    'YTD Sales': 'ytd_sales',
    'Wk Loss': 'wk_Loss',
    'QTD Loss': 'qtd_Loss',
    '5 Wk Var vs Plan': 'MTD_vs_Plan',
    'YTD vs Plan': 'ytd_vs_plan',
    '5 Wk Var LFL': 'MTD_Var_LFL',
    'YTD Var LFL': 'ytd_varlfl',
    'Wk Loss % to Sales': 'wk_Loss_Perc_to_Sales',
    '5 Wk Loss % to Sales': 'MTD_Loss_perc_to_Sales',
    'Wk Waste': 'wk_Waste',
    'Wk % to Sales': 'wk_Waste_Perc_to_Sales',
    '5 Wk Waste': 'MTD_Waste',
    '5 Wk % to Sales': 'MTD_Perc_To_Sales',
    'QTD Waste': 'qtd_waste',
    'QTD Var to plan': 'qtd_Var_to_plan',
    'QTD % to Sales': 'qtd_Perc_to_Sales',
    'YTD Waste': 'YTD_Waste',
    'YTD % to Sales': 'YTD_Perc_To_Sales',
    'QTD Loss % to Sales': 'qtd_Loss_Perc_to_Sales',
    'YTD Loss % to Sales': 'YTD_Loss_perc_to_Sales',
    'CSAT Score': 'csatScore',
    'Wow Moments': 'wow_moments',
    'Wow to total': 'wow_total',
    'Rescue to total': 'rescue_total',
    '% to total': 'varToTotal'
}
export function getFieldFromKey(headerKey) {
    let valueFields = headerKey.replace(/[^a-zA-Z1-9 ]/g, "").split(' ');
       if(headerKey in HEADER_KEY_MAP){
        return HEADER_KEY_MAP[headerKey]
       }
    let fieldName = valueFields[0].toLowerCase();
    if (valueFields[0] === '') {
        valueFields = valueFields.slice(1);
        fieldName = valueFields[0].toLowerCase();
    }
    valueFields.forEach((field, idx) => {
        if (idx > 0) {
            fieldName = fieldName.concat(field.charAt(0).toUpperCase() + field.slice(1));
        }
    })
    return fieldName;
};

const isNumber = (n) => { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

export const sortData = (keyName, sortState, sortedData) => {
    let sortedState = [...sortState];
    const sortedItemState = sortedState.find(val => val.name === keyName);
    if (sortedItemState.sortState === 'NONE') {
        sortedItemState.sortState = 'ASC';
    } else if (sortedItemState.sortState === 'ASC') {
        sortedItemState.sortState = 'DESC';
    } else {
        sortedItemState.sortState = 'ASC';
    }
    let tempData = [...sortedData];
    let sortingData
    if (keyName === "sites") {
        sortingData = sortedItemState.sortState === 'ASC' ? tempData.sort((a, b) => (a[keyName]) > (b[keyName]) ? 1 : -1) : tempData.sort((a, b) => (a[keyName]) > (b[keyName]) ? -1 : 1);
    } else {
        sortingData = sortedItemState.sortState === 'ASC' ?
            tempData.sort((a, b) => {

                return isNumber(a[keyName]) ? ((a[keyName]) - (b[keyName])) : (parseInt(a[keyName]) - parseInt(b[keyName]))
            }

            )

            : tempData.sort((a, b) => {
                return isNumber(a[keyName]) ? ((b[keyName]) - (a[keyName])) : (parseInt(b[keyName]) - parseInt(a[keyName]))

            }

            );
    }

    return { sortingData, sortedState }
}

export const createMarkup = (content) => ({ __html: content });

export const renderSortIcons = (colName, data, isTile, state) => {
   const colSort = data?.find(item => item.name === colName)?.sortState;
    switch (colSort) {
        case 'NONE':
            return <ArrowIcon isTile={isTile} src={i_sort_neutral} alt="sort" />;
        case 'ASC':
            return <ArrowIcon isTile={isTile} src={i_small_sort_lowest_to_highest} alt="sort" />
        case 'DESC':
            return <ArrowIcon isTile={isTile} src={i_small_sort_highest_to_lowest} alt="sort" />;
        default:
            break;
    }
}

export const downloadExcel = (data) => {
    const link = document.createElement('a');
    link.href = data.body.preSignedUrl;
    link.setAttribute('download', data.body.key);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return data;
}

export const defaultSortOrder = (data) => {
    return Object.keys(data[0]).map(item => {
        switch (item) {
            case 'varToPlan':
                return { name: item, sortState: 'ASC' };
            case 'varToTotal':
                return { name: item, sortState: 'ASC' };
            case 'completed':
                return { name: item, sortState: 'ASC' };
            case 'compliance':
                return { name: item, sortState: 'ASC' };
            case 'safetyInd':
                return { name: item, sortState: 'ASC' };
            case 'labourVsDemand':
                return { name: item, sortState: 'DESC' };
            case 'csatScore':
                return { name: item, sortState: 'ASC' };
            case 'wk_Sales':
                return { name: item, sortState: 'ASC' };
            case 'qtd_sales':
                return { name: item, sortState: 'ASC' };
            case 'wk_Loss':
                return { name: item, sortState: 'ASC' };
            case 'qtd_Loss':
                return { name: item, sortState: 'ASC' }
            case 'wk_Vol':
                return { name: item, sortState: 'ASC' };
            case 'qtd_Vol':
                return { name: item, sortState: 'ASC' };
            case 'wk_Waste':
                return { name: item, sortState: 'ASC' };
            case 'csat_score':
                return { name: item, sortState: 'ASC' };
            default:
                return { name: item, sortState: 'NONE' };
        }
    }
    );
}



export const columnHeadersServices = ['Sites', 'Total Sales (£)', 'Var to plan (%)', 'Var LFL (%)'];

export const columnHeadersFuel = ['Sites', 'Total Vol (L)', 'Var to plan (%)', 'Var LFL (%)'];

export const columnHeaderWaste = ['Sites', 'Waste (£)', 'Var to plan (%)', '% to sales (%)'];

export const columnHeaderLosses = ['Sites', 'Value Loss (£)', '% to total (%)'];

export function siteCollector(key, listOfSites) {
    const fetchArray = key.map((item) => listOfSites.find(value => value.siteName === item));
    return fetchArray
}

export function searchSite(key, listOfSites) {
    const result = listOfSites.filter((item) => item.toLowerCase().includes(key.toLowerCase()))
    return result
}
export function searchRegionalManagerFilter(key, list) {
    const result = list.filter((item) => item.regionalManagerName.toLowerCase().includes(key.toLowerCase()))
    return result
}
export function capitalize(key) {
    let splitResult = key.split(' ')
    let result;
    splitResult.map((data, index) => {
        if (index === 0) {
            if (isNaN(isNaN(data))) {
                result = data
            } else {
                result = data?.charAt(0).toUpperCase() + data.slice(1).toLowerCase() + " "
            }
        } else {
            if (isNaN(isNaN(data))) {
                result = result + " " + data
            } else {
                result = result + " " + data?.charAt(0).toUpperCase() + data.slice(1).toLowerCase() + " "
            }
        }

    });

    return result || ''
}
export function getRequiredInput(key, menuList) {
    let resArr = [];
    menuList.map((item, index) => {
        let headerName = item.header;
        let arr = item.links.filter((link) => link.name.toLowerCase().includes(key.toLowerCase()));
        if (!isEmpty(arr)) {
            arr.map((item2, index2) => {
                let object = {
                    "linkname": headerName + ">" + item2.name,
                    "name": item2.name,
                    "link": item2.url

                }
                resArr.push(object)
            })
        }
    })
    return resArr;
}

export const eventLogging = (eventName, payload) => {
    const analytics = getAnalytics();
    logEvent(analytics, eventName, payload)
}

export const renderAlert = (value) => {
    if (value >= 10 || value <= -10) {
        return true;
    }
    return false;
}


