/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 22/03/2021
* Author : Arun Rapolu
*/
import { constants } from '../constants/reducerConstants';
import { authServices } from '../services/authServices';
import { startLoader, stopLoader, showErrorPage, getLogoutUrl } from '../helpers/commons';


export const authActions = {
    callAuth,
    savePath,
    getUserData,
    userLogout
}

function callAuth(code, languageCode) {
    return dispatch => {
        dispatch(startLoader());
        return authServices.callAuth(code, languageCode).then( data => {
            data.tokenStartTime = new Date().getTime();
            data.tokenExpiryTime = new Date().getTime()+(data.expires_in * 1000);
           dispatch(stopLoader());
           dispatch(saveAuthInfo(data));
           dispatch({ type: constants.SAVE_LANGUAGE_CODE, code: languageCode })
        return {
            success : true,
            id_token: data.id_token
        }
       },
       error => {
           dispatch(clearAuthInfo());
           dispatch(stopLoader());
           if (error && error.message === 'Failed to fetch') {
            dispatch({type: constants.CLEAR_AUTH_STORE});
            window.location.href = getLogoutUrl(languageCode);
           }
           dispatch(showErrorPage(error))
       })   
   }
   function saveAuthInfo(data) { return {type: constants.SAVE_AUTH_INFO, data}}
   function clearAuthInfo() { return {type: constants.CLEAR_AUTH_INFO}}
}

function savePath(pathName) {
    return { type: constants.SAVE_REDIRECT_PATH, pathName}
}

function getUserData(data) {
    return (dispatch, getState) => {
        const { authInfo } = getState();
        dispatch(startLoader());
        return authServices.getUserData(data, authInfo.languageCode).then( data => {
            let newlang = data.body?.countryCode||'PL';
           dispatch(stopLoader());
           dispatch(saveUserData(data));
            return {
                success : true,
                language: newlang
            }
       },
       error => {
           dispatch(clearUserData());
           dispatch(stopLoader());
           if (error && error.message === 'Failed to fetch') {
            dispatch({type: constants.CLEAR_AUTH_STORE});
            window.location.href = getLogoutUrl(authInfo.languageCode);
           }
           dispatch(showErrorPage(error))
       })   
   }
   function saveUserData(data) { return {type: constants.SAVE_USER_DATA, data}}
   function clearUserData() { return {type: constants.CLEAR_USER_DATA}}
}

function userLogout(){
    return (dispatch, getState) => {
        const { authInfo } = getState();
        dispatch(startLoader());
        dispatch(clearAuthInfo());
        window.location.href = getLogoutUrl(authInfo.languageCode);
   }
   function clearAuthInfo() { return {type: constants.CLEAR_AUTH_STORE}}
}