/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 06/01/2023
 * Author : Aditya Padmanabhan
 */

import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { adminActions } from '../../actions/adminActions';
import EmaintenaceLoading from '../E-Maintenance/EmaintenaceLoading';
import DropDown from '../dropDown/dropdown';
import { Allcategories, Fileheader, weeks, years } from './AdminConstants';
import { ButtonText, FileTable, FileTableHeader, FileTablecontent, HighlightLogText, InfoOfUpload, Logstable, Perrowupload, SCdataUploadValues, SelectionOption, StatusOfUpload, SubmitFilebutton, TableAlignment } from './AdminDashboard.styled';
import { checkAllStatus, checkCategory, itemFinder, removeCategory, subCategoryFinder } from './AdminUtils';


function ScoreCardDataUpload(props) {
  const { data } = props;
  let refDropDownCategory = useRef(null)
  let refDropDownSubCategory = useRef(null)
  let refDropDownYear = useRef(null)
  let refDropDownWeek = useRef(null)

  let [openDropdown, setOpenDropdown] = useState(false);
  let [dropDownSub, setDropDownSub] = useState(false);
  let [dropDownYear, setDropDownYear] = useState(false);
  let [dropDownWeek, setDropDownWeek] = useState(false);
  let [rowHightlight, setRowHighlight] = useState(0);
  let [selectedValue, setSelectedValue] = useState("Please select category");
  let [selectedSubValue, setSelectedSubValue] = useState("select subcategory");
  let [selectedYearValue, setSelectedYearValue] = useState(new Date().getFullYear());
  const week = weeks();
  let todaydate = new Date();
  let oneJan = new Date(todaydate.getFullYear(), 0, 1);
  let numberOfDays = Math.floor((todaydate - oneJan) / (24 * 60 * 60 * 1000));
  let result = Math.ceil((todaydate.getDay() + 1 + numberOfDays) / 7) -1;
  let [selectedWeekValue, setSelectedWeekValue] = useState(result);
  let [subCategory, setSubcategory] = useState({});
  let [logs, setLogs] = useState([]);
  const year = years();
  let [loadLog, setLoadLog] = useState(false);
  let [uploadFlag, setUploadFlag] = useState(true);
  let [weekValue, setWeekValue]= useState(week)
  const [intervalCheck, setIntervalCheck] = useState(0);
  let headerLogs = ["FILENAME", "TIMESTAMP", "DESCRIPTION", "STATUS"]

  useEffect(() => {
    setLogs(props.logs);
    if (!isEmpty(props.logs)) {
      setLoadLog(true);
      let statusChecker = checkAllStatus(props.logs); // this function returns logs of items for which status is completed or failed
      if (!isEmpty(props.inProgressCategory) && !isEmpty(statusChecker)) {
        let allCategory = [...props.inProgressCategory];  // this array saves for which all category user has uploaded file
        allCategory.map((item) => {
          let istrue = statusChecker.filter((item1) => item1.category === item);
          if (!isEmpty(istrue)) {
            let newSetData = new Set([...allCategory])
            newSetData.delete(item);
            newSetData = Array.from(newSetData);
            props.setCategory(newSetData);
          }
        })

        let setExec = [...props.exeIds]
        setExec.forEach((item) => {
          let istrue = statusChecker.filter((item1) => item1.category === item.category)
          if (!isEmpty(istrue)) {
            let newSetData = new Set([...props.exeIds]);
            newSetData.delete(item);
            newSetData = Array.from(newSetData);
            props.updateExecutionId(newSetData);
          }
        })


      }
    }
  }, [props.logs])



  useEffect(() => {
    let categoryChecker = [...props.inProgressCategory];
    let res = subCategoryFinder(selectedValue, data);
    if (res[0]?.subCategories === undefined && res.length > 0) {
      if (categoryChecker.indexOf(res[0]?.id) === -1) {
        setUploadFlag(true);
        document.getElementById("buttonUpload").disabled = false;
      } else {
        setUploadFlag(false);
        document.getElementById("buttonUpload").disabled = true;
      }
    } else if (res[0]?.subCategories !== undefined && res.length > 0) {
      let getItem = itemFinder(selectedSubValue, res[0]);
      if (
        categoryChecker.indexOf(getItem[0].id) === -1 &&
        getItem.length > 0
      ) {
        setUploadFlag(true);
        document.getElementById("buttonUpload").disabled = false;
      } else {
        setUploadFlag(false);
        document.getElementById("buttonUpload").disabled = true;
      }
    }

  }, [props.inProgressCategory]);

  useEffect(() => {
    if (!isEmpty(props.exeIds)) {
      clearInterval(intervalCheck);
      let id = setInterval(() => {
        let data = props.exeIds.map((item) => {
          return item.exeID;
        });
        setIntervalCheck(id);
        props.scoreCardlogFetch(data);
      }, 30000);
    }
    else {
      clearInterval(intervalCheck);
    }

    return () => {
      clearInterval(intervalCheck);
    };
  }, [props.exeIds])

  // useEffect for close dropdown outside for Category
  useEffect(() => {
    const closedropdown = (event) => {
      if ((refDropDownCategory?.current && !refDropDownCategory?.current.contains(event?.target))) {
        setOpenDropdown(false)
      }
    }
    document.addEventListener('click', closedropdown, true)
    return () => {
      document.removeEventListener('click', closedropdown, true);
    };
  }, [refDropDownCategory]);

  // useEffect for close dropdown outside for SubCategory
  useEffect(() => {
    const closedropdown = (event) => {
      if ((refDropDownSubCategory?.current && !refDropDownSubCategory?.current.contains(event?.target))) {
        setDropDownSub(false)
      }
    }
    document.addEventListener('click', closedropdown, true)
    return () => {
      document.removeEventListener('click', closedropdown, true);
    };
  }, [refDropDownSubCategory]);
  //end

  // useEffect for close dropdown outside for year
  useEffect(() => {
    const closedropdown = (event) => {
      if ((refDropDownYear?.current && !refDropDownYear?.current.contains(event?.target))) {
        setDropDownYear(false)
      }
    }
    document.addEventListener('click', closedropdown, true)
    return () => {
      document.removeEventListener('click', closedropdown, true);
    };
  }, [refDropDownYear]);
  //end

  // useEffect for close dropdown outside for week
  useEffect(() => {
    const closedropdown = (event) => {
      if ((refDropDownWeek?.current && !refDropDownWeek?.current.contains(event?.target))) {
        setDropDownWeek(false)
      }
    }
    document.addEventListener('click', closedropdown, true)
    return () => {
      document.removeEventListener('click', closedropdown, true);
    };
  }, [refDropDownWeek]);
  //end

  function filterSelection(item, index, text) {
    setOpenDropdown(!openDropdown)
    setSelectedValue(item);
    let res = subCategoryFinder(item, data);
    setSubcategory(res);
    if (res[0].subCategories === undefined && res.length > 0) {
      props.setFileTable(res[0].id);
      setRowHighlight(0);
      let categoryChecker = [...props.inProgressCategory]
      if (categoryChecker.indexOf(res[0].id) === -1) {
        setUploadFlag(true);
        if (document.getElementById("buttonUpload") !== null) {
          document.getElementById("buttonUpload").disabled = false;
        }
      }
      else {
        setUploadFlag(false);
        if (document.getElementById("buttonUpload") !== null) {
          document.getElementById("buttonUpload").disabled = true;
        }
      }
    }
  }

  function filterSubSelection(item, index, text) {
    setDropDownSub(!dropDownSub)
    setSelectedSubValue(item["subCategory"]);
    if (item.id !== undefined) {
      props.setFileTable(item.id);
      setRowHighlight(0);
      let categoryChecker = [...props.inProgressCategory]
      if (categoryChecker.indexOf(item.id) === -1) {
        setUploadFlag(true);
        if (document.getElementById("buttonUpload") !== null) {
          document.getElementById("buttonUpload").disabled = false;
        }
      }
      else {
        setUploadFlag(false)
        if (document.getElementById("buttonUpload") !== null) {
          document.getElementById("buttonUpload").disabled = true;
        }
      }
    }
  }

  function filterYearSelection(item, index, text) {
    setDropDownYear(!dropDownYear)
    setSelectedYearValue(item);
  }

  function filterWeekSelection(item, index, text) {
    setWeekValue([...week]);
    setDropDownWeek(!dropDownWeek)
    setSelectedWeekValue(item);
  }

  function changeHigLight(key) {
    setRowHighlight(key);

  }

  function upload() {
    if (subCategory[0].subCategories === undefined) {
      let getRequiredFile = props.listOfFiles[rowHightlight]
      let week = selectedWeekValue.toString()
      let year = selectedYearValue.toString()
      setLoadLog(true);
      document.getElementById("buttonUpload").disabled = true;
      let response = {
        "fileName": getRequiredFile.fileName,
        "year": year,
        "weekNumber": week,
        "category": subCategory[0].id
      }
      if (props.logs.length > 0) {
        let isCategoryInLog = checkCategory(subCategory[0].id, props.logs)
        if (isCategoryInLog !== undefined) {
          let removeFromLogs = removeCategory(subCategory[0].id, props.logs);
          props.updateLog(removeFromLogs);
        }
      }
      props.uploadData(response, false).then((data) => {
        let setExec = [...props.exeIds]
        let newExecution = {
          "category": data.category,
          "exeID": data.executionId
        }
        let allExecutionID = [...setExec, newExecution]
        let novo = new Set(allExecutionID)
        novo = Array.from(novo)
        props.updateExecutionId(novo)
        let cate = new Set([...props.inProgressCategory])
        cate.add(subCategory[0].id)
        cate = Array.from(cate)
        props.setCategory(cate)

      },
        () => {
          setUploadFlag(true);
          document.getElementById("buttonUpload").disabled = false;
        });
    }
    else {
      let getRequiredFile = props.listOfFiles[rowHightlight];
      let getItem = itemFinder(selectedSubValue, subCategory[0]);
      let week = selectedWeekValue.toString()
      let year = selectedYearValue.toString()
      setLoadLog(true);
      document.getElementById("buttonUpload").disabled = true;
      let response = {
        "fileName": getRequiredFile.fileName,
        "year": year,
        "weekNumber": week,
        "category": getItem[0].id
      }
      if (props.logs.length > 0) {
        let isCategoryInLog = checkCategory(getItem[0].id, props.logs)
        if (isCategoryInLog !== undefined) {
          let removeFromLogs = removeCategory(getItem[0].id, props.logs);
          props.updateLog(removeFromLogs);
        }
      }
      props.uploadData(response, false).then((data) => {
        let setExec = [...props.exeIds]
        let newExecution = {
          "category": data.category,
          "exeID": data.executionId
        }
        let allExecutionID = [...setExec, newExecution]
        let novo = new Set(allExecutionID)
        novo = Array.from(novo)
        props.updateExecutionId(novo)
        let cate = new Set([...props.inProgressCategory])
        cate.add(getItem[0].id)
        cate = Array.from(cate)
        props.setCategory(cate);
      },
        () => {
          setUploadFlag(true);
          document.getElementById("buttonUpload").disabled = false;
        });
    }
  }

  const gotoDashboard = (url) => {
    if (url === "/") {
      return;
    } else {
      window.open(url);
    }
  };

  const searchWeekFilter = (value)=>{
    if(value.length === 0){
      setWeekValue([...week]);
      return;
    }
    let filterData = week?.filter(data =>   
           (data + '').includes(value)
        )
       setWeekValue([...filterData]);
  }

  return (
    <>
      <InfoOfUpload>
        Please follow below steps to upload a report:<br></br>
        <br></br>
        1.Select Category (and Subcategory if available), Year and the last
        closed Week<br></br>
        <br></br>
        2.Select a report from the list<br></br>
        <br></br>
        3.Submit<br></br>
        <br></br>
        4.Check if the report loaded successfully
      </InfoOfUpload>
      <SCdataUploadValues>
        <SelectionOption ref={el => { refDropDownCategory.current = el; }}>
          <ButtonText>Select Category</ButtonText>
          <DropDown
            styleValues={"filterButtonStyle categorySelectorWidth"}
            filterWidth={165}
            radioButton={true}
            dropDownKey={openDropdown}
            arrayList={Allcategories}
            selectedItem={selectedValue}
            selectedViewText={selectedValue}
            onClick={() => setOpenDropdown(!openDropdown)}
            itemSelection={(item, index) =>
              filterSelection(item, index, "roles")
            }
            filterDropDownStyle={"filterDropDownStyle"}
          />
        </SelectionOption>
        {!isEmpty(subCategory) && subCategory[0].subCategories ? (
          <SelectionOption ref={el => { refDropDownSubCategory.current = el; }}>
            <ButtonText>Select Subcategory</ButtonText>
            <DropDown
              styleValues={"filterButtonStyle categorySelectorWidth"}
              filterWidth={165}
              radioButton={true}
              dropDownKey={dropDownSub}
              onClick={() => setDropDownSub(!dropDownSub)}
              arrayList={subCategory[0].subCategories}
              selectedViewText={selectedSubValue}
              selectedItem={selectedSubValue}
              itemSelection={(item, index) =>
                filterSubSelection(item, index, "roles")
              }
              usedFor="subcategory"
              filterDropDownStyle={"filterDropDownStyle"}
            />
          </SelectionOption>
        ) : null}
        <SelectionOption ref={el => { refDropDownYear.current = el; }}>
          <ButtonText>Year</ButtonText>
          <DropDown
            styleValues={"filterButtonStyle categorySelectorWidth"}
            filterWidth={165}
            radioButton={true}
            dropDownKey={dropDownYear}
            onClick={() => setDropDownYear(!dropDownYear)}
            arrayList={year}
            selectedViewText={selectedYearValue}
            selectedItem={selectedYearValue}
            itemSelection={(item, index) =>
              filterYearSelection(item, index, "roles")
            }
            filterDropDownStyle={"filterDropDownStyle"}
          />
        </SelectionOption>
        <SelectionOption ref={el => { refDropDownWeek.current = el; }}>
          <ButtonText>Week</ButtonText>
          <DropDown
            styleValues={"filterButtonStyle categorySelectorWidth"}
            filterWidth={165}
            radioButton={true}
            dropDownKey={dropDownWeek}
            onClick={() => setDropDownWeek(!dropDownWeek)}
            arrayList={weekValue}
            selectedViewText={selectedWeekValue}
            selectedItem={selectedWeekValue}
            itemSelection={(item, index) =>
              filterWeekSelection(item, index, "roles")
            }
            type = {"week"}
            searchFilter = {(value)=>  searchWeekFilter(value)}
            filterDropDownStyle={"filterDropDownStyle"}
          />
        </SelectionOption>
      </SCdataUploadValues>
      {props.listOfFiles.length > 0 && (
        <FileTable>
          <TableAlignment>
            {Fileheader.map((item) => {
              return <FileTableHeader>{item}</FileTableHeader>;
            })}
          </TableAlignment>

          <TableAlignment id="values">
            {props.listOfFiles.map((item, Index) => {
              return (
                <Perrowupload
                  key={Index}
                  toBehighlighted={Index === rowHightlight ? true : false}
                  onClick={() => changeHigLight(Index)}
                >
                  <FileTablecontent
                    onClick={() => gotoDashboard(item?.linkingUrl)}
                  >
                    {item.fileName}
                  </FileTablecontent>
                  <FileTablecontent>{item.size}</FileTablecontent>
                  <FileTablecontent>{item.lastModifiedTime}</FileTablecontent>
                </Perrowupload>
              );
            })}
          </TableAlignment>
          <SubmitFilebutton
            id="buttonUpload"
            color={uploadFlag}
            onClick={() => uploadFlag && upload()}
          >
            Submit
          </SubmitFilebutton>
          {loadLog && (
            <StatusOfUpload>
              <TableAlignment>
                {headerLogs.map((item) => {
                  return <FileTableHeader flex= {item== 'DESCRIPTION'?6:( (item== 'TIMESTAMP' || item== 'FILENAME') ?1.5:1)}>{item}</FileTableHeader>;
                })}
              </TableAlignment>
              <Logstable>
                {logs.length > 0 ? (

                  logs.map((item) => {
                    return (
                      <TableAlignment id="values">
                        <Perrowupload>
                          <FileTablecontent flex= {1.5}>{item.category}</FileTablecontent>
                          <FileTablecontent flex= {1.5}>
                            {item.message.includes("is successful") ||
                              item.message.includes("failed") ||
                              item.message.includes("successfully") ? (
                              <HighlightLogText>{`${item.timestamp}`}</HighlightLogText>
                            ) : (
                              ` ${item.timestamp}`
                            )}
                          </FileTablecontent>
                          <FileTablecontent flex= {6}>
                            {item.message.includes("is successful") ||
                              item.message.includes("failed") ||
                              item.message.includes("successfully") ? (
                              <HighlightLogText>{`${item.message}`}</HighlightLogText>
                            ) : (
                              ` ${item.message}`
                            )}
                          </FileTablecontent>
                          <FileTablecontent flex= {1}>{item.status}</FileTablecontent>
                        </Perrowupload>
                      </TableAlignment>
                    );
                  })
                ) : (
                  <EmaintenaceLoading />
                )}</Logstable>
            </StatusOfUpload>
          )}
        </FileTable>
      )}
    </>
  );
}


function mapStateToProps(state) {
  const { adminInfo } = state;
  const { listOfFiles, submitButton, logs, inProgressCategory, exeIds } = adminInfo;
  return { listOfFiles, submitButton, logs, inProgressCategory, exeIds };
}

const mapDispatchToProps = {
  setFileTable: (data) => adminActions.setFileTable(data),
  uploadData: (data) => adminActions.uploadData(data),
  scoreCardlogFetch: (data) => adminActions.scoreCardlogFetch(data),
  setCategory: (data) => adminActions.setCategory(data),
  updateLog: (data) => adminActions.updateLog(data),
  updateExecutionId: (data) => adminActions.updateExecutionId(data)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(ScoreCardDataUpload));
