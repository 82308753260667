/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabContainer, TabDesign, TabText } from './tabView.styled'

export function TabView(props) {
    const { t } = useTranslation();
    const [colorChange, setColorChange] = useState(false);
    const { tabItems, selectedTab, onClick } = props
    useEffect(() => {

    }, []);
    return (
        <TabContainer>
            {tabItems.map((item, index) => (
                <TabDesign onMouseEnter={() => setColorChange(item.key)}
                    onMouseLeave={() => setColorChange(false)}
                    selectedTab={item.key === selectedTab || colorChange === item.key} onClick={() => props.onClick(item)} >
                    <TabText>{item.value}</TabText>
                </TabDesign>
            ))}
        </TabContainer>
    )
}

export default TabView
