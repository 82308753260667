import styled from 'styled-components';

export const DashboardContainer = styled.div``;

export const HeadingContainer = styled.div`
    display: flex;
    margin:10px;
    font-family: Roboto;
    font-weight: 300;
    font-size: 14px;
    height: 2rem;
    line-height: 16.41px;
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 5px;
    @media only screen and (max-width: 1000px) {
         margin-left:auto;
     }    
`;

export const ListingHeaderKey = styled.span`
    color: #666666;
    font-family:Roboto-Regular;
    
`;

export const ListingHeaderValue = styled.span`
    color: #666666;
    font-family:Roboto-Light;
`;

export const SiteListingContainer = styled.div`
    margin-top: 1.5em;
    margin-bottom: 17px;
    border-radius: ${(props) => props.id ==='link'  ? '0.25rem' : '10px'};
    border: ${(props) => props.id ==='link'  ? '1px solid rgba(0,0,0,.125)' : 'solid 0.5px #ccc'};
    box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.12);
    padding: ${(props) => props.isMobile ? '0px' : '14px 4px 19px 10px'};
    background-color: #FFFFFF;
    @media only screen and (max-width: 1000px) {
          overflow-x:hidden;
      }    
`;

export const FilterByTime = styled.div`
    font-family: 'Roboto-Light';
    width: auto;
    text-align: center;
    display: inline-block;
    position: relative;
    margin-right: 5px;
    margin-left:5px;
    background-color: ${(props) => props.isCurrentFilter ? '#009901' : '#9c9c9c'};
    color: #FFFFFF;
    padding: 5px 3px;
    cursor: pointer;
`;

export const TimeContainer = styled.div`
    display: flex;
`;

export const TitleContainer = styled.div`
    font-family: 'Universe for BP Light';
    width: 100%;
    font-weight: 300;
    padding-left: ${(props) => props.mobile ? '9%' : ''};
    @media only screen and (min-width:400px) and (max-width:430px) {
      {
        padding-left:  ${(props) => props.mobile ? '13%' : ''};
      }
    }
    @media only screen and (min-width:376px) and (max-width:399px) {
      {
        padding-left:  ${(props) => props.mobile ? '11%' : ''};
      }
    }
`;

export const ReviewView = styled.div`
  overflow:  ${(props) => props.filterView ? 'hidden': 'scroll'};
  position: ${(props) => props.filterView ?  'absolute': 'relative'};
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px; 
`
export const TableContainer = styled.div`
    height: 17em;
    margin: 10px;
    @media only screen and (max-width: 1000px) {
         overflow-x:hidden;
     }
   
`;

export const LineGraphContainer = styled.div`
`;

export const ViewContainer = styled.div`
margin-top:-200px;
padding-top: 100px;
`

export const MessagePopup = styled.div`
  visibility: ${(props) => props.showPopup ? 'visible' : 'hidden'};
  width: 120px;
  background-color: #353535;
  text-align: center;
  border-radius: 9px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 2.8em;
  left: 37%;
  margin-left: -60px;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #353535 transparent transparent transparent;
    }
`;

export const ExportContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: ${(props) => props.mobile ? '7.5em' : '9em'};
  cursor: ${(props) => props.isRegionalManager ? 'pointer' : 'not-allowed'};
  opacity: ${(props) => props.isRegionalManager ? 'initial' : '50%'};
`;

export const FilterButton = styled.div`
  width: 83px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #007833;
  position: fixed;
  right: 20px;
  z-index: 2;
  top : ${(props) => props.topAdjustment - 120}px;
`;

export const FilterLayerMobile = styled.div`
 height: 100%;
 overflow: hidden;
 touch-action: pan-x;
`;

export const FilterText = styled.div`
font-family: Roboto-Regular;
  font-size: 16px;
  color:white;
`
export const ExcelLabel = styled.span`
  font-family: 'Roboto-Light';
  font-size: 12px;
  padding-top: 5px;
  margin-right: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.isRegionalManager ? '#009901' : '#999999'};
`;

export const DownloadIcon = styled.div`
  text-align: end;
`;

export const IconImage = styled.img`
  height: 1em;
`;

export const BannerText = styled.div`
  color: white;
  font-family: 'Roboto-Light';
  font-size: 16px;
  width: inherit;
  margin-left: 2.7em;
  text-align: start;
  @media only screen and (min-width:400px) and (max-width:430px) {
    margin-left: 3.8em;
  
  }
  @media only screen and (min-width:376px) and (max-width:399px) {
    margin-left:  3em;
  
  }
`;