import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { MenuContainer, LogoContainer, ImageLogoMenu, MenuLinksContainer,
Lists, ListItem, LinkName, RightArrowContainer, RightArrow } from "./Menu.styled";
import { scorecardActions } from "../../actions/scorecardActions";
import { ALLINKS } from "../../constants/menu";
import i_icon_chevron_right_green from "../../images/icons/i_icon_chevron_right_green.svg";
import i_icon_chevron_right_white from "../../images/icons/i_icon_chevron_right_white.svg";
import brandImage from "../../images/bp-responsive.png";

function Menu(props) {
    const [showLink, setShowLink] = useState(false);
    return (
        <MenuContainer id="menuContainer">
          <LogoContainer id="logoContainer">
          <ImageLogoMenu
              src={brandImage}
              className="d-inline-block align-top mr-2  h2"
              alt="BP logo"
              loading="lazy"
              data-test="BPlogo"
              id = "bpLogo"
            />
          </LogoContainer>
          <MenuLinksContainer id="menuListContainer">
          <Lists id = "list">
            {ALLINKS.map((section,index) => {
                return (
                    <ListItem id={"listItem" + index} onMouseOver={() => setShowLink(section.name)} onMouseOut={() => setShowLink('')}>
                        <LinkName id= {"linkName" + index}>
                            {section.name}
                            <RightArrowContainer id= {"rightArrowContainer" + index}>
                                <RightArrow id={"rightArrowContainer" + index} src={showLink === section.name ? i_icon_chevron_right_white : i_icon_chevron_right_green} />
                            </RightArrowContainer>
                        </LinkName>
                    </ListItem>
                )
            })}
            </Lists>
          </MenuLinksContainer>
        </MenuContainer>
    );
}

function mapStateToProps(state) {
    const { scorecardInfo, authInfo } = state;
    const { scorecardData, error } = scorecardInfo;
    const { userData, languageCode } = authInfo;
    const { name } = userData;
    return { scorecardData, error, name, languageCode };
}

const mapDispatchToProps = {
    getScorecardData: scorecardActions.getScorecardData,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    Menu
);