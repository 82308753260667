import { LANGUAGE_URLS } from '../constants/environmentConstants';
import { getLanguageCode } from '../helpers/commons';

export const eMaintenanceServices = {
  geteMaintenanceData,
};

function geteMaintenanceData(id, data, languageCode,response) {
  // let country = getLanguageCode();
  
  // let resquestType = country === 'uk' ? "POST" :"GET"
  const requestOptions = response ? {
    method: "POST",
    headers: {
      Authorization: data,
      'Content-Type': 'application/json'

    },
    body: JSON.stringify(response)
  }:{
    method: "POST",
    headers: {
      Authorization: data,
    }
  }
  const url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
  return fetch(`${url}/emaintenance`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      let error = (data && data.message) || response.statusText;
      if (response.status === 400) {
        error = response.status;
      }
      throw new Error(error);
    }
    return data;
  });
}
