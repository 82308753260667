/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 31/12/2021
 * Author : Aditya Padmanabhan
 */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MobileFilterContainer, ApplyButton, FilterObject, CrossContainer, SearchView, SelectedInputMobile, FilterListHeader, SelectedInput, FilterList, FilterListItem, FilterListText, ApplyText, FilterHeader, FilterViewMobile, FilterTitle } from './Filter.styled';
import { SELECTEDFILTER } from './Filter.constants';
import i_cross from "../../images/icons/i_cross.svg";
import search from "../../images/icons/search.png";
function MobileFilter(props) {
    const { t } = useTranslation();
    const [searchView, setSearchView] = useState(false);
    useEffect(() => {

    }, []);

    function searchMethod(searchValue, title,placeholder) {
        return (
            <SearchView>
                <SelectedInputMobile type='text'
                    placeholder={placeholder}
                    value={searchValue}
                    onChange={(e) => { props.handleChange(e, title) }}
                />
                <CrossContainer isReviewsSection={props.isReviewsSection} onClick={() => {
                    searchValue === '' ? setSearchView(false) : props.onClearFilter(title)
                }}>
                    <img className="closeImage" src={i_cross} alt='cross' style={{ height: 17, width: 17, marginRight:15 }} />
                </CrossContainer>
            </SearchView>
        )
    }

    function titleMethod(title, showFilter) {
        return (
            <FilterObject>
                <FilterListHeader>
                    {title}
                </FilterListHeader>
                {showFilter && <CrossContainer isReviewsSection={props.isReviewsSection} onClick={() => {
                    setSearchView(title)
                }}>
                    <img src={search} alt='search' />
                </CrossContainer>}
            </FilterObject>
        )
    }

    function filterListMethod(searchValue, id, title, selected, objectList, list) {
        return (
            <div>
                {searchView === title && title === t('Regional Manager:') ?
                    searchMethod(searchValue, title, t("Search a Regional Manager"))
                    : title === t('Regional Manager:') &&
                    titleMethod(title, true)
                }

                {searchView === title && title === t('Site Name:') ?
                    searchMethod(searchValue, title, t("Search a site"))
                    : title === t('Site Name:') &&
                    titleMethod(title, true)
                }

                {title === t('Channel of Trade:') && titleMethod(title, false)}
                <FilterList id={id}>
                    {objectList.map(function (key, index) {
                        let selectedItem = false
                        let textValue = ''
                        selectedItem = selected === key
                        if (key === 'ALL' || key === t('ALL')) {
                            textValue = t('ALL')
                        } else if (title === t('Regional Manager:')) {
                            textValue = list[key].name
                        } else if (title === t('Channel of Trade:')) {
                            textValue = key
                        } else {
                            textValue = list[key].siteName
                        }
                        return (
                            <FilterListItem selectedItem={selectedItem} onClick={() => {
                                const value = { [key]: list[key] };
                                props.selectionMethodMobile(key, value, id)
                            }}>
                                <FilterListText selectedItem={selectedItem}>
                                    {textValue}
                                </FilterListText>
                            </FilterListItem>
                        )
                    })}
                </FilterList>
            </div>
        )
    }
    return (
        <div style={{height:'100%', overflow: 'hidden', 
        touchAction: 'pan-x', backgroundColor:'transparent'}}>
            <MobileFilterContainer onClick={(e) => { 
                props.closeFilterMethodMob()
            }} />
            <FilterViewMobile>
                <ApplyButton >
                    <ApplyText onClick={() => props.filterApi()} >{t('Apply Filter')}</ApplyText>
                </ApplyButton>
                <FilterHeader>
                    <FilterTitle>{t('Filter By')}</FilterTitle>
                    {filterListMethod(props.regionalManagerSearch, SELECTEDFILTER.REGIONALMANAGER, t('Regional Manager:'), props.selectedRegionalManager, Object.keys(props.regionalManagerList), props.regionalManagerList)}
                    {filterListMethod('', SELECTEDFILTER.CHANNELOFTRADES, t('Channel of Trade:'), props.selectedTradeOfChannel, props.channelOfTradesList, props.channelOfTradesList)}
                    {filterListMethod(props.siteManagerSearch, SELECTEDFILTER.SITENAME, t('Site Name:'), props.selectedSiteName, Object.keys(props.siteList), props.siteList)}
                </FilterHeader>
            </FilterViewMobile>
        </div>
    );
}

export default MobileFilter
