import styled from "styled-components";
import isMobile from "../../../../helpers/isMobile";

export const SafetyOverviewlBox = styled.div`
  margin-top: 1em;
  border-radius: 10px;
  box-shadow: 0 2px 11px 0 rgba(209, 209, 209, 0.5);
  border: solid 0.5px #d4d4d4;
  background-color: #fff;
  padding-bottom: 5px;
`;
export const LoyaltyFor = styled.div`
  margin-left: 0.2em;
  font-family: Roboto-light;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666;
`;
export const Rightviewpeople = styled.div`
  flex: 0.33;
  margin-left: 1em;
`;
export const MainContainer = styled.div`
  height: ${(props) => `${props.height}px`};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: ${(props) => (props.isTileWide && isMobile() ? "10px" : "2px")};
  }
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  @media screen and (max-device-width: 480px) {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 200%;
    }
`;
export const Noresult = styled.div`
  display: flex;
  margin-top: 1em;
  
  justify-content: center;
`;
export const Clockcontainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transperent;
  justify-content: space-evenly;
  height: 100%;
`;

export const MandatoryTaskContainer = styled.div`
margin-top: 1em;
border-radius: 10px;
border: solid 0.5px #ccc;
box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.06);
background-color: #fff;
`;

export const Olddatadarkcontent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 299px;
  height: 165px;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 #e0e0e0;
  background-color: white;
  z-index: 1;
`;

export const OlddataUnpubcontent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 299px;
  height: 150px;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 #e0e0e0;
  background-color: white;
  z-index: 1;
`;

export const Olddatadarkoverlay = styled.div`
height: inherit;
display: flex;
justify-content: center;
align-items: center;
width: inherit;
margin-top: -10.5em;
`;

export const OldUnpubdata = styled.div`
height: 100%;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
margin-top: -8em;
@media screen and (max-device-width: 480px) {
  height: 60%;
  }
`
export const ClockingTimeContainer = styled.div`
  flex: 0.7;
  border-radius: 10px;
  border: solid 0.5px #ccc;
  box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.06);
  background-color: #fff;
`;

export const DarkSitesContainer = styled.div`
  border-radius: 10px;
  margin-top: 1em;
  border: solid 0.5px #ccc;
  box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.06);
  background-color: #fff;
  padding-bottom: 5px;
`;

export const UnpublishedContainer = styled.div`
  flex: 0.3;
  border-radius: 10px;
  margin-top: 1em;
  border: solid 0.5px #ccc;
  box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.06);
  background-color: #fff;
  padding-bottom: 5px;
`;

export const Clockingheadercell = styled.div`
  flex: ${(props) => (props.index === 0 ? "0.5" : "0.25")};
  display: flex;
  font-size: 12px;
  font-family: ${(props) =>
    props.usedIn === "header" ? "roboto-light" : "roboto-regular"};
  justify-content: ${(props) => (props.index === 0 ? "flex-start" : "center")};
  margin-left: ${(props) => (props.index === 0 && props.rowUsed !== 'totalRow' ? "0.5em" : "")};
`;
export const Loyaltyheader = styled.div`
  display: flex;
  flex: 1;
  border-bottom: ${(props) => (props.usedIn === "header" ? "1px solid #ccc" : "")};
  background: ${(props) => (props.usedIn === "header" ? "rgba(247,247,247,1)" : "")}; 
  height: 45px;
  align-items: center;
}
`;

export const Clockingspace = styled.span`
`;

export const SafetyOverviewTable = styled.div`
@media screen and (max-device-width: 480px) {
  overflow-x: scroll;
  overflow-y: hidden;
  }
`;

export const Headertradingtable = styled.div`
  display: flex;
  flex: 1;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  background-color: #fafafa;
  @media screen and (max-device-width: 480px) {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 200%;
    }
`;

export const TotalRow = styled.div`
   display: flex;
   flex-direction: row;
   border-top: 1px solid rgba(0, 0, 0, 0.125);
   height: 2em;
   align-items: center;
   @media screen and (max-device-width: 480px) {
    width: 200%;
    overflow-y: hidden;
    overflow-x: hidden;
    align-items: center;
    }
 `;


export const GraphContainer = styled.div`
     align-items: center;
    display: flex;
    justify-content: center;
    margin-left:30px;
    @media screen and (max-device-width: 480px) {
      margin-left:0px;
      }
`

export const ScContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    margin-left:${(props)=> props.marginLeft-10+"px"};
    margin-right:${(props)=> props.marginRight-10+"px"};
    `



export const ActualText = styled.span`
  font-family: Roboto-Light;
  font-size: 14px;
  margin-right: ${(props) => (props.isLosses ? "8px" : "13px")};
  padding-left: 6px;
`;
export const AlertIcon = styled.img`
  height: 17px;
  width: 16px;
  margin-top: -2px;
`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 39px;
  // padding: 9px 12px 9px 5px;
  &:nth-child(2n) {
    background-color: #fafafa;
  }
`;
export const ScoreLegendContainer = styled.div`
  display: flex;
  margin-top: 1em;
  margin-bottom: 0.5em;
  align-items: center;
  padding: 5px 0px 11px 16px;
  justify-content: center;
  @media screen and (max-device-width: 480px) {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-around;
    }
`;
export const Legend = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  @media screen and (max-device-width: 480px) {
    margin-left: 0px;
    }
`;
export const LegendColor = styled.span`
  height: 13px;
  width: 13px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;
  border: solid 1px #979797;
`;

export const LegendName = styled.div`
  font-family: Roboto-Light;
  font-size: 11px;
  margin-left:3px;
`;
export const ScoreGraphContainer = styled.div`
 // height: 235px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom:5px;
`;

export const Actual = styled.div`
  height: 13px;
  width: 13px;
  background-color: ${(props) =>
    props.isActualMe
      ? "#666"
      : props.state === "DANGER"
      ? "#ffc000"
      : "#007f00"};
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  margin-left: 1em;
`;
export const Peopleheadingcell = styled.div`
  display: flex;
  flex: ${(props) =>
    props.index === 0 ? "2" : '1.14'};
  align-items: center;
  justify-content: ${(props) => (props.index === 0 ? "flex-start" : "center")};
  font-size: 12px;
  color: ${(props) => (props.colorRed ? "red" : "black")};
  height: inherit;
  margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
  margin-right: ${(props) => (props.index === 6 ? "1em" : "")};
  font-family: ${(props) =>
    props.renderedIn === "table" ? "Roboto-Regular" : "Roboto-Light"};
`;

export const Peopleicon = styled.div`
 width: 65px;
 height: 100%;
 display:flex;
 justify-content: flex-start;
 align-items:center;
`

export const Greenbar = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  //  background-color: green;
  border-radius: 2em;
  justify-content: flex-start;
`;

export const Progress = styled.div`
  width: ${(props) => props.width===0? 5:props.width}%;
  border-radius: 2em;
  height: 100%;
  background-color: ${(props) => (props.booleanProgress ? "red" : props.width === 0? 'grey': "green")};
`;

export const Redbar = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  border-radius: 2em;
  justify-content: flex-end;
`;
export const Whitepeoplebar = styled.div`
  width: 80%;
  height: 20%;
  background-color: #eaeaea;
  border-radius: 2em;
  display: flex;
  justify-content: center;
`;

export const Olddatasafetyview = styled.div`
 height: inherit;
display: flex;
justify-content: center;
align-items: center;
 width: inherit;

`;

export const Olddatasafetycontent = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
 align-items: center;
 width: 499px;
 height: 300px;
 border-radius: 8px;
 box-shadow: 0 6px 20px 0 #e0e0e0;
background-color: white;
z-index:1;
 position: absolute;
margin-bottom: 35em;
`;

export const Oldmandcontent = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
 align-items: center;
 width: 350px;
 height: 250px;
 border-radius: 8px;
 box-shadow: 0 6px 20px 0 #e0e0e0;
 background-color: white;
 z-index:1;
 position: absolute;
 margin-bottom: 20em;
`

export const MandatoryTaskTable = styled.div`
@media screen and (max-device-width: 480px) {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 12em;
  }
`;

export const MandatoryTaskData = styled.div`
height: ${(props) => `${props.height}px`};
overflow-y: scroll;
::-webkit-scrollbar {
  width: ${(props) => (props.isTileWide && isMobile() ? "10px" : "2px")};
}
padding-top: 0.5em;
padding-bottom: 0.5em;
@media screen and (max-device-width: 480px) {
  width: 100%;
  height: 180px;;
  overflow-y: scroll;
  overflow-x: hidden;
}
`;