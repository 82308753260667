/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 05/09/2022
 * Author : Aditya Padmanabhan
 */


import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { BarRating,  ColorCode,  ComplimentCode,  CustomerContainer, DataContainer, Description, HeaderContainer, MainContainer, OrangeCirlce,  PreviousNumber,  ScoreKpiName, ScorePopupContainer, ScoreTitle, SiteHeading, SiteNames, SiteRatings,  TableHeader } from './ScoreCard.styled'
import i_icon from "../../images/icons/i_icon.svg";
import  {  ComplimentProgressBar } from '../chart/Bars';
import { createMarkup } from './utils';


function CustomerComplaints(props) {
    const { title, headers, data, toolTipText } = props.data;
    const [showPopup, setShowPopup] = useState('');

    const handleHover = (name, type) => {
        if (type === 'leave') {
            setShowPopup('');
        } else {
            setShowPopup(name);
        }
    }

    const renderData = (headerKey, itemData, headerIndex) => {
        const {compliments, compliants, prev_compliments, prev_complaints} = itemData
        let fieldName
        if(headerKey === 'Sites'){
         fieldName = headerKey.toLowerCase();
        }
        
        return (
            <SiteRatings alignText={headerIndex}>
                <SiteNames id={itemData[fieldName]+"_"+headerIndex}>{itemData[fieldName]}</SiteNames>
                <BarRating>
                <ComplimentProgressBar id={"bar1_"+headerIndex} completed={(compliments/compliants)*100} color={`#29a21f`} value={compliments}/>
                <ComplimentProgressBar id={"bar2_"+headerIndex}  completed={100} color={`#edac1a`} value={compliants}/>
                </BarRating>
                <PreviousNumber>
                 <ColorCode id={'heading'+headerIndex}><Description>{`(${prev_compliments})`} </Description><Description>{`(${prev_complaints})`} </Description>
                 </ColorCode> 
                 </PreviousNumber>
            </SiteRatings>
        );
    }

  return (
    <CustomerContainer>
      <Card className="data-card">
        <TableHeader>
          <ScoreTitle id="customerTitle">
            <ScoreKpiName>{title}</ScoreKpiName>
            <div
              className="right-card-view"
              onMouseOver={(e) => handleHover(title, "enter")}
              onMouseOut={(e) => handleHover("", "leave")}
            >
              <img className="cross-class" alt="tile_info" src={i_icon} />
            </div>
          </ScoreTitle>
        </TableHeader>
        <HeaderContainer id="customer">
          {headers.map((colName, colIdx) => {
            return (
              <>
                <SiteHeading alignIndex={colIdx}>{colName}</SiteHeading>
                <ColorCode>
                  <ComplimentCode>
                    <OrangeCirlce color={`#edac1a`} />
                    <Description id="complaints">Complaints</Description>
                  </ComplimentCode>
                  <ComplimentCode>
                    <OrangeCirlce color={`#29a21f`} />
                    <Description>Compliments</Description>
                  </ComplimentCode>
                </ColorCode>
              </>
            );
          })}
        </HeaderContainer>
        <MainContainer>
          {data.map((itemData, index) => {
            return (
              <DataContainer>
                {headers.map((headerKey, headerIndex) =>
                  renderData(headerKey, itemData, headerIndex)
                )}
              </DataContainer>
            );
          })}
        </MainContainer>
        {showPopup === title && (
          <ScorePopupContainer
            showPopup={showPopup === title}
            bottomSetting={title}
          >
            <span className="What-you-can-expect">
              <span
                key={toolTipText}
                dangerouslySetInnerHTML={createMarkup(toolTipText)}
              />
            </span>
          </ScorePopupContainer>
        )}
      </Card>
    </CustomerContainer>
  );
}

export default CustomerComplaints