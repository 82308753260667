import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import TradingUiComponent from "../../../../scorecardNewDesign/TradingProfitably/Screens/TradingUiComponent";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const Services = ({ services, getServicesData, layout }) => {
  useEffect(() => {
    getServicesData(KPINames.SERVICES);
  }, []);

  if (services.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (services.data) {
    return (
      <TradingUiComponent
        dataFor={"Month"}
        data={services.data}
        index={2}
        weekNumber={layout?.data?.weekNumber}
        expectedRefreshTimestamp={layout?.data?.schedules?.servicesData}
      />
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
    authInfo: {
      userData: { name },
    },
  } = state;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  const services = scorecardImprovedInfo[KPINames.SERVICES];
  return { name, services, layout };
}

const mapDispatchToProps = {
  getServicesData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Services);
