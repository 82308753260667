export const breakpoints = {
	smallUp: 0,
	mediumUp: 480,
	largeUp: 768,
	xlargeUp: 992,
	xxlargeUp: 1200,
	xxxlargeUp: 1920,
	xxxxlargeUp: 2560,
	mediumDown: ['down', 479],
	largeDown: ['down', 767],
	xlargeDown: ['down', 991],
	xlargeDownLandscape: ['landscape', 991],
	xxlargeDown: ['down', 1199],
	smallOnly: [0, 479],
	mediumOnly: [480, 767],
	largeOnly: [768, 991],
	xlargeOnly: [992, 1199],
	xxlargeOnly: [1200, 1919],
	xxxlargeOnly: [1920, 2559],
	xxxxlargeOnly: [2560, 9999999999999],
	mobileLandscape: ['mobileLandscape']
};
