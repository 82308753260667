/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DropDownContainer, SelectionContainer, SelectionText, Checkbox } from './button.Styled'
import tick_box from "../../images/icons/tick-box.png";

export function ChecklistSelection(props) {
    const { t } = useTranslation();
    const { arrayList, selectedValue } = props
    useEffect(() => {

    }, []);
    return (
        <DropDownContainer data-test="drop-down" style={{ width: props.width, }}>
           {arrayList.map((item, index) => {
                let checkList =props.noStarProp ? selectedValue.find(element => element === item): selectedValue.find(element => element === index);
                return (<SelectionContainer key={index} data-test="selection-cell" onClick={() => {
                    props.selectedMethod(item, index)
                }}>
                    <SelectionText data-test="selection-text">{props.noStarProp ? item : index === 0 ? t('ALL') : t(`${item}Star`)}</SelectionText>
                    {selectedValue[0] === 0 || selectedValue[0]==='ALL Views'|| selectedValue[0] === 'ALL' ?
                        <img src={tick_box} alt='tick' height={20} width={20} /> :
                        checkList === index || checkList===item ?
                            <img src={tick_box} alt='tick' height={20} width={20} /> :
                            <Checkbox />
                    }
                </SelectionContainer>
                )
            }
            )}
            </DropDownContainer>
    )
}

export default ChecklistSelection
