/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/5/2023
*/

import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import isMobile from "../../../../helpers/isMobile";
import useWindowDimensions, {
  responsiveGraphWidthBar,
} from "../../TradingProfitably/utils/WindowDimensions";

import * as d3 from "d3";
import { axisBottom, axisLeft } from 'd3';
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../actions/scorecardActions";
import { CIRCULAR_RED, bpThemeGreenColor, graphLabelGrayColor } from "../../../../constants/colors";
import {
  defaultSortOrder,
  renderSortIcons,
  sortData
} from "../../../Scorecard/utils";
import { KPINames } from "../../../ScorecardImproved/ScorecardImprovedConstants";
import { TileHeader } from "../../Customer/Screens/Components/TileHeader";
import { Colorgradient, Exclaimation, ExclaimationText, OldDataBlur, Oldtext, TooltipValue } from "../../Customer/style/Customer.styled";
import { Olddatatradingapprovee } from "../../TradingProfitably/Styles/TradingUi.styled";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import { titleGenerator } from "../../scorecardConstants";
import {
  DataContainer,
  GraphContainer,
  Headertradingtable,
  Legend,
  LegendColor,
  LegendName,
  MainContainer,
  Olddatasafetycontent,
  Olddatasafetyview,
  Peopleheadingcell,
  SafetyOverviewTable,
  SafetyOverviewlBox,
  ScoreGraphContainer,
  ScoreLegendContainer,
  TotalRow
} from "../styles/SafetyUi.styled";
import { SAFETY_FIRST_LEGENDS, SUBGROUPS, returnRequiredRegionalHeader } from "../utils/SafetyUtils";

function SafetyOperationOverView(props) {
  const { renderItems, dataFor , siteSafety} = props;
  const { headers, data, title, state, graph, toolTipText, expectedRefreshTimestamp, lastWeekUpdated } =
    renderItems;
  const [graphData, setGraphData] = useState([]);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  let headerNames = data?.length > 0 ? defaultSortOrder(data) : [];
  const [sortedData, setSortedData] = useState(data);
  const [sortState, setSortState] = useState(headerNames);
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [decPos, setDecPos] = useState(1);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
  const [blur, setBlur] = useState(props.weekNumber !== lastWeekUpdated);
  useEffect(() => {
    setGraphData([]);
    setSortedData([]);
    setSortState([]);
    async function correctGraph() {
      //let graphData = await getGraphData(graph);
      await setGraphData([...graph]);

      setSortedData(data);
      let sotdata = defaultSortOrder(data);
      setSortState(sotdata);
    }
    correctGraph();
  }, [props, width]);

  useEffect(() => {
    render();
  }, [graphData])

  const renderSafetyData = (headerKey, itemData, headerIndex) => {
    let key = returnRequiredRegionalHeader(headerKey);
    return headerIndex === 0 ? (
      <Peopleheadingcell renderedIn="table" index={headerIndex}>
        {itemData[key]}
      </Peopleheadingcell>
    ) : (
      <Peopleheadingcell renderedIn="table" index={headerIndex}>
        {itemData[key] === "NA" ? itemData[key] : `${itemData[key]}`}
      </Peopleheadingcell>
    );
  };

  const renderSortIcon = (colName) => {
    return true;
  };



  const sortItem = (keyName, sortState, sortedData) => {
    let result;
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    const rowsWithNA = data.filter((rows) => rows[keyName] === "NA");
    if (isAnyColumnSorted === "NONE") {
      const initialSortState = Object.keys(data[0]).map((item) => ({
        name: item,
        sortState: "NONE",
      }));
      result = sortData(
        keyName,
        initialSortState,
        data.filter((rows) => rows[keyName] !== "NA")
      );
    } else {
      result = sortData(
        keyName,
        sortState,
        sortedData.filter((rows) => rows[keyName] !== "NA")
      );
    }
    const currentState = result.sortedState.find((val) => val.name === keyName);
    setSortState(result.sortedState);
    setSortedData(
      currentState.sortState === "ASC"
        ? [...result.sortingData, ...rowsWithNA]
        : [...rowsWithNA, ...result.sortingData]
    );
  };

  const handleHover = (event, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  const render = () => {
    if (Array.isArray(graphData) && graphData.length > 0) {
      const svg = d3.select(`svg[class=${'safety-kpi-bar'}]`);
      const everything = svg.selectAll("*");
      everything.remove();

      const graphContainer = d3.select(`#${'safety-first'}`);

      var height = +svg.attr('height');
      const margin = { top: 10, right: 50, bottom: 40, left: isMobile() ? 33 : 40 };
      const innerHeight = height - margin.top - margin.bottom - 12;

      const reduceValue = graphData.reduce(function (prev, curr) {
        const prevValue = parseInt(prev.security) + parseInt(prev.nearMiss) + parseInt(prev.injuries) + parseInt(prev.g);
        const currValue = parseInt(curr.security) + parseInt(curr.nearMiss) + parseInt(curr.injuries) + parseInt(curr.g);
        return prevValue > currValue ? prev : curr;
      });
      let maxValue = parseInt(reduceValue?.security) + parseInt(reduceValue?.nearMiss) + parseInt(reduceValue?.injuries) + parseInt(reduceValue?.g)

      const minReduceValue = graphData.reduce(function (prev, curr) {
        const prevValue = parseInt(prev.security) + parseInt(prev.nearMiss) + parseInt(prev.injuries) + parseInt(prev.g);
        const currValue = parseInt(curr.security) + parseInt(curr.nearMiss) + parseInt(curr.injuries) + parseInt(curr.g);
        return prevValue < currValue ? prev : curr;
      });
      let minValue = parseInt(minReduceValue?.security) + parseInt(minReduceValue?.nearMiss) + parseInt(minReduceValue?.injuries) + parseInt(minReduceValue?.g)

      const xScale = d3.scaleBand()
        .rangeRound([0, isMobile() ? 320 : responsiveGraphWidthBar(width)])
        .domain(graphData.map(d => dataFor == "Month" ? (`W${(d.weekNumber > 52) ? (d.weekNumber % 52) : d.weekNumber}`) : (d.weekNumber)))
        .padding([isMobile() ? 0.6 : 0.45]);

      const yScale = d3.scaleLinear()
        .domain([0, maxValue])
        .range([120, 0]);
      const graphPosition = svg.append('g')
        .attr('transform', `translate(${margin.left - 25},${margin.top})`);

      const xAxis = axisBottom(xScale)

      const yAxis = axisLeft(yScale)
        .tickValues([minValue > 0 ? 0.1 : 0, (0 + maxValue) / 2, maxValue])
        .tickPadding(2)
        .tickSize(isMobile() ? -285 : -responsiveGraphWidthBar(width));

      graphPosition.append('g').call(yAxis);
      const color = d3.scaleOrdinal()
        .domain(SUBGROUPS)
        .range(['#007f00', '#99cc00', '#004f00', '#999999']);

      const stackedData = d3.stack()
        .keys(SUBGROUPS)
        (graphData);

      const barTooltip = graphContainer
        .append("div")
        .style("display", "none")
        .attr("class", isMobile() ? "barTooltipMobile" : "barTooltip");

      const mousemove = function (event) {
        const data = event.currentTarget.__data__.data;
        const rect = event.currentTarget.getBoundingClientRect();
        barTooltip
          .html(`
                    <div>${data.security} Security</div>
                    <div>${data.g} G+</div>
                    <div>${data.injuries} Injury</div>
                    <div>${data.nearMiss} Near Miss</div>
                `)
          .style("display", "block");
        barTooltip
          .style("left", isMobile() ? `${rect.left - 75}px` : `${rect.left - 125}px`)
          .style("top", `${rect.bottom - 60}px`);
      }
      const mouseleave = function (event) {
        barTooltip
          .style("display", "none");
      }

      // Adding week number below chart
      svg.append("g")
        .selectAll("text")
        .data(stackedData[0])
        .enter()
        .append("text")
        .attr('class', 'scale-x')
        .attr('transform', `translate(${(isMobile() ? margin.left : (margin.left)) + 4},0)`)
        .attr("x", function (d) { return xScale(dataFor == "Month" ? `W${(d.data.weekNumber > 52) ? (d.data.weekNumber % 52) : d.data.weekNumber}` : d.data.weekNumber); })
        .attr("y", innerHeight )
        .text(function (d) { return dataFor == "Month" ? `W${(d.data.weekNumber > 52) ? (d.data.weekNumber % 52) : d.data.weekNumber}` : d.data.weekNumber; })
        .style("fill", state == "OK" ? bpThemeGreenColor : graphLabelGrayColor);

      // Adding SC Info box
      const scInfoBoxGroup = svg.append("g");
      // Use selectAll and enter to create multiple sc-info-box elements
      const scInfo = scInfoBoxGroup
        .selectAll(".sc-info-box") // Select all existing sc-info-box elements (initially empty)
        .data(stackedData[0]) // Bind the data to the selection
        .enter() // Enter the selection to create new elements for each data point
        .append("foreignObject") // Append a foreignObject element for each data point
        .attr("width", 60)
        .attr("height", 24)
        .attr('transform', `translate(${(isMobile() ? margin.left : (margin.left))-12},${-margin.top})`)
        .attr("x", function (d) { return xScale(dataFor == "Month" ? `W${(d.data.weekNumber > 52) ? (d.data.weekNumber % 52) : d.data.weekNumber}` : d.data.weekNumber); })
        .attr("y", innerHeight + 30);

      // Within each foreignObject, append a div with the class "sc-info-box"
      scInfo
        .append("xhtml:div")
        .attr("class", "sc-info-box")
        .text(function(d){return  `SC: ${d.data.safetyConv }`   })
        .style("fill", state === "OK" ? bpThemeGreenColor : graphLabelGrayColor)
        .style("color", function(d) {
          return getSafetyConversationColor(d.data)} );

      svg.append("g")
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(stackedData)
        .enter().append("g")
        .attr('transform', `translate(${isMobile() ? margin.left : (margin.left)},${margin.top})`)
        .attr("fill", function (d) { return color(d.key); })
        .selectAll("rect")
        //.attr("d", topRoundedColumn(0, height, innerHeight, 36))
        // enter a second time = loop subgroup per subgroup to add all rectangles
        .data(function (d) { return d; })
        .enter().append("rect")
        .attr("x", function (d) { return xScale(dataFor == "Month" ? `W${(d.data.weekNumber > 52) ? (d.data.weekNumber % 52) : d.data.weekNumber}` : d.data.weekNumber); })
        .attr("y", function (d) { return yScale(d[1]); })
        .attr("height", function (d) { return yScale(d[0]) - yScale(d[1]); })
        .attr("width", isMobile() ? 25 : 30)
        .on("mousemove", mousemove)
        .on("touchstart", mousemove)  // for mobile touch
        .on("mouseleave", mouseleave);

      window.addEventListener("mousewheel", mouseleave);
      window.addEventListener("touchmove", mouseleave);// it's for mobile version
      return () => {
        window.removeEventListener('mousewheel', mouseleave);
        window.removeEventListener("touchmove", mouseleave); // it's for mobile version
      };
    }
  };


  const getSafetyConversationColor = (item) => {
    let total = parseInt(item.security) + parseInt(item.nearMiss) + parseInt(item.injuries) + parseInt(item.g);
    return total > parseInt(item.safetyConv) ? CIRCULAR_RED : bpThemeGreenColor;
  }

  const getHeaderState = () => {
    const item = graph[graph.length-1]
    let total = parseInt(item.security) + parseInt(item.nearMiss) + parseInt(item.injuries) + parseInt(item.g);
    return total > parseInt(item.safetyConv) ? "DANGER" : "OK";
  }

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
    } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };
 
  const colorDecider = (keyName, sortState) => {
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    if (isAnyColumnSorted === 'NONE') {
      return ""
    }
    else if (isAnyColumnSorted === 'ASC') {
      return "#007833"
    }
    else {
      return "#8B0000"
    }
  }

  function loadExcel() {
    props.exportToExcel(titleGenerator[title], headers, sortedData);
  }

  return (
    <SafetyOverviewlBox>
      <OldDataBlur boolTrue={blur} id="OldDataBlur">
        {title === 'Waste' || title === 'Till Losses' ? <>
          {
            props.weekNumber - 1 === 0 ?
              <>
                {!blur && 52 !== lastWeekUpdated && (
                  <Exclaimation
                    onMouseEnter={(e) => blurHover(e, "enter")}
                    onMouseLeave={(e) => blurHover(e, "leave")}
                    id="exclamationContainer"
                  >
                    <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                  </Exclaimation>
                )}
              </>
              :
              <>
                {!blur && props.weekNumber - 1 !== lastWeekUpdated && (
                  <Exclaimation
                    onMouseEnter={(e) => blurHover(e, "enter")}
                    onMouseLeave={(e) => blurHover(e, "leave")}
                    id="exclamationContainer"
                  >
                    <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                  </Exclaimation>
                )}
              </>
          }
        </> : <>
          {!blur && props.weekNumber !== lastWeekUpdated && (
            <Exclaimation
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
              id="exclamationContainer"
            >
              <ExclaimationText id="ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
        </>}
        {oldPopUpVisible && (
          <TooltipValue
            id="tooltipvalue"
            top={blurRect.top + 70}
            left={blurRect.left + 15}
          >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
        )}
        <TileHeader
          showPopup={showPopup}
          exportToExcel={() => loadExcel()}
          excelLoader={props.excelLoader}
          kpiName={props.kpiName}
          handleHover={(e, value) => handleHover(e, value)}
          title={title}
          state='GREY'
        />
        {showPopup && (
          <ScorePopupContainerWeb
            top={top}
            left={left - 20}
            width={18}
            id='popUpDesktop'
            textDescription={toolTipText}
          />
        )}
        <ScoreGraphContainer id="ScoreGraphContainer">
          <ScoreLegendContainer id="ScoreLegendContainer">
            {SAFETY_FIRST_LEGENDS.map((legend, idxLegend) => {
              return (
                <Legend key={idxLegend} id="legend">
                  <LegendColor color={legend.color} id="legendColor" />
                  <LegendName id="legendName">{legend.name}</LegendName>
                </Legend>
              );
            })}
          </ScoreLegendContainer>
          <GraphContainer id={'safety-first'}>
            <svg
              id="chart"
              className={"safety-kpi-bar"}
              width="95%"
              height="215"
              style={{ marginTop: "10px" }}
            />

          </GraphContainer>
        </ScoreGraphContainer>
        <SafetyOverviewTable>

        
        <Headertradingtable>
          {headers.map((item, index) =>
            <Peopleheadingcell
              onClick={() =>
                sortItem(
                  returnRequiredRegionalHeader(item),
                  sortState,
                  sortedData
                )
              }
              index={index}
            >
              <Colorgradient index={index}>{item}</Colorgradient>
              {renderSortIcon(item) &&
                renderSortIcons(
                  returnRequiredRegionalHeader(item),
                  sortState,
                  true,
                  state
                )}
            </Peopleheadingcell>

          )}
        </Headertradingtable>
        <MainContainer height={200}>
          {!isEmpty(sortedData)
            ? sortedData.filter(key => key.sites !== "Total").map((itemData, index) => {
              return (
                <DataContainer
                  key={index}
                  alignIndex={index}
                  id="dataContainer"
                >
                  {headers.map((headerKey, headerIndex) =>
                    renderSafetyData(headerKey, itemData, headerIndex)
                  )}
                </DataContainer>
              );
            })
            : null}
        </MainContainer>
        <TotalRow>
          {!isEmpty(sortedData)
            ? sortedData.filter(key => key.sites === 'Total').map((itemData, index) => {
              return (
                <> {headers.map((headerKey, headerIndex) =>
                  renderSafetyData(headerKey, itemData, headerIndex)
                )}
                </>
              );
            })
            : null}
        </TotalRow>
        </SafetyOverviewTable>
      </OldDataBlur>
      {blur && (
        <Olddatasafetyview id="OldDataFullview">
          <Olddatasafetycontent id="olddatacontent">
            <Oldtext id="old">You're viewing old data</Oldtext>
            <Oldtext id="data_not_updated_text">This data has not been updated yet.</Oldtext>
            <Oldtext id="oldtext">
              Scheduled updates occurs on&nbsp;{siteSafety}
            </Oldtext>
            <Olddatatradingapprovee id="olddataapprove" onClick={() => setBlur(!blur)}>
              continue with old data
            </Olddatatradingapprovee>
          </Olddatasafetycontent>
        </Olddatasafetyview>
      )}

    </SafetyOverviewlBox>
  );
}

const mapStateToProps = (state) => {
  const { scorecardInfo, loader, authInfo, scorecardImprovedInfo } = state;
  const { userData } = authInfo;
  const { showLoader } = loader;
  const { excelLoader, kpiName, siteNames, noSelected, selectedManagerName } =
    scorecardInfo;
    const layout = scorecardImprovedInfo[KPINames.LAYOUT];
    const siteSafety = layout?.data?.schedules?.siteSafety;
  return {
    excelLoader,
    kpiName,
    showLoader,
    siteNames,
    noSelected,
    selectedManagerName,
    userData,
    siteSafety
  };
};

const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
    scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(SafetyOperationOverView)
);

