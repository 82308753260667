export const LANGUAGE_URLS = [
    // dev env urls with countries
    { code: 'PL', env: 'dev', api_url: 'https://api-pl.dev-eos.bpglobal.com', country_code: 'PL' },
    { code: 'UK', env: 'dev', api_url: 'https://api-gb.dev-eos.bpglobal.com', country_code: 'EN' },
   
    { code: 'ES', env: 'dev', api_url: 'https://uik56twnnb.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'ES' },
    { code: 'LU', env: 'dev', api_url: 'https://oaja6z0fk5.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'LU' },
    { code: 'PT', env: 'dev', api_url: 'https://4j9vps0mj7.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'PT' },
   // end dev

    // Test env urls with countries
    { code: 'PL', env: 'test', api_url: 'https://api-pl.test-eos.bpglobal.com', country_code: 'PL' },
    { code: 'UK', env: 'test', api_url: 'https://api-gb.test-eos.bpglobal.com', country_code: 'EN' },

    { code: 'ES', env: 'test', api_url: 'https://276fdksxgk.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'ES' },
    { code: 'LU', env: 'test', api_url: 'https://hwcfzp6v8c.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'LU' },
    { code: 'PT', env: 'test', api_url: 'https://15w7eqzg4a.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'PT' },
   // end test

   // UAT env urls with 
    { code: 'PL', env: 'uat', api_url: 'https://api-pl.uat-eos.bpglobal.com', country_code: 'PL' },
    { code: 'UK', env: 'uat', api_url: 'https://api-gb.uat-eos.bpglobal.com', country_code: 'EN' },

    { code: 'ES', env: 'uat', api_url: 'https://fdkc90rv9c.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'ES' },
    { code: 'LU', env: 'uat', api_url: 'https://o8i23d1h97.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'LU' },
    { code: 'PT', env: 'uat', api_url: 'https://bgmkc5yc43.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'PT' },
    // end UAT

    // prod env urls 
    { code: 'PL', env: 'prod', api_url: 'https://api-pl.eos.bpglobal.com', country_code: 'PL' },
    { code: 'UK', env: 'prod', api_url: 'https://api-gb.eos.bpglobal.com', country_code: 'EN' },

    { code: 'ES', env: 'prod', api_url: 'https://klh57ubn8f.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'ES' },
    { code: 'LU', env: 'prod', api_url: 'https://vew1u4k6rb.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'LU' },
    { code: 'PT', env: 'prod', api_url: 'https://fupwj9vbi8.execute-api.eu-west-1.amazonaws.com/dev', country_code: 'PT' }
   // end prod
];

export const WEBSOCKET_URLS = [
    { env: 'dev', api_url: 'wss://urvvmzdsrj.execute-api.eu-west-1.amazonaws.com/dev' },
    { env: 'test', api_url: 'wss://vosbzk2x3i.execute-api.eu-west-1.amazonaws.com/test' },
    { env: 'uat', api_url: 'wss://hi5uiwh7ak.execute-api.eu-west-1.amazonaws.com/uat' },
    { env: 'prod', api_url: 'wss://7e0qpjd5pe.execute-api.eu-west-1.amazonaws.com/prod' }
];

export const LOGIN_URLS = [
    { env: 'dev', url: `https://sda-eos-dev.auth.eu-west-1.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=` },
    { env: 'test', url: `https://test-eos.auth.eu-west-1.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=` },
    { env: 'uat', url: `https://uat-eos.auth.eu-west-1.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=` },
    { env: 'prod', url: `https://enablement-of-sales.auth.eu-west-1.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=` }
];

export const LOGOUT_URLS = [
    { env: 'dev', url: `https://sda-eos-dev.auth.eu-west-1.amazoncognito.com/logout?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=` },
    { env: 'test', url: `https://test-eos.auth.eu-west-1.amazoncognito.com/logout?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=` },
    { env: 'uat', url: `https://uat-eos.auth.eu-west-1.amazoncognito.com/logout?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=` },
    { env: 'prod', url: `https://enablement-of-sales.auth.eu-west-1.amazoncognito.com/logout?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=` }
]