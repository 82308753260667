/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 06/12/2021
* Author : Sunil Vora
*/
import { constants } from '../constants/reducerConstants';
import { tableServices } from '../services/tableServices';
import { getLogoutUrl } from '../helpers/commons';

export const tableActions = {
    getAverageRatingsData,
    getAverageSortResponse
}

function getAverageSortResponse(sortState) {
    return async (dispatch, getState) => {
        dispatch({ type: constants.SET_SORT_STATE, data: sortState });
    }
}

function getAverageRatingsData(filter, sortState) {

    return async (dispatch, getState) => {
        dispatch({ type: constants.GOOGLE_RATING_START_LOADER, });;
        const { authInfo } = getState();
        return tableServices.getAverageRatings(authInfo.userData.id, authInfo.sessionData.id_token, filter, authInfo.languageCode).then(data => {
            dispatch({ type: constants.SAVE_AVERAGE_RATINGS_DATA, data });

        },
            error => {
                dispatch({ type: constants.CLEAR_AVERAGE_RATINGS_DATA });
                if (error && error.message === 'Failed to fetch') {
                    dispatch({type: constants.CLEAR_AUTH_STORE});
                    window.location.href = getLogoutUrl(authInfo.languageCode);
                }
            })
    }
}