import React from 'react';
import { useTranslation } from 'react-i18next';
import {store} from "./../../helpers/store";
import isMobile from "../../helpers/isMobile";
import { Footerdisclaimer, Footerlist, FooterListItems, Footertext, PagefooterData } from "./Page.styled";



function PageFooter() {
  let userCode = store.getState().authInfo?.userData?.countryCode;
  const { t } = useTranslation();
  let footerData = {
    year: new Date().getFullYear(),
    company: "BP Digital Assets",

    links: [
      {
        title: t('legal notice'),
        url: t('url_legal_notice'),
      },
      {
        title: t('privacy statement'),
        url: t('url_privacy_statement'),
      },
      {
        title: t('cookies'),
        url: t('url_cookies'),
      },
    ],

    disclaimer: t('disclaimer'),
  };
  const renderFooterList = footerData.links.map((link, index) => {
    if(!isMobile()){
        return (
              // <li className="mb-2 mb-md-0 ml-4 mr-2" key={index}>
                <a className="text-decoration-none" href={link.url} target="_blank">
                  {link.title}
                </a>
              // </li>
            );
    }
    else{
      return(
        <FooterListItems  key={index}>
                <a className="text-decoration-none" href={link.url}>
                  {link.title}
                </a>
      </FooterListItems>
      )
    }
    }
  
  );
  return (
    <>
    { 
    !isMobile() ?
      <footer className="footer mt-auto py-3 text-muted" data-test="sdaFooter">
        <PagefooterData>
           <Footerdisclaimer>
              <Footertext id='logo'>BP p.l.c</Footertext>
              <Footertext>Copyright &copy;&nbsp;1996-{footerData.year}&nbsp;&nbsp;{t('bp_footer')}</Footertext>
           </Footerdisclaimer>
           <Footerlist>
            {renderFooterList}
           </Footerlist>
         </PagefooterData>
      </footer>
      :
      <footer className="footer mt-auto py-3 text-muted" data-test="sdaFooter">
        <div className="mt-4 mb-4">
        <div className='row'>
        <FooterListItems className='font-weight-bold' id='para'>
              {(userCode==='PL')? <>Copyright &copy;&nbsp;{footerData.year}&nbsp;&nbsp;<br></br>{t('bp_footer')}</>:<>
              <Footertext id='logo' displaydevice='mobile'>BP p.l.c</Footertext><Footertext  displaydevice='mobile'>Copyright &copy;&nbsp;{footerData.year}&nbsp;&nbsp;{t('bp_footer')}</Footertext></>}
         </FooterListItems>
        </div>
          <div className="row">
           {renderFooterList}
          </div>
          </div>
        </footer>
        }
     
    </>
  );
}

export default PageFooter;
