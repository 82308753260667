import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import { constants } from '../constants/reducerConstants';

import { loader } from './loader';
import { authInfo } from './authReducer';
import { kpiInfo } from './kpiReducer';
import { sitesInfo } from './siteReducer';
import { tableInfo } from './tableReducer';
import { googleAverageScoreInfo } from './googleScoreReducer';
import { graphInfo } from './graphReducer';
import { errorInfo } from './errorReducer';
import { eMaintenanceInfo } from './eMaintanenceReducer';
import { NotificationInfo } from './NotificationReducer';
import { financialInfo } from './financialReducer';
import { adminInfo } from './adminReducer';
import { scorecardInfo } from './scorecardReducer';
import { menuInfo } from './menuReducer';
import { scorecardImprovedInfo } from './scorecardImprovedReducer';

const appReducer = combineReducers({
    loader,
    authInfo,
    kpiInfo,
    sitesInfo,
    tableInfo,
    googleAverageScoreInfo,
    graphInfo,
    errorInfo,
    eMaintenanceInfo,
    NotificationInfo,
    financialInfo,
    adminInfo,
    scorecardInfo,
    menuInfo,
    scorecardImprovedInfo,
});

const rootReducer = (state, action) => {
    if (action.type === constants.CLEAR_AUTH_STORE) {
        storageSession.removeItem('persist:root')
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};



export default rootReducer;