/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 31/03/2021
* Author : Arun Rapolu
*/
import { constants } from '../constants/reducerConstants';

const initialState = {
    sessionData: {},
    redirectUrl: '/',
    userData:{},
    callRefreshToken: true,
    languageCode: ''
}

export function authInfo(state = initialState, action) {
    switch (action.type) {
        case constants.SAVE_AUTH_INFO:
            return {
                ...state,
                sessionData: action.data
            };
        case constants.CLEAR_AUTH_INFO:
            return {
                ...state,
                sessionData: {}
            };
        case constants.SAVE_REDIRECT_PATH:
            return {
                ...state,
                redirectUrl: action.pathName
            };
        case constants.CLEAR_REDIRECT_PATH:
            return {
                ...state,
                redirectUrl: null
            };
        case constants.SAVE_USER_DATA:
            return {
                ...state,
                userData: action.data.body
            };
        case constants.CLEAR_USER_DATA:
            return {
                ...state,
                userData: {}
            };
        case constants.SAVE_LANGUAGE_CODE:
            return {
                ...state,
                languageCode: action.code
            };
        case constants.CLEAR_AUTH_STORE:
            return initialState
        default:
            return state
    }
}
