export const siteRatingsPerWeek = [
    {
        week: 1,
        rating: 2,
        type: 'Site'
    },
    {
        week: 2,
        rating: 2,
        type: 'Site'
    },
    {
        week: 3,
        rating: 3,
        type: 'Site'
    },
    {
        week: 4,
        rating: 2,
        type: 'Site'
    },
    {
        week: 5,
        rating: 2,
        type: 'Site'
    },
    {
        week: 6,
        rating: 2,
        type: 'Site'
    },
    {
        week: 7,
        rating: 2,
        type: 'Site'
    },
    {
        week: 8,
        rating: 4,
        type: 'Site'
    },
    {
        week: 9,
        rating: 3.5,
        type: 'Site'
    },
    {
        week: 10,
        rating: 4,
        type: 'Site'
    },
    {
        week: 11,
        rating: 3.5,
        type: 'Site'
    },
    {
        week: 12,
        rating: 4,
        type: 'Site'
    },
    {
        week: 13,
        rating: 3.5,
        type: 'Site'
    },
    {
        week: 14,
        rating: 4,
        type: 'Site'
    },
    {
        week: 15,
        rating: 3.5,
        type: 'Site'
    },
    {
        week: 16,
        rating: 4,
        type: 'Site'
    },
    {
        week: 17,
        rating: 3.5,
        type: 'Site'
    },
    {
        week: 1,
        rating: 2,
        type: 'Country'
    },
    {
        week: 2,
        rating: 2,
        type: 'Country'
    },
    {
        week: 3,
        rating: 5,
        type: 'Country'
    },
    {
        week: 4,
        rating: 1,
        type: 'Country'
    },
    {
        week: 5,
        rating: 1,
        type: 'Country'
    },
    {
        week: 6,
        rating: 4,
        type: 'Country'
    },
    {
        week: 7,
        rating: 2,
        type: 'Country'
    },
    {
        week: 8,
        rating: 1,
        type: 'Country'
    },
    {
        week: 9,
        rating: 5,
        type: 'Country'
    },
    {
        week: 10,
        rating: 1,
        type: 'Country'
    },
    {
        week: 11,
        rating: 3,
        type: 'Country'
    },
    {
        week: 12,
        rating: 1,
        type: 'Country'
    },
    {
        week: 13,
        rating: 1,
        type: 'Country'
    },
    {
        week: 14,
        rating: 2,
        type: 'Country'
    },
    {
        week: 15,
        rating: 3.5,
        type: 'Country'
    },
    {
        week: 16,
        rating: 4,
        type: 'Country'
    },
    {
        week: 17,
        rating: 3.5,
        type: 'Country'
    },
]

export const averageRating = [
      {
        "period": "2021",
        "number": "11",
        "value": 3.7,
        "columnHeader": "Nov'21",
		"type": "Site"
      },
      {
        "period": "2021",
        "number": "10",
        "value": 4.1,
        "columnHeader": "Oct'21",
		"type": "Site"
      },
      {
        "period": "2021",
        "number": "09",
        "value": 4.7,
        "columnHeader": "Sep'21",
		"type": "Site"
      },
      {
        "period": "2021",
        "number": "08",
        "value": 3.8,
        "columnHeader": "Aug'21",
		"type": "Site"
      },
      {
        "period": "2021",
        "number": "07",
        "value": 4.4,
        "columnHeader": "Jul'21",
		"type": "Site"
      },
      {
        "period": "2021",
        "number": "06",
        "value": 4.3,
        "columnHeader": "Jun'21",
		"type": "Site"
      },
      {
        "period": "2021",
        "number": "05",
        "value": 4.3,
        "columnHeader": "May'21",
		"type": "Site"
      },
      {
        "period": "2021",
        "number": "04",
        "value": 3,
        "columnHeader": "Apr'21",
		"type": "Site"
      },
      {
        "period": "2021",
        "number": "01",
        "value": 3,
        "columnHeader": "Jan'21",
		"type": "Site"
      },
      {
        "period": "2020",
        "number": "12",
        "value": 3.3,
        "columnHeader": "Dec'20",
		"type": "Site"
      },
      {
        "period": "2021",
        "number": "11",
        "value": 2.0,
        "columnHeader": "Nov'21",
		"type": "Country"
      },
      {
        "period": "2021",
        "number": "10",
        "value": 3.0,
        "columnHeader": "Oct'21",
		"type": "Country"
      },
      {
        "period": "2021",
        "number": "09",
        "value": 3.5,
        "columnHeader": "Sep'21",
		"type": "Country"
      },
      {
        "period": "2021",
        "number": "08",
        "value": 4.5,
        "columnHeader": "Aug'21",
		"type": "Country"
      },
      {
        "period": "2021",
        "number": "07",
        "value": 1.7,
        "columnHeader": "Jul'21",
		"type": "Country"
      },
      {
        "period": "2021",
        "number": "06",
        "value": 3.3,
        "columnHeader": "Jun'21",
		"type": "Country"
      },
      {
        "period": "2021",
        "number": "05",
        "value": 2.3,
        "columnHeader": "May'21",
		"type": "Country"
      },
      {
        "period": "2021",
        "number": "04",
        "value": 4,
        "columnHeader": "Apr'21",
		"type": "Country"
      },
      {
        "period": "2021",
        "number": "01",
        "value": 4,
        "columnHeader": "Jan'21",
		"type": "Country"
      },
      {
        "period": "2020",
        "number": "12",
        "value": 4.3,
        "columnHeader": "Dec'20",
		"type": "Country"
      },
      {
        "period": "2021",
        "number": "11",
        "value": 2.7,
        "columnHeader": "Nov'21",
		"type": "Manager"
      },
      {
        "period": "2021",
        "number": "10",
        "value": 2.1,
        "columnHeader": "Oct'21",
		"type": "Manager"
      },
      {
        "period": "2021",
        "number": "09",
        "value": 1.7,
        "columnHeader": "Sep'21",
		"type": "Manager"
      },
      {
        "period": "2021",
        "number": "08",
        "value": 4.8,
        "columnHeader": "Aug'21",
		"type": "Manager"
      },
      {
        "period": "2021",
        "number": "07",
        "value": 2.4,
        "columnHeader": "Jul'21",
		"type": "Manager"
      },
      {
        "period": "2021",
        "number": "06",
        "value": 2.3,
        "columnHeader": "Jun'21",
		"type": "Manager"
      },
      {
        "period": "2021",
        "number": "05",
        "value": 4.8,
        "columnHeader": "May'21",
		"type": "Manager"
      },
      {
        "period": "2021",
        "number": "04",
        "value": 2,
        "columnHeader": "Apr'21",
		"type": "Manager"
      },
      {
        "period": "2021",
        "number": "01",
        "value": 5,
        "columnHeader": "Jan'21",
		"type": "Manager"
      },
      {
        "period": "2020",
        "number": "12",
        "value": 4.6,
        "columnHeader": "Dec'20",
		"type": "Manager"
      },
      {
        "period": "2021",
        "number": "11",
        "value": 2.6,
        "columnHeader": "Nov'21",
		"type": "ChannelOfTrade"
      },
      {
        "period": "2021",
        "number": "10",
        "value": 3,
        "columnHeader": "Oct'21",
		"type": "ChannelOfTrade"
      },
      {
        "period": "2021",
        "number": "09",
        "value": 3.7,
        "columnHeader": "Sep'21",
		"type": "ChannelOfTrade"
      },
      {
        "period": "2021",
        "number": "08",
        "value": 2.8,
        "columnHeader": "Aug'21",
		"type": "ChannelOfTrade"
      },
      {
        "period": "2021",
        "number": "07",
        "value": 1.6,
        "columnHeader": "Jul'21",
		"type": "ChannelOfTrade"
      },
      {
        "period": "2021",
        "number": "06",
        "value": 2.5,
        "columnHeader": "Jun'21",
		"type": "ChannelOfTrade"
      },
      {
        "period": "2021",
        "number": "05",
        "value": 4,
        "columnHeader": "May'21",
		"type": "ChannelOfTrade"
      },
      {
        "period": "2021",
        "number": "04",
        "value": 3.8,
        "columnHeader": "Apr'21",
		"type": "ChannelOfTrade"
      },
      {
        "period": "2021",
        "number": "01",
        "value": 2,
        "columnHeader": "Jan'21",
		"type": "ChannelOfTrade"
      },
      {
        "period": "2020",
        "number": "12",
        "value": 3.9,
        "columnHeader": "Dec'20",
		"type": "ChannelOfTrade"
      }
	];

export const lineToColor = (key) => {
    switch (key) {
        case 'Country':
            return '#009900';
        case 'Regional Manager':
            return '#edac1a';
        case 'COFO':
            return '#666666';
        case 'COCO':
            return '#999999';
        case 'DODO':
            return '#0099cc';
        case 'DODO CS':
            return '#99cc00';
        case 'CODO':
            return '#99cc00';
        case 'DOFO':
            return '#5c7a00';
        case 'Site':
            return '#bedbbf';
        default:
            break;
    }
};

export const financialLineColor = (key, isBottom, isScoreGraph, isDanger) => {
    switch (key) {
        case 'ActualSales':
            return isBottom ? '#edac1a' : '#666';
        case 'PlannedSales':
            return '#666666';
        case 'ActualSalesUK':
            return '#EDAC1A'
        default:
            break;
    }
};

    
    export const emaintenanceData = {
        "totalCount" : {
        "open": 10,
        "on_time": 5,
        "overdue" : 5
        },
        "sites" : [
        {
        "sapId": "0P445",
        "siteName": "Adel SF Connect",
        "onTimeTasks": [
        {
        "description": "str",
        "taskReference": "#8182073",
        "category": "Coffee Machine",
        "taskIdentifier": "str",
        "completeByDate": "str"
        },
        {
        "description": "str",
        "taskReference": "#8182075",
        "category": "Coffee Machine",
        "taskIdentifier": "str",
        "completeByDate": "str"
        },
        {
        "description": "str",
        "taskReference": "#8182078",
        "category": "Coffee Machine",
        "taskIdentifier": "str",
        "completeByDate": "str"
        },
        {
        "description": "str",
        "taskReference": "#8182073",
        "category": "Fuel Pump",
        "taskIdentifier": "str",
        "completeByDate": "str"
        },
        {
        "description": "str",
        "taskReference": "#8182072",
        "category": "Fuel Pump",
        "taskIdentifier": "str",
        "completeByDate": "str"
        }
        ],
        "overdueTasks": [
        {
        "description": "str",
        "taskReference": "#8182080",
        "category": "Coffee Machine",
        "taskIdentifier": "str",
        "completeByDate": "str"
        },
        ]
        },
        {
        "sapId": "0P445",
        "siteName": "Addington SF",
        "onTimeTasks": [
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182075",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182078",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182072",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            }
            ],
            "overdueTasks": [
            {
            "description": "str",
            "taskReference": "#8182080",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            ]
        },
            {
        "sapId": "0P445",
        "siteName": "Addington Filling SF",
        "onTimeTasks": [
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182075",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182078",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182072",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            }
            ],
            "overdueTasks": [
            {
            "description": "str",
            "taskReference": "#8182080",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            ]
        },
        {
        "sapId": "0P445",
        "siteName": "Abbey Service Station",
        "onTimeTasks": [
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182075",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182078",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182072",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            }
            ],
            "overdueTasks": [
            {
            "description": "str",
            "taskReference": "#8182080",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            ]
        },
        {
        "sapId": "0P445",
        "siteName": "Autohalt FS779",
        "onTimeTasks": [
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182075",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182078",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182072",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            }
            ],
            "overdueTasks": [
            {
            "description": "str",
            "taskReference": "#8182080",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            ]
        },
        {
        "sapId": "0P445",
        "siteName": "Ashtley Service Station",
        "onTimeTasks": [
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182075",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182078",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182072",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            }
            ],
            "overdueTasks": [
            {
            "description": "str",
            "taskReference": "#8182080",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            ]
        },
        {
        "sapId": "0P445",
        "siteName": "Ashford SF Connect",
        "onTimeTasks": [
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182075",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182078",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182073",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            {
            "description": "str",
            "taskReference": "#8182072",
            "category": "Fuel Pump",
            "taskIdentifier": "str",
            "completeByDate": "str"
            }
            ],
            "overdueTasks": [
            {
            "description": "str",
            "taskReference": "#8182080",
            "category": "Coffee Machine",
            "taskIdentifier": "str",
            "completeByDate": "str"
            },
            ]
        }
        ]
        }
