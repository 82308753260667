/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 08/02/2021
 * Author : Sunil Vora
 */

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CircleView, TicketSubText,
    ArrowIcon,
    DescriptionText,
    ArrowContainer,
    DisFlex,
    PopupContainer,
    PopupContainerMobile
  } from "./EMaintenance.styled";
import i_down from "../../images/icons/i-down.svg";
import i_arrow from "../../images/icons/i-arrow.svg";
import isMobile from "../../helpers/isMobile";
import { NotificationCloseContainer } from "../Notification/Notification.styled";

function SubTaskView(props) {
    const { t } = useTranslation();
    const [showSubtask, setShowSubTask] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [taskDetail, setTaskDetail] = useState("");
    const [top, setTop] = useState(0);
    const [left, setLeft] =useState(0);
    const { site, task } = props;
    const taskList = site.tasks;

    function handleHover(event, type, detail) {
      const currRect = event.target.getBoundingClientRect();
      setShowPopup(type === "enter");
      setTaskDetail(detail);
      setTop(currRect.top);
      setLeft(currRect.left);
    }

    const handleHoverMobile = (event, type, detail) => {
      const currRect = event.target.getBoundingClientRect();
      setShowPopup(type === "enter");
      setTaskDetail(detail);
      setTop(currRect.top);
      setLeft(currRect.left);
  }

    if ((task === 'open' && site.tasks.length > 0) || (task === 'overdue' && site.tasks.length > 0))
    {
      return (
        <>
        <DisFlex isCoManager={props.isCoManager} fulltext={showSubtask} onClick={() => setShowSubTask(!showSubtask)}>
        <div style={{flex:1}}>
        <CircleView isCoManager={props.isCoManager} taskType={task} type='subText'>{site.tasks.length}</CircleView>
        </div>
        <div style={{flex:7}}>
        <TicketSubText taskType={task} type='subText' fulltext={showSubtask}>{site.siteName}</TicketSubText>        
        </div>
        <div style={{flex:2}}>
         <ArrowContainer>
          <ArrowIcon onClick={() => setShowSubTask(!showSubtask)} src={showSubtask ? i_down : i_arrow} />
         </ArrowContainer>
         </div>
         </DisFlex>
       
        { !isMobile() && showSubtask && 
          taskList.map(list => (
            <>
            <DescriptionText taskType={task} onMouseEnter={(e) => !isMobile() && handleHover(e, 'enter', list)} onMouseLeave={(e) => !isMobile() && handleHover(e, 'leave', '')}>{`${list.referenceId}   ${list.taskCategory.trim()}`}
            {showPopup && !isMobile()?
              <PopupContainer top={top} left={left} showPopup={showPopup} taskType={task}>
                <span className="What-you-can-expect">
                  {`#${taskDetail.referenceId}`}
                </span>
                <br />
                <span className="Lorem-ipsum-dolor-si">
                  {taskDetail.taskCategory?.trim()}
                  <br />
                  {t("Complete due")}:{" "}
                  <br />
                  {taskDetail.completeByTimestamp ? taskDetail.completeByTimestamp?.substring(
                    0,
                    10
                  ) : taskDetail.completeByTimestamp?.substring(
                    0,
                    10
                  )}
                </span>
              </PopupContainer>:<></>
            }
            </DescriptionText>   
          </>
          ))
        }
        { isMobile() && showSubtask && 
          taskList.map(list => (
            <>
            <DescriptionText taskType={task} onClick ={(e)=> handleHoverMobile(e, 'enter', list)} onMouseEnter={(e) => handleHoverMobile(e, 'enter', list)} onMouseLeave={(e) => isMobile() && handleHoverMobile(e, 'leave', '')}>{`${list.referenceId}   ${list.taskCategory.trim()}`}
            {showPopup && isMobile()? 
             <div style={{height:'100%', overflow: 'hidden',  
             backgroundColor:'transparent'}} >
           {/* <NotificationCloseContainer onClick={(e) => isMobile()? taskDetail.length === 0? handleHoverMobile(e, 'enter', list):handleHoverMobile(e, 'leave', ''):null}/> */}
            <PopupContainerMobile top={top} showPopup={showPopup} taskType={task} left={left}>
              <span className="What-you-can-expect">
                  {`#${taskDetail.referenceId}`}
                </span>
                <br />
                <span className="Lorem-ipsum-dolor-si">
                  {taskDetail.taskCategory?.trim()}
                  <br />
                  {/* {t("Complete due")}:{" "} */}
                  <br />
                  {taskDetail.completeByTimestamp ? taskDetail.completeByTimestamp.substring(
                    0,
                    10
                  ) : taskDetail.completeByTimestamp?.substring(
                    0,
                    10
                  )}
                </span>
              </PopupContainerMobile></div> :<></>}
            </DescriptionText>   
          </>
          ))
        }
        </> 
    );
    }
    return null;
}

export default SubTaskView;