const firebaseConfig = (languageCode) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        switch (languageCode?.toUpperCase()) {
            case 'PL':
                return {
                    apiKey: "AIzaSyBJLZL0Hz40FJFVpj3n1aYzZ_Hggc4ogAY",
                    authDomain: "store-performance-scorecard-pl.firebaseapp.com",
                    projectId: "store-performance-scorecard-pl",
                    storageBucket: "store-performance-scorecard-pl.appspot.com",
                    messagingSenderId: "1086689793867",
                    appId: "1:1086689793867:web:b8d68fcb88b26795887ace",
                    measurementId: "G-YZJ6DBMHPX",
                };
            case 'UK':
                return {
                    apiKey: "AIzaSyAWKD-MQkPcV8QcDm_wgcPR3Dz2f1lddqU",
                    authDomain: "store-performance-scorecard.firebaseapp.com",
                    projectId: "store-performance-scorecard",
                    storageBucket: "store-performance-scorecard.appspot.com",
                    messagingSenderId: "394848479404",
                    appId: "1:394848479404:web:aed817e3c024590eac5709",
                    measurementId: "G-7EN9V3GE32"
                }
            default:
                return {}
        }
    } else {
        return {
            apiKey: "AIzaSyBsSKouP7RPa4adTuvxB-j4iGvmMJrkqzo",
            authDomain: "dev-scorecard.firebaseapp.com",
            projectId: "dev-scorecard",
            storageBucket: "dev-scorecard.appspot.com",
            messagingSenderId: "148772335891",
            appId: "1:148772335891:web:a31c25695bbefacb946ae6",
            measurementId: "G-11Q50KZG00"
        };
    }
};

export default firebaseConfig;
