/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 05/06/2022
 * Author : Sunil Vora
 */

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { FinancialWidgetContainer, TitleContainer, ShopSalesTitle,
LFL, Plan, BottomSales, TopSales, SiteContainer, Rank, SiteName,
LFLValue, PlanValue, RankContainer, LabelContainer,GraphContainer,
LegendContainer, Planned, Actual, PlannedText, ActualText,
HighlightContainer, Symbol } from "./FinancialWidget.styled";
import FinancialLineGraph from "./FinancialLineGraph";

function FinancialWidget(props) {
    const { t } = useTranslation();
    const { financialData } = props;
    const [showGraphForIndex, setshowGraphForIndex] = useState('');
    const [sales, setSales] = useState([]);
    const { topFive, lastFive, week } = financialData;

    const handleClick = (index, isBottom, financeData) => {
        let sales = [];
        const graphIndex = isBottom ? `${index}-bottom` : `${index}-top`;
        if (graphIndex === showGraphForIndex) {
            setshowGraphForIndex('');
        } else {
            financeData.dailySales.forEach(sale => {
                sales.push({ type: "ActualSales", value: sale.salesActual, ...sale },
                { type: "PlannedSales", value: sale.plannedSales, ...sale })
            })
            setSales(sales);
            setshowGraphForIndex(graphIndex);
        }
    }

    const financialWidgetRow = (financeData, index, week, isBottom) => {
        const graphIndex = isBottom ? `${index}-bottom` : `${index}-top`;
        return (
            <>
                <LabelContainer isBottom={isBottom} isOpen={showGraphForIndex === graphIndex} onClick={() => handleClick(index, isBottom, financeData)}>
                    <RankContainer>
                        <HighlightContainer>
                            <Rank>
                                {`${financeData.siteRank}/${financeData.lastRank}`}
                            </Rank>
                            <Symbol isBottom={isBottom} />
                        </HighlightContainer>
                        <SiteName fullText={showGraphForIndex === graphIndex}>{financeData.siteName}</SiteName>
                    </RankContainer>
                    <LFLValue>{`${financeData.vsLfl}%`}</LFLValue>
                    <PlanValue>{`${financeData.vsPlan}%`}</PlanValue>
                </LabelContainer>
                {showGraphForIndex === graphIndex && 
                    <GraphContainer>
                        <LegendContainer>
                            <Planned></Planned>
                            <PlannedText>Plan</PlannedText>
                            <Actual isBottom={isBottom}></Actual>
                            <ActualText>Actual</ActualText>
                        </LegendContainer>
                        <FinancialLineGraph xScale={250} graphId='widgetContainer' graphClass='financialLineGraph' sales={sales} isBottom={isBottom} weekNum={week} />
                    </GraphContainer>}
            </>
        );
    }

    return (
        <FinancialWidgetContainer>
            <TitleContainer>
                <ShopSalesTitle>{`${t("Bottom Sales")} (Wk ${week})`}</ShopSalesTitle>
                <LFL>{t("LFL")}</LFL>
                <Plan>{t("Plan")}</Plan>
            </TitleContainer>
            <BottomSales>
                {lastFive.map((value, index) => (
                    <SiteContainer>
                        {financialWidgetRow(value, index, week, true)}
                    </SiteContainer>
                ))}
            </BottomSales>
            <TitleContainer isTop={true}>
                <ShopSalesTitle>{`${t("Top Sales")} (Wk ${week})`}</ShopSalesTitle>
                <LFL>{t("LFL")}</LFL>
                <Plan>{t("Plan")}</Plan>
            </TitleContainer>
            <TopSales>
                {topFive.map((value, index) => (
                    <SiteContainer>
                        {financialWidgetRow(value, index, week)}
                    </SiteContainer>
                ))}
            </TopSales>
        </FinancialWidgetContainer>
    );
}

function mapStateToProps(state) {
    const { financialInfo } = state;
    const { financialData } = financialInfo;
    return { financialData };
}

export default compose(connect(mapStateToProps))(
    FinancialWidget
);