/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 14/07/2022
 * Author : Sunil Vora
 */

import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../actions/scorecardActions";
import { isLargeDeskTop } from "../../helpers/isLargeDeskTop";
import isMobile from "../../helpers/isMobile";
import { isTablet } from "../../helpers/isTablet";
import EmaintenaceLoading from "../E-Maintenance/EmaintenaceLoading";
import AnnoucementMobile from "../ScorecardImproved/AnnoucementMobile";
import { KPINames } from "../ScorecardImproved/ScorecardImprovedConstants";
import BarComponent from "./BarComponent";
import CustomerComplaints from "./CustomerComplaints";
import EmaintenanceWrapper from "./EmaintenanceWrapper";
import LoyaltyIssuance from "./LoyaltyIssuance";
import MobileTable from "./MobileTable";
import {
  CategoryContainer,
  CategoryContainerNew,
  CategoryContainerTradingProfTopRow,
  CustomerWrapper,
  KpiName,
  KpiWeek,
  Mobilecategory,
  PeopleMobile,
  SafetyContentMobile,
  ScoreCardContainer,
  ScoreCardTitle,
  ScoreTitleContainer,
  Tiledisplaymobile,
} from "./ScoreCard.styled";
import ScorecardFilter from "./Scorecardfilter";
import TableComponent from "./TableComponent";
import TileComponent from "./TileComponent";
import VoiceOfCustomer from "./VoiceOfCustomer";



function ScoreCard(props) {
  const { scorecardData, error, name, layout } = props;
  let tradingProfitability;
  let safetyFirst;
  let peopleOverview;
  let customer;
  
  if (scorecardData && scorecardData.length > 0) {
     scorecardData.map((object, index) => {
      switch (object.sectionHeader) {
        case 'Trading Profitably':
          tradingProfitability = scorecardData[index]
          break;
        case 'Safety First Operations':
          safetyFirst = scorecardData[index]
          break;
        case 'People':
          peopleOverview = scorecardData[index]
          break;
        default:
          customer = scorecardData[index]

      }
    })
  }
  
  let [scroll, setScroll] = useState(false);

  // First method to call when component will load
  useEffect(() => {
    props.getScorecardData();
    props.getScorecardLayoutData(KPINames.LAYOUT);
  }, []);


  // Rendering table view/tile when access over mobile for People/Safety First Operations first Section of tile
  const mobileTable = (comp, idx, week) => {
    return (
      <>
        <MobileTable data={comp} weekNumber={week} id="mobileTable"/>
      </>
    );
  };

  function changeEvent() {
    setScroll(false);
  }

  // Method used to render Tile UI based on type from Api Response
  const renderComponents = (comp, idx, isTileWider) => {
    // set width for each tile based on kpi title
    let width = 0;
    if (comp.title === "People Overview") {
      width = 35.3;
    } else if (comp.title === "Clocking on time") {
      width = 10;
    } else if (
      comp.title === "Safety Operations Overview () = last week count"
    ) {
      width = 35.3;
    }
    switch (comp.type) {
      case "tile":
        return (
          // Rendering all tiles inside(Trading Profitably), Clocking on time, Unpublished schedules,Dark Sites, Mand. Tasks
          <TileComponent
            regionalManager={name}
            width={width}
            isTileWider={isTileWider}
            data={comp}
            weekNumber={safetyFirst.weekNumber}
            changeEvent={() => changeEvent()}
            event={scroll}
            compId= {idx}
            id="tileComponent"
          />
        );
      //Desktop view table view for People and safety first operations sections first tiles
      case "table":
        return (
          <TableComponent
            width={width}
            data={comp}
            weekNumber={safetyFirst.weekNumber}
            id="tableComponent"
          />
        );
      // rendering bar chart(Regional Safety Scores)
      case "chart":
        return (
          <BarComponent
            data={comp}
            graphId="safety-first"
            graphClass="safety-kpi-bar"
            weekNumber={safetyFirst.weekNumber}
            mobileDevice={true}
            width={12}
            id="barComponent"
          />
        );
      case "site complaints":
        return <CustomerComplaints data={comp} id="customerComplaints"/>;

      // rendering tile with title (Loyalty Issuance () = last week count) desktop/mobile view
      case "comparator2":
        return (
          <LoyaltyIssuance data={comp} weekNumber={safetyFirst.weekNumber} id="loyaltyIssuance"/>
        );
      // rendering tile with title Customer Satisfaction for desktop/mobile version
      case "tile2":
        return <VoiceOfCustomer data={comp} weekNumber={safetyFirst.weekNumber} id="voiceOfCustomer"/>;
      // return null
      default:
        break;
    }
  };


  // render views for desptop resolution is greater than width of 1962
  if (isLargeDeskTop() && !isMobile() && !isTablet()) {
    return !isEmpty(scorecardData) ? (
      <>
        <ScorecardFilter id="scoreCardFilter"/>
        <ScoreCardContainer id="scoreCardContainer">
          <ScoreTitleContainer id="scoreTitleContainer" >
            <ScoreCardTitle id="scoreCardTitle">Store Performance Scorecard</ScoreCardTitle>
          </ScoreTitleContainer>
          {

            scorecardData && scorecardData.map((object) => {
              switch (object.sectionHeader) {
                case 'Trading Profitably':
                  return (
                    <>
                      <Row>
                        <div className="colDiv">
                          <CategoryContainerTradingProfTopRow id="scoreCardTradingRow">
                            <KpiName id="kpiName">
                              {`${tradingProfitability?.sectionHeader}: `}&nbsp;
                            </KpiName>
                            <KpiWeek id="kpiWeek">{` Week ${tradingProfitability?.weekNumber
                              }, ${tradingProfitability?.startDate} - ${tradingProfitability?.endDate
                              }`}</KpiWeek>
                          </CategoryContainerTradingProfTopRow>
                          <Row xs={1} md={2} xl={4} className="tradingProfitability" id="row">
                            {tradingProfitability?.components
                              .slice(0, 3)
                              .map((comp, idx) => renderComponents(comp, idx, true))}
                            {tradingProfitability?.components
                           .slice(5, 6)
                          .map((comp, idx) => renderComponents(comp, idx, true))}
                          </Row>
                        </div>
                        <div className="colDiv">
                          <CategoryContainerTradingProfTopRow id="scoreCardTradingRow">
                            {tradingProfitability?.weekNumber - 1 === 0 ? <KpiWeek className="weekLeft" id="kpiWeek">{` Week ${52
                              }`}</KpiWeek> : <KpiWeek id="kpiWeek" className="weekLeft">{` Week ${tradingProfitability?.weekNumber - 1
                                }`}</KpiWeek>}
                          </CategoryContainerTradingProfTopRow>
                          <Row xs={1} md={2} xl={3} className="tradingProfitabilityLargeDevices" id="row">
                            {tradingProfitability?.components
                              .slice(3, 5)
                              .map((comp, idx) => renderComponents(comp, idx, true))}
                          </Row>
                        </div>
                      </Row>
                    </>
                  )

                case 'Safety First Operations':
                  return (
                    <div className="customerContainer">
                      <CategoryContainer id="categoryContainer">
                        <KpiName id="kpiName">{`${safetyFirst?.sectionHeader}: `}&nbsp;</KpiName>
                        <KpiWeek id="kpiWeek">{` Week ${safetyFirst?.weekNumber
                          }, ${safetyFirst?.startDate} - ${safetyFirst?.endDate
                          }`}</KpiWeek>
                      </CategoryContainer>
                      <Row xs={1} md={2} xl={3} className="padding-adjustments-other" id="row">
                        {safetyFirst?.components.map((comp, idx) =>
                          idx !== 1 &&
                          renderComponents(comp, idx)
                        )}
                      </Row>
                      <Row xs={1} md={2} xl={2} className="padding-adjustments-other-safety-last-row" id="row">
                        {safetyFirst?.components.slice(1, 2).map((comp, idx) =>
                          renderComponents(comp, idx)
                        )}
                        <EmaintenanceWrapper id="eMainWrapper" />
                      </Row>

                    </div>
                  )

                case 'People':
                 return( <div className="customerContainer">
                    <CategoryContainer id="categoryContainer">
                      <KpiName id="kpiName">{`${peopleOverview?.sectionHeader}: `}&nbsp;</KpiName>
                      <KpiWeek id="kpiWeek">{` Week ${peopleOverview?.weekNumber
                        }, ${peopleOverview?.startDate} - ${peopleOverview?.endDate
                        }`}</KpiWeek>
                    </CategoryContainer>
                    <Row xs={1} md={2} xl={3} className="padding-adjustments-other" id="row">
                      {peopleOverview?.components.map((comp, idx) =>
                        renderComponents(comp, idx)
                      )}
                    </Row>
                  </div>)

                default:
                 return( <div className="customerContainer">

                    <CategoryContainer id="categoryContainer">
                      <KpiName id="kpiName">{`${customer?.sectionHeader}: `}&nbsp;</KpiName>
                      <KpiWeek id="kpiWeek">{` Week ${customer?.weekNumber
                        }, ${customer?.startDate} - ${customer?.endDate
                        }`}</KpiWeek>
                    </CategoryContainer>
                    <Row xs={1} md={2} xl={3} className="padding-adjustments-other" id="row">
                      {customer?.components.map((comp, idx) =>
                        renderComponents(comp, idx)
                      )}
                    </Row>
                  </div>)

              }

            })
          }
        </ScoreCardContainer>
      </>
    ) : (
      <>
        <ScorecardFilter />
        <EmaintenaceLoading />
      </>
    );
  } 
  else if (!isMobile() && !isTablet()) {
    return !isEmpty(scorecardData) ? (
      <>
        <ScorecardFilter id="scoreCardFilter"/>
        <ScoreCardContainer id="scoreCardContainer">
          <ScoreTitleContainer id="scoreTitleContainer">
            <ScoreCardTitle id="scoreCardTitle">Store Performance Scorecard</ScoreCardTitle>
          </ScoreTitleContainer>
          {scorecardData && scorecardData.map((object) => {

            switch (object.sectionHeader) {
              case 'Trading Profitably':

                return (<>
                  <>
                    <CategoryContainerTradingProfTopRow id="scoreCardTradingRow">
                      <KpiName id="kpiName">
                        {`${tradingProfitability?.sectionHeader}: `}&nbsp;
                      </KpiName>
                      <KpiWeek id="kpiWeek">{` Week ${tradingProfitability?.weekNumber
                        }, ${tradingProfitability?.startDate} - ${tradingProfitability?.endDate
                        }`}</KpiWeek>
                    </CategoryContainerTradingProfTopRow>
                    <Row xs={1} md={2} xl={4} className="tradingProfitability" id="row">
                      {tradingProfitability?.components
                        .slice(0, 3)
                        .map((comp, idx) => renderComponents(comp, idx, true))}
                       {tradingProfitability?.components
                        .slice(5, 6)
                        .map((comp, idx) => renderComponents(comp, idx, true))}
                    </Row>
                  </>
                  <>
                    <CategoryContainerNew >
                      {tradingProfitability?.weekNumber - 1 === 0 ? <KpiWeek id = "kpiWeek" className="weekLeft">{` Week ${52
                        }`}</KpiWeek> : <KpiWeek id = "kpiWeek" className="weekLeft">{` Week ${tradingProfitability?.weekNumber - 1
                          }`}</KpiWeek>}
                    </CategoryContainerNew>
                    <Row xs={1} md={2} xl={3} className="tradingProfitability" id="row">
                      {tradingProfitability?.components
                        .slice(3, 5)
                        .map((comp, idx) => renderComponents(comp, idx, true))}
                    </Row>
                  </>
                </>
                )

              case 'Safety First Operations':
                return (<div className="customerContainer">
                  <CategoryContainer id="categoryContainer">
                    <KpiName id="kpiName">{`${safetyFirst?.sectionHeader}: `}&nbsp;</KpiName>
                    <KpiWeek id = "kpiWeek">{` Week ${safetyFirst?.weekNumber
                      }, ${safetyFirst?.startDate} - ${safetyFirst?.endDate
                      }`}</KpiWeek>
                  </CategoryContainer>
                  <Row xs={1} md={2} xl={3} className="padding-adjustments-other" id="row">
                    {safetyFirst?.components.map((comp, idx) =>
                      idx !== 1 &&
                      renderComponents(comp, idx)
                    )}
                  </Row>
                  <Row xs={1} md={2} xl={2} className="padding-adjustments-other-safety-last-row" id="row">
                    {safetyFirst?.components.slice(1, 2).map((comp, idx) =>
                      renderComponents(comp, idx)
                    )}
                    <EmaintenanceWrapper id="eMainWrapper"/>
                  </Row>

                </div>
                )


              case 'People':
                return (<div className="customerContainer">
                  <CategoryContainer id="categoryContainer">
                    <KpiName id="kpiName">{`${peopleOverview?.sectionHeader}: `}&nbsp;</KpiName>
                    <KpiWeek id = "kpiWeek">{` Week ${peopleOverview?.weekNumber
                      }, ${peopleOverview?.startDate} - ${peopleOverview?.endDate
                      }`}</KpiWeek>
                  </CategoryContainer>
                  <Row xs={1} md={2} xl={3} className="padding-adjustments-other" id="row">
                    {peopleOverview?.components.map((comp, idx) =>
                      renderComponents(comp, idx)
                    )}
                  </Row>
                </div>
                )
              default:
                return (<div className="customerContainer">

                  <CategoryContainer id="categoryContainer">
                    <KpiName id="kpiName">{`${customer?.sectionHeader}: `}&nbsp;</KpiName>
                    <KpiWeek id = "kpiWeek">{` Week ${customer?.weekNumber
                      }, ${customer?.startDate} - ${customer?.endDate
                      }`}</KpiWeek>
                  </CategoryContainer>
                  <Row xs={1} md={2} xl={3} className="padding-adjustments-other" id="row">
                    {customer?.components.map((comp, idx) =>
                      renderComponents(comp, idx)
                    )}
                  </Row>
                </div>
                )

            }

          })

          }

        </ScoreCardContainer>
      </>
    ) : (
      <>
        <ScorecardFilter />
        <EmaintenaceLoading />
      </>
    );
  } else if (!isMobile()) {
    return !isEmpty(scorecardData) ? (
      <>
        <ScoreCardContainer id="scoreCardContainer" displaydevice={isMobile()}>
          <>
            <Mobilecategory id="mobileCategory">
              <KpiName id="kpiName" displaydevice="mobile">
                {`${tradingProfitability?.sectionHeader} `}&nbsp;
              </KpiName>
              <KpiWeek id = "kpiWeek" displaydevice="mobile">{` Week ${tradingProfitability?.weekNumber
                }, ${tradingProfitability?.startDate} - ${tradingProfitability?.endDate
                }`}</KpiWeek>
            </Mobilecategory>
            <Row xs={1} sm={2} className="g-4 tradingProfitabilitymobile" id="row">
              {tradingProfitability?.components
                .slice(0, 3)
                .map((comp, idx) => renderComponents(comp, idx, true))}
            </Row>
            <Mobilecategory id="mobileCategory">
              <KpiWeek id = "kpiWeek" displaydevice="mobile">{` Week ${tradingProfitability?.weekNumber - 1
                }`}</KpiWeek>
              <Row
                xs={1}
                sm={2}
                className="g-4 tradingProfitabilitymobile adjustments"
              >
                {tradingProfitability?.components
                  .slice(3, 5)
                  .map((comp, idx) => renderComponents(comp, idx, true))}
              </Row>
            </Mobilecategory>
          </>
          <>
            <Mobilecategory id="mobileCategory">
              <KpiName id="kpiName" displaydevice="mobile">
                {`${safetyFirst?.sectionHeader}`}&nbsp;
              </KpiName>
              <KpiWeek id = "kpiWeek" displaydevice="mobile">{` Week ${safetyFirst?.weekNumber
                }, ${safetyFirst?.startDate} - ${safetyFirst?.endDate
                }`}</KpiWeek>
              {safetyFirst?.components.slice(0, 1).map((comp, idx) => (
                <Row xs={1} className="g-4 safetyTablet" id="row">
                  {renderComponents(comp, idx)}
                </Row>
              ))}
              <Row xs={1} sm={3} className="g-4 safetyTablet" id="row">
                {safetyFirst?.components
                  .slice(1, 4)
                  .map((comp, idx) => renderComponents(comp, idx))}
              </Row>
              <EmaintenanceWrapper id="eMainWrapper"/>
            </Mobilecategory>
          </>
        </ScoreCardContainer>
      </>
    ) : (
      <>
        <EmaintenaceLoading />
      </>
    );
  } else {
    return !isEmpty(scorecardData) ? (
      <>
     
        <ScoreCardContainer id="scoreCardContainer" displaydevice={isMobile()}>

        <AnnoucementMobile announcement={layout?.data?.announcment}/>
          <ScorecardFilter id="scoreCardFilter"/>

          {scorecardData.map((object, index) => {
            switch (object.sectionHeader) {
              case 'Trading Profitably':
                 return(
<>
            <Mobilecategory id="mobileCategory">
              <KpiName id="kpiName" displaydevice="mobile">
                {`${tradingProfitability?.sectionHeader} `}&nbsp;
              </KpiName>
              <KpiWeek id = "kpiWeek" displaydevice="mobile">{` Week ${tradingProfitability?.weekNumber
                }, ${tradingProfitability?.startDate} - ${tradingProfitability?.endDate
                }`}</KpiWeek>
            </Mobilecategory>
            <Row
              xs={1}
              sm={2}
              className="g-4 tradingProfitHeightmobile padding-adjustments"
              onScroll={() => setScroll(true)}
            >
              {tradingProfitability?.components
                .slice(0, 3)
                .map((comp, idx) => renderComponents(comp, idx, true))}
                 {tradingProfitability?.components
                .slice(5, 6)
                .map((comp, idx) => renderComponents(comp, idx, true))}
            </Row>
            <Mobilecategory id="mobileCategory">
              {tradingProfitability?.weekNumber - 1 === 0 ? <KpiWeek id = "kpiWeek" className="weekLeft">{` Week ${52
                }`}</KpiWeek> : <KpiWeek id = "kpiWeek" className="weekLeft">{` Week ${tradingProfitability?.weekNumber - 1
                  }`}</KpiWeek>}
              {tradingProfitability?.components.slice(3, 4).map((comp, idx) => (
                <Row
                  xs={1}
                  className="g-4 tradingProfitabilitymobile adjustments"
                >
                  {renderComponents(comp, idx, true)}
                </Row>
              ))}
            </Mobilecategory>
            <Mobilecategory id="mobileCategory">
              {tradingProfitability?.weekNumber - 1 === 0 ? <KpiWeek id = "kpiWeek" className="weekLeft">{` Week ${52
                }`}</KpiWeek> : <KpiWeek id = "kpiWeek" className="weekLeft">{` Week ${tradingProfitability?.weekNumber - 1
                  }`}</KpiWeek>}
              {tradingProfitability?.components.slice(4, 5).map((comp, idx) => (
                <Row
                  xs={1}
                  className="g-4 tradingProfitabilitymobile adjustments"
                >
                  {renderComponents(comp, idx, true)}
                </Row>
              ))}
            </Mobilecategory>
          </>
                 )
              case 'Safety First Operations':
                return(
                  <Mobilecategory id="mobileCategory">
                  <KpiName id="kpiName" displaydevice="mobile">
                    {`${safetyFirst?.sectionHeader}`}&nbsp;
                  </KpiName>
                  <KpiWeek id = "kpiWeek" displaydevice="mobile">{` Week ${safetyFirst?.weekNumber
                    }, ${safetyFirst?.startDate} - ${safetyFirst?.endDate
                    }`}</KpiWeek>
                  <SafetyContentMobile>
                    {safetyFirst?.components.slice(0, 1).map((comp, idx) => (
                      <Row xs={1} className="g-4 tradingProfitabilitymobile" id="row">
                        {mobileTable(comp, idx, safetyFirst?.weekNumber)}
                      </Row>
                    ))}
                  </SafetyContentMobile>
                  <SafetyContentMobile className="marginTopSafetyContentMobile">
                    {safetyFirst?.components.slice(1, 2).map((comp, idx) => (
                      <Row
                        xs={1}
                        className="g-4 tradingProfitabilitymobile adjustments"
                      >
                        {renderComponents(comp, idx)}
                      </Row>
                    ))}
                  </SafetyContentMobile>
                  <Tiledisplaymobile id="tileDisplayedMobile">
                    {safetyFirst?.components
                      .slice(2, 4)
                      .map((comp, idx) => renderComponents(comp, idx))}
                  </Tiledisplaymobile>
                  <EmaintenanceWrapper  id="eMainWrapper"/>
                </Mobilecategory>
                )
              case 'People':
                return (
                  <Mobilecategory id="mobileCategory">
              <KpiName id="kpiName" displaydevice="mobile">
                {`${peopleOverview?.sectionHeader}`}&nbsp;
              </KpiName>
              <KpiWeek id = "kpiWeek" displaydevice="mobile">{` Week ${peopleOverview?.weekNumber
                }, ${peopleOverview?.startDate} - ${peopleOverview?.endDate
                }`}</KpiWeek>
              <PeopleMobile>
                {peopleOverview?.components.slice(0, 1).map((comp, idx) => (
                  <Row xs={1} className="g-4 tradingProfitabilitymobile" id="row">
                    {mobileTable(comp, idx, peopleOverview?.weekNumber)}
                  </Row>
                ))}
              </PeopleMobile>
              <Tiledisplaymobile id="tileDisplayedMobile">
                {peopleOverview?.components
                  .slice(1, 3)
                  .map((comp, idx) => renderComponents(comp, idx))}
              </Tiledisplaymobile>
            </Mobilecategory>
                )
              default:
               return(<Mobilecategory id="mobileCategory">
                <KpiName id="kpiName" displaydevice="mobile">
                  {`${customer?.sectionHeader}`}&nbsp;
                </KpiName>
                <KpiWeek id = "kpiWeek" displaydevice="mobile">{` Week ${customer?.weekNumber
                  }, ${customer?.startDate} - ${customer?.endDate
                  }`}</KpiWeek>
                <CustomerWrapper id="customerWrapper">
                  {customer?.components.slice(0, 2).map((comp, idx) => (
                    <Row xs={1} id="row" className={idx === 0 ? "g-4 customermobile" : "customermobileNewPos"}>
                      {renderComponents(comp, idx)}
                    </Row>
                  ))}
                </CustomerWrapper>
              </Mobilecategory>)

            }
          })
          }
        </ScoreCardContainer>
      </>
    ) : (
      <>
        <EmaintenaceLoading />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { scorecardInfo, authInfo, scorecardImprovedInfo } = state;
  const { scorecardData, error, listOfSites } = scorecardInfo;
  const { userData, languageCode } = authInfo;
  const { name } = userData;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return { scorecardData, error, name, languageCode, listOfSites, userData, layout };
}

const mapDispatchToProps = {
  getScorecardData: scorecardActions.getScorecardData,
  getScorecardLayoutData: (kpiName) =>
  scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ScoreCard);
