/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 09/12/2021
* Author : Sunil Vora
*/
import { constants } from '../constants/reducerConstants';
import { graphServices } from '../services/graphServices';
import { getLogoutUrl } from '../helpers/commons';

export const graphActions = {
    getSiteData
}

function getSiteData(filter) {
    return async (dispatch, getState)  => {
        const { authInfo } = getState();
        dispatch({ type: constants.GOOGLE_LINE_GRAPH_LOADER });
        return graphServices.getSiteAverageRatings(authInfo.userData.id, authInfo.sessionData.id_token, filter, authInfo.languageCode).then(data => {
           dispatch({type: constants.SAVE_SITE_LINE_DATA, data});
       },
       error => {
           dispatch({type: constants.CLEAR_SITE_LINE_DATA});
           if (error && error.message === 'Failed to fetch') {
            dispatch({type: constants.CLEAR_AUTH_STORE});
            window.location.href = getLogoutUrl(authInfo.languageCode);
           }
       })
   }
}