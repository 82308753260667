/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 02/11/2022
* Author : Sunil Vora
*/
import { constants } from '../constants/reducerConstants';
import { menuServices } from '../services/menuServices';
import { showErrorPage, getLogoutUrl, startLoader, stopLoader } from '../helpers/commons';

export const menuActions = {
    saveCurrentMenu,
    getMenuData
}


function saveCurrentMenu(menuItem) {
    return (dispatch) => {
        dispatch({ type: constants.SAVE_CURRENT_MENUITEM, menuItem });
   }
}

function getMenuData() {
    return (dispatch, getState)  => {
        const { authInfo } = getState();
        dispatch(startLoader())
        return menuServices.getMenuData(authInfo.userData.id, authInfo.sessionData.id_token, authInfo.languageCode).then( data => {
           dispatch({type: constants.SAVE_MENU_DATA, data});
           dispatch(stopLoader());
       },
       error => {
           dispatch({type: constants.CLEAR_KPI_INFO});
           if (error && error.message === 'Failed to fetch') {
            dispatch({type: constants.CLEAR_AUTH_STORE});
            window.location.href = getLogoutUrl(authInfo.languageCode);
           }
           dispatch(showErrorPage(error));
           dispatch(stopLoader());
       })   
   }
}
