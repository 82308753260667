import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import PeopleOverView from "../../../../scorecardNewDesign/People/Screens/PeopleOverView";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const PeopleOverviewComponent = ({ people_overview, getPeopleOverviewData , layout}) => {
  useEffect(() => {
    getPeopleOverviewData(KPINames.PEOPLE_OVERVIEW);
  }, []);

  if (people_overview.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (people_overview.data) {
    return (
        <PeopleOverView weekNumber = {layout?.data?.weekNumber} renderItems = {people_overview.data} dataFor = {"Month"}/>
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
  } = state;
  const people_overview = scorecardImprovedInfo[KPINames.PEOPLE_OVERVIEW];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return {  people_overview, layout };
}

const mapDispatchToProps = {
  getPeopleOverviewData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PeopleOverviewComponent);
