/* eslint-disable react/prop-types */
import React, { useEffect, } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingOverlay from 'react-loading-overlay';
import Menu from "./Menu/Menu";
import PageHeader from "./page/PageHeader";
import PageHeaderNew from "./pagenew/PageHeader";
import PageFooter from "./page/PageFooter";
import Error from "./Error/Error";
import '../css/index.css';
import '../css/bp-icons.css';
import { isEmptyObj, getLoginUrl, getRedirectUrl, getLanguageCode } from "../helpers/commons";
import I18Component from "../i18N/I18Component";
import { compose } from "redux";
import { connect } from "react-redux";
import { authActions } from "../actions/authActions";
import { Mainsection,BlurredView } from "./App.styled";
import isMobile from "../helpers/isMobile";



function App(props) {
  useEffect(() => {
    if (window.location.search !== "") {
      const code = window.location.search.substring(6);
      const languageCode = getLanguageCode();
      const redirectUrl = getRedirectUrl(languageCode);
      window.history.pushState("", "", redirectUrl);
      async function getAuthenticate() {
        let authRes = await props.callAuth(code, languageCode);
        if (authRes && authRes.success) {
          let userRes = await props.getUserData(authRes.id_token);
          if (userRes && userRes.success) {
            if (userRes.language && userRes.language !== null) {
              sessionStorage.setItem("SaveLanguage", userRes.language);
              props.history.push(props.redirectUrl);
            }
          }
        }
      }
      getAuthenticate();
    } else if (isEmptyObj(props.userData) && !props.errorCode) {
      const langCode = getLanguageCode();
      props.savePath(window.location.pathname);
      window.location.href = getLoginUrl(langCode);
    }
  }, [props.errorCode]);
   return (
    <LoadingOverlay
      active={props.showLoader}
      className="loaderSpinner"
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
        },
      }}
    >
      {!isEmptyObj(props.userData) ? (
        <>
          <I18Component userData={props.userData}>
            <Mainsection>
              <Row>
               <Col xl={12}>
                  {props.location.pathname === "/admin/dashboard" ||
                  props.location.pathname === "/admin/previewchanges" ? (
                    <PageHeader userAdmin={true} />
                  ) : (
                   props.showErrorPage?  <BlurredView >
                     <PageHeader userAdmin={false} />
                     </BlurredView>: ((props.location.pathname == "/old_scorecard" ) || isMobile()?<PageHeader userAdmin={false} />  :<PageHeaderNew userAdmin={false} />)
                   
                  )}
                  <Error>{props.children}</Error>
                  {props.location.pathname !== "/reviews/dashboard" && (
                    <PageFooter />
                  )}
                </Col>
              </Row>
            </Mainsection>
          </I18Component>
         </>
      ) : props.showErrorPage ? (
        <>
          <I18Component userData={{}}>
            {props.location.pathname === "/admin/dashboard" ||
            props.location.pathname === "/admin/previewchanges" ? (
              <PageHeader userAdmin={true} />
            ) : (
              <PageHeader userAdmin={false} />
            )}
            <Error>{props.children}</Error>
            {props.location.pathname !== "/reviews/dashboard" && <PageFooter />}
          </I18Component>
        </>
      ) : null}
    </LoadingOverlay>
  );
}

function mapState(state) {
  const { authInfo, loader, errorInfo } = state;
  const { sessionData, redirectUrl, userData, languageCode } = authInfo;
  const { showLoader } = loader;
  const { showErrorPage, errorCode } = errorInfo;
  return { sessionData, redirectUrl, userData,  languageCode, showLoader, showErrorPage, errorCode };
}

const actionCreators = {
  callAuth: authActions.callAuth,
  savePath: authActions.savePath,
  getUserData: authActions.getUserData,
};

export default compose(withRouter, connect(mapState, actionCreators))(App);
