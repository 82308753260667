import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { Weekheadertext } from "../../../scorecardNewDesign/TradingProfitably/Styles/TradingUi.styled";
import { KPINames } from "../../ScorecardImprovedConstants";
import FuelVolume from "./KPI/FuelVolume";
import Services from "./KPI/Services";
import ShopAndSales from "./KPI/ShopAndSales";
import WBCSales from "./KPI/WBCSales";
import Loss from "./KPI/loss";
import Waste from "./KPI/waste";

function TradingProfitably({ layout}) {
  return (
  <>
      <Row>
        <Col xs={12} lg={6}>
          <ShopAndSales />
        </Col>
        <Col xs={12} lg={6}>
          <FuelVolume />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <Services/>
        </Col>
        <Col xs={12} lg={6}>
          <WBCSales />
        </Col>
      </Row>
      <Row>
        <Col>
          <Weekheadertext>{ layout?.data? ` Week ${layout?.data?.weekNumber - 1
            },${layout?.data?.previousWeekStartDate} - ${layout?.data?.previousWeekEndDate}`: ''}</Weekheadertext>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <Waste/>
        </Col>
        <Col xs={12} lg={6}>
          <Loss/>
        </Col>
      </Row>
      </>
  );
}

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
  } = state;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
 return { layout };
}

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TradingProfitably
);
