import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import Loyalty from "../../../../scorecardNewDesign/Customer/Screens/Loyalty";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";


export const LoyaltyRankingComponent = ({ loyalty_ranking, getLoyaltyRankingData , layout}) => {
    useEffect(() => {
        getLoyaltyRankingData(KPINames.LOYALTY_RANKING);
    }, []);
    
    if (loyalty_ranking.isLoading || layout?.data === null) {
        return <ScorecardPlaceholder />;
    }

    if (loyalty_ranking.data) {
        return (
            (<Loyalty data={loyalty_ranking.data} dataFor={"Month"} weekNumber={layout?.data?.weekNumber} />)
        );
    }
    return null;
};

function mapStateToProps(state) {
    const {
        scorecardImprovedInfo,
    } = state;
    const loyalty_ranking = scorecardImprovedInfo[KPINames.LOYALTY_RANKING];
    const layout = scorecardImprovedInfo[KPINames.LAYOUT];
    return { loyalty_ranking, layout };
}

const mapDispatchToProps = {
    getLoyaltyRankingData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LoyaltyRankingComponent);
