export const SCORECARDLIST = [
    {
        "category": "Trade",
        "kpiNames":[
            "Safety Conversatioins",
            "Precursor Report",
            "Near Misses",
            "Injury"
         ],
         "sites":[
            {
               "sapId":"0U342",
               "siteName":"Site Name 1",
               "metrics":[
                  1,
                  2,
                  3,
                  4
               ]
            },
            {
               "sapId":"0U352",
               "siteName":"Site Name 2",
               "metrics":[
                  12,
                  21,
                  33,
                  41
               ]
            },
            {
               "sapId":"0U212",
               "siteName":"Site Name 3",
               "metrics":[
                  78,
                  21,
                  30,
                  71
               ]
            }
         ],
        "kpis": [
            {
                "name": "Shop Sales vs Plan",
                "sites": [  
                    {
                        "siteName": "The Buck",
                        "vsLfl": -30.1
                    },
                    {
                        "siteName": "Great Torrington",
                        "vsLfl": -27.8
                    },
                    {
                        "siteName": "Petersfield",
                        "vsLfl": +12.2
                    },
                    {
                        "siteName": "Wadebridge",
                        "vsLfl": +0.0
                    },
                    {
                        "siteName": "Crondall",
                        "vsLfl": +11.0
                    }
                ],
                "status": "medium",
                "sales": [
                    {
                        "date": "11-07-2022",
                        "diffInPerc": -12.6,
                        "plannedSales": 14.2,
                        "salesActual": 12.4,
                        "salesUK": 13.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "12-07-2022",
                        "diffInPerc": -15.7,
                        "plannedSales": 13.4,
                        "salesActual": 11.3,
                        "salesUK": 15.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "13-07-2022",
                        "diffInPerc": -23.5,
                        "plannedSales": 15,
                        "salesActual": 11.4,
                        "salesUK": 16.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "14-07-2022",
                        "diffInPerc": -29.8,
                        "plannedSales": 15.4,
                        "salesActual": 10.8,
                        "salesUK": 13.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "15-07-2022",
                        "diffInPerc": -12.3,
                        "plannedSales": 16.3,
                        "salesActual": 14.3,
                        "salesUK": 9.4,
                        "sapId": "0U0PK"
                    }
                ]
            },
            {
                "name": "Fuel Sales vs Plan",
                "sites": [
                    {
                        "siteName": "The Buck",
                        "vsLfl": -30.1
                    },
                    {
                        "siteName": "Great Torrington",
                        "vsLfl": -27.8
                    },
                    {
                        "siteName": "Petersfield",
                        "vsLfl": +12.2
                    },
                    {
                        "siteName": "Wadebridge",
                        "vsLfl": +0.0
                    },
                    {
                        "siteName": "Crondall",
                        "vsLfl": +11.0
                    }
                ],
                "status": "bottom",
                "sales": [
                    {
                        "date": "11-07-2022",
                        "diffInPerc": -9.6,
                        "plannedSales": 6.2,
                        "salesActual": 5.4,
                        "salesUK": 9.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "12-07-2022",
                        "diffInPerc": -10.7,
                        "plannedSales": 7.4,
                        "salesActual": 8.3,
                        "salesUK": 8.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "13-07-2022",
                        "diffInPerc": -13.5,
                        "plannedSales": 4,
                        "salesActual": 6,
                        "salesUK": 7.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "14-07-2022",
                        "diffInPerc": -15.7,
                        "plannedSales": 6.8,
                        "salesActual": 8.2,
                        "salesUK": 4.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "15-07-2022",
                        "diffInPerc": -16.3,
                        "plannedSales": 9.3,
                        "salesActual": 5.3,
                        "salesUK": 11.4,
                        "sapId": "0U0PK"
                    }
                ]
            },
            {
                "name": "Waste",
                "sites": [
                    {
                        "siteName": "The Buck",
                        "vsLfl": -30.1
                    },
                    {
                        "siteName": "Great Torrington",
                        "vsLfl": -27.8
                    },
                    {
                        "siteName": "Petersfield",
                        "vsLfl": +12.2
                    },
                    {
                        "siteName": "Wadebridge",
                        "vsLfl": +0.0
                    },
                    {
                        "siteName": "Crondall",
                        "vsLfl": +11.0
                    }
                ],
                "status": "top",
                "sales": [
                    {
                        "date": "11-07-2022",
                        "diffInPerc": -12.6,
                        "plannedSales": 14.2,
                        "salesActual": 12.4,
                        "salesUK": 13.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "12-07-2022",
                        "diffInPerc": -15.7,
                        "plannedSales": 13.4,
                        "salesActual": 11.3,
                        "salesUK": 16.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "13-07-2022",
                        "diffInPerc": -23.5,
                        "plannedSales": 15,
                        "salesActual": 11.4,
                        "salesUK": 12.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "14-07-2022",
                        "diffInPerc": -29.8,
                        "plannedSales": 15.4,
                        "salesActual": 10.8,
                        "salesUK": 13.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "15-07-2022",
                        "diffInPerc": -12.3,
                        "plannedSales": 16.3,
                        "salesActual": 14.3,
                        "salesUK": 18.4,
                        "sapId": "0U0PK"
                    }
                ]
            },
            {
                "name": "Stockloss",
                "sites": [
                    {
                        "siteName": "The Buck",
                        "vsLfl": -30.1
                    },
                    {
                        "siteName": "Great Torrington",
                        "vsLfl": -27.8
                    },
                    {
                        "siteName": "Petersfield",
                        "vsLfl": +12.2
                    },
                    {
                        "siteName": "Wadebridge",
                        "vsLfl": +0.0
                    },
                    {
                        "siteName": "Crondall",
                        "vsLfl": +11.0
                    }
                ],
                "status": "bottom",
                "sales": [
                    {
                        "date": "11-07-2022",
                        "diffInPerc": -12.6,
                        "plannedSales": 14.2,
                        "salesActual": 12.4,
                        "salesUK": 16.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "12-07-2022",
                        "diffInPerc": -15.7,
                        "plannedSales": 13.4,
                        "salesActual": 11.3,
                        "salesUK": 15.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "13-07-2022",
                        "diffInPerc": -23.5,
                        "plannedSales": 15,
                        "salesActual": 11.4,
                        "salesUK": 16.4,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "14-07-2022",
                        "diffInPerc": -29.8,
                        "plannedSales": 15.4,
                        "salesActual": 10.8,
                        "salesUK": 11.5,
                        "sapId": "0U0PK"
                    },
                    {
                        "date": "15-07-2022",
                        "diffInPerc": -12.3,
                        "plannedSales": 16.3,
                        "salesActual": 14.3,
                        "salesUK": 17.2,
                        "sapId": "0U0PK"
                    }
                ]
            }
        ]
    },
    // {
    //     "category": "Safety first operations",
    //     "kpiNames":[
    //         "Safety Conversatioins",
    //         "Precursor Report",
    //         "Near Misses",
    //         "Injury"
    //      ],
    //      "sites":[
    //         {
    //            "sapId":"0U342",
    //            "siteName":"Site Name 1",
    //            "metrics":[
    //               1,
    //               2,
    //               3,
    //               4
    //            ]
    //         },
    //         {
    //            "sapId":"0U352",
    //            "siteName":"Site Name 2",
    //            "metrics":[
    //               12,
    //               21,
    //               33,
    //               41
    //            ]
    //         },
    //         {
    //            "sapId":"0U212",
    //            "siteName":"Site Name 3",
    //            "metrics":[
    //               78,
    //               21,
    //               30,
    //               71
    //            ]
    //         }
    //      ],
    //     "kpis": [
    //         {
    //             "name": "Safety Conversations",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "medium",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         },
    //         {
    //             "name": "Mandatory Task Completion",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "medium",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         },
    //         {
    //             "name": "Food Audit",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "top",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         },
    //         {
    //             "name": "HSSE Audit",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "top",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     "category": "People",
    //     "kpiNames":[
    //         "Safety Conversatioins",
    //         "Precursor Report",
    //         "Near Misses",
    //         "Injury"
    //      ],
    //      "sites":[
    //         {
    //            "sapId":"0U342",
    //            "siteName":"Site Name 1",
    //            "metrics":[
    //               1,
    //               2,
    //               3,
    //               4
    //            ]
    //         },
    //         {
    //            "sapId":"0U352",
    //            "siteName":"Site Name 2",
    //            "metrics":[
    //               12,
    //               21,
    //               33,
    //               41
    //            ]
    //         },
    //         {
    //            "sapId":"0U212",
    //            "siteName":"Site Name 3",
    //            "metrics":[
    //               78,
    //               21,
    //               30,
    //               71
    //            ]
    //         }
    //      ],
    //     "kpis": [
    //         {
    //             "name": "Sick Absence",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "bottom",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         },
    //         {
    //             "name": "Smile Engagement",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "bottom",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         },
    //         {
    //             "name": "Turnover",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "bottom",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         },
    //         {
    //             "name": "Training Percentage",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "bottom",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     "category": "Customer",
    //     "kpiNames":[
    //         "Safety Conversatioins",
    //         "Precursor Report",
    //         "Near Misses",
    //         "Injury"
    //      ],
    //      "sites":[
    //         {
    //            "sapId":"0U342",
    //            "siteName":"Site Name 1",
    //            "metrics":[
    //               1,
    //               2,
    //               3,
    //               4
    //            ]
    //         },
    //         {
    //            "sapId":"0U352",
    //            "siteName":"Site Name 2",
    //            "metrics":[
    //               12,
    //               21,
    //               33,
    //               41
    //            ]
    //         },
    //         {
    //            "sapId":"0U212",
    //            "siteName":"Site Name 3",
    //            "metrics":[
    //               78,
    //               21,
    //               30,
    //               71
    //            ]
    //         }
    //      ],
    //     "kpis": [
    //         {
    //             "name": "Voice of Customer",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "top",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         },
    //         {
    //             "name": "Google Reviews",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "bottom",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         },
    //         {
    //             "name": "Loyality Issuance",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "top",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         },
    //         {
    //             "name": "Schedule Effectiveness",
    //             "sites": [
    //                 {
    //                     "siteName": "The Buck",
    //                     "vsLfl": -30.1
    //                 },
    //                 {
    //                     "siteName": "Great Torrington",
    //                     "vsLfl": -27.8
    //                 },
    //                 {
    //                     "siteName": "Petersfield",
    //                     "vsLfl": +12.2
    //                 },
    //                 {
    //                     "siteName": "Wadebridge",
    //                     "vsLfl": +0.0
    //                 },
    //                 {
    //                     "siteName": "Crondall",
    //                     "vsLfl": +11.0
    //                 }
    //             ],
    //             "status": "medium",
    //             "sales": [
    //                 {
    //                     "date": "11-07-2022",
    //                     "diffInPerc": -12.6,
    //                     "plannedSales": 14.2,
    //                     "salesActual": 12.4,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "12-07-2022",
    //                     "diffInPerc": -15.7,
    //                     "plannedSales": 13.4,
    //                     "salesActual": 11.3,
    //                     "salesUK": 15.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "13-07-2022",
    //                     "diffInPerc": -23.5,
    //                     "plannedSales": 15,
    //                     "salesActual": 11.4,
    //                     "salesUK": 16.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "14-07-2022",
    //                     "diffInPerc": -29.8,
    //                     "plannedSales": 15.4,
    //                     "salesActual": 10.8,
    //                     "salesUK": 13.4,
    //                     "sapId": "0U0PK"
    //                 },
    //                 {
    //                     "date": "15-07-2022",
    //                     "diffInPerc": -12.3,
    //                     "plannedSales": 16.3,
    //                     "salesActual": 14.3,
    //                     "salesUK": 9.4,
    //                     "sapId": "0U0PK"
    //                 }
    //             ]
    //         }
    //     ]
    // }
];

export const SCORECARDUPDATED = [
  {
    sectionHeader: "Trading Profitably",
    weekNumber: "29",
    startDate: "18 July",
    endDate: "24 July",
    components: [
      {
        type: "tile",
        rank: "12",
        total: "32",
        title: "Shop Sales vs Plan",
        state: "OK",
        toolTipText: "Some random text here",
        displayGraph: true,
        graph: [
          {
            weekNumber: 11,
            avgCountry: 10,
            avgRegionalManager: -3.4,
          },
          {
            weekNumber: 12,
            avgCountry: 12,
            avgRegionalManager: -1.2,
          },
          {
            weekNumber: 13,
            avgCountry: 9,
            avgRegionalManager: 0,
          },
          {
            weekNumber: 14,
            avgCountry: 2,
            avgRegionalManager: 9.2,
          },
          {
            weekNumber: 15,
            avgCountry: -1,
            avgRegionalManager: 30,
          },
        ],
        lastDataUpdate: "08-07-2022",
        headers: ["Sites", "Total Sales", "Var to plan", "Var LFL"],
        data: [
          {
            sites: "Clacket Lane",
            totalSales: "58,505",
            varToPlan: "-28.9%",
            varLFL: "-28.9%",
          },
          {
            sites: "The Buck",
            totalSales: "90,391",
            varToPlan: "-27.1%",
            varLFL: "-27.1%",
          },
          {
            sites: "BlackBird Hill",
            totalSales: "60,283",
            varToPlan: "-26.5%",
            varLFL: "-26.5%",
          },
          {
            sites: "Jorvik",
            totalSales: "58,505",
            varToPlan: "-28.9%",
            varLFL: "-28.9%",
          },
          {
            sites: "Holsworthy",
            totalSales: "58,505",
            varToPlan: "-28.9%",
            varLFL: "-28.9%",
          },
        ],
      },
      {
        type: "tile",
        rank: "12",
        total: "32",
        title: "Fuel Volume vs Plan",
        state: "DANGER",
        toolTipText: "Some random text here",
        displayGraph: true,
        graph: [
          {
            weekNumber: 11,
            avgCountry: 12.1,
            avgRegionalManager: -4.5,
          },
          {
            weekNumber: 12,
            avgCountry: 13.2,
            avgRegionalManager: -1.2,
          },
          {
            weekNumber: 13,
            avgCountry: 15.6,
            avgRegionalManager: 0,
          },
          {
            weekNumber: 14,
            avgCountry: 18.9,
            avgRegionalManager: -15.3,
          },
          {
            weekNumber: 15,
            avgCountry: 22,
            avgRegionalManager: -21,
          },
        ],
        lastDataUpdate: "08-07-2022",
        headers: ["Sites", "Total Sales", "Var to plan", "Var LFL"],
        data: [
          {
            sites: "Clacket Lane",
            totalSales: "58505",
            varToPlan: "-28.9%",
            varLFL: "-28.9%",
          },
          {
            sites: "The Buck",
            totalSales: "90391",
            varToPlan: "-27.1%",
            varLFL: "-27.1%",
          },
          {
            sites: "BlackBird Hill",
            totalSales: "60283",
            varToPlan: "-26.5%",
            varLFL: "-26.5%",
          },
          {
            sites: "Jorvik",
            totalSales: "58505",
            varToPlan: "-28.9%",
            varLFL: "-28.9%",
          },
          {
            sites: "Holsworthy",
            totalSales: "58505",
            varToPlan: "-28.9%",
            varLFL: "-28.9%",
          },
        ],
      },
      {
         "type":"tile",
         "rank":"12",
         "total":"32",
         "title":"Services vs Plan",
         "state":"OK",
         "toolTipText":"Some random text here",
         "displayGraph":true,
         "graph":[
            {
               "weekNumber":11,
               "avgCountry":12.1,
               "avgRegionalManager":-4.5
            },
            {
               "weekNumber":12,
               "avgCountry":13.2,
               "avgRegionalManager":-1.2
            },
            {
               "weekNumber":13,
               "avgCountry":15.6,
               "avgRegionalManager":0
            },
            {
               "weekNumber":14,
               "avgCountry":18.9,
               "avgRegionalManager":-15.3
            },
            {
               "weekNumber":15,
               "avgCountry":22,
               "avgRegionalManager":-21
            }
         ],
         "lastDataUpdate":"08-07-2022",
         "headers":[
            "Sites",
            "Total Sales",
            "Var to plan",
            "Var LFL"
         ],
         "data":[
            {
               "sites":"Clacket Lane",
               "totalSales": "58505",
               "varToPlan": "-28.9%",
               "varLFL":"-28.9%"
            },
            {
               "sites":"The Buck",
               "totalSales": "90391",
               "varToPlan": "-27.1%",
               "varLFL":"-27.1%"
            },
            {
               "sites":"BlackBird Hill",
               "totalSales": "60283",
               "varToPlan": "-26.5%",
               "varLFL":"-26.5%"
            },
            {
               "sites":"Jorvik",
               "totalSales": "58505",
               "varToPlan": "-28.9%",
               "varLFL":"-28.9%"
            },
            {
               "sites":"Holsworthy",
               "totalSales": "58505",
               "varToPlan": "-28.9%",
               "varLFL":"-28.9%"
            },
         ]
      },
      {
         "type":"tile",
         "rank":"12",
         "total":"32",
         "title":"Waste %",
         "state":"DANGER",
         "toolTipText":"Some random text here",
         "displayGraph":true,
            "graph":[
               {
                  "weekNumber":11,
                  "avgCountry":12.1,
                  "avgRegionalManager":-4.5
               },
               {
                  "weekNumber":12,
                  "avgCountry":13.2,
                  "avgRegionalManager":-1.2
               },
               {
                  "weekNumber":13,
                  "avgCountry":15.6,
                  "avgRegionalManager":0
               },
               {
                  "weekNumber":14,
                  "avgCountry":18.9,
                  "avgRegionalManager":-15.3
               },
               {
                  "weekNumber":15,
                  "avgCountry":22,
                  "avgRegionalManager":-21
               }
         ],
         "lastDataUpdate":"08-07-2022",
         "headers":[
            "Sites",
            "Waste in £",
            "Var to plan",
            "Vs sales",
         ],
         "data":[
            {
               "sites":"Clacket Lane",
               "wasteIn": "54505",
               "varToPlan": "-28.9%",
               "vsSales":"9.1%"
            },
            {
               "sites":"The Buck",
               "wasteIn": "65701",
               "varToPlan": "-21.2%",
               "vsSales":"8.7%"
            },
            {
               "sites":"BlackBird Hill",
               "wasteIn": "51234",
               "varToPlan": "-22.2%",
               "vsSales":"5.6%"
            },
            {
               "sites":"Jorvik",
               "wasteIn": "45897",
               "varToPlan": "-23.5%",
               "vsSales":"10.5%"
            },
            {
               "sites":"Holsworthy",
               "wasteIn": "52453",
               "varToPlan": "-15.6%",
               "vsSales":"6.7%"
            }
         ]
      },
      {
         "type":"tile",
         "rank":"12",
         "total":"32",
         "title":"Losses",
         "state":"DANGER",
         "toolTipText":"Some random text here",
         "displayGraph":true,
            "graph":[
               {
                  "weekNumber":11,
                  "avgCountry":12.1,
                  "avgRegionalManager":-4.5
               },
               {
                  "weekNumber":12,
                  "avgCountry":13.2,
                  "avgRegionalManager":-1.2
               },
               {
                  "weekNumber":13,
                  "avgCountry":15.6,
                  "avgRegionalManager":0
               },
               {
                  "weekNumber":14,
                  "avgCountry":18.9,
                  "avgRegionalManager":-15.3
               },
               {
                  "weekNumber":15,
                  "avgCountry":22,
                  "avgRegionalManager":-21
               }
         ],
         "lastDataUpdate":"08-07-2022",
         "headers":[
            "Sites",
            "Value loss",
            "% to total"
         ],
         "data":[
            {
               "sites":"Clacket Lane",
               "valueLoss": "58503",
               "toTotal": "-28.9%"
            },
            {
               "sites":"The Buck",
               "valueLoss": "90391",
               "toTotal": "-27.1%"
            },
            {
               "sites":"BlackBird Hill",
               "valueLoss": "60283",
               "toTotal": "-26.5%"
            },
            {
               "sites":"Jorvik",
               "valueLoss": "58503",
               "toTotal": "-28.9%"
            },
            {
               "sites":"Holsworthy",
               "valueLoss": "58503",
               "toTotal": "-28.9%"
            }
         ]
      }
   ]
},
{
    "sectionHeader":"Safety First Operations",
    "weekNumber":"29",
    "startDate":"18 July",
    "endDate":"24 July",
    "components":[
       {
          "type":"tile",
          "rank":"12",
          "total":"32",
          "title":"Safety Conv.",
          "state":"danger",
          "toolTipText":"Some random text here",
          "displayGraph":false,
          "headers":[
             "Sites",
             "Difference"
          ],
          "data":[
             {
                "sites":"Clacket Lane",
                "difference":"-3"
             },
             {
                "sites":"Sandbach North",
                "difference":"-3"
             },
             {
                "sites":"Jorvik",
                "difference":"-2"
             },
             {
                "sites":"Holsworthy",
                "difference":"-3"
             },
             {
                "sites":"The Moss",
                "difference":"-2"
             },
             {
                "sites":"Clacket Lane",
                "difference":"-3"
             },
             {
                "sites":"Sandbach North",
                "difference":"-3"
             },
             {
                "sites":"Jorvik",
                "difference":"-2"
             },
             {
                "sites":"Holsworthy",
                "difference":"-3"
             },
             {
                "sites":"The Moss",
                "difference":"-2"
             }
          ]
       },
       {
          "type":"table",
          "title":"Safety Operations Overview () = last week count",
          "state":"neutral",
          "headers":[
             "Sites",
             "Safety Conv.",
             "Near Miss",
             "Inj. Count",
             "G+",
             "Security"
          ],
          "data":[
             {
                "sites":"Clacket Lane",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             },
             {
                "sites":"The Moss",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             },
             {
                "sites":"Blackbird Hill",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             },
             {
                "sites":"Holsworthy",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             },
             {
                "sites":"Jorwik",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             },
             {
                "sites":"Clacket Lane",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             },
             {
                "sites":"The Moss",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             },
             {
                "sites":"Blackbird Hill",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             },
             {
                "sites":"Holsworthy",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             },
             {
                "sites":"Jorwik",
                "safetyConv":"21(21)",
                "nearMiss":"1(21)",
                "injCount":"0(21)",
                "g":"0(21)",
                "security":"0(21)"
             }
          ]
       },
       {
          "type":"chart",
          "title":"Safety Operations Overview",
          "state":"neutral",
          "infoSection":{
             "header":"Last week",
             "dataPoints":[
                "Near Miss",
                "Injuries",
                "G+",
                "Security"
             ]
          },
          "data":[
             {
                "weekNumber": "W26",
                "nearMiss": 12,
                "injuries": 12,
                "g": 16,
                "security": 17
             },
             {
                "weekNumber": "W27",
                "nearMiss": 12,
                "injuries": 16,
                "g": 11,
                "security": 21
             },
             {
                "weekNumber": "W28",
                "nearMiss": 13,
                "injuries": 9,
                "g": 14,
                "security": 1
             },
             {
                "weekNumber": "W29",
                "nearMiss": 11,
                "injuries": 1,
                "g": 19,
                "security": 2
             }
          ]
       },
       {
          "type":"tile",
          "title":"Dark Sites",
          "rank":"12",
          "total":"32",
          "state":"danger",
          "toolTipText":"Some random text here",
          "displayGraph":false,
          "headers":[
             "Sites"
          ],
          "data":[
             {
                "sites":"Clacket Lane"
             },
             {
                "sites":"Sandbach North"
             },
             {
                "sites":"Jorvik"
             },
             {
                "sites":"Holsworthy"
             },
             {
                "sites":"The Moss"
             },
             {
                "sites":"Clacket Lane"
             },
             {
                "sites":"Sandbach North"
             },
             {
                "sites":"Jorvik"
             },
             {
                "sites":"Holsworthy"
             },
             {
                "sites":"The Moss"
             }
          ]
       },
       {
          "type":"tile",
          "title":"Mand.Tasks",
          "rank":"12",
          "total":"32",
          "state":"danger",
          "toolTipText":"Some random text here",
          "displayGraph":false,
          "headers":[
             "Sites",
             "Completed"
          ],
          "data":[
             {
                "sites":"Clacket Lane",
                "completed":"67%"
             },
             {
                "sites":"Sandbach North",
                "completed":"69%"
             },
             {
                "sites":"Jorvik",
                "completed":"77%"
             }
          ]
       }
    ]
 },
 {
   "sectionHeader":"People",
   "weekNumber":"29",
   "startDate":"18 July",
   "endDate":"24 July",
   "components":[
      {
         "type":"table",
         "title":"People Overview",
         "state":"NEUTRAL",
         "headers":[
            "Sites",
            "Labour vs demand",
            "Sick",
            "Absence",
			   "Holiday"
         ],
         "data":[
            {
               "sites":"Cutler",
               "labourVsDemand": +12,
               "sick": 3,
               "absence": 0,
			      "holiday": 17
            },
            {
               "sites":"The Buck",
               "labourVsDemand": +5,
               "sick": 0,
               "absence": 4,
			      "holiday": 10
            },
            {
               "sites":"BlackBird Hill",
               "labourVsDemand": -2,
               "sick": 0,
               "absence": 2,
			      "holiday": 2
            },
            {
               "sites":"Jorvik",
               "labourVsDemand": -10,
               "sick": 9,
               "absence": 10,
			      "holiday": 10
            },
            {
               "sites":"Holsworthy",
               "labourVsDemand": -23,
               "sick": 2,
               "absence": 0,
			      "holiday": 0
            },
         ]
      },
	  {
         "type":"tile",
         "title":"Clocking on time",
         "state":"DANGER",
		   "toolTipText":"Some random text here",
         "displayGraph":false,
         "headers":[
            "Sites",
            "Compliance"
         ],
         "data":[
            {
               "sites":"Cutler",
               "compliance": 42
            },
            {
               "sites":"The Buck",
               "compliance": 54
            },
            {
               "sites":"BlackBird Hill",
               "compliance": 58
            },
            {
               "sites":"Jorvik",
              "compliance": 86
            },
            {
               "sites":"Holsworthy",
              "compliance": 87
            },
         ]
      },
	  {
         "type":"tile",
         "title":"Unpublished schedules",
         "state":"DANGER",
		   "toolTipText":"Some random text here",
         "displayGraph":false,
         "headers":[
            "Sites",
            "W + 1",
			   "W + 2"
         ],
         "data":[
            {
               "sites":"Cutler",
               "w1": true,
			      "w2": true
            },
            {
               "sites":"The Buck",
               "w1": true,
			      "w2": true
            },
            {
               "sites":"BlackBird Hill",
               "w1": true,
			      "w2": false
            },
            {
               "sites":"Jorvik",
               "w1": false,
               "w2": true
            },
            {
               "sites":"Holsworthy",
               "w1": false,
			      "w2": true
            },
         ]
      }
	  ]
},
{
  sectionHeader: "Customer",
  weekNumber: "29",
  startDate: "18 July",
  endDate: "24 July",
  components: [
    { 
      type: "site complaints",
      title: "Compliments & Complaints () = last week count",
      toolTipText: "Some random text here",
      headers: ["Sites"],
      data: [
        {
          sites: "Clacket Lane",
          compliments: 10,
          compliants: 32,
          prev_compliments: 10,
          prev_complaints: 2,
        },
        {
          sites: "Sandbach North",
          compliments: 20,
          compliants: 28,
          prev_compliments: 10,
          prev_complaints: 2,
        },
        {
          sites: "Jorvik",
          compliments: 12,
          compliants: 15,
          prev_compliments: 10,
          prev_complaints: 2,
        },
        {
          sites: "Holsworthy",
          compliments: 12,
          compliants: 12,
          prev_compliments: 10,
          prev_complaints: 2,
        },
        {
          sites: "Clacket Lane",
          compliments: 10,
          compliants: 32,
          prev_compliments: 10,
          prev_complaints: 2,
        },
        {
          sites: "Sandbach North",
          compliments: 20,
          compliants: 28,
          prev_compliments: 10,
          prev_complaints: 2,
        },
        {
          sites: "Jorvik",
          compliments: 12,
          compliants: 15,
          prev_compliments: 10,
          prev_complaints: 2,
        },
        {
          sites: "Holsworthy",
          compliments: 12,
          compliants: 12,
          prev_compliments: 10,
          prev_complaints: 2,
        },
      ],
    },
    {
      type:"site comparison",
      title: "Loyalty Issuance () = last week count",
      toolTipText: "Some random text here",
      max_percent: 32,
      headers: ["Sites"],
      data: [
        {
          sites: "Clacket Lane",
          Target_percentage: 18,
          actual_percentage: 15,
          prev_percentage: 11,
        },
        {
          sites: "Jorvik",
          Target_percentage: 9,
          actual_percentage: 15,
          prev_percentage: 12,
        },
        {
          sites: "The Buck",
          Target_percentage: 12,
          actual_percentage: 8,
          prev_percentage: 9,
        },
        {
          sites: "Holsworthy",
          Target_percentage: 13,
          actual_percentage: 9,
          prev_percentage: 15,
        },
        {
          sites: "Clacket Lane",
          Target_percentage: 21,
          actual_percentage: 24,
          prev_percentage: 11,
        },
        {
          sites: "Jorvik",
          Target_percentage: 9,
          actual_percentage: 15,
          prev_percentage: 12,
        },
        {
          sites: "The Buck",
          Target_percentage: 12,
          actual_percentage: 8,
          prev_percentage: 9,
        },
        {
          sites: "Holsworthy",
          Target_percentage: 13,
          actual_percentage: 9,
          prev_percentage: 15,
        },
      ],
      Average:[
        {
          userName:"Aditya",
          target:12.8,
          Average:13.8
        }
      ],
      comparison_report: [
        {
          prev_position_data: {
            some_value: 7,
            name: "Robert J",
            position: "2nd",
          },
        my_place: {
            some_value: 7,
            name: "me",
            position: "3rd",
          },
          next_position_data: {
            some_value: 7,
            name: "Anna B",
            position: "4rth",
          },
        },
      ],
      Top_three_placement: [
        {
          second_place: {
            some_value: 7,
            name: "Anna B",
            position: "2nd",
          },
          fisrt_place: {
            some_value: 7,
            name: "Aditya Padmanabhan",
            position: "1st",
          },
          Thrid_place: {
            some_value: 7,
            name: "Robert J",
            position: "3rd",
          },
        },
      ],
    },
  ],
},

];

export const SAFETY_FIRST_LEGENDS = [
   { 
      name: 'Security',
      color: '#007f00'
   },
   { 
      name: 'G+',
      color: '#99cc00'
   },
   { 
      name: 'Injury',
      color: '#999999'        
   },
   { 
      name: 'Near miss',
      color: '#000099'
   }
];

export const SUBGROUPS = ['nearMiss', 'injuries', 'g', 'security'];

export const FIELD_WITH_PERCENT = ['completed', 'compliance', 'varToPlan', 'varLFL', 'varToTotal','vsSales','toSales'];
