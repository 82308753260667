
import styled from 'styled-components';

export const ErrorPageContainer = styled.div`
    color: #009b00;
    background-color: rgb(241, 248, 241);
    padding: 4% 2% 0 3%;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const ErrorPageHeading = styled.div`
    font-size: 40px;
    font-weight: bolder;
`;

export const ErrorPageMessage = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #52db52;
    padding-right: 30%;
`;

export const ErrorPageHomeButton = styled.button`
    border-radius: 35px !important;
    white-space: normal;
    height: 40px;
    text-align: center;
    width: 40%;
    border: 0px solid;
    font-size: 13px;
    background: #fff;
    color: #000;
    border: 1px solid #7f8285 !important;
    margin-top: 5%;
`;