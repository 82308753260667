/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DropDownContainer, SelectionContainer, SelectionText, CircleRadioButton, SelectedRadioButton,SelectionTextSubCat } from './button.Styled'

export function RadioButton(props) {
    const { t } = useTranslation();
    const { arrayList } = props
    useEffect(() => {

    }, []);
     return (
        <DropDownContainer data-test="radio-container" style={{ width: props.width }}>
          { props.usedFor !== 'subcategory' ? 
          arrayList.map((item, index) => {
                return (<SelectionContainer key={index} data-test="radio-cells" onClick={() => props.onClick(item, index)}>
                    <SelectionText data-test="radio-text">{(item.title || item)}</SelectionText>
                    <CircleRadioButton>
                        {props.selectedValue === item && <SelectedRadioButton></SelectedRadioButton>}
                    </CircleRadioButton>
                </SelectionContainer>)
            }
            ):arrayList.map((item, index) => {
                return (<SelectionContainer key={index} data-test="radio-cells" onClick={() => props.onClick(item, index)}>
                    <SelectionTextSubCat data-test="radio-text">{(item.title || item["subCategory"])}</SelectionTextSubCat>
                    
                    <div style={{flex:1}}><CircleRadioButton>
                        {props.selectedValue === item["subCategory"] && <SelectedRadioButton></SelectedRadioButton>}
                    </CircleRadioButton>
                    </div>
                </SelectionContainer>)
            }
            )}
        </DropDownContainer>
    )
}

export default RadioButton
