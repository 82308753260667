import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { scorecardActions } from '../../../../../actions/scorecardActions';
import MandatoryTask from '../../../../scorecardNewDesign/SafetyOpertaion/screens/MandatoryTask';
import { KPINames } from '../../../ScorecardImprovedConstants';
import ScorecardPlaceholder from '../../../ScorecardPlaceholder';



export const MandatoryTaskComponent = ({ mandatory_tasks, getMandatoryTaskData , layout}) => {

  useEffect(() => {
    getMandatoryTaskData(KPINames.MANDATORY_TASKS);
  }, []);


  if (mandatory_tasks.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (mandatory_tasks.data) {
    return (
      (<MandatoryTask data={mandatory_tasks.data} dataFor={"Month"} weekNumber={layout.data?.weekNumber} />)
    );
  }
  return null;

}

function mapStateToProps(state) {
  const { scorecardImprovedInfo } = state;
  const mandatory_tasks = scorecardImprovedInfo[KPINames.MANDATORY_TASKS];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return { layout, mandatory_tasks };
}

const mapDispatchToProps = {
  getMandatoryTaskData: (kpiUrl) =>
    scorecardActions.getScoreCardContent(kpiUrl),
};

export default connect(mapStateToProps, mapDispatchToProps)(MandatoryTaskComponent)