import styled from "styled-components";

export const ComponentStyle = styled.div`
    width: ${(props) => props.isCountryScore ? '220px' : '320px'};
    height: ${(props) => props.isCountryScore ? '256px' : '80px'};
    background: #FFF;
    border-radius: 20px;
    margin-top:8px;
    box-shadow: 1px solid black;
    font-family: 'Universe for BP Light';
`;

export const PlaceHolderText = styled.div`
  color: #aaa;
`;

export const ItemAlign = styled.div`
  display:flex;
  flex-direction:row;
  flex:1;
  justify-content:${(props) => !props.mobile ? "flex-start":"center"};
  flex-wrap:nowrap;
  @media only screen and (max-width: 1200px) {
      flex-wrap:wrap;
  }
  overflow-x: ${(props) => !props.mobile ? "scroll":"hidden"};
`;

export const ValueText = styled.div`
  color: #000;
`;

export const ComponentTitle = styled.div`
  font-size: 14px;
  margin: 6 2px 0 2px;
  text-align: start;
  font-weight: regular;
  height:40px;
  font-family:Roboto-Regular;
  color:#666666;
  margin-left:${(props) =>   props.width <5 ? "14px" : "9px"};
`;
export const MobileTitle = styled(ComponentTitle)`
  margin-left: 1.4em;
  padding-top: 0.8em;
`
export const OverAllRatingStyle = styled(PlaceHolderText)`
  font-size: 24px;
  line-height: normal;
  margin-top: 6px;
  text-align: center;
  margin-top: 6px;
  padding-left:0px;
  padding-right:5px; 
`;

export const RatingStyle = styled(ValueText)`
  font-size: 25px;
  line-height: 25px;
  margin-top: 5px;
  text-align: right;
`;

export const YearStyle = styled(PlaceHolderText)`
  font-size: 13px;
  text-align: ${(props) => (props.isCountryScore ? "center" : "right")};
  padding-left:5px;
  padding-right:5px;
`;

export const ArrowIcon = styled.div`
  display:flex;
  color: ${(props) => props.avarageScore === '0.0' || props.avarageScore === undefined ? "#09c" : props.avarageScore > 0 ? "#009b00" : "#ff0000"};
  font-size: 26px;
  margin-top:-10px;
  align-items:center;
  justify-content:center; 
`;

export const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -100vh;
  --top-spacing: 200px;
  scroll-padding-top: var(--top-spacing);
  margin-top: var(--top-spacing);
`;

export const ScoreContainer = styled.div`
  margin-top: 6.4rem;
  margin-bottom: 17px;
  border-radius: ${(props) => props.id ==='link'  ? '0.25rem' : '10px'};
  border: ${(props) => props.id ==='link'  ? '1px solid rgba(0,0,0,.125)' : 'solid 0.5px #ccc'};
  box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.12);
  padding: 14px 4px 19px 4px;
  background-color: #FFFFFF;
  height: 355px;
  @media only screen and (max-width: 1000px) {
      overflow-x: hidden;
  }
  @media only screen and (max-width: 1200px) {
    height: auto;
  }
`;

export const ItemView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0 5px 15px -2px rgba(46, 74, 79, 0.12);
  background-color: #fff;
  border-radius: 1px;
  border: solid 1px #ccc;
  width: inherit;
  
  flex-wrap: nowrap;
  margin-bottom: ${(props) => props.mobile ? '1em' : ''};
  margin-left: 1px;
  margin-right: 1px;
  
  
`;

export const YearGuide = styled.div` 
  display: flex;
  flex-direction: column;
  flex: 0.5;
  width: 100%;
  background-color: ${(props) => (props.id === 'month' ? "#f5f5f5" : "white")};
  padding-left: ${(props) =>   props.width <5 ? "10px" : "5px"};
  padding-right: ${(props) =>   props.width <5 ? "10px" : "5px"};
`

export const Mobileyearguide = styled(YearGuide)`
  padding-left: 1em;
  padding-right: 1em;
`
export const View = styled.div`
  padding:10px 2px 0 2px;
  font-size:12px;
  font-family:Roboto-Light;
  color:#666666;
`;

export const YearView = styled.div`
  display:flex;
  flex-direction: row;
  align-items:flex-start;
  justify-content:space-between;
`;
export const Mainview = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.mobile ? "290px" : "240px")};
  margin-bottom: 10px;
  margin-right: ${(props) =>  props.width < 4 ? "2em" : "0.5em"};
  margin-left: ${(props) =>  props.width < 4 ? "2em" : "0.5em"};
  width: ${(props) =>  props.width <5 ? "16rem" : "13.2rem"};
  border: 1px solid white;
`;

export const Mobileview = styled(Mainview)`
 margin-right: 1em;
 margin-left: 1em;
 width: 17em;
 height: 290px;

`

export const ReviewView = styled(YearView)`
  margin-top:${(props) => (props.id === 'year' ? "5px" : "2px")};
  height:5px;
  margin-bottom:5px;
  justify-content:space-between;
  flex:0.60;
`
export const IconView = styled(YearView)`
  height:5px;
  margin-bottom:5px;
`;

export const CurrentYear = styled.div`
  display:flex;
  flex-direction:column;
  flex:0.38;
  padding-right:0.5em;
  
`;

export const Topthreerow = styled.div`
  display:flex;
  flex-direction:column;
  flex:0.38;
  margin-top: ${(props)=>props.index!==1 ? "2.7em":"0em"};
  padding-right:0.5em;
`;

export const ReviewScore = styled.div`
  font-size:${(props) => (props.id === 'year' ? "11px" : "8px")};
  font-family:Roboto;
  color:#999;
  padding-bottom:10px;
  padding-top:${(props) => (props.id === 'year' ? "5px" : "3px")};
`;


export const Text1 = styled.div`
  font-family:Roboto-Regular;
  font-size:${(props) => (props.id === 'value' ? "24px" : props.id === 'reviews' ? "9px" : "12px")};
  color:#666666;
  padding-top:${(props) => (props.id === 'reviews' ? "0px" : props.id === 'year' ? "5px" : "0")};
  padding-left:${(props) => (props.id === 'reviews' ? "0px" : props.id === 'year' ? "10px" : "0")};
`;

export const Valuetexting = styled.span`
  font-family:${(props)=>props.id==='name'?'roboto-bold':'roboto-regular'};
  font-size:${(props)=>props.id==='name'?'9px':'10px'};
  color:#666666;
  width:100%;
`;

export const PositionDisplay = styled.span`
  font-family:roboto-regular;
  font-size:10px;
  color:${(props)=>props.index===1?"black":"white"};
  background-color: ${(props)=>props.index===1?" #FFE600":props.index===2?"#ea6f0d":"#999999"}
`;

export const PercentStyle = styled.div`
  display:flex;
  flex-direction:column;
  flex:0.28;
  justify-content:center;
  font-size:0px;
`;

export const PercentValue = styled.span`
  font-size:${(props) => (props.width <5 ? "31px" : "28px")};
  font-family:Roboto-Regular;
  text-align:right;
  color:${(props) => (props.avarageScore === '0.0' || props.avarageScore === undefined ? "#09c" : props.avarageScore > 0 ? "#009b00" : "#ff0000")};
`;

export const MonthView = styled(View)`
`;

export const MonthBox = styled.div`
 display:flex;
 flex-direction:row;
 background-color:green;
`