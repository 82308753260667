/* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 25/11/2021
* Author : Sunil Vora
*/

import React from 'react';
import PropTypes from 'prop-types';
// import { Icon } from 'elements';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
// import {  } from '@fortawesome/free-brands-svg-icons';
import { TableHead, HeadingCell, HeadingButton } from './Table.styled';
import { SORT, SELECTION } from './Table.constants';

function renderSortingIcon(heading, sortState) {
    const active = sortState.order === SORT.ASCENDING;
    return (
        <>
            {active ? <FontAwesomeIcon className='spaceGiver' icon={faAngleUp} /> : <FontAwesomeIcon className='spaceGiver' icon={faAngleDown} />}
        </>
    );
}

function renderHeadingContent(heading) {
    if (React.isValidElement(heading.component)) {
        return heading.component;
    }

    return heading.title;
}

function renderHeadingInner(heading, onSort, sortState, fetching, disableSort, disableTabIndex) {
    if (heading.sortable) {
        return (
            <HeadingButton
                disabled={fetching || disableSort}
                onClick={() => {
                    onSort(heading.key, sortState)
                }}
                tabIndex={disableTabIndex ? '-1' : '0'}
            >
                {renderHeadingContent(heading)}
                {renderSortingIcon(heading, sortState)}
            </HeadingButton>
        );
    }

    return renderHeadingContent(heading);
}

function renderHeading(heading) {
    if (React.isValidElement(heading)) {
        return heading;
    }
    const cellProps = {};
    if (heading.width) {
        cellProps.style = {
            width: heading.width
        };
    }
    if (heading.textAlign) {
        cellProps.style = {
            ...cellProps.style,
            textAlign: heading.textAlign
        }
    }
    return (
        <HeadingCell highLightColor={heading.highLightColor || false} fontLight={heading.isFontLight} key={heading.key} {...cellProps} customStyle={this.customStyle} index={heading.index}>
            {renderHeadingInner(
                heading,
                this.onSort,
                this.sortState,
                this.fetching,
                this.disableSort,
                this.disableTabIndex
            )}
        </HeadingCell>
    );
}

function renderHeadings(headings, onSort, sortState, fetching, disableSort, disableTabIndex, customStyle) {
    if (Array.isArray(headings)) {
        return headings.map(renderHeading, { onSort, sortState, fetching, disableSort, disableTabIndex, customStyle });
    }
    return headings;
}

const TableHeader = React.forwardRef(
    (
        {
            headings,
            sortState,
            onSort,
            selectionState,
            selectionOptions,
            fetching,
            isSelectAllVisible,
            disableSort,
            disableTabIndex,
            customStyle = false

        },
        ref
    ) => {
        if (!headings) return null;
        return (
            <TableHead ref={ref}>
                <tr>
                    {renderHeadings(headings, onSort, sortState, fetching, disableSort, disableTabIndex, customStyle)}
                </tr>
            </TableHead>
        );
    }
);

const headingObjectShape = PropTypes.shape({
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    sortable: PropTypes.bool.isRequired,
    isFontLight: PropTypes.bool,
    width: PropTypes.string,
    textAlign: PropTypes.string,
    component: PropTypes.element
});

TableHeader.propTypes = {
    customStyle: PropTypes.bool,
    disableSort: PropTypes.bool,
    disableTabIndex: PropTypes.bool,
    fetching: PropTypes.bool.isRequired,
    headings: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([headingObjectShape, PropTypes.element])),
        PropTypes.node
    ]),
    isSelectAllVisible: PropTypes.bool,
    onSort: PropTypes.func,
    selectionOptions: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            text: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
            action: PropTypes.func.isRequired
        })
    ),
    selectionState: PropTypes.oneOf([SELECTION.ALL, SELECTION.SOME, SELECTION.NONE]),
    sortState: PropTypes.shape({
        key: PropTypes.string,
        order: PropTypes.oneOf([SORT.ASCENDING, SORT.DESCENDING])
    })
};

export default TableHeader;
