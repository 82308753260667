
// user LOGIN events
export const EVENT_USER_LOGIN = "event_user_login"
export const EVENT_REGIONAL_MANAGER_SELECTION = "event_regional_manager_selection"
export const EVENT_SITE_FILTER_SELECTION = "event_site_filter_selection"
export const EVENT_DOWNLOAD_EXCEL = "event_download_excel"
export const EVENT_LINK_VISIT = "event_link_visit"


//keys for custom events
export const KEY_USER_ID = "key_user_id"
export const KEY_USER_TITLE = "key_user_title"
export const KEY_REGIONAL_MANAGER_NAME= "key_regional_manager_name"
export const KEY_REGIONAL_MANAGER_ID = "key_regional_manager_id"
export const KEY_CHANNEL_OF_TRADE = "key_channel_of_trade"
export const LIST_OF_FILTERED_SITES = "key_list_filter_sites"
export const DOWNLOAD_SECTION_NAME = "key_download_section_name"
export const DOWNLOAD_SECTION_TILE_NAME = "key_download_section_tile_name"
export const KEY_LINK_NAME= "link_name"
export const KEY_LINK_URL= "link_url"
export const KEY_LINK_HEADER= "link_header"
export const KEY_ARRAY_OF_SAP_ID= "array_sapId"
export const KEY_ARRAY_OF_SITES= "array_of_sites"
export const UNKNOWN_TITLE = "UNKNOWN"









