import { constants } from "../constants/reducerConstants";
import { showErrorPage, startLoader, stopLoader } from '../helpers/commons';
import { adminServices } from "../services/adminServices";

export const adminActions = {
  getAdminUserDetails,
  getAdminViewDetails,
  updateTable,
   updateView,
  sendDataToServer,
  createAdminUser,
  updateAdminUser,
  deleteAdminUser,
  changeEdit,
  setAllViews,
  addNewViewServer,
  deleteView,
  scoreCardCategoryFetch,
  setFileTable,
  uploadData,
  scoreCardlogFetch,
  siteMasterListFetch,
  siteDatalogFetch,
  setCategory,
  updateLog,
  updateExecutionId,
  siteUpdateExe
};

function getAdminUserDetails() {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    dispatch(startLoader());
    return adminServices
      .getAdminData(authInfo.sessionData.id_token, authInfo.languageCode)
      .then(
        (data) => {
          dispatch(stopLoader());
          dispatch({ type: constants.USER_ADMIN_SUCCESS, data });
        },
        (error) => {
          dispatch(stopLoader());
          dispatch({ type: constants.USER_ADMIN_FAILURE, error });
        }
      );
  };
}

function scoreCardCategoryFetch() {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    dispatch(startLoader());
    return adminServices
      .categoryScoreCardfetch(authInfo.sessionData.id_token, authInfo.languageCode)
      .then(
        (data) => {
          dispatch(stopLoader());
          dispatch({ type: constants.CATEGORY_FETCH_SUCCESS, data });
        },
        (error) => {
          dispatch(stopLoader());
          dispatch({ type: constants.CATEGORY_FETCH_FAILURE, error });
        }
      );
  };
}

function siteMasterListFetch() {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    dispatch(startLoader());
    return adminServices
      .siteMasterFetch(authInfo.sessionData.id_token, authInfo.languageCode)
      .then(
        (data) => {
          dispatch(stopLoader());
          dispatch({ type: constants.SITE_MASTER_LIST_FETCH_SUCCESS, data });
        },
        (error) => {
          dispatch(stopLoader());
          dispatch(showErrorPage(error));
        }
      );
  };
}

function setCategory(data){
 return (dispatch) => {
  dispatch({type: constants.SET_CATEGORY, data: data})
}
 }



function siteDatalogFetch(value) {
  return (dispatch, getState) => {
    const { authInfo, adminInfo } = getState();
    const {siteLogs} = adminInfo;
    return adminServices
      .siteDataLogfetch(authInfo.sessionData.id_token, authInfo.languageCode, value)
      .then(
        (data) => {
          if(data.logs !== undefined){
            let dataOfLogs = [...siteLogs, ...data.logs]
            dispatch({ type: constants.SITE_LOG_FETCH_SUCCESS, dataOfLogs});
          }
        },
        (error) => {
          // dispatch(stopLoader());
          // dispatch({ type: constants.CATEGORY_FETCH_FAILURE, error });
        }
      );
  };
}

function updateLog(data){
  return (dispatch) => {
    let dataOfLogs = [...data]
    dispatch({ type: constants.LOG_FETCH_SUCCESS, dataOfLogs});
  }
}

function scoreCardlogFetch(data) {
  return (dispatch, getState) => {
    const { authInfo, adminInfo } = getState();
    const {logs} = adminInfo;
    return adminServices
      .scoreCardLogfetch(authInfo.sessionData.id_token, authInfo.languageCode, data)
      .then(
        (data) => {
          if(data.logs !== undefined){
            let dataOfLogs = [...logs, ...data.logs]
            dispatch({ type: constants.LOG_FETCH_SUCCESS, dataOfLogs});
          }
        },
        (error) => {
          // dispatch(stopLoader());
          // dispatch({ type: constants.CATEGORY_FETCH_FAILURE, error });
        }
      );
  };
}

function sendDataToServer(data, view) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    return adminServices
      .sendToServer(
        authInfo.sessionData.id_token,
        data,
        view,
        authInfo.languageCode
      )
      .then(
        (data) => {
          dispatch({ type: constants.VIEW_MANAGER_SUCCESS, data });
          return data;
        },
        (error) => {
          
        }
      );
  };
}

function updateTable(data) {
  return (dispatch) => {
    dispatch({ type: constants.SAVE_UPDATED_TABLE, data });
  };
}

function changeEdit() {
  return (dispatch, getState) => {
    const { adminInfo } = getState();
    if (adminInfo.editLinkCheck) {
      dispatch({ type: constants.EDIT_LINK_FALSE });
    } else {
      dispatch({ type: constants.EDIT_LINK_TRUE });
    }
  };
}

function setAllViews(data) {
  return (dispatch) => {
    dispatch({ type: constants.SET_ALL_VIEWS, data });
  };
}

function addNewViewServer(data) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    return adminServices
      .sendToAddView(authInfo.sessionData.id_token, data, authInfo.languageCode)
      .then(
        (data) => {
          dispatch({ type: constants.NEW_ADDED_VIEW, data });
          return data;
        },
        (error) => {
     
        }
      );
  };
}

function uploadData(data, siteData) {
  if(!siteData){
    return (dispatch, getState) => {
      const { authInfo } = getState();
      dispatch({type: constants.DISABLE_SUBMIT})
      return adminServices
        .uploadToS3(authInfo.sessionData.id_token, authInfo.languageCode, data, true)
        .then(
          (data) => {
            // dispatch({ type: constants.NEW_ADDED_VIEW, data });
            dispatch({type: constants.ENABLE_SUBMIT})
            return data;
          },
          (error) => {
            dispatch({type: constants.ENABLE_SUBMIT})
          }
        );
    };
  }
  else{
    return (dispatch, getState) => {
      const { authInfo } = getState();
      // dispatch({type: constants.DISABLE_SUBMIT})
      return adminServices
        .uploadToS3(authInfo.sessionData.id_token, authInfo.languageCode, data, false)
        .then(
          (data) => {
            // dispatch({ type: constants.NEW_ADDED_VIEW, data });
            // dispatch({type: constants.ENABLE_SUBMIT})
            return data;
          },
          (error) => {
            // dispatch({type: constants.ENABLE_SUBMIT})
          }
        );
    };
  }
}

function updateExecutionId(data){
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_EXECUTION_ID, data });
  }
}

function siteUpdateExe(data){
  return (dispatch) => {
    dispatch({ type: constants.SITE_UPDATE_EXECUTION_ID, data });
  }
}

function setFileTable(data) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    dispatch(startLoader());
    return adminServices
      .fetchTable(authInfo.sessionData.id_token, authInfo.languageCode,data)
      .then(
        (data) => {
          dispatch({ type: constants.FILE_TABLE_UPDATE, data });
          // return data;
          dispatch(stopLoader());
        },
        (error) => {
          dispatch({ type: constants.FILE_TABLE_CLEAR});
          dispatch(stopLoader());
        }
      );
  };
}

function updateView(data) {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_VIEW, data });
  };
}

function getAdminViewDetails() {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    dispatch(startLoader());
    return adminServices
      .getViewData(authInfo.sessionData.id_token, authInfo.languageCode)
      .then(
        (data) => {
          dispatch(stopLoader());
          dispatch({ type: constants.VIEW_MANAGER_SUCCESS, data });
        },
        (error) => {
          dispatch(stopLoader());
          dispatch({ type: constants.VIEW_MANAGER_FAILURE, error });
        }
      );
  };
}

function deleteView(view) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    return adminServices
      .deleteView(authInfo.sessionData.id_token, view, authInfo.languageCode)
      .then(
        (data) => {
          dispatch({ type: constants.NEW_ADDED_VIEW, data });
        },
        (error) => {
          console.log(error);
        }
      );
  };
}

function createAdminUser(data) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    return adminServices
      .createAdminUser(
        authInfo.sessionData.id_token,
        data,
        authInfo.languageCode
      )
      .then(
        (data) => {
          dispatch({ type: constants.CREATE_USER_MANAGER_SUCCESS, data });
          return data;
        },
        (error) => {
          dispatch({ type: constants.CREATE_USER_MANAGER_FAILURE, error });
        }
      );
  };
}

function updateAdminUser(data, type) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    let request = {};
    switch (type) {
      case "viewFilter":
        request = {
          view: data.view,
          countryCode: data.countryCode,
          id: data.id
        };
        break;
      case "adminCheck":
        request = {
          admin: data.admin,
          countryCode: data.countryCode,
          id: data.id
        };
        break;
        case "countryCode":
          request = {
          countryCode: data.countryCode,
          id: data.id
          }
        break;
      default:
    }
    return adminServices
      .updateAdminUser(
        authInfo.sessionData.id_token,
        request,
        authInfo.languageCode,
        data.id
      )
      .then(
        (data) => {
          dispatch({ type: constants.UPDATE_USER_MANAGER_SUCCESS, data });
          return data;
        },
        (error) => {
          dispatch({ type: constants.UPDATE_USER_MANAGER_FAILURE, error });
        }
      );
  };
}

function deleteAdminUser(id) {
  return (dispatch, getState) => {
    const { authInfo } = getState();
    return adminServices
      .deleteAdminUser(authInfo.sessionData.id_token, id, authInfo.languageCode)
      .then(
        (data) => {
          dispatch({ type: constants.CREATE_USER_MANAGER_SUCCESS, data });
          return data;
        },
        (error) => {
          dispatch({ type: constants.CREATE_USER_MANAGER_FAILURE, error });
        }
      );
  };
}


