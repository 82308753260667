import { REVIEWS_DATA_FIELDS } from '../../constants/googlereviewsdata';
import { ResponseView ,FlexView, ContentShiftRight} from './SiteRankingRow.styled';

export function mapSiteRankingHeaderCellToValue(cells, heading, filterSelection, reviewkey, languageKey) {
    switch (heading) {
        case 'Site name':
            return <ContentShiftRight>{cells[REVIEWS_DATA_FIELDS.SITE]}</ContentShiftRight>
        case 'Avg star rating':
            let filterCheck = filterSelection === 'YEAR' ?
                REVIEWS_DATA_FIELDS.MONTH_AVERAGE : filterSelection === 'MONTH' ?
                    REVIEWS_DATA_FIELDS.WEEK_AVERAGE : REVIEWS_DATA_FIELDS.DAY_AVERAGE
            return cells[filterCheck];
        default:
            let filterCheck1 = filterSelection === 'YEAR' ?
                REVIEWS_DATA_FIELDS.MONTH_RATING : filterSelection === 'MONTH' ?
                    REVIEWS_DATA_FIELDS.WEEK_RATING : REVIEWS_DATA_FIELDS.DAY_RATING
            return cells[filterCheck1] ? cells[filterCheck1].find(value => value.columnHeader === heading)?.value : null;
    }
}

export function mapSiteReviewHeaderCellToValue(cells, heading, filterSelection, reviewkey, languageKey) {
    switch (heading) {
        case 'Site name':
            return (languageKey("Response") === reviewkey ? null : <ContentShiftRight>{cells.siteName}</ContentShiftRight>);
        case 'Date':
            return cells.dateCreated;
        case 'Rating':
            return (languageKey("Response") === reviewkey ? null : cells.rating);
        case 'Comments':
            {
                let textkey = languageKey("Response") === reviewkey ? cells.data : cells.review
                let titlekey = languageKey("Response") !== reviewkey ? <b>{reviewkey}</b> : reviewkey
                let styleKey = languageKey("Response") !== reviewkey ? 'reviewResponse' : 'reviewResponse'
                return <div className={styleKey}>
                    <ResponseView >{titlekey}</ResponseView><FlexView >{textkey}</FlexView>
                </div>

            }
        default:
            return ''
    }
}
