import styled from "styled-components";
import isMobile from "../../helpers/isMobile";

export const ScoreCardContainer = styled.div`
  margin-left: 0.2em;
  margin-right: 0.2em;
  margin-top: ${(props) => (props.displaydevice ? "8em" : "")};
  @media only screen and (min-width: 374px) and (max-width: 414px) {
    margin-top: 7em;
  }
`;

export const ScoreCardContainermobile = styled.div``;

export const KpiName = styled.div`
  font-family: ${(props) =>
    props.displaydevice === "mobile" ? "roboto-regular" : "roboto-bold"};
  font-size: ${(props) => (props.displaydevice === "mobile" ? "26px" : "18px")};
  color: "#666666";
  padding-left: ${(props) =>
    props.displaydevice === "mobile" ? "0.25em" : "0.65em"};
`;

export const KpiSection = styled.div``;

export const SitesContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 17px 12px 12px;
`;

export const Site = styled.div`
  font-family: Roboto-Regular;
  font-size: 14px;
  align-self: center;
  flex: 1;
`;

export const TileWrapper = styled.div`
  width: ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`;

export const Score = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  align-self: center;
`;

export const ScoreHeader = styled.div`
  ${(props) =>
    !props.isCoManager &&
    `
        flex: 1 1 auto;
    `}
  padding: ${(props) =>
    props.title === "Unpublished schedules" && props.isMobile
      ? "0 1px 0 6px"
      : "0 10px 0 6px"};
  background-color: ${(props) => props.color};
`;

export const CustomerSatisfactionMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1em;
`;

export const MobileCircleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,0.175);
  padding-bottom:7px;
`;

export const CircularArea = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: apace-around;
  align-items: center;
  margin-top: ${(props)=>props.marginTop+"px"}
`;

export const TextAreaCSAT = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const ScoreTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 0 !important;
  height: ${(props) => (props.value === "card" ? "35px" : "34px")};
  flex: 1;
`;

export const CustomerSatisfactionTable = styled.div`
  height: inherit;
  width: inherit;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
`;

export const ScoreKpiName = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  font-family: Roboto-Light;
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: normal;
  padding-top: 4px;
  color: ${(props) => props.textColor};
  @media only screen and (min-width: 300px) and (max-width: 500px) {
    font-size: 13px;
  }
`;

export const ScoreKpiNameNew = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  font-family: Roboto-Light;
  font-size: 14px;
  letter-spacing: normal;
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${(props) => props.textColor};
  @media only screen and (min-width: 300px) and (max-width: 500px) {
    font-size: 13px;
  }
`;
export const Percentage = styled.div`
  display: flex;
  color: ${(props) => props.textColor};
  padding-top: 1.2em;
  font-size: 10px;
`;

export const ScoreStatusContainer = styled.div`
  align-self: center;
  width: 2em;
  margin-left: 4px;
`;

export const ScoreStatus = styled.img`
  position: relative;
  height: 17px;
`;

export const ScoreInfo = styled.div`
  width: 20%;
  display: flex;
  justify-content: end;
  margin-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

export const SiteStatus = styled.img`
  position: relative;
  height: 17px;
  align-self: center;
  margin-right: 5px;
`;

export const ScoreGraphContainer = styled.div`
  height: 11em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top: ${(props) =>
    props.id === "CS" ? "1px solid rgba(0, 0, 0, 0.125)" : ""};
`;

export const VoCProgress = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  height: inherit;
  justify-content: center;
`;

export const CsatScore = styled.div`
  display: flex;
  flex: 0.2;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 0.2em;
  margin-top: -1.5em;
`;

export const Circularprogress = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: space-between;
  align-text: center;
  height: 100%;
`;

export const NocircularData = styled.div`
 text-align: center;
 width: 100%;
 height: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
`

export const Circlesubtext = styled.div`
  font-family: roboto-regular;
  color: #666666;
  font-size: 14px;
  padding-top: 0.5em;
`;

export const DescriptionProgress = styled.div`
  display: flex;
  flex: 0.3;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Perlinedetails = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  height: inherit;
  align-self: start;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const MobileLegendexplaination = styled.div`
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  width: 100%;
  justify-content: center;
  height: inherit;
  align-items: center;
  display: flex;
`;

export const Detailtext = styled.text`
  font-family: roboto-regular;
  font-size: 12px;
  color: #666666;
  width: ${(props) => props.usedFor === 'value' ? '65px':'75px'};
  padding-left: 3px;
`;
export const MobileCSText = styled.div`
  font-family: roboto-regular;
  font-size: 12px;
  color: #666666;
  width: i;
`;

export const Coloredcircle = styled.div`
  height: 13px;
  width: 13px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;

export const ProgressVoc = styled.div`
  display: grid;
  background-image: ${(props) => ` conic-gradient(
  ${props.color} ${props.percentage}%,
  #f3f3f3 ${props.percentage}%
)`};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-left: 10px;
  margin-right: 10px;
  place-items: center;
`;
export const C1 = styled.div`
  place-items: center;
  display: grid;
  position: relative;
  height: 88%;
  width: 88%;
  background-color: white;
  border-radius: 50%;
`;

export const CircleandDesc = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  align-items: center;
  justify-content: center;
`;

export const CustomerSatisfactionHeading = styled.div`
  font-family: ${(props) =>
    props.value === "heading" ? "roboto-regular" : "roboto-light"};
  font-size: ${(props) => (props.value === "heading" ? "14px" : "12px")};
  color: #666666;
  padding-left: 0.25em;
`;

export const ValueContainer = styled.div`
  position: relative;
  font-family: Roboto-Regular;
  font-size: 16px;
  padding-top: 1.5em;
`;

export const SomeValue = styled.span`
  position: relative;
  font-family: Roboto-Light;
  font-size: 12px;
  padding-bottom: 1.7em;
`;

export const ScoreLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 5px 0px 11px 16px;
`;

export const Planned = styled.div`
  height: 13px;
  width: 13px;
  background-color: #666666;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
`;

export const Actual = styled.div`
  height: 13px;
  width: 13px;
  background-color: ${(props) => (props.isActualMe ? "#666" : "#EDAC1A")};
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
`;

export const PlannedText = styled.span`
  font-family: Roboto-Light;
  font-size: 14px;
  margin-right: 1em;
`;

export const Mobilecategory = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: inherit;
  justify-content: center;
  padding-left: 0.45em;
`;

export const Errorbox = styled.div`
  display: flex;
  flex: 1;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
`;

export const ActualText = styled.span`
  font-family: Roboto-Light;
  font-size: 14px;
  margin-right: ${(props) => (props.isLosses ? "8px" : "13px")};
`;

export const StoreFilter = styled.div`
  width: 20em;
`;

export const ApplyText = styled.div`
  width: 100px;
  height: inherit;
  align-items: center;
  border: 0;
  color: ${(props) =>
    props.color === 0 || props.applyColor === false ? "silver" : "#007833"};
  font-family: roboto-regular;
  font-size: 16px;
  cursor: ${(props) =>
    props.color === 0 || props.applyColor === false
      ? "not-allowed"
      : "pointer"};
`;

export const ScorePopupContainer = styled.div`
  position: absolute;
  background-color: #353535;
  border-radius: 9px;
  top: ${(props) => `${props.top}px`};
  right: ${(props) => `${props.left}px`};
  width: ${(props) => `${props.width}em`};
  align-items: center;
  padding: 10px;
  justify-content: ${(props) => (props.isTileWider ? "center" : "right")};
  z-index: 3;
`;

export const MobilePopup = styled.div`
  position: absolute;
  background-color: #353535;
  border-radius: 9px;
  top: ${(props) => `${props.top - 32}px`};
  left: ${(props) => `${props.left - 218}px`};
  width: ${(props) => `${props.width}em`};
  align-items: center;
  padding: 8px;
  justify-content: center;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    top: ${(props) => `${props.arrowTop}%`};
    left: ${(props) => `${props.arrowLeft}%`};
    border-width: 13px;
    border-style: solid;
    border-color: transparent transparent #353535 transparent;
  }
`;

export const ScoreTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 29px;
`;

export const ScoreCardTitle = styled.div`
  font-family: Roboto-Light;
  font-size: 24px;
  letter-spacing: normal;
  color: #29a21f;
  margin-right: 2em;
  margin-left: 0.5em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
`;

export const ScoreDropdown = styled.div`
  button {
    background-color: white;
    border-color: white;
    color: #666666;
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px auto 24px;
`;
export const CategoryContainerTradingProfTopRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px auto 12px;
`;
export const CategoryContainerNew = styled.div`
  display: flex;
  flex-direction: row;
  margin: 21px auto 12px;
`;

export const KpiWeek = styled.div`
  font-family: Roboto-Regular;
  font-size: ${(props) => (props.displaydevice === "mobile" ? "16px" : "18px")};
  color: "#666666";
  padding-left: ${(props) => (props.displaydevice === "mobile" ? "0.6em" : "")};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  border-bottom: ${(props) =>
    props.id === "customer" ? "none" : "1px solid #cccccc"};
  background-color: ${(props) =>
    props.id === "customer" ? "#ffffff" : "#fafafa"};
  height: ${(props) => (props.id === "customer" ? "40px" : "35px")};
  align-items: center;
  ${(props) =>
    props.isTileWider &&
    `
        padding-left: 25px;
        justify-content: space-between;
    `}
`;
export const HeaderContainerNew = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  border-bottom: ${(props) =>
    props.id === "customer" ? "none" : "1px solid #cccccc"};
  background-color: ${(props) =>
    props.id === "customer" ? "#ffffff" : "#fafafa"};
  height: ${(props) => (props.id === "customer" ? "40px" : "35px")};
  align-items: center;
  ${(props) =>
    props.isTileWider &&
    `
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;
    `}
`;
export const HeaderContainerMandTask = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  border-bottom: ${(props) =>
    props.id === "customer" ? "none" : "1px solid #cccccc"};
  background-color: ${(props) =>
    props.id === "customer" ? "#ffffff" : "#fafafa"};
  height: ${(props) => (props.id === "customer" ? "40px" : "35px")};
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: ${(props)=> isMobile()?15: props.paddingRight+"px"};
  ${(props) =>
    props.isTileWider &&
    `
        padding-left: 0px;
        padding-right: 0px;
    `}
`;
export const ColName = styled.div`
  font-family: Roboto-Light;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}` : "12px")};
  margin-right: ${(props) => `${props.marginRight}px`};
  margin-left: ${(props) => `${props.marginLeft}em`};
  color: #666666;
  padding-left: 0em;
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.isTileWider &&
    `
        margin-right: ${props.colName === "Sites" ? "0" : "0"};
        align-items:center;
        `}
`;

export const Name = styled.div``;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 9px 12px 9px 5px;
  &:nth-child(2n) {
    background-color: #fafafa;
  }
`;

export const DataContainerMandTask = styled.div`
  display: flex;
  flex-direction: row;
  padding: 9px 9px 9px 9px;
  &:nth-child(2n) {
    background-color: #fafafa;
  }
`;
export const DataContainerUnPub = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
   padding-left: 15px;
    padding-right: ${isMobile()?"0px":"35px"};
    padding-top: 10px;
  &:nth-child(2n) {
    background-color: #fafafa;
  }
`;

export const SiteSelected = styled.span`
  align-self: center;
  width: ${(props) => (props.usedFor === "crosscontainer" ? "100%" : "65%")};
  height: inherit;
  justify-content: flex-end;
  display: flex;
  padding-right: ${(props) =>
    props.usedFor === "crosscontainer" ? "0.5em" : ""};
`;

export const GreenValue = styled.text`
  color: green;
  fonst-family: roboto-bold;
  align-self: center;
  font-size: 14px;
  font-weight: 800;
`;

export const MainContainer = styled.div`
  height: ${(props) => `${props.height}px`};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: ${(props) => (props.isTileWide && isMobile() ? "10px" : "8px")};
  }
`;

export const OuterView = styled.div`
  background-color: red;
  overflow-y: scroll;
  margin-bottom: -100px;
  height: 100%;
`;

export const Averageontainer = styled.div`
   height: 207px;
   width:inherit;
   display:flex;
   justify-content:flex-end;
   padding-top: 4em;
}
`;

export const Barcontainer = styled.div`
   height: inherit;
   width: 250px;
   display:flex;
   justify-content:center;
   align-items: center;
}
`;

export const Targetwritten = styled.div`
   height: ${(props) => props.height}em;
   width:10%;
   display:flex;
   justify-content:center;
   margin-bottom:${(props) => props.sidepercent};
}
`;

export const DataValue = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666666;
  flex: 0.4;
  text-align: ${(props) => (props.alignment === 0 ? "left" : "center")};
  padding-left: ${(props)=> (props.paddingLeft?props.paddingLeft+"px":"0.3em") };
  &:first-child {
    flex: ${(props) => (props.widthKey === "Dark Sites" ? "1" : "0.6")};
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: ${(props) =>
      props.widthKey === "Dark Sites" ? "" : "1"};
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  ${(props) =>
    props.isTileWider &&
    `text-overflow: ellipsis;
        overflow:hidden;
        -webkit-line-clamp: ${props.widthKey === "Sites" ? "" : "1"};
        display: -webkit-box;   
        -webkit-box-orient: vertical;
        text-align:${props.align ? props.align : "center"};
        padding-right: ${props.paddingRight ? `${props.paddingRight}px` : ""};
        flex: ${props.flex};
        &:nth-child(n + 3) {
            flex: ${props.headerKey === "% to total" ? "0.65" : props.flex};
        }
        padding-left: ${props.paddingLeft ? `${props.paddingLeft}px` : "0.3em"};
        
        &:first-child {
          flex: ${props.flex};
        }
        `}
`;

export const DataValueNew = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666666;
  flex: 0.5;
  text-align:${(props)=> props.itemAlign?props.itemAlign: "left"} ;
  padding-left: 5px;
`;
export const WbcValue = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666666;
  flex: ${(props)=> props.flex};
  padding-left: ${(props)=> props.paddingLeft};
  text-align:${(props)=> props.itemAlign?props.itemAlign: "left"} ;
 // padding-left: 5px;
`;
export const DataValueUnPub = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666666;
  padding-left: ${(props)=>props.paddingLeft};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 52px;
  white-space: nowrap;
}
  
`;
export const DataValueTotalShopSales = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666666;
  flex: ${(props) => props.flex};
  text-align: center;
  padding-left: ${(props) => props.paddingLeft+"px"}; 
  padding-right:${(props) => props.paddingRight+"px"}     
`;
export const VoCValue = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666666;
  flex: 0.4;
  text-align: ${(props) => (props.alignment === 0 ? "left" : "center")};
  padding-left: 0.3em;
  &:first-child {
    flex: ${(props) => (props.widthKey === "Dark Sites" ? "1" : "0.3")};
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: ${(props) =>
      props.widthKey === "Dark Sites" ? "" : "1"};
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  &:nth-child(2) {
    text-align: center;
  }
  &:nth-child(3) {
    text-align: center;
  }
  &:nth-child(4) {
    text-align: center;
  }
`;

export const VocTableHeader = styled.div`
  font-family: Roboto-Light;
  font-size: 12px;
  color: #666666;
  flex: 0.4;
  text-align: ${(props) => (props.alignment === 0 ? "left" : "center")};
  padding-left: 0.3em;
  &:first-child {
    flex: ${(props) => (props.widthKey === "Dark Sites" ? "1" : "0.3")};
  }
  &:nth-child(2) {
    text-align: center;
    flex: 0.4;
  }
  &:nth-child(3) {
    text-align: center;
    flex: 0.4;
  }
  &:nth-child(4) {
    text-align: center;
    flex: 0.4;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding-top: 4px;
`;

export const TotalContainerWbc = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding-top: 4px;
  justify-content:space-between
`;

export const TableHeader = styled.div`
  flex: 1 1 auto;
  padding: 0 10px 0 6px;
  border-bottom: 1px solid #ebebeb;
`;

export const Mobiletableheader = styled.div`
  display: flex;
  padding: 0 10px 0 6px;
  border-bottom: 1px solid #ebebeb;
  width: inherit;
`;

export const TableColumn = styled.div`
  text-align: ${(props) => (props.alignIndex === 0 ? "left" : "center")};
  font-family: Roboto-Light;
  font-size: 12px;
  color: #666666;
  flex: ${(props) => (props.isWidth ? "auto" : "0.5")};
  &:first-child {
    flex: 0.6;
  }
  align-items: center;
  padding-left: ${(props) => (props.alignIndex === 0 ? "7px" : "")};
  ${(props) =>
    props.title === "Safety Operations Overview () = last week count" &&
    `
      &:nth-child(2) {
        flex: 0.6;
        text-align: right;
        
      }
      &:nth-child(3) {
        flex: 0.7;
        text-align: right;
      }
      &:nth-child(4) {
        flex: 0.6;
        text-align: right;
      }
      &:nth-child(5) {
        flex: 0.5;
        text-align: right;
      }
      &:nth-child(6) {
        flex: 0.4;
        text-align: center;
      }
      &:nth-child(7) {
        flex: 0.6;
        text-align: center;
      }
    `}
  ${(props) =>
    props.title === "People Overview" &&
    `
          &:first-child {
            flex: 4.6;
          }
          &:nth-child(2) {
              text-align: left;
          }
          &:nth-child(3) {
              text-align: center;
          }
          &:nth-child(4) {
              text-align: right;
              padding-right: 18px;
          }
          &:nth-child(5) {
              text-align: right;
              padding-right: 10px;
          }
          `}
`;
export const SiteHeading = styled.div`
  display: flex;
  width: 30%;
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666666;
  justify-content: flex-end;
  height: inherit;
  align-items: center;
  border-bottom: 1px solid #cccccc;
`;

export const RangeHeading = styled.div`
  display: flex;
  width: 49%;
  font-family: Roboto-Light;
  font-size: 12px;
  color: #666666;
  height: inherit;
  align-items: center;
  margin-left: 1.5em;
  border-bottom: solid 1px #979797;
  justify-content: space-between;
`;

export const Limitsetter = styled.div`
  display: flex;
  height: 50%;
  font-family: Roboto-Light;
  font-size: 12px;
  color: #666666;

  align-items: baseline;
  border-left: solid 1px #979797;
`;

export const ArrowIcon = styled.img`
  height: 13px;
  width: 13px;
  margin-top: ${(props) => (props.isTile ? "2px" : "1px")};
`;

export const AlertIcon = styled.img`
  height: 17px;
  width: 16px;
  margin-top: -2px;
`;

export const TableValue = styled.div`
  font-family: ${(props) => (props.isAlert ? "Roboto-Bold" : "Roboto-Regular")};
  font-size: 12px;
  color: ${(props) => (props.isAlert ? "#bb453a" : "#666666")};
  flex: 0.5;
  &:first-child {
    flex: 0.6;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  text-align: ${(props) => (props.alignText === 0 ? "left" : "center")};
  padding-left: ${(props) => (props.alignText === 0 ? "7px" : "")};
  ${(props) =>
    props.title === "People Overview" &&
    `
        &:nth-child(2) {
            text-align: right;
            margin-left: 15px;
        }
        &:nth-child(3) {
            padding-left: 19px;
            text-align: right;
        }
        &:nth-child(4) {
            text-align: right;
            padding-right: 18px;
        }
        &:nth-child(5) {
            text-align: right;
            padding-right: 15px;
        }
        `}
  ${(props) =>
    props.title === "Safety Operations Overview () = last week count" &&
    !props.isTotal &&
    props.alignText !== 0 &&
    props.alignText < 6 &&
    `
        &:nth-child(n + 2) {
          text-align: right;
          flex: 0.6;
        }
        &:nth-child(5) {
          text-align: right;
         
        }
        &:nth-child(2) {
          text-align: center;
          flex: 0.6
        }
        &:nth-child(3) {
          text-align: right;
          
        }
        &:nth-child(4) {
          text-align: end;
        }
        &:nth-child(6) {
          text-align: center;
        }
        `}
        ${(props) =>
    props.title === "Safety Operations Overview () = last week count" &&
    props.isTotal &&
    `
          &:first-child{
            padding-left: 10px;
          }
          &:nth-child(n + 2) {
            text-align: right;
            flex: 0.6;
          }
          &:nth-child(5) {
            text-align: right;
           
          }
          &:nth-child(2) {
            text-align: center;
            flex: 0.6
          }
          &:nth-child(3) {
            text-align: right;
            
          }
          &:nth-child(4) {
            text-align: end;
          }
          &:nth-child(6) {
            text-align: center;
          }
          &:nth-child(7) {
            text-align: center;
            padding-right:5px;
          }
            `}
          ${(props) =>
    props.title === "People Overview" &&
    props.isTotal &&
    `
              &:first-child {
                padding-left: 11px;
                flex: 1;
              }
              &:nth-child(n + 2) {
                text-align: right;
                
              }
              &:nth-child(5) {
                flex: 0.7;
                text-align: center;
                padding-left: 46px;
              }
              &:nth-child(2) {
                text-align: end;
                padding-left: 80px;
              }
              &:nth-child(3) {
                text-align: end;
                padding-left: 70px;
              }
              &:nth-child(4) {
                text-align: center;
                padding-left: 65px;
              }
            `}
`;
export const TableValueTotal = styled.div`
  font-family: ${(props) => (props.isAlert ? "Roboto-Bold" : "Roboto-Regular")};
  font-size: 12px;
  color: ${(props) => (props.isAlert ? "#bb453a" : "#666666")};
  flex: 0.5;
  &:first-child {
    flex: 0.6;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  text-align: ${(props) => (props.alignText === 0 ? "left" : "center")};
  padding-left: ${(props) => (props.alignText === 0 ? "7px" : "")};
  ${(props) =>
    props.title === "People Overview" &&
    `
        &:nth-child(2) {
            text-align: right;
            margin-left: 15px;
        }
        &:nth-child(3) {
            padding-left: 19px;
            text-align: right;
        }
        &:nth-child(4) {
            text-align: right;
            padding-right: 18px;
        }
        &:nth-child(5) {
            text-align: right;
            padding-right: 23px;
        }
        `}
  ${(props) =>
    props.title === "Safety Operations Overview () = last week count" &&
    !props.isTotal &&
    props.alignText !== 0 &&
    props.alignText < 6 &&
    `
        &:nth-child(n + 2) {
          text-align: right;
          flex: 0.6;
        }
        &:nth-child(5) {
          text-align: right;
         
        }
        &:nth-child(2) {
          text-align: center;
          flex: 0.6
        }
        &:nth-child(3) {
          text-align: right;
          
        }
        &:nth-child(4) {
          text-align: end;
        }
        &:nth-child(6) {
          text-align: center;
        }
        `}
        ${(props) =>
    props.title === "Safety Operations Overview () = last week count" &&
    props.isTotal &&
    `
          &:first-child{
            padding-left: 10px;
          }
          &:nth-child(n + 2) {
            text-align: right;
            flex: 0.6;
          }
          &:nth-child(5) {
            text-align: right;
           
          }
          &:nth-child(2) {
            text-align: center;
            flex: 0.6
          }
          &:nth-child(3) {
            text-align: right;
            
          }
          &:nth-child(4) {
            text-align: end;
          }
          &:nth-child(6) {
            text-align: center;
          }
          &:nth-child(7) {
            text-align: center;
            padding-right:13px;
          }
            `}
          ${(props) =>
    props.title === "People Overview" &&
    props.isTotal &&
    `
              &:first-child {
                padding-left: 11px;
                flex: 1;
              }
              &:nth-child(n + 2) {
                text-align: right;
                
              }
              &:nth-child(5) {
                flex: 0.7;
                text-align: center;
                padding-left: 46px;
              }
              &:nth-child(2) {
                text-align: end;
                padding-left: 80px;
              }
              &:nth-child(3) {
                text-align: end;
                padding-left: 70px;
              }
              &:nth-child(4) {
                text-align: center;
                padding-left: 65px;
              }
            `}
`;
export const SiteRatings = styled.div`
  display: flex;
  flex: 1;
  height: 25px;
  text-align: ${(props) => (props.alignText === 0 ? "left" : "center")};
  padding-left: ${(props) => (props.alignText === 0 ? "7px" : "")};
`;
export const SiteNames = styled.div`
  display: flex;
  width: 32%;
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666666;
  justify-content: flex-end;
  margin-top: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
export const PreviousNumber = styled.div`
  display: flex;
  justify-content: center;
  width: 12%;
  font-size: 12px;
  color: #666666;
  height: inherit;
`;

export const ColorCode = styled.div`
  display: flex;
  margin-left: 10px;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: ${(props) => (props.id === "range" ? "1em" : "")};
`;

export const Personalranking = styled.div`
 flex:0.5
 width:inherit;
`;

export const Loyaltycomponents = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Sectiondivider = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666666;
  flex: 0.6;
  &:first-child {
    flex: ${(props) => (props.id === "average" ? "0.4" : "")};
  }
  text-align: ${(props) => (props.alignment === 0 ? "left" : "center")};
  margin-left: ${(props) => (props.id !== "average" ? "1em" : "0.5em")};
`;

export const LoyaltyContainer = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "50%")};
  display: flex;
`;
export const OrangeCirlce = styled.div`
  height: 11px;
  width: 11px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-top: 2px;
`;

export const ComplimentCode = styled.div`
  display: flex;
  width: 80px;
  margin-left: 15px;
  justify-content: space-between;
`;

export const Description = styled.div`
  font-family: Roboto-regular;
  font-size: 11px;
  color: #666666;
  margin-right: ${(props) => (props.id === "complaints" ? "9px" : "")};
`;

export const Scoredivider = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Rowdisplay = styled.div`
  display: flex;
  width:100%
  justify-content: space-between;
  margin-top: 0.5em;
  padding-top: ${(props) => (props.id === "top_three" ? "2em" : "")};



`;

export const Sectioncontainer = styled.div`
  width: 100%;
  display: flex;
  height: inherit;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 90%;
  font-family: roboto-light;
  font-size: 12px;
  border-bottom: ${(props) =>
    props.id === "top_three" ? "" : "1px solid #cccccc"};
  background-color: #ffffff;
  padding-top: ${(props) => (props.id === "top_three" ? "4em" : "")};
`;
export const Innerheading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: 50%;
  font-family: roboto-light;
  font-size: 12px;
  border-bottom: 1px solid #cccccc;
`;
export const Valuetext = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  margin-left: 10px;
  color: "#000000";
  width: 50px;
`;

export const BarRating = styled.div`
  height: inherit;
  width: 55%;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
`;

export const TargetValue = styled.div`
  width: 65px;
  font-size: 9px;
  font-family: Roboto-Regular;
  color: #666666;
  margin-left: ${(props) =>
    props.id === "range" ? props.sidepercent - 25 : ""}%;
  margin-top: ${(props) => (props.id === "loyalty" ? props.sidepercent : "0")}%;
`;
export const AverageValue = styled.div`
  font-size: 9px;
  font-family: Roboto-Regular;
  color: #000000;
  padding-left: 5px;
  margin-top: ${(props) =>
    props.id === "loyalty" ? props.sidepercent - 4 : "-4"}%;
`;

export const TableContainer = styled.div`
  width: ${(props) => (props.width ? `${props.width}em` : "35%")};
  padding-top: ${(props) => (props.displaydevice ? "1em" : "")};
  overflow-x: clip;
`;

export const BarContainer = styled.div`
  width: ${isMobile() ? "100%" : "35.2em"};
  margin-left: ${isMobile() ? null : "0px"};
`;

export const LastWeekDetails = styled.div`
  padding-left: 13px;
  border-bottom: 1px solid #cccccc;
  height: 71px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LastWeekTitle = styled.div`
  text-align: center;
  font-family: Roboto-Light;
  font-size: 12px;
  height: 15px;
`;

export const LastWeekData = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  justify-content:space-around;
  padding-left: 30px;
  padding-right: 30px
`;

export const PointsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 46px;
`;

export const PointName = styled.div`
  font-family: Roboto-Regular;
  font-size: 10px;
  text-align: center;
`;

export const PointValue = styled.div`
  font-family: Roboto-Bold;
  font-size: 14px;
  text-align: center;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: ${(props) => isMobile() ? "5px 10px 8px 20px" : "5px 10px 8px 30px"};
  @media only screen and (min-width:350px) and (max-width:375px) {
    padding: "5px 0px 8px 0px"
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: row;
  width: 75px;
`;

export const LegendColor = styled.span`
  height: 13px;
  width: 13px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;
`;

export const LegendName = styled.div`
  font-family: Roboto-Light;
  font-size: 11px;
`;

export const LastDateUpdate = styled.div`
  font-family: Roboto-Light;
  font-size: 9px;
  color: #666666;
  text-align: end;
  padding-right: 12px;
  margin-top: -16em;
`;

export const TooltipValue = styled.div`
  position: fixed;
  background-color: #353535;
  border-radius: 9px;
  max-height: 20em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  justify-content: center;
  z-index: 1;
  top: ${(props) => `${props.top - 41}px`};
  left: ${(props) => `${props.left}px`};
  color: white;
`;

export const CustomerContainer = styled.div`
  width: ${(props) => (props.id === "loyalty" ? "43.5em" : "40%")};
  margin-left: ${(props) => (props.id === "loyalty" ? "-0.1em" : "")};
`;

export const CustomerMobile = styled.div`
  width: 100%;
  padding-top: 2px;
`;

export const ScoreExportContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  cursor: pointer;
  opacity: initial;
  padding-top: 7px;
`;

export const ScoreExcelLabel = styled.span`
  font-family: "Roboto-Light";
  font-size: 12px;
  padding-top: 5px;
  margin-right: 1px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.isNeutral ? "#007833" : "#ffffff")};
`;

export const ScoreDownloadIcon = styled.div`
  text-align: end;
  margin-right: ${(props) => `${props.marginRight}px`};
`;

export const OldDataBlur = styled.div`
  ${(props) =>
    props.boolTrue
      ? ` 
 opacity: 0.15;
 backdrop-filter: blur(10px);
 -webkit-filter: blur(10px);
 pointer-events: none;
 `
      : ``}
`;

export const FilterBlur = styled.div`
  opacity: 0;
  backdrop-filter: blur(3px);
  -webkit-filter: blur(3px);
  pointer-events: none;
  display: flex;
  flex: 0.5;
`;

export const VoiceDataBlur = styled.div`
  ${(props) =>
    props.boolTrue
      ? ` 
 opacity: 0.15;
 backdrop-filter: blur(10px);
 -webkit-filter: blur(10px);
 pointer-events: none;
 height:inherit;
 width: inherit;
 overflow: hidden;
 `
      : ``}
`;
export const OldDataFullview = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
  width: inherit;
  position: absolute;
`;

export const VoCDataFullview = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
  width: inherit;
  position: absolute;
  margin-top: 7em;
`;

export const Exclaimation = styled.span`
  position: absolute;
  margin-top: -12px;
  width: 28px;
  height: 28px;
  z-index: 0;
  padding: 1px 5px 4px 12px;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
`;

export const ExclaimationText = styled.text`
  color: white;
  font-size: 14px;
  z-index: 3;
  text-align: center;
  font-family: roboto-rold;
`;

export const OldDataContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10em;
  height: 250px;
  position: absolute;
  top: 10%;
`;

export const Oldtext = styled.div`
  display: flex;
  text-align: center;
  font-family: ${(props) =>
    props.id === "old" ? "roboto-bold" : "roboto-regular"};
  font-size: ${(props) => (props.id === "old" ? "16px" : "14px")};
  font-color: black;
  justify-content: center;
  margin-top: 1em;
`;

export const OldDataApprove = styled.button`
  width: 145px;
  height: 29px;
  margin: 20px 3px 31px 3px;
  color: white;
  font-size: 12px;
  border-radius: 128px;
  border: 0;
  background-color: #060;
`;

export const Tiledisplaymobile = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Tablemobilecontainer = styled.div`
  height: 240px;
  width: 367px;
  overflow-y: hidden;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 3px;
  }
`;
export const TablemobilecontainerNew = styled.div`
  height: 240px;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
`;

export const Persitedetails = styled.div`
  height: 172px;
  display: flex;
  flex-direction: column;
  &:nth-child(2n) {
    background-color: #f7f7f7;
  }
`;

export const Mobilesiteheading = styled.div`
  width: 100%;
  height: 10%;
  font-family: roboto-bold;
  font-size: 12px;
  padding-top: 0.5em;
  padding-left: 0.5em;
`;

export const Mobilesitedetails = styled.div`
  width: 100%;
  height: 90%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
`;

export const Persitefield = styled.div`
  width: inherit;
  height: 27px;
  display: flex;
  justify-content: center;
`;

export const Sitetext = styled.span`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #111111;
  width: 99px;
  align-items: center;
  margin-left: 3em;
  display: flex;
  justify-content: ${(props) => (props.value ? "center" : "right")};
`;

export const SafetyContentMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: space-between;
`;

export const PeopleMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: space-between;
`;

export const CustomerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

export const RankingContainer = styled.div`
  margin-top: 30px;
`;
