import { constants } from "../constants/reducerConstants";

const siteGraphRatings = {
    sitesData : [],
    graphLoader: false,
    error: ''
}

export function graphInfo(state = siteGraphRatings , action) {
    switch(action.type){
        case constants.GOOGLE_LINE_GRAPH_LOADER:
            return {
                ...state,
                graphLoader: true
            }
        case constants.SAVE_SITE_LINE_DATA:
          return{
              ...state,
              sitesData: action.data,
              graphLoader: false          
          } 
        case constants.CLEAR_SITE_LINE_DATA:
            return{
                ...state,
                error: action.data?.message||'',
                graphLoader: false
            }
        default: return state
    }
}