import styled from "styled-components";

export const LessLinksGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 60px;
  padding: 0.2px;
  @media only screen and (max-width: 760px) {
    grid-template-columns: 100%;
    height: ${(props) => props.linkNumber*50}px;
  }
`;

export const MoreLinksGrid = styled(LessLinksGrid)`
  height:170px;
  @media only screen and (max-width: 760px) {
    height: ${(props) => props.linkNumber*50}px;
  }
  
`
;

export const GridItem = styled.div`
  padding: 2px;
  text-align: left;
  &:hover{
    #color {
      color: #007f00;
    }
    #next {
      color: #383736;
      right: 15px;
      transition: 0.2s ease-in;
    }
    #next div.arrow {
      display: inline-block;
      transition: 0.3s ease-in;
      color: #007f00;
    }
    #next:hover div.arrow {
      transform: translateX(30%);     
    }
  }
  height:40px;
`;

export const Gi = styled.div`
  padding: 2px;
  text-align: center;
`;

export const Space = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
`;

export const Container = styled.div`
  display:flex;
  flex-direction:row;
  flex:1;
  align-items:center;
  flex-wrap:nowrap;
  justify-content: space-between;
  width:  ${(props) => props.mobile?"60%":"100%"};
  @media only screen and (max-width: 1000px) {
    flex-wrap:wrap;
  }
`;

export const LinkText = styled.div`
  display:flex;
  flex:1;
  font-family:Roboto-Light;
  font-size:16px;
  align-self:flex-start;
  padding-right:1px;
  padding-left:1px;
  height:100%;
  flex-direction: column;
`;

export const Arrow = styled.div`
  display:flex;
  align-self:flex-start;
  flex:0.25;
`;

export const Maintext = styled.div`
  font-size: 16px;
`;

export const AdditionalText = styled.div`
  font-size: 12px;
  width: 9em;
`;

export const CardPopup = styled.div`
  visibility: ${(props) => props.showPopup ? 'visible' : 'hidden'};
  font-size: 14px;
  width: 150px;
  background-color: #353535;
  text-align: center;
  border-radius: 9px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 13.3em;
  left: 14%;
  margin-left: -60px;
  color: white;
  margin-bottom:30px;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #353535 transparent transparent transparent;
    }
`;

export const HeaderContainer = styled.div`
display: flex;
flex:1;
align-items:center;
justify-content:space-between;
padding: 5px 15px 8px 15px;
margin:5px 0px;
border-bottom: ${(props) => props.removeBorder ? 'solid 0px #ebebeb' : 'solid 1px #ebebeb'};
`
export const HeaderText = styled.span`
font-family:Roboto-Light;
font-size: 14px;
font-weight: 300;
color: #000;
`
export const HorizontalList = styled.div`
display: flex;
overflow: auto;
white-space: nowrap;
`
export const ListView = styled.div`
padding: 9px;
  border-radius: 27px;
  height:24px;
  display:flex;
  align-items:center;
  background-color:${(props) => !props.selectedItem ? '#f2f2f2': 'white'};
  box-shadow: ${(props) => props.selectedItem ? '0 10px 10px 0 rgba(35, 35, 35, 0.2)' :'0'};
  margin: 11px 0px 27px 19px;
`
export const ListName = styled.div`
font-family: Roboto-Regular;
  font-size: 14px;
  color: rgba(17, 17, 17, 0.64);
`
export const AddNewView = styled.div`
display: flex;
align-items:center;
justify-content:center;
height:24px;
padding: 9px;
 margin: 11px 0px 27px 0px;
 z-index:0;
 margin-right:1em;
`
export const AddNewText = styled.div`
font-family: Roboto-Regular;
font-size: 14px;
line-height: 2.5;
text-decoration: underline;
color: ${(props) => !props.disableEditView ? 'green': '#666666'};
cursor: pointer;
`
export const FilterText = styled.div`
font-family: Roboto-Light;
font-size: 14px;
font-weight: 300;
color: #000;
margin-left:1em;
`

export const PopupContainer = styled.div`
  width: 145px;
  background-color: #353535;
  margin-top:125px;
  text-align: center;
  border-radius: 9px;
  padding: 5px;
  position: absolute;
  z-index: 1;
    &::after {
      content: "";
      position: absolute;
      top: -20%;
      left: 48%;
      border-width: 10px;
      border-style: solid;
      border-color: transparent  transparent #353535 transparent  ;
    }
`;
