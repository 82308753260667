/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 22/03/2021
* Author : Arun Rapolu
*/

import { handleResponse, getRedirectUrl } from '../helpers/commons';
import { LANGUAGE_URLS } from '../constants/environmentConstants';

export const authServices = {
    callAuth,
    getUserData
}

function callAuth(code, languageCode) {
    let urlencoded = new URLSearchParams();
    const redirectUri = getRedirectUrl(languageCode);
    urlencoded.append("grant_type", "authorization_code");
    urlencoded.append("code", code);
    urlencoded.append("client_id", process.env.REACT_APP_CLIENTID);
    urlencoded.append("redirect_uri", redirectUri);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        body: urlencoded
    }
    return fetch(`${process.env.REACT_APP_AUTHURL}`, requestOptions)
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch(error => {
        return Promise.reject(error);
    });;
}

function getUserData(data, languageCode) {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization':data
        } 
    }
    const url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    return fetch(`${url}/authorization`, requestOptions)
    .then(handleResponse)
    .then(data => {
        return data;     
    })
    .catch(error => {
        return Promise.reject(error);
    });
}