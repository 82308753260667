/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 25/11/2021
* Author : Sunil Vora
*/
import { constants } from '../constants/reducerConstants';

const initialState = {
    kpiData: {},
    additionalLinks:[],
    additionalToolLinks:[],
    max:0
}

export function kpiInfo(state = initialState, action) {
    switch (action.type) {
        case constants.SAVE_KPI_INFO:
            return {
                ...state,
                kpiData: action.data.body.kpiList,
                additionalLinks:action.data.body.additionalLinks,
                additionalToolLinks:action.data.body.additionalToolsLinks,
                max:action.data.body.max
            };
        case constants.CLEAR_KPI_INFO:
            return {
                ...state,
                kpiData: [],
                additionalLinks:[],
                additionalToolLinks:[]
            };
        default:
            return state
    }
}
