import { useEffect, useState } from 'react';
import isMobile from '../../../../helpers/isMobile';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

export function Monthgiver(value, allData) {
    return allData[value - 1]?.weekNumber
}

export const responsiveGraphWidth = (width, type) => { 
 
  if (isMobile()){
    return width/1.4;
  }
  let result
  if(type === 'People'){

    if(width <= 990){
      result = (width/1.2)
    }
    else if(width>=990 && width<1320){
      result = (width/2.6)
    }
    else if(width >1300){
      result = (width/1.8)
    }
  }
  else if(type === 'Customer'){
    if(width>1200){
      result = (width/2.4)
    }
    else if(width >=750){
      result = (width/2.7)
    }
    else if(width >380){
      result =(width/1.4)
    }
    else{
      result = (width/1.6)
    }
   }
   else if(type === 'regional'){
    if(width <= 990){
      result = (width/1.2)
    }
    else{
      result = (width/1.8)
    }
  }
  else{
    if(width <= 990){
      result = (width/1.2)
    }
    else if(width>=990 && width<1320){
      result = (width/2.6)
    }
    else{
      result = (width/2.4)
    }
  }
  return result
}
export const responsiveGraphWidthBar = (width)=> (width<=1150? (width/1.2) : (width/1.85));

export const RmFilterWidth = (width) => width <1200? 150:245
export const financialLineColor = (key, isBottom, isScoreGraph, isDanger) => {
  switch (key) {
      case 'ActualSales':
          return isBottom ? '#edac1a' : isDanger ? '#ffc000' : '#007f00';
      case 'PlannedSales':
          return '#666666';
      case 'ActualSalesUK':
          return isScoreGraph ? 'rgba(118, 118, 118, 1)' : '#edac1a'
      default:
          break;
  }
};
