import React from 'react';
import isMobile from '../../helpers/isMobile';
import { AnnoucmentMessageDiv, AnnoucmentMessageImprovedMobileDiv } from '../scorecardNewDesign/Announcement/message.styled';

function Annoucement({ announcement }) {

    if (!announcement) {
        return null;
    }

    return (announcement?.message && (isMobile() ? <AnnoucmentMessageImprovedMobileDiv> {'Announcement - '}
        {announcement.message}
        <br></br>
        {`Updated on - ${announcement.date}`} </AnnoucmentMessageImprovedMobileDiv> : <AnnoucmentMessageDiv>
        {'Announcement - '}
        {announcement.message}
        <br></br>
        {`Updated on - ${announcement.date}`}
    </AnnoucmentMessageDiv>))
}

export default Annoucement