/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 23/11/2021
* Author : Arun Rapolu
*/
import { constants } from '../constants/reducerConstants';

const initialState = {
    showLoader: false
}

export function loader(state = initialState, action) {
    switch (action.type) {
        case constants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            };
        case constants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            };
        default:
            return state
    }
}