import styled from 'styled-components';

export const Cell = styled.td`
    font-family: ${(props) => props.fontLight ? 'Roboto-Regular' : 'Roboto-Regular'};
    height: 2.5rem;
    background-clip: padding-box;
    color: #666666;
    text-align: ${(value) => value.value === 'Site name' || value.value === 'Comments' ? 'left' : 'center'};
   
`;

export const DetailsRowStyled = styled.tr`
    display: table;
    table-layout: fixed;
    width: 100%;
    &:nth-child(2n + 1) ${Cell} {
        background-color: #EBEBEB;
    };
`;

export const ContentShiftRight = styled.div`
    text-align: left;
    padding-left:5px;
`

export const ResponseView = styled.div`
    width: 105px;
    text-align:right;
    padding-right:9px `

export const FlexView = styled.div`
    display:flex;
    flex: 1
`
