/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/5/2023
 */

import { isEmpty } from "lodash";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../actions/scorecardActions";
import i_icon_alert from "../../../../images/icons/i_icon_alert.svg";
import { AlertIcon } from "../../../Scorecard/ScoreCard.styled";
import { KPINames } from "../../../ScorecardImproved/ScorecardImprovedConstants";
import { TileHeader } from "../../Customer/Screens/Components/TileHeader";
import {
  Exclaimation,
  ExclaimationText,
  Loyaltyheader,
  Loyaltyscore,
  OldDataBlur,
  Oldtext,
  Targetscore,
  TooltipValue,
  UnpubIcon
} from "../../Customer/style/Customer.styled";
import { OldUnpubdata, OlddataUnpubcontent } from "../../SafetyOpertaion/styles/SafetyUi.styled";
import { returnRequiredRegionalHeader } from "../../SafetyOpertaion/utils/SafetyUtils";
import { Olddatatradingapprovee } from "../../TradingProfitably/Styles/TradingUi.styled";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import { titleGenerator } from "../../scorecardConstants";
import i_summary from "../Assest/i_summary.svg";
import { Clockingheadercell, Clockingspace, UnPublishedSchedulesData, UnPublishedSchedulesTable, UnpublishedContainer } from "../Style/PeopleUi.styled";


function Unpublished(props) {
  const { data } = props;
  const { toolTipText, title, headers, lastWeekUpdated, expectedRefreshTimestamp} = data;
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [blur, setBlur] = useState(props.weekNumber !== lastWeekUpdated);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);

  const handleHover = (event, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  function loadExcel() {
    props.exportToExcel(titleGenerator[title], headers, data.data);
  }

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
     } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };
   
  return (
    <UnpublishedContainer>
      <OldDataBlur boolTrue={blur} id="OldDataBlur">
      {!blur && props.weekNumber !== lastWeekUpdated && (
              <Exclaimation
                onMouseEnter={(e) => blurHover(e, "enter")}
                onMouseLeave={(e) => blurHover(e, "leave")}
                id="exclamationContainer"
              >
                <ExclaimationText id="ExclaimationText">!</ExclaimationText>
              </Exclaimation>
            )}
            {oldPopUpVisible && (
            <TooltipValue
              id="tooltipvalue"
              top={blurRect.top + 70}
              left={blurRect.left + 15}
            >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
          )}
      <TileHeader
       exportToExcel = {() => loadExcel()}
       excelLoader={props.excelLoader}
       kpiName = {props.kpiName}
        showPopup={showPopup}
        handleHover={(e, value) => handleHover(e, value)}
        title= {title}
        state={data.state}
      />
      {showPopup && (
        <ScorePopupContainerWeb
        top={top}
        left={left-20}
        width = {18}
         id='popUpDesktop'
         textDescription = {toolTipText}
      />
      )}
     { isEmpty(data.data) ?<Loyaltyscore component="people">
        <UnpubIcon src={i_summary} alt="summary" />
        <Targetscore component="unpub">
          No unpublished schedules within your site
        </Targetscore>
      </Loyaltyscore>:<>
      <UnPublishedSchedulesTable>
       <Loyaltyheader usedIn="header">
       {headers?.map((item, index) => {
         return (
           <Clockingheadercell 
            usedIn="header" index={index}>
             {item} 
           </Clockingheadercell>

         );
       })}
     </Loyaltyheader>
      <UnPublishedSchedulesData height={65} usedin="clocking">
      { data?.data.length ?
      data.data.map((itemData, dataindex) => {
       return (
            <>
              <Loyaltyheader>
                 { data.headers.map((headerItem, headerIndex) => {
                  let key = returnRequiredRegionalHeader(headerItem);
                  return headerIndex === 0 ? (
                    <Clockingheadercell usedIn="table" index={headerIndex}>
                    <Clockingspace>{itemData[key]}</Clockingspace>
                    </Clockingheadercell>
                  ) : headerIndex === 1 ? (
                    <Clockingheadercell usedIn="table" index={headerIndex}>
                      {itemData[key] &&  <div><AlertIcon src={i_icon_alert} alt="alert" /></div>}
                    </Clockingheadercell>
                  ) : (
                    <>
                      <Clockingheadercell index={headerIndex}>
                      {itemData[key] &&  <div><AlertIcon src={i_icon_alert} alt="alert" /></div>}
                      </Clockingheadercell>
                    </>
                  );
                })}
              </Loyaltyheader>
           
            </>
          );
        })
      : <></>}
      </UnPublishedSchedulesData></UnPublishedSchedulesTable></>
     }</OldDataBlur>
      {blur && (
          <>
          <OldUnpubdata>
            <OlddataUnpubcontent>
            <Oldtext id="old">You're viewing old data</Oldtext>
              <Oldtext id="data_not_updated_text">This data has not been updated yet.</Oldtext>
               <Oldtext id="oldtext">
                 Scheduled updates occurs on&nbsp;{props.layout?.data?.schedules?.unpublishedData}
              </Oldtext>
               <Olddatatradingapprovee id="olddataapprove" onClick={() => setBlur(!blur)}>
                 continue with old data
               </Olddatatradingapprovee>
            </OlddataUnpubcontent>
          </OldUnpubdata>
          </>
        )}
    </UnpublishedContainer>
  );
}


const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

const mapStateToProps = (state) => {
  const { scorecardImprovedInfo } = state;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
return {
  layout
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(Unpublished)
);
