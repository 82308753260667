/* Copyright (C) BP -
 * All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 24/11/2021
 * Author : Sunil Vora
 */

import { initializeApp } from "firebase/app";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import Previewchanges from "./components/AdminDashboard/Previewchanges";
import App from "./components/App";
import ReviewsDashboard from "./components/GoogleReviewDashboard/ReviewsDashboard";
import PageBody from "./components/page/PageBody";
import firebaseConfig from "./constants/firebaseConfig";
import { getLanguageCode } from "./helpers/commons";
import { history } from "./helpers/history";
import { persistor, store } from "./helpers/store";

const languageCode = getLanguageCode();
const app = initializeApp(firebaseConfig(languageCode) || {});

export class EOSApp extends React.Component {
  render() {
    return (
      <BrowserRouter history={history}>
        <App>
          <Switch>
            <Route exact path="/" component={PageBody} />
            <Route exact path="/old_scorecard" component={PageBody} />
            <Route exact path="/improved_scorecard" component={PageBody} />
            <Route
              exact
              path="/reviews/dashboard"
              component={ReviewsDashboard}
            />
            <Route exact path="/admin/dashboard" component={AdminDashboard} />
            <Route
              exact
              path="/admin/previewchanges"
              component={Previewchanges}
            />
          </Switch>
        </App>
        {/* <SplashScreen/> */}
      </BrowserRouter>
    );
  }
}

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <Provider store={store}>
      <React.StrictMode>
        <PersistGate loading={null} persistor={persistor}>
          <EOSApp />
        </PersistGate>
      </React.StrictMode>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
