/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/10/2022
 * Author : Sunil Vora
 */

import React, { useState } from "react";
import { Card, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from 'redux';
import { withTranslation, useTranslation } from "react-i18next";
import { ScoreHeader, ScoreTitle, ScoreKpiName, ScorePopupContainer, MobilePopup } from "./ScoreCard.styled";
import Emaintanence from "../E-Maintenance/Emaintanence";
import { createMarkup } from "./utils";
import i_icon from "../../images/icons/i_icon.svg";
import isMobile from "../../helpers/isMobile";
import { isTablet } from "../../helpers/isTablet";
import { isLargeDeskTop } from "../../helpers/isLargeDeskTop";
import cross_icon from "../../images/icons/cross_icon.svg";

function EmaintenanceWrapper(props) {
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState('');
    const [top, setTop] = useState(0);
    const [left, setLeft] =useState(0);

    const handleHover = (event, name, type) => {
        const currRect = event.target.getBoundingClientRect();
        setTop(currRect.top);
        setLeft(currRect.left);
        if (type === 'leave') {
            setShowPopup('');
        } else {
            setShowPopup(name);
        }
    }

    const WrapperSelection = () =>{
        if(isMobile()){
            return 'emaintenance-wrapper-mobile'
        }
        else if(isTablet()){
            return 'emaintenance-wrapper-tablet'
        }
        else{
            return 'emaintenance-wrapper'
        }
    }

    return (
      <Col className={WrapperSelection()} id="col">
        <Card
          className="data-card"
          style={{ height: "375px", //boxShadow: "none"
         
        }}
        >
          <ScoreHeader isCoManager={true} color="#ffffff" id= "scoreHeader">
            <ScoreTitle value="card" id="card">
              <ScoreKpiName id="scoreKpiName" textColor="black">
                {t("Emaintenance_header")}
              </ScoreKpiName>
              <div
                className="right-card-view"
                onMouseOver={(e) => handleHover(e, "eMaintenance", "enter")}
                onMouseLeave={(e) => handleHover(e, "", "leave")}
                onClick={(e) => {
                  isTablet() && showPopup === "eMaintenance"
                    ? handleHover(e, "", "leave")
                    : handleHover(e, "eMaintenance", "enter");
                }}
              >
                {isTablet() ? (
                  <img
                    className="cross-class"
                    alt="tile_info"
                    src={showPopup === "eMaintenance" ? cross_icon : i_icon}
                  />
                ) : (
                  <img className="cross-class" alt="tile_info" src={i_icon} />
                )}
              </div>
            </ScoreTitle>
          </ScoreHeader>
          <Emaintanence isCoManager={true} class="image-cover" id="eMaintenance"/>
          {isTablet()
            ? showPopup === "eMaintenance" && (
                <MobilePopup
                  top={70}
                  left={left - 20}
                  width={15}
                  arrowTop={0}
                  arrowLeft={-100}
                  device="mobile"
                  id="scoreKpiName"
                >
                  <span className="What-you-can-expect">
                    <span
                      key={t("Emaintenance_Popup_Text")}
                      dangerouslySetInnerHTML={createMarkup(
                        t("Emaintenance_Popup_Text")
                      )}
                    />
                  </span>
                </MobilePopup>
              )
            : showPopup === "eMaintenance" && (
                <ScorePopupContainer
                  top={40}
                  left={3}
                  isTileWider={props.isTileWider}
                  width={13}
                 // arrowTop={-27}
                  arrowLeft={73}
                  id='popUpDesktop'
                >
                  <span className="What-you-can-expect">
                    <span
                      key={t("Emaintenance_Popup_Text")}
                      dangerouslySetInnerHTML={createMarkup(
                        t("Emaintenance_Popup_Text")
                      )}
                    />
                  </span>
                </ScorePopupContainer>
              )}
        </Card>
      </Col>
    );
}

const mapStateToProps = (state) => {
    const { scorecardInfo } = state;
    const { kpiName } = scorecardInfo;
    return { kpiName };
}

export default compose(
    connect(mapStateToProps, null)
)(withTranslation()(EmaintenanceWrapper));