import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import CustomerSatisfactionComponent from "./KPI/CustomerSatisfaction";
import LoyaltyRankingComponent from "./KPI/LoyaltyRanking";
import RegionalLoyaltyComponent from "./KPI/RegionalLoyalty";

function CustomerSection() {
  return (
    <>
      <Row>
        <Col>
          <CustomerSatisfactionComponent />
        </Col>
      </Row>
      <Row>
        <Col xs = {12} lg={8}>
          <RegionalLoyaltyComponent />
        </Col>
        <Col xs={12} lg={4} >
          <LoyaltyRankingComponent />
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state) {
}

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CustomerSection
);
