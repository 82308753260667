/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect } from "react";
import { ButtonContainer, ButtonText, TriangleDesignDown, TriangleDesignUp } from './button.Styled'

export function SelectionButton(props) {
    useEffect(() => {

    }, []);
    return (
        <>
            <ButtonContainer data-test="button-container" style = {{width:props.width}} onClick={() => props.onClick()}>
                <ButtonText data-test="button-text">{props.buttonText}</ButtonText>
                {!props.openDropDown ? <TriangleDesignDown width ={props.width} /> : <TriangleDesignUp width ={props.width} />}
            </ButtonContainer>
        </>
    )
}

export default SelectionButton
