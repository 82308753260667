import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from "redux";
import {
  defaultSortOrder,
  getFieldFromKey,
  renderSortIcons,
  sortData
} from "../../../scorecardNewDesign/utils";
import {
  Actual, ActualText,
  AllGraphContainer,
  BarGraphContainer,
  C1,
  CircleandDesc,
  Circlesubtext,
  CircularGraphContainer,
  Circularprogress,
  Colorgradient,
  CustomerContainer,
  CustomerSatisfactionTable,
  DataContainer,
  DividerVertical,
  Exclaimation, ExclaimationText,
  HeaderCell,
  Headertradingtable,
  LegendsRow,
  MainContainer,
  NocircularData,
  OldDataBlur,
  OlddataCScontent, Oldtextnd,
  Oldviewcustomersatisfaction,
  ProgressVoc,
  ScoreLegendContainer,
  SomeValue,
  TooltipValue,
  ValueContainer,
  WeekLabel
} from '../style/Customer.styled';
import { Legends } from "./Components/CircularGraphLegend";
import { TileHeader } from "./Components/TileHeader";
//import { BarGraphCustomer } from "./Components/BarChart";
import { isEmpty } from "lodash";
import { scorecardActions } from "../../../../actions/scorecardActions";
import { bpThemeGreenColor, CIRCULAR_RED, LEGEND_GRAY } from "../../../../constants/colors";
import isMobile from "../../../../helpers/isMobile";
import { capitalize } from "../../../Scorecard/utils";
import { KPINames } from "../../../ScorecardImproved/ScorecardImprovedConstants";
import ScoreLineGraph from "../../components/ScorecardLineGraph";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import { titleGenerator } from "../../scorecardConstants";
import { Olddatatradingapprovee } from "../../TradingProfitably/Styles/TradingUi.styled";
import useWindowDimensions, { responsiveGraphWidth } from "../../TradingProfitably/utils/WindowDimensions";

function CustomerSatisfaction(props) {
  const {
    state,
    title,
    toolTipText,
    displayGraph,
    headers,
    data,
    graph,
    rank,
    total,
    lastWeekUpdated,
    expectedRefreshTimestamp, } = props.data
  const { t } = useTranslation();
  let headerNames = data?.length > 0 ? defaultSortOrder(data) : [];
  const { width } = useWindowDimensions();
  const [graphData, setGraphData] = useState([])
  const [tableHeader, setTableHeaders] = useState([])
  const [sortedData, setSortedData] = useState(data);
  const [sortState, setSortState] = useState(headerNames);
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [decPos, setDecPos] = useState(1);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
  const [blur, setBlur] = useState(props.weekNumber !== lastWeekUpdated);



  useEffect(() => {
    setGraphData([]);
    async function correctGraph() {
      let graphData = await getGraphData(graph.lineGraph)
      setGraphData(graphData);
    }
    correctGraph();

  }, [props, width, blur])

  const getGraphData = (scoreSales) => {
    let sales = [];
    scoreSales?.forEach((sale) => {
      if (props.siteNames.length === 0 && !isMobile()) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else if (isMobile() && props.noSelected === 0) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
        ];
      }
    });
    return sales;
  };

  const colorDetermine = (value) => {
    if (value >= 75) {
      return bpThemeGreenColor
    }
    else if (value >= 25) {
      return LEGEND_GRAY
    }
    else {
      return CIRCULAR_RED
    }
  }
  const renderSortIcon = (colName) => {
    return true;
  };

  const sortItem = (keyName, sortState, sortedData) => {
    let result;
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    const rowsWithNA = data.filter((rows) => rows[keyName] === "NA");
    if (isAnyColumnSorted === "NONE") {
      const initialSortState = Object.keys(data[0]).map((item) => ({
        name: item,
        sortState: "NONE",
      }));
      result = sortData(
        keyName,
        initialSortState,
        data.filter((rows) => rows[keyName] !== "NA")
      );
    } else {
      result = sortData(
        keyName,
        sortState,
        sortedData.filter((rows) => rows[keyName] !== "NA")
      );
    }
    const currentState = result.sortedState.find((val) => val.name === keyName);
    setSortState(result.sortedState);
    setSortedData(
      currentState.sortState === "ASC"
        ? [...result.sortingData, ...rowsWithNA]
        : [...rowsWithNA, ...result.sortingData]
    );
  };

  const renderData = (headerKey, itemData, headerIndex, title) => {
    let fieldName = getFieldFromKey(headerKey);
    if (fieldName === 'wow') {
      return (
        <HeaderCell index={headerIndex}>{`${itemData[fieldName]}`}</HeaderCell>
      );
    }
    else if (fieldName === 'sites') {
      return (
        <HeaderCell index={headerIndex}>{`${capitalize(itemData[fieldName])}`}</HeaderCell>
      );
    }
    return (
      <HeaderCell index={headerIndex}>{itemData[fieldName] !== 'NA' ? `${itemData[fieldName]}%` : 'NA'}</HeaderCell>
    );
  };

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
    } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };

  const handleHover = (event, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };
  let circularGraph = graph?.data;
  let legendData = graph?.legend;

  const getHeaderState = () => {
    const score = circularGraph.filter(item => item.description == props.selectedManagerName)[0]?.percent;
    if (score >= 75) {
      return "OK";
    }
    else if (score >= 25) {
      return "GREY";
    }
    else {
      return "DANGER";
    }
  }


  function loadExcel() {
    props.exportToExcel(titleGenerator[title], headers, sortedData);
  }

  return (
    <CustomerContainer>
      <OldDataBlur boolTrue={blur} id="OldDataBlur">
        {title === 'Waste' || title === 'Till Losses' ? <>
          {
            props.weekNumber - 1 === 0 ?
              <>
                {!blur && 52 !== lastWeekUpdated && (
                  <Exclaimation
                    onMouseEnter={(e) => blurHover(e, "enter")}
                    onMouseLeave={(e) => blurHover(e, "leave")}
                    id="exclamationContainer"
                  >
                    <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                  </Exclaimation>
                )}
              </>
              :
              <>
                {!blur && props.weekNumber - 1 !== lastWeekUpdated && (
                  <Exclaimation
                    onMouseEnter={(e) => blurHover(e, "enter")}
                    onMouseLeave={(e) => blurHover(e, "leave")}
                    id="exclamationContainer"
                  >
                    <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                  </Exclaimation>
                )}
              </>
          }
        </> : <>
          {!blur && props.weekNumber !== lastWeekUpdated && (
            <Exclaimation
              onMouseEnter={(e) => blurHover(e, "enter")}
              onMouseLeave={(e) => blurHover(e, "leave")}
              id="exclamationContainer"
            >
              <ExclaimationText id="ExclaimationText">!</ExclaimationText>
            </Exclaimation>
          )}
        </>}
        {oldPopUpVisible && (
          <TooltipValue
            id="tooltipvalue"
            top={blurRect.top + 70}
            left={blurRect.left + 15}
          >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
        )}
        <TileHeader showPopup={showPopup}
          exportToExcel={() => loadExcel()}
          excelLoader={props.excelLoader}
          kpiName={props.kpiName}
          handleHover={(e, value) => handleHover(e, value)} title={title} state={getHeaderState()} />
        {showPopup && (
          <ScorePopupContainerWeb
            top={top}
            left={left - 20}
            width={18}
            id='popUpDesktop'
            textDescription={toolTipText}
          />
        )}
        <AllGraphContainer>
          <CircularGraphContainer>
            <WeekLabel>{props.dataFor == "Month" ? ('W' + props.weekNumber) : "Year Total"}</WeekLabel>
            <Circularprogress id="circularProgress">
              {circularGraph.length > 0 ? circularGraph.map((item, index) => {
                return (
                  <>
                    <CircleandDesc id={index + "circleandDesc"}>
                      <ProgressVoc
                        id={index + "progressVoc"}
                        percentage={item.percent}
                        color={colorDetermine(item.percent)}
                      >
                        <C1 id={index + "c1"}>
                          <ValueContainer id={index + "valueContainer"}>{item.percent}%</ValueContainer>
                          <SomeValue id={index + "someValue"}>{item.count}</SomeValue>
                        </C1>
                      </ProgressVoc>
                      <Circlesubtext id={index + "circleSubText"}>{item.description}</Circlesubtext>
                    </CircleandDesc>
                  </>
                );
              }) : <NocircularData>no data available</NocircularData>}
            </Circularprogress>
            <LegendsRow>
              {legendData.slice().reverse().map((data) => {
                return <Legends item={data}></Legends>
              })

              }
            </LegendsRow>
          </CircularGraphContainer>
          <DividerVertical />
          <BarGraphContainer>
            <ScoreLegendContainer id="ScoreLegendContainer">
            {!isMobile() && props.siteNames.length === 0 && (
              <>

                <Actual
                  id="actual-text"
                  isDanger={getHeaderState()}
                  isActualMe={true}
                ></Actual>
                <ActualText id="actual-text">
                  Actual UK
                </ActualText>{" "}
              </>
            )}

            {!isMobile() && (
              <>
                <Actual state={state}></Actual>
                <ActualText
                  id="actual-text"
                  isTill
                  Losses={title === "Till Losses"}
                >
                  {props.siteNames.length === 0
                    ? props.selectedManagerName
                    : props.siteNames.length === 1
                      ? props.siteNames[0]
                      : t("Selected Sites")}
                </ActualText>
              </>
            )}

            {isMobile() && props.siteNames.length === 0 && (
              <>
                <Actual state={state}></Actual>
                <ActualText id="actual-text" isLosses={title === "Till Losses"}>
                  Actual UK
                </ActualText>
              </>
            )}

            {isMobile() && (
              <>
                <Actual
                  id="actual-text"
                  isDanger={state === "DANGER"}
                  isActualMe={true}
                ></Actual>
                <ActualText
                  id="actual-text"
                  isTill
                  Losses={title === "Till Losses"}
                >
                  {props.noSelected === 0
                    ? props.selectedManagerName
                    : props.siteNames.length === 1
                      ? props.siteNames[0]
                      : t("Selected Sites")}
                </ActualText>
              </>
            )}
            </ScoreLegendContainer>
            {!isEmpty(graphData) ?
              <ScoreLineGraph
                regionalManager={props.selectedManagerName}
                isDanger={state === "DANGER"}
                scoreGraph={true}
                xScale={responsiveGraphWidth(width, "Customer")}
                graphId={`scoreCard-${title.replace(/[^a-zA-Z]/g, "")}`}
                graphClass={`scoreGraph-${title.replace(/[^a-zA-Z]/g, "")}`}
                sales={graphData}
                title={title}
                state={state}
                data={props.dataFor}
                selection={props.noSelected}
                siteLength={props.siteNames.length}
                device={isMobile()}
                id="Financial_graph"
                scroll={props.event}
                decPos={decPos}
                boolValue = {blur}
              /> : null}
          </BarGraphContainer>
        </AllGraphContainer>
        <CustomerSatisfactionTable>
        <Headertradingtable>
          {headers.map((item, index) => {
            return (
              <>
                <HeaderCell
                  onClick={() =>
                    sortItem(getFieldFromKey(item), sortState, sortedData)
                  }
                  usedFor="header"
                  index={index}
                >
                  {<Colorgradient index={index} state={state}>{item}</Colorgradient>}
                  {renderSortIcon(item) &&
                    renderSortIcons(getFieldFromKey(item), sortState, true, state)}
                </HeaderCell>
              </>
            );
          })}
        </Headertradingtable>
        <MainContainer height={150} style={{ marginBottom: 5 }}>
          {!isEmpty(sortedData) ? sortedData.map((itemData, index) => {
            if (itemData.sites !== "Total") {
              return (
                <DataContainer
                  key={index}
                  alignIndex={index}
                  id="dataContainer"
                >
                  {headers.map((headerKey, headerIndex) =>
                    renderData(headerKey, itemData, headerIndex, title)
                  )}
                </DataContainer>
              );
            }
          }) : null}
        </MainContainer>
        </CustomerSatisfactionTable>
      </OldDataBlur>
      {blur && (
        <Oldviewcustomersatisfaction id="OldDataFullview">
          <OlddataCScontent id="olddatacontent">
            <Oldtextnd id="old">You're viewing old data</Oldtextnd>
            <Oldtextnd id="data_not_updated_text">This data has not been updated yet.</Oldtextnd>
            <Oldtextnd id="oldtext">
              Scheduled updates occurs on&nbsp;{props.voiceOfCustomerData}
            </Oldtextnd>
            <Olddatatradingapprovee id="olddataapprove" onClick={() => setBlur(!blur)}>
              continue with old data
            </Olddatatradingapprovee>
          </OlddataCScontent>
        </Oldviewcustomersatisfaction>
      )}
    </CustomerContainer>
  )
}

const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
    scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

const mapStateToProps = (state) => {
  const { scorecardInfo, loader, authInfo, scorecardImprovedInfo } = state;
  const { userData } = authInfo;
  const { showLoader } = loader;
  const { excelLoader, kpiName, siteNames, noSelected, selectedManagerName } =
    scorecardInfo;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  const voiceOfCustomerData = layout?.data?.schedules?.voiceOfCustomerData;
  return {
    excelLoader,
    kpiName,
    showLoader,
    siteNames,
    noSelected,
    selectedManagerName,
    userData,
    voiceOfCustomerData
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CustomerSatisfaction);

