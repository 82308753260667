import { KPINames } from "../components/ScorecardImproved/ScorecardImprovedConstants";
import { scorecardReducerConstants } from "../constants/reducerConstants";

const initialState = {
  [KPINames.LAYOUT]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.SHOP_SALES]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.FUEL_SALES]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.SERVICES]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.WBC_SALES]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.WASTE]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.LOSS]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.CUSTOMER_SATISFACTION]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.REGIONAL_LOYALTY]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.LOYALTY_RANKING]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.PEOPLE_OVERVIEW]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.CLOCKING_ON_TIME]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.UNPUBLISHED_SCHEDULE]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.SITE_SAFETY_OVERVIEW]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.DARK_SITES]: {
    data: null,
    error: "",
    isLoading: false,
  },
  [KPINames.MANDATORY_TASKS]: {
    data: null,
    error: "",
    isLoading: false,
  },
};

export function scorecardImprovedInfo(state = initialState, action) {
  if (action.kpi) {
    const kpiName = action.kpi;
    const reducerConst = scorecardReducerConstants[kpiName];
    switch (action.type) {
      case reducerConst.SUCCESS:
        return {
          ...state,
          [kpiName]: {
            data: action.data.body,
            error: "",
            isLoading: false,
          },
        };
      case reducerConst.FAILURE:
        return {
          ...state,
          [kpiName]: {
            data: null,
            error: action.error.message || action.error.errorMessage || "",
            isLoading: false,
          },
        };
      case reducerConst.LOADER_START:
        return {
          ...state,
          [kpiName]: {
            ...state[kpiName],
            isLoading: true,
          },
        };
      case reducerConst.LOADER_DONE:
        return {
          ...state,
          [kpiName]: {
            ...state[kpiName],
            isLoading: false,
          },
        };
      default:
        return state;
    }
  }
  return state;
}
