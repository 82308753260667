import styled from "styled-components";

export const AnnoucmentMessageDiv = styled.div`
    font-size: larger;
    border-radius: 7px;
    border: 1px solid rgb(235, 235, 235);
    background: white;
    color: red;
    padding: 10px;
    margin-left: 13px;
    margin-bottom: 10px;
    margin-right: 14px;
    margin-top: 10px;
`;

export const AnnoucmentMessageImprovedMobileDiv =  styled.div`
font-size: small;
border-radius: 7px;
border: 1px solid rgb(235, 235, 235);
background: white;
color: red;
padding: 10px;
margin-left: 13px;
margin-bottom: 10px;
margin-right: 14px;
margin-top: 70px;
`;

export const AnnoucmentMessageMobileDiv = styled.div`
    font-size: small;
    border-radius: 7px;
    border: 1px solid rgb(235, 235, 235);
    background: white;
    color: red;
    padding: 10px;
    margin-left: 5px;
    margin-bottom: 0;
    margin-top: 10px;
`;
