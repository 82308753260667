/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/17/2022
 * Author : Aditya Padmanabhan
 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import i_icon_remove from "../../images/icons/i_icon_remove.svg";
import search from "../../images/icons/search.png";
import white_tick from "../../images/icons/white_tick_icon.svg";
import { FilterButton, FilterLayerMobile, FilterText } from '../GoogleReviewDashboard/ReviewsDashboard.styled';
import { SELECTEDFILTER } from '../filter/Filter.constants';
import { ApplyButton, ApplyText, CrossContainer, FilterHeader, FilterList, FilterListHeader, FilterListItem, FilterListText, FilterObject, FilterTitle, FilterViewMobile, MobileFilterContainer, SearchView, SelectedInputMobile, SiteMobileCircle } from '../filter/Filter.styled';
import { GreenValue, SiteSelected } from './ScoreCard.styled';
import { capitalize, searchRegionalManagerFilter, searchSite } from './utils';



function MobileScorecardFilter(props) {
    let [filterButton, setFilterButton] = useState(props?.filterButtonValue);
    const [searchView, setSearchView] = useState(false);
    const [searchViewRegionalManager, setSearchViewRegionalManager] = useState(false);
    let [siteSearch, setSiteSearch] = useState('')
    let [siteSearchRegionalMang, setSiteSearchRegionalMang] = useState('')
    let [searchSites, setSearchSites] = useState(props.arrayList)
    let [searchRegionalManager, setSearchSitesRegionalManager] = useState(props.regionalManagerList)
    let [selectedIndexForManager, setSelectedIndexForManager] = useState(props.regionalManagers.findIndex(obj => obj.regionalManagerName.toLowerCase() === props.selectedManagerName.toLowerCase()))
   
    const keyBoardValue = window.innerHeight;
    const { t } = useTranslation();
    useEffect(() => {
        setSearchSites(props.arrayList)
       
    }, [props.arrayList,props.regionalManagers])

    useEffect(() => {
        if(!props?.filterButtonValue){
        setFilterButton(props?.filterButtonValue)
        }
    }, [props?.filterButtonValue])

    // useEffect(()=>{
    //   let index =props.regionalManagers.findIndex(obj => obj.regionalManagerName.toLowerCase() === props.selectedManagerName.toLowerCase())
    //   //props.toggleManagerName({id:item.regionalManagerId, name:item.regionalManagerName})
    //  index>0 && setSelectedIndexForManager(index)
    // },[filterButton])

    function selectionFeature(key, index) {
        if (searchView) {
            let indexvalue = props.arrayList.indexOf(key);
            props.mobileSelection(key, indexvalue);
        }
        else {
            props.mobileSelection(key, index);
        }
    }
   async function selectionFeatureForManager(key, index) {
       setSelectedIndexForManager(index);
       await props.regionalManagerSelection(key, index)
       //scrollToPositionManager(key.regionalManagerName)
    }

   const scrollToPositionManager = (managerName)=>{
    setTimeout(()=>{
        let element = document.getElementById("list"+managerName);
        if(element == null){
            element = <div id={"list"+managerName} class="sc-hYzpey gGpbSB"><span class="sc-dMUtCB bHzxMT">{managerName}</span></div>
        }
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    }, 1000)
        
    }
    function titleMethod(title, showFilter) {
        return (
            <FilterObject id="filterObject">
                <FilterListHeader id="filterListHeader">
                    {title}
                </FilterListHeader>
                {showFilter && <CrossContainer id="crossContainer" onClick={() => {
                    setSearchView(title)

                }}>
                    <img src={search} alt='search' />
                </CrossContainer>}
                {showFilter && <SiteSelected id="siteSelected">{props.noSelected !== 0 ? <> <GreenValue>{props.noSelected}</GreenValue>&nbsp;selected</> : <><GreenValue>all</GreenValue>&nbsp;selected</>}</SiteSelected>}
            </FilterObject>
        )
    }

    function titleMethodNew(title, showFilter) {
        return (
            <FilterObject id="filterObject">
                <FilterListHeader id="filterListHeader">
                    {title}
                </FilterListHeader>
                {showFilter && <CrossContainer id="crossContainer" onClick={() => {
                    setSearchViewRegionalManager(title)

                }}>
                    <img src={search} alt='search' />
                </CrossContainer>}
            </FilterObject>
        )
    }

    async function handleChange(e, filterType) {
        if (filterType === t('Site Name:')) {
            setSiteSearch(e.target.value)
            if (e.target.value.length > 0) {
                let updatedSiteList = searchSite(e.target.value, props.arrayList)
                await setSearchSites(updatedSiteList);
                //props.selectFromMainList()
            } else {
                setSearchSites([...props.arrayList]);
            }

        } else {
            setSiteSearchRegionalMang(e.target.value)
            if (e.target.value.length > 0) {
                let updatedSiteList = searchRegionalManagerFilter(e.target.value, props.regionalManagerList)
                await setSearchSitesRegionalManager(updatedSiteList);
                //props.selectFromMainList()
            } else {
                setSearchSitesRegionalManager([...props.regionalManagerList]);
            }
        }
    }

    function searchMethod(searchValue, title, placeholder) {
        return (
            <>
                <SearchView id="searchView">
                    <SelectedInputMobile type='text'
                        placeholder={placeholder}
                        value={searchValue}
                        onChange={(e) => handleChange(e, title)}
                        id="selectedInputMobile"
                    />
                    <CrossContainer id="crossContainer" onClick={() => {
                        setSearchView(false)
                        setSiteSearch('');
                        setSearchSites(props.arrayList);
                    }}>
                        <img className="closeImage" src={i_icon_remove} alt='cross' style={{ height: 45, width: 45, marginRight: 15 }} />
                    </CrossContainer>
                </SearchView>
                {/* <SiteSelected usedFor='crosscontainer'>{props.noSelected !== 0 ?<> <GreenValue>{props.noSelected}</GreenValue>&nbsp;selected</>:<><GreenValue>all</GreenValue>&nbsp;selected</>}</SiteSelected> */}
            </>
        )
    }

    function searchMethodRegionalManager(searchValue, title, placeholder) {
        return (
            <>
                <SearchView id="searchView">
                    <SelectedInputMobile type='text'
                        placeholder={placeholder}
                        value={searchValue}
                        onChange={(e) => handleChange(e, title)}
                        id="selectedInputMobile"
                    />
                    <CrossContainer id="crossContainer" onClick={() => {
                        setSearchViewRegionalManager(false);
                        setSiteSearchRegionalMang('');
                        let index = props.regionalManagerList.findIndex(obj => obj.regionalManagerName.toLowerCase() === props.selectedManagerName.toLowerCase())
                        setSearchSitesRegionalManager(props.regionalManagerList);
                        selectionFeatureForManager(props.regionalManagerList[index], index);

                    }}>
                        <img className="closeImage" src={i_icon_remove} alt='cross' style={{ height: 45, width: 45, marginRight: 15 }} />
                    </CrossContainer>
                </SearchView>
                {/* <SiteSelected usedFor='crosscontainer'>{props.noSelected !== 0 ?<> <GreenValue>{props.noSelected}</GreenValue>&nbsp;selected</>:<><GreenValue>all</GreenValue>&nbsp;selected</>}</SiteSelected> */}
            </>
        )
    }

  async  function applyFunction() {
        setFilterButton(true)
        props.sendMobile()
    }

    function filterListMethod(searchValue, id, title, objectList, selected, list) {
        return (
            <div>
                {searchViewRegionalManager && (title === t('Regional Manager:')) ?
                    searchMethodRegionalManager(searchValue, title, t("Search a Regional Manager"))
                    : title === t('Regional Manager:') && titleMethodNew(title, true)}

                {searchView && (title === t('Site Name:')) ?
                    searchMethod(searchValue, title, t("Search a site"))
                    : title === t('Site Name:') &&
                    titleMethod(title, true)
                }
                {title === t('Regional Manager:') ?
                    <FilterList id={id}>
                        {list.map(function (key, index) {
                            return (
                                <FilterListItem id={"list"+key.regionalManagerName} selectedItem={index === selectedIndexForManager ? true : false}
                                    onClick={() => {
                                        selectionFeatureForManager(key, index)
                                    }}
                                >
                                    <FilterListText id={"list"+index} selectedItem={index === selectedIndexForManager ? true : false}>
                                        { capitalize(key.regionalManagerName)}
                                    </FilterListText>
                                </FilterListItem>
                            )
                        })}
                    </FilterList>
                    :
                    <FilterList id={id}>
                        {objectList.map(function (key, index) {
                            let checkList = list?.find(element => element === key)
                            return (

                                <FilterListItem id={"list"+key.regionalManagerName} selectedItem={checkList} onClick={() => {
                                    selectionFeature(key, index)
                                }}>
                                    <FilterListText id={"list"+index} selectedItem={checkList}>
                                        <SiteMobileCircle  id ={"circle"+index} selectedItem={checkList} src={checkList !== -1 ? white_tick : null} /> &nbsp; {key}
                                    </FilterListText>
                                </FilterListItem>
                            )
                        })}
                    </FilterList>

                }
            </div>
        )
    }
    let { siteList } = props
    return (
        <>
            {filterButton ? <>
                <FilterButton topAdjustment={keyBoardValue} onClick={() => setFilterButton(false)} id="filterButton">
                    <FilterText id="filterText">{"Filter"}</FilterText>
                </FilterButton>
            </> : <> <FilterLayerMobile id="filterLayerMobile">
                <MobileFilterContainer onClick={() => {
                    setFilterButton(true)
                }} />
                <FilterViewMobile id="filterViewMobile">
                    <ApplyButton id="applyButton">
                        <ApplyText
                            id="applyText"
                            onClick={() => applyFunction()}>
                            {t('Apply Filter')}</ApplyText>
                    </ApplyButton>
                    <FilterHeader id="filterHeader">
                        <FilterTitle id="filterTitle">{t('Filter By')}</FilterTitle>
                        {filterListMethod(siteSearchRegionalMang, SELECTEDFILTER.REGIONALMANAGER, t('Regional Manager:'), [`${props.name}`], true, searchRegionalManager)}
                        {filterListMethod(siteSearch, SELECTEDFILTER.SITENAME, t('Site Name:'), searchSites, true, siteList)}
                         {(props.selectedManagerName == props.mangName) && scrollToPositionManager(props.selectedManagerName)}
                    </FilterHeader>
                </FilterViewMobile>
            </FilterLayerMobile></>}
        </>
    )
}

function mapStateToProps(state) {
    const { scorecardInfo, authInfo } = state;
    const { noSelected, regionalManagers, selectedManagerName } = scorecardInfo;
    const { userData } = authInfo;
    const { name } = userData;
    return { name, noSelected, regionalManagers, selectedManagerName };
}

export default compose(connect(mapStateToProps, null))(
    MobileScorecardFilter
);
