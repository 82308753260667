/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 31/03/2021
* Author : Arun Rapolu
*/
import { constants } from '../constants/reducerConstants';

const initialState = {
  userDetails: [],
  channelOfTrades:{},
  views:{},
  viewMangerData:{},
  presentView:"",
  updatedLinksOfViews:[],
  editLinkCheck:false,
  updatedTable:[],
  allViews:[],
  scoreCardCategories:[],
  listOfFiles:[],
  submitButton:true,
  logs:[],
  siteMasterFileList: [],
  siteLogs: [],
  inProgressCategory: [],
  exeIds: [],
  siteExeId: []

}

export function adminInfo(state = initialState, action) {
    switch (action.type) {
        case constants.USER_ADMIN_SUCCESS:
            return {
                ...state,
                userDetails: action.data.users,
                views: action.data.views,
                channelOfTrades: action.data.channelOfTrades
            };
        case constants.SET_CATEGORY:
            return{
                ...state,
                inProgressCategory: action.data.length > 0 ? action.data : []
            }

        case constants.VIEW_MANAGER_SUCCESS:
            return {
                ...state,
                viewMangerData: action.data.viewDetails
                };
        case constants.SITE_MASTER_LIST_FETCH_SUCCESS:
            return{
                ...state,
                siteMasterFileList: action.data.list_of_files
            }
        case constants.DISABLE_SUBMIT:
            return{
                ...state,
                submitButton:false
            }
        case constants.UPDATE_EXECUTION_ID:
            return {
                ...state,
                exeIds: action.data
            }
        case constants.SITE_UPDATE_EXECUTION_ID:
                return {
                    ...state,
                    siteExeId: action.data
                }
        case constants.ENABLE_SUBMIT:
                return{
                    ...state,
                    submitButton:true
                }
        case constants.CATEGORY_FETCH_SUCCESS:
                return {
                        ...state,
                        scoreCardCategories: action.data.scoreCardCategories
                        };
        case constants.LOG_FETCH_SUCCESS:
            return{
                ...state,
                logs: action.dataOfLogs
            }
        case constants.SITE_LOG_FETCH_SUCCESS:
                return{
                    ...state,
                    siteLogs : action.dataOfLogs
                }
        case constants.FILE_TABLE_UPDATE:
            return{
                ...state,
                listOfFiles: action.data.list_of_files
            }
        case constants.FILE_TABLE_CLEAR:
            return{
                ...state,
                listOfFiles:[]
            }
        case constants.CATEGORY_FETCH_FAILURE:
                return {
                        ...state,
                                   
                    };
        case constants.USER_ADMIN_FAILURE:
            return {
                ...state
            };
        case constants.SAVE_UPDATED_TABLE:
            return {
                  ...state,
                  updatedTable: action.data

                };
        case constants.SAVE_UPDATED_LINK:
                return {
                          ...state,
                          updatedLinksOfViews: action.data
        
                        };
        case constants.SET_SEND_DATA:
            return {
                          ...state,
                          sendData: action.data
        
                   };
        case constants.SET_VIEW_SEND_DATA:
            return{
                ...state,
                viewDataToSend: action.data
            }
        case constants.CLEAR_SEND_DATA:
            return{
                ...state,
                sendData:initialState.sendData
            }
        case constants.UPDATE_VIEW:
            return{
                ...state,
                presentView: action.data
            }
        case constants.EDIT_LINK_FALSE:
            return{
                ...state,
                editLinkCheck:false
            }
        case constants.EDIT_LINK_TRUE:
            return{
                ...state,
                editLinkCheck:true
            }
        case constants.SET_PREVIEW:
            return{
                ...state
            }
        case constants.SET_ALL_VIEWS:
            return {
                ...state,
                allViews:action.data
            }
        case constants.NEW_ADDED_VIEW:
                return{
                    ...state,
                    viewMangerData: action.data.viewDetails
                }
        default:
            return state
    }
}
