import styled from "styled-components";
import isMobile from "../../../../helpers/isMobile";

export const Rightviewpeople = styled.div`
  flex: 0.33;
  margin-left: 1em;
`;

export const Clockcontainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transperent;
  justify-content: space-evenly;
  height: 100%;
`;


export const TotalclockingContainerRow = styled.div`
   display: flex;
   flex-direction: row;
   border-top: 1px solid rgba(0, 0, 0, 0.125);
   height: 2em;
   align-items: center;
   @media screen and (max-device-width: 480px) {
    width: 230%;
    overflow-y: hidden;
    overflow-x: hidden;
    align-items: center;
    }
 `;

export const ClockingTimeContainer = styled.div`
  margin-top: 1em;
  border-radius: 10px;
  border: solid 0.5px #ccc;
  box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.06);
  background-color: #fff;
`;

export const UnpublishedContainer = styled.div`
  height: calc(100% - 395px);
  border-radius: 10px;
  margin-top: 1em;
  border: solid 0.5px #ccc;
  box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.06);
  background-color: #fff;
`;

export const Clockingheadercell = styled.div`
  flex: ${(props) => (props.index === 1 ? "0.4" : "0.3")};
  display: flex;
  font-size: 12px;
  font-family: ${(props) =>
    props.usedIn === "header" ? "roboto-light" : "roboto-regular"};
  justify-content: ${(props) => (props.index === 0 ? "flex-start" : "flex-end")};
  margin-left: ${(props) => (props.index === 0 && props.rowName !== 'totalRow' ? "0.5em" : "")};
  margin-right: ${(props) => (props.index === 2 ? "1em" : "")};
  margin-top: ${(props) => (props.index === 2 ? "0.2em" : "")};
`;

export const Clockingspace = styled.span`
  display: inline-block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80px;
`;

export const LoyaltyRankingName = styled.span`
  margin-left: 0.5em;
  display: inline-block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
`;

export const OldClockingcontent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 250px;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 #e0e0e0;
  background-color: white;
  z-index: 1;
  position: absolute;
  margin-bottom: 20em;
`;

export const Oldloyaltycontent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 250px;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 #e0e0e0;
  background-color: white;
  z-index: 1;
  position: absolute;
  margin-bottom: 30em;
`;

export const Peopleheadingcell = styled.div`
  display: flex;
  flex: ${(props) =>
    props.index === 0 ? "0.2" : props.index === 1 ? "0.2" : "0.15"};
  align-items: center;
  justify-content: ${(props) =>
    props.index === 0 || props.index === 1 ? "flex-start" : "center"};
  font-size: 12px;
  color: ${(props) => (props.colorRed ? "red" : "black")};
  height: inherit;
  margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
  font-family: ${(props) =>
    props.renderedIn === "table" ? "Roboto-Regular" : "Roboto-Light"};
  // &:first-child {
  //    text-overflow: ellipsis;
  //   overflow: hidden;
  //   -webkit-line-clamp: 1;
  //   display: -webkit-box;
  //   -webkit-box-orient: vertical;
  // }
`;

export const Peopleicon = styled.div`
  width: 65px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Greenbar = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  //  background-color: green;
  border-radius: 2em;
  justify-content: flex-start;
`;

export const Progress = styled.div`
  width: ${(props) => (props.width === 0 ? 5 : props.width)}%;
  border-radius: 2em;
  height: 100%;
  background-color: ${(props) =>
    props.booleanProgress ? "red" : props.width === 0 ? "grey" : "green"};
`;

export const Redbar = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  border-radius: 2em;
  justify-content: flex-end;
`;
export const Whitepeoplebar = styled.div`
  width: 80%;
  height: 20%;
  background-color: #eaeaea;
  border-radius: 2em;
  display: flex;
  justify-content: center;
`;

export const PeopleOverviewTable = styled.div`
@media screen and (max-device-width: 480px) {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 16em;
  margin-top: 20px;
  }
`;

export const Headertradingtable = styled.div`
  display: flex;
  flex: 1;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  background-color: #fafafa;
  @media screen and (max-device-width: 480px) {
  width: 230%;
  overflow-y: scroll;
  overflow-x: hidden;
  }
`;

export const PeopleOverviewBox = styled.div`
  height: 550px;
  margin-top: 1em;
  border-radius: 10px;
  box-shadow: 0 2px 11px 0 rgba(209, 209, 209, 0.5);
  border: solid 0.5px #d4d4d4;
  background-color: #fff;
  @media screen and (max-device-width: 480px) {
    height: 565px;
    }
`;

export const ClockingOnTimeTable = styled.div`
@media screen and (max-device-width: 480px) {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 12em;
  }
`;

export const ClockingOnTimetableData = styled.div`
height: ${(props) => `${props.height}px`};
overflow-y: scroll;
::-webkit-scrollbar {
  width: ${(props) => (props.isTileWide && isMobile() ? "10px" : "2px")};
}
padding-top: 0.5em;
padding-bottom: 0.5em;
@media screen and (max-device-width: 480px) {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
`;

export const UnPublishedSchedulesTable = styled.div`
@media screen and (max-device-width: 480px) {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 8em;
  }
`;

export const UnPublishedSchedulesData = styled.div`
height: ${(props) => `${props.height}px`};
overflow-y: scroll;
::-webkit-scrollbar {
  width: ${(props) => (props.isTileWide && isMobile() ? "10px" : "2px")};
}
padding-top: 0.5em;
padding-bottom: 0.5em;
@media screen and (max-device-width: 480px) {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
`;


