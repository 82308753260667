import styled from 'styled-components';

export const TabContainer = styled.div`
display:flex;
flex:1;
`
export const TabDesign = styled.div`
display:flex;
flex:0.5;
align-items:center;
padding:16px 0px 16px 0px;
justify-content:center;
border-bottom:${(props) => !props.selectedTab ? 'solid 2px #ededed' : 'solid 2px #a1a1a1'};
`
export const TabText = styled.span`
font-family: Roboto-Regular;
font-size: 14px;
color: #000;
`