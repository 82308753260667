import * as d3 from "d3";
import {
  axisBottom,
  axisLeft,
  line
} from 'd3';
import { nest } from 'd3-collection';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { graphLabelGrayColor } from "../../../constants/colors";
import isMobile from "../../../helpers/isMobile";
import { Monthgiver, financialLineColor } from "../TradingProfitably/utils/WindowDimensions";

import { SvgContainer } from "../../FinancialWidget/FinancialWidget.styled";

const ScoreLineGraph = React.memo(props => {
  const { t } = useTranslation();
  useEffect(() => {
    render();
  }, [props.xScale.width]);

  useEffect(() => {
    render()

  }, [props.sales])

  const render = () => {
    const dailySales = props.sales;
    let actualSales, actualSalesUk, plannedSales;
    if (Array.isArray(dailySales) && dailySales.length > 0) {
      const xField = 'weekNumber';
      const yeField = props.data === 'Year' ? 'monthNumber' : null
      const svg = d3.select(`svg[class=${props.graphClass}]`);
      const graphContainer = d3.select(`#${props.graphId}`);



      const allSales = props.scoreGraph ? [...dailySales] : [...actualSales, ...plannedSales, ...actualSalesUk];

      const height = +svg.attr('height');
      const xValue = d => d[xField];
      const xAxisLabel = `${t('Week')} ${props.weekNum}`;

      const yeaField = d => d[yeField];

      const yValue = d => d.value;
      const yAxisLabel = t('Shop sales');

      const colorValue = d => d.type;

      const margin = { top: 10, right: 50, bottom: 50, left: 37 };
      const windowWidth = window.innerWidth;
      const innerWidth = windowWidth - margin.left - margin.right;
      const innerHeight = height - margin.top - margin.bottom;
      let xMaxRange;
      switch (true) {
        case windowWidth < 1200:
          xMaxRange = 400;
          break;
        case windowWidth > 1200 && windowWidth < 1400:
          xMaxRange = 200;
          break;
        case windowWidth > 1700 && windowWidth < 1950:
          xMaxRange = (30 * innerWidth) / 100;
          break;
        case windowWidth > 1950:
          xMaxRange = (40 * innerWidth) / 100;
          break;
        default:
          xMaxRange = (20 * innerWidth) / 100;
      }

      const xScale = d3.scaleLinear()
        .range([20, props.xScale])
        .domain(props.data === 'Month' ? d3.extent(allSales, xValue) : d3.extent(allSales, yeaField));

      const yScale = d3.scaleLinear()
        .domain([d3.min(allSales.map(minVal => minVal.value)), d3.max(allSales.map(minVal => minVal.value))])
        .range(props.title == 'Customer Satisfaction' ? [180, 0] : [120, 0]);

      const graphPosition = svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);
      let graphValue;
      var seenNames = {};
      if (props.data !== 'Month') {
        graphValue = allSales.filter(function (currentObject) {
          if (currentObject.monthNumber in seenNames) {
            return false;
          } else {
            seenNames[currentObject.monthNumber] = true;
            return true;
          }
        });
      }

      const xAxis = axisBottom(xScale)
        .tickPadding(2)
        .tickValues(props.data === 'Month' ? [...new Set(allSales.map(val => val[xField]))] : [...new Set(allSales.map(val => val[yeField]))])
        .tickFormat((value) => props.data === 'Month' ? `W${(value > 52) ? (value % 52) : value}` : Monthgiver(value, graphValue)

        );

      const yAxis = axisLeft(yScale)
        .tickSize(-(innerWidth))
        .tickPadding(-2)
        .tickValues(d3.min(allSales.map(minVal => minVal.value)) >= 0 ? [d3.min(allSales.map(minVal => minVal.value)), (d3.min(allSales.map(minVal => minVal.value)) + d3.max(allSales.map(minVal => minVal.value))) / 2, d3.max(allSales.map(minVal => minVal.value))] :
          [d3.min(allSales.map(minVal => minVal.value)), (d3.min(allSales.map(minVal => minVal.value)) + d3.max(allSales.map(minVal => minVal.value))) / 2, d3.max(allSales.map(minVal => minVal.value))])
        .tickFormat((value) => {
          try {
            if (value === 0 && d3.min(allSales.map(minVal => minVal.value)) !== 0) {
              return '0%';
            } else if (props.title === 'Till Losses') {
              return `${(value.toFixed(2))}%`;
            }
            else if (props.title === 'Customer Satisfaction') {
              return `${(value.toFixed(1))}%`
            }
            return `${(value.toFixed(1))}%`;

          }
          catch (error) {
            return '';
          }
        });

      const yAxisG = graphPosition.append('g').call(yAxis).attr('class', 'scale-y');

      if (!props.scoreGraph) {
        yAxisG.append('text')
          .attr('class', 'axis-label')
          .attr('y', -23)
          .attr('x', -innerHeight / 2)
          .attr('fill', 'black')
          .attr('transform', `rotate(-90)`)
          .attr('text-anchor', 'middle')
          .text(yAxisLabel);
      }


      const xAxisG = graphPosition.append('g').call(xAxis)
        .attr('transform', `translate(0,${props.title == 'Customer Satisfaction' ? (innerHeight - 50) : innerHeight - 20})`)
        .attr('class', 'scale-x')
        .selectAll('text')
        .style("fill", graphLabelGrayColor);


      if (!props.scoreGraph) {
        xAxisG.append('text')
          .attr('class', 'axis-label')
          .attr('y', 25)
          .attr('x', 125)
          .attr('fill', 'black')
          .text(xAxisLabel);
      }


      const lineGenerator = line()
        .x(d => props.data === 'Month' ? xScale(xValue(d)) : xScale(yeaField(d)))
        .y(d => yScale(yValue(d)));

      const nested = nest()
        .key(colorValue)
        .entries(allSales);

      const tooltip = !props.scoreGraph ? graphContainer
        .append("div")
        .attr("class", "hoverTooltip") : graphContainer
          .append("div")
          .style("display", "none");


      const mousemove = (event) => {
        let nearestData = [];
        let totalTitle;
        let isFirstPoint = false;
        const rect = graphPosition.node().getBoundingClientRect();
        const salesClassName = props.isBottom ? 'salesBottom' : 'salesTop';
        const mousePosition = d3.pointer(event, graphPosition.node());
        const xCordinate = xScale.invert(mousePosition[0]);
        const decimalPlace = xCordinate - Math.floor(xCordinate);
        const nearestX = Math.round(xCordinate);
        const nearestXScale = xScale(nearestX);
        // Calculate the tooltip's left and top positions relative to the graph container
        let tooltipLeft = event.clientX - rect.left;
        let tooltipTop = event.clientY - rect.top;

        // Adjust the tooltip's position to ensure it doesn't go beyond the edges of the graph container
        tooltipLeft = Math.max(0, Math.min(rect.width - 120, tooltipLeft));
        tooltipTop = Math.max(0, Math.min(rect.height, tooltipTop));

        if (decimalPlace >= 0.8 || decimalPlace <= 0.2) {
          isFirstPoint = props.scoreGraph
            ? nearestX === allSales[0].weekNumber
            : nearestX === allSales[0].dateIndex;
          nearestData = allSales.filter(data => props.scoreGraph ? data.weekNumber === nearestX : data.dateIndex === nearestX);
        }
        if (nearestXScale >= 186 && !props.scoreGraph) {
          tooltipLeft = nearestXScale - 40 - (nearestXScale / 3);
        } else if (props.scoreGraph) {
          tooltipLeft = rect.left + nearestXScale + 7;
        } else {
          tooltipLeft = nearestXScale - 40;
        }

        if (nearestData.length) {
  
          if (isFirstPoint) {
            if (isMobile())
            {
              tooltipLeft = tooltipLeft +10;
              tooltip.attr("class", "scoreTooltipMobileImprovedFirst");  
            }
            else{
              tooltipLeft = tooltipLeft - 140;
              tooltip.attr("class", "scoreTooltipImprovedFirst");
            }
          }
          else {
            if(isMobile())
            {
              tooltipLeft = tooltipLeft - 75;
              tooltip.attr("class", "scoreTooltipMobileImproved");  
            }
            else{
              tooltipLeft = tooltipLeft - 140;
              tooltip.attr("class", "scoreTooltipImproved");  
            }
          }

          tooltip.style("font-size", isMobile() ? "10px" : "14px")
            .style("left", `${tooltipLeft}px`)
            .style("top", `${tooltipTop + rect.top + 20}px`);
          switch (props.title) {
            case 'Waste':
              totalTitle = 'Total Waste';
              break;
            case 'Till Losses':
              totalTitle = 'Total Till Losses';
              break;
            case 'Fuel Volume vs Plan':
              totalTitle = 'Total Volume';
              break;
            default:
              totalTitle = 'Total Sales';
          }

          if (props.scoreGraph && !props.boolValue) {
            if (props.title === 'Till Losses') {
              tooltip.html(`
                <div>Week ${nearestData[0].weekNumber}</div>
                <div class='country'>UK: ${nearestData[0].avgCountry}%</div>
                <div>${props.device && props.selection === 0 ? props.regionalManager : !props.device && props.siteLength === 0 ? props.regionalManager : t("Selected Sites")}: ${nearestData[0].avgRegionalManager}%</div>
                <div>${totalTitle}: £${nearestData[0].totalRegionalManager.toLocaleString('en-GB')}</div>
              `)
                .style("display", "block");
            } else if (props.title === 'Fuel Volume vs Plan') {
              tooltip.html(`
                <div>Week ${nearestData[0].weekNumber}</div>
                <div class='country'>UK: ${nearestData[0].avgCountry ? nearestData[0].avgCountry + "%" : "NA"}</div>
                <div>${props.device && props.selection === 0 ? props.regionalManager : !props.device && props.siteLength === 0 ? props.regionalManager : t("Selected Sites")}: ${nearestData[0].avgRegionalManager}%</div>
                <div>${totalTitle}: ${nearestData[0].totalRegionalManager.toLocaleString('en-GB')}L</div>
              `)
                .style("display", "block");
            }
            else if (props.title === 'Regional Loyalty') {
              tooltip.html(`
                <div>Week ${nearestData[0].weekNumber}</div>
                <div class='country'>UK Issuance: ${nearestData[0].avgCountry ? nearestData[0].avgCountry + "%" : "NA"}</div>
                <div>${props.device && props.selection === 0 ? props.regionalManager : !props.device && props.siteLength === 0 ? props.regionalManager + " " + "Issuance" : t("Selected Sites Issuance")}: ${nearestData[0].avgRegionalManager}%</div>
                
              `)
                .style("display", "block");
            }
            else if (props.title === 'Customer Satisfaction') {
              tooltip.html(`
                <div>Week ${nearestData[0].weekNumber}</div>
                <div class='country'>UK: ${nearestData[0].avgCountry ? nearestData[0].avgCountry + "%" : "NA"}</div>
                <div>${props.device && props.selection === 0 ? props.regionalManager : !props.device && props.siteLength === 0 ? props.regionalManager : t("Selected Sites")}: ${nearestData[0].avgRegionalManager}%</div>
                
              `)
                .style("display", "block");
            }
            else if (props.title === 'People Overview') {
              tooltip.html(`
                <div>Week ${nearestData[0].weekNumber}</div>
                <div class='country'>UK Labour vs Demand: ${nearestData[0].avgCountry !== 'NA' ? nearestData[0].avgCountry + "%" : "NA"}</div>
                <div>${props.device && props.selection === 0 ? props.regionalManager : !props.device && props.siteLength === 0 ? props.regionalManager + " " + "Labour vs Demand" : t("Selected Sites Labour vs Demand")}: ${nearestData[0].avgRegionalManager}%</div>
                
              `)
                .style("display", "block");
            }
            else {
              try {
                tooltip.html(`
                  <div>Week ${nearestData[0].weekNumber}</div>
                  <div class='country'>UK: ${nearestData[0].avgCountry ? nearestData[0].avgCountry + "%" : "NA"}</div>
                  <div>${props.device && props.selection === 0 ? props.regionalManager : !props.device && props.siteLength === 0 ? props.regionalManager : t("Selected Sites")}: ${nearestData[0].avgRegionalManager ? nearestData[0].avgRegionalManager + "%" : "NA"}</div>
                  <div>${totalTitle}: £${nearestData[0].totalRegionalManager.toLocaleString('en-GB')}</div>
                  `)
                  .style("display", "block");
              } catch (e) {

              }
            }
          } else {
            tooltip.html(`
              <div>
                <span class="dateVal">${nearestData[0].date.substring(0, 5)}</span> 
                <span class=${salesClassName}>${nearestData[0].salesActual}k</span> 
                <span class="dateVal">vs ${nearestData[0].plannedSales}k</span> 
                <span class=${salesClassName}>${nearestData[0].diffInPerc}%</span>
              </div>
            `)
              .style("opacity", 1)
              .style("display", "block");
          }
        }
      }

      const mouseleave = (event) => {
        tooltip.style("display", "none");
      }

      svg.selectAll('.score-graph').raise().data(nested).enter()
        .append('path')
        .attr('class', 'score-graph')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .attr('d', d => lineGenerator(d.values))
        .attr('stroke', d => financialLineColor(d.key, props.isBottom, props.scoreGraph, props.isDanger));



      graphPosition.selectAll(null).data(nested)
        .enter().append('g').selectAll('circle')
        .data(d => d.values)
        .enter().append("circle")
        .attr("class", "data-circle")
        .attr('fill', d => financialLineColor(d.type, props.isBottom, props.scoreGraph, props.isDanger))
        .attr("r", d => d.value !== null ? 4 : 0)
        .attr("cx", function (d) { return props.data === 'Month' ? xScale(d[xField]) : xScale(d[yeField]) })
        .attr("cy", function (d) { return yScale(d.value) })
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave)

      let selectionWidth = (props.xScale / 2 + 10)
      svg.append('rect')
        .attr('x', 37)
        .attr('y', 10)
        .style("pointer-events", "all")
        .attr('width', props.data === 'Month' ? selectionWidth : ((selectionWidth / graphValue.length) * (props.decPos * 2) - 25) || selectionWidth)
        .attr('height', props.title == 'Customer Satisfaction' ? (innerHeight - 60) : (innerHeight - 30))
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave)
        .attr('stroke-dasharray', '1px dotted #f00')
        .attr('fill', props.data === 'Month' ? 'none' : 'rgba(234, 234, 234, 0.2)');


      window.addEventListener('scroll', mouseleave);
      window.addEventListener("mousewheel", mouseleave);
      window.addEventListener("touchmove", mouseleave);// it's for mobile version
      return () => {
        window.removeEventListener('scroll', mouseleave);
        window.removeEventListener('mousewheel', mouseleave);
        window.removeEventListener("touchmove", mouseleave); // it's for mobile version

      };
    }

  };

  return (
    <SvgContainer title={props.title} id={props.graphId}>
      <svg className={props.graphClass} width='100%' height={props.title == 'Customer Satisfaction' ? '300' : '210'}></svg>
    </SvgContainer>
  );
});
export default ScoreLineGraph;