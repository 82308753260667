import React from 'react';
import { AnnoucmentMessageMobileDiv } from '../scorecardNewDesign/Announcement/message.styled';

function AnnoucementMobile({ announcement }) {

    if (!announcement )
    {
        return null;
    }

    return (announcement?.message && (<AnnoucmentMessageMobileDiv>
        {'Announcement - '}
        {announcement.message}
        <br></br>
        {`Updated on - ${announcement.date}`} 
    </AnnoucmentMessageMobileDiv> )) 
}

export default AnnoucementMobile