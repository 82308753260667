/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 05/09/2022
 * Author : Aditya Padmanabhan
 */

import React from "react";
import { Valuetext } from "../Scorecard/ScoreCard.styled";
import {BlackAverageLine,BlackLine,} from "./../E-Maintenance/EMaintenance.styled";

const ProgressBar = (props) => {
  const { completed } = props;
  const containerStyles = {
    height: 9,
    width: "100%",
    marginTop:15,
    borderRadius: 10,
    backgroundImage: "linear-gradient(to right, #f7d638, #edac1a)",
  };
  const fillerStyles = {
    height: "100%",
    width: completed === 0 ? "100%" : `${completed}%`,
    backgroundImage: props.isCoManager ? "linear-gradient(to right, #007833, #009900)" : "linear-gradient(to right, #00c900, #009900)",
    borderRadius: "inherit",
    textAlign: "center",
  };
  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
      </div>
  );
};

export default ProgressBar;

export const TargetBar = (props) => {
  const { completed, isHigh, sidepercent, actual } = props;
  const containerStyles = {
    height: 14,
    width: "100%",
    backgroundColor: `${isHigh === "yes" ? "#BEE3BB" : "#F9E6BA"}`,
    margin: "auto",
  };

  const fillerStyles = {
    height: "100%",
    width: completed === 0 ? "100%" : `${completed}%`,
    backgroundColor: `${isHigh === "yes" ? "#29A21F" : "#edac1a"}`,
    float: "left",
  };
  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
      <Valuetext>{`${actual}%`}</Valuetext>
      </div>
      <BlackLine Sidepercent={sidepercent}/>
      
    </div>
  );
};

export const AverageBarYellow = (props) => {
  const { Target, isHigh, sidepercent, average } = props;
  const containerStyles = {
    height: `${Target}%`,
    width: "100%",
    backgroundColor: "#F9E6BA",
    margin: "auto",
  };

  const fillerStyles = {
    width: 45,
    height: 155,
    backgroundColor: "#edac1a", //"#F9E6BA" #edac1a
    fontSize: "10px",
  };
  return (
    <div style={{ marginLeft: 40 }}>
      <div style={fillerStyles}>
        <BlackAverageLine />
        <div style={containerStyles}></div>
      </div>
      <div
        style={{ marginTop: -35, width: 45, color: "#000000" }}
      >{`Average${average}%`}</div>
    </div>
  );
};

export const AverageBarGreen = (props) => {
  const { Target, average } = props;
  const fillerStyles = {
    width: 45,
    height: 155,
    backgroundColor: "#29A21F",
    fontSize: "10px",
  };
  return (
    <div style={{ marginLeft: 40 }}>
      <div style={fillerStyles}>
        <BlackAverageLine margin={Target} id="green" />
      </div>

      <div
        style={{ marginTop: -35, width: 45, color: "#000000" }}
      >{`Average${average}%`}</div>
    </div>
  );
};

export const ComplimentProgressBar = (props) => {
  const { completed, color, value } = props;
  const containerStyles = {
    height: 14,
    width: `${completed}%`,
    backgroundColor: color,
    font: 11,
  };

  return (
    <div style={containerStyles}>
      <Valuetext>{value}</Valuetext>
    </div>
  );
};
