import { constants } from "../constants/reducerConstants";

const initialState = {
  financialData: {},
  error: ""
};

export function financialInfo(state = initialState, action) {
  switch (action.type) {
    case constants.FINANCIAL_DATA_SUCCESS:
      return {
        ...state,
        financialData: action.data.body
      };
    case constants.FINANCIAL_DATA_FAILURE:
      return {
        ...state,
        error: action.error
        
      };
    default:
      return state;
  }
}
