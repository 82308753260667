export const renderAlert = (value) => {
    if (value >= 10 || value <= -10) {
        return true;
    }
    return false;
}

const MAP_HEADERS_KEY = {
     'Completed':'completed',
    'Sites':'sites',
    'W + 1':'W1',
    'W + 2':'W2',
    'Safety Ind.':'safetyInd',
    'Safety Conv.':'safetyConv',
    'Proactive Conv.':'proActiveConv',
    'Near Miss':'nearMiss',
    'Inj. Count':'injCount',
    'G+':'g',
    'Security':'security',
}

export function returnRequiredRegionalHeader(value){
         return MAP_HEADERS_KEY[value]
    }

    export const SAFETY_FIRST_LEGENDS = [
        { 
           name: 'Security',
           color: '#007f00'
        },
        { 
            name: 'Near Miss',
            color: '#99cc00'
         },
        { 
           name: 'G+',
           color: '#004f00'
        },
        { 
           name: 'Injury',
           color: '#999999'        
        },
        
        { 
            name: 'Safety Conversations',
            color: '#ffffff'
         }

     ];

     export const SUBGROUPS = ['security','nearMiss','g','injuries'];
