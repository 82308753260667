import React, { useEffect } from "react";
import { connect } from "react-redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import TradingUiComponent from "../../../../scorecardNewDesign/TradingProfitably/Screens/TradingUiComponent";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const FuelVolume = ({ fuel_sales, getFuelSalesData, layout }) => {
  useEffect(() => {
    getFuelSalesData(KPINames.FUEL_SALES);
  }, []);

  if (fuel_sales.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }
  if (fuel_sales?.data) {
    return (
      <TradingUiComponent
        dataFor={"Month"}
        data={fuel_sales.data}
        index={1}
        weekNumber={layout?.data?.weekNumber}
        expectedRefreshTimestamp =  {layout?.data?.schedules?.fuelSalesData}
      />
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
    authInfo: {
      userData: { name },
    },
  } = state;
  const fuel_sales = scorecardImprovedInfo[KPINames.FUEL_SALES];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return { name, fuel_sales, layout };
}

const mapDispatchToProps = {
  getFuelSalesData: (kpiUrl) => scorecardActions.getScoreCardContent(kpiUrl),
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelVolume);
