import styled from "styled-components";

export const FinancialWidgetContainer = styled.div`
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    object-fit: cover;
    height: 250px;
    overflow-y: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 20px 0px 20px;
  ${(props) => 
    props.isTop && `
    margin-top: 1.7em;
    `}
`;

export const ShopSalesTitle = styled.span`
  font-family: Roboto-Light;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  flex: 1.5;
  color: #111;
  align-self: center;
`;

export const LFL = styled.span`
  flex: 0.3;
  font-size: 14px;
  align-self: center;
  text-align: left;
`;

export const Plan = styled.span`
  font-size: 14px;
  align-self: center;
`;

export const BottomSales = styled.div`
  padding: 12px 20px 0px 20px;
`;

export const TopSales = styled.div`
  padding: 12px 20px 0px 20px;
`;

export const SiteContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Rank = styled.div`
  font-family: Roboto-Regular;
  font-size: 10px;
  margin-right: 6px;
`;

export const SiteName = styled.div`
  font-family: Roboto-Regular;
  font-size: 14px;
  align-self: center;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.fullText ? '' : 1};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  width: 10em;
`;

export const LFLValue = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  width: 4.7em;
  align-self: center;
  text-align: right;
`;

export const PlanValue = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  align-self: center;
  width: 6em;
  text-align: right;
`;

export const RankContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LabelContainer = styled.div`
  display: inherit;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
  &:hover {
    background-color: ${(props) => props.isBottom ? 'rgba(245, 206, 51, 0.17)' : 'rgba(0, 153, 1, 0.17)'};
  }
  ${(props) => 
    props.isOpen && `
    background-color: ${props.isBottom ? 'rgba(245, 206, 51, 0.17)' : 'rgba(0, 153, 1, 0.17)'};
    `}
`;

export const GraphContainer = styled.div``;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;

export const Planned = styled.div`
  height: 13px;
  width: 13px;
  background-color: #666666;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
`;

export const Actual = styled.div`
  height: 13px;
  width: 13px;
  background-color: ${(props) => props.isBottom ? '#edac1a' : '#009900'};
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
`;

export const PlannedText = styled.span`
  font-family: Roboto-Light;
  font-size: 14px;
  margin-right: 1em;
`;

export const ActualText = styled.span`
  font-family: Roboto-Light;
  font-size: 14px;
`;

export const HighlightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Symbol = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 9px; 
  margin-top: 2px;
  margin-left: 13px;
  background-color: ${(props) => props.isBottom ? '#edac1a' : '#009900'};
  font-family: Roboto-Regular;
  font-size: 10px;
`;

export const Svg = styled.svg`
  height: ${(props) => `${props.containerHeight}em`};
  width:'100%';
`;

export const SvgContainer = styled.div`
  margin-left: 8px;
`;
