/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 22/4/2022
 * Author : Aditya Padmanabhan
 */

import React from 'react';
import {Container,SpincontainingMessage,MessageText, Clockwisebox,GreenColorText} from "./EMaintenance.styled";
import i_clockwise from "../../images/icons/i_clockwise.svg";
import { useTranslation } from "react-i18next";

function EmaintenanceError(props) {
  const { t } = useTranslation();
  return (
      <Container><SpincontainingMessage>
      <MessageText>{t("Ooops, something went wrong. Please")}&nbsp;<GreenColorText onClick={()=>props.loadData()}>{t("refresh")}</GreenColorText></MessageText>
      <Clockwisebox> <img src={i_clockwise} alt='clockwise'onClick={()=>props.loadData()}/></Clockwisebox>
  </SpincontainingMessage></Container>
    
  )
}

export default EmaintenanceError