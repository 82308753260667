import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import TradingUiComponent from "../../../../scorecardNewDesign/TradingProfitably/Screens/TradingUiComponent";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const ShopAndSales = ({ shop_sales, getShopAndSalesData, layout, expectedRefreshTimestamp }) => {
  useEffect(() => {
    getShopAndSalesData(KPINames.SHOP_SALES);
  }, []);

  if (shop_sales.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (shop_sales.data) {
    return (
      <TradingUiComponent
        dataFor={"Month"}
        data={shop_sales.data}
        index={0}
        weekNumber={layout?.data?.weekNumber}
        expectedRefreshTimestamp={layout.data?.schedules?.shopSalesData}
      />
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
    authInfo: {
      userData: { name },
    },
  } = state;

  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  const shop_sales = scorecardImprovedInfo[KPINames.SHOP_SALES];
  return { name, shop_sales, layout };
}

const mapDispatchToProps = {
  getShopAndSalesData: (kpiName) =>
    scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ShopAndSales
);
