/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect } from "react";
import { SearchContainer, InputText, CrossContainer, SearchMobileContainer } from "../tableReview/TableReview.styled";
import i_cross from "../../images/icons/i_cross.svg";
import search from "../../images/icons/search.png";

export function Search(props) {
    useEffect(() => {

    }, []);

    if (props.isMobile) {
        return (
            <SearchMobileContainer isReviewsSection={props.isReviewsSection} data-test="search-container">
                <InputText
                    data-test="input-view"
                    type="text"
                    value={props.value}
                    isReviewsSection={props.isReviewsSection}
                    placeholder={props.placeholder}
                    onChange={(e) => props.onChange(e)}
                />
            <CrossContainer data-test="close-button" isReviewsSection={props.isReviewsSection} onClick={() => { props.onClick() }}>
                <img className="closeImage" src={i_cross} alt='cross' style={{ height: 17, width: 17 }} />
            </CrossContainer>
            </SearchMobileContainer>
        );
    }

    return (
        <SearchContainer>
            <InputText
                type="text"
                value={props.value}
                placeholder={props.placeholder}
                onChange={(e) => props.onChange(e)}
            />
            <CrossContainer onClick={() => { props.onClick() }}>
                {props.value.length === 0 ? <img src={search} alt='search'/> :
                    <img className="closeImage" src={i_cross} alt='cross' style={{ height: 15, width: 15 }} />}
            </CrossContainer>
        </SearchContainer>
    )
}

export default Search
