import styled from "styled-components";

export const MainContainer = styled.div`
  flex: 1;
`;

export const TradingContainer = styled.div`
  height: auto;
  display: flex;
  justify-content: space-evenly;
  margin-top: 1em;
  flex: 1;
`;
export const ScoreGraphContainer = styled.div`
  height: 235px;
  border-top: ${(props) =>
    props.id === "CS" ? "1px solid rgba(0, 0, 0, 0.125)" : ""};
`;

export const ScoreCardContainer = styled.div`
  margin-left: 1.6em;
  margin-right: 1.6em;
  position: relative;
`;
export const TradingBox = styled.div`
  height: 550px;
  margin-top: 1em;
  //  background-color: red;
  border-radius: 10px;
  box-shadow: 4px 4px 17px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dedede;
  background-color: #fff;
`;

export const TotalContainerRow = styled.div`
   display: flex;
   flex-direction: row;
   border-top: 1px solid rgba(0, 0, 0, 0.125);
   height: 1em;
   @media screen and (max-device-width: 480px) {
    width: 230%;
    }
 `;

export const TotalcontainerTradingmobile = styled.div`
   display: flex;
   border-top: 1px solid rgba(0, 0, 0, 0.125);
   height: 1em;
   flex-direction: row;
   padding: 9px 12px 9px 5px;
   align-items: center;
   flex: 1;
   width: 230%;
`

export const Mtradingmobileheader = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid grey;
  display: flex;
  height: 40px;

  align-items: center;
  flex: 1;
  width: 230%;
`;

export const Titlearea = styled.div`
  height: 61px;
  background-color: ${(props) =>
    props.state === "OK" ? "#288f33" : "#e64949"};
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TilteAndRank = styled.text`
  width: 250px;
  font-size: ${(props) => (props.usedFor === "mobile" ? "14px" : "16px")};
  padding-left: 1em;
  color: white;
  font-family: Roboto-Regular;
`;

export const Exportandispace = styled.div`
  margin-right: 1em;
  display: flex;
  width: 150px;
  height: 40px;
  justify-content: space-around;
  align-items: center;
  // background-color: red;
`;
export const TradingProfitablyTable = styled.div`
@media screen and (max-device-width: 480px) {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 15em;
  }
`;

export const Headertradingtable = styled.div`
  display: flex;
  flex: 1;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  background-color: #fafafa;
  @media screen and (max-device-width: 480px) {
    width: 230%;
    overflow-y: scroll;
    overflow-x: hidden;
    }
`;

export const Exportcontainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 94px;
  height: inherit;
  background-color: ${(props) =>
    props.backgroundcolor === "DANGER" ? "#9e3232" : "#006600"};
  align-items: center;
  color: rgba(255, 255, 255, 0.92);
  font-family: roboto-regular;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
`;

export const Infoicon = styled.img`
  width: 18px;
  height: 18px;
  margin: 8px 0px 8px 13px;
  object-fit: contain;
`;

export const ExportIcon = styled.img`
  height: 40%;
`;

export const HeaderCell = styled.div`
  display: flex;
  flex: ${(props) => (props.index === 0 ? "0.15" : "0.14")};
  align-items: center;
  justify-content: ${(props) => (props.index === 0 ? "flex-start" : "flex-end")};
  font-size: 12px;
  height: inherit;
  margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
  margin-left: "1em"
  font-family: ${(props) =>
    props.usedFor === "header" ? "Roboto-Light" : "Roboto-Regular"};
  &:first-child {
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
export const MtradingLossDataCells = styled.div`
display: flex;
flex: ${(props) => (props.index === 0 ? "0.15" : "0.14")};
align-items: center;
justify-content: ${(props) => (props.index === 0 ? "flex-start" : "flex-end")};
font-size: 12px;
height: inherit;
margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
font-family: ${(props) =>
  props.usedFor === "header" ? "roboto-light" : "roboto-regular"};
  &:first-child {
     min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
     top: 10px;
`;

export const TradingDataCell = styled.div`
  display: flex;
  flex: ${(props) => (props.index === 0 ? "0.15" : "0.14")};
  align-items: center;
  justify-content: ${(props) => (props.index === 0 ? "flex-start" : "flex-end")};
  font-size: 12px;
  height: inherit;
  min-width: 50px;
  margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
  font-family: ${(props) =>
    props.usedFor === "header" ? "Roboto-Light" : "Roboto-Regular"};
  &:first-child {
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    top: 10px;
  }
`

export const TradingDatatotalcell = styled.div`
display: flex;
flex: ${(props) => (props.index === 0 ? "0.15" : "0.14")};
align-items: center;
justify-content: ${(props) => (props.index === 0 ? "flex-start" : "flex-end")};
margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
font-size: 12px;
height: inherit;
min-width: 50px;
font-family: ${(props) =>
props.usedFor === "header" ? "Roboto-Light" : "Roboto-Regular"};
 padding-top: 2em;
 
`;

export const Lossdatatotalcell = styled.div`
display: flex;
flex: 0.2;
align-items: center;
justify-content: ${(props) => (props.index === 0 ? "flex-start" : "flex-end")};
margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
margin-right: ${(props) => (props.index === 4 ? "1em" : "")};
font-size: 12px;
height: inherit;
min-width: 50px;
font-family: ${(props) =>
props.usedFor === "header" ? "Roboto-Light" : "Roboto-Regular"};
 padding-top: 2em;
`;



export const TradingDataMobileTotal = styled.div`
display: flex;
flex: ${(props) => (props.index === 0 ? "0.15" : "0.14")};
align-items: center;
justify-content: ${(props) => (props.index === 0 ? "flex-start" : "flex-end")};
font-size: 12px;
height: inherit;
min-width: 0px;
font-family: ${(props) =>
  props.usedFor === "header" ? "Roboto-Light" : "Roboto-Regular"};
padding-top: 2em;
padding-left: ${(props) => props.index === 0 ? '1em':''};
padding-right: ${(props) => props.index === 6 ? '1em':''};
`;

export const headerCellMtable = styled.div`
  display: flex;
  flex: ${(props) => (props.index === 0 ? "0.4" : "0.3")};
  align-items: center;
  justify-content: ${(props) => (props.index === 0 ? "flex-start" : "center")};
  font-size: 12px;
  height: inherit;
  margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
  font-family: ${(props) =>
    props.usedFor === "header" ? "Roboto-Light" : "Roboto-Regular"};
  &:first-child {
    // display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Colorgradient = styled.text`
 color: ${(props) => props.decideColor};
 text-align: center;
`;

export const Headeradjustments = styled.div`
  width: 55px;
  text-align: center;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) =>
    props.index === 0 && props.state === "OK" ? "#007833" : ""};
`;

export const WasteCell = styled.div`
  display: flex;
  flex: ${(props) => (props.index === 0 ? "0.2" : "0.2")};
  align-items: center;
  justify-content: ${(props) => (props.index === 0 ? "flex-start" : "flex-end")};
  font-size: 12px;
  height: inherit;
  margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
  font-family: ${(props) =>
    props.usedFor === "header" ? "roboto-light" : "roboto-regular"};
  &:first-child {
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
`;

export const Wastedatacell = styled.div`
  display: flex;
  flex: ${(props) => (props.index === 0 ? "0.2" : "0.2")};
  align-items: center;
  justify-content: ${(props) => (props.index === 0 ? "flex-start" : "flex-end")};
  font-size: 12px;
  height: inherit;
  margin-left: ${(props) => (props.index === 0 ? "1em" : "")};
  margin-right: ${(props) => (props.index === 4? "1em" : "")};
  font-family: ${(props) =>
    props.usedFor === "header" ? "roboto-light" : "roboto-regular"};
  &:first-child {
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    position: relative;
    top: 10px;
  }
`;

export const Weekheadertext = styled.div`
  margin-top: 1em;
  margin-left: 0;
  font-size: 16px;
  font-family: roboto-regular;
`;

export const MobileTradingContainer = styled.div`
  display: flex;
  margin-left: -1em;
  margin-right: -1em;
  margin-top: 1em;
  height: auto;
  flex-direction: column;
  //  background-color: orange;
`;

export const TradingMobileBox = styled.div`
  height: 35em;
  width: 100%;
  margin-top: 1em;
  border-radius: 10px;
  box-shadow: 4px 4px 17px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dedede;
  background-color: #fff;
`;

export const MobileTradingWeekHeader = styled.div`
  margin-top: 1.5em;
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: rgba(17, 17, 17, 0.64);
`;
export const MobileTradingTable = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  height: 15.5em;
`;

export const Mtradingmobilebody = styled.div`
  height: 10em;
  align-items: center;
  width: 230%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Olddatatradingcontent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 499px;
  height: 300px;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 #e0e0e0;
  background-color: white;
  z-index: 1;
`;

export const Olddatafullviewtrading = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -32.5em;
`;

export const Olddatatradingapprovee = styled.div`
  width: 145px;
  height: 29px;
  margin: 20px 3px 31px 3px;
  color: white;
  font-size: 12px;
  border-radius: 8px;
  border: 0;
  background-color: #060;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
