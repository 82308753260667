/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 5/19/2023
 */

import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../actions/scorecardActions";
import isMobile from "../../../../helpers/isMobile";
import i_download from "../../../../images/icons/i_download.svg";
import i_download_white from "../../../../images/icons/i_download_white.svg";
import { Exclaimation, ExclaimationText, Oldtext, TooltipValue } from "../../../Scorecard/ScoreCard.styled";
import { ExcelSpin } from "../../../commonStyles/common.styled";
import {
  defaultSortOrder,
  getFieldFromKey,
  renderSortIcons,
  sortData
} from "../../../scorecardNewDesign/utils";
import {
  Actual,
  ActualText,
  DataContainer,
  MainContainer,
  OldDataBlur,
  ScoreLegendContainer
} from "../../Customer/style/Customer.styled";
import { ScorePopupContainerWeb } from "../../components/ScorePopContainerWeb";
import ScoreLineGraph from "../../components/ScorecardLineGraph";
import { titleGenerator } from "../../scorecardConstants";
import info_scorecard from '../Assets/info_scorecard.svg';
import {
  Colorgradient,
  ExportIcon,
  Exportandispace,
  Exportcontainer,
  HeaderCell,
  Headertradingtable,
  Infoicon,
  Lossdatatotalcell,
  Olddatafullviewtrading,
  Olddatatradingapprovee,
  Olddatatradingcontent,
  ScoreGraphContainer,
  TilteAndRank,
  Titlearea,
  TotalContainerRow,
  TradingBox,
  TradingDataCell,
  TradingDatatotalcell,
  TradingProfitablyTable,
  WasteCell,
  Wastedatacell
} from "../Styles/TradingUi.styled";
import useWindowDimensions, { responsiveGraphWidth } from '../utils/WindowDimensions';

function TradingUiComponent(props) {
  const {
    state,
    title,
    toolTipText,
    displayGraph,
    headers,
    data,
    graph,
    rank,
    total,
    lastWeekUpdated,
    
  } = props.data;
  
  const { t } = useTranslation();
  let headerNames = data?.length > 0 ? defaultSortOrder(data) : [];
  const [sortedData, setSortedData] = useState(data);
  const [sortState, setSortState] = useState(headerNames);
  const [showPopup, setShowPopup] = useState("");
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [blur, setBlur] = useState(title === 'Waste' || title === 'Till Losses' ? props.weekNumber === 1 ? 52 !== lastWeekUpdated : props.weekNumber - 1 !== lastWeekUpdated : props.weekNumber !== lastWeekUpdated);
  const [graphData, setGraphData] = useState([]);
  const [decPos, setDecPos] = useState(1);
  const [blurRect, setBlurRect] = useState(0);
  const [oldPopUpVisible, setOldPopUpVisible] = useState(false);
  const [site, setSite] = useState("");
  const [rect, setRect] = useState(0);

  const { width } = useWindowDimensions();

  const getGraphData = (scoreSales) => {
    let sales = [];
    scoreSales?.forEach((sale) => {
      if (sale?.plannedSales) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "PlannedSales", value: sale.plannedSales, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else if (props.siteNames.length === 0 && !isMobile()) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else if (isMobile() && props.noSelected === 0) {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
          { type: "ActualSalesUK", value: sale.avgCountry, ...sale },
        ];
      } else {
        sales = [
          ...sales,
          { type: "ActualSales", value: sale.avgRegionalManager, ...sale },
        ];
      }
    });
    return sales;
  };


  const colorDecider = (keyName, sortState) => {
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    if (isAnyColumnSorted === 'NONE') {
      return ""
    }
    else if (isAnyColumnSorted === 'ASC') {
      return "#007833"
    }
    else {
      return "#8B0000"
    }
  };

  const exportToExcel = () => {
    props.exportToExcel(titleGenerator[title], headers, sortedData);
  };

  useMemo(() => {
    if (props.dataFor === 'Year') {
      let v = graphData.filter(item => item.weekNumber === 'Dec')
      if (!isEmpty(v)) {
        setDecPos(v[0].monthNumber)
      }
    }
  }, [graphData])

  useEffect(() => {
    setGraphData([]);
    setSortedData([]);
    setSortState([])
    async function correctGraph() {
      let graphData = await getGraphData(graph)
      setGraphData(graphData);
      setSortedData(data);
      let sotdata = defaultSortOrder(data)
      setSortState(sotdata)
    }
    correctGraph();

  }, [props, width, blur])

  const renderValueLoss = (dataValue) => {
    if (dataValue !== 'NA') {
      const isNegative = Math.sign(dataValue);
      if (isNegative === -1) {
        return `-£${dataValue.toString().split("-")[1]}`;
      }
       return `£${dataValue.toString()}`;
    }
   }

  const headersMap = {
    "Waste": {
      "Waste": "waste",
      "Var to plan": "wasteVarToPlan",
      "% to sales": "wastePercSales",
      "5 Wk Waste": "mtdWaste",
      "5 Wk Var vs Plan": "mtdWasteVarToPlan",
      "5 Wk Waste % to sales": "mtdWastePercSales",
    },
    "Till Losses": {
      "Value loss": "valueLoss",
      "% to total": "lossPercentTotal",
      "5 Wk Value loss": "mtdValueLoss",
      "5 Wk Loss % to Sales": "mtdLossPercentTotal",
    }
  }

  const headersType = {
    "currency": ["Wk Sales", "5 Wk Sales", "Waste", "Value loss", "5 Wk Waste", "5 Wk Value loss"],
    "percentage": ["Wk Var to Plan", "Wk Var LFL", "5 Wk Var vs Plan", "5 Wk Var LFL", "Var to plan", "% to sales",
      "% to total", "5 Wk Var vs plan", "5 Wk Waste % to sales", "5 Wk Loss % to Sales"],
    "leterVolume": ["Wk Vol", "5 Wk Vol"],
    "sites": ["Sites"],

  }

  const renderTotal = (title, headerKey, index) => {

    let fieldName;
    const lastWeekData = graph?.length && graph[graph?.length - 1];
    if (title in headersMap && headerKey in headersMap[title]) {
      fieldName = headersMap[title][headerKey];
    }
    else {
      switch (headerKey) {
        case "Wk Sales":
          fieldName = "totalRegionalManager";
          break;
        case "5 Wk Sales":
          fieldName = "mtdTotalRegionalManager";
          break;
        case "Wk Var to Plan":
          fieldName = "avgRegionalManager";
          break;
        case "5 Wk Var vs Plan":
          fieldName = "mtdAverageRegionalManager";
          break;
        case "Wk Var LFL":
          fieldName = "varLFL";
          break;
        case "Wk Vol":
          fieldName = "totalRegionalManager";
          break;
        case "5 Wk Vol":
          fieldName = "mtdTotalRegionalManager";
          break;
        case "5 Wk Var LFL":
          fieldName = "MTDShopVarLFL";
          break;
        case "Waste":
          fieldName = "totalRegionalManager";
          break;
        case "% to sales":
          fieldName = "percSales";
          break;
        case "Value loss":
          fieldName = "totalRegionalManager";
          break;
        case "% to total":
          fieldName = "avgRegionalManager";
          break;
        case "Var to plan":
          fieldName = "avgRegionalManager";
        default:
          break;
      }
    }

    if (headersType["sites"].includes(headerKey)) {
      return title !=='Till Losses'? <TradingDatatotalcell index={index}>{"Total"}</TradingDatatotalcell>:<Lossdatatotalcell index = {index}>{"Total"}</Lossdatatotalcell>;
    }
    else if (headersType["currency"].includes(headerKey)) {
      return title !=='Till Losses'?(
        <TradingDatatotalcell index={index}>
          {`£${lastWeekData[fieldName]?.toLocaleString("en-GB")}`}
        </TradingDatatotalcell>
      ):(
        <Lossdatatotalcell index={index}>
        {`£${lastWeekData[fieldName]?.toLocaleString("en-GB")}`}
      </Lossdatatotalcell>
      );
    }
    else if (headersType["percentage"].includes(headerKey)) {
      return title !=='Till Losses'?(
        <TradingDatatotalcell index={index}>
          {`${lastWeekData[fieldName]?.toLocaleString("en-GB")}%`}
        </TradingDatatotalcell>
      ):(
        <Lossdatatotalcell index={index}>
        {`${lastWeekData[fieldName]?.toLocaleString("en-GB")}%`}
      </Lossdatatotalcell>
      );
    }
    else if (headersType["leterVolume"].includes(headerKey)){
      return (
        <TradingDatatotalcell index={index}>
          {`${lastWeekData[fieldName]?.toLocaleString("en-GB")}L`}
        </TradingDatatotalcell>
      );
    }
    else {
      return <></>;
    }
  };

  const renderTotalRow = (headers, title) => {
    return headers.map((headerKey, headerIndex) => renderTotal(title, headerKey, headerIndex))
  }

  const handleHover = (event, name, type) => {
    const currRect = event.target.getBoundingClientRect();
    setTop(currRect.top);
    setLeft(currRect.left);
    if (type === "leave") {
      setShowPopup("");
    } else {
      setShowPopup(name);
    }
  };

  const sortItem = (keyName, sortState, sortedData) => {
    let result;
    const isAnyColumnSorted = sortState?.find(
      (val) => val.name === keyName
    )?.sortState;
    const rowsWithNA = data.filter((rows) => rows[keyName] === "NA");
    if (isAnyColumnSorted === "NONE") {
      const initialSortState = Object.keys(data[0]).map((item) => ({
        name: item,
        sortState: "NONE",
      }));
      result = sortData(
        keyName,
        initialSortState,
        data.filter((rows) => rows[keyName] !== "NA")
      );
    } else {
      result = sortData(
        keyName,
        sortState,
        sortedData.filter((rows) => rows[keyName] !== "NA")
      );
    }
    const currentState = result.sortedState.find((val) => val.name === keyName);
    setSortState(result.sortedState);
    setSortedData(
      currentState.sortState === "ASC"
        ? [...result.sortingData, ...rowsWithNA]
        : [...rowsWithNA, ...result.sortingData]
    );
  };

  const renderData = (headerKey, itemData, headerIndex, title) => {
    let fieldName = getFieldFromKey(headerKey);
    const { left, top } = rect;
    if (title === "Till Losses") {
      if (fieldName === 'varToTotal' || fieldName === 'MTD_Sales' || fieldName === 'mtdLossPercentToSales') {
        return (
          <Wastedatacell index={headerIndex}>{`${itemData[fieldName]}%`}</Wastedatacell>
        )
      }
      
      if (fieldName === 'wk_Loss_Perc_to_Sales' || fieldName === 'mtdValueLoss'|| fieldName === 'valueLoss') {
        return (
          <Wastedatacell index={headerIndex}>{itemData[fieldName] !== 'NA' ? `${renderValueLoss(itemData[fieldName])}` : `${itemData[fieldName]}`}</Wastedatacell>
        )
      }
      return (
        // <Wastedatacell index={headerIndex}>{itemData[fieldName] !== 'NA' ?`${itemData[fieldName]}`:`${itemData[fieldName]}`}</Wastedatacell>
        <Wastedatacell index={headerIndex}
          onMouseOver={(e) => handleSiteHover(e, itemData[fieldName], "enter")}
          onMouseOut={(e) => handleSiteHover(e, "", "leave")}>{`${itemData[fieldName]}`}
          {site === itemData[fieldName] && (
            <TooltipValue id="tooltipvalue" top={top} left={left}>
              {itemData[fieldName]}
            </TooltipValue>
          )}
        </Wastedatacell>
      );
    }
    if (headersType["currency"].includes(headerKey)) {
      return (
        <TradingDataCell index={headerIndex}>{itemData[fieldName] !== 'NA' ? `£${itemData[fieldName]?.toLocaleString("en-GB")}` : `${itemData[fieldName]}`}</TradingDataCell>
      );
    }
    if (headersType["leterVolume"].includes(headerKey)) {
      return (
        <TradingDataCell index={headerIndex}>{itemData[fieldName] !== 'NA' ? `${itemData[fieldName]?.toLocaleString("en-GB")}L` : `${itemData[fieldName]}`}</TradingDataCell>
      );
    }
    if (headersType["percentage"].includes(headerKey)) {
      return (
        <TradingDataCell index={headerIndex}>{itemData[fieldName] !== 'NA' ? `${itemData[fieldName]}%` : `${itemData[fieldName]}`}</TradingDataCell>
      );
    }
    return (
      <TradingDataCell index={headerIndex}
        onMouseOver={(e) => handleSiteHover(e, itemData[fieldName], "enter")}
        onMouseOut={(e) => handleSiteHover(e, "", "leave")}>{`${itemData[fieldName]}`}
        {site === itemData[fieldName] && (
          <TooltipValue id="tooltipvalue" top={top} left={left}>
            {itemData[fieldName]}
          </TooltipValue>
        )}
      </TradingDataCell>

    );
  };

  const handleSiteHover = (event, value, type) => {

    if (type === "leave") {
      setSite(value);
    }
   
    setSite(value);
    setRect(event.target.getBoundingClientRect());
   
  };

  const blurHover = (event, type) => {
    if (type === "enter") {
      setBlurRect(event.target.getBoundingClientRect());
      setOldPopUpVisible(true)
     } else {
      setOldPopUpVisible(false)
      setBlurRect(0);
    }
  };

  const renderSortIcon = (colName) => {
    return true;
  };
  return (
    <>

      <TradingBox index={props.index}>
        <OldDataBlur boolTrue={blur} id="OldDataBlur">
          {title === 'Waste' || title === 'Till Losses' ? <>
            {
              props.weekNumber - 1 === 0 ?
                <>
                  {!blur && 52 !== lastWeekUpdated && (
                    <Exclaimation
                      onMouseEnter={(e) => blurHover(e, "enter")}
                      onMouseLeave={(e) => blurHover(e, "leave")}
                      id="exclamationContainer"
                    >
                      <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                    </Exclaimation>
                  )}
                </>
                :
                <>
                  {!blur && props.weekNumber - 1 !== lastWeekUpdated && (
                    <Exclaimation
                      onMouseEnter={(e) => blurHover(e, "enter")}
                      onMouseLeave={(e) => blurHover(e, "leave")}
                      id="exclamationContainer"
                    >
                      <ExclaimationText id="ExclaimationText">!</ExclaimationText>
                    </Exclaimation>
                  )}
                </>
            }
          </> : <>
            {!blur && props.weekNumber !== lastWeekUpdated && (
              <Exclaimation
                onMouseEnter={(e) => blurHover(e, "enter")}
                onMouseLeave={(e) => blurHover(e, "leave")}
                id="exclamationContainer"
              >
                <ExclaimationText id="ExclaimationText">!</ExclaimationText>
              </Exclaimation>
            )}
          </>}
          {oldPopUpVisible && (
            <TooltipValue
              id="tooltipvalue"
              top={blurRect.top + 70}
              left={blurRect.left + 15}
            >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
          )}
          <Titlearea state={state}>
            <TilteAndRank>
              {title} | {rank}/{total}
            </TilteAndRank>
            <Exportandispace>
              <Exportcontainer backgroundcolor={state} onClick={() => exportToExcel()}>
                Excel
                  {props.excelLoader &&
                    props.kpiName === title.split(" ")[0] ? (
                      <ExcelSpin
                        color={state === "NEUTRAL" ? "#007833" : "white"}
                      />
                    ) : (
                      <ExportIcon
                id="IconImageExcel"
                src={state === "NEUTRAL" ? i_download : i_download_white}
                alt="scorecard_excel"
                 />
                    )}
              </Exportcontainer>

              <div
                id="right-card-view-div"
                className="right-card-view"
                onMouseEnter={(e) => handleHover(e, title, "enter")}
                onMouseLeave={(e) => handleHover(e, "", "leave")}
                onClick={(e) => {
                  showPopup === title
                    ? handleHover(e, "", "leave")
                    : handleHover(e, title, "enter");
                }}
              >
                <Infoicon src={info_scorecard} alt='info_icon' />
              </div>
            </Exportandispace>
          </Titlearea>
          {showPopup === title && (
            <ScorePopupContainerWeb
              top={top}
              left={left - 20}
              width={18}
              id='popUpDesktop'
              textDescription={toolTipText}
            />
          )}
          <ScoreGraphContainer id="ScoreGraphContainer">
            <ScoreLegendContainer id="ScoreLegendContainer" usedIn='trading'>
            {!isMobile() && props.siteNames.length === 0 && (
              <>

                <Actual
                  id="actual-text"
                  isDanger={state==="DANGER"}
                  isActualMe={true}
                ></Actual>
                <ActualText id="actual-text">
                  Actual UK
                </ActualText>{" "}
              </>
            )}

            {!isMobile() && (
              <>
                <Actual state={state}></Actual>
                <ActualText
                  id="actual-text"
                  isTill
                  Losses={title === "Till Losses"}
                >
                  {props.siteNames.length === 0
                    ? props.selectedManagerName
                    : props.siteNames.length === 1
                      ? props.siteNames[0]
                      : t("Selected Sites")}
                </ActualText>
              </>
            )}

            {isMobile() && props.siteNames.length === 0 && (
              <>
                <Actual state={state}></Actual>
                <ActualText id="actual-text" isLosses={title === "Till Losses"}>
                  Actual UK
                </ActualText>
              </>
            )}

            {isMobile() && (
              <>
                <Actual
                  id="actual-text"
                  isDanger={state === "DANGER"}
                  isActualMe={true}
                ></Actual>
                <ActualText
                  id="actual-text"
                  isTill
                  Losses={title === "Till Losses"}
                >
                  {props.noSelected === 0
                    ? props.selectedManagerName
                    : props.siteNames.length === 1
                      ? props.siteNames[0]
                      : t("Selected Sites")}
                </ActualText>
              </>
            )}
             
            </ScoreLegendContainer>
            {!isEmpty(graphData)?
              <ScoreLineGraph
                regionalManager={props.selectedManagerName}
                isDanger={state === "DANGER"}
                scoreGraph={true}
                xScale={responsiveGraphWidth(width)}
                graphId={`scoreCard-${title.replace(/[^a-zA-Z]/g, "")}`}
                graphClass={`scoreGraph-${title.replace(/[^a-zA-Z]/g, "")}`}
                sales={graphData}
                title={title}
                state={state}
                data={props.dataFor}
                selection={props.noSelected}
                siteLength={props.siteNames.length}
                device={isMobile()}
                id="Financial_graph"
                scroll={props.event}
                decPos={decPos}
                boolValue = {blur}
              /> : null}
          </ScoreGraphContainer>
          <TradingProfitablyTable> 
          <Headertradingtable>
            {headers.map((item, index) => {
              if (title === "Till Losses") {
                return (
                  <WasteCell 
                   onClick={() =>
                    sortItem(getFieldFromKey(item), sortState, sortedData)
                  }
                  usedFor="header"
                  index={index}
                >
                {<Colorgradient index={index}>{item}</Colorgradient>}
                  {renderSortIcon(item) &&
                    renderSortIcons(getFieldFromKey(item), sortState, true, state)}
                  </WasteCell>
                );
              }
              return (
                <>
                  <HeaderCell
                    onClick={() =>
                      sortItem(getFieldFromKey(item), sortState, sortedData)
                    }
                    usedFor="header"
                    index={index}
                  >
                  {<Colorgradient index={index}>{item}</Colorgradient>}
                    {renderSortIcon(item) &&
                      renderSortIcons(getFieldFromKey(item), sortState, true, state)}
                  </HeaderCell>
                </>
              );
            })}
          </Headertradingtable>
          <MainContainer height={150}>
            {!isEmpty(sortedData) ? sortedData.map((itemData, index) => {
              if (itemData.sites !== "Total") {
                return (
                  <DataContainer
                    key={index}
                    alignIndex={index}
                    id="dataContainer"
                  >
                    {headers.map((headerKey, headerIndex) =>
                      renderData(headerKey, itemData, headerIndex, title)
                    )}
                  </DataContainer>
                );
              }
            }) : null}
          </MainContainer>
          <TotalContainerRow id="totalContainer">
            {
              renderTotalRow(headers, title)
            }
          </TotalContainerRow>
          </TradingProfitablyTable>
        </OldDataBlur >
        {blur && (
          <Olddatafullviewtrading id="OldDataFullview">
            <Olddatatradingcontent id="olddatacontent">
              <Oldtext id="old">You're viewing old data</Oldtext>
              <Oldtext id="data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id="oldtext">
                Scheduled updates occurs on&nbsp;{props.expectedRefreshTimestamp}
              </Oldtext>
              <Olddatatradingapprovee id="olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </Olddatatradingapprovee>
            </Olddatatradingcontent>
          </Olddatafullviewtrading>
        )}
      </TradingBox>

    </>
  );
}

const mapStateToProps = (state) => {
  const { scorecardInfo, loader, authInfo } = state;
  const { userData } = authInfo;
  const { showLoader } = loader;
  const { excelLoader, kpiName, siteNames, noSelected, selectedManagerName } = scorecardInfo;
  return {
    excelLoader,
    kpiName,
    showLoader,
    siteNames,
    noSelected,
    selectedManagerName,
    userData,
  };
};

const mapDispatchToProps = {
  exportToExcel: (kpiName, headers, sortedData) =>
    scorecardActions.getScorecardDataExport(kpiName, headers, sortedData),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(TradingUiComponent)
);
