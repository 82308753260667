/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 10/12/2021
* Author : Sunil Vora
*/

import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { isEmpty } from 'lodash';
import { nest } from 'd3-collection';
import { useTranslation } from 'react-i18next';
import {
    select,
    scaleLinear,
    axisLeft,
    axisBottom,
    line,
    scalePoint
  } from 'd3';
import { lineToColor } from '../map/lineGraphData';
import { graphActions } from "../../actions/graphActions";
import '../../css/linegraph.css';

function LineGraph(props) {
  const {t} = useTranslation();
  useEffect(() => {
      if (isEmpty(props.sitesData)) {
      }      
      render();    
  }, [props, props.lineGraphArray]);
  
  const render = (data) => {
    const sitesRatings = props.lineGraphArray;
    if (Array.isArray(sitesRatings) && sitesRatings.length > 0) {
      const svg = select(`svg[class='lineGraph']`);
      const graphContainer = select('#container');
      const validRatings = sitesRatings.filter(item => item.value && item.value !== null);
      const height = +svg.attr('height');
    
      const xValue = d => { 
        return d.columnHeader;
      };
      const xAxisLabel = 'Months';
      
      const yValue = d => d.value;
      const yAxisLabel = t('Ratings');
      
      const colorValue = d => d.type;
      
      const margin = { top: 20, right: 160, bottom: 40, left: 80 };
      const windowWidth = window.innerWidth;
      const innerWidth = windowWidth - margin.left - margin.right;
      const innerHeight = height - margin.top - margin.bottom;
      let xMaxRange;
      switch(true) {
        case windowWidth < 1200:
          xMaxRange = 400;
          break;
        case windowWidth > 1200 && windowWidth < 1400:
          xMaxRange = 200;
          break;
        case windowWidth > 1700 && windowWidth < 1950:
          xMaxRange = (30*innerWidth)/100;
          break;
        case windowWidth > 1950:
          xMaxRange = (40*innerWidth)/100;
          break;
        default:
          xMaxRange = (20*innerWidth)/100;
      }

      const xScale = scalePoint()
        .range([50, innerWidth - xMaxRange])
        .domain(validRatings.map(item => item.columnHeader));
      
      const yScale = scaleLinear()
        .domain([0,5])
        .range([300, 0]);   
      
      const graphPosition = svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);
      
      const xAxis = axisBottom(xScale)
        .tickPadding(10);
      
      const yAxis = axisLeft(yScale)
        .tickSize(-(innerWidth - xMaxRange + 200))
        .tickPadding(2)
        .tickValues([0,1, 2, 3, 4, 5]);
      
      const yAxisG = graphPosition.append('g').call(yAxis).attr('class', 'scale-y');
      
      yAxisG.append('text')
          .attr('class', 'axis-label')
          .attr('y', -60)
          .attr('x', -innerHeight / 2)
          .attr('fill', 'black')
          .attr('transform', `rotate(-90)`)
          .attr('text-anchor', 'middle')
          .text(yAxisLabel);
          
      const xAxisG = graphPosition.append('g').call(xAxis)
        .attr('transform', `translate(0,${innerHeight})`)
        .attr('class', 'scale-x');
      
      xAxisG.append('text')
          .attr('class', 'axis-label')
          .attr('y', 160)
          .attr('x', innerWidth / 2)
          .attr('fill', 'black')
          .text(xAxisLabel);
      
      const lineGenerator = line()
        .x(d => xScale(xValue(d)))
        .y(d => yScale(yValue(d)));
      
      const nested = nest()
        .key(colorValue)
        .entries(validRatings);

      const rectangle = graphContainer
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip1");

      const mouseover = (d) => {
        rectangle
          .style("opacity", 1)
          .style("stroke", "black")
        select(this)
          .style("stroke", "black")
          .style("opacity", 1);
      }
      const mousemove = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        rectangle
          .html(`${event.currentTarget.__data__.name}: ${event.currentTarget.__data__.value}`)
          .style("left", `${rect.left +10}px`)
          .style("top", `${rect.top - 60}px`)
      }
      const mouseleave = (d) => {
        rectangle
          .style("opacity", 0)
        select(this)
          .style("stroke", "none")
          .style("opacity", 0.8)
      }
      
      svg.selectAll('.line-path').data(nested).enter()
          .append('path')
          .attr('class', 'line-path')
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .attr('d', d => lineGenerator(d.values))
          .attr('stroke', d => lineToColor(d.key));        

      
      graphPosition.selectAll(null).data(nested)
      .enter().append('g').selectAll('circle')
      .data(d => d.values)
        .enter().append("circle")
        .attr("class", "data-circle")
        .attr('fill', d =>  lineToColor(d.type))
        .attr("r", 5)
        .attr("cx", function(d) { return xScale(d.columnHeader); })
        .attr("cy", function(d) { return yScale(d.value); })
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave);
    }
  };
  
  return (
    <div id='container'>
      <svg className='lineGraph' width='100%' height='360' ></svg>
    </div>
  );
}

function mapStateToProps(state) {
  const { graphInfo } = state;
  const { sitesData } = graphInfo;
  return { sitesData };
}

const mapDispatchToProps = {
  getSiteLineData: graphActions.getSiteData
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(LineGraph);

