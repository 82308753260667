import { getAnalytics, setUserId } from "firebase/analytics";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { financialActions } from "../../actions/financialActions";
import { kpiActions } from "../../actions/kpiActions";
import { menuActions } from "../../actions/menuActions";
import { siteActions } from "../../actions/siteRakingActions";
import {
  EVENT_LINK_VISIT,
  EVENT_USER_LOGIN,
  KEY_CHANNEL_OF_TRADE,
  KEY_LINK_HEADER,
  KEY_LINK_NAME,
  KEY_LINK_URL,
  KEY_REGIONAL_MANAGER_ID,
  KEY_REGIONAL_MANAGER_NAME,
  KEY_USER_ID,
  KEY_USER_TITLE,
  UNKNOWN_TITLE,
} from "../../constants/FireBaseEvents";
import { LANGUAGE_URLS } from "../../constants/environmentConstants";
import isMobile from "../../helpers/isMobile";
import { isTablet } from "../../helpers/isTablet";
import { store } from "../../helpers/store";
import headerImage from "../../images/bp_image.png";
import i_icon_remove from "../../images/icons/i_icon_remove.svg";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import LinkSection from "../LinkSection/LinkSection";
import ScoreCard from "../Scorecard/Scorecard";
import { eventLogging } from "../Scorecard/utils";
import ScoreCardImproved from "../ScorecardImproved/ScorecardImproved";
import {
  CrossButton,
  LinkName,
  ListItem,
  Lists,
  MenuLinksContainer,
  MenuWrapper,
  PageBodyBlur,
  Pagecontent,
  ScorecardDisplay,
  SideContainer,
  TitleBox,
  TitleHeader
} from "./Page.styled";
import PageCardsDisplay from "./PageCardsDisplay";

function PageBody(props) {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);
  const [kpiList, setKpiList] = useState([]);
  const selectedMenuSection = props?.menuData?.menu?.find(
    (section) => section.header === props.currentMenuItem
  )?.links;
  const languageCode = store.getState().authInfo?.languageCode;
  const userLanguage = LANGUAGE_URLS?.find(
    (value) =>
      value.env === process.env.REACT_APP_ENVIRONMENT &&
      value.code.toLowerCase() === languageCode
  )?.country_code;

  useEffect(() => {
    setKpiList(props.kpiData);
    if (
      isEmpty(props.kpiData) &&
      (languageCode !== "uk" || props.channelOfTrade === "DO")
    ) {
      props.getKpiData();
    } else if (languageCode === "uk" && props.channelOfTrade === "CO") {
      // props.getFinancialData();
      props.getMenuData();
    } else if (props.kpiData.length > 0 && isMobile()) {
      var operationObject = props.kpiData.filter(
        (key) => key.graphName === "operations"
      );
      if (operationObject.length > 0) {
        let findIndex = props.kpiData.indexOf(operationObject[0]);
        let kpiResponse = props.kpiData.slice();
        kpiResponse.splice(findIndex, 1);
        let kpiIndex = operationObject.concat(kpiResponse);
        setKpiList(kpiIndex);
      }
    }
    logEvent();
  }, [props.kpiData]);

  const logEvent = () => {
    const analytics = getAnalytics();
    // here we are setting looged in userid in firebase analytic
    setUserId(analytics, props?.userData?.id);
    // log custom event of logged in user with detail
    let loggedUserDetail = {
      [KEY_USER_ID]: props?.userData?.id,
      [KEY_REGIONAL_MANAGER_NAME]: props?.userData?.name,
      [KEY_REGIONAL_MANAGER_ID]: props?.userData?.regionalManagerId,
      [KEY_CHANNEL_OF_TRADE]: props?.userData?.channelOfTrade,
      [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase()
    };
    // logged event
    eventLogging(EVENT_USER_LOGIN, loggedUserDetail);
  };

  const gotoDashboard = (url) => {
    if (url === "/") {
      return;
    } else {
      window.open(url);
    }
  };

  function deviceSelector() {
    if (isMobile()) {
      return "mobile";
    } else {
      return "tablet";
    }
  }
  const logEventForLinkVisit = (linkcatured) => {
    // logged event
    eventLogging(EVENT_LINK_VISIT, linkcatured);
  };

  return !isMobile() ? (
    <>
      <PageBodyBlur boolTrue={props.currentMenuItem !== ""}>
        {languageCode === "uk" && props.channelOfTrade === "CO" ? (
          <>
            <ScorecardDisplay displaydevice={isTablet()}>
              {props.location.pathname === "/old_scorecard" ? (
                <ScoreCard />
              ) : props.location.pathname === "/improved_scorecard" ? (
                <ScoreCardImproved />
              ) : props.location.pathname === "/admin/dashboard" ? (
                <AdminDashboard />
              ) : (
                <ScoreCardImproved />
              )}
            </ScorecardDisplay>
          </>
        ) : (
          <></>
        )}
        {languageCode !== "uk" || props.channelOfTrade === "DO" ? (
          <>
            <TitleBox className="task-create-header heightAlter">
              <div className="vertical-center mt-3">
                <div className="container row headerImageReset">
                  <div className="col-md-10  header-display">
                    <h3 className="bpHeaderWeight">
                      {t("regional_manager_cockpit")}
                    </h3>
                  </div>
                  <div className="col-md-2">
                    <img
                      src={headerImage}
                      width="371px"
                      height="95px"
                      className="headerImage"
                      alt="BP header"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </TitleBox>
          </>
        ) : (
          <></>
        )}
        <Pagecontent id="desktop">
          <PageCardsDisplay
            data={props.kpiData}
            financialData={props.financialData}
            popup={popup}
            setPopup={(value) => setPopup(value)}
            channelOfTrade={props.channelOfTrade}
          />
        </Pagecontent>
        {(props.additionalLinks.length !== 0 ||
          props.additionalToolLinks.length !== 0) && (
            <LinkSection
              additionalLink={props.additionalLinks}
              additionalToolLinks={props.additionalToolLinks}
            />
          )}
      </PageBodyBlur>
      <MenuWrapper>
        <MenuLinksContainer isOpen={props.currentMenuItem !== ""}>
          <Lists>
            {selectedMenuSection?.map((link) => {
              return (
                <ListItem
                  onClick={() => {
                    gotoDashboard(link?.url);
                    // log custom event of logged in user with detail
                    let linkcatured = {
                      [KEY_USER_ID]: props?.userData?.id,
                      [KEY_LINK_NAME]: link?.name,
                      [KEY_LINK_URL]: link?.url,
                      [KEY_LINK_HEADER]: props?.currentMenuItem,
                      [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
                    };
                    logEventForLinkVisit(linkcatured);
                  }}
                >
                  <LinkName>{link?.name}</LinkName>
                </ListItem>
              );
            })}
          </Lists>
        </MenuLinksContainer>
        <SideContainer
          isOpen={props.currentMenuItem !== ""}
          onClick={() => props.setActiveMenu("")}
        >
          <CrossButton src={i_icon_remove} />
        </SideContainer>
      </MenuWrapper>
    </>
  ) : (
    <>
      {languageCode === "uk" && props.channelOfTrade === "CO" ? (
        <>
          {props.location.pathname === "/improved_scorecard" ? (
            <TitleHeader id="mobile">
              <h3 className="bpHeaderMobile">
                {t("regional_manager_cockpit_mobile")}
              </h3>
            </TitleHeader>):  <></> }
        </>
      ) : (
        <>
          <TitleBox className="task-create-header" id="mobile">
            <div className="col-sm-12  header-display">
              <h3 className="bpHeaderWeight">
                {t("regional_manager_cockpit")}
              </h3>
            </div>
          </TitleBox>
        </>
      )}
      {languageCode === "uk" && props.channelOfTrade === "CO" ? (
        <>
          {props.location.pathname === "/improved_scorecard" ? <ScoreCard /> : <ScoreCardImproved />}
        </>
      ) : null}
      <Pagecontent id="mobile">
        <PageCardsDisplay
          data={kpiList}
          financialData={props.financialData}
          popup={popup}
          setPopup={(value) => setPopup(value)}
          channelOfTrade={props.channelOfTrade}
        />
      </Pagecontent>
      {(props.additionalLinks.length !== 0 ||
        props.additionalToolLinks.length !== 0) && (
          <LinkSection
            additionalLink={props.additionalLinks}
            additionalToolLinks={props.additionalToolLinks}
          />
        )}
    </>
  );
}

function mapStateToProps(state) {
  const { kpiInfo, sitesInfo, financialInfo, authInfo, menuInfo } = state;
  const { kpiData, additionalLinks, additionalToolLinks } = kpiInfo;
  const { sites } = sitesInfo;
  const { financialData } = financialInfo;
  const { languageCode, userData } = authInfo;
  const { currentMenuItem, menuData } = menuInfo;
  const { channelOfTrade, showScoreCard } = userData;
  return {
    kpiData,
    sites,
    showScoreCard,
    additionalToolLinks,
    additionalLinks,
    financialData,
    languageCode,
    channelOfTrade,
    currentMenuItem,
    menuData,
    userData,
  };
}

const mapDispatchToProps = {
  getKpiData: kpiActions.getKpiData,
  getSiteData: siteActions.getSiteData,
  getFinancialData: financialActions.getFinancialData,
  getMenuData: menuActions.getMenuData,
  setActiveMenu: menuActions.saveCurrentMenu,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PageBody);
