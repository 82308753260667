/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 25/11/2021
* Author : Sunil Vora
*/
import { constants } from '../constants/reducerConstants';
import { kpiServices } from '../services/kpiServices';
import { startLoader, stopLoader, showErrorPage, getLogoutUrl } from '../helpers/commons';

export const kpiActions = {
    getKpiData
}

function getKpiData() {
    return (dispatch, getState)  => {
        const { authInfo } = getState();
        dispatch(startLoader());
        return kpiServices.getKpiData(authInfo.userData.id, authInfo.sessionData.id_token, authInfo.languageCode).then( data => {
           dispatch({type: constants.SAVE_KPI_INFO, data});
           dispatch(stopLoader());
       },
       error => {
           dispatch({type: constants.CLEAR_KPI_INFO});
           dispatch(stopLoader());
           if (error && error.message === 'Failed to fetch') {
            dispatch({type: constants.CLEAR_AUTH_STORE});
            window.location.href = getLogoutUrl(authInfo.languageCode);
           }
           dispatch(showErrorPage(error));
       })   
   }
}