import styled from 'styled-components';

export const BlankButton = styled.button`
  vertical-align: middle;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  color: inherit;
  text-align: inherit;
`;

export const ReviewContainer = styled.div`
  flex: 1;
  margin: 10px;
`;

export const HeaderView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b6b6b6;
  padding: ${(props) => props.isMobile ? '0 2px 8px 10px' : '0 0 10px 0'};
  margin-left: ${(props) => props.id === 'link' ? '9px' : '0px'};
  margin-right: ${(props) => props.id === 'link' ? '10px' : '0px'};
  height: 40px;
`;

export const RatingText = styled.span`
  font-size: 12px;
  font-weight: 300;
  font-family: Roboto-Light;
`;

export const RatingContainer = styled.div`
  border-radius: 5px;
  border: solid 1px #ebebeb;
  padding: 5px 8px 5px 8px;
  z-index: 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content:center;
  display:flex;
`;

export const EmptyText = styled.span`
  font-family: ${(props) => props.id === 'link' ? 'Roboto-Light' : 'Roboto-Regular'};
  font-size: ${(props) => props.id === 'link' ? '16px' : '22px'};
`;

export const SearchContainer = styled.div`
  border-radius: 10px;
  border: solid 1px #ebebeb;
  width: 353px;
  height: 30px;
  display: flex;
  padding-left: 5px;
  justify-content: space-between;
`;

export const InputText = styled.input`
  border: 0px;
  padding-left: ${(props) => props.isReviewsSection ? '0px' : '21px'};
  font-family: Roboto-Light;
  font-size: 14px;
  flex: 0.8;
  padding-right: 20px;
`;

export const TableHeader = styled.div`
  display: flex;
  flex:1;
  position: sticky;
  border-bottom: 1px solid #b6b6b6;
  margin: 0px 10px 0px 10px;
`;

export const HeaderTitle = styled.span`
 font-family: Roboto-Regular;
 font-size: 12px;
 color: #787389;
 padding:3px;
`;

export const HeaderTitleView = styled.div`
  flex: ${(props) => props.discription ? 0.8 : props.siteName ? 0.11 : 0.1};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.discription || props.siteName ? 'flex-start' : 'center'};
  margin-left: ${(props) => props.discription ? '12px' : '0px'};
`;

export const TableBody = styled.div`
  flex:1;
  display: flex;
  margin-left:10px;
  background-color:${(props) => props.odd ? '#ebebeb' : 'transparent'};
  align-items: center;
  align-items: stretch;
`;

export const TextKey = styled.span`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #787389;
  flex: ${(props) => props.discription ? 0.8 : props.siteName ? 0.10 : 0.1};
  padding: 7px 0px 7px 0px;
  border-right: ${(props) => props.discription ? '0px' : '1px solid #b6b6b6'}; 
  padding-left: 1px;
  text-align: ${(props) => props.discription || props.siteName ? 'left' : 'center'};
  margin-left: ${(props) => props.discription ? '12px' : '0px'};
  padding-left: ${(props) => props.siteName ? '8px' : '0px'};
`;

export const TableBodyContainer = styled.div`
  height:250px;
`
export const HeaderViewMobile = styled.div`
 display:flex;
 align-items:center;
`

export const EmptyList = styled.div`
  height: 250px;
  display:flex;
  margin-top:-10px;
  align-items:center;
  justify-content:center;
  margin-left: ${(props) => props.id = 'link' ? '' : ''};
`;

export const TriangleDesignDown = styled.div`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #999;
  font-size: 0;
  line-height: 0;
  float: left;
  margin:7px 3px 0px 3px;
`;

export const TriangleDesignUp = styled.div`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  font-size: 0;
  line-height: 0;
  float: left;
  margin: 0px 3px 5px 3px;
`;

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StarText = styled.span`
  margin: 4px 0px 4px 13px;
  font-family: Roboto-Regular;
  font-size: 12px;
  font-weight: 300;
  color: #000;
`;

export const Checkbox = styled.div`
  height:16px;
  width:16px;
  border-radius: 2px;
  margin-right:4px;
  border: solid 1px #009900;
`;

export const StarContainer = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding-right:6px;
`;

export const ZindexValue = styled.div`
  background-color:  #353535;
  height:100%;
  width:100%;
  left:0px;
  z-index:3;
  position:absolute;
`;

export const ReviewFilter = styled.div`
 margin-left:10px;
`;

export const CrossContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${(props) => props.isReviewsSection ? '0px' : '20px'};
  padding-right: 10px;
  width: 62px;
  height: 32px;
`;

export const SearchMobileContainer = styled.div`
  width: ${(props) => props.isReviewsSection ? '363px' : '353px'};
  height: ${(props) => props.isReviewsSection ? '49px': '30px'};
  display: flex;
  padding-left: 5px;
  justify-content: space-between;
  ${(props) => props.isReviewsSection && `
    padding: 0px 24.5px 19px 20px;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex:1;
  flex-wrap: wrap;
  margin-top: 29px;
`;


export const SelectionContainer = styled.div`
  display: flex;
  flex:1;
  flex-direction: row;
  padding-bottom: 10px;
`;

export const HeadingContainer = styled.div`
  border-bottom: 1px solid #cccccc;
  display:flex;
  flex:1
`;

export const HeadingKey = styled.div`
  font-family: Roboto-Light;
  margin-right: 3px;
  font-size: 14px;
`;

export const HeadingValue = styled.div`
  color: #666666;
  font-family: Roboto-Light;
  font-size: 14px;
`;

export const ReviewsHeading = styled.div`
  display: flex;
  align-items: center;
  flex:1;
  justify-content:space-between;
  padding: 0px 20px 19px 20px;
`;

export const Image = styled.img`
  width: 18px;
  height: 17px;
`;

export const ImageContainer = styled.div`
`;

export const ReviewsContainer = styled.div`
  margin-top: 10px;
  height: 41em;
  overflow-y: scroll;
`;

export const Reviews = styled.div`
  margin-bottom: 10px;
  padding: 0px 19px 19px 19px;
  &:nth-child(2n) {
        background-color: #f5f5f5;
    };
`;

export const SiteName = styled.div`
  font-family: Roboto-Light;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #111111;
`;

export const RatingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`;

export const ResponseHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const Date = styled.div`
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 2;
  letter-spacing: normal;
  padding-top: 3px;
`;

export const CustomerReview = styled.div`
  font-family: Roboto-Light;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const ResponseContainer = styled.div`
  margin-top: 10px;
  padding-left: 9px;
  border-left: 1px solid #009903;
`;

export const ResponseHeading = styled.div`
  font-family: Roboto-Light;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: normal;
  margin-right: 15px;
`;

export const ResponseDate = styled.div`
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 2;
  letter-spacing: normal;
  padding-top: 2px;
`;

export const Response = styled.div`
  font-family: Roboto-Light;
  font-size: 14px;
  line-height: normal;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

export const FilterHeading = styled.div`
  font-family: Roboto-Regular;
  padding-top: 8px;
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.03px;
`;

export const FilterContainer = styled.div`
  padding: 0px 20px 0px 20px;
  width: 100%;
  border-bottom: 1px solid #ededed;
`;
