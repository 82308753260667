import styled from "styled-components";

export const Mainsection = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const BlurredView = styled.div`
-webkit-filter: blur(5px);
-moz-filter: blur(5px);
-o-filter: blur(5px);
-ms-filter: blur(5px);
filter: blur(5px);
pointer-events: none;
`;

