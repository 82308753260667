import { constants } from '../constants/reducerConstants';


const averageRatings = {
    tableData: [],
    ratingTableLoader: false,
    error: '',
    sortState: {
        key: null,
        order: 'DESC'
    }
}

export function tableInfo(state = averageRatings, action) {
    switch (action.type) {
        case constants.GOOGLE_RATING_START_LOADER:
            return {
                ...state,
                ratingTableLoader: true
            }
        case constants.SAVE_AVERAGE_RATINGS_DATA:
            return {
                ...state,
                ratingTableLoader: false,
                tableData: action.data
            }
        case constants.CLEAR_AVERAGE_RATINGS_DATA:
            return {
                ...state,
                ratingTableLoader: false,
                error: ""
            }
        case constants.SET_SORT_STATE:
            return {
                ...state,
                sortState: {
                    key: action.data.key,
                    order: action.data.order
                }
            }
        default:
            return { ...state }
    }
}