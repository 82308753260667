/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 05/09/2022
 * Author : Aditya Padmanabhan
 */




import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import {  SideLine } from '../E-Maintenance/EMaintenance.styled';
import  { TargetBar,  } from '../chart/Bars';
import {  BarRating,  ColorCode,  DataContainer, Description, HeaderContainer,  LoyaltyContainer, MainContainer,  NocircularData,  PreviousNumber, RangeHeading, SiteHeading, SiteNames,  SiteRatings,  TargetValue, TooltipValue } from './ScoreCard.styled'
import isMobile from '../../helpers/isMobile';

function Rangeloyalty(props) {
    const {header,data, max_percentage} = props;
    const {max_issuance, max_width} =max_percentage;
    const [site, setSite] = useState('');
    const [rect, setRect] = useState(0);

    const handleSiteHover = (event, value, type) => {
        if (type === 'leave') {
            setSite(value);
        }
        if (event.target.scrollHeight > event.target.clientHeight) {
            setSite(value);
            setRect(event.target.getBoundingClientRect());
        }
    }

    const renderData = (headerKey, itemData, headerIndex) => {
        const { left, top } = rect;
        const { issuance, targetIssuance, previousIssuance} = itemData
        let fieldName
        if(headerKey === 'Sites'){
         fieldName = headerKey.toLowerCase();
        }
        
        return (
            <SiteRatings alignText={headerIndex} id="siteRatings">
                <SiteNames id={itemData[fieldName]+"_"+headerIndex} onMouseOver={(e) => handleSiteHover(e, itemData[fieldName], 'enter')} onMouseOut={(e) => handleSiteHover(e, '', 'leave')}>
                    {itemData[fieldName]}
                    {site === itemData[fieldName] && <TooltipValue id="toolTipValue" top={top} left={left}>{itemData[fieldName]}</TooltipValue>}
                </SiteNames>
                <BarRating id={itemData[fieldName]+"_"+headerIndex}>
                <TargetValue id={itemData[fieldName]+"_"+headerIndex} sidepercent={(targetIssuance/max_width)*100}>{`Target:${targetIssuance}%`}</TargetValue>
                <TargetBar id={itemData[fieldName]+"_"+headerIndex} completed={(issuance/max_width)*100}  sidepercent={(targetIssuance/max_width)*100} actual={issuance}
                isHigh={(issuance>=targetIssuance)?"yes":"no"} />
                 </BarRating>
                <PreviousNumber id={itemData[fieldName]+"_"+headerIndex+"_previousNumber"}>
                 <ColorCode id={itemData[fieldName]+"_"+headerIndex+"_colorCode"}><Description id="description">{`(${previousIssuance}%)`} </Description>
                 </ColorCode> 
                 </PreviousNumber>
            </SiteRatings>
        );
    }
  return (
   <LoyaltyContainer isMobile={isMobile()} id="loyaltyContainer">
        <Card className='data-card no_border'>
        <HeaderContainer id="customer">
        {header.map((colName, colIdx) => {
                return (<>
                        <SiteHeading alignIndex={colIdx} id="siteHeading">
                                {colName} 
                        </SiteHeading>
                        <RangeHeading id="rangeHeading">
                            <SideLine id="sideLine"></SideLine>
                            <div style={{paddingRight:"0.8em", color:"#111111",fontFamily:"Roboto-Light", fontSize:"11px"}}>0%</div>
                            <div style={{ fontSize:"12px"}}>Loyalty Issuance</div>
                            <div style={{marginLeft:"1.38em",color:"#111111",fontFamily:"Roboto-Light", fontSize:"11px"}}>{`${max_issuance}%`}</div>
                            <SideLine id="sideLine"></SideLine>
                        </RangeHeading>
                        </>
                        );
            })}
            </HeaderContainer>
            <MainContainer height={278} id = "mainContainer">
            {data.length>0? data.map((itemData, index) => {
                    return (
                        <DataContainer id="dataContainer">
                            {header.map((headerKey, headerIndex) => (
                              renderData(headerKey, itemData)
                            ))}
                        </DataContainer>
                    )
                }):<NocircularData>no data available</NocircularData>}
            </MainContainer>
        </Card>
    </LoyaltyContainer>
   )
}

export default Rangeloyalty