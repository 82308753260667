/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 6/1/2022
 * Author : Aditya Padmanabhan
 */

import React from "react";
import {
  Textarea,
  Imagebox,
  Image,
  Rowarea,
  MainTextView,
  NotificationText,
  NotificationSubText,
  Eyebox,
  GreenText,
  BlackText,
} from "./Notification.styled";
import i_eye_black from "../../images/icons/i_eye_black.svg";
import i_eye_grey from "../../images/icons/i_eye_grey.svg";
import { NotificationActions } from "../../actions/NotificationAction";
import { compose } from "redux";
import { connect } from "react-redux";

export function Rowdetails(props) {
  const gotoDashboard = (url,id) => {
    if (url === "/") {
      return;
    } else {
      if(id==='new'){
        let unseen = [...props.unseen];
        let seen = [...props.seen];
        let json = {};
        if (props.unseen[props.index].seen) {
          unseen[props.index].seen = false;
        }
        json = {
          new: unseen,
          viewed: seen,
        };
        props.savenotificationInfo(json);
      }
      window.open(url);
    }
  };
  return (
    <Rowarea id={props.status} data-test="notification-row-container">
      <Imagebox>
        <Image src={props.icon} alt="trolly" />
      </Imagebox>
      <Textarea>
        
        <MainTextView data-test="notification-link-container" onClick={() => gotoDashboard(props.data.url,props.id)}>
          <NotificationText>
            <GreenText id = {props.status}>
              {props.data.fileName}&nbsp;<BlackText>{props.String}</BlackText>
            </GreenText>
          </NotificationText>
          <NotificationSubText>
            {props.data.notificationTimestamp}
          </NotificationSubText>
        </MainTextView>
        
      </Textarea>
      {
        <Eyebox onClick={() => props.changeStatus(props.index)}>
          {props.status ? (
            <Image src={i_eye_black} alt="trolly" />
          ) : (
            <Image src={i_eye_grey} alt="trolly" />
          )}
        </Eyebox>
      }
    </Rowarea>
  );
}

const actionCreators = {
  savenotificationInfo: NotificationActions.savenotificationInfo,
};

function mapStateToProps(state) {
  const { NotificationInfo } = state;
  const {unseen,seen} = NotificationInfo;
  return {unseen,seen};
}

export default compose(connect(mapStateToProps, actionCreators))(Rowdetails);
