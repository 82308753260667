/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 25/11/2021
* Author : Sunil Vora
*/

import React from 'react';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import Table from '../Table/Table';
import { EmptyTableStyled,EmptyText } from './Datatable.styled';
import { SORT, SELECTION } from '../Table/Table.constants';

class DataTable extends React.Component {
    componentDidMount() {
        if (!this.props.isFetching) {
            this.getData();
        }
    }


    getData() {
        this.props.getData({
            // filter params
            currentPage: this.props.currentPage,
            resultsPerPage: this.props.resultsPerPage,
            sortState: this.props.sortState,
            searchQuery: this.props.searchQuery,
            ...this.props.additionalQueryParams
        });
    }

    getColCount = memoize((colCount, hasMultiSelect) => (hasMultiSelect ? colCount + 1 : colCount));

    renderAutoloadRows() {
        if (!this.props.animationLoading && this.props.isFetching && this.props.children.length > 0) {
            return this.renderChildren();
        }
        return null;
    }

    renderLoadingRows() {
        if (!this.props.isFetching) return null;
        return (
            <tr>
                <td colSpan={this.colCount}>
                    <EmptyTableStyled key={0}>
                    </EmptyTableStyled>
                </td>
            </tr>
        );
    }

    renderEmpty() {
        return (
            <EmptyTableStyled key={0}>
                <EmptyText>{this.props.noResultsEl}</EmptyText>
            </EmptyTableStyled>
        );
    }

    renderChildren() {
        if (isFunction(this.props.children)) {
            return this.props.children();
        }

        return this.props.children;
    }

    render() {
        const tableProps = {
            caption: this.props.caption,
            data: this.props.data,
            disableSort: this.props.disableSort,
            headings: this.props.headings,
            isSelectAllVisible: this.props.isSelectAllVisible,
            fetching: this.props.isFetching,
            onRenderChildren: this.props.onRenderChildren,
            onSort: this.props.onSort,
            rowSpacing: this.props.rowSpacing,
            selectionOptions: this.props.selectionOptions,
            selectionState: this.props.selectionState,
            sortState: this.props.sortState,
            isLayoutFixed: this.props.isLayoutFixed,
            tableId: this.props.tableId
        };
        this.colCount = this.getColCount(this.props.headings.length, false);
        const customStyle = this.props.customstyle ? this.props.customstyle : false;
        if (this.props.headings.length > 2) {
            return (
                <React.Fragment>
                    <Table {...tableProps} customStyle={customStyle}>
                        {this.renderAutoloadRows() ||
                            this.renderChildren()
                        }
                    </Table>
                </React.Fragment>
            );
        }
        else {
            return (
                this.renderEmpty()

            )
        }
    }
}

const headingObjectShape = PropTypes.shape({
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    sortable: PropTypes.bool.isRequired,
    width: PropTypes.string,
    component: PropTypes.element
});

DataTable.propTypes = {
    additionalQueryParams: PropTypes.object,
    animationLoading: PropTypes.bool,
    caption: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    content: PropTypes.object,
    currentPage: PropTypes.number,
    customstyle: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
    didInvalidate: PropTypes.bool.isRequired,
    getData: PropTypes.func.isRequired,
    hideFuelIcon: PropTypes.bool,
    footerVisibility: PropTypes.bool,
    tableActionsButtonsVisibility: PropTypes.bool,
    hasError: PropTypes.bool,
    headings: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([headingObjectShape, PropTypes.element])),
        PropTypes.node
    ]),
    isSelectAllVisible: PropTypes.bool,
    isFetching: PropTypes.bool,
    isLayoutFixed: PropTypes.bool,
    moreLabel: PropTypes.string,
    noResultsEl: PropTypes.node,
    onRenderChildren: PropTypes.func,
    onRenderEmpty: PropTypes.func,
    onSort: PropTypes.func,
    resultsPerPage: PropTypes.number,
    rowSpacing: PropTypes.string,
    searchQuery: PropTypes.string,
    selectionOptions: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            text: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
            action: PropTypes.func.isRequired
        })
    ),
    selectionState: PropTypes.oneOf([SELECTION.ALL, SELECTION.SOME, SELECTION.NONE]),
    setPage: PropTypes.func.isRequired,
    sortState: PropTypes.shape({
        key: PropTypes.string,
        order: PropTypes.oneOf([SORT.ASCENDING, SORT.DESCENDING])
    }),
    tableLegends: PropTypes.func,
    totalPageCount: PropTypes.number.isRequired,
    tableActionsButtons: PropTypes.array,
    dropdownActions: PropTypes.array,
    disableSort: PropTypes.bool
};

export default DataTable;
