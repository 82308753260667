/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 07/06/2022
* Author : Sunil Vora
*/

import React, { useEffect } from "react";
import * as d3 from "d3";
import { nest } from 'd3-collection';
import { useTranslation } from 'react-i18next';
import {
    axisLeft,
    axisBottom,
    line
  } from 'd3';
import { financialLineColor } from '../map/lineGraphData';
import { SvgContainer } from './FinancialWidget.styled';
import './FinancialGraph.css';
import isMobile from "../../helpers/isMobile";

const FinancialLineGraph = React.memo(props => {
  const {t} = useTranslation();
  useEffect(() => {    
      render();    
  }, []);
  
  const render = () => {
    const dailySales = props.sales;
    let actualSales, actualSalesUk, plannedSales;
    if (Array.isArray(dailySales) && dailySales.length > 0) {
      const xField = props.scoreGraph ? 'weekNumber' : 'dateIndex';
      const svg = d3.select(`svg[class=${props.graphClass}]`);
      const graphContainer = d3.select(`#${props.graphId}`);
      if (!props.scoreGraph) {
        actualSales = dailySales.filter(date => date.type === 'ActualSales').map((dateVal, index) => {
          if (dateVal.value === null) {
            return {
              ...dateVal,
              dateIndex: index,
              value: 0
            }
          }
          return {
            ...dateVal,
            dateIndex: index
          }
        });
  
        plannedSales = dailySales.filter(date => date.type === 'PlannedSales').map((dateVal, index) => {
          if (dateVal.value === null) {
            return {
              ...dateVal,
              dateIndex: index,
              value: 0
            }
          }
          return {
            ...dateVal,
            dateIndex: index,
          }
        });
  
        actualSalesUk = dailySales.filter(date => date.type === 'ActualSalesUK').map((dateVal, index) => {
          if (dateVal.value === null) {
            return {
              ...dateVal,
              dateIndex: index,
              value: 0
            }
          }
          return {
            ...dateVal,
            dateIndex: index,
          }
        });
      }
      

      const allSales = props.scoreGraph ? [...dailySales] : [...actualSales, ...plannedSales, ...actualSalesUk];
      
      const height = +svg.attr('height');
      const xValue = d => d[xField];
      const xAxisLabel = `${t('Week')} ${props.weekNum}`;
    
      const yValue = d => d.value;
      const yAxisLabel = t('Shop sales');
      
      const colorValue = d => d.type;
      
      const margin = { top: 10, right: 50, bottom: 50, left: 37 };
      const windowWidth = window.innerWidth;
      const innerWidth = windowWidth - margin.left - margin.right;
      const innerHeight = height - margin.top - margin.bottom;
      let xMaxRange;
      switch(true) {
        case windowWidth < 1200:
          xMaxRange = 400;
          break;
        case windowWidth > 1200 && windowWidth < 1400:
          xMaxRange = 200;
          break;
        case windowWidth > 1700 && windowWidth < 1950:
          xMaxRange = (30*innerWidth)/100;
          break;
        case windowWidth > 1950:
          xMaxRange = (40*innerWidth)/100;
          break;
        default:
          xMaxRange = (20*innerWidth)/100;
      }

      const xScale = d3.scaleLinear()
        .range([20, props.xScale])
        .domain(d3.extent(allSales, xValue));

      const yScale = d3.scaleLinear()
        .domain([d3.min(allSales.map(minVal => minVal.value)), d3.max(allSales.map(minVal => minVal.value))])
        .range([80, 0]);
      
      const graphPosition = svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);
      
      const xAxis = axisBottom(xScale)
        .tickPadding(2)
        .tickValues([...new Set(allSales.map(val => val[xField]))])
        .tickFormat((value) => `W${(value>52)?(value%52):value}`);
      
      const yAxis = axisLeft(yScale)
        .tickSize(-(innerWidth))
        .tickPadding(2)
        .tickValues(d3.min(allSales.map(minVal => minVal.value)) >= 0 ? [d3.min(allSales.map(minVal => minVal.value)), (d3.min(allSales.map(minVal => minVal.value)) + d3.max(allSales.map(minVal => minVal.value))) / 2 , d3.max(allSales.map(minVal => minVal.value))] : 
        [d3.min(allSales.map(minVal => minVal.value)), 0, (d3.min(allSales.map(minVal => minVal.value)) + d3.max(allSales.map(minVal => minVal.value))) / 2 , d3.max(allSales.map(minVal => minVal.value))])
        .tickFormat((value) => {
          if (value === 0 && d3.min(allSales.map(minVal => minVal.value)) !== 0) {
            return '';
          } else if (props.title === 'Losses') {
            return `${(value.toFixed(2))}`;
          }
          return `${(value.toFixed(1))}%`;
        });

      const yAxisG = graphPosition.append('g').call(yAxis).attr('class', 'scale-y');
      
      if (!props.scoreGraph) {
        yAxisG.append('text')
          .attr('class', 'axis-label')
          .attr('y', -23)
          .attr('x', -innerHeight / 2)
          .attr('fill', 'black')
          .attr('transform', `rotate(-90)`)
          .attr('text-anchor', 'middle')
          .text(yAxisLabel);
      }
     
          
      const xAxisG = graphPosition.append('g').call(xAxis)
        .attr('transform', `translate(0,${innerHeight})`)
        .attr('class', 'scale-x');

      if (!props.scoreGraph) {
        xAxisG.append('text')
          .attr('class', 'axis-label')
          .attr('y', 25)
          .attr('x', 125)
          .attr('fill', 'black')
          .text(xAxisLabel);
      }

      const lineElement = Object.values(document.getElementsByTagName('line')).filter(line => line.__data__ === 0.0);
      if(d3.min(allSales.map(minVal => minVal.value)) !== 0){
        lineElement.forEach(line => {
          line.style.strokeDasharray = (3,3);
        });
      }
      
      const lineGenerator = line()
        .x(d => xScale(xValue(d)))
        .y(d => yScale(yValue(d)));
      
      const nested = nest()
        .key(colorValue)
        .entries(allSales);

      const tooltip = !props.scoreGraph ? graphContainer
        .append("div")
        .attr("class", "hoverTooltip") : graphContainer
        .append("div")
        .attr("class", isMobile()? "scoreTooltipMobile":"scoreTooltip")
        .style("display", "none");
  
      const vertical = graphContainer
        .append("div")
        .attr("class",isMobile()?"hoverLineMobile" :"hoverLine");

      const mousemove = (event) => {
        let tooltipLeft;
        let nearestData = [];
        let totalTitle;
        const rect = event.currentTarget.getBoundingClientRect();
        const salesClassName = props.isBottom ? 'salesBottom' : 'salesTop';    
        const mousePosition = d3.pointer(event, graphPosition.node());
        const xCordinate = xScale.invert(mousePosition[0]);
        const decimalPlace = xCordinate - Math.floor(xCordinate);
        const nearestX = Math.round(xCordinate);
        const nearestXScale = xScale(nearestX);
        if (decimalPlace >= 0.95 || decimalPlace <= 0.05) {
          nearestData = allSales.filter(data => props.scoreGraph ? data.weekNumber === nearestX : data.dateIndex === nearestX);
        }
        if (nearestXScale >= 186 && !props.scoreGraph) {
          tooltipLeft = nearestXScale - 40 - (nearestXScale / 3);
        } else if (props.scoreGraph) {
          tooltipLeft = rect.left + nearestXScale + 7;
        } else {
          tooltipLeft = nearestXScale - 40;
        }
       
        if (nearestData.length) {
          vertical.style("left", `${nearestXScale + 37}px`)
          .style("display", "block")
          .style("opacity", 0.3)
          tooltip
          .style("left", `${isMobile()? tooltipLeft-40:(tooltipLeft+40)}px`)
          .style("font-size",isMobile()?"10px":"14px")
          .style("top", `${rect.top + 121}px`);

          switch(props.title) {
            case 'Waste':
              totalTitle = 'Total Waste';
              break;
            case 'Till Losses':
              totalTitle = 'Total Till Losses';
              break;
            case 'Fuel Volume vs Plan':
              totalTitle = 'Total Volume';
              break;
            default:
              totalTitle = 'Total Sales';
          }

          if (props.scoreGraph) {
             if(props.title === 'Till Losses'){
              tooltip.html(`
              <div>Week ${nearestData[0].weekNumber}</div>
              <div class='country'>UK: ${nearestData[0].avgCountry}</div>
              <div>${props.device && props.selection === 0 ? props.regionalManager: !props.device && props.siteLength === 0 ? props.regionalManager : t("Selected Sites")}: ${nearestData[0].avgRegionalManager}</div>
              <div>${totalTitle}: £${nearestData[0].totalRegionalManager.toLocaleString('en-GB')}</div>
            `)
            .style("display", "block");
             } 
             else if (props.title === 'Fuel Volume vs Plan') {
              tooltip.html(`
              <div>Week ${nearestData[0].weekNumber}</div>
              <div class='country'>UK: ${nearestData[0].avgCountry?nearestData[0].avgCountry+"%":"NA"}</div>
              <div>${props.device && props.selection === 0 ? props.regionalManager: !props.device && props.siteLength === 0 ? props.regionalManager : t("Selected Sites")}: ${nearestData[0].avgRegionalManager}%</div>
              <div>${totalTitle}: ${nearestData[0].totalRegionalManager.toLocaleString('en-GB')}L</div>
            `)
            .style("display", "block");
             }
             else{
              tooltip.html(`
              <div>Week ${nearestData[0].weekNumber}</div>
              <div class='country'>UK: ${nearestData[0].avgCountry?nearestData[0].avgCountry+"%":"NA"}</div>
              <div>${props.device && props.selection === 0 ? props.regionalManager: !props.device && props.siteLength === 0 ? props.regionalManager : t("Selected Sites")}: ${nearestData[0].avgRegionalManager?nearestData[0].avgRegionalManager+"%":"NA"}</div>
              <div>${totalTitle}: £${nearestData[0].totalRegionalManager.toLocaleString('en-GB')}</div>
            `)
            .style("display", "block");
             }
          } else {
            tooltip.html(`
            <div>
              <span class="dateVal">${nearestData[0].date.substring(0,5)}</span> 
              <span class=${salesClassName}>${nearestData[0].salesActual}k</span> 
              <span class="dateVal">vs ${nearestData[0].plannedSales}k</span> 
              <span class=${salesClassName}>${nearestData[0].diffInPerc}%</span>
            </div>
          `)
            .style("opacity", 1)
            .style("display", "block");
          }
        }
      }

      const mouseleave = (event) => {
         vertical.style("left", "-2400em");
         tooltip.style("display", "none");
      }
      
      svg.selectAll('.score-graph').data(nested).enter()
          .append('path')
          .attr('class', 'score-graph')
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .attr('d', d => lineGenerator(d.values))
          .attr('stroke', d => financialLineColor(d.key, props.isBottom, props.scoreGraph, props.isDanger));
      
     
        
          graphPosition.selectAll(null).data(nested)
          .enter().append('g').selectAll('circle')
          .data(d => d.values)
          .enter().append("circle")
        .attr("class", "data-circle")
        .attr('fill', d =>   financialLineColor(d.type, props.isBottom, props.scoreGraph, props.isDanger))
        .attr("r",d=> d.value !== null ?4:0)
        .attr("cx", function(d) { return xScale(d[xField])})
        .attr("cy", function(d) { return yScale(d.value)})
        

      svg
      .append('rect')
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr('width', innerWidth)
      .attr('height', innerHeight)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);
     
      window.addEventListener('scroll', mouseleave);
      window.addEventListener("mousewheel", mouseleave);
      window.addEventListener("touchmove", mouseleave);// it's for mobile version
      return () => {
          window.removeEventListener('scroll', mouseleave);
          window.removeEventListener('mousewheel', mouseleave);
          window.removeEventListener("touchmove", mouseleave); // it's for mobile version
          
      };
    }
    
  };
  
  return (
    <SvgContainer id={props.graphId}>
      <svg className={props.graphClass} width='100%' height='140'></svg>
    </SvgContainer>
  );
});

export default FinancialLineGraph;
