import styled from "styled-components";

export const PopupContainer = styled.div`
width: 371px;
position: absolute;
background-color: #232323;
border-radius: 4px;
left: ${(props) => `${(props.left - 260)}px`};
width: ${(props) => `${props.width}em`};
align-items: center;
justify-content: center;
z-index: 3;
`
export const TitleContainer = styled.div`
  border-radius: 4px;
  background-color: #232323;
  display:flex;
  align-items: center;
  padding:10px 0px 10px 10px;
  border-bottom:solid 1px #404040;

`

export const Infoicon = styled.img`
width:  18px;
height: 18px;
object-fit: contain;
`;
export const TitleName = styled.div`
font-family: Roboto;
font-size: 14px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.71;
letter-spacing: normal;
color: #fff;
margin-left:10px;
`;

export const TitleDescription = styled.div`
  padding: 10px 13px 10px 13px;
  font-family: 'ROBOTO-REGULAR';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  //line-height: 1.39;
  letter-spacing: 0.22px;
  color: rgba(255, 255, 255, 0.64);
`;

export const ScorePopupContainer = styled.div`
  position: absolute;
  background-color: #353535;
  border-radius: 9px;
  //right : 10px;
  //top:50px;
  //top: ${(props) => `${props.top}px`};
  left: ${(props) => `${(props.left - 260)}px`};
  width: ${(props) => `${props.width}em`};
  align-items: center;
  padding: 10px;
  justify-content: center;
  z-index: 3;
`;


export const ButtonmobileYMfilter = styled.div`
background-color: ${(props)=> !props.isWhite?"#dadada": "#006600"};
display: flex;
border-radius: 4px;
align-items: center;
cursor: pointer;
width: 140px;
height: 38px;


`