/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 03/06/2022
* Author : Sunil Vora
*/
import { constants } from '../constants/reducerConstants';
import { financialServices } from '../services/financialServices';
import { showErrorPage, getLogoutUrl } from '../helpers/commons';

export const financialActions = {
    getFinancialData
}

function getFinancialData() {
    return (dispatch, getState)  => {
        const { authInfo } = getState();
        return financialServices.getFinancialData(authInfo.userData.id, authInfo.sessionData.id_token, authInfo.languageCode).then( data => {
           dispatch({type: constants.FINANCIAL_DATA_SUCCESS, data});
       },
       error => {
           dispatch({type: constants.FINANCIAL_DATA_FAILURE});
           if (error && error.message === 'Failed to fetch') {
            dispatch({type: constants.FINANCIAL_DATA_FAILURE});
            window.location.href = getLogoutUrl(authInfo.languageCode);
           }
           dispatch(showErrorPage(error));
       })   
   }
}
