import { Row } from "react-bootstrap";
import styled, { css } from "styled-components";

export const CustomNoPadding = css`
  margin-right: 0;
  margin-left: 0;
`;


export const CustomNoPaddingRow = styled.div`
  display: flex;
  align-items: center;
  ${CustomNoPadding}
`;

export const CustomRow = styled(Row)`
  margin-right: 10px; /* Adjust the margin value as needed */
  margin-left: 0;
  // margin-bottom: 20px;
`;


export const BlankButton = styled.button`
  vertical-align: middle;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  color: inherit;
  text-align: inherit;
`;

export const HeaderContainer = styled.nav`
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 100%;
  position: fixed;
  zindex: 1;
  box-shadow: none;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-ditrection: row;
  justify-content: space-between;
  padding-right: 70px;
  padding-left: 70px;
  width: 100%;
  padding-top: 15px;
  z-index: 10;
`;

export const LogoView = styled.a`
  height: 64px;
  margin-right: 7px;
  z-index: 20;
`;

export const ImageLogo = styled.img`
  height: 68px;
  widtht: 48px;
`;

export const ImageBox = styled.div`
  display: inline-flex;
  width: ${(props) => (props.id==='logo' ? '65%' : '35%')};
  flex-direction: row;
  justify-content: flex-end;
`;

export const ImageLogoMobile = styled.img`
  flex: 1.2;
  height: 65px;
  width: 48px;
  margin-left: -16px;
`;

export const HeaderText = styled.h3`
  margin-bottom: inherit;
  font-weight: 300;
  font-family: 'Roboto-Light';
  font-size: 23px;
  width: 40em;
  margin-left:6px;
  margin-top:6px;
  text-align: ${(props) => (props.isGoogleReviewPage ? 'end' : 'start')};
  color: ${(props) => (props.admin ? 'white' : 'black')};
`;

export const HeaderTitleTextWeb = styled.h3`
  margin-bottom: inherit;
  font-weight: 300;
  font-family: 'Roboto-Light';
  font-size: 36px;
  width: 492px;
  // margin-left:6px;
  // margin-top:6px;
  text-align: 'start';
  color: #000;
`;

export const HeaderTitleTextWebNew = styled.h3`
  font-weight: 300;
  font-family: 'Roboto-Light';
  font-size: 36px;
  text-align: 'start';
  align-content: center;
  color: #000;
  padding-top:20px;
`;

export const BodyContainer = styled.div`
  --top-spacing: 120px;
  scroll-padding-top: var(--top-spacing);
  margin-top: var(--top-spacing);
  border-top-style: solid;
  border-top-color: #cccccc;
  border-top-width: thin;
`;

export const TitleHeader = styled.div`
  position: ${(props) => (props.id === 'mobile' ? 'absolute' : "")};
  display: flex;
  flex-direction: column;
  background-color: #008000 !important;
  color: #ffffff;
  padding-left: 0px !important;
  height: ${(props) => (props.id === 'mobile' ? '52px' : '96px')};
  left: ${(props) => (props.id === "mobile" ? '0px' : '')};
  right: ${(props) => (props.id === "mobile" ? '0px' : '')};
  top: ${(props) => (props.id === "mobile" ? '65px' : '')};
  text-align: left;
  justify-content: center;
  @media only screen and (min-width:300px) and (max-width:500px) {
    background-color: transparent !important;
    color: #008000;
  }
`;

export const TitleBox = styled.div`
  position: ${(props) => (props.id === 'mobile' ? 'absolute' : "")};
  display: flex;
  flex-direction: column;
  background-color: #008000;
  color: #ffffff;
  padding-left: ${(props) => (props.id === 'mobile' ? '1em !important' : '0px !important')};;
  height: ${(props) => (props.id === 'mobile' ? '52px' : '96px')};
  width: inherit;
  right: ${(props) => (props.id === "mobile" ? '0px' : '')};
  top: ${(props) => (props.id === "mobile" ? '65px' : '')};
  text-align: left;
  justify-content: center;
`;

export const Logoutcontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.id === "mobile" ? '28px' : '')};
  height: ${(props) => (props.id === "mobile" ? '48px' : '')};
  cursor: pointer;
`;
export const Logoutbox = styled.div`
  height:  1.5em;
  width: 1.5em;
  display: flex;
  justify-content: space-between;
`;

export const FooterListItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.id === 'para' ? '' : "1em")};
  margin-bottom: ${(props) => (props.id === 'para' ? '0.5em' : '0.5em')};
  margin-left: 1.8em;
`;

export const ImageContainer = styled.div`
  margin-left: 9.5em;
  
`;
export const Pagecontent = styled.div`
  margin-top: ${(props) => (props.id === 'mobile' ? '40%' : '4%')};
  margin-left: 0.15em;
  margin-right: 0.15em;
`;

export const LogoutView = styled.div`
  z-index:1;
  position:absolute;
  right:5px;
  top:0px;
  bottom:0px;
`;
export const LogoutboxMobile = styled.img`
  height: 48px;
  width: 28px;
`;

export const BannerAdjustmenst = styled.div`
  width:  inherit;
  height: 4em;
  display: flex;
  flex:1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index:0;
  background-color: white !important;
  border-bottom: 1px solid #eaeaea;
`;

export const Menubox = styled.img`
  width: 28px;
  height: 33px;
  flex: 0.2;
`;

export const Menuoptions =styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #f7f7f7; 
  opacity: 1;
`;

export const DropdownBox = styled.div`
  display: flex;
  flex-direction: column; 
  flex: 1;
  width: inherit;
  height: 80%;
  background-color: #fff;
  align-items: left;
`;
export const Contactsupport = styled.div`
  display: flex;
  flex: 0.5
  width: 100px;
  margin-left: 1.9em;
  height:20px;
`;

export const Menuboxtext = styled.div`
  margin-left: 0.1em;
  margin-top: 1em;
  font-size: 14px;
`;

export const Menudesktop = styled.div`
  display: flex;
  justify-content: ${(props) => (props.id === 'admin' ? 'space-between' : 'space-around')};
  flex: 0.5;
`;
export const Blurbox = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(5px);
  background-color: #00000040;
`;

export const Circleview = styled.text`
  width: 19px;
  height: 19px;
  padding: 1.5px 3px 3px 3px;
  background-color: #f60;
  border-radius: 50%;
`;

export const Circletext = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  color: white;
`;

export const Applychange = styled.div`
  display:flex;
  justify-content:center;
  width: 223px;
  height: 40px;
  margin: 0 0 0 31px;
  font-family: roboto-regular;
  border-radius: 8px;
  background-color: #92ca80;
  align-items:center;
  cursor: pointer;
`;

export const Discardchange = styled.text`
  width: 120px;
  text-decoration: underline;
  color:white;
  margin-top: 0.8em;
  font-family: roboto-regular;
  font-size: 14px;

`
export const ScorecardDisplay = styled.div`
  background-color: ${(props) => props.displaydevice ? "":"white"};
  padding-bottom: ${(props) => props.displaydevice ? "":"3em"};
 
`;

export const PagefooterData = styled.div`
  display: flex;
  width: 100%;
  flex:1;

`;

export const Footerdisclaimer =styled.div`
 display: flex;
 flex:0.4;
 margin-left: 2em;
 justify-content: center;
 flex-direction: column;

`;

export const Footerlist =styled.div`
 display: flex;
 flex:0.6;
 margin-left: 4em;
 margin-right: 4em;
 justify-content: space-between;
 font-size: 14px;
 font-family: roboto-regular;
`;

export const Footertext =styled.text`
  font-family:${(props)=>props.id==='logo'?"roboto-bold":"roboto-regular"};
  font-size: ${(props)=>props.displaydevice==='mobile'?"12px":"14px"};
  margin-top: ${(props)=>props.id==='logo'?"":"0.5em"};
  color: ${(props)=>props.id==='logo'?"#000":"rgba(17, 17, 17, 0.7)"};
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 149px;
  padding: 19px 20px;
  background: #ffffff;
`;

export const ImageMenu = styled.img`
  height: 75px;
  width: 64px;
  margin-top: -12px;
`;
export const ImageMenuNew = styled.img`
  height: 75px;
  width: 64px;
`;

export const AdditionalImages = styled.img`
  height: ${(props) => props.usedFor === "backArrow" ? 48 : 24}px;
  width: ${(props) => props.usedFor === "backArrow" ? 48 : 24}px;
  `;

export const CrossButton = styled.img`
  position: absolute;
  left: 91%;
  height: 30px;
  width: 37px;
  top:2.5%;
`;

export const LinksList = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px
  //margin-left: 40.8px;
  //flex: 0.98;
`;
export const LinksListOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40.8px;
 // margin-top: 17px;
  flex: 0.98;
`;

export const Section = styled.div`
  font-family: Roboto-Regular;
  font-size: 15px;
  color: ${(props) => props.isSelected ? '#007833' : 'rgba(17, 17, 17, 0.64)'};
  align-self: center;
`;

export const LinkWrapper = styled.div`
cursor: pointer;
`;

export const PageBodyBlur = styled.div`
  ${(props) => props.boolTrue ? ` 
  opacity: 0.25;
  backdrop-filter: blur(3px);
  -webkit-filter: blur(3px);
  pointer-events: none;
  `: ``}
`;

export const MenuLinksContainer = styled.div`
 
  height: 0;
  opacity: 0;
  width: 0;
 
  transition: 0.5s ease-in-out;
  
  ${(props) =>
    props.isOpen && `
    height: 100em;
    opacity: 1;
    width: 35%;
    margin-top:30px;
    background-color: #f2f2f2;
    position: absolute;
    top: 18%;
    `}
  @media (min-width: 1920px) {
    top: 8%;
  }
`;

export const SideContainer = styled.div`
  display: ${(props) => props.isOpen ? 'block' : 'none'};
  



  ${(props) =>
    props.isOpen && `

    position: absolute;
    top: 18%;
    width: 65%;
    height: 100em;
    left: 35%;
    margin-top:30px;
    @media (min-width: 1920px) {
      top: 8%;
    }
    `}
  




 
 
`

export const Lists = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  // padding-top: 33px;
`;

export const ListItem = styled.li`
  padding: 12px 31px 12px 42px;
  color: rgba(17, 17, 17, 0.64);
  &:hover {
    color: #007833;
    cursor: pointer;
  }
`;

export const LinkName = styled.div`
  display: flex;
  font-family: Roboto-Regular;
  font-size: 14px;
  flex-direction: row;
  flex: 1;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const AdditionalLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 18px;
  cursor: pointer;
`;
export const AdminBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 18px;
  cursor: pointer;
  font-family: Roboto-Regular;
  font-size: 14px;
  color: rgba(17, 17, 17, 0.64);
`;




export const SearchBoxd = styled.div`
 display: inline-block;
 width: 275px;
 align-items: center;
 height: 20px;
 background transparent;
 border-bottom: 1px solid green;
`;

export const AdminBoxd = styled.div`
 display: inline-block;
 width: 275px;
 align-items: center;
 height: 20px;
 background transparent;
 border-bottom: 1px solid green;
`;

export const SearchContent = styled.div`
width: 100%;
height: 30px;
float: right;
transition: width .75s ease-in-out;
min-width: 30px;

`;

export const Iconbox = styled.img`
 height: ${(props) => props.id === 'cross' ? "50%" : "100%"};
 width: 25px;
 align-items: center;
 margin-top: -5px;
`;

export const SearchFeild = styled.input`
background: none;
width: 80%;
line-height: 20px;
margin-top: -1.3em;
opacity: 1;
border: 0;
`;

export const Resultfield = styled.div`
 visibility: ${(props) => props.visiblity === "" ? "hidden":"visible"};
 position: absolute;
 background-color: white;
 height: 100px;
 overflow-y: scroll;
 overflow-x: hidden;
 width: 280px;
 margin-left: 0em;
 z-index: 2;
 margin-top: -0.2em;
`;

export const DesktopsearchNo = styled.div`
 display: flex;
 height: inherit;
 width: inherit;
 justify-content: center;
 align-items: center;
 font-family: roboto-regular;
 font-size: 12px;
 color: #007833;
`;


export const Perrowsearchresult = styled.div`
 width: inherit;
 height: 50px;
 display: flex;
 align-items: left;
 flex-direction: column;
 cursor: pointer;
 margin-top: 5px;
 margin-left: 1.7em;
`;
export const Linknamesearch = styled.text`
 font-size: ${(props) => props.id ==='text'? "14px":"10px"};
 font-family: roboto-regular;
 color: ${(props) => props.id ==='text'? "green":"black"};;
`

export const SupportBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 18px;
  cursor: pointer;
`;

export const LogoutBox = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const Label = styled.div`
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(17, 17, 17, 0.64);
  align-self: center;
  padding-top: 2px;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;  
`;

export const LinkMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${(props) => props.isHeading ? 'Roboto-Light' : 'Roboto-Regular'};
  font-size: 14px;
  border-bottom: 1px solid #eaeaea;
  height:  ${(props) => props.isBack ? 65 : 47}px;
  padding: 12px 10px 12px 15px;
  color: ${(props) => props.isHeading ? '#95858585' : 'rgba(17, 17, 17, 0.64)'};
  ${(props) =>
    props.isBack && `
    background-color: #f7f7f7;
  `}
`;

export const LinkMobileName = styled.div`
  flex: 1;
`;

export const MenuImageContainer = styled.div``;

export const SearchMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #007F00;
  width: 90%;
  margin-left: 20px;
`;

export const InputMenu = styled.input`
  border: 0px;
  padding-left: 21px;
  font-family: Roboto-Light;
  font-size: 14px;
  flex: 0.8;
  padding-right: 20px;
`;

export const CrossMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
