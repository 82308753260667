import React from "react";
import { IconView, DescriptionText1, DisFlex } from "./EMaintenance.styled";
import { useTranslation } from "react-i18next";
import i_collab from "../../images/icons/i_collab.svg";
export default function Overdue() {
  const { t } = useTranslation();
  return (
    <DisFlex>
      <IconView>
        <img src={i_collab} alt="collab" />
      </IconView>
      &nbsp;
      <DescriptionText1 task="overdue">
        {<b>{t("GREAT")}</b>}, {t("you have no overdue tickets")}
      </DescriptionText1>
    </DisFlex>
  );
}
