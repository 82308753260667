export const ALLINKS = [
    {
        name: "Trading Profitably",
        links: [
            "A link",
            "A long name of a link",
            "Business excel",
            "Link with a name",
            "Realistic Link Name"
        ]
    },
    {
        name: "Safety First Operations",
        links: [
            "A link",
            "A long name of a link",
            "Business excel",
            "Link with a name",
            "Realistic Link Name"
        ]
    },
    {
        name: "People",
        links: [
            "A link",
            "A long name of a link",
            "Business excel",
            "Link with a name",
            "Realistic Link Name"
        ]
    },
    {
        name: "Customer",
        links: [
            "A link",
            "A long name of a link",
            "Business excel",
            "Link with a name",
            "Realistic Link Name"
        ]
    }
]