/* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 30/12/2021
* Author : Nisha Katatia
*/

import React from "react";
import { FilterView, FilterWidget, IndexView, FilterContainer } from './Filter.styled';
import DropDown from './DropDown';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { averageScoreActions } from '../../actions/GoogleAverageScoreAction';
import { selectedobject, fetchListArray, searchTextFilter } from './utils';
import { SELECTEDFILTER } from './Filter.constants';
import { withTranslation } from "react-i18next";
import isMobile from "../../helpers/isMobile";
import MobileFilter from './MobileFilter'
let ALL
export class Filter extends React.Component {
    constructor(props) {
        super(props);
        const { t } = this.props
        this.state = {
            dropDownKey: false,
            selectedCountry: '',
            selectedRegionalManager: '',
            selectedTradeOfChannel: '',
            selectedSiteName: '',
            regionalManagerList: {},
            updatedRegionalManagerList: {},
            channelOfTradesList: [],
            siteList: {},
            updatedSiteList: {},
            spinner: true,
            filterDetails: {},
            siteManagerSearch: '',
            regionalManagerSearch: '',
        }
        ALL = {
            value: t('ALL')
        }
    }


    componentDidMount() {
        if (!isMobile()) {
            this.webFilterAPI()
        }
        else {
            const { userData } = this.props
            let request = '?mode=mobile&regional-manager-id=' + userData.regionalManagerId + '&name=' + userData.name
            this.mobileFilterApi(request, false)
        }
    }

    // Site Data Handled for Mobile Setup
    siteListMethod(selectedSite, selectedUser) {
        const { country } = this.props.filterDetails
        const { sites } = country
        let siteList
        if (selectedUser) {
            siteList = fetchListArray(sites, Object.values(selectedUser)[0].sites)
        } else {
            siteList = sites
        }
        siteList = { ALL, ...siteList }
        if (selectedSite) {
            siteList = { ...selectedSite, ...siteList }
        }
        return siteList
    }

    //Channel of Trade handled in mobile Setup
    channelOfTradeListMethod(selectedChannelOfTrade, selectedUser, countryChannelOfTrades) {
        let channelOfTrade = []
        const { t } = this.props
        if (selectedChannelOfTrade) {
            // selected Channnel of Trade
            channelOfTrade.push(selectedChannelOfTrade)
        }
        if (selectedUser && Object.values(selectedUser)[0].channelOfTrades.length > 0) {
            // Channel Of Trade with selected user
            channelOfTrade.push(t('ALL'))
            channelOfTrade = channelOfTrade.concat(Object.values(selectedUser)[0].channelOfTrades)
        } else if (!selectedUser && countryChannelOfTrades.length > 0) {
            // Channel of Trade without selected user
            channelOfTrade.push(t('ALL'))
            channelOfTrade = channelOfTrade.concat(countryChannelOfTrades)
        }
        return channelOfTrade
    }

    // Handle Regional Manager list for mobile
    regionalMangerList() {
        const { country } = this.props.filterDetails
        const { regionalManagers } = country
        let regionalManagerDetails = {}
        if (country.user) {
            //Selected user for regional manager
            regionalManagerDetails = country.user
        }
        regionalManagerDetails = { ...regionalManagerDetails, ALL }
        regionalManagerDetails = { ...regionalManagerDetails, ...regionalManagers }
        return regionalManagerDetails
    }


    // Web Filter Api Call
    webFilterAPI() {
        const { t } = this.props
        this.props.getFilterData().then(
            () => {
                if (this.props.filterDetails.country) {
                    const { country } = this.props.filterDetails
                    const { regionalManagers, sites } = country
                    let selectedRegionalManager = selectedobject(regionalManagers, country.user)
                    let siteList = fetchListArray(sites, Object.values(selectedRegionalManager)[0].sites)
                    this.setState({
                        spinner: false,
                        selectedCountry: country.name,
                        regionalManagerList: { ALL, ...regionalManagers },
                        channelOfTradesList: [t('ALL')].concat(Object.values(selectedRegionalManager)[0].channelOfTrades),
                        selectedTradeOfChannel: Object.values(selectedRegionalManager)[0].channelOfTrades.length > 1 ? t('ALL') : Object.values(selectedRegionalManager)[0].channelOfTrades[0],
                        selectedRegionalManager: selectedRegionalManager,
                        updatedRegionalManagerList: { ALL, ...regionalManagers },
                        siteList: { ALL, ...siteList },
                        updatedSiteList: { ALL, ...siteList },
                        selectedSiteName: { ALL },
                        regionalManagerSearch: Object.values(selectedRegionalManager)[0]?.name,
                        siteManagerSearch: t('ALL')
                    }, () => {
                        this.filterMethod(false)
                    })
                }
            })
    }

    //Mobile Fields Setups 
    mobileKeySetups(mobileFilterApi) {
        const { t } = this.props
        const { country } = this.props.filterDetails
        if (country) {
            let siteListObject = {}
            let regionalManagerObject = {}
            let channelOfTrade = []
            // Handle Lists of Filter
            regionalManagerObject = this.regionalMangerList()
            siteListObject = this.siteListMethod(country.site, country.user)
            channelOfTrade = this.channelOfTradeListMethod(country.channelOfTrade, country.user, country.channelOfTrades)
            this.setState({
                regionalManagerList: regionalManagerObject,
                updatedRegionalManagerList: regionalManagerObject,
                selectedSiteName: country.site ? country.site : { ALL },
                siteList: siteListObject,
                updatedSiteList: siteListObject,
                selectedRegionalManager: country.user ? country.user : { ALL },
                selectedTradeOfChannel: country.channelOfTrade ? country.channelOfTrade : t('ALL'),
                channelOfTradesList: channelOfTrade
            }, () => {
                if (!mobileFilterApi) {
                    this.filterMethod(false)
                }
            })
        }
    }

    // Filter for Mobile Screen
    mobileFilterApi(request, mobileFilterApi) {
        this.props.getFilterDataMobile(request).then(
            () => {
                this.mobileKeySetups(mobileFilterApi)
            })
    }

    componentDidUpdate() {
        const { selectedRegionalManager, siteList, selectedSiteName } = this.state
        const { t } = this.props
        if (!this.props.closeFilter && this.state.dropDownKey) {
            this.setState({
                dropDownKey: false,
                regionalManagerSearch: Object.values(selectedRegionalManager)[0]?.name,
                updatedRegionalManagerList: this.state.regionalManagerList,
                updatedSiteList: { ALL, ...siteList },
                siteManagerSearch: Object.keys(selectedSiteName)[0] === 'ALL' ? t('ALL') : Object.values(selectedSiteName)[0].siteName
            })
        }
    }

    openDropDownMethod(value) {
        const { t } = this.props
        const { selectedRegionalManager, selectedSiteName } = this.state
        this.props.closeFilterMethod(value === this.state.dropDownKey ? false : value)
        this.setState({
            dropDownKey: value === this.state.dropDownKey ? false : value,
            regionalManagerSearch: Object.keys(selectedRegionalManager)[0] === 'ALL' ? t('ALL') : Object.values(selectedRegionalManager)[0].name,
            siteManagerSearch: Object.keys(selectedSiteName)[0] === 'ALL' ? t('ALL') : Object.values(selectedSiteName)[0].siteName,
        })
    }

    // Filter method check for other Apis
    filterMethod(mobileFilter) {
        const { selectedRegionalManager, selectedTradeOfChannel, selectedSiteName, channelOfTradesList } = this.state
        this.filterChecks(selectedRegionalManager, selectedTradeOfChannel, selectedSiteName, channelOfTradesList, mobileFilter)
    }


    filterChecks(selectedRegionalManager, selectedTradeOfChannel, selectedSiteName, channelOfTradesList, mobileFilter) {
        const { country } = this.props.filterDetails
        let filterQuery = ''
        const { t } = this.props
        let filterObject = { country: country.name }
        let mobileRequest = '?mode=mobile'
        if (Object.keys(selectedRegionalManager)[0] !== 'ALL') {
            let regionalManagerId = Object.values(selectedRegionalManager)[0].regionalManagerId
            let regionalManagerName = Object.values(selectedRegionalManager)[0].name
            filterQuery = '?regional-manager-id=' + regionalManagerId
            mobileRequest = mobileRequest + '&regional-manager-id=' + regionalManagerId + '&name=' + regionalManagerName
            filterObject = { regionalManager: Object.values(selectedRegionalManager)[0].name, regionalManagerId: regionalManagerId, ...filterObject }
        }
        if (selectedTradeOfChannel !== t('ALL') && channelOfTradesList.length > 2) {
            if (Object.keys(selectedRegionalManager)[0] !== 'ALL') {
                filterQuery = filterQuery + '&channel-of-trade=' + selectedTradeOfChannel
            } else {
                filterQuery = '?channel-of-trade=' + selectedTradeOfChannel
            }
            mobileRequest = mobileRequest + '&channel-of-trade=' + selectedTradeOfChannel
            filterObject = { channelOfTrade: selectedTradeOfChannel, ...filterObject }
        }
        if (Object.keys(selectedSiteName)[0] !== 'ALL') {
            let siteId = Object.values(selectedSiteName)[0].sapId
            filterQuery = filterQuery + '&site-id=' + siteId
            mobileRequest = mobileRequest + '&site-id=' + siteId
            filterObject = { siteName: Object.values(selectedSiteName)[0].siteName, ...filterObject }
        }
        if (mobileFilter) {
            this.mobileFilterApi(mobileRequest, mobileFilter)
        }
        this.props.filterChange(filterQuery, filterObject)
    }

    //Mobile Selection Method
    selectionMethodMobile(selectedMethod, key, value) {
        const { country } = this.props.filterDetails
        const { regionalManagers } = country
        const { t } = this.props
        switch (selectedMethod) {
            case SELECTEDFILTER.CHANNELOFTRADES:
                this.setState({
                    selectedTradeOfChannel: key,
                    selectedSiteName: { ALL },
                })
                break;
            case SELECTEDFILTER.REGIONALMANAGER:
                {
                    let checkKey = key === 'ALL' ? false : value
                    let channelOfTrade = this.channelOfTradeListMethod(false, checkKey, country.channelOfTrades)
                    let siteList = this.siteListMethod(false, checkKey)
                    this.setState({
                        selectedRegionalManager: value,
                        channelOfTradesList: channelOfTrade,
                        selectedTradeOfChannel: t('ALL'),
                        selectedSiteName: { ALL },
                        updatedSiteList: siteList,
                        siteList: siteList,
                    })
                }
                break
            case SELECTEDFILTER.SITENAME:
                {
                    let selectedRegionalManager = this.state.selectedRegionalManager
                    if (key !== 'ALL' && Object.keys(this.state.selectedRegionalManager)[0] === 'ALL') {
                        selectedRegionalManager = selectedobject(regionalManagers, Object.values(value)[0].regionalManagerId)
                    }
                    this.setState({
                        selectedTradeOfChannel: Object.values(value)[0].channelOfTrade,
                        selectedSiteName: value,
                        selectedRegionalManager: selectedRegionalManager
                    })
                }
                break
            default:
        }
    }

    // Web Selection Method
    selectedDropDown(selectedMethod, key, value) {
        const { country } = this.props.filterDetails
        const { regionalManagers, channelOfTrades, sites } = country
        const { t } = this.props
        // eslint-disable-next-line default-case
        switch (selectedMethod) {
            case SELECTEDFILTER.CHANNELOFTRADES:
                this.setState({
                    selectedTradeOfChannel: key,
                    selectedSiteName: { ALL },
                    siteManagerSearch: t('ALL')
                })
                break;
            case SELECTEDFILTER.REGIONALMANAGER:
                {
                    let channelOfTradesList = []
                    let selectedTradeOfChannel = ''
                    let siteList = { ALL, ...sites }
                    if (key === 'ALL') {
                        channelOfTradesList = [t('ALL')].concat(channelOfTrades)
                        selectedTradeOfChannel = t('ALL')
                        siteList = { ALL, ...sites }
                    } else {
                        channelOfTradesList = [t('ALL')].concat(Object.values(value)[0].channelOfTrades)
                        selectedTradeOfChannel = Object.values(value)[0].channelOfTrades.length > 1 ? t('ALL') : Object.values(value)[0].channelOfTrades[0]
                        let siteListValues = fetchListArray(sites, Object.values(value)[0].sites)
                        siteList = { ALL, ...siteListValues }
                    }
                    this.setState({
                        channelOfTradesList: channelOfTradesList,
                        updatedRegionalManagerList: this.state.regionalManagerList,
                        selectedTradeOfChannel: selectedTradeOfChannel,
                        selectedRegionalManager: value,
                        siteList: siteList,
                        updatedSiteList: siteList,
                        selectedSiteName: { ALL },
                        regionalManagerSearch: key !== 'ALL' ? Object.values(value)[0]?.name : t('ALL'),
                        siteManagerSearch: t('ALL')
                    })
                }
                break;
            case SELECTEDFILTER.SITENAME:
                let selectedTradeOfChannel = this.state.selectedTradeOfChannel
                let regionalManagerSearch = this.state.regionalManagerSearch
                let selectedRegionalManager = this.state.selectedRegionalManager
                let siteList = this.state.siteList
                let channelOfTradesList = this.state.channelOfTradesList
                if (key !== 'ALL') {
                    selectedRegionalManager = selectedobject(regionalManagers, Object.values(value)[0].regionalManagerId)
                    regionalManagerSearch = Object.values(selectedRegionalManager)[0].name
                    let siteListValues = fetchListArray(sites, Object.values(selectedRegionalManager)[0].sites)
                    siteList = { ALL, ...siteListValues }
                    channelOfTradesList = [t('ALL')].concat(Object.values(selectedRegionalManager)[0].channelOfTrades)
                    selectedTradeOfChannel = Object.values(value)[0].channelOfTrade
                }
                this.setState({
                    channelOfTradesList: channelOfTradesList,
                    siteManagerSearch: key !== 'ALL' ? Object.values(value)[0].siteName : t('ALL'),
                    regionalManagerSearch: regionalManagerSearch,
                    selectedRegionalManager: selectedRegionalManager,
                    selectedTradeOfChannel: selectedTradeOfChannel,
                    siteList: siteList,
                    selectedSiteName: key !== 'ALL' ? value : { ALL },
                    updatedSiteList: siteList
                })
                break;
        }
        this.props.closeFilterMethod(false)
        this.setState({ dropDownKey: false, }, () => {
            const { selectedRegionalManager, selectedTradeOfChannel, selectedSiteName, channelOfTradesList } = this.state
            this.filterChecks(selectedRegionalManager, selectedTradeOfChannel, selectedSiteName, channelOfTradesList)
        })
    }

    async handleChange(e, filterType) {
        const { t, } = this.props;
        if (filterType === t('Site Name:')) {
            this.setState({ siteManagerSearch: e })
            if (e.length > 0) {
                let updatedSiteList = searchTextFilter(e, this.state.siteList)
                this.props.closeFilterMethod(filterType)
                this.setState({ updatedSiteList: { ALL, ...updatedSiteList }, dropDownKey: filterType, })
            } else {
                this.setState({ siteManagerSearch: e, updatedSiteList: this.state.siteList, })
            }

        } else {
            this.setState({ regionalManagerSearch: e })
            if (e.length > 0) {
                let updatedRegionalManagerList = searchTextFilter(e, this.state.regionalManagerList)
                this.props.closeFilterMethod(filterType)
                this.setState({ updatedRegionalManagerList: { ALL, ...updatedRegionalManagerList }, dropDownKey: filterType, })
            } else {
                this.setState({ updatedRegionalManagerList: this.state.regionalManagerList })
            }
        }
    }

    render() {
        const { t } = this.props;
        const { selectedTradeOfChannel } = this.state
        if (this.props.filterDetails.country) {
            const { country } = this.props.filterDetails
            const { sites } = country
            if (!isMobile()) {
                return (

                    <FilterView className="container" data-test="filter-header">
                        <FilterContainer data-test="filter-block">
                            <FilterWidget>{t("Filter All Widgets")}</FilterWidget>
                            <DropDown title={t('Regional Manager:')}
                                data-test="filter-regional-manager"
                                filterData={this.props.filterData}
                                selectionMethod={(key, value) => this.selectedDropDown(SELECTEDFILTER.REGIONALMANAGER, key, value)}
                                selectedValue={Object.keys(this.state.selectedRegionalManager)[0]}
                                placeholderText={this.state.regionalManagerSearch}
                                regionalManagerDetails={this.props.regionalManagerDetails}
                                arrayList={Object.keys(this.state.updatedRegionalManagerList)}
                                dropDownKey={this.state.dropDownKey}
                                handleChange={(e) => this.handleChange(e.target.value, t('Regional Manager:'))}
                                listOfObjects={this.state.updatedRegionalManagerList}
                                openDropDownMethod={(value) => this.openDropDownMethod(value)} />
                            <DropDown title={t('Channel of Trade:')}
                                data-test="filter-channel-trades"
                                filterData={this.props.filterData}
                                selectionMethod={(key, value) => this.selectedDropDown(SELECTEDFILTER.CHANNELOFTRADES, key, value)}
                                selectedValue={selectedTradeOfChannel}
                                placeholderText={selectedTradeOfChannel}
                                dropDownKey={this.state.dropDownKey}
                                arrayList={this.state.channelOfTradesList}
                                listOfObjects={this.state.channelOfTradesList}
                                openDropDownMethod={(value) => this.openDropDownMethod(value)} />
                            <DropDown title={t('Site Name:')}
                                data-test="filter-site-name"
                                filterData={this.props.filterData}
                                selectionMethod={(key, value) => this.selectedDropDown(SELECTEDFILTER.SITENAME, key, value)}
                                selectedValue={Object.keys(this.state.selectedSiteName)[0]}
                                placeholderText={this.state.siteManagerSearch}
                                dropDownKey={this.state.dropDownKey}
                                handleChange={(e) => this.handleChange(e.target.value, t('Site Name:'))}
                                arrayList={Object.keys(this.state.updatedSiteList)}
                                listOfObjects={sites}
                                openDropDownMethod={(value) => this.openDropDownMethod(value)} />
                            {this.state.spinner && <IndexView />}
                        </FilterContainer>
                    </FilterView>
                )
            }
            else {
                if (this.props.filterView) {
                    return (
                        Object.keys(this.state.regionalManagerList).length > 0 && <MobileFilter
                            keyBoardValue={this.props.keyBoardValue}
                            regionalManagerList={this.state.updatedRegionalManagerList}
                            handleChange={(e, title) => this.handleChange(e.target.value, title)}
                            selectedRegionalManager={Object.keys(this.state.selectedRegionalManager)[0]}
                            channelOfTradesList={this.state.channelOfTradesList}
                            selectedTradeOfChannel={selectedTradeOfChannel}
                            siteList={this.state.updatedSiteList}
                            closeFilterMethodMob={() => {
                                this.mobileKeySetups(true)
                                this.props.closeFilterMethodMob()
                            }}
                            siteManagerSearch={this.state.siteManagerSearch}
                            regionalManagerSearch={this.state.regionalManagerSearch}
                            onClearFilter={(title) => this.setState({ siteManagerSearch: '', regionalManagerSearch: '' }, () => this.handleChange('', title))}
                            filterApi={() => this.filterMethod(true)}
                            selectedSiteName={Object.keys(this.state.selectedSiteName)[0]}
                            selectionMethodMobile={(key, value, title) => this.selectionMethodMobile(title, key, value,)}
                        />
                    )
                } else {
                    return null
                }
            }
        } else {
            return null
        }
    }
}


function mapStateToProps(state) {
    const { googleAverageScoreInfo, authInfo } = state;
    const { userData } = authInfo;
    const { filterDetails } = googleAverageScoreInfo;
    return { filterDetails, userData };
}

const mapDispatchToProps = {
    getFilterData: () => averageScoreActions.getFilterData(),
    getFilterDataMobile: (request) => averageScoreActions.getFilterDataMobile(request)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(Filter));
