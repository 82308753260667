import styled from "styled-components";

export const MenuContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eaeaea;
  width: inherit;
  position: fixed;
`;

export const LogoContainer = styled.div`
  padding: 15px 112px 15px 80px;
`;

export const ImageLogoMenu = styled.img`
  height: 80px;
  width: 60px;
`;

export const MenuLinksContainer = styled.div``;

export const Lists = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`;

export const ListItem = styled.li`
  padding: 12px 31px 12px 19px;
  color: rgba(17, 17, 17, 0.64);
  &:hover {
    background-color: #007833;
    color: white;
  }
`;

export const LinkName = styled.div`
  display: flex;
  font-family: Roboto-Regular;
  font-size: 14px;
  flex-direction: row;
  flex: 1;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const RightArrow = styled.img`
  position: relative;
  height: 17px;
`;

export const RightArrowContainer = styled.div`
  align-self: center;
  width: 2em;
  margin-left: 4px;
`;
