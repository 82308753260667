import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../actions/scorecardActions";
import {
  ScoreCardContainer
} from "../scorecardNewDesign/ScoreCard.styled";

import Scorecardfilter from "../scorecardNewDesign/Scorecardfilter";
import Annoucement from "./Annoucement";
import ScorecardContent from "./ScorecardContent";
import { KPINames } from "./ScorecardImprovedConstants";

function ScoreCardImproved({ getScorecardLayoutData, layout }) {
  useEffect(() => {
    getScorecardLayoutData(KPINames.LAYOUT);
  }, []);

  return (
    <>
      <Annoucement announcement={layout?.data?.announcment}/>
      <Scorecardfilter id="scoreCardFilter" />

      <ScoreCardContainer id="scoreCardContainer">

        <ScorecardContent />
      </ScoreCardContainer>
    </>
  );
}

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
  } = state;
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return { layout };
}


const mapDispatchToProps = {
  getScorecardLayoutData: (kpiName) =>
    scorecardActions.getScoreCardContent(kpiName),
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ScoreCardImproved
);
