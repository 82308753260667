/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 11/04/2022
 * Author : Nisha Kataria
 */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderContainer, HeaderText } from './Card.styled'
import Search from '../search/search.js'

export function HeaderCard(props) {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');
    const { titleText } = props
    useEffect(() => {

    }, []);

   function handleChange(e){
        setSearchText(e)
        props.searchMethod(e)
    }
  

    return (
        <HeaderContainer>
            <HeaderText>{titleText}</HeaderText>
            <Search data-test="search-container" value={searchText} placeholder = {props.placeholderText}
                onChange={(e) => {handleChange(e.target.value); setSearchText(e.target.value)}}
                onClick={() => {
                    setSearchText('')
                    handleChange('')
                }}
            />
        </HeaderContainer>
    )
}

export default HeaderCard
