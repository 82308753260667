export const KPINames = {
    LAYOUT: "LAYOUT",
    SHOP_SALES: "SHOP_SALES",
    FUEL_SALES: "FUEL_SALES",
    SERVICES: "SERVICES",
    WBC_SALES: "WBC_SALES",
    WASTE: "WASTE",
    LOSS: "LOSS",
    CUSTOMER_SATISFACTION: "CUSTOMER_SATISFACTION",
    REGIONAL_LOYALTY: "REGIONAL_LOYALTY",
    LOYALTY_RANKING: "LOYALTY_RANKING",
    PEOPLE_OVERVIEW: "PEOPLE_OVERVIEW",
    CLOCKING_ON_TIME: "CLOCKING_ON_TIME",
    UNPUBLISHED_SCHEDULE: "UNPUBLISHED_SCHEDULE",
    SITE_SAFETY_OVERVIEW: "SITE_SAFETY_OVERVIEW",
    DARK_SITES: "DARK_SITES",
    MANDATORY_TASKS: "MANDATORY_TASKS",
};

export const KPIUrls = {
    [KPINames.LAYOUT]: "layout",
    [KPINames.SHOP_SALES]: "trading_profitably/shop_sales/",
    [KPINames.FUEL_SALES]: "trading_profitably/fuel_sales/",
    [KPINames.SERVICES]: "trading_profitably/services/",
    [KPINames.WBC_SALES]: "trading_profitably/wbc_sales/",
    [KPINames.WASTE]: "trading_profitably/waste/",
    [KPINames.LOSS]: "trading_profitably/loss/",
    [KPINames.CUSTOMER_SATISFACTION]: "customer/customer_satisfaction/",
    [KPINames.REGIONAL_LOYALTY]: "customer/regional_loyalty/",
    [KPINames.LOYALTY_RANKING]: "customer/loyalty_ranking/",
    [KPINames.PEOPLE_OVERVIEW]: "people/people_overview/",
    [KPINames.CLOCKING_ON_TIME]: "people/clocking_on_time/",
    [KPINames.UNPUBLISHED_SCHEDULE]: "people/unpublished_schedule/",
    [KPINames.SITE_SAFETY_OVERVIEW]: "site_safety/safety_operations_overview/",
    [KPINames.DARK_SITES]: "site_safety/dark_sites/",
    [KPINames.MANDATORY_TASKS]: "site_safety/mandatory_tasks/",
};
