import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import TradingUiComponent from "../../../../scorecardNewDesign/TradingProfitably/Screens/TradingUiComponent";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const Waste = ({ waste, getWasteData, layout }) => {
  useEffect(() => {
    getWasteData(KPINames.WASTE);
  }, []);

  if (waste.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (waste.data) {
    return (
      <TradingUiComponent
        dataFor={"Month"}
        data={waste.data}
        index={4}
        weekNumber={layout?.data?.weekNumber}
        expectedRefreshTimestamp={layout?.data?.schedules?.wasteData}
      />
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
  } = state;
  const waste = scorecardImprovedInfo[KPINames.WASTE];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return { waste, layout };
}

const mapDispatchToProps = {
  getWasteData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Waste);
