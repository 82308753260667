/* All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Date: 12/08/2022
 * Author : Sunil Vora
 */

import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from 'redux';
import { scorecardActions } from "../../actions/scorecardActions";
import { DOWNLOAD_SECTION_TILE_NAME, EVENT_DOWNLOAD_EXCEL, KEY_USER_ID, KEY_USER_TITLE, UNKNOWN_TITLE } from '../../constants/FireBaseEvents';
import isMobile from "../../helpers/isMobile";
import { isTablet } from "../../helpers/isTablet";
import cross_icon from "../../images/icons/cross_icon.svg";
import i_download from "../../images/icons/i_download.svg";
import i_download_white from "../../images/icons/i_download_white.svg";
import i_icon from "../../images/icons/i_icon.svg";
import i_icon_alert from "../../images/icons/i_icon_alert.svg";
import i_icon_info_bold_white from "../../images/icons/i_icon_info_bold_white.svg";
import { DescriptionText1 } from "../E-Maintenance/EMaintenance.styled";
import { IconImage } from "../GoogleReviewDashboard/ReviewsDashboard.styled";
import { ExcelSpin } from "../commonStyles/common.styled";
import {
  AlertIcon,
  DataContainer,
  Errorbox,
  Exclaimation, ExclaimationText,
  HeaderContainer,
  MainContainer,
  MobilePopup,
  OldDataApprove,
  OldDataBlur, OldDataContent,
  OldDataFullview,
  Oldtext,
  ScoreDownloadIcon,
  ScoreExcelLabel,
  ScoreExportContainer,
  ScoreKpiName,
  ScorePopupContainer,
  ScoreTitle,
  TableColumn,
  TableContainer,
  TableHeader,
  TableValue,
  TableValueTotal,
  TooltipValue,
  TotalContainer
} from "./ScoreCard.styled";
import { createMarkup, defaultSortOrder, eventLogging, getFieldFromKey, renderAlert, renderSortIcons, sortData } from "./utils";

function TableComponent(props) {
    const { state, title, headers, data, lastWeekUpdated, toolTipText, expectedRefreshTimestamp } = props.data;
    const [sortedData, setSortedData] = useState(data?.slice(0, data?.length - 1));
    const [sortState, setSortState] = useState(data?.length > 0 ? defaultSortOrder(data) : []);
    const [site, setSite] = useState('');
    const [rect, setRect] = useState(0);
    const [showPopup, setShowPopup] = useState('');
    const [ blur, setBlur] = useState(props.weekNumber!==lastWeekUpdated)
    const [blurRect, setBlurRect] = useState(0);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);

    useEffect(()=>{
        setSortedData(data?.slice(0, data?.length - 1));
     },[data]);

    const blurHover = (event, type) => {
        if(type==='enter'){
            setBlurRect(event.target.getBoundingClientRect());
        }
        else{
            setBlurRect(0)
        }
    }

    const handleSiteHover = (event, value, type) => {
        if (type === 'leave') {
            setSite(value);
        }
        if (event.target.scrollHeight > event.target.clientHeight) {
            setSite(value);
            setRect(event.target.getBoundingClientRect());
        }
    }

    const renderData = (headerKey, itemData, headerIndex) => {
        let fieldName;
        const { left, top } = rect;
        if(headerKey === 'Sites'){
         fieldName = headerKey.toLowerCase();
        }
        else{
            fieldName = getFieldFromKey(headerKey);
        }
        if (props.width && headerKey === 'Labour vs demand' && renderAlert(itemData[fieldName])) {
            return (
                <>
                    <TableValue style={{ textAlign: 'right' }} isAlert={true} isWidth={props.width} alignText={headerIndex} id= {itemData[fieldName]+"_"+headerIndex}>
                        <AlertIcon src={i_icon_alert} alt="alert" />
                        {Math.sign(parseInt(itemData[fieldName])) === 1 ? `+${itemData[fieldName]}%` : itemData[fieldName] !== 'NA' ? `${itemData[fieldName]}%`: itemData[fieldName]}
                    </TableValue>
                </>
            );
        } else if (headerKey === 'Labour vs demand' && !renderAlert(itemData[fieldName])) {
            return (
                <>
                    <TableValue id= {itemData[fieldName]+"_"+headerIndex} style={{ textAlign: 'right' }} isWidth={props.width} alignText={headerIndex}>
                        {Math.sign(parseInt(itemData[fieldName])) === 1 ? `+${itemData[fieldName]}%` : itemData[fieldName] !== 'NA' ? `${itemData[fieldName]}%`: itemData[fieldName]}
                    </TableValue>
                </>
            );
        }
        return (
            <TableValue  id= {itemData[fieldName]+"_"+headerIndex} onMouseOver={(e) => handleSiteHover(e, itemData[fieldName], 'enter')} onMouseOut={(e) => handleSiteHover(e, '', 'leave')} title={title} alignText={headerIndex}>
                {title === 'People Overview' && (headerIndex !== 0 && itemData[fieldName] !== 'NA')? `${itemData[fieldName]}%` : itemData[fieldName]}
                {site === itemData[fieldName] && <TooltipValue  id = "tooltipvalue" top={top} left={left}>{itemData[fieldName]}</TooltipValue>}
            </TableValue>
        );
    }

    const renderTotal = (headerKey, headerIndex) => {
        const fieldName = getFieldFromKey(headerKey);
        const totalData = data[data?.length - 1];
        
        if(title === 'People Overview'){
            if(headerIndex !== 0){
                return (
                    <TableValueTotal id= {totalData[fieldName]+"_"+headerIndex} isTotal={true} isWidth={props.width} title={title} alignText={headerIndex}>
                        {`${totalData[fieldName]}%`}
                    </TableValueTotal>
                );
            }
            else{
                return (
                    <TableValue id= {totalData[fieldName]+"_"+headerIndex} isTotal={true} isWidth={props.width} title={title} alignText={headerIndex}>
                        {`${totalData[fieldName]}`}
                    </TableValue>
                );
            }
        }

        return (
            <TableValueTotal id= {totalData[fieldName]+"_"+headerIndex} isTotal={true} isWidth={props.width} title={title} alignText={headerIndex}>
                {`${totalData[fieldName]}`}
            </TableValueTotal>
        );
    }

     const sortItem = (keyName, sortState, sortedData) => {
        let result;
        const isAnyColumnSorted = sortState?.find(val => val.name === keyName)?.sortState;
        const rowsWithNA = data.filter(rows => rows[keyName] === 'NA');
        if (isAnyColumnSorted === 'NONE') {
            const initialSortState = Object.keys(data[0]).map(item => ({name: item, sortState: 'NONE'}));            
            result = sortData(keyName, initialSortState, data.filter(rows => rows[keyName] !== 'NA'));
        } else {
            result = sortData(keyName, sortState, sortedData.filter(rows => rows[keyName] !== 'NA'));
        }
        const currentState = result.sortedState.find(val => val.name === keyName);
        setSortState(result.sortedState);
        setSortedData(currentState.sortState === 'ASC' ? [...result.sortingData, ...rowsWithNA] : [...rowsWithNA, ...result.sortingData]);     
    }

    const widthSelector = () => {
        if(isTablet()){
            return 75;
        }
        else{
            return props.width;
        }
    }

    const exportToExcel = () => {
        const kpiName = title.split(' ')[0];
        props.exportToExcel(kpiName, headers, sortedData);
        let downloadEventData = {
          [KEY_USER_ID]: props?.userData?.id,
          [KEY_USER_TITLE]: (props?.userData?.title || UNKNOWN_TITLE).toUpperCase(),
          [DOWNLOAD_SECTION_TILE_NAME]:title
          }
          eventLogging(EVENT_DOWNLOAD_EXCEL, downloadEventData)
    }

    const handleHover = (event, name, type) => {
        const currRect = event.target.getBoundingClientRect();
        setTop(currRect.top);
        setLeft(currRect.left);
        if (type ==='leave') {
            setShowPopup('');
        } else {
            setShowPopup(name);
        }
    }

    return (
      <TableContainer width={widthSelector()} displaydevice={isTablet()} id="tableContainer">
        <Card className="data-card" style={{ height: "375px" }}>
          <OldDataBlur boolTrue={blur} id= "OldDataBlur">
            {!blur && props.weekNumber !== lastWeekUpdated && (
              <Exclaimation  id="exclamationContainer"
                onMouseEnter={(e) => blurHover(e, "enter")}
                onMouseLeave={(e) => blurHover(e, "leave")}
              >
                <ExclaimationText id = "ExclaimationText">!</ExclaimationText>
              </Exclaimation>
            )}
            {blurRect !== 0 && (
              <TooltipValue
                id = "tooltipvalue"
                top={blurRect.top + 70}
                left={blurRect.left + 15}
              >{`OLD DATA W${lastWeekUpdated}`}</TooltipValue>
            )}
            <TableHeader id={"header"+title}>
              <ScoreTitle value="card" id="card">
                <ScoreKpiName id="scoreKpiName"
                  textColor={state === "NEUTRAL" ? "black" : "white"}
                >
                  {title}
                </ScoreKpiName>
                {sortedData?.length > 0 && (
                  <ScoreExportContainer
                    isRegionalManager={true}
                    onClick={() => exportToExcel()}
                    mobile={isMobile()}
                    id = "ScoreExportContainer"
                  >
                    <ScoreExcelLabel isNeutral={state === "NEUTRAL"} id = "ScoreExcelLabel">
                      Excel
                    </ScoreExcelLabel>
                    <ScoreDownloadIcon marginRight={15} id = "ScoreDownloadIcon">
                      {props.excelLoader &&
                      props.kpiName === title.split(" ")[0] ? (
                        <ExcelSpin
                         color={state === "NEUTRAL" ? "#007833" : "white"}
                        />
                      ) : (
                        <IconImage
                         id = "IconImageExcel"
                          src={
                            state === "NEUTRAL" ? i_download : i_download_white
                          }
                          alt="scorecard_excel"
                        />
                      )}
                    </ScoreDownloadIcon>
                  </ScoreExportContainer>
                )}
                {
                  <div
                    className="safety-card-view"
                    id = "right-card-view-div"
                    onMouseOver={(e) => handleHover(e, title, "enter")}
                    onMouseLeave={(e) => handleHover(e, "", "leave")}
                    onClick={(e) => {
                      isTablet() && showPopup === title
                        ? handleHover(e, "", "leave")
                        : handleHover(e, title, "enter");
                    }}
                  >
                    {isTablet() ? (
                      <img
                        className="cross-class"
                        alt="tile_info"
                        src={showPopup === title ? cross_icon : i_icon}
                        
                      />
                    ) : (
                      <img
                        className="cross-class"
                        alt="tile_info"
                        src={
                          state === "NEUTRAL" ? i_icon : i_icon_info_bold_white
                        }
                      />
                    )}
                  </div>
                }
              </ScoreTitle>
            </TableHeader>
            {data?.length > 0 ? (
              <>
                <HeaderContainer id="headerBox">
                  {headers.map((colName, colIdx) => {
                    return (
                      <TableColumn
                        id="tableColumn"
                        isWidth={props.width}
                        title={title}
                        onClick={() =>
                          sortItem(
                            getFieldFromKey(colName),
                            sortState,
                            sortedData
                          )
                        }
                        alignIndex={colIdx}
                      >
                        {colName}
                        {renderSortIcons(getFieldFromKey(colName), sortState)}
                      </TableColumn>
                    );
                  })}
                </HeaderContainer>
                <MainContainer height={280} id = "mainContainer">
                  {sortedData.map((itemData, index) => {
                      return ( itemData.sites !== "Total" &&
                      <DataContainer key={index} id="dataContainer">
                        {headers.map((headerKey, headerIndex) =>
                          renderData(headerKey, itemData, headerIndex)
                        )}
                      </DataContainer>
                    );
                  })}
                </MainContainer>
                <TotalContainer id="totalContainer">
                  {headers.map((headerKey, headerIndex) =>
                    renderTotal(headerKey, headerIndex)
                  )}
                </TotalContainer>
              </>
            ) : (
              <>
                {" "}
                <Errorbox id="errorBox">
                  <DescriptionText1 task="open" id="descriptionText1">
                    <b>
                      An Error Occurred <br></br> Please Try Again Later
                    </b>
                  </DescriptionText1>
                </Errorbox>
              </>
            )}
            {isTablet()
              ? showPopup === title && (
                  <MobilePopup
                    top={75}
                    left={left + 15}
                    width={15}
                    arrowLeft={80}
                    device="mobile"
                    id='popUpMobile'
                  >
                    <span className="What-you-can-expect">
                      <span
                        key={toolTipText}
                        dangerouslySetInnerHTML={createMarkup(toolTipText)}
                      />
                    </span>
                  </MobilePopup>
                )
              : showPopup === title && (
                  <ScorePopupContainer
                    top={40}
                    left={10}
                    width={20}
                   // arrowTop={title === "People Overview" ? -7 : -15}
                    arrowLeft={86}
                    id='popUpDesktop'
                  >
                    <span className="What-you-can-expect">
                      <span
                        key={toolTipText}
                        dangerouslySetInnerHTML={createMarkup(toolTipText)}
                      />
                    </span>
                  </ScorePopupContainer>
                )}
          </OldDataBlur>
          {blur && (
            <OldDataFullview id = "OldDataFullview">
            <OldDataContent id = "olddatacontent">
              <Oldtext id="old">OLD DATA!</Oldtext>
              <Oldtext id = "data_not_updated_text">This data has not been updated yet.</Oldtext>
              <Oldtext id = "oldtext">
                Scheduled updates&nbsp;{expectedRefreshTimestamp}
              </Oldtext>
              <OldDataApprove id = "olddataapprove" onClick={() => setBlur(!blur)}>
                continue with old data
              </OldDataApprove>
            </OldDataContent>
          </OldDataFullview>
          )}
        </Card>
      </TableContainer>
    );
}

const mapStateToProps = (state) => {
    const { scorecardInfo, authInfo } = state;
    const { excelLoader, kpiName } = scorecardInfo;
    const { userData } = authInfo;
    return { excelLoader, kpiName, userData };
}

const mapDispatchToProps = {
    exportToExcel: (kpiName, headers, sortedData) => scorecardActions.getScorecardData(kpiName, headers, sortedData)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(TableComponent));
