import React from 'react'
import { Card, Col } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay'

function ScorecardPlaceholder(props) {
    return (
        <Col >
            <LoadingOverlay active={true} spinner styles={{
                overlay: (base) => ({
                    ...base,
                    background: '#cccccc'
                })
            }}>
                <Card className='data-card' style={{ height: "375px", marginTop: "0.7em" }} />
            </LoadingOverlay>
        </Col>
    )
}

export default ScorecardPlaceholder
