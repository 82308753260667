import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import TradingUiComponent from "../../../../scorecardNewDesign/TradingProfitably/Screens/TradingUiComponent";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";

export const Loss = ({ loss, getLossData, layout }) => {
  useEffect(() => {
    getLossData(KPINames.LOSS);
  }, []);

  if (loss.isLoading || layout?.data === null) {
    return <ScorecardPlaceholder />;
  }

  if (loss.data) {

    return (
      <TradingUiComponent
        dataFor={"Month"}
        data={loss.data}
        index={5}
        weekNumber={layout?.data?.weekNumber}
        expectedRefreshTimestamp={layout?.data?.schedules?.lossData}
      />
    );
  }
  return null;
};

function mapStateToProps(state) {
  const {
    scorecardImprovedInfo,
    authInfo: {
      userData: { name },
    },
  } = state;
  const loss = scorecardImprovedInfo[KPINames.LOSS];
  const layout = scorecardImprovedInfo[KPINames.LAYOUT];
  return { name, loss, layout };
}

const mapDispatchToProps = {
  getLossData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Loss);
