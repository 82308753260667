import { constants } from "../constants/reducerConstants";

const siteRanking = {
    sites : [],
    error : ''
}

export function sitesInfo(state = siteRanking , action) {
    switch(action.type){
        case constants.FETCH_SITE_RANKING_SUCCESS:
          return{
              ...state,
            sites:action.data.body
           
          } 
        case constants.FETCH_SITE_RANKING_FAILURE:
            return{
                ...state,
                error:action.error.message
            }
        default: return state 
    }
}