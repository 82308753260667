

import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  z-index: 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  background-color: white;
  border: solid 1px #ebebeb;
  padding: 0px 8px;
  overflow: hidden;
`;

export const ButtonText = styled.div`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #666;
`;
export const ArrowDown = styled.img`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ebebeb;
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ebebeb;
`;

export const DropDownStyle = styled.div`
  z-index: 5;
  background-color: white;
  border-radius: 3px;
  border: solid 1px #ebebeb;
  position: absolute;
  height: 100px;
`;

