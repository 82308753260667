/* Copyright (C) BP -
* All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Date: 09/12/2021
* Author : Sunil Vora
*/

import { handleResponse } from '../helpers/commons';
import { LANGUAGE_URLS } from '../constants/environmentConstants';

export const graphServices = {
    getSiteAverageRatings
}

function getSiteAverageRatings(id, data, filter, languageCode) {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization':data
        } 
    }
    const url = LANGUAGE_URLS.find(value => value.env === process.env.REACT_APP_ENVIRONMENT && value.code.toLowerCase() === languageCode).api_url;
    return fetch(`${url}/google-aggregated-ratings`+filter, requestOptions)
    .then(handleResponse)
    .then(data => {
        if(data.statusCode === 400){
            return Promise.reject(data);
        }
        else{
            return {
                ratings: data.body
            };
        }
    })
    .catch(error => {
        return Promise.reject(error);
    });
}
