import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { scorecardActions } from "../../../../../actions/scorecardActions";
import SafetyOperationOverView from "../../../../scorecardNewDesign/SafetyOpertaion/screens/SafetyOperationOverView";
import { KPINames } from "../../../ScorecardImprovedConstants";
import ScorecardPlaceholder from "../../../ScorecardPlaceholder";


export const SiteSafetyOverviewComponent = ({ site_safety_overview, getSiteSafetyOverviewData , layout}) => {
    useEffect(() => {
        getSiteSafetyOverviewData(KPINames.SITE_SAFETY_OVERVIEW);
    }, []);
    
    if (site_safety_overview.isLoading || layout?.data === null) {
        return <ScorecardPlaceholder />;
    }

    if (site_safety_overview.data) {
        return (
            (<SafetyOperationOverView renderItems={site_safety_overview.data} dataFor={"Month"} weekNumber={layout?.data?.weekNumber} />)
        );
    }
    return null;
};

function mapStateToProps(state) {
    const {
        scorecardImprovedInfo,
    } = state;
    const site_safety_overview = scorecardImprovedInfo[KPINames.SITE_SAFETY_OVERVIEW];
    const layout = scorecardImprovedInfo[KPINames.LAYOUT];
    return { site_safety_overview, layout };
}

const mapDispatchToProps = {
    getSiteSafetyOverviewData: (kpiName) => scorecardActions.getScoreCardContent(kpiName),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SiteSafetyOverviewComponent);
