import { SORT } from '../components/Table/Table.constants';

export const REVIEWS_HEADINGS = [
    "Site name",
    "Avg star rating"
];
export const REVIEWS_HEADINGS1 = [
    "Nazwa stacji",
    "Średnia Ocena"
];
export const sortState = { key: 'Avg star rating', order: SORT.DESCENDING };

export const REVIEWS_DATA = [
    { site: 'Site A', star_rating: 4, jan: 3.45, feb: 3.45, mar: 3.45, apr: 3.45, may: 3.45, jun: 3.45, jul: 3.45, aug: 3.45, sep: 3.45, oct: 3.45, nov: 3.45, dec: 3.45 },
    { site: 'Site B', star_rating: 3, jan: 4.30, feb: 4.30, mar: 4.30, apr: 4.30, may: 4.30, jun: 4.30, jul: 4.30, aug: 4.30, sep: 4.30, oct: 4.30, nov: 4.30, dec: 4.30 },
    { site: 'Site C', star_rating: 2, jan: 3.00, feb: 3.00, mar: 3.00, apr: 3.00, may: 3.00, jun: 3.00, jul: 3.00, aug: 3.00, sep: 3.00, oct: 3.00, nov: 3.00, dec: 3.00 },
    { site: 'Site D', star_rating: 1, jan: 4.45, feb: 4.45, mar: 4.45, apr: 4.45, may: 4.45, jun: 4.45, jul: 4.45, aug: 4.45, sep: 4.45, oct: 4.45, nov: 4.45, dec: 4.45 },
    { site: 'Site E', star_rating: 3, jan: 4.50, feb: 4.50, mar: 4.50, apr: 4.50, may: 4.50, jun: 4.50, jul: 4.50, aug: 4.50, sep: 4.50, oct: 4.50, nov: 4.50, dec: 4.50 }
];

export const REVIEWS_DATA_FIELDS = {
    SITE: 'siteName',
    MONTH_AVERAGE: 'monthAverage',
    WEEK_AVERAGE: 'weekAverage',
    DAY_AVERAGE:'dayAverage',
    WEEK_RATING: 'weekRatings',
    DAY_RATING: 'dayRatings',
    MONTH_RATING:'monthRatings',
    JAN: 'jan',
    FEB: 'feb',
    MAR: 'mar',
    APR: 'apr',
    MAY: 'may',
    JUN: 'jun',
    JUL: 'jul',
    AUG: 'aug',
    SEP: 'sep',
    OCT: 'oct',
    NOV: 'nov',
    DEC: 'dec'
};

export const ratingFields = [1, 2, 3, 4, 5];

export const linkName = 'RM Cockpit';

export const scoreDataBody = {
    average : {
        month:{
            currentMonth:{
                period: "2022",
                value:1.1,
                reviews:1720,
                number: "01",
                columnHeader:"Jan'22"

            },
            previousMonth:{
                period: "2022",
                value:1.1,
                reviews:520,
                number: "01",
                columnHeader:"Jan'22"
            },
            averageRating :2.6,
            differenceInPercent:-73.6,
            
        },
        year:{
            currentYear:{
                period: "2022",
                value:4.1,
            },
            previousYear:{
                period: "2021",
                value:4.1,
            },
            averageRating :4.6,
            differenceInPercent:0
        }
    },

    average9 : {
        month:{
            currentMonth:{
                period: "2022",
                value:1.1,
                reviews:1720,
                number: "01",
                columnHeader:"Jan'22"

            },
            previousMonth:{
                period: "2022",
                value:1.1,
                reviews:520,
                number: "01",
                columnHeader:"Jan'22"
            },
            averageRating :2.6,
            differenceInPercent:-73.6,
            
        },
        year:{
            currentYear:{
                period: "2022",
                value:4.1,
            },
            previousYear:{
                period: "2021",
                value:4.1,
            },
            averageRating :4.6,
            differenceInPercent:0
        }
    },
    average7 : {
        month:{
            currentMonth:{
                period: "2022",
                value:1.1,
                reviews:1720,
                number: "01",
                columnHeader:"Jan'22"

            },
            previousMonth:{
                period: "2022",
                value:1.1,
                reviews:520,
                number: "01",
                columnHeader:"Jan'22"
            },
            averageRating :2.6,
            differenceInPercent:-73.6,
            
        },
        year:{
            currentYear:{
                period: "2022",
                value:4.1,
            },
            previousYear:{
                period: "2021",
                value:4.1,
            },
            averageRating :4.6,
            differenceInPercent:0
        }
    },
    average3 : {
        month:{
            currentMonth:{
                period: "2022",
                value:1.1,
                reviews:1720,
                number: "01",
                columnHeader:"Jan'22"

            },
            previousMonth:{
                period: "2022",
                value:1.1,
                reviews:520,
                number: "01",
                columnHeader:"Jan'22"
            },
            averageRating :2.6,
            differenceInPercent:-73.6,
            
        },
        year:{
            currentYear:{
                period: "2022",
                value:4.1,
            },
            previousYear:{
                period: "2021",
                value:4.1,
            },
            averageRating :4.6,
            differenceInPercent:0
        }
    },
    average2 : {
        month:{
            currentMonth:{
                period: "2022",
                value:1.1,
                reviews:1720,
                number: "01",
                columnHeader:"Jan'22"

            },
            previousMonth:{
                period: "2022",
                value:1.1,
                reviews:520,
                number: "01",
                columnHeader:"Jan'22"
            },
            averageRating :2.6,
            differenceInPercent:-73.6,
            
        },
        year:{
            currentYear:{
                period: "2022",
                value:4.1,
            },
            previousYear:{
                period: "2021",
                value:4.1,
            },
            averageRating :4.6,
            differenceInPercent:0
        }
    },
    
        
}
